import { useInfiniteQuery } from '@tanstack/react-query';
import { BikeleasingEmployeeInformationDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

type EmployeeMap = Map<string, BikeleasingEmployeeInformationDTO[]>;
export interface BikeleasingEmployeeInformationContextValue {
  employeeMap: EmployeeMap;
  loadForEmployeeIds: (newEmployeeIds: string[]) => void;
}
const BikeleasingEmployeeInformationContext = createContext<BikeleasingEmployeeInformationContextValue | undefined>(undefined);

// Because employeeTable uses paging to load Employees this Context is designed to load BL-EmployeeInformations paged via employeeIds
export const BikeleasingEmployeeInformationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [employeeIdsToFetch, setEmployeeIdsToFetch] = useState<string[]>([]);
  const getTenantId = useTenantID();
  const { fetchNextPage, data } = useInfiniteQuery({
    queryKey: ['tenants', getTenantId(), 'benefits', 'bikeleasing', 'employeeInformation'],
    queryFn: ({ pageParam }: { pageParam?: string[] }) =>
      pageParam?.length
        ? apis.bikeLeasing.getEmployeeInformations({ tenantId: getTenantId(), employeeIds: pageParam }).then(res => res.data)
        : { employeeInformations: [] },
    initialPageParam: [],
    getNextPageParam: () => employeeIdsToFetch,
  });

  const employeeMap = useMemo(() => {
    const map: Map<string, BikeleasingEmployeeInformationDTO[]> = new Map();

    // store all queried EmployeeIds to prevent trying to refetch those
    data?.pageParams.flatMap(employeeIds => (employeeIds as string[] | undefined) ?? []).forEach(employeeId => map.set(employeeId, []));

    data?.pages
      .flatMap(page => page.employeeInformations)
      .forEach(information => map.set(information.employeeId, map.get(information.employeeId)!.concat(information)));
    return map;
  }, [data]);

  const loadForEmployeeIds = useCallback(
    (employeeIds: string[]) => {
      const newEmployeeIds = employeeIds.filter(employeeId => !employeeMap.has(employeeId));
      if (!newEmployeeIds.length) {
        return;
      }
      setEmployeeIdsToFetch(newEmployeeIds);
    },
    [employeeMap],
  );

  useEffect(() => void fetchNextPage(), [employeeIdsToFetch, fetchNextPage]);
  const contextValue = useMemo(
    (): BikeleasingEmployeeInformationContextValue => ({
      employeeMap,
      loadForEmployeeIds,
    }),
    [loadForEmployeeIds, employeeMap],
  );

  return <BikeleasingEmployeeInformationContext.Provider value={contextValue}>{children}</BikeleasingEmployeeInformationContext.Provider>;
};

export function useBikeleasingEmployeeInformationContext(): BikeleasingEmployeeInformationContextValue {
  const ctx = useContext(BikeleasingEmployeeInformationContext);
  // Throw error that the Context is not injected, if it is undefined.
  if (ctx === undefined) {
    throw new Error('useGeolocationContext must be used within a Geolocation');
  }
  return ctx;
}
