import React, { createContext, useContext, useMemo, useState } from 'react';

export interface MainDrawerContextType {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}

const MainDrawerContext = createContext<MainDrawerContextType>({
  open: false,
  handleOpen: () => void 0,
  handleClose: () => void 0,
});

export const MainDrawerProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState(false);

  const contextValue = useMemo<MainDrawerContextType>(
    () => ({
      open,
      handleOpen: () => setOpen(true),
      handleClose: () => setOpen(false),
    }),
    [open],
  );

  return <MainDrawerContext.Provider value={contextValue}>{children}</MainDrawerContext.Provider>;
};

export function useMainDrawer(): MainDrawerContextType {
  return useContext(MainDrawerContext);
}
