import { BenefitActivationDTO, BenefitActivationDTOBenefitEnum, BenefitTemplateItemDTO } from 'probonio-shared-ui/api';
import { createContext, useContext } from 'react';

interface BenefitActivationModal {
  onBenefitCreate: (benefit: BenefitActivationDTOBenefitEnum, customId?: string) => void;
  onBenefitEdit: (benefitActivation: BenefitActivationDTO | BenefitTemplateItemDTO) => void;
  onBenefitDelete: (benefitActivation: BenefitActivationDTO) => void;
  onBenefitStop: (benefitActivation: BenefitActivationDTO) => void;
}

const BenefitActivationModalContext = createContext<BenefitActivationModal | null>(null);

export function useBenefitActivationModal(): BenefitActivationModal {
  const context = useContext(BenefitActivationModalContext);

  if (!context) {
    throw new Error('No BenefitActivationModalContext found for component');
  }

  return context;
}

export default BenefitActivationModalContext;
