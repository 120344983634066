import { Box, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { MRT_Cell, MRT_ColumnDef, MRT_Row, MRT_TableInstance } from 'material-react-table';
import {
  CouponsV2TokenOrderDTO,
  CouponsV2TokenOrderDTOOrderReasonEnum,
  CouponsV2TokenOrderDTOStatusEnum,
  EmployeeDTOStatusEnum,
} from 'probonio-shared-ui/api';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAggregationContext } from '../../coupons/aggregationContext';
import { renderUserName } from '../../../userManagement/userFormatter';

interface CellProps {
  cell: MRT_Cell<CouponsV2TokenOrderDTO>;
  row: MRT_Row<CouponsV2TokenOrderDTO>;
  table: MRT_TableInstance<CouponsV2TokenOrderDTO>;
}

const DateCell = ({ cell, table }: CellProps): JSX.Element => {
  const { i18n } = useTranslation('couponsV2Module');

  return (
    <Box marginLeft={table.getState().grouping[0] === 'month' ? 1 : 0}>
      {cell.renderValue() ? i18n.format(new Date(cell.renderValue() as string), 'date') : undefined}
    </Box>
  );
};

const AmountCell = ({ cell, row }: CellProps): JSX.Element => {
  const { t } = useTranslation('couponsV2Module');

  return (
    <Typography component="span" variant="inherit">
      {t('common:money', { money: cell.renderValue() })}
    </Typography>
  );
};

const DetailsCell = ({ cell, row }: CellProps): JSX.Element => {
  const { t } = useTranslation('couponsV2Module');
  const tokenOrderType = cell.renderValue() as CouponsV2TokenOrderDTOOrderReasonEnum;

  if (tokenOrderType === CouponsV2TokenOrderDTOOrderReasonEnum.Gift) {
    return <>{t(`ordersTable.row.giftOrder.${row.original.giftType}`, { occasion: row.original.giftOccasion })}</>;
  }
  return <>{t('ordersTable.row.couponOrder')}</>;
};

const AggregatedAmountCell = ({ aggregation, row }: CellProps & { aggregation: string }): JSX.Element => {
  const { t } = useTranslation('couponsV2Module');
  const aggregationBuckets = useAggregationContext()[aggregation];
  const bucket = aggregationBuckets?.find(bucket => bucket.bucketKey === row.groupingValue);

  if (!bucket) {
    return <></>;
  }

  return (
    <Typography component="span" variant="inherit">
      {t('common:money', { money: bucket.value })}
    </Typography>
  );
};

const StatusCell = ({ cell, row }: CellProps): JSX.Element => {
  const { t } = useTranslation('couponsV2Module');
  const theme = useTheme();

  let color: string;
  if (cell.getValue() === CouponsV2TokenOrderDTOStatusEnum.PaymentFailed) {
    color = theme.palette.error.main;
  } else if (cell.getValue() === CouponsV2TokenOrderDTOStatusEnum.Delivered) {
    color = theme.palette.success.main;
  } else {
    color = theme.palette.warning.main;
  }

  return (
    <Typography component="span" variant="inherit" color={color}>
      {t(`ordersTable.row.status.${cell.getValue()}`)}
    </Typography>
  );
};

const EmployeeCell = ({ cell, table }: CellProps): JSX.Element => {
  const { t } = useTranslation('couponsV2Module');

  if (!cell.getValue()) {
    return <em>{t('ordersTable.row.employee.deleted')}</em>;
  }

  return <>{cell.getValue()}</>;
};

export function useTokenOrderColumns(): MRT_ColumnDef<CouponsV2TokenOrderDTO>[] {
  const { t } = useTranslation('couponsV2Module');

  return useMemo<MRT_ColumnDef<CouponsV2TokenOrderDTO>[]>(
    () => [
      {
        id: 'month',
        accessorFn: tokenOrder => (tokenOrder.deliveryDate ? DateTime.fromISO(tokenOrder.deliveryDate!).toFormat('yyyy-MM') : ''),
        header: t('ordersTable.header.date'),
      },
      {
        accessorKey: 'deliveryDate',
        header: t('ordersTable.header.deliveryDate'),
        Cell: DateCell,
        aggregationFn: 'max',
        AggregatedCell: ({ cell }) => {
          const dateIso = cell.getValue<string>();
          if (!dateIso) {
            return <b>{t('ordersTable.row.deliveryDate.notDelivered')}</b>;
          }
          return <b>{DateTime.fromISO(dateIso).toLocaleString({ month: 'long', year: 'numeric' })}</b>;
        },
      },
      {
        id: 'employeeName',
        accessorFn: tokenOrder => {
          if (!tokenOrder.employee) {
            return;
          }
          return renderUserName(
            tokenOrder.employee.user,
            tokenOrder.employee.employeeNumber,
            tokenOrder.employee.status === EmployeeDTOStatusEnum.Inactive,
          );
        },
        header: t('ordersTable.header.employee'),
        Cell: EmployeeCell,
        enableSorting: false,
      },
      {
        accessorKey: 'amount',
        header: t('ordersTable.header.amount'),
        Cell: AmountCell,
        AggregatedCell: props => (
          <b>
            <AggregatedAmountCell aggregation="amountByMonth" {...props} />
          </b>
        ),
      },
      {
        accessorKey: 'status',
        header: t('ordersTable.header.status'),
        Cell: StatusCell,
      },
      {
        accessorKey: 'orderReason',
        header: t('ordersTable.header.details'),
        Cell: DetailsCell,
        enableSorting: false,
      },
    ],
    [t],
  );
}
