import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { ActivationDialogExtensions } from './ActivationDialog';
import { useBenefitContext } from './BenefitContext';
import { useBAVDialogExtensions } from './bav';
import { useBikeDialogExtensions } from './bike';
import { useCouponsDialogExtensions } from './coupons';
import { useCouponsV2DialogExtensions } from './couponsV2/useCouponsV2DialogExtensions';
import { useCustomBenefitDialogExtensions } from './customBenefit';
import { useFlexDialogExtension } from './flex/useFlexDialogExtension';
import { useInternetDialogExtension } from './internet';
import { useLunchDialogExtensions } from './lunch';
import { useMobilityDialogExtensions } from './mobility';
import { useNewsDialogExtensions } from './news/useNewsDialogExtensions';
import { useRecreationDialogExtensions } from './recreation/useRecreationDialogExtensions';
import { useBikeleasingDialogExtensions } from './bikeleasing/useBikeleasingDialogExtensions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useCustomBenefitDialogExtensionsFromContext(): ActivationDialogExtensions<any> {
  const { customBenefit } = useBenefitContext();

  return useCustomBenefitDialogExtensions(customBenefit!);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BENEFIT_ACTIVATION_HOOKS: Record<BenefitDTOBenefitEnum, () => ActivationDialogExtensions<any>> = {
  BAV: useBAVDialogExtensions,
  LUNCH: useLunchDialogExtensions,
  COUPONS: useCouponsDialogExtensions,
  COUPONS_V2: useCouponsV2DialogExtensions,
  MOBILITY: useMobilityDialogExtensions,
  GIFTS: () => {
    throw new Error('Cannot activate GIFT benefit');
  },
  INTERNET: useInternetDialogExtension,
  BIKE: useBikeDialogExtensions,
  NEWS: useNewsDialogExtensions,
  FLEX: useFlexDialogExtension,
  RECREATION: useRecreationDialogExtensions,
  CUSTOM: useCustomBenefitDialogExtensionsFromContext,
  BIKELEASING: useBikeleasingDialogExtensions,
};
