import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Button, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { CircularProgressBar } from 'probonio-shared-ui/component/progress';
import { ReactNode, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FullWidthCenterBox } from '../../../component/layout/BoxLayout';
import { BenefitPrecondition } from '../benefitPrecondition';
import { AddBikeleasingCondition } from './AddBikeleasingCondition';

import CloudIcon from '@mui/icons-material/Cloud';
import EuroIcon from '@mui/icons-material/Euro';
import ShieldIcon from '@mui/icons-material/Shield';
import { BikeleasingIcon } from 'probonio-shared-ui/component/icon';
import { useInfiniteBikeleasingConditions } from './useBikeleasingConditions';

interface BLSListItemProps {
  title: string;
  description: string;
  icon: ReactNode;
}

const BLSListItem = ({ title, description, icon }: BLSListItemProps) => {
  return (
    <ListItem>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>
        <Typography variant="body1" color="text.secondary">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </ListItemText>
    </ListItem>
  );
};

const PreconditionComponent: React.FC<{ isPreconditionMet: boolean; onSuccess?: () => void }> = ({ isPreconditionMet, onSuccess }) => {
  const { t } = useTranslation('benefitsModule');

  return (
    <>
      {!isPreconditionMet && (
        <>
          <Grid container marginTop={1} flexWrap="nowrap" alignItems="stretch">
            <Grid flexGrow={1}>
              <Typography variant="h2" gutterBottom>
                {t('BIKELEASING.integration.title')}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t('BIKELEASING.integration.info')}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ marginY: 3 }} />
          <Box width="100%" display="flex" justifyContent="center" mb={5}>
            <IconButton
              href="https://bikeleasing.de/"
              target="_blank"
              focusRipple={false}
              disableTouchRipple
              disableRipple
              sx={{ width: '100%' }}
            >
              <BikeleasingIcon width="60%" />
            </IconButton>
          </Box>
        </>
      )}
      <Box display="flex">
        {!isPreconditionMet && (
          <>
            <Box flex={1}>
              <Typography variant="h2">{t('BIKELEASING.noAccount.title')}</Typography>
              <Typography mt={3} variant="body2" color="text.secondary" gutterBottom>
                {t('BIKELEASING.noAccount.info1')}
              </Typography>
              <Typography gutterBottom variant="body2" mb={2} color="text.secondary">
                {t('BIKELEASING.noAccount.info2')}
              </Typography>
              <Typography variant="h3" gutterBottom>
                {t('BIKELEASING.advantages.title')}
              </Typography>
              <List>
                <BLSListItem
                  title={t('BIKELEASING.advantages.lessCosts.title')}
                  description={t('BIKELEASING.advantages.lessCosts.description')}
                  icon={<EuroIcon />}
                />
                <BLSListItem
                  title={t('BIKELEASING.advantages.climateFriendly.title')}
                  description={t('BIKELEASING.advantages.climateFriendly.description')}
                  icon={<CloudIcon />}
                />
                <BLSListItem
                  title={t('BIKELEASING.advantages.security.title')}
                  description={t('BIKELEASING.advantages.security.description')}
                  icon={<ShieldIcon />}
                />
              </List>
              <Typography variant="h3" gutterBottom>
                {t('BIKELEASING.createNow.title')}
              </Typography>
              <Typography gutterBottom variant="body2" color="text.secondary">
                {t('BIKELEASING.createNow.description')}
              </Typography>
              <Box display="flex" justifyContent="center" my={3}>
                <Button startIcon={<OpenInNewIcon />} target="_blank" href="https://bikeleasing.de/arbeitgeber#c2626">
                  {t('BIKELEASING.integration.bikeleasingPortal')}
                </Button>
              </Box>
              <Typography variant="h3" gutterBottom>
                {t('BIKELEASING.questions.title')}
              </Typography>
              <Typography gutterBottom variant="body2" color="text.secondary">
                <Trans i18nKey="benefitsModule:BIKELEASING.questions.description">
                  <a href="https://bikeleasing.de/" target="_blank" rel="noreferrer" />
                </Trans>
              </Typography>
            </Box>
            <Box>
              <Divider orientation="vertical" sx={{ marginX: 3 }} />
            </Box>
          </>
        )}
        <Box flex={1}>
          {!isPreconditionMet && (
            <Typography mb={3} variant="h2">
              {t('BIKELEASING.integration.guide.title')}
            </Typography>
          )}
          <Typography variant="h3" gutterBottom>
            {t('BIKELEASING.integration.guide.info.title')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t('BIKELEASING.integration.guide.info.description')}
          </Typography>
          <Typography variant="h3" marginTop={3} gutterBottom>
            {t('BIKELEASING.integration.guide.howTo.title')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t('BIKELEASING.integration.guide.howTo.description')}
          </Typography>
          <Typography component="span" variant="body2" color="text.secondary">
            <Trans i18nKey="benefitsModule:BIKELEASING.integration.guide.howTo.stepByStep">
              <a href="https://portal.bikeleasing.de/user/integrations/probonio/create" target="_blank" rel="noreferrer" />
            </Trans>
          </Typography>
          <Box mt={5}>
            <AddBikeleasingCondition onSuccess={onSuccess} />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export function useBikeleasingPrecondition(onSuccess?: () => void): BenefitPrecondition {
  const { data: bikeleasingConditionList, isLoading, isFetching } = useInfiniteBikeleasingConditions({ page: 0, pageSize: 0 });
  const isPreconditionMet = !!bikeleasingConditionList && bikeleasingConditionList.pages[0].totalCount > 0;

  const component = useMemo(
    () =>
      isFetching || isLoading ? (
        <FullWidthCenterBox>
          <CircularProgressBar />
        </FullWidthCenterBox>
      ) : (
        <PreconditionComponent isPreconditionMet={isPreconditionMet} onSuccess={onSuccess} />
      ),
    [isFetching, isLoading, isPreconditionMet, onSuccess],
  );

  return useMemo(() => ({ isPreconditionMet, PreconditionComponent: component }), [component, isPreconditionMet]);
}
