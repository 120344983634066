import { Box } from '@mui/material';
import { MRT_SortingState } from 'material-react-table';
import { CouponTransactionHistoryDTO } from 'probonio-shared-ui/api';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfiniteScrollReactTable } from '../../../component/table/InfiniteScrollReactTable';
import { mapSortModel } from '../../../component/table/sortMapper';
import { useLoadMore } from '../../../component/table/useLoadMore';
import { AggregationContextProvider, AggregationsModel } from './aggregationContext';
import { useTransactionColumns } from './transactionColumns';
import { useInfiniteTransactionHistory } from './useInfiniteTransactionHistory';

interface Props {
  filter?: string;
  includeCouponOrders?: boolean;
  includeGiftOrders?: boolean;
  includeBudgetOrders?: boolean;
}

const OrdersTableComp: React.FC<Props> = ({ filter, includeCouponOrders, includeGiftOrders, includeBudgetOrders }) => {
  const { t } = useTranslation('couponsModule');
  const [sortModel, setSortModel] = useState<MRT_SortingState>([{ id: 'transactionDate', desc: true }]);
  const isSortedByDate = sortModel.length === 0 || sortModel[0].id === 'transactionDate';
  const columns = useTransactionColumns();
  const filteredColumns = columns.filter(col => isSortedByDate || col.id !== 'month');

  const mappedSortModel = mapSortModel(columns, sortModel);

  const queryResult = useInfiniteTransactionHistory(pageParam => ({
    sortBy: mappedSortModel,
    filter,
    includeCouponOrders,
    includeGiftOrders,
    includeBudgetOrders,
    withAggregations: pageParam === 0 && isSortedByDate,
  }));

  const loadMoreProps = useLoadMore(queryResult, 'couponTransactions');

  return (
    <Box>
      <AggregationContextProvider
        value={(queryResult.data?.pages[0].aggregations || { amountByMonth: [] }) as unknown as AggregationsModel}
      >
        <InfiniteScrollReactTable<CouponTransactionHistoryDTO>
          {...loadMoreProps}
          columns={filteredColumns}
          enablePagination={false}
          enableTopToolbar={false}
          enableBottomToolbar={false}
          enableColumnActions={false}
          enableSortingRemoval={false}
          localization={{ noRecordsToDisplay: t('ordersTable.noResults') }}
          manualSorting
          onSortingChange={setSortModel}
          state={{ sorting: sortModel, grouping: isSortedByDate ? ['month'] : [], expanded: true }}
          groupedColumnMode="remove"
        />
      </AggregationContextProvider>
    </Box>
  );
};

export const OrdersTable = React.memo(OrdersTableComp);
