import WarningIcon from '@mui/icons-material/Warning';
import { Alert, Box, Button, Chip, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { BenefitDTOBenefitEnum, BenefitTemplateDTO, BenefitTemplateItemDTO, CustomBenefitDTO } from 'probonio-shared-ui/api';
import { SwitchControl } from 'probonio-shared-ui/component/form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNewDialogState } from '../../../component/dialog';
import { ConfirmationModal } from '../../../component/dialog/ConfirmationModal';
import { TextFieldControl } from '../../../component/form';
import { BenefitDropdown } from '../../userManagement/BenefitSelector';
import { BenefitCard } from './BenefitCard';
import { EditTemplateItemDialog } from './EditTemplateItemDialog';
import {
  useCreateBenefitTemplateMutation,
  useDeleteBenefitTemplateMutation,
  useUpdateBenefitTemplateMutation,
} from './benefitTemplateQueries';
import { BenefitRef } from '../../benefits/BenefitsCell';
import { TemplateItemActions } from './TemplateItemActions';
import { BENEFIT_ORDER } from '../../benefits/BenefitIcon';
import { UnsavedChangesListener } from '../../../component/form/UnsavedChangesListener';
import { useTenant } from 'probonio-shared-ui/module/me';
import { isTemplateItemValid } from './validateTemplate';

interface Props {
  template?: BenefitTemplateDTO;
  onDelete: () => void;
  onCreate: (templateId: string) => void;
}

interface FormValues {
  name: string;
  isDefault: boolean;
  templateItems: BenefitTemplateItemDTO[];
}

const INITIAL_VALUES: DefaultValues<FormValues> = { name: '', isDefault: false, templateItems: [] };

export const EditTemplateForm: React.FC<Props> = ({ template, onDelete, onCreate }) => {
  const { t } = useTranslation('tenantModule');
  const { enqueueSnackbar } = useSnackbar();
  const { tenant } = useTenant();
  const [editedItem, setEditedItem] = useState<BenefitTemplateItemDTO>();

  const editDialog = useNewDialogState();
  const confirmDeleteDialog = useNewDialogState();
  const deleteMutation = useDeleteBenefitTemplateMutation();

  const handleDeleteTemplate = useCallback(() => {
    if (!template) {
      return;
    }
    deleteMutation.mutate(template.id, {
      onSuccess: () => {
        confirmDeleteDialog.dialogState.handleClose();
        enqueueSnackbar(t('benefitTemplates.successDelete'), { variant: 'success' });
        onDelete();
      },
    });
  }, [confirmDeleteDialog.dialogState, deleteMutation, enqueueSnackbar, onDelete, t, template]);

  const { control, formState, reset, watch, handleSubmit, setValue } = useForm<FormValues>({
    defaultValues: INITIAL_VALUES,
  });
  useEffect(() => {
    reset(template ? { name: template.name, isDefault: template.isDefault, templateItems: template.templateItems } : INITIAL_VALUES);
  }, [reset, template]);
  const templateItems = watch('templateItems');

  const createMutation = useCreateBenefitTemplateMutation();
  const updateMutation = useUpdateBenefitTemplateMutation();

  const handleSave = useCallback(
    (values: FormValues) => {
      if (template) {
        const newTemplateItems = formState.dirtyFields.templateItems ? values.templateItems : undefined;
        updateMutation.mutate(
          { benefitTemplateId: template.id, update: { name: values.name, isDefault: values.isDefault, templateItems: newTemplateItems } },
          {
            onSuccess: () => {
              enqueueSnackbar(t('benefitTemplates.successUpdate'), { variant: 'success' });
            },
          },
        );
      } else {
        createMutation.mutate(
          { name: values.name, isDefault: values.isDefault, templateItems: values.templateItems },
          {
            onSuccess: newId => {
              enqueueSnackbar(t('benefitTemplates.successCreate'), { variant: 'success' });
              onCreate(newId);
            },
          },
        );
      }
    },
    [createMutation, enqueueSnackbar, formState.dirtyFields.templateItems, onCreate, t, template, updateMutation],
  );

  const handleAddBenefit = useCallback(
    (benefit: BenefitDTOBenefitEnum, customBenefit?: CustomBenefitDTO) => {
      setEditedItem({ benefit, customBenefitOptions: customBenefit ? { customBenefitId: customBenefit.id } : undefined });
      editDialog.handleOpen();
    },
    [editDialog],
  );

  const handleEditItem = useCallback(
    (item: BenefitTemplateItemDTO) => {
      setEditedItem(item);
      editDialog.handleOpen();
    },
    [editDialog],
  );

  const handleUpdateItem = useCallback(
    (updatedItem: BenefitTemplateItemDTO) => {
      const index = templateItems.findIndex(
        item =>
          item.benefit === updatedItem.benefit &&
          item.customBenefitOptions?.customBenefitId === updatedItem.customBenefitOptions?.customBenefitId,
      );
      const newItems = [...templateItems];
      if (index >= 0) {
        newItems[index] = updatedItem;
      } else {
        newItems.push(updatedItem);
      }
      setValue('templateItems', newItems, { shouldDirty: true });
    },
    [setValue, templateItems],
  );

  const handleDeleteItem = useCallback(
    (itemToDelete: BenefitTemplateItemDTO) => {
      setValue(
        'templateItems',
        templateItems.filter(
          item =>
            item.benefit !== itemToDelete.benefit ||
            item.customBenefitOptions?.customBenefitId !== itemToDelete.customBenefitOptions?.customBenefitId,
        ),
        { shouldDirty: true },
      );
    },
    [templateItems, setValue],
  );

  const excludeBenefits = useMemo(
    (): BenefitRef[] => [
      { benefit: BenefitDTOBenefitEnum.Gifts },
      { benefit: BenefitDTOBenefitEnum.News },
      ...templateItems.map(item => ({ benefit: item.benefit, customBenefitId: item.customBenefitOptions?.customBenefitId })),
    ],
    [templateItems],
  );

  const orderedTemplateItems = [...templateItems].sort((a, b) => BENEFIT_ORDER.indexOf(a.benefit) - BENEFIT_ORDER.indexOf(b.benefit));
  const containsInvalidBenefits = templateItems.some(item => !isTemplateItemValid(tenant, item));

  return (
    <Box component="form" onSubmit={handleSubmit(handleSave)} flexGrow={1}>
      <UnsavedChangesListener formState={formState} />
      <Box display="flex" mb={3} alignItems="center">
        <Typography variant="h2" flexGrow={1}>
          {t(template ? 'benefitTemplates.editTemplate' : 'benefitTemplates.createTemplate')}
        </Typography>
        <Stack direction="row" spacing={2}>
          {template && (
            <Button data-test-id="delete-template" size="small" color="error" variant="outlined" onClick={confirmDeleteDialog.handleOpen}>
              {t('benefitTemplates.action.delete')}
            </Button>
          )}
          <Button
            data-test-id="save-template"
            type="submit"
            size="small"
            color="primary"
            disabled={!formState.isDirty || templateItems.length === 0 || containsInvalidBenefits}
          >
            {t('common:buttons.save')}
          </Button>
        </Stack>
      </Box>
      <Stack spacing={2}>
        <TextFieldControl
          control={control}
          name="name"
          label={t('benefitTemplates.field.name')}
          fullWidth
          rules={{ required: true, maxLength: 60 }}
        />
        <SwitchControl control={control} name="isDefault" label={t('benefitTemplates.field.isDefault')} />
        <Box>
          <Stack direction="row" display="flex" mb={1.5} spacing={3}>
            <Box flexGrow={1}>
              <Typography variant="h3" gutterBottom>
                {t('benefitTemplates.benefitsTitle')} *
              </Typography>
              <Typography variant="body2" color="text.secondary" mb={1.5}>
                {t('benefitTemplates.benefitsInfo')}
              </Typography>
            </Box>
            <BenefitDropdown
              data-test-id="add-benefit"
              size="small"
              color="secondary"
              sx={{ flexShrink: 0 }}
              excludeBenefits={excludeBenefits}
              onSelect={handleAddBenefit}
              showTosBadge
            >
              {t('benefitTemplates.action.addBenefit')}
            </BenefitDropdown>
          </Stack>
          <Stack spacing={1.5} data-test-id="template-benefit-list">
            {orderedTemplateItems.map(templateItem => (
              <BenefitCard
                key={`${templateItem.benefit}-${templateItem.customBenefitOptions?.customBenefitId}`}
                cardItem={templateItem}
                onClick={handleEditItem}
                actions={<TemplateItemActions templateItem={templateItem} onEdit={handleEditItem} onDelete={handleDeleteItem} />}
                actionChips={
                  !isTemplateItemValid(tenant, templateItem) && (
                    <Chip
                      variant="filled"
                      color="error"
                      size="small"
                      label={t('benefitTemplates.benefitUnavailable')}
                      icon={<WarningIcon />}
                    />
                  )
                }
              />
            ))}
          </Stack>
          {!templateItems.length && <Alert severity="warning">{t('benefitTemplates.noBenefitsWarning')}</Alert>}
        </Box>
      </Stack>
      <ConfirmationModal
        dialogState={confirmDeleteDialog.dialogState}
        confirmColor="error"
        confirmButtonTitle={t('common:buttons.delete')}
        onConfirm={handleDeleteTemplate}
        closeOnConfirm={false}
        loading={deleteMutation.isPending}
        dataTestId="confirm-delete-template"
      >
        {t('benefitTemplates.confirmDelete')}
      </ConfirmationModal>
      <EditTemplateItemDialog dialogState={editDialog.dialogState} templateItem={editedItem} onUpdate={handleUpdateItem} />
    </Box>
  );
};
