import { Alert, AlertTitle } from '@mui/material';
import { CouponBudgetOrderDTOStatusEnum } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const CouponPendingBudgetOrdersTable: React.FC = () => {
  const { t } = useTranslation('couponsModule');

  const { data } = useTenantQuery(
    [
      'benefits',
      'coupons',
      'budgetOrders',
      { budgetOrderStatus: CouponBudgetOrderDTOStatusEnum.Ordered },
      apis.coupons.getBudgetOrders.name,
    ],
    tenantId => apis.coupons.getBudgetOrders({ tenantId, budgetOrderStatus: CouponBudgetOrderDTOStatusEnum.Ordered }).then(res => res.data),
  );

  if (!data || data.totalCount === 0) {
    return <></>;
  }

  return (
    <Alert severity="info" sx={{ marginBottom: 1.5 }}>
      <AlertTitle>{t('ordersTable.pendingBudgetOrders', { count: data.totalCount })}</AlertTitle>
      <ul style={{ marginBottom: 0 }}>
        {data.couponBudgetsOrders.map(order => (
          <li key={order.id}>
            <Trans
              i18nKey={
                order.orderEmployeeId
                  ? `couponsModule:ordersTable.${new Date(order.orderDate) > new Date() ? 'future' : 'pending'}BudgetOrderItem`
                  : `couponsModule:ordersTable.${new Date(order.orderDate) > new Date() ? 'future' : 'pending'}BudgetOrderItemAdmin`
              }
              values={{ ...order, orderDate: new Date(order.orderDate) }}
            />
          </li>
        ))}
      </ul>
    </Alert>
  );
};
