import { Paper, Typography, Divider, Box } from '@mui/material';

interface Props {
  rows?: number;
  columns?: number;
  title: string;
  subTitle?: React.ReactNode;
  actions?: React.ReactNode;
}

export const DashboardPanel: React.FC<React.PropsWithChildren<Props>> = ({ rows = 1, columns = 1, title, subTitle, actions, children }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        padding: 1.5,
        display: 'flex',
        flexDirection: 'column',
        gridRowEnd: `span ${rows}`,
        gridColumnEnd: `span ${columns}`,
        overflow: 'hidden',
      }}
    >
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography variant="h3">{title}</Typography>
          {subTitle && (
            <Typography component="div" variant="body2" color="text.secondary">
              {subTitle}
            </Typography>
          )}
        </Box>
        <Box>{actions}</Box>
      </Box>
      <Divider sx={{ marginY: 1.5 }} />
      <Box flexGrow={1} display="flex" flexDirection="column" overflow="hidden">
        {children}
      </Box>
    </Paper>
  );
};
