import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  BenefitDTOBenefitEnum,
  BulkResultItemDTO,
  UpdateBenefitActivationDTO,
  BulkCreateBenefitActivationsDTOEmployeeFilter,
} from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { RowSelectionState } from '../../../component/table/useManualRowSelection';
import { useRefetchEmployees } from '../../userManagement/query';
import { EmployeeFilter, mapEmployeeFilterToRequest } from '../employeeStatusList/employeeFilterState';
import { useRefetchActivations } from '../query';

export class BulkUpdateFailedError extends Error {
  public readonly results: BulkResultItemDTO[];

  public constructor(responseData: { data: BulkResultItemDTO[] }) {
    super('Bulk update failed');
    this.results = responseData.data;
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useBulkUpdateActivationsMutation(
  filter: EmployeeFilter,
  rowSelectionState: RowSelectionState,
  benefit: BenefitDTOBenefitEnum,
  customBenefitId?: string,
) {
  const getTenantId = useTenantID();
  const withMessage = useWithMessage();
  const refetchEmployees = useRefetchEmployees();
  const refetchActivations = useRefetchActivations();

  return useMutation({
    mutationFn: async (bulkUpdate: UpdateBenefitActivationDTO) => {
      let employeeFilter: BulkCreateBenefitActivationsDTOEmployeeFilter | undefined, employeeIds;
      if (rowSelectionState.allSelected) {
        employeeFilter = {
          ...mapEmployeeFilterToRequest(filter),
          considerFutureActivations: true,
          activeBenefit: benefit,
          activeCustomBenefitId: customBenefitId,
          excludeEmployeeStatus: ['INACTIVE'],
        };
      } else {
        employeeIds = Object.keys(rowSelectionState.rowSelection).filter(id => rowSelectionState.rowSelection[id]);
      }

      try {
        return await apis.benefits
          .updateBenefitActivations({
            tenantId: getTenantId(),
            bulkUpdateBenefitActivationsDTO: { benefit, customBenefitId, employeeFilter, employeeIds, update: bulkUpdate },
          })
          .then(res => res.data);
      } catch (err) {
        if (err instanceof AxiosError && err.response?.status === 422) {
          throw new BulkUpdateFailedError(err.response.data);
        }
        throw err;
      }
    },

    onError: err => {
      if (!(err instanceof BulkUpdateFailedError)) {
        withMessage(err as Error);
      }
    },
    onSuccess: async () => {
      await refetchEmployees();
      await refetchActivations();
    },
  });
}
