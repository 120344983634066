import { BAVContractDTO } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';

export function useCurrentBAVContract(employeeId: string, options?: { enabled?: boolean }): BAVContractDTO | undefined {
  const { data: contracts } = useTenantQuery(
    ['employees', employeeId, 'benefits', 'bav', 'contracts', { page: 0, pageSize: 1 }],
    tenantId => apis.bav.listContracts({ tenantId, employeeId, page: 0, pageSize: 1 }).then(res => res.data),
    options,
  );

  return contracts?.results[0];
}
