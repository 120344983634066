import { DateTime } from 'luxon';
import { BenefitActivationListDTO, BenefitsApiGetBenefitActivationsRequest } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';

interface Options extends Omit<BenefitsApiGetBenefitActivationsRequest, 'tenantId' | 'employeeId' | 'startDate' | 'endDate'> {
  startDate?: Date;
  endDate?: Date;
}

export const useBenefitActivations = (
  employeeId?: string,
  options?: Options,
): { data: BenefitActivationListDTO | undefined; isFetching: boolean; isLoading: boolean } => {
  const { data, isFetching, isLoading } = useTenantQuery(
    ['employees', employeeId, 'benefitActivations', options, apis.benefits.getBenefitActivations.name],
    tenantId =>
      apis.benefits
        .getBenefitActivations({
          employeeId: employeeId!,
          tenantId,
          ...options,
          startDate: options?.startDate ? DateTime.fromJSDate(options.startDate).toFormat('yyyy-MM') : undefined,
          endDate: options?.endDate ? DateTime.fromJSDate(options.endDate).toFormat('yyyy-MM') : undefined,
        })
        .then(res => res.data),
    {
      enabled: !!employeeId,
      placeholderData: previousData => previousData,
    },
  );
  return { data, isFetching, isLoading };
};
