import BadgeIcon from '@mui/icons-material/Badge';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import {
  EmployeeDTOCivilStatusEnum,
  EmployeeDTOStatusEnum,
  UpdateEmployeeDTO,
  UpdateEmployeeDTOEmployeeTypeEnum,
  UpdateEmployeeDTORolesEnum,
  UpdateEmployeeDTOStatusEnum,
  UserDTOStatusEnum,
} from 'probonio-shared-ui/api';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import { apis } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { validateEmail } from 'probonio-shared-ui/utils/email';
import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNewDialogState } from '../../../../component/dialog';
import { ConfirmationModal } from '../../../../component/dialog/ConfirmationModal';
import { DatePickerControl, SelectControl, TextFieldControl } from '../../../../component/form';
import { MaskedClipboardInput } from '../../../../component/form/MaskedClipboardInput';
import { UnsavedChangesListener } from '../../../../component/form/UnsavedChangesListener';
import { ActionCardContent } from '../../../../component/layout/ActionCardContent';
import { SettingsFormRow } from '../../../../component/settings/SettingsFormRow';
import { useAppSelector } from '../../../../redux/hooks';
import { getDirtyFieldsFromData, toISODateString } from '../../../../util/form';
import { formatRegistrationCode } from '../../AddUserDialog/AddUserCode';
import { DeleteEmployeeButton } from '../../DeleteEmployeeButton';
import { TerminateEmploymentDialog } from '../../TerminateEmploymentDialog';
import { mapEmployeeStatus } from '../../employeeStatus';
import { useRefetchEmployees } from '../../query';
import { useProfileEmployee } from '../activations/hooks/useProfileEmployee';
import { useTenant } from 'probonio-shared-ui/module/me';

const USER_ROLES: readonly UpdateEmployeeDTORolesEnum[] = ['USER', 'TENANT_ADMIN', 'TENANT_ASSISTANT'] as const;
const EMPLOYEE_TYPES: UpdateEmployeeDTOEmployeeTypeEnum[] = Object.values(UpdateEmployeeDTOEmployeeTypeEnum);

interface FormValues
  extends Omit<
    UpdateEmployeeDTO,
    'employeeType' | 'hireDate' | 'roles' | 'birthDate' | 'terminationDate' | 'defaultCouponId' | 'defaultCouponCombination'
  > {
  employeeType: UpdateEmployeeDTOEmployeeTypeEnum | '';
  // eslint-disable-next-line @rushstack/no-new-null
  hireDate: Date | null;
  // eslint-disable-next-line @rushstack/no-new-null
  birthDate: Date | null;
  role: UpdateEmployeeDTORolesEnum;
}

const UserProfileCard: React.FC = () => {
  const { employee, onNavigateBack } = useProfileEmployee();
  const { t } = useTranslation('usersModule');
  const { enqueueSnackbar } = useSnackbar();
  const me = useAppSelector(state => state.me.user);
  const withMessage = useWithMessage();
  const refetchEmployees = useRefetchEmployees();
  const confirmMailDialog = useNewDialogState();
  const activateEmployeeDialog = useNewDialogState();
  const terminateEmploymentDialog = useNewDialogState();
  const { invalidateTenant } = useTenant();

  const { control, handleSubmit, formState, getValues, reset, watch } = useForm<FormValues>({
    shouldFocusError: false,
    defaultValues: {
      status: employee.status as UpdateEmployeeDTOStatusEnum,
      department: employee.department || '',
      costCenter: employee.costCenter || '',
      employeeNumber: employee.employeeNumber || '',
      employeeType: employee.employeeType || '',
      hireDate: employee.hireDate ? DateTime.fromISO(employee.hireDate).toJSDate() : null,
      birthDate: employee.birthDate ? DateTime.fromISO(employee.birthDate).toJSDate() : null,
      role: employee.roles[0] || UpdateEmployeeDTORolesEnum.User,
      mail: employee.user?.mail || '',
      civilStatus: employee.civilStatus || EmployeeDTOCivilStatusEnum.Single,
      numberOfKids: employee.numberOfKids || 0,
    },
  });
  const mail = watch('mail');

  const employeeStatus = mapEmployeeStatus(employee);

  const userRoleOptions = useMemo(
    () =>
      USER_ROLES.map(role => ({
        value: role,
        label: t(`common:role.${role}.name`),
      })),
    [t],
  );

  const updateUserDetailsMutation = useMutation({
    mutationFn: async (updatedEmployee: UpdateEmployeeDTO) => {
      await apis.employee.updateEmployee({
        tenantId: employee.tenantId,
        employeeId: employee.id,
        updateEmployeeDTO: updatedEmployee,
      });
    },
    onSuccess: async () => {
      enqueueSnackbar(t('saveChanges'), { variant: 'success' });
      const { costCenter, department } = formState.dirtyFields;
      if (costCenter || department) {
        await invalidateTenant();
      }
      await refetchEmployees();
    },
    onError: err => {
      if (err instanceof AxiosError && err.response?.status === 409) {
        if (err.response.data.data.mail) {
          enqueueSnackbar(t('saveConflictMail'), { variant: 'error' });
        } else {
          enqueueSnackbar(t('saveConflictEmployeeNumber'), { variant: 'error' });
        }
      } else if (err instanceof AxiosError && err.response?.status === 422) {
        enqueueSnackbar(t('deactivationError'), { variant: 'error' });
      } else {
        withMessage(err as Error);
      }
    },
  });

  const reactivateMutation = useMutation({
    mutationFn: async () => {
      await apis.employee.updateEmployee({
        tenantId: employee.tenantId,
        employeeId: employee.id,
        updateEmployeeDTO: { terminationDate: null, status: EmployeeDTOStatusEnum.Active },
      });
    },
    onSuccess: async () => {
      enqueueSnackbar(t('userForm.terminateEmployment.reactivateSuccess'), { variant: 'success' });
      await refetchEmployees();
      activateEmployeeDialog.dialogState.handleClose();
    },
  });

  const handleSave = useCallback(() => {
    // Check for dirty fields and only make request with changed fields.
    const { values, count } = getDirtyFieldsFromData(formState.dirtyFields, getValues);

    // Form is currently built with a single string for the roles.
    let roles: UpdateEmployeeDTORolesEnum[] | undefined;
    if (values.role) {
      roles = [values.role];
    }

    if (count > 0) {
      const updateDTO: UpdateEmployeeDTO = {
        status: values.status,
        employeeType: values.employeeType || undefined,
        employeeNumber: values.employeeNumber === undefined ? undefined : values.employeeNumber || null,
        department: values.department === undefined ? undefined : values.department || null,
        costCenter: values.costCenter === undefined ? undefined : values.costCenter || null,
        hireDate: toISODateString(values.hireDate),
        birthDate: toISODateString(values.birthDate),
        roles,
        mail: values.mail,
        civilStatus: values.civilStatus,
        numberOfKids: values.numberOfKids,
      };
      void updateUserDetailsMutation
        .mutateAsync(updateDTO)
        .then(() => reset(getValues()))
        .catch(() => {});
    } else {
      enqueueSnackbar(t('notChanged'), { variant: 'error' });
    }
  }, [formState.dirtyFields, getValues, updateUserDetailsMutation, reset, enqueueSnackbar, t]);

  const handleSaveWithConfirm = useCallback(() => {
    const { values } = getDirtyFieldsFromData(formState.dirtyFields, getValues);

    if (values.mail && values.mail !== employee.user?.mail) {
      confirmMailDialog.handleOpen();
      return;
    }

    handleSave();
  }, [confirmMailDialog, employee.user?.mail, formState.dirtyFields, getValues, handleSave]);

  const handleResetTerminationDate = useCallback(() => {
    reactivateMutation.mutate();
  }, [reactivateMutation]);

  return (
    <>
      <form onSubmit={handleSubmit(handleSaveWithConfirm)}>
        <ActionCardContent
          title={t('userMenu.editSettings')}
          actions={
            <Stack direction="row" gap={1}>
              <Button variant="text" size="small" onClick={onNavigateBack} data-test-id="settings-form-panel-cancel">
                {t('common:buttons.abort')}
              </Button>
              <Tooltip arrow placement="top" title={t('common:buttons.saveDisabledTooltip')} disableHoverListener={formState.isDirty}>
                <span>
                  <LoadingButton size="small" type="submit" disabled={!formState.isDirty} dataTestId="settings-form-panel-save">
                    {t('common:buttons.save')}
                  </LoadingButton>
                </span>
              </Tooltip>
            </Stack>
          }
        >
          <SettingsFormRow
            title={t('userForm.row.personal.title')}
            fullWidth
            info={<Trans i18nKey="usersModule:userForm.row.personal.info" />}
            hideDivider
          >
            <Stack direction="column" gap={3}>
              {employee.user && (
                <TextFieldControl
                  data-test-id="edit-mail"
                  control={control}
                  name="mail"
                  type="email"
                  label={t('userForm.field.mail')}
                  rules={{ required: true, validate: { email: value => !value || validateEmail(value) } }}
                  size="small"
                />
              )}
              <DatePickerControl
                fullWidth
                name="birthDate"
                label={t('userForm.field.birthDate')}
                control={control}
                size="small"
                minDate={DateTime.now().minus({ years: 80 }).toJSDate()}
                maxDate={DateTime.now().minus({ years: 10 }).toJSDate()}
                showYearDropdown
              />
              <Box display="grid" gap={1} gridTemplateColumns="2fr 1fr">
                <SelectControl
                  dataTestId="edit-civilStatus"
                  control={control}
                  name="civilStatus"
                  label={t('benefitsModule:RECREATION.fields.civilStatus')}
                  fullWidth
                  options={Object.values(EmployeeDTOCivilStatusEnum).map(value => ({ label: t(`common:civilStatus.${value}`), value }))}
                  rules={{ required: true }}
                />
                <SelectControl
                  dataTestId="edit-numberOfKids"
                  control={control}
                  name="numberOfKids"
                  label={t('benefitsModule:RECREATION.fields.numberOfKids')}
                  fullWidth
                  options={new Array(9).fill(0).map((value, index) => ({ label: `${index}`, value: index }))}
                  rules={{ required: true }}
                />
              </Box>
            </Stack>
          </SettingsFormRow>
          <SettingsFormRow title={t('userForm.row.professional.title')} fullWidth info={t('userForm.row.professional.info')}>
            <Stack direction="column" gap={3}>
              <TextFieldControl
                data-test-id="edit-number"
                fullWidth
                name="employeeNumber"
                label={t('userForm.field.employeeNumber')}
                control={control}
                size="small"
              />
              <SelectControl
                dataTestId="edit-role"
                control={control}
                name="role"
                label={t('userForm.field.role')}
                fullWidth
                rules={{ required: true }}
                options={userRoleOptions}
                size="small"
                disabled={me?.employments?.some(employment => employment.id === employee.id)}
              />
              <TextFieldControl
                data-test-id="edit-department"
                fullWidth
                name="department"
                label={t('userForm.field.department')}
                control={control}
                size="small"
              />
              <TextFieldControl
                data-test-id="edit-costCenter"
                fullWidth
                name="costCenter"
                label={t('userForm.field.costCenter')}
                control={control}
                size="small"
              />
              <DatePickerControl
                data-test-id="edit-hire-date"
                fullWidth
                name="hireDate"
                label={t('userForm.field.hireDate')}
                control={control}
                size="small"
                minDate={DateTime.now().minus({ years: 80 }).toJSDate()}
                maxDate={DateTime.now().plus({ years: 1 }).toJSDate()}
              />

              <SelectControl
                dataTestId="edit-type"
                control={control}
                name="employeeType"
                label={t('userForm.field.employeeTypeLabel')}
                fullWidth
                defaultValue=""
                options={EMPLOYEE_TYPES.map(type => ({
                  value: type,
                  label: t(`common:employeeType.${type}`),
                }))}
                size="small"
              />

              {employeeStatus.status === EmployeeDTOStatusEnum.InvitationCode && (
                <MaskedClipboardInput
                  value={formatRegistrationCode(employee.registrationCode)}
                  mask={t('userForm.registrationCodeMask')}
                  fullWidth
                  size="small"
                  inputProps={{ sx: { textAlign: 'center' } }}
                />
              )}
            </Stack>
          </SettingsFormRow>

          <Stack direction="row" justifyContent="flex-end" spacing={2} marginTop={3}>
            {(employee.status === EmployeeDTOStatusEnum.Active || employee.status === EmployeeDTOStatusEnum.Registered) &&
              employeeStatus.status !== 'PLANNED_TERMINATION' && (
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  startIcon={<MeetingRoomIcon />}
                  disabled={employee.user?.id === me?.id}
                  onClick={terminateEmploymentDialog.handleOpen}
                  data-test-id="button-terminate-employment"
                >
                  {t('userForm.terminateEmployment.button')}
                </Button>
              )}
            {(employee.status === EmployeeDTOStatusEnum.Inactive || employeeStatus.status === 'PLANNED_TERMINATION') && (
              <Button
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<BadgeIcon />}
                onClick={activateEmployeeDialog.handleOpen}
                data-test-id="button-reactivate-employment"
              >
                {t('userForm.terminateEmployment.activate')}
              </Button>
            )}
            {(employee.status === EmployeeDTOStatusEnum.Inactive || employee.status === EmployeeDTOStatusEnum.InvitationCode) && (
              <DeleteEmployeeButton employee={employee} />
            )}
          </Stack>
        </ActionCardContent>
      </form>

      <ConfirmationModal dialogState={confirmMailDialog.dialogState} title={t('userForm.confirmMailDialog.title')} onConfirm={handleSave}>
        <Typography variant="inherit" mb={2}>
          <Trans i18nKey="usersModule:userForm.confirmMailDialog.text" values={{ mail }} />
        </Typography>
        <Trans
          i18nKey={
            employee.user?.status === UserDTOStatusEnum.Pending
              ? 'usersModule:userForm.confirmMailDialog.pendingInfo'
              : 'usersModule:userForm.confirmMailDialog.activeInfo'
          }
        />
      </ConfirmationModal>
      <TerminateEmploymentDialog dialogState={terminateEmploymentDialog.dialogState} employeeId={employee.id} />
      <ConfirmationModal
        dialogState={activateEmployeeDialog.dialogState}
        title={t('userForm.terminateEmployment.reactivateTitle')}
        onConfirm={handleResetTerminationDate}
        closeOnConfirm={false}
        loading={reactivateMutation.isPending}
      >
        {t('userForm.terminateEmployment.reactivateText')}
      </ConfirmationModal>
      <UnsavedChangesListener formState={formState} />
    </>
  );
};

export default UserProfileCard;
