import { Card } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { EmployeeDTO, EmployeeDTOStatusEnum } from 'probonio-shared-ui/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SubPage from '../../../../component/layout/SubPage';
import { renderUserName } from '../../userFormatter';
import ProfileEmployeeContext from '../activations/hooks/useProfileEmployee';
import ProfileCard from '../profile/ProfileCard';

interface Props {
  employee: EmployeeDTO;
  cardContent: React.ReactNode;
  editActive?: boolean;
  onEdit: () => void;
  onCancel: () => void;
}

const UserProfileSubPage: React.FC<Props> = ({ employee, cardContent, editActive, onEdit, onCancel }) => {
  const { t } = useTranslation('usersModule');

  return (
    <SubPage
      title={`${renderUserName(employee.user, employee.employeeNumber)}${
        employee?.status === EmployeeDTOStatusEnum.Inactive ? ` (${t('statusTerminated')})` : ''
      }`}
      onNavigateBack={onCancel}
    >
      <ProfileEmployeeContext.Provider value={{ employee, onNavigateBack: onCancel }}>
        <Grid
          sx={{ height: 'calc(100vh - 190px)' }}
          container
          display="grid"
          gridAutoFlow="column"
          gap={2.5}
          gridTemplateColumns="300px 3.6fr"
        >
          <Grid display="grid" alignItems="flex-start">
            <Card
              sx={{
                display: 'flex',
                padding: 1.5,
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <ProfileCard data-test-id="user-profile-card" editActive={editActive} handleEdit={onEdit} />
            </Card>
          </Grid>
          <Grid data-test-id="user-profile-card-benefit-content" marginBottom={2} display="grid">
            <Card sx={{ padding: 1.5 }}>{cardContent}</Card>
          </Grid>
        </Grid>
      </ProfileEmployeeContext.Provider>
    </SubPage>
  );
};

export default UserProfileSubPage;
