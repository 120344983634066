import { useTranslation } from 'react-i18next';
import { BenefitTab } from '../../component/benefitTabs/BenefitTab';
import { BenefitTabPanel } from '../../component/benefitTabs/BenefitTabPanel';
import { useSearchParamsTabsState } from '../../component/tab/tabState';
import { BENEFIT_ICONS } from '../../module/benefits/BenefitIcon';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { NewsArticlesTable } from '../../module/benefits/news/NewsArticlesTable';
import { NewsBenefitMenu } from '../../module/benefits/news/NewsBenefitMenu';
import { useNewsArticles } from '../../module/benefits/news/useNewsArticles';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';

const NewsArticlesPage: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const tabsState = useSearchParamsTabsState('tab');

  const draftArticlesCount =
    useNewsArticles({
      page: 0,
      pageSize: 0,
      status: 'DRAFT',
    }).data?.totalCount || 0;

  const publishedArticlesCount =
    useNewsArticles({
      page: 0,
      pageSize: 0,
      status: 'ACTIVE',
    }).data?.totalCount || 0;

  const expiredArticlesCount =
    useNewsArticles({
      page: 0,
      pageSize: 0,
      status: 'EXPIRED',
    }).data?.totalCount || 0;

  return (
    <AuthorizedLayout title={t('NEWS.articles')} breadcrumbs={[{ title: t('NEWS.title'), to: '/benefits/news/articles' }]}>
      <BenefitSubPage icon={BENEFIT_ICONS.NEWS} title={t('NEWS.title')} subTitle={t('NEWS.articles')} menu={<NewsBenefitMenu />}>
        <BenefitTabPanel tabsState={tabsState}>
          <BenefitTab value="" label={t('NEWS.drafts')} count={draftArticlesCount}>
            <NewsArticlesTable status="DRAFT" />
          </BenefitTab>
          <BenefitTab value="published" label={t('NEWS.published')} count={publishedArticlesCount}>
            <NewsArticlesTable status="ACTIVE" />
          </BenefitTab>
          <BenefitTab value="expired" label={t('NEWS.expired')} count={expiredArticlesCount}>
            <NewsArticlesTable status="EXPIRED" />
          </BenefitTab>
        </BenefitTabPanel>
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};

export default NewsArticlesPage;
