import DownloadIcon from '@mui/icons-material/Download';
import { IconButton, Stack } from '@mui/material';
import { MRT_Row } from 'material-react-table';
import { InternetReceiptTenantDTO } from 'probonio-shared-ui/api';
import React, { SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const InternetReceiptActions: React.FC<{ row: MRT_Row<InternetReceiptTenantDTO> }> = ({ row }) => {
  const { t } = useTranslation('benefitsModule');

  // prevent clicks inside the actions component to propagate to the row click handler
  const handleStopPropagation = useCallback((ev: SyntheticEvent) => ev.stopPropagation(), []);

  return (
    <Stack direction="row" justifyContent="flex-end" onClick={handleStopPropagation}>
      {(row.original.imageURL || row.original.pdfURL) && (
        <IconButton
          data-test-id="download-receipt-button"
          href={row.original.pdfURL || row.original.imageURL!}
          title={t('INTERNET.downloadReceipt')}
        >
          <DownloadIcon fontSize="small" />
        </IconButton>
      )}
    </Stack>
  );
};
