import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { BenefitDTOBenefitEnum, CreateBenefitActivationDTO, UpdateBenefitActivationDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useActiveActivation } from '../ActiveActivation';
import { useRefetchActivations } from '../query';
import { ActivationDialogSaveFunctions } from './ActivationDialog';

export function useEmployeeActivationSaveFunctions(
  benefit: BenefitDTOBenefitEnum,
  employeeId: string,
  customBenefitId?: string,
): ActivationDialogSaveFunctions {
  const { t } = useTranslation('benefitsModule');
  const refetchActivations = useRefetchActivations(employeeId);
  const getTenantId = useTenantID();
  const { enqueueSnackbar } = useSnackbar();

  const withMessage = useWithMessage();

  const handleActivationError = useCallback(
    (err: Error) => {
      if ((err as AxiosError).response?.status === 409) {
        const errorData = (err as AxiosError).response?.data as { data?: { flexConflict?: boolean } } | undefined;
        enqueueSnackbar(t(errorData?.data?.flexConflict ? 'flexConflictError' : 'conflictError'), { variant: 'error' });
      } else if ((err as AxiosError).response?.status === 422) {
        enqueueSnackbar(t([`inuseError.${benefit}`, 'inuseError.default']), { variant: 'error' });
      } else {
        withMessage(err);
      }
    },
    [benefit, enqueueSnackbar, t, withMessage],
  );

  const { activeActivation } = useActiveActivation(employeeId, benefit, false, customBenefitId);

  const createActivationMutation = useMutation({
    mutationFn: async (props: { create: CreateBenefitActivationDTO }) => {
      await apis.benefits.createBenefitActivation({
        tenantId: getTenantId(),
        employeeId,
        createBenefitActivationDTO: props.create,
      });
    },

    onSuccess: () => {
      void refetchActivations();
      enqueueSnackbar(t('successCreate'), { variant: 'success' });
    },
    onError: (err: Error) => handleActivationError(err),
  });

  const updateActivationMutation = useMutation({
    mutationFn: async (props: { benefitActivationId: string; update: UpdateBenefitActivationDTO }) => {
      await apis.benefits.updateBenefitActivation({
        benefitActivationId: props.benefitActivationId,
        tenantId: getTenantId(),
        employeeId,
        updateBenefitActivationDTO: props.update,
      });
    },

    onSuccess: () => {
      void refetchActivations();
      enqueueSnackbar(t('successUpdate'), { variant: 'success' });
    },
    onError: (err: Error) => handleActivationError(err),
  });

  return useMemo(
    () => ({
      activeEndDate: activeActivation?.endDate ? DateTime.fromISO(activeActivation.endDate) : undefined,
      onCreateActivation: create => createActivationMutation.mutateAsync({ create }),
      onUpdateActivation: (benefitActivationId, update) => updateActivationMutation.mutateAsync({ benefitActivationId, update }),
    }),
    [activeActivation?.endDate, createActivationMutation, updateActivationMutation],
  );
}
