import { BikeContractDTO } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';

export function useCurrentBikeContract(employeeId: string, options?: { enabled?: boolean }): BikeContractDTO | undefined {
  const { data: contracts } = useTenantQuery(
    ['employees', employeeId, 'benefits', 'bike', 'contracts', { page: 0, pageSize: 1 }],
    tenantId => apis.bike.listContracts({ tenantId, employeeId, page: 0, pageSize: 1 }).then(res => res.data),
    options,
  );

  return contracts?.results[0];
}
