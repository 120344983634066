import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { HorizontalBox } from '../../../component/layout/BoxLayout';
import { RowSelectionState } from '../../../component/table/useManualRowSelection';
import { EmployeeBenefitStatusList } from './EmployeeBenefitStatusList';
import { EmployeeFilterSelector } from './EmployeeFilterSelector';
import { EmployeeFilterState } from './employeeFilterState';

interface Props {
  benefit: BenefitDTOBenefitEnum;
  customBenefitId?: string;
  filterState: EmployeeFilterState;
  rowSelectionState: RowSelectionState;
}

export const EmployeeBenefitStatusPanel = ({ benefit, customBenefitId, filterState, rowSelectionState }: Props): JSX.Element => {
  return (
    <>
      <HorizontalBox gap={1} marginBottom={1.5}>
        <EmployeeFilterSelector
          filterState={filterState}
          showBenefitFilter={filterState.debouncedFilter.activationState !== 'ACTIVE'}
          showStatusFilter
        />
      </HorizontalBox>
      <EmployeeBenefitStatusList
        benefit={benefit}
        customBenefitId={customBenefitId}
        filter={filterState.debouncedFilter}
        rowSelectionState={rowSelectionState}
      />
    </>
  );
};
