import { Chip, Stack, TableProps } from '@mui/material';
import { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { BenefitActivationDTO, BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemedMaterialReactTable } from '../../../component/table/ThemedMaterialReactTable';
import { BenefitActivationActions } from '../../userManagement/UserProfile/activations/actions/BenefitActivationActions';
import { BenefitActivationModalProvider } from '../../userManagement/UserProfile/activations/modals/BenefitActivationModalProvider';
import { useBenefitActivations } from './useBenefitActivations';
import { BenefitOptionChips } from '../../tenant/benefitTemplates/BenefitCard';
import { BenefitNameAndIcon } from '../../tenant/benefitTemplates/BenefitNameAndIcon';
import { isActive } from '../../userManagement/UserProfile/activations/hooks/useEmployeeActivations';

interface Props {
  employeeId: string;
}

const PAGE_SIZE = 10;

export const ActivationsList: React.FC<Props> = ({ employeeId }) => {
  const { t, i18n } = useTranslation('benefitsModule');
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: PAGE_SIZE });
  const { data: activations, isLoading } = useBenefitActivations(employeeId, {
    page: pagination.pageIndex,
    pageSize: pagination.pageSize,
    sortBy: ['startDate:desc', 'benefit:asc'],
  });

  const COLUMNS = useMemo(
    (): MRT_ColumnDef<BenefitActivationDTO>[] => [
      {
        accessorKey: 'benefit',
        header: t('fields.benefit'),
        Cell: ({ row, cell }) => (
          <BenefitNameAndIcon
            benefit={cell.getValue<BenefitDTOBenefitEnum>()}
            customBenefitId={row.original.customBenefitOptions?.customBenefitId}
          />
        ),
      },
      {
        accessorKey: 'startDate',
        header: t('fields.dateFrom'),
        maxSize: 80,
        Cell: ({ cell }) => i18n.format(new Date(cell.getValue<string>()), 'monthAndYearShort'),
      },
      {
        accessorKey: 'endDate',
        header: t('fields.dateTill'),
        maxSize: 80,
        Cell: ({ cell }) => (cell.getValue<string>() ? i18n.format(new Date(cell.getValue<string>()), 'monthAndYearShort') : ''),
      },
      {
        id: 'options',
        header: t('fields.options'),
        Cell: ({ row }) => (
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <BenefitOptionChips benefitOptions={row.original} />
            {isActive(row.original) && <Chip size="small" color="success" label={t('usersModule:profileActivationList.active')} />}
          </Stack>
        ),
      },
    ],
    [i18n, t],
  );

  const renderActions = useCallback(
    ({ row }: { row: MRT_Row<BenefitActivationDTO> }) => <BenefitActivationActions activation={row.original} />,
    [],
  );

  return (
    <BenefitActivationModalProvider>
      <ThemedMaterialReactTable
        enableTopToolbar={false}
        enableColumnActions={false}
        enableSorting={false}
        manualPagination
        onPaginationChange={setPagination}
        data={activations?.benefitActivations || []}
        columns={COLUMNS}
        rowCount={activations?.totalCount || 0}
        state={{ pagination, isLoading }}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={renderActions}
        muiTableProps={{ 'data-test-id': 'activations-list' } as TableProps}
      />
    </BenefitActivationModalProvider>
  );
};
