import { Box, Typography } from '@mui/material';

interface Props {
  size?: number;
  padding?: number;
  color?: 'success' | 'warning' | 'error' | 'info' | 'primary' | 'secondary';
}

export const Ribbon: React.FC<React.PropsWithChildren<Props>> = ({ size = 150, padding = 0.25, color = 'error', children }) => {
  return (
    <Box
      sx={{
        pointerEvents: 'none',
        width: size,
        height: size,
        position: 'absolute',
        top: 0,
        right: 0,
        overflow: 'hidden',
        zIndex: 1,
        '& div': {
          width: '100%',
          color: `${color}.contrastText`,
          backgroundColor: `${color}.main`,
          padding,
          textAlign: 'center',
          transform: `translate(50%, -50%) rotate(45deg) translateY(${size * 0.4}px)`,
        },
      }}
    >
      <Typography component="div" fontSize="12px" textTransform="uppercase" fontWeight="600">
        {children}
      </Typography>
    </Box>
  );
};
