import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type LocationState = { prevPath?: string; forceHistoryBack?: boolean } | undefined;

export function useNavigateBack(to?: string): () => void {
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(() => {
    const { prevPath, forceHistoryBack } = (typeof location.state === 'object' && (location.state as LocationState)) || {};
    if (!to || prevPath === to || forceHistoryBack) {
      window.history.back();
    } else {
      navigate(to);
    }
  }, [to, navigate, location]);
}
