import { BenefitDTOBenefitEnum, EmployeeApiListEmployeesRequest } from 'probonio-shared-ui/api';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BenefitTab } from '../../../component/benefitTabs/BenefitTab';
import { BenefitTabPanel } from '../../../component/benefitTabs/BenefitTabPanel';
import { EmployeeBenefitStatusPanel } from './EmployeeBenefitStatusPanel';
import { EmployeeFilter, EmployeeFilterState, mapEmployeeFilterToRequest } from './employeeFilterState';
import { useEmployeeList } from '../../userManagement/useEmployeeList';
import { useSearchParamsTabsState } from '../../../component/tab/tabState';
import { RowSelectionState } from '../../../component/table/useManualRowSelection';
import { BENEFIT_PRECONDITION_HOOKS } from '../benefitPrecondition';
import { BenefitPaper } from '../../../component/benefitTabs/BenefitPaper';

interface Props {
  benefit: BenefitDTOBenefitEnum;
  customBenefitId?: string;
  filterState: EmployeeFilterState;
  rowSelectionState: RowSelectionState;
  children?: React.ReactNode;
}

export const BenefitEmployeeTabs = ({ benefit, customBenefitId, filterState, rowSelectionState, children }: Props): JSX.Element => {
  const precondition = BENEFIT_PRECONDITION_HOOKS[benefit]?.();
  const { t } = useTranslation('benefitsModule');
  const tabsState = useSearchParamsTabsState('tab');
  const isInitialLoad = useRef(true);

  const parameter: Partial<EmployeeApiListEmployeesRequest> = {
    ...mapEmployeeFilterToRequest(filterState.debouncedFilter),
    considerFutureActivations: true,
    page: 0,
    pageSize: 0,
    excludeEmployeeStatus: ['INACTIVE'],
  };

  const activeEmployeeList = useEmployeeList({ ...parameter, activeBenefit: benefit, activeCustomBenefitId: customBenefitId });
  const inactiveEmployeeList = useEmployeeList({
    ...parameter,
    inactiveBenefit: benefit,
    inactiveCustomBenefitId: customBenefitId,
  });
  const totalEmployeeList = useEmployeeList(parameter, { enabled: !!filterState.debouncedFilter.benefitFilter });

  const activeEmployeeCount = activeEmployeeList.data?.totalCount || 0;
  const inactiveEmployeeCount = inactiveEmployeeList.data?.totalCount || 0;
  const totalEmployeeCount = filterState.debouncedFilter.benefitFilter
    ? totalEmployeeList.data?.totalCount || 0
    : activeEmployeeCount + inactiveEmployeeCount;

  useEffect(() => {
    if (!activeEmployeeList.data || !inactiveEmployeeList.data || !isInitialLoad.current) return;

    isInitialLoad.current = false;

    if (activeEmployeeCount === 0 && inactiveEmployeeCount > 0 && tabsState.tabIndex === '') {
      return tabsState.handleTabChange(undefined, 'inactive');
    }
  }, [activeEmployeeCount, activeEmployeeList.data, inactiveEmployeeCount, inactiveEmployeeList.data, tabsState]);

  useEffect(() => {
    const setFilter = filterState.setFilter;
    let activationState: EmployeeFilter['activationState'];
    if (tabsState.tabIndex === '') {
      activationState = 'ACTIVE';
    } else if (tabsState.tabIndex === 'inactive') {
      activationState = 'INACTIVE';
    }

    setFilter(filter => ({
      ...filter,
      activationState,
    }));
  }, [filterState.setFilter, tabsState.tabIndex]);

  return !precondition || precondition.isPreconditionMet ? (
    <BenefitTabPanel tabsState={tabsState}>
      <BenefitTab value="" label={t('employeeTabs.tab.active')} count={activeEmployeeCount}>
        <EmployeeBenefitStatusPanel
          key="active"
          benefit={benefit}
          customBenefitId={customBenefitId}
          filterState={filterState}
          rowSelectionState={rowSelectionState}
        />
      </BenefitTab>
      <BenefitTab value="inactive" label={t('employeeTabs.tab.inactive')} count={inactiveEmployeeCount}>
        <EmployeeBenefitStatusPanel
          key="inactive"
          benefit={benefit}
          customBenefitId={customBenefitId}
          filterState={filterState}
          rowSelectionState={rowSelectionState}
        />
      </BenefitTab>
      <BenefitTab value="all" label={t('employeeTabs.tab.all')} count={totalEmployeeCount}>
        <EmployeeBenefitStatusPanel
          key="all"
          benefit={benefit}
          customBenefitId={customBenefitId}
          filterState={filterState}
          rowSelectionState={rowSelectionState}
        />
      </BenefitTab>
      {children}
    </BenefitTabPanel>
  ) : (
    <BenefitPaper>{precondition.PreconditionComponent}</BenefitPaper>
  );
};
