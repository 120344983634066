import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorView } from './ErrorView';
interface Props {
  logout?: () => void;
  eventId?: string;
}

export const ErrorFallback = ({ logout, eventId }: Props): JSX.Element => {
  const { t } = useTranslation('errorModule');

  return (
    <ErrorView
      headlineLarge={t('unhandled.headlineLarge')}
      headlineSmall={t('unhandled.headlineSmall')}
      message={t('unhandled.message')}
      eventId={eventId}
    >
      <Button startIcon={<HomeIcon />} color="warning" variant="contained" href="/">
        {t('goHome')}
      </Button>
      {/* Logout Button will only be displayed if there is an after the Auth Provider is initialized */}
      {logout && (
        <Button startIcon={<LogoutIcon />} sx={{ margin: 1 }} color="warning" variant="contained" onClick={logout}>
          {t('navigationModule:logout')}
        </Button>
      )}
    </ErrorView>
  );
};
