import { Paper, SxProps } from '@mui/material';

export interface BenefitTabProps {
  label: string;
  labelIcon?: React.ReactElement;
  value?: string;
  pullRight?: boolean;
  sx?: SxProps;
  count?: number;
  disabled?: boolean;
}

export const BenefitTab: React.FC<React.PropsWithChildren<BenefitTabProps>> = ({ sx, children }) => {
  return (
    <Paper data-test-id="benefit-table" elevation={0} sx={{ padding: 1.5, borderTopLeftRadius: 0, borderTopRightRadius: 0, ...sx }}>
      {children}
    </Paper>
  );
};
