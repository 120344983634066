import { Box } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { InfiniteScrollReactTable } from '../../../component/table/InfiniteScrollReactTable';
import { useLoadMore } from '../../../component/table/useLoadMore';
import { NewsArticlesActions } from './NewsArticlesActions';
import { useNewsArticlesColumns } from './newsArticlesColumns';
import { useInfiniteNewsArticles } from './useNewsArticles';
import { NewsArticleDTO } from 'probonio-shared-ui/api';
import { MRT_Row } from 'material-react-table';

interface Props {
  status: 'ACTIVE' | 'DRAFT' | 'EXPIRED';
}

export const NewsArticlesTable: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation('benefitsModule');
  const navigate = useNavigate();
  const columns = useNewsArticlesColumns();

  const queryResult = useInfiniteNewsArticles({ status });

  const loadMoreProps = useLoadMore(queryResult, 'results');

  const handleRowProps = useCallback(
    ({ row }: { row: MRT_Row<NewsArticleDTO> }) => ({
      onClick: () => {
        navigate(`/benefits/news/addNewArticle/${row.original.id}`, {
          state: { forceHistoryBack: true, article: row.original },
        });
      },
      sx: { cursor: 'pointer' },
    }),
    [navigate],
  );

  return (
    <Box data-test-id="news-contracts-table">
      <InfiniteScrollReactTable
        {...loadMoreProps}
        columns={columns}
        enableSorting={false}
        enablePagination={false}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        enableColumnActions={false}
        enableSortingRemoval={false}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={NewsArticlesActions}
        localization={{ noRecordsToDisplay: t('NEWS.fields.noResults') }}
        muiTableBodyRowProps={handleRowProps}
      />
    </Box>
  );
};
