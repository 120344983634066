import { EmployeeDTO } from 'probonio-shared-ui/api';
import { createContext, useContext } from 'react';

interface ProfileEmployee {
  employee: EmployeeDTO;
  onNavigateBack: () => void;
}

const ProfileEmployeeContext = createContext<ProfileEmployee | null>(null);

export function useProfileEmployee(): ProfileEmployee {
  const context = useContext(ProfileEmployeeContext);

  if (!context) {
    throw new Error('No ProfileEmployeeContext found for component');
  }

  return context;
}

export default ProfileEmployeeContext;
