import { SelectProps, SelectVariants } from '@mui/material';
import React from 'react';
import { SelectorLayout, SelectorTypes } from '../SelectorLayout';
import { useDepartmentSelector } from '../useSelectorQuery';

interface Props extends Omit<SelectProps<string>, 'onChange' | 'variant'> {
  onChange: (department?: string) => void;
  variant?: SelectVariants;
}

export const DepartmentSelector: React.FC<Props> = ({ onChange, sx, value, ...props }) => {
  const data = useDepartmentSelector();

  return (
    <SelectorLayout selectorType={SelectorTypes.DEPARTMENT} selectorData={data} onChange={onChange} sx={sx} value={value} {...props} />
  );
};
