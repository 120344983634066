import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PendingIcon from '@mui/icons-material/Pending';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewDialogState } from '../../component/dialog';
import { ConfirmationModal } from '../../component/dialog/ConfirmationModal';
import { SepaMandateStepper } from './SepaMandateStepper';
import bankTransferIcon from './img/bankTransfer.svg';
import sepaIcon from './img/sepa.svg';

const PaymentAccordion: React.FC<
  React.PropsWithChildren<{
    isActive?: boolean;
    isInProgress?: boolean;
    defaultExpanded?: boolean;
    icon: string;
    title: string;
    infoText: string;
    expanded?: boolean;
    dataTestId?: string;
    onChange?: (event: React.SyntheticEvent, isExpanded: boolean) => void;
    onActivate?: () => void;
  }>
> = ({ isActive, isInProgress, defaultExpanded, icon, title, infoText, expanded, children, dataTestId, onChange, onActivate }) => {
  const { t } = useTranslation('tenantModule');

  const handleActivate = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    event => {
      if (onActivate) {
        event.stopPropagation();
        onActivate();
      }
    },
    [onActivate],
  );

  return (
    <Box marginBottom={2.5}>
      <Accordion defaultExpanded={defaultExpanded} expanded={expanded} onChange={onChange}>
        <AccordionSummary
          expandIcon={(isActive || isInProgress) && onChange ? <ExpandMoreIcon /> : undefined}
          sx={{ '.MuiAccordionSummary-content': { justifyContent: 'space-between', alignItems: 'center' } }}
        >
          <Box width={275} flexShrink={0}>
            <Box width={40} flexShrink={0} display="inline-flex" justifyContent="center" alignItems="center" marginRight={1.5}>
              <img alt="icon" src={icon} style={{ maxHeight: '20px' }} />
            </Box>
            {title}
          </Box>
          <Box>
            <Typography color="text.secondary">{infoText}</Typography>
          </Box>
          <Box width={200} flexShrink={0} display="flex" justifyContent="flex-end" alignItems="center">
            {isActive || isInProgress ? (
              <Box display="flex" justifyContent="center" marginRight={0.5} whiteSpace="nowrap">
                {isInProgress ? (
                  <PendingIcon color="warning" sx={{ marginRight: 0.5 }} />
                ) : (
                  <CheckCircleIcon color="success" sx={{ marginRight: 0.5 }} />
                )}
                {t(isInProgress ? 'paymentMethods.inProgress' : 'paymentMethods.active')}
              </Box>
            ) : (
              <Button size="small" sx={{ height: 36, marginY: '-12px' }} onClick={handleActivate} data-test-id={dataTestId}>
                {t('paymentMethods.activate')}
              </Button>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Box>
  );
};

export const PaymentMethodsPanel: React.FC = () => {
  const { t } = useTranslation('tenantModule');
  const { tenant, invalidateTenant } = useTenant();
  const [expandedPanel, setExpandedPanel] = useState('');
  const confirmBankTranferDialog = useNewDialogState();

  const sepaActive = tenant?.sepa && !!tenant?.sepaMandate;
  const sepaInProgress = tenant?.sepa && !tenant?.sepaMandate;

  useEffect(() => {
    if (tenant?.sepa) {
      setExpandedPanel('sepa');
    } else {
      setExpandedPanel('bankTransfer');
    }
  }, [tenant?.sepa]);

  const bankTransferMutation = useMutation({
    mutationFn: () => apis.sepa.updateTenantSepaMandate({ tenantId: tenant!.id, updateTenantSepaConfigDTO: { sepa: false } }),

    onSuccess: () => {
      confirmBankTranferDialog.dialogState.handleClose();
      invalidateTenant();
    },
  });

  const handleActivateBankTransfer = useCallback(() => {
    bankTransferMutation.mutate();
  }, [bankTransferMutation]);

  const handleExpand = useCallback(
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedPanel(isExpanded ? panel : '');
    },
    [],
  );

  return (
    <>
      <Divider sx={{ marginY: 3 }} />
      <PaymentAccordion
        title={t('paymentMethods.bankTransfer')}
        infoText={t('paymentMethods.bankTransferInfo')}
        icon={bankTransferIcon}
        isActive={!sepaActive}
        onActivate={confirmBankTranferDialog.handleOpen}
        dataTestId="switch-payment-bank-transfer"
      />
      <PaymentAccordion
        title={t('paymentMethods.sepa')}
        infoText={t('paymentMethods.sepaInfo')}
        icon={sepaIcon}
        isActive={sepaActive}
        isInProgress={sepaInProgress}
        expanded={expandedPanel === 'sepa'}
        onChange={handleExpand('sepa')}
        dataTestId="switch-payment-sepa"
      >
        <SepaMandateStepper />
      </PaymentAccordion>
      <ConfirmationModal
        dialogState={confirmBankTranferDialog.dialogState}
        title={t('paymentMethods.confirmChange.title')}
        loading={bankTransferMutation.isPending}
        closeOnConfirm={false}
        onConfirm={handleActivateBankTransfer}
      >
        {t('paymentMethods.confirmChange.text')}
      </ConfirmationModal>
    </>
  );
};
