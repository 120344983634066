import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BenefitPaper } from '../../component/benefitTabs/BenefitPaper';
import { BENEFIT_ICONS } from '../../module/benefits/BenefitIcon';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { ReceiptDelayBenefitSettingsPanel } from '../../module/benefits/lunch/ReceiptDelayBenefitSettingsPanel';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';

export const LunchSettingsPage: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { benefit: benefitName } = useParams<{ benefit: string }>();
  const benefit: BenefitDTOBenefitEnum = benefitName!.toUpperCase() as BenefitDTOBenefitEnum;

  return (
    <AuthorizedLayout title={t('subPage.settings')} breadcrumbs={[{ title: t(`${benefit}.title`), to: `/benefits/${benefitName}` }]}>
      <BenefitSubPage icon={BENEFIT_ICONS.LUNCH} title={`${t('LUNCH.title')} / ${t('MOBILITY.title')}`} subTitle={t('subPage.settings')}>
        <BenefitPaper>
          <ReceiptDelayBenefitSettingsPanel />
        </BenefitPaper>
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};
