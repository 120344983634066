import { MRT_ColumnDef } from 'material-react-table';
import { EmployeeDTO } from 'probonio-shared-ui/api';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AdditionalEmployeeColumns } from '../../employeeStatusList/EmployeeBenefitStatusList';
import { useBikeleasingConditions } from '../useBikeleasingConditions';
import { useBikeleasingEmployeeInformationContext } from './BikeleasingEmployeeInformationContext';

export function useAdditionalBikeleasingColumns(): AdditionalEmployeeColumns {
  const { t } = useTranslation('benefitsModule');
  const { loadForEmployeeIds, employeeMap } = useBikeleasingEmployeeInformationContext();
  const { data } = useBikeleasingConditions();
  const loadMore = useCallback(
    (employees: EmployeeDTO[]) => {
      loadForEmployeeIds(employees.map(employee => employee.id));
    },
    [loadForEmployeeIds],
  );

  return useMemo(() => {
    const columns: MRT_ColumnDef<EmployeeDTO>[] = [
      {
        header: t('BIKELEASING.integration.form.companyCode'),
        id: 'companyCode',
        Cell: ({ row }) =>
          [
            ...new Set(
              employeeMap
                .get(row.original.id)
                ?.map(
                  employeeInformation =>
                    data?.results.find(bikeleasingCondition => bikeleasingCondition.id === employeeInformation.bikeleasingConditionId)
                      ?.companyCode,
                ),
            ),
          ].join(', '),
      },
      {
        header: t('BIKELEASING.integration.form.externalId'),
        id: 'blsExternalId',
        Cell: ({ row }) => (
          <>
            {employeeMap
              .get(row.original.id)
              ?.map(employeeInformation => employeeInformation.externalUsername)
              .join(', ') ?? ''}
          </>
        ),
        enableSorting: false,
      },
    ];
    return { columns, loadAdditionalData: loadMore };
  }, [data?.results, employeeMap, loadMore, t]);
}
