import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { fontWeights } from '../../../theme';
import { HorizontalBox } from '../../../component/layout/BoxLayout';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { BenefitPaper } from '../../../component/benefitTabs/BenefitPaper';

export interface ReportDiagramData {
  validatedCount: number;
  validatedMax: number;
}

interface Props {
  report?: ReportDiagramData;
  isLoading?: boolean;
  reportMonth: DateTime;
}

export const CircularProgressWithLabel: React.FC<{ value: number; loading?: boolean }> = ({ value, loading }) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        value={100}
        size={60}
        thickness={8}
        sx={{ color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800] }}
      />
      <CircularProgress
        variant={loading ? undefined : 'determinate'}
        value={value || 0}
        size={60}
        thickness={8}
        sx={{ position: 'absolute', left: 0 }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {!loading && (
          <Typography variant="body2" component="div" fontWeight={fontWeights.bold} sx={{ lineHeight: 'unset' }}>
            {Number.isNaN(value) ? '-' : `${Math.round(value)}%`}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export const ReportDiagram: React.FC<Props> = ({ report, isLoading, reportMonth }) => {
  const { t } = useTranslation('benefitsModule');

  const progressPercent = (100 * (report?.validatedCount || 0)) / (report?.validatedMax || 0);

  return (
    <BenefitPaper size="sm" sx={{ px: 2 }}>
      <HorizontalBox gap={2} alignItems="center" flexGrow={1}>
        <CircularProgressWithLabel value={progressPercent} loading={isLoading} />
        <Typography variant="h3">{t('report.lodas.title', { reportMonth })}</Typography>
      </HorizontalBox>
    </BenefitPaper>
  );
};
