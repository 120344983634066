import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { BikeContractDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';

export function useDeleteBikeContract(): UseMutationResult<void, Error, Pick<BikeContractDTO, 'tenantId' | 'employeeId' | 'id'>> {
  const getTenantId = useTenantID();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (contract: Pick<BikeContractDTO, 'tenantId' | 'employeeId' | 'id'>) => {
      await apis.bike.deleteContract({ tenantId: contract.tenantId, employeeId: contract.employeeId, contractId: contract.id });
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['tenants', getTenantId(), 'benefits', 'bike', 'contracts'] });
    },
  });
}
