import { AuthorizationError } from '@openid/appauth';
import { AuthenticateOptions, AuthProvider, ErrorAction } from '@tiffinger-thiel/appauth-react';
import { createDesktopAuthHandlers } from 'probonio-shared-ui/module/auth';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function getEnv(name: string): string {
  return (import.meta.env[name] || '').toString();
}

// Checks for the Azure AD B2C specific error codes indicating an invalid registration token
// See https://docs.microsoft.com/en-us/azure/active-directory-b2c/error-codes for list of error codes
// This will have to be adapted for different IdPs
function isRegistrationError(err: unknown): boolean | undefined {
  return (
    err instanceof AuthorizationError &&
    err.error === 'invalid_request' &&
    (err.errorDescription?.includes('AADB2C90208') ||
      err.errorDescription?.includes('AADB2C90209') ||
      err.errorDescription?.includes('AADB2C90210'))
  );
}
function isUserCancelledError(err: unknown): boolean | undefined {
  return err instanceof AuthorizationError && err.error === 'access_denied' && err.errorDescription?.includes('AADB2C90091');
}
function isSocialUserNotFoundError(err: unknown): boolean | undefined {
  return err instanceof AuthorizationError && err.error === 'server_error' && err.errorDescription?.includes('AADB2C99002');
}

const AUTH_OPTIONS: AuthenticateOptions = {
  openIdConnectUrl: getEnv('VITE_AUTH_OPEN_ID_CONNECT'),
  scope: getEnv('VITE_AUTH_SCOPE'),
  clientId: getEnv('VITE_AUTH_CLIENT_ID'),
  redirectUrl: `${location.origin}/oauth`,
};

const { authHandler, endSessionHandler } = createDesktopAuthHandlers();

export const DesktopAuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('authModule');

  const withMessage = useWithMessage(() => t('errorMessage'));
  const navigate = useNavigate();
  const handleError = useCallback(
    (err: unknown, type: ErrorAction) => {
      if (isRegistrationError(err)) {
        // onError callback and isReady state change happen at the same time, causing navigation to
        // the error page to fail. setTimeout is a workaround to enforce an order to the navigate commands.
        // See OAuthPage and RegisterPage for conflicting navigate commands.
        setTimeout(() => {
          navigate('/register/error');
        }, 100);
      } else if (isSocialUserNotFoundError(err)) {
        setTimeout(() => {
          navigate('/socialLoginError');
        }, 100);
      } else if (isUserCancelledError(err)) {
        navigate('/');
      } else if (type === ErrorAction.REFRESH_TOKEN_REQUEST) {
        // token refresh failed, ignore
      } else {
        withMessage(err as Error);
        navigate('/');
      }
    },
    // the updated navigate function (depending on the current location) is only needed for relative urls
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [withMessage],
  );

  return (
    <AuthProvider options={AUTH_OPTIONS} onError={handleError} authHandler={authHandler} endSessionHandler={endSessionHandler}>
      {children}
    </AuthProvider>
  );
};
