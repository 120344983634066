import React from 'react';
import { FullWidthCenterBox, GradientBox } from './BoxLayout';
import { BrandLogo } from '../brand/logo/BrandLogo';
import { Box, Typography, useTheme } from '@mui/material';

interface Props {
  title?: React.ReactNode;
  text?: React.ReactNode;
}

const MessageLayout: React.FC<React.PropsWithChildren<Props>> = ({ children, text, title }) => {
  const theme = useTheme();

  return (
    <GradientBox
      sx={{
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
          alignItems: 'center',
        },
        height: '100vh',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          [theme.breakpoints.up('sm')]: {
            maxWidth: '500px',
            maxHeight: '700px',
          },
          // needed so text wont hide behind backgroundHider
          // used in Parent
          zIndex: 10,
        }}
      >
        <FullWidthCenterBox sx={{ flexGrow: 1 }}>
          <BrandLogo />
        </FullWidthCenterBox>
        <FullWidthCenterBox sx={{ flexGrow: 1, display: 'flex', flexWrap: 'wrap' }}>
          <FullWidthCenterBox>
            <h2>{title}</h2>
          </FullWidthCenterBox>
          <FullWidthCenterBox>
            <Typography color="text.secondary" sx={{ maxWidth: '300px', margin: 2 }}>
              {text}
            </Typography>
          </FullWidthCenterBox>
        </FullWidthCenterBox>
        {children}
      </Box>
    </GradientBox>
  );
};

export default MessageLayout;
