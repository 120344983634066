import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BenefitTab } from '../../component/benefitTabs/BenefitTab';
import { BenefitTabPanel } from '../../component/benefitTabs/BenefitTabPanel';
import { BENEFIT_ICONS } from '../../module/benefits/BenefitIcon';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { CouponsV2SepaAlert } from '../../module/benefits/couponsV2/CouponsV2SepaAlert';
import { TenantGiftsV2Panel } from '../../module/benefits/couponsV2/gifts/TenantGiftsV2Panel';
import { useInfiniteCouponsV2Gifts } from '../../module/benefits/couponsV2/gifts/useInfiniteCouponsV2Gifts';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';

export const CouponsV2GiftsPage: React.FC = () => {
  const { t } = useTranslation('couponsV2Module');

  const { data: pendingData } = useInfiniteCouponsV2Gifts(() => ({ page: 0, pageSize: 0, isDelivered: false }));
  const pendingCount = pendingData?.pages[0].totalCount;
  const { data: deliveredData } = useInfiniteCouponsV2Gifts(() => ({ page: 0, pageSize: 0, isDelivered: true }));
  const deliveredCount = deliveredData?.pages[0].totalCount;

  return (
    <AuthorizedLayout title={t('giftsPage.subTitle')} breadcrumbs={[{ title: t('giftsPage.breadcrumbTitle'), to: '/benefits/coupons_v2' }]}>
      <Box mt={0.5}>
        <CouponsV2SepaAlert />
      </Box>
      <BenefitSubPage icon={BENEFIT_ICONS.COUPONS_V2} title={t('giftsPage.breadcrumbTitle')} subTitle={t('giftsPage.subTitle')}>
        <BenefitTabPanel>
          <BenefitTab label={t('giftsPage.tab.pending')} count={pendingCount}>
            <TenantGiftsV2Panel isDelivered={false} />
          </BenefitTab>
          <BenefitTab label={t('giftsPage.tab.delivered')} count={deliveredCount}>
            <TenantGiftsV2Panel isDelivered />
          </BenefitTab>
        </BenefitTabPanel>
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};
