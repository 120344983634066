import { DateTime } from 'luxon';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useMemo } from 'react';

import {
  BenefitActivationDTO,
  CouponGiftDTO,
  CouponGiftDTOStatusEnum,
  CouponsV2GiftDTO,
  CouponsV2GiftDTOStatusEnum,
} from 'probonio-shared-ui/api';

import { useProfileEmployee } from './useProfileEmployee';
import { useBenefitActivations } from '../../../../benefits/ActivationsList/useBenefitActivations';
import { useCouponsV2Gifts } from '../../../../benefits/couponsV2/couponsV2Queries';
import { BENEFIT_ORDER } from '../../../../benefits/BenefitIcon';

function splitActivations(activations: BenefitActivationDTO[]): {
  activations: BenefitActivationDTO[];
  upcomingActivations: BenefitActivationDTO[];
} {
  const accActivations: BenefitActivationDTO[] = activations.filter(act => !isUpcoming(act));
  const upcomingActivations: BenefitActivationDTO[] = activations.filter(act => isUpcoming(act));

  return { activations: accActivations, upcomingActivations };
}

export function isUpcoming(activation: BenefitActivationDTO): boolean {
  const today = DateTime.utc().endOf('day');
  const startDate = DateTime.fromISO(activation.startDate);

  return startDate > today;
}

export function isActive(activation: BenefitActivationDTO): boolean {
  const today = DateTime.utc();
  const startDate = DateTime.fromISO(activation.startDate);
  const endDate = activation.endDate ? DateTime.fromISO(activation.endDate).endOf('day') : undefined;

  return startDate < today && (!endDate || endDate > today);
}

interface UserProfileBenefits {
  activations: BenefitActivationDTO[];
  upcomingActivations: BenefitActivationDTO[];
  gifts: CouponGiftDTO[];
  v2Gifts: CouponsV2GiftDTO[];
  totalActivationsCount: number;
  isLoading: boolean;
}

export const useEmployeeActivations = (): UserProfileBenefits => {
  const { employee } = useProfileEmployee();

  const { data: activationsData, isLoading: isActivationLoading } = useBenefitActivations(employee.id, {
    startDate: DateTime.utc().startOf('month').toJSDate(),
  });
  const { data: v2GiftsData, isLoading: isV2GiftsLoading } = useCouponsV2Gifts(employee.id, {});
  const { data: giftsData, isLoading: isGiftsLoading } = useTenantQuery(
    ['employees', employee.id, 'benefits', 'coupons', 'gifts', apis.coupons.listCouponGifts.name],
    tenantId => apis.coupons.listCouponGifts({ employeeId: employee.id, tenantId }).then(res => res.data),
  );

  const gifts = useMemo(
    () =>
      giftsData?.couponGifts
        .filter(gift => gift.status === CouponGiftDTOStatusEnum.Pending || gift.status === CouponGiftDTOStatusEnum.PaymentFailed)
        .sort((a, b) => new Date(a.payoutDate).getTime() - new Date(b.payoutDate).getTime()) || [],
    [giftsData?.couponGifts],
  );

  const v2Gifts = useMemo(
    () =>
      v2GiftsData?.results
        .filter(gift => gift.status === CouponsV2GiftDTOStatusEnum.Pending || gift.status === CouponsV2GiftDTOStatusEnum.InvoiceCreated)
        .sort((a, b) => new Date(a.payoutDate).getTime() - new Date(b.payoutDate).getTime()) || [],
    [v2GiftsData?.results],
  );
  const benefitActivations =
    activationsData?.benefitActivations.sort((a, b) => BENEFIT_ORDER.indexOf(a.benefit) - BENEFIT_ORDER.indexOf(b.benefit)) || [];

  const { activations, upcomingActivations } = splitActivations(benefitActivations);
  const totalActivationsCount = activations.length + upcomingActivations.length + gifts.length + v2Gifts.length;

  return useMemo(() => {
    return {
      activations,
      upcomingActivations,
      gifts: gifts,
      v2Gifts: v2Gifts,
      totalActivationsCount,
      isLoading: isActivationLoading || isV2GiftsLoading || isGiftsLoading,
    };
  }, [activations, gifts, isActivationLoading, isGiftsLoading, isV2GiftsLoading, totalActivationsCount, upcomingActivations, v2Gifts]);
};
