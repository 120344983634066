import { CouponsV2GiftDTO, CouponsV2GiftDTOGiftTypeEnum } from 'probonio-shared-ui/api';
import { createContext, useContext } from 'react';

interface V2GiftActivationModal {
  onV2GiftCreate: (giftType: CouponsV2GiftDTOGiftTypeEnum) => void;
  onV2GiftDelete: (activation: CouponsV2GiftDTO) => void;
  onV2GiftEdit: (gift: CouponsV2GiftDTO) => void;
}

const V2GiftActivationModalContext = createContext<V2GiftActivationModal | null>(null);

export function useV2GiftActivationModal(): V2GiftActivationModal {
  const context = useContext(V2GiftActivationModalContext);

  if (!context) {
    throw new Error('No ProfileActivationActions found for component');
  }

  return context;
}

export default V2GiftActivationModalContext;
