import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { ApplyBenefitTemplateDTO, BulkApplyBenefitTemplateDTO, FilterEmployeesDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RowSelectionState } from '../../../component/table/useManualRowSelection';
import { useRefetchEmployees } from '../../userManagement/query';
import { EmployeeFilter, mapEmployeeFilterToRequest } from '../employeeStatusList/employeeFilterState';
import { useRefetchActivations } from '../query';

export function useBulkApplyTemplateMutation(
  rowSelectionState: RowSelectionState,
  filter?: EmployeeFilter,
): { onApplyTemplate: (applyTemplate: ApplyBenefitTemplateDTO) => Promise<void> } {
  const { t } = useTranslation('benefitsModule');
  const getTenantId = useTenantID();
  const refetchEmployees = useRefetchEmployees();
  const refetchActivations = useRefetchActivations();
  const { enqueueSnackbar } = useSnackbar();

  const bulkApplyTemplateMutation = useMutation({
    mutationFn: (params: BulkApplyBenefitTemplateDTO) =>
      apis.benefits.bulkApplyBenefitTemplate({ tenantId: getTenantId(), bulkApplyBenefitTemplateDTO: params }),

    onSuccess: async () => {
      await refetchEmployees();
      await refetchActivations();
    },
  });

  const handleApplyTemplate = useCallback(
    async (applyTemplate: ApplyBenefitTemplateDTO) => {
      let employeeFilter: FilterEmployeesDTO | undefined;
      let employeeIds: string[] | undefined;
      if (rowSelectionState.allSelected) {
        employeeFilter = {
          ...mapEmployeeFilterToRequest(filter),
          considerFutureActivations: true,
          excludeEmployeeStatus: ['INACTIVE'],
        };
      } else {
        employeeIds = Object.keys(rowSelectionState.rowSelection).filter(id => rowSelectionState.rowSelection[id]);
      }
      const { data: benefitActivationResponse } = await bulkApplyTemplateMutation.mutateAsync({
        employeeFilter,
        employeeIds,
        applyTemplate,
      });
      const successResults = benefitActivationResponse.results.filter(result => result.status === 'SUCCESS');
      const errorResults = benefitActivationResponse.results.filter(result => result.status === 'ERROR');
      if (benefitActivationResponse.results.length === 0) {
        enqueueSnackbar(t('bulkApplyTemplate.noNewActivationsDone'), { variant: 'warning' });
      } else if (errorResults.length && successResults.length) {
        enqueueSnackbar(
          t('bulkApplyTemplate.newActivationsSomeErrors', { successCount: successResults.length, errorCount: errorResults.length }),
          { variant: 'warning' },
        );
      } else if (errorResults.length) {
        enqueueSnackbar(t('bulkApplyTemplate.newActivationsError', { count: errorResults.length }), { variant: 'error' });
      } else {
        enqueueSnackbar(t('bulkApplyTemplate.newActivationsSuccess', { count: successResults.length }), { variant: 'success' });
      }

      if (successResults.length) {
        rowSelectionState.handleReset();
      }
    },
    [bulkApplyTemplateMutation, enqueueSnackbar, filter, rowSelectionState, t],
  );

  return useMemo(
    () => ({
      onApplyTemplate: handleApplyTemplate,
    }),
    [handleApplyTemplate],
  );
}
