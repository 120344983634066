import { BenefitActivationDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useCallback, useState } from 'react';
import { ActivationDialog, useActivationDialogState } from '..';
import { useNewDialogState } from '../../../component/dialog';
import { RowSelectionState } from '../../../component/table/useManualRowSelection';
import { BulkEditActivationsDialog } from '../ActivationDialog/BulkEditActivationsDialog';
import { useBoundActivationComponent } from '../ActivationDialog/useBoundActivationComponent';
import { BenefitProvider } from '../BenefitContext';
import { BenefitRef } from '../BenefitsCell';
import { EmployeeFilter } from '../employeeStatusList/employeeFilterState';
import { useBulkActivationSaveFunctions } from '../employeeStatusList/useBulkActivationSaveFunctions';
import BulkBenefitActivationModalContext from './useBulkBenefitActivationModal';
import { ApplyTemplateDialog } from '../ApplyTemplateDialog';
import { useBulkApplyTemplateMutation } from './useBulkApplyTemplateMutation';

interface Props {
  rowSelectionState: RowSelectionState;
  employeeFilter?: EmployeeFilter;
}

export const BulkBenefitActivationModalProvider: React.FC<React.PropsWithChildren<Props>> = ({
  rowSelectionState,
  employeeFilter,
  children,
}) => {
  const activationDialog = useActivationDialogState();
  const editDialog = useNewDialogState();
  const applyTemplateDialog = useNewDialogState();

  const [benefitRef, setBenefitRef] = useState<BenefitRef | undefined>();

  const ActivationDialogComponent = useBoundActivationComponent(ActivationDialog, benefitRef?.benefit);
  const activationSaveFunctions = useBulkActivationSaveFunctions(rowSelectionState, employeeFilter);
  const { onApplyTemplate } = useBulkApplyTemplateMutation(rowSelectionState, employeeFilter);

  const handleOpenActivationCreate = useCallback(
    (benefit: BenefitActivationDTOBenefitEnum, customBenefitId?: string) => {
      setBenefitRef({ benefit, customBenefitId });
      activationDialog.handleOpenDialogToCreate();
    },
    [activationDialog],
  );

  const handleOpenActivationEdit = useCallback(
    (benefit: BenefitActivationDTOBenefitEnum, customBenefitId?: string) => {
      setBenefitRef({ benefit, customBenefitId });
      editDialog.handleOpen();
    },
    [editDialog],
  );

  const handleOpenApplyTemplate = useCallback(() => {
    applyTemplateDialog.handleOpen();
  }, [applyTemplateDialog]);

  return (
    <BulkBenefitActivationModalContext.Provider
      value={{
        onBenefitCreate: handleOpenActivationCreate,
        onBenefitEdit: handleOpenActivationEdit,
        onApplyTemplate: handleOpenApplyTemplate,
      }}
    >
      <BenefitProvider benefit={benefitRef?.benefit!} customBenefitId={benefitRef?.customBenefitId} showLoadingIndicator={false}>
        <ActivationDialogComponent saveFunctions={activationSaveFunctions} activationDialogState={activationDialog} />
        <BulkEditActivationsDialog
          dialogState={editDialog.dialogState}
          employeeFilter={employeeFilter || {}}
          rowSelectionState={rowSelectionState}
        />
      </BenefitProvider>
      <ApplyTemplateDialog dialogState={applyTemplateDialog.dialogState} onApply={onApplyTemplate} />
      {children}
    </BulkBenefitActivationModalContext.Provider>
  );
};
