import { Box, Typography } from '@mui/material';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContentEditor } from '../../../../../../component/contentEditor/ContentEditor';
import { VerticalBox } from '../../../../../../component/layout/BoxLayout';
import { HelpContentValidation } from './HelpContentValidation';
import { FormValues } from '../../../useCustomBenefitCreator';

interface Props {
  setCanContinue: (newValue: boolean) => void;
  control: Control<FormValues>;
}

export const HelpContent: React.FC<Props> = ({ control, setCanContinue }) => {
  const { t } = useTranslation('customBenefitsModule');

  return (
    <>
      <VerticalBox gap={3} height="100%" maxHeight="100%">
        <Box>
          <Typography variant="h2">{t('customBenefitCreator.content.textStep.title')}</Typography>
          <Typography variant="caption">{t('customBenefitCreator.content.textStep.description')}</Typography>
        </Box>
        <Box>
          <ContentEditor control={control} name="html" />
        </Box>
      </VerticalBox>
      <HelpContentValidation setCanContinue={setCanContinue} />
    </>
  );
};
