import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { NewsArticleDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';

export function useExpireNewsArticle(): UseMutationResult<void, Error, Pick<NewsArticleDTO, 'tenantId' | 'employeeId' | 'id'>> {
  const getTenantId = useTenantID();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (article: Pick<NewsArticleDTO, 'tenantId' | 'employeeId' | 'id'>) => {
      await apis.news.expireNewsArticle({ tenantId: article.tenantId, articleId: article.id });
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['tenants', getTenantId(), 'benefits', 'news', 'articles'] });
    },
  });
}
