import { EmployeeDTO } from 'probonio-shared-ui/api';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AdditionalEmployeeColumns } from '../employeeStatusList/EmployeeBenefitStatusList';

export function useAdditionalBAVColumns(): AdditionalEmployeeColumns {
  const { t } = useTranslation('benefitsModule');

  return useMemo(
    () => ({
      columns: [
        {
          accessorFn: (employee: EmployeeDTO) => (employee?.bavNotInterested ? t('employeeTabs.confirmed') : ''),
          header: t('employeeTabs.header.notInterested'),
          enableSorting: false,
        },
      ],
    }),
    [t],
  );
}
