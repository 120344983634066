import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { UpsertBikeContractDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { useTranslation } from 'react-i18next';

interface ConflictResponse {
  data?: {
    employeeNumber?: string;
  };
}

export function useUpsertContractMutation(): UseMutationResult<void, Error, { contractNumber: string; contract: UpsertBikeContractDTO }> {
  const { t } = useTranslation('bikeModule');
  const getTenantId = useTenantID();
  const withMessage = useWithMessage((err: Error) => {
    if ((err as AxiosError).response?.status === 422) {
      const responseData = (err as AxiosError).response?.data as ConflictResponse;
      if (responseData?.data?.employeeNumber) {
        return t('importDialog.conflictError', { employeeNumber: responseData?.data?.employeeNumber });
      }
    }
  });

  return useMutation({
    mutationFn: async (data: { contractNumber: string; contract: UpsertBikeContractDTO }) => {
      await apis.bike.upsertContract({
        tenantId: getTenantId(),
        contractNumber: data.contractNumber,
        upsertBikeContractDTO: data.contract,
      });
    },
    onError: withMessage,
  });
}
