import { Alert, Button, MenuItem, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  BenefitActivationDTO,
  BenefitDTOBenefitEnum,
  BenefitTemplateItemDTO,
  CreateBenefitActivationDTO,
  UpdateBenefitActivationDTO,
} from 'probonio-shared-ui/api';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SelectControl } from '../../../component/form';
import { ActivationDialogExtensions } from '../ActivationDialog';
import { SelectedBikeleasingCondition } from './SelectedBikeleasingCondition';
import { useBikeleasingConditions } from './useBikeleasingConditions';

interface BikeLeasingBenefitOptions {
  bikeleasingConditionId?: string;
  bikeleasingCompanyCode?: string;
}

const Fields: React.FC = () => {
  const { data: bikeleasingConditionList } = useBikeleasingConditions();

  const { t } = useTranslation('benefitsModule');
  const { control, watch, setValue } = useFormContext();
  const { bikeleasingConditionId }: BikeLeasingBenefitOptions = watch('benefitOptions');
  const selectedCondition = useMemo(
    () =>
      bikeleasingConditionList?.results.find(condition => condition.id === bikeleasingConditionId) || bikeleasingConditionList?.results[0],
    [bikeleasingConditionId, bikeleasingConditionList?.results],
  );

  useEffect(() => {
    setValue('benefitOptions.bikeleasingCompanyCode', selectedCondition?.companyCode);
    setValue('benefitOptions.bikeleasingConditionId', selectedCondition?.id);
  }, [selectedCondition?.companyCode, selectedCondition?.id, setValue]);

  return (
    <>
      <Grid container columnSpacing={2} rowSpacing={1} marginTop={1} columns={10} mb={1}>
        <Grid size={10}>
          {!selectedCondition && (
            <Stack sx={{ marginBottom: 2 }}>
              <Alert
                severity="warning"
                action={
                  <Button
                    component={Link}
                    to="/benefits/bikeleasing/integration"
                    sx={{ alignSelf: 'center' }}
                    variant="text"
                    color="inherit"
                    size="small"
                  >
                    {t('BIKELEASING.conditionDetailDialog.alertAction')}
                  </Button>
                }
              >
                {t('BIKELEASING.conditionDetailDialog.alert')}
              </Alert>
            </Stack>
          )}
          <SelectControl
            defaultValue=""
            control={control}
            name="benefitOptions.bikeleasingConditionId"
            label={t('BIKELEASING.fields.condition')}
            fullWidth
            displayEmpty={true}
            margin="dense"
            rules={{ required: true }}
          >
            {!selectedCondition && <MenuItem>{t('BIKELEASING.conditionDetailDialog.noOptions')}</MenuItem>}
            {bikeleasingConditionList?.results.map(condition => (
              <MenuItem key={condition.id} value={condition.id} data-test-id={`option-${condition.id}`}>
                <Typography>{t('BIKELEASING.conditionDetailDialog.title', { companyCode: condition.companyCode })}</Typography>
              </MenuItem>
            ))}
          </SelectControl>
        </Grid>
      </Grid>
      <SelectedBikeleasingCondition bikeleasingCondition={selectedCondition} />
    </>
  );
};

export function useBikeleasingDialogExtensions(): ActivationDialogExtensions<BikeLeasingBenefitOptions> {
  const handleResetBenefitOptions = useCallback(
    (selectedActivation?: BenefitActivationDTO | BenefitTemplateItemDTO): BikeLeasingBenefitOptions => {
      const bikeleasingConditionId = selectedActivation?.bikeleasingOptions?.bikeleasingConditionId;
      const bikeleasingCompanyCode = selectedActivation?.bikeleasingOptions?.bikeleasingCompanyCode;
      return { bikeleasingConditionId, bikeleasingCompanyCode };
    },
    [],
  );

  const handleCreateActivation = useCallback<ActivationDialogExtensions<BikeLeasingBenefitOptions>['onCreateActivation']>(
    async (baseValues, bikeleasingOptions, saveActivation) => {
      const create: CreateBenefitActivationDTO = {
        ...baseValues,
        benefit: BenefitDTOBenefitEnum.Bikeleasing,
        bikeleasingOptions,
      };
      await saveActivation(create);
    },
    [],
  );

  const handleUpdateActivation = useCallback<NonNullable<ActivationDialogExtensions<BikeLeasingBenefitOptions>['onUpdateActivation']>>(
    async (baseValues, bikeleasingOptions, saveActivation) => {
      const update: UpdateBenefitActivationDTO = {
        ...baseValues,
        bikeleasingOptions,
      };
      await saveActivation(update);
    },
    [],
  );
  return useMemo(
    () => ({
      Fields,
      onResetBenefitOptions: handleResetBenefitOptions,
      onCreateActivation: handleCreateActivation,
      onUpdateActivation: handleUpdateActivation,
    }),
    [handleCreateActivation, handleResetBenefitOptions, handleUpdateActivation],
  );
}
