import { MRT_Row } from 'material-react-table';
import { CouponsV2GiftDTO } from 'probonio-shared-ui/api';
import React, { useCallback, useState } from 'react';
import { ThemedMaterialReactTable } from '../../../../component/table/ThemedMaterialReactTable';
import { V2GiftActivationActions } from '../../../userManagement/UserProfile/activations/actions/V2GiftActivationActions';
import { V2GiftActivationModalProvider } from '../../../userManagement/UserProfile/activations/modals/V2GiftActivationModalProvider';
import { useCouponsV2Gifts } from '../couponsV2Queries';
import { useGiftsV2Columns } from './giftsV2Columns';

interface Props {
  employeeId: string;
}

const PAGE_SIZE = 10;

export const GiftsV2List: React.FC<Props> = ({ employeeId }) => {
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: PAGE_SIZE });

  const { data, isFetching } = useCouponsV2Gifts(employeeId, { page: pagination.pageIndex, pageSize: pagination.pageSize });

  const COLUMNS = useGiftsV2Columns();

  const renderActions = useCallback(({ row }: { row: MRT_Row<CouponsV2GiftDTO> }) => <V2GiftActivationActions v2Gift={row.original} />, []);

  return (
    <V2GiftActivationModalProvider>
      <ThemedMaterialReactTable
        enableTopToolbar={false}
        enableColumnActions={false}
        // if we want sorting, we need to pass it to the api for proper sorting over more pages
        enableSorting={false}
        manualPagination
        onPaginationChange={setPagination}
        data={data?.results || []}
        columns={COLUMNS}
        rowCount={data?.totalCount || 0}
        state={{ pagination, isLoading: isFetching }}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={renderActions}
      />
    </V2GiftActivationModalProvider>
  );
};
