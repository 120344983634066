import { DateTime, Interval } from 'luxon';
import { LunchReceiptTenantDTO, MobilityReceiptTenantDTO, RecreationReceiptTenantDTO } from 'probonio-shared-ui/api';

export const mapReceiptStatus = (
  receipt: LunchReceiptTenantDTO | MobilityReceiptTenantDTO | RecreationReceiptTenantDTO,
): {
  status: string;
  color: string;
} => {
  const status = receipt.status;
  let color = 'success.main';
  if (receipt.status === 'PENDING') {
    color = 'warning.main';
  } else if (receipt.status === 'REJECTED') {
    color = 'error.main';
  }
  return { status, color };
};

export function getValidityMonths(validityStartDate?: string, validityEndDate?: string): number | undefined {
  if (validityStartDate && validityEndDate) {
    return (DateTime.fromISO(validityStartDate).until(DateTime.fromISO(validityEndDate).plus({ months: 1 })) as Interval).length('months');
  }
}
