import { Box, Breadcrumbs, Divider, Drawer, styled, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import SimpleBar from 'simplebar-react';
import { useMainDrawer } from '../../component/drawer/MainDrawerContext';
import { Link } from 'react-router-dom';
import { BrandLogo } from '../../component/brand/logo/BrandLogo';

export const MAIN_MENU_WIDTH = 280;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

interface Props {
  footer?: React.ReactNode;
  children?: React.ReactNode;
}

export const MainMenuDrawer = React.memo<Props>(({ footer, children }) => {
  const drawerState = useMainDrawer();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xl'));

  return (
    <Drawer
      data-test-id="main-menu-drawer"
      variant={isMobile ? 'temporary' : 'permanent'}
      anchor="left"
      open={drawerState.open}
      onClose={drawerState.handleClose}
      sx={{ width: MAIN_MENU_WIDTH, flexShrink: 0, '& .MuiDrawer-paper': { overflowY: 'unset', borderRight: 'none' } }}
    >
      <Box display="flex" flexDirection="column" sx={{ width: MAIN_MENU_WIDTH, height: '100%' }} role="presentation">
        <DrawerHeader sx={{ borderRight: isMobile ? undefined : `1px solid ${theme.palette.divider}` }}>
          <Box flexGrow={1}>
            <Link to="/">
              <BrandLogo plain height={45} />
            </Link>
          </Box>
        </DrawerHeader>
        {isMobile ? (
          <Divider />
        ) : (
          <Breadcrumbs>
            <span>&nbsp;</span>
          </Breadcrumbs>
        )}
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          minHeight={0}
          borderRight={isMobile ? undefined : `1px solid ${theme.palette.divider}`}
        >
          <SimpleBar tabIndex={-1} style={{ flexGrow: 1, minHeight: 0 }}>
            {children}
          </SimpleBar>
          <Divider />
          {footer}
        </Box>
      </Box>
    </Drawer>
  );
});
