import { Typography } from '@mui/material';
import { BenefitDTOBenefitEnum, BenefitUpsellingDTO, CustomBenefitDTO } from 'probonio-shared-ui/api';
import { BENEFIT_PICTURES } from 'probonio-shared-ui/component/button';
import { CUSTOM_BENEFIT_ICONS } from 'probonio-shared-ui/component/icon';
import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DashboardCarouselButton } from '../../component/carousel/DashboardCarouselButton';
import { useNewDialogState } from '../../component/dialog';
import { SupportDialog } from '../../component/support';
import { BENEFIT_ICONS } from '../benefits/BenefitIcon';
import { CustomBenefitTemplateKeys, CustomBenefitTemplates } from '../benefits/customBenefit/customBenefitTemplates';
import { useBAVContracts } from '../benefits/bav/useBAVContracts';
import { useBikeContracts } from '../benefits/bike/useBikeContracts';
import { useNewsArticles } from '../benefits/news/useNewsArticles';
import { useTenant } from 'probonio-shared-ui/module/me';

interface BenefitProps {
  benefit: BenefitDTOBenefitEnum;
  grant: number;
  savings: number;
}

const BAVContent: React.FC = () => {
  const { data: contractList } = useBAVContracts({ page: 0, pageSize: 0 }, { staleTime: Infinity });

  return (
    <Trans i18nKey="dashboardModule:benefitsPanel.benefitContent.BAV" values={{ count: contractList?.totalCount || 0 }}>
      <Typography variant="inherit" component="span" color="success.main" fontWeight="bold" />
    </Trans>
  );
};

const BikeContent: React.FC = () => {
  const { data: contractList } = useBikeContracts({ page: 0, pageSize: 0 }, { staleTime: Infinity });

  return (
    <Trans i18nKey="dashboardModule:benefitsPanel.benefitContent.BIKE" values={{ count: contractList?.totalCount || 0 }}>
      <Typography variant="inherit" component="span" color="success.main" fontWeight="bold" />
    </Trans>
  );
};

const NewsContent: React.FC = () => {
  const { data: newsArticles } = useNewsArticles({ page: 0, pageSize: 0, status: 'ACTIVE' });

  return (
    <Trans i18nKey="dashboardModule:benefitsPanel.benefitContent.NEWS" values={{ count: newsArticles?.totalCount || 0 }}>
      <Typography variant="inherit" component="span" color="success.main" fontWeight="bold" />
    </Trans>
  );
};

export const DashboardBenefitButton: React.FC<BenefitProps> = ({ benefit, grant, savings }) => {
  const { t } = useTranslation('dashboardModule');
  const navigate = useNavigate();
  const { tenant } = useTenant();

  const handleNavigate = useCallback(() => {
    let target = benefit.toLowerCase();
    // if FLEX benefit is active, go to flex page instead of individual benefit page
    if (tenant?.flexBenefits?.includes(benefit)) {
      target = 'flex';
    }
    navigate(`/benefits/${target}`);
  }, [benefit, navigate, tenant?.flexBenefits]);

  const defaultContent = (
    <>
      <Typography component="div" noWrap variant="inherit">
        <Trans i18nKey="dashboardModule:benefitsPanel.benefitGrant" values={{ amount: grant }}>
          <strong />
        </Trans>
      </Typography>
      <Typography component="div" noWrap variant="inherit">
        <Trans i18nKey="dashboardModule:benefitsPanel.benefitSavings" values={{ amount: savings }}>
          <Typography variant="inherit" component="span" color="success.main" fontWeight="bold" />
        </Trans>
      </Typography>
    </>
  );

  let content = defaultContent;
  if (benefit === BenefitDTOBenefitEnum.Bav) {
    content = <BAVContent />;
  } else if (benefit === BenefitDTOBenefitEnum.Bike) {
    content = <BikeContent />;
  } else if (benefit === BenefitDTOBenefitEnum.News) {
    content = <NewsContent />;
  }

  return (
    <DashboardCarouselButton
      title={t(`common:benefit.${benefit}`)}
      icon={BENEFIT_ICONS[benefit]}
      imageUrl={BENEFIT_PICTURES[benefit]}
      onClick={handleNavigate}
      dataTestId={`${benefit.toLowerCase()}-dashboard-button`}
    >
      {content}
    </DashboardCarouselButton>
  );
};

interface CustomBenefitProps {
  customBenefit: CustomBenefitDTO;
}

export const DashboardCustomBenefitButton: React.FC<CustomBenefitProps> = ({ customBenefit }) => {
  const { t } = useTranslation('dashboardModule');
  const navigate = useNavigate();

  const handleNavigate = useCallback(() => {
    navigate(`/benefits/custom/${customBenefit.id}`);
  }, [customBenefit.id, navigate]);

  return (
    <DashboardCarouselButton
      title={customBenefit.title}
      icon={CUSTOM_BENEFIT_ICONS[customBenefit.iconName]}
      imageUrl={customBenefit.tileImageURL!}
      onClick={handleNavigate}
    >
      {t('customBenefitsModule:configureButtonHint')}
    </DashboardCarouselButton>
  );
};

interface BenefitUpsellingProps {
  upselling: BenefitUpsellingDTO;
}

export const DashboardBenefitUpsellingButton: React.FC<BenefitUpsellingProps> = ({ upselling }) => {
  const { t } = useTranslation('dashboardModule');

  const customBenefitTemplate = upselling.customBenefitTemplateKey
    ? CustomBenefitTemplates.get(upselling.customBenefitTemplateKey as CustomBenefitTemplateKeys)
    : undefined;

  const title = customBenefitTemplate?.title || t(`common:benefit.${upselling.benefit}`);

  const supportDialog = useNewDialogState();

  return (
    <>
      <DashboardCarouselButton
        faded
        title={title}
        icon={customBenefitTemplate ? CUSTOM_BENEFIT_ICONS[customBenefitTemplate.iconValue] : BENEFIT_ICONS[upselling.benefit]}
        imageUrl={customBenefitTemplate?.imageUrl || BENEFIT_PICTURES[upselling.benefit]}
        ribbonText={t('benefitsPanel.bookNow')}
        onClick={supportDialog.handleOpen}
      >
        <Trans i18nKey="dashboardModule:benefitsPanel.upsellingRate" values={{ value: upselling.upsellingRate }}>
          <Typography variant="inherit" component="span" color="success.main" fontWeight="bold" />
        </Trans>
      </DashboardCarouselButton>
      <SupportDialog dialogState={supportDialog.dialogState} />
    </>
  );
};
