import { Paper, Alert, Button } from '@mui/material';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const CouponsV2SepaAlert: React.FC = () => {
  const { t } = useTranslation('couponsV2Module');
  const { tenant } = useTenant();

  const sepaActive = tenant?.sepa === true && tenant?.sepaMandate ? true : false;

  return sepaActive ? null : (
    <Paper elevation={0}>
      <Alert
        severity="warning"
        action={
          <Button component={Link} to="/settings/payment" variant="text" color="inherit" size="small" sx={{ marginY: -0.5 }}>
            {t('navigationModule:menuItem.tenantSettings')}
          </Button>
        }
      >
        {t('budgetPage.alertMessage')}
      </Alert>
    </Paper>
  );
};
