import { ChartConfiguration, ChartData } from 'chart.js';
import { DateTime } from 'luxon';
import { FinancialReportEntryDTO } from 'probonio-shared-ui/api';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactChart } from '../../../component/chart';
import { CHART_COLORS, gradientFill } from './chartGradient';
import { BENEFIT_ORDER } from '../../benefits/BenefitIcon';

interface Props {
  reportData: FinancialReportEntryDTO[];
}

// eslint-disable-next-line @rushstack/no-new-null
const isDefined = <T extends unknown>(arg: T | null | undefined): arg is T => arg !== null && arg !== undefined;

export const BenefitGrantChart: React.FC<Props> = ({ reportData }) => {
  const { t, i18n } = useTranslation('dashboardModule');

  const data = useMemo((): ChartData<'line', number[]> => {
    const sortedData = BENEFIT_ORDER.map(benefit => reportData.find(reportEntry => reportEntry.benefit === benefit)).filter(isDefined);
    return {
      labels: (sortedData[0]?.grantHistory || Array.from({ length: 7 })).map(
        (value, index, array) =>
          DateTime.now()
            .minus({ months: array.length - index - 1 })
            .setLocale(i18n.resolvedLanguage!).monthLong,
      ),
      datasets: sortedData
        .filter(reportEntry => !!reportEntry.grant)
        .map((reportEntry, index) => ({
          label: t(`common:benefit.${reportEntry.benefit}`),
          data: reportEntry.grantHistory,
          backgroundColor: gradientFill(index, CHART_COLORS[index]),
          fill: true,
          pointRadius: 0,
          cubicInterpolationMode: 'monotone',
          tension: 0.4,
        })),
    };
  }, [i18n.resolvedLanguage, reportData, t]);

  const config = useMemo(
    (): ChartConfiguration<'line', number[]> => ({
      type: 'line',
      data: data,
      options: {
        aspectRatio: 16 / 9,
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false,
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            stacked: true,
            ticks: {
              callback: tickValue => t('benefitsPanel.chart.scaleFormat', { value: tickValue }),
            },
          },
        },
        plugins: {
          legend: {
            onHover: event => {
              if (event.native?.target instanceof HTMLElement) {
                event.native.target.style.cursor = 'pointer';
              }
            },
            onLeave: event => {
              if (event.native?.target instanceof HTMLElement) {
                event.native.target.style.cursor = 'default';
              }
            },
            labels: {
              boxWidth: 10,
              boxHeight: 10,
              pointStyle: 'circle',
              usePointStyle: true,
            },
          },
          filler: {},
          tooltip: {
            callbacks: {
              label: tooltipItem => t('benefitsPanel.chart.tooltipItem', { label: tooltipItem.dataset.label, value: tooltipItem.parsed.y }),
              footer: tooltipItems => {
                const sum = tooltipItems.reduce((sum, tooltipItem) => sum + tooltipItem.parsed.y, 0);
                return t('benefitsPanel.chart.tooltipTotal', { value: sum });
              },
            },
          },
        },
      },
    }),
    [data, t],
  );

  return <ReactChart config={config} sx={{ height: '100%', width: '100%' }} />;
};
