import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TutorialStepDefinition } from '../TutorialStep';

export function usePayrollReportStep(): TutorialStepDefinition {
  const { t } = useTranslation('tutorialModule');
  const { tenant } = useTenant();

  const loading = !tenant;
  const completed = !!tenant?.lodasExportType;

  return useMemo(
    () => ({
      requiredBenefits: [
        BenefitDTOBenefitEnum.Coupons,
        BenefitDTOBenefitEnum.CouponsV2,
        BenefitDTOBenefitEnum.Lunch,
        BenefitDTOBenefitEnum.Mobility,
        BenefitDTOBenefitEnum.Internet,
      ],
      ...(t('steps.payrollReport', { returnObjects: true }) as {
        label: string;
        description: string;
        help: string;
        buttonText: string;
      }),
      path: '/',
      loading,
      completed,
    }),
    [completed, loading, t],
  );
}
