import { MRT_Row } from 'material-react-table';
import { CouponGiftDTO } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import React, { useCallback, useState } from 'react';
import { ThemedMaterialReactTable } from '../../../../component/table/ThemedMaterialReactTable';
import { GiftActivationActions } from '../../../userManagement/UserProfile/activations/actions/GiftActivationActions';
import { GiftActivationModalProvider } from '../../../userManagement/UserProfile/activations/modals/GiftActivationModalProvider';
import { useGiftsColumns } from './giftsColumns';

interface Props {
  employeeId: string;
}

const PAGE_SIZE = 10;

export const GiftsList: React.FC<Props> = ({ employeeId }) => {
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: PAGE_SIZE });

  const { data, isFetching } = useTenantQuery(
    [
      'employees',
      employeeId,
      'benefits',
      'coupons',
      'gifts',
      { page: pagination.pageIndex, pageSize: pagination.pageSize },
      apis.coupons.listCouponGifts.name,
    ],
    tenantId =>
      apis.coupons
        .listCouponGifts({ employeeId, tenantId, page: pagination.pageIndex, pageSize: pagination.pageSize })
        .then(res => res.data),
    {
      placeholderData: previousData => previousData,
    },
  );

  const COLUMNS = useGiftsColumns();

  const renderActions = useCallback(({ row }: { row: MRT_Row<CouponGiftDTO> }) => <GiftActivationActions gift={row.original} />, []);

  return (
    <GiftActivationModalProvider>
      <ThemedMaterialReactTable
        enableTopToolbar={false}
        enableColumnActions={false}
        // if we want sorting, we need to pass it to the api for proper sorting over more pages
        enableSorting={false}
        manualPagination
        onPaginationChange={setPagination}
        data={data?.couponGifts || []}
        columns={COLUMNS}
        rowCount={data?.totalCount || 0}
        state={{ pagination, isLoading: isFetching }}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={renderActions}
      />
    </GiftActivationModalProvider>
  );
};
