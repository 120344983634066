import { Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTenant } from 'probonio-shared-ui/module/me';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../redux/hooks';
import probonioLogo from './logo.svg';
import probonioLogoPlain from './logoPlain.svg';

interface Props {
  plain?: boolean;
  height?: number;
}

export const BrandLogo: React.FC<Props> = ({ plain, height }) => {
  const { t } = useTranslation('navigationModule');
  const { tenant } = useTenant();
  const currentUser = useAppSelector(state => state.me.user);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const SIMON_MAIL = 'simon@tiffinger-thiel.de';
  const SIMON_LOGO = '/image/demo_logo.png';
  const tenantLogo = currentUser?.mail === SIMON_MAIL ? SIMON_LOGO : tenant?.imageURL;

  const brandLogo = plain ? probonioLogoPlain : probonioLogo;

  return (
    <span style={isMobile ? { display: 'inline-block' } : {}}>
      <img
        data-test-id="brand-logo"
        src={tenantLogo || brandLogo}
        alt={t('brand')}
        style={{ verticalAlign: 'middle', maxHeight: height || '50px', maxWidth: 'min(100%, 300px)' }}
      />
      {!plain && (
        <>
          <Divider orientation="vertical" sx={{ height: 35, verticalAlign: 'middle', display: 'inline-block', marginX: 2 }} />
          <Typography sx={{ verticalAlign: 'middle' }} component="span" variant="h2">
            {t(tenantLogo && !isMobile ? 'titleFull' : 'title')}
          </Typography>
        </>
      )}
    </span>
  );
};
