import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BenefitPaper } from '../../component/benefitTabs/BenefitPaper';
import { BENEFIT_ICONS } from '../../module/benefits/BenefitIcon';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import BikeleasingContractsTable from '../../module/benefits/bikeleasing/contracts/BikeleasingContractsTable';
import { useBikeleasingPrecondition } from '../../module/benefits/bikeleasing/useBikeleasingPrecondition';
import { EmployeeFilterSelector } from '../../module/benefits/employeeStatusList/EmployeeFilterSelector';
import { useReduxEmployeeFilterState } from '../../module/benefits/employeeStatusList/employeeFilterState';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';
import { setEmployeeFilter } from '../../module/userManagement/filter';

const BikeleasingContractsPage: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const filterState = useReduxEmployeeFilterState(
    useCallback(state => state.filter.employeeFilter, []),
    setEmployeeFilter,
  );
  const { PreconditionComponent, isPreconditionMet } = useBikeleasingPrecondition();

  return (
    <AuthorizedLayout title={t('BIKELEASING.contracts')} breadcrumbs={[{ title: t('BIKELEASING.title'), to: '/benefits/bikeleasing' }]}>
      <BenefitSubPage icon={BENEFIT_ICONS.BIKELEASING} title={t('BIKELEASING.title')} subTitle={t('BIKELEASING.contracts')}>
        <BenefitPaper>
          {isPreconditionMet ? (
            <>
              <EmployeeFilterSelector
                filterState={filterState}
                showBenefitFilter={false}
                showStatusFilter={false}
                showDepartmentFilter={false}
                showCostCenterFilter={false}
              />
              <BikeleasingContractsTable filterState={filterState} />
            </>
          ) : (
            PreconditionComponent
          )}
        </BenefitPaper>
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};

export default BikeleasingContractsPage;
