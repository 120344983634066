import { BenefitTemplateDTO } from 'probonio-shared-ui/api';
import { BenefitsCell } from '../../benefits/BenefitsCell';
import { SxProps } from '@mui/material';

interface Props {
  template: BenefitTemplateDTO;
  sx?: SxProps;
}

export const TemplateBenefitIcons: React.FC<Props> = ({ template, sx }) => {
  const benefits = template.templateItems.map(item => ({
    benefit: item.benefit,
    customBenefitId: item.customBenefitOptions?.customBenefitId,
  }));

  return <BenefitsCell activeBenefits={benefits} sx={{ width: 'auto', ...sx }} />;
};
