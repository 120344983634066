import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { BikeleasingApiListConditionsRequest, BikeleasingConditionListDTO } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { CONDITIONS_LIST_QUERY_KEY, CREATE_CONDITION_KEY, keyWithTenant } from './model/bikeleasing-query-keys';

const PAGE_SIZE = 5;
export function useInfiniteBikeleasingConditions(
  parameters?: Partial<BikeleasingApiListConditionsRequest>,
  options?: { enabled?: boolean },
): UseInfiniteQueryResult<InfiniteData<BikeleasingConditionListDTO, Error>> {
  const getTenantId = useTenantID();

  return useInfiniteQuery({
    queryKey: [...keyWithTenant(getTenantId(), CONDITIONS_LIST_QUERY_KEY), parameters],
    queryFn: ({ pageParam = 0 }) =>
      apis.bikeLeasing
        .listConditions({ tenantId: getTenantId(), page: pageParam, pageSize: PAGE_SIZE, ...parameters })
        .then(res => res.data),

    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => (pages.length * PAGE_SIZE < lastPage.totalCount ? pages.length : undefined),
    placeholderData: previousData => previousData,
    ...options,
  });
}

export function useInvalidateBikeleasingConditions(): () => Promise<void> {
  const getTenantId = useTenantID();
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries({ queryKey: CREATE_CONDITION_KEY(getTenantId()) });
}

export function useBikeleasingConditions(): UseQueryResult<BikeleasingConditionListDTO, Error> {
  return useTenantQuery(CONDITIONS_LIST_QUERY_KEY, tenantId => apis.bikeLeasing.listConditions({ tenantId }).then(res => res.data));
}
