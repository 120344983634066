import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { SettingsFormPanel } from '../../../component/settings/SettingsFormPanel';
import { FormFields, LunchSettingsForm } from './LunchSettingsForm';

export const ReceiptDelayBenefitSettingsPanel: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { enqueueSnackbar } = useSnackbar();
  const { tenant, isLoading, invalidateTenant } = useTenant();

  const { control, reset, handleSubmit, formState } = useForm<FormFields>({
    defaultValues: {
      lunchPeriodCloseDelay: 3,
    },
  });

  const handleReset = useCallback(() => {
    if (!tenant) {
      return;
    }

    reset({
      lunchPeriodCloseDelay: tenant.lunchPeriodCloseDelay ?? 3,
    });
  }, [reset, tenant]);
  useEffect(handleReset, [handleReset]);

  const mutation = useMutation({
    mutationFn: async (values: FormFields) => {
      await apis.tenants.updateTenant({
        tenantId: tenant!.id,
        updateTenantDTO: {
          lunchPeriodCloseDelay: values.lunchPeriodCloseDelay,
        },
      });
    },

    onSuccess: () => {
      enqueueSnackbar(t('LUNCH.settings.successToast'), { variant: 'success' });
      invalidateTenant();
    },
  });

  const handleSave = useCallback(
    (values: FormFields) => {
      mutation.mutate(values);
    },
    [mutation],
  );

  return (
    <SettingsFormPanel
      title={t('LUNCH.settings.title')}
      info={<Trans i18nKey="benefitsModule:LUNCH.settings.info" />}
      isDirty={formState.isDirty}
      isLoading={mutation.isPending}
      onSubmit={handleSubmit(handleSave)}
      onReset={handleReset}
    >
      <LunchSettingsForm control={control} disabled={isLoading || mutation.isPending} />
    </SettingsFormPanel>
  );
};
