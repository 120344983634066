/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CouponBudgetOrderDTO
 */
export interface CouponBudgetOrderDTO {
    /**
     * Budget order amount in cents
     * @type {number}
     * @memberof CouponBudgetOrderDTO
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof CouponBudgetOrderDTO
     */
    'status': CouponBudgetOrderDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CouponBudgetOrderDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CouponBudgetOrderDTO
     */
    'tenantId': string;
    /**
     * 
     * @type {string}
     * @memberof CouponBudgetOrderDTO
     */
    'orderEmployeeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponBudgetOrderDTO
     */
    'orderDate': string;
    /**
     * 
     * @type {string}
     * @memberof CouponBudgetOrderDTO
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponBudgetOrderDTO
     */
    'lastName'?: string;
}

export const CouponBudgetOrderDTOStatusEnum = {
    Ordered: 'ORDERED',
    Completed: 'COMPLETED',
    Cancelled: 'CANCELLED'
} as const;

export type CouponBudgetOrderDTOStatusEnum = typeof CouponBudgetOrderDTOStatusEnum[keyof typeof CouponBudgetOrderDTOStatusEnum];


