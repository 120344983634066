import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query';
import { CouponsV2ApiListTenantCouponGiftsRequest, CouponsV2GiftWithEmployeeListDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';

const PAGE_SIZE = 10;

export function useInfiniteCouponsV2Gifts(
  parameters?: (pageParam: number) => Partial<CouponsV2ApiListTenantCouponGiftsRequest>,
): UseInfiniteQueryResult<InfiniteData<CouponsV2GiftWithEmployeeListDTO, Error>> {
  const getTenantId = useTenantID();

  return useInfiniteQuery({
    queryKey: [
      'tenants',
      getTenantId(),
      'benefits',
      'coupons_v2',
      'gifts',
      { pageSize: PAGE_SIZE, ...parameters?.(0) },
      apis.couponsV2.listTenantCouponGifts.name,
    ],
    getNextPageParam: (lastPage: { totalCount: number }, pages) =>
      pages.length * PAGE_SIZE < lastPage.totalCount ? pages.length : undefined,
    initialPageParam: 0,
  });
}
