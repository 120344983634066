import Grid from '@mui/material/Grid2';
import { DateTime } from 'luxon';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BenefitPaper } from '../../component/benefitTabs/BenefitPaper';
import { BENEFIT_ICONS } from '../../module/benefits/BenefitIcon';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { ChooseExportTypeListener } from '../../module/benefits/payrollReport/ChooseExportTypeListener';
import { isDate } from '../../module/benefits/payrollReport/isDate';
import { PayrollDownloadPanel } from '../../module/benefits/payrollReport/PayrollDownloadPanel';
import { PayrollExportAlerts } from '../../module/benefits/payrollReport/PayrollExportAlerts';
import { PayrollSettingsForm } from '../../module/benefits/payrollReport/PayrollSettingsForm';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';

interface ExportInterval {
  start: Date;
  end: Date;
}
export type ExportDate = Date | ExportInterval;

interface Props {
  benefit: BenefitDTOBenefitEnum;
  reportBenefits?: BenefitDTOBenefitEnum[];
  menu?: React.ReactNode;
  showReceiptsWarning?: boolean;
  showBenefitHeaders?: boolean;
}

const BenefitPayrollReportPage: React.FC<Props> = ({ benefit, reportBenefits, menu, showReceiptsWarning, showBenefitHeaders }) => {
  const { t } = useTranslation('benefitsModule');
  const [exportDate, setExportDate] = useState<ExportDate>(new Date());

  const [isValid, setIsValid] = useState(true);
  const exportBenefits = useMemo(() => reportBenefits || [benefit], [benefit, reportBenefits]);

  const handleChangeExportMonth = useCallback((exportDate: ExportDate) => {
    setExportDate(
      isDate(exportDate)
        ? exportDate
        : {
            end: exportDate.end || new Date(),
            start: exportDate.start || DateTime.now().startOf('month').toJSDate(),
          },
    );
  }, []);

  const handleValidate = useCallback((isValid: boolean) => {
    setIsValid(isValid);
  }, []);

  return (
    <AuthorizedLayout
      title={t('subPage.payrollReport')}
      breadcrumbs={[{ title: t(`${benefit}.title`), to: `/benefits/${benefit.toLowerCase()}` }]}
    >
      <BenefitSubPage icon={BENEFIT_ICONS[benefit]} title={t(`${benefit}.title`)} subTitle={t('subPage.payrollReport')} menu={menu}>
        <PayrollExportAlerts exportDate={exportDate} showReceiptsWarning={showReceiptsWarning} />
        <Grid container spacing={2.5}>
          <Grid size={{ xs: 12, lg: 4 }}>
            <BenefitPaper>
              <PayrollDownloadPanel
                benefits={exportBenefits}
                disabled={!isValid}
                onChangeExportMonth={handleChangeExportMonth}
                isInterval={!isDate(exportDate)}
              />
            </BenefitPaper>
          </Grid>
          <Grid size={{ xs: 12, lg: 8 }}>
            <BenefitPaper>
              <PayrollSettingsForm benefits={exportBenefits} showBenefitHeaders={showBenefitHeaders} onValidate={handleValidate} />
            </BenefitPaper>
          </Grid>
        </Grid>
      </BenefitSubPage>
      <ChooseExportTypeListener benefits={exportBenefits} />
    </AuthorizedLayout>
  );
};

export default BenefitPayrollReportPage;
