import { Node, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { CustomEditor, CustomElement } from './PlaceholderInput.types';

export const getEditorValue = (editor: CustomEditor): string => {
  const value = Node.string(editor);
  return value;
};

export const removeCurrentNode =
  (element: CustomElement, editor: CustomEditor): (() => void) =>
  () => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.removeNodes(editor, { at: path, voids: true });
  };
