const ErrUriAndBlobMissing = 'at least uri or file has to be given';
const ErrNoBlob = 'unable to get Blob';

export class ImageFile {
  public readonly filename: string;
  public readonly mimetype: string;

  private isObjectURI: boolean = false;
  private uri?: string;
  private blob?: Blob;

  public constructor(options: { file?: File }) {
    if (!options.file) {
      throw new Error(ErrUriAndBlobMissing);
    }

    if (options.file) {
      this.blob = options.file;
      this.filename = options.file.name;
      this.mimetype = options.file.type;
      return;
    }

    // Should not happen, but typescript needs it.
    this.filename = '';
    this.mimetype = '';
  }

  public async getBlob(): Promise<Blob> {
    if (this.blob !== undefined) {
      return this.blob;
    }

    if (!this.uri) {
      // Should not happen as it is catched in the constructor.
      throw new Error(ErrUriAndBlobMissing);
    }

    // Download file:

    const blob = this.mimetype as unknown as Blob;
    if (blob) {
      this.blob = blob;
      return this.blob;
    }

    throw new Error(ErrNoBlob);
  }

  public async getURI(): Promise<string> {
    if (this.uri) {
      return this.uri;
    }

    if (!this.blob) {
      // Should not happen as it is catched in the constructor.
      throw new Error(ErrUriAndBlobMissing);
    }

    // Create a new uri
    this.isObjectURI = true;
    this.uri = URL.createObjectURL(this.blob);
    return this.uri;
  }

  public dispose(): void {
    if (this.uri && this.isObjectURI) {
      URL.revokeObjectURL(this.uri);
    }
  }
}
