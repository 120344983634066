import { Box, Typography } from '@mui/material';
import { CUSTOM_BENEFIT_ICONS } from 'probonio-shared-ui/component/icon';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicDialog, DialogState } from '../../../component/dialog';
import { DialogLoadingButton } from '../../../component/dialog/DialogLoadingButton';
import { useNavigateWithPrevPath } from '../../../util/useNavigateWithPrevPath';
import { CustomBenefitTemplate } from './customBenefitTemplates';

interface Props {
  customBenefit: CustomBenefitTemplate;
  customBenefitKey: string;
  dialogState: DialogState;
}
export const CustomBenefitCreateModal: React.FC<Props> = ({ customBenefit, customBenefitKey, dialogState }) => {
  const { t } = useTranslation('customBenefitsModule');
  const navigate = useNavigateWithPrevPath();
  const handleConfirm = useCallback(() => {
    navigate(`create/${customBenefitKey}`);
  }, [customBenefitKey, navigate]);

  const Icon = CUSTOM_BENEFIT_ICONS[customBenefit.iconValue];
  return (
    <BasicDialog
      maxWidth="md"
      dialogState={dialogState}
      title={
        <>
          <Icon color="primary" sx={{ mr: 1 }} />
          {t(`createDialog.title`, { customBenefit: customBenefit.title })}
        </>
      }
      content={
        <Box display="flex" flexDirection="column" rowGap={2} textAlign="center" pt={0.7} pb={1.1}>
          <Typography color="text.secondary">{t('createDialog.description')}</Typography>
          <Typography color="text.secondary">{t('createDialog.support', { customBenefit: customBenefit.title })}</Typography>
        </Box>
      }
      actions={
        <DialogLoadingButton data-test-id="create-dialog-confirm-button" onClick={handleConfirm}>
          {t('createDialog.confirm')}
        </DialogLoadingButton>
      }
    />
  );
};
