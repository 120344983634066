import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';

const QueryOptions = () => {
  return {
    keepPreviousData: true,
    gcTime: 60000,
    staleTime: 60000,
  };
};

export const useCostCenterSelector = () => {
  const { data } = useTenantQuery(
    ['costCenter', apis.tenants.listCostCenters.name],
    tenantId => apis.tenants.listCostCenters({ tenantId }).then(res => res.data.costCenters),
    QueryOptions(),
  );
  return data;
};

export const useDepartmentSelector = () => {
  const { data } = useTenantQuery(
    ['departments', apis.tenants.listDepartments.name],
    tenantId => apis.tenants.listDepartments({ tenantId }).then(res => res.data.departments),
    QueryOptions(),
  );
  return data;
};
