import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Drawer, IconButton, Stack, Typography } from '@mui/material';
import { BenefitDTOBenefitEnum, LunchReceiptTenantDTO, MobilityReceiptTenantDTO, RecreationReceiptTenantDTO } from 'probonio-shared-ui/api';
import { ReceiptImageView } from './ReceiptImageView';
import { DialogState } from '../../../component/dialog';
import { ReceiptDetails } from './ReceiptDetails';
import { useTranslation } from 'react-i18next';

interface Props {
  dialogState: DialogState;
  benefit: BenefitDTOBenefitEnum;
  receipt: LunchReceiptTenantDTO | MobilityReceiptTenantDTO | RecreationReceiptTenantDTO;
}

export const ReceiptDetailsDrawer: React.FC<Props> = ({ dialogState, benefit, receipt }) => {
  const { t } = useTranslation('receiptsModule');

  return (
    <Drawer
      open={dialogState.isOpen}
      anchor="right"
      hideBackdrop
      slotProps={{ root: { style: { position: 'absolute', top: 'unset', bottom: 'unset', left: 'unset', right: 'unset' } } }}
    >
      <Box px={2} paddingTop={1.5} paddingBottom={2} height="100vh" display="flex" flexDirection="column">
        <Box display="flex" mb={2}>
          <Typography variant="h1">{t('receiptDetails.drawerTitle', { benefit, date: new Date(receipt.date) })}</Typography>
          <IconButton
            sx={{ position: 'absolute', right: 8, top: 8 }}
            color="inherit"
            onClick={dialogState.handleClose}
            title={t('common:buttons.close')}
            aria-label={t('common:buttons.close')}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ mb: 2 }} />
        {receipt && (
          <Stack direction="row" spacing={2} flexGrow={1} minHeight={0}>
            <ReceiptImageView benefit={benefit} receipt={receipt} />
            <ReceiptDetails benefit={benefit} receipt={receipt} />
          </Stack>
        )}
      </Box>
    </Drawer>
  );
};
