import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BenefitDocuments } from '../../module/benefits/BenefitDocuments';
import { BENEFIT_ICONS } from '../../module/benefits/BenefitIcon';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';

const BenefitDocumentsPage: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { benefit: benefitName } = useParams<{ benefit: string }>();
  const benefit: BenefitDTOBenefitEnum = benefitName!.toUpperCase() as BenefitDTOBenefitEnum;

  return (
    <AuthorizedLayout title={t('subPage.documents')} breadcrumbs={[{ title: t(`${benefit}.title`), to: `/benefits/${benefitName}` }]}>
      <BenefitSubPage icon={BENEFIT_ICONS[benefit]} title={t(`${benefit}.title`)} subTitle={t('subPage.documents')}>
        <BenefitDocuments benefit={benefit} />
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};

export default BenefitDocumentsPage;
