import { ChartConfiguration } from 'chart.js';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactChart } from '../../component/chart';
import { DateTime } from 'luxon';
import { Box, CircularProgress, Typography } from '@mui/material';

interface Props {
  isLoading?: boolean;
}

export const BenefitGrantChartPlaceholder: React.FC<Props> = ({ isLoading }) => {
  const { t } = useTranslation('dashboardModule');

  const config = useMemo(
    (): ChartConfiguration<'line', number[]> => ({
      type: 'line',
      data: {
        labels: new Array(7).fill(0).map((value, index, array) => DateTime.now().minus({ months: array.length - index - 1 }).monthLong),
        datasets: [],
      },
      options: {
        aspectRatio: 16 / 9,
        scales: {
          x: {
            grid: { display: false },
          },
          y: {
            grid: { display: false },
            min: 0,
            max: 100000,
            ticks: {
              callback: tickValue => t('benefitsPanel.chart.scaleFormat', { value: tickValue }),
            },
          },
        },
      },
    }),
    [t],
  );

  return (
    <Box position="relative" sx={{ height: '100%', width: '100%' }}>
      <ReactChart config={config} sx={{ height: '100%', width: '100%' }} />
      <Box
        position="absolute"
        left={0}
        top={0}
        right={0}
        bottom={0}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {isLoading ? (
          <CircularProgress size={100} thickness={2} />
        ) : (
          <>
            <Typography variant="h3" color="text.secondary" gutterBottom>
              {t('benefitsPanel.chart.emptyTitle')}
            </Typography>
            <Typography textAlign="center" color="text.secondary" maxWidth={250}>
              {t('benefitsPanel.chart.emptyText')}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};
