import { Box, Typography } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';

interface Props {
  params?: URLSearchParams;
}

const WIDTH = 430;
const HEIGHT = 826;
const BORDER_X = 20;
const BORDER_Y = 15;

const MobileAppPhone: React.FC<Props> = ({ params }) => {
  return (
    <>
      <>
        <Box
          sx={{
            position: 'relative',
            height: HEIGHT,
            width: WIDTH,
            margin: 2,
            textAlign: 'center',
          }}
        >
          <iframe
            data-test-id="mobile-app-preview"
            src={`${import.meta.env.VITE_WEB_APP_URL}/home?${params}`}
            seamless
            style={{
              width: WIDTH - 2 * BORDER_X,
              height: HEIGHT - 2 * BORDER_Y,
              position: 'absolute',
              left: BORDER_X,
              top: BORDER_Y,
              textAlign: 'center',
              outline: 'none',
              border: 'none',
            }}
          />
          <img
            alt="A Phone"
            src="/phoneframe.webp"
            style={{
              width: WIDTH,
              height: HEIGHT,
              pointerEvents: 'none',
              position: 'absolute',
              left: '0',
              top: '0',
              marginLeft: 'auto',
              marginRight: 'auto',
              textAlign: 'center',
            }}
          />
        </Box>
      </>
      <Typography variant="caption" alignSelf="center">
        <Trans i18nKey="tenantModule:customisationSettings.warning">
          <a href={import.meta.env.VITE_WEB_APP_URL} />
        </Trans>
      </Typography>
    </>
  );
};

export default MobileAppPhone;
