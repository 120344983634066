import { ButtonProps, Tooltip } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { EmployeeDTO, UpdateEmployeeDTOStatusEnum } from 'probonio-shared-ui/api';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import { apis } from 'probonio-shared-ui/module/api';
import { MouseEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRefetchEmployees } from './query';

interface Props {
  employee: EmployeeDTO;
  tooltip?: string;
  children: React.ReactNode;
  buttonProps: ButtonProps;
  onSuccess?: () => void;
}

export const UnlockUserButton: React.FC<Props> = ({ employee, tooltip, children, buttonProps, onSuccess }) => {
  const refetchEmployees = useRefetchEmployees();
  const { t } = useTranslation('common');
  const mutation = useMutation({
    mutationFn: () =>
      apis.employee.updateEmployee({
        tenantId: employee.tenantId,
        employeeId: employee.id,
        updateEmployeeDTO: {
          status: UpdateEmployeeDTOStatusEnum.Active,
        },
      }),

    onSuccess: async () => {
      await refetchEmployees();
      onSuccess?.();
      enqueueSnackbar(t('usersModule:unlockUser'), { variant: 'success' });
    },
  });
  const inviteUser: MouseEventHandler<HTMLButtonElement> = useCallback(
    e => {
      e.stopPropagation();

      mutation.mutate();
    },
    [mutation],
  );

  return (
    <Tooltip title={tooltip ? tooltip : t(`userStatusButton.tooltip.registeredTooltip`)}>
      <LoadingButton onClick={inviteUser} {...buttonProps} loading={mutation.isPending}>
        {children}
      </LoadingButton>
    </Tooltip>
  );
};
