import { Typography } from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';
import { BAVContractDTO, BAVContractDTOStatusEnum } from 'probonio-shared-ui/api';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { renderUserName } from '../../userManagement/userFormatter';

export function getContractStatusColor(status: BAVContractDTOStatusEnum): string {
  switch (status) {
    case BAVContractDTOStatusEnum.Active:
      return 'success.main';
    case BAVContractDTOStatusEnum.Suspended:
      return 'warning.main';
    case BAVContractDTOStatusEnum.Cancelled:
      return 'error.main';
    case BAVContractDTOStatusEnum.Transferred:
      return 'text.secondary';
  }
}

export function useBAVContractColumns(): MRT_ColumnDef<BAVContractDTO>[] {
  const { t } = useTranslation('bavModule');

  return useMemo<MRT_ColumnDef<BAVContractDTO>[]>(
    () => [
      {
        id: 'name',
        accessorFn: contract => renderUserName(contract),
        header: t('contractsTable.header.name'),
      },
      {
        accessorKey: 'startDate',
        header: t('contractsTable.header.startDate'),
        Cell: ({ cell }) => t('common:date', { date: new Date(cell.getValue<string>()) }),
      },
      {
        id: 'totalPremium',
        accessorFn: contract => contract.employerGrant + contract.employeePremiumGross + contract.vwlConversion,
        header: t('contractsTable.header.totalPremium'),
        Cell: ({ cell }) => t('common:money', { money: cell.getValue() }),
      },
      {
        accessorKey: 'insuranceName',
        header: t('contractsTable.header.insuranceName'),
      },
      {
        accessorKey: 'contractNumber',
        header: t('contractsTable.header.contractNumber'),
      },
      {
        accessorKey: 'status',
        header: t('contractsTable.header.status'),
        Cell: ({ cell }) => (
          <Typography variant="inherit" color={getContractStatusColor(cell.getValue<BAVContractDTOStatusEnum>())}>
            {t(`status.${cell.getValue()}`)}
          </Typography>
        ),
      },
    ],
    [t],
  );
}
