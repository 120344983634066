import { DateTime } from 'luxon';
import { useBenefitTemplates } from './benefitTemplateQueries';
import { useMemo } from 'react';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';

export function useTemplateMinStartDate(selectedTemplateId?: string, queryEnabled?: boolean): DateTime {
  const { data: templates } = useBenefitTemplates({ enabled: queryEnabled });

  return useMemo(() => {
    let minStartDate = DateTime.now().startOf('month');
    const selectedTemplate = templates?.results.find(template => template.id === selectedTemplateId);
    if (
      (selectedTemplate?.templateItems.some(item => item.benefit === BenefitDTOBenefitEnum.Coupons) && DateTime.now().day > 20) ||
      (selectedTemplate?.templateItems.some(item => item.benefit === BenefitDTOBenefitEnum.CouponsV2) && DateTime.now().day > 15)
    ) {
      minStartDate = minStartDate.plus({ months: 1 });
    }
    return minStartDate;
  }, [selectedTemplateId, templates?.results]);
}
