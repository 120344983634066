import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { NewsApiListArticlesRequest, NewsArticlesListDTO } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';

export function useNewsArticles(
  parameters?: Partial<NewsApiListArticlesRequest>,
  options?: { enabled?: boolean },
): UseQueryResult<NewsArticlesListDTO, Error> {
  return useTenantQuery(
    ['benefits', 'news', 'articles', parameters, apis.news.listArticles.name],
    tenantId => apis.news.listArticles({ tenantId, ...parameters }).then(res => res.data),
    {
      ...options,
    },
  );
}

const PAGE_SIZE = 15;

export function useInfiniteNewsArticles(
  parameters?: Partial<NewsApiListArticlesRequest>,
  options?: { enabled?: boolean },
): UseInfiniteQueryResult<InfiniteData<NewsArticlesListDTO, Error>> {
  const getTenantId = useTenantID();

  return useInfiniteQuery({
    queryKey: ['tenants', getTenantId(), 'benefits', 'news', 'articles', apis.news.listArticles.name, parameters],
    queryFn: ({ pageParam = 0 }) =>
      apis.news
        .listArticles({
          tenantId: getTenantId(),
          page: pageParam,
          pageSize: PAGE_SIZE,
          ...parameters,
        })
        .then(res => res.data),
    getNextPageParam: (lastPage, pages) => (pages.length * PAGE_SIZE < lastPage.totalCount ? pages.length : undefined),
    placeholderData: previousData => previousData,
    initialPageParam: 0,
    ...options,
  });
}

export function useInvalidateNewsArticles(): () => Promise<void> {
  const getTenantId = useTenantID();
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries({ queryKey: ['tenants', getTenantId(), 'benefits', 'news', 'articles'] });
}
