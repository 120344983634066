import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ErrorView } from '../module/error/ErrorView';

const Error404Page: React.FC = () => {
  const { t } = useTranslation('errorModule');

  return (
    <ErrorView headlineLarge={t('notFound.headlineLarge')} headlineSmall={t('notFound.headlineSmall')} message={t('notFound.message')}>
      <Button color="warning" variant="contained" href="/">
        {t('goHome')}
      </Button>
    </ErrorView>
  );
};

export default Error404Page;
