import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult, useMutation, UseMutationResult } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import {
  BenefitDTOBenefitEnum,
  LunchReceiptTenantListDTO,
  MobilityReceiptTenantListDTO,
  RecreationReceiptTenantListDTO,
} from 'probonio-shared-ui/api';
import { apis, downloadResponse } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { ReportFilter } from '../report';

const PAGE_SIZE = 20;

export function useReceiptsQuery(
  benefit: 'LUNCH' | 'MOBILITY' | 'RECREATION',
  reportFilter: ReportFilter,
  sortBy: string[],
): UseInfiniteQueryResult<InfiniteData<LunchReceiptTenantListDTO | MobilityReceiptTenantListDTO | RecreationReceiptTenantListDTO, Error>> {
  const getTenantId = useTenantID();

  const month = reportFilter.month.toFormat('yyyy-LL');
  let apiName;
  if (benefit === BenefitDTOBenefitEnum.Lunch) {
    apiName = apis.lunch.getLunchReceipts.name;
  } else if (benefit === BenefitDTOBenefitEnum.Mobility) {
    apiName = apis.mobility.getMobilityReceipts.name;
  } else if (benefit === BenefitDTOBenefitEnum.Recreation) {
    apiName = apis.recreation.getRecreationReceipts.name;
  } else {
    throw new Error(`Unsupported benefit ${benefit}`);
  }
  return useInfiniteQuery({
    queryKey: [
      'tenants',
      getTenantId(),
      'benefits',
      benefit.toLowerCase(),
      'receipts',
      { tenantId: getTenantId(), month, department: reportFilter.department, filter: reportFilter.searchText, sortBy, pageSize: PAGE_SIZE },
      apiName,
    ],
    queryFn: async ({ pageParam = 0 }) => {
      if (benefit === BenefitDTOBenefitEnum.Lunch) {
        return apis.lunch
          .getLunchReceipts({
            tenantId: getTenantId(),
            month,
            department: reportFilter.department,
            filter: reportFilter.searchText,
            sortBy: sortBy,
            page: pageParam,
            pageSize: PAGE_SIZE,
          })
          .then(res => res.data);
      } else if (benefit === BenefitDTOBenefitEnum.Mobility) {
        return apis.mobility
          .getMobilityReceipts({
            tenantId: getTenantId(),
            month,
            department: reportFilter.department,
            filter: reportFilter.searchText,
            sortBy: sortBy,
            page: pageParam,
            pageSize: PAGE_SIZE,
          })
          .then(res => res.data);
      } else if (benefit === BenefitDTOBenefitEnum.Recreation) {
        return apis.recreation
          .getRecreationReceipts({
            tenantId: getTenantId(),
            month,
            department: reportFilter.department,
            filter: reportFilter.searchText,
            sortBy: sortBy,
            page: pageParam,
            pageSize: PAGE_SIZE,
          })
          .then(res => res.data);
      } else {
        throw new Error(`Unsupported benefit ${benefit}`);
      }
    },

    getNextPageParam: (lastPage, pages) => (pages.length * PAGE_SIZE < lastPage.totalCount ? pages.length : undefined),
    initialPageParam: 0,
    placeholderData: previousData => previousData,
  });
}

export function useExportReceiptsMutation(
  benefit: 'LUNCH' | 'MOBILITY' | 'RECREATION',
): UseMutationResult<void, Error, DateTime | undefined> {
  const getTenantId = useTenantID();

  return useMutation({
    mutationFn: async (month?: DateTime) => {
      let res;
      let prefix;
      if (benefit === BenefitDTOBenefitEnum.Lunch) {
        res = await apis.lunch.getReceiptDetailsExport({
          tenantId: getTenantId(),
          month: (month || DateTime.now()).toFormat('yyyy-MM'),
        });
        prefix = 'Lunch';
      } else if (benefit === BenefitDTOBenefitEnum.Mobility) {
        res = await apis.mobility.getReceiptDetailsExport({
          tenantId: getTenantId(),
          month: (month || DateTime.now()).toFormat('yyyy-MM'),
        });
        prefix = 'Mobility';
      } else if (benefit === BenefitDTOBenefitEnum.Recreation) {
        res = await apis.recreation.getReceiptDetailsExport({
          tenantId: getTenantId(),
          month: (month || DateTime.now()).toFormat('yyyy-MM'),
        });
        prefix = 'Recreation';
      } else {
        throw new Error(`Unsupported benefit ${benefit}`);
      }
      const defaultFileName = `${prefix}ReceiptDetails_${(month || DateTime.now()).toFormat('yyyy-MM')}.csv`;
      downloadResponse(res, defaultFileName);
    },
  });
}
