import { VerticalBox } from '../../../../component/layout/BoxLayout';
import { StepContent, Step as MUIStep, StepLabel, Stepper } from '@mui/material';

export interface Step {
  label: string;
  description: string;
  content: () => JSX.Element;
  preview: () => JSX.Element;
}

interface Props {
  activeStep: number;
  steps: Step[];
}

const StepOverview: React.FC<Props> = ({ activeStep, steps }) => {
  return (
    <VerticalBox alignItems="center" textAlign="center" gap={2} justifyContent="space-between">
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map(({ description, label }) => {
          return (
            <MUIStep key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>{description}</StepContent>
            </MUIStep>
          );
        })}
      </Stepper>
    </VerticalBox>
  );
};

export default StepOverview;
