import DownloadIcon from '@mui/icons-material/Download';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Chip, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { BAVContractDTO, BAVDocumentDTO } from 'probonio-shared-ui/api';
import { apis, downloadUrl, useTenantQuery } from 'probonio-shared-ui/module/api';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  contract: BAVContractDTO;
}

const DocumentDownloadButton: React.FC<{ contract: BAVContractDTO; bavDocument: BAVDocumentDTO }> = ({ contract, bavDocument }) => {
  const { t } = useTranslation('bavModule');

  const mutation = useMutation({
    mutationFn: async () => {
      const { downloadURL, fileName } = await apis.bav
        .getContractDocument({
          tenantId: contract.tenantId,
          employeeId: contract.employeeId,
          contractId: contract.id,
          documentId: bavDocument.id,
        })
        .then(res => res.data);
      if (downloadURL) {
        downloadUrl(downloadURL, fileName, true);
      }
    },
  });

  const handleDownload = useCallback(() => {
    mutation.mutate();
  }, [mutation]);

  return (
    <ListItemButton sx={{ px: 1, py: 0.1 }} onClick={handleDownload} disabled={mutation.isPending} component="li">
      <ListItemIcon sx={{ color: 'text.secondary', minWidth: theme => theme.spacing(2.5) }}>
        <InsertDriveFileIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText
        primary={
          <>
            {bavDocument.displayName}
            <Chip
              label={t(`documentType.${bavDocument.documentType}`)}
              sx={{ marginLeft: 1, pointerEvents: 'none' }}
              color="info"
              variant="filled"
              size="small"
            />
          </>
        }
        sx={{ my: '3px' }}
        primaryTypographyProps={{ maxWidth: '800px', noWrap: true }}
      />
      <DownloadIcon color="primary" />
    </ListItemButton>
  );
};

export const ContractDocuments: React.FC<Props> = ({ contract }) => {
  const { data: contractDocuments } = useTenantQuery(
    ['employees', contract.employeeId, 'benefits', 'bav', 'contracts', contract.id, 'documents', apis.bav.listContractDocuments.name],
    tenantId =>
      apis.bav.listContractDocuments({ tenantId, employeeId: contract.employeeId, contractId: contract.id }).then(res => res.data),
  );

  return (
    <List dense disablePadding sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      {contractDocuments?.results.map(doc => <DocumentDownloadButton key={doc.id} contract={contract} bavDocument={doc} />)}
    </List>
  );
};
