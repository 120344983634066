import React, { useCallback } from 'react';
import { BenefitDTOBenefitEnum, CustomBenefitDTO } from 'probonio-shared-ui/api';
import { CUSTOM_BENEFIT_ICONS } from 'probonio-shared-ui/component/icon';
import { BenefitButtonRenderer } from './NavBenefitList';
import { useNavigateWithPrevPath } from '../../../util/useNavigateWithPrevPath';
import { useBenefitMenuItems } from '../benefitMenuItems';

export const CustomBenefitButton: React.FC<{
  renderer: BenefitButtonRenderer;
  selected: boolean;
  customBenefit: CustomBenefitDTO;
}> = ({ customBenefit, selected, renderer }) => {
  const navigate = useNavigateWithPrevPath();

  const handleClick = useCallback(() => {
    navigate(`benefits/custom/${customBenefit.id}`);
  }, [customBenefit.id, navigate]);

  const benefitMenuItems = useBenefitMenuItems(BenefitDTOBenefitEnum.Custom, customBenefit, selected);

  const BenefitButton = renderer;

  return (
    <BenefitButton
      dataTestId={customBenefit.title}
      icon={CUSTOM_BENEFIT_ICONS[customBenefit.iconName]}
      selected={selected}
      title={customBenefit.title}
      active={customBenefit.enabled}
      onClick={handleClick}
      subMenuItems={benefitMenuItems}
    />
  );
};
