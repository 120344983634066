import { Box, Button } from '@mui/material';
import { MRT_TableOptions, MRT_TableState } from 'material-react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { ThemedMaterialReactTable } from './ThemedMaterialReactTable';
import { LoadMoreProps } from './useLoadMore';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props<TData extends Record<string, any>> = MRT_TableOptions<TData> & LoadMoreProps<TData>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const InfiniteScrollReactTable = <TData extends Record<string, any> = {}>({
  rowCount,
  onLoadMore,
  hasMore,
  isLoading,
  isFetching,
  ...props
}: Props<TData>): JSX.Element => {
  const { t } = useTranslation();

  const state = useMemo<Partial<MRT_TableState<TData>>>(() => ({ ...props.state, isLoading }), [props.state, isLoading]);

  return (
    <InfiniteScroll
      pageStart={0}
      data-test-id="table-infinity-scroll"
      initialLoad={false}
      loadMore={onLoadMore}
      hasMore={hasMore}
      loader={
        <div className="loader" key={0}>
          <Box textAlign="center" padding={1}>
            <Button variant="text" onClick={onLoadMore}>
              {t('common:loadMoreData')}
            </Button>
          </Box>
        </div>
      }
    >
      <ThemedMaterialReactTable<TData> {...props} state={state} />
    </InfiniteScroll>
  );
};
