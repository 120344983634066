import { Box, Typography } from '@mui/material';
import { DesktopErrorLayout } from '../../component/layout/DesktopErrorLayout';
import { fontSizes } from '../../theme';

interface Props {
  headlineLarge: React.ReactNode;
  headlineSmall: React.ReactNode;
  message: React.ReactNode;
  eventId?: string;
}

export const ErrorView: React.FC<React.PropsWithChildren<Props>> = ({ headlineLarge, headlineSmall, message, eventId, children }) => {
  return (
    <DesktopErrorLayout>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h1" sx={{ paddingTop: 1, paddingBottom: 2, fontSize: fontSizes.xxxl }}>
          {headlineLarge}
        </Typography>
        <Typography variant="h2" gutterBottom>
          {headlineSmall}
        </Typography>
        <Box sx={{ marginBottom: 1.5 }}>
          <Typography color="inherit">{message}</Typography>
          {eventId && (
            <Typography variant="body2" sx={{ marginTop: 0.5 }}>
              ID: {eventId}
            </Typography>
          )}
        </Box>
        {children}
      </Box>
    </DesktopErrorLayout>
  );
};
