import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTenantID } from 'probonio-shared-ui/module/me';

export const useRefetchBudgetOrders = (): (() => Promise<void>) => {
  const queryClient = useQueryClient();
  const getTenantId = useTenantID();

  return useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ['tenants', getTenantId(), 'benefits', 'coupons', 'budgetOrders'] });
  }, [queryClient, getTenantId]);
};
