import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SettingsFormPanel } from '../../component/settings/SettingsFormPanel';
import { PaymentMethodsPanel } from './PaymentMethodsPanel';
import { FormFields, TenantSettingsForm } from './TenantSettingsForm';

export const PaymentSettingsPanel: React.FC = () => {
  const { t } = useTranslation('tenantModule');
  const { control, reset, handleSubmit, formState } = useForm<FormFields>({ defaultValues: { invoiceEmailList: [] } });
  const { tenant, isLoading, invalidateTenant } = useTenant();
  const { enqueueSnackbar } = useSnackbar();

  const handleReset = useCallback(() => {
    if (!tenant) {
      return;
    }

    reset({
      tenantName: tenant.name,
      tenantStreet: tenant.street,
      tenantPostalCode: tenant.postalCode,
      tenantCity: tenant.city,
      tenantMail: tenant.mail,
      invoiceEmailList: tenant.invoiceEmailList || [],
    });
  }, [reset, tenant]);
  useEffect(handleReset, [handleReset]);

  const mutation = useMutation({
    mutationFn: async (values: FormFields) => {
      await apis.tenants.updateTenant({
        tenantId: tenant!.id,
        updateTenantDTO: {
          name: values.tenantName,
          street: values.tenantStreet,
          postalCode: values.tenantPostalCode,
          city: values.tenantCity,
          mail: values.tenantMail,
          invoiceEmailList: values.invoiceEmailList,
        },
      });
    },
    onSuccess: () => {
      enqueueSnackbar(t('paymentSettings.successToast'), { variant: 'success' });
      invalidateTenant();
    },
  });

  const handleSave = useCallback(
    (values: FormFields) => {
      mutation.mutate(values);
    },
    [mutation],
  );

  return (
    <>
      <SettingsFormPanel
        title={t('paymentSettings.title')}
        info={t('paymentSettings.info')}
        isDirty={formState.isDirty}
        isLoading={isLoading || mutation.isPending}
        onSubmit={handleSubmit(handleSave)}
        onReset={handleReset}
      >
        <TenantSettingsForm control={control} disabled={isLoading || mutation.isPending} />
      </SettingsFormPanel>
      <PaymentMethodsPanel />
    </>
  );
};
