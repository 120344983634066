import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Button } from '@mui/material';
import { BenefitDropdown } from './BenefitSelector';
import { useTranslation } from 'react-i18next';
import { BenefitDTOBenefitEnum, CustomBenefitDTO } from 'probonio-shared-ui/api';
import { useCallback } from 'react';
import { RowSelectionState } from '../../component/table/useManualRowSelection';
import { useBulkBenefitActivationModal } from '../benefits/bulkActivation/useBulkBenefitActivationModal';

interface Props {
  rowSelectionState: RowSelectionState;
}

export const EmployeeListEditButtons: React.FC<Props> = ({ rowSelectionState }) => {
  const { t } = useTranslation('usersModule');
  const { onBenefitCreate, onApplyTemplate } = useBulkBenefitActivationModal();

  const handleSelectBenefit = useCallback(
    (benefit: BenefitDTOBenefitEnum, customBenefit?: CustomBenefitDTO) => {
      onBenefitCreate(benefit, customBenefit?.id);
    },
    [onBenefitCreate],
  );

  return (
    <>
      <Button
        color="secondary"
        data-test-id="apply-template"
        startIcon={<PlaylistAddIcon />}
        disabled={!rowSelectionState.someRowsSelected}
        onClick={onApplyTemplate}
      >
        {t('userForm.applyTemplate.button')}
      </Button>
      <BenefitDropdown
        color="primary"
        showTosBadge
        excludeBenefits={[{ benefit: BenefitDTOBenefitEnum.Gifts }, { benefit: BenefitDTOBenefitEnum.News }]}
        disabled={!rowSelectionState.someRowsSelected}
        onSelect={handleSelectBenefit}
      >
        {t('profileActivationList.addBenefit')}
      </BenefitDropdown>
    </>
  );
};
