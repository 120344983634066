import { BenefitActivationDTO, BenefitActivationDTOBenefitEnum, BenefitTemplateItemDTO } from 'probonio-shared-ui/api';
import { useCallback, useState } from 'react';
import ProfileBenefitActionsContext from './useBenefitActivationModal';
import { useProfileEmployee } from '../hooks/useProfileEmployee';
import { useDeleteBenefitActivationMutation } from '../hooks/useDeleteBenefitActivationMutation';
import { useNewDialogState } from '../../../../../component/dialog';
import { useBoundActivationComponent } from '../../../../benefits/ActivationDialog/useBoundActivationComponent';
import { BenefitProvider } from '../../../../benefits/BenefitContext';
import {
  ActivationDialog,
  DeleteActivationDialog,
  StopActivationDialog,
  useActivationDialogState,
  useEmployeeActivationSaveFunctions,
} from '../../../../benefits';
import { BenefitRef } from '../../../../benefits/BenefitsCell';

export const BenefitActivationModalProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { employee } = useProfileEmployee();
  const activationDialog = useActivationDialogState();
  const deleteDialog = useNewDialogState();
  const stopDialog = useNewDialogState();
  const { deleteActivation } = useDeleteBenefitActivationMutation();
  const [activationToEdit, setActivationToEdit] = useState<BenefitActivationDTO>();

  const [benefitRefToEdit, setBenefitRefToEdit] = useState<BenefitRef | undefined>();

  const handleDeleteBenefit = useCallback(() => {
    deleteActivation(activationToEdit!);
    deleteDialog.dialogState.handleClose();
  }, [activationToEdit, deleteActivation, deleteDialog.dialogState]);

  const ActivationDialogComponent = useBoundActivationComponent(ActivationDialog, activationToEdit?.benefit || benefitRefToEdit?.benefit);
  const activationSaveFunctions = useEmployeeActivationSaveFunctions(
    activationToEdit?.benefit || benefitRefToEdit?.benefit!,
    employee.id,
    activationToEdit?.customBenefitOptions?.customBenefitId || benefitRefToEdit?.customBenefitId,
  );

  const handleOpenActivationCreate = useCallback(
    (benefit: BenefitActivationDTOBenefitEnum, customBenefitId?: string) => {
      setActivationToEdit(undefined);
      setBenefitRefToEdit({ benefit, customBenefitId });
      activationDialog.handleOpenDialogToCreate();
    },
    [activationDialog],
  );

  const handleOpenActivationEdit = useCallback(
    (activation: BenefitTemplateItemDTO | BenefitActivationDTO) => {
      const benefitActivation = activation as BenefitActivationDTO;

      setActivationToEdit(benefitActivation);
      setBenefitRefToEdit({ benefit: activation.benefit, customBenefitId: benefitActivation.customBenefitOptions?.customBenefitId });

      activationDialog.handleOpenDialogToEdit(benefitActivation);
    },
    [activationDialog],
  );

  const handleOpenActivationStop = useCallback(
    (activation: BenefitTemplateItemDTO | BenefitActivationDTO) => {
      const benefitActivation = activation as BenefitActivationDTO;
      setActivationToEdit(benefitActivation);
      setBenefitRefToEdit({ benefit: activation.benefit, customBenefitId: benefitActivation.customBenefitOptions?.customBenefitId });

      stopDialog.handleOpen();
    },
    [stopDialog],
  );
  const handleOpenActivationDelete = useCallback(
    (activation: BenefitTemplateItemDTO | BenefitActivationDTO) => {
      const benefitActivation = activation as BenefitActivationDTO;
      setActivationToEdit(benefitActivation);
      setBenefitRefToEdit({ benefit: activation.benefit, customBenefitId: benefitActivation.customBenefitOptions?.customBenefitId });

      deleteDialog.handleOpen();
    },
    [deleteDialog],
  );

  return (
    <ProfileBenefitActionsContext.Provider
      value={{
        onBenefitCreate: handleOpenActivationCreate,
        onBenefitEdit: handleOpenActivationEdit,
        onBenefitDelete: handleOpenActivationDelete,
        onBenefitStop: handleOpenActivationStop,
      }}
    >
      <BenefitProvider
        benefit={activationToEdit?.benefit || benefitRefToEdit?.benefit!}
        customBenefitId={benefitRefToEdit?.customBenefitId}
        showLoadingIndicator={false}
      >
        <ActivationDialogComponent saveFunctions={activationSaveFunctions} activationDialogState={activationDialog} />
      </BenefitProvider>
      {activationToEdit && (
        <StopActivationDialog dialogState={stopDialog.dialogState} employeeId={employee.id} activationToStop={activationToEdit} />
      )}
      {activationToEdit && <DeleteActivationDialog dialogState={deleteDialog.dialogState} onDelete={handleDeleteBenefit} />}
      {children}
    </ProfileBenefitActionsContext.Provider>
  );
};
