import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BasicDialog, DialogState } from '../../../component/dialog';
import { DialogTabs, useTabsState } from '../../../component/tab';
import { DialogTab } from '../../../component/tab/DialogTab';
import { AddUserCode } from './AddUserCode';
import { AddUserMail } from './AddUserMail';

interface Props {
  dialogState: DialogState;
}

export const AddUserDialog: React.FC<Props> = ({ dialogState }) => {
  const { t } = useTranslation('usersModule');
  const { handleTabChange, tabIndex } = useTabsState();
  return (
    <BasicDialog
      dialogState={dialogState}
      fullWidth
      title={t('createNewUser')}
      content={
        <Box data-test-id="user-add-popup">
          <Box>
            <DialogTabs variant="fullWidth" value={tabIndex} onChange={handleTabChange}>
              <DialogTab data-test-id="user-add-popup-email" label={t('userForm.field.mail')} />
              <DialogTab data-test-id="user-add-popup-code" label={t('createNewUserFields.registrationCode')} />
            </DialogTabs>
          </Box>
          <Box sx={{ paddingTop: 2 }}>
            {tabIndex === 0 && <AddUserMail dialogState={dialogState} />}
            {tabIndex === 1 && <AddUserCode dialogState={dialogState} />}
          </Box>
        </Box>
      }
    />
  );
};
