import { Box, styled } from '@mui/material';
import { fontSizes } from '../../theme';

export const DatePickerContainer = styled(Box)(({ theme }) => ({
  '.react-datepicker__input-container': {
    width: 'inherit',
  },

  '.react-datepicker-wrapper': {
    width: '100%',
  },
  '.react-datepicker': {
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    bottom: 1,
    '&-popper': {
      zIndex: 2,
      '&[data-placement^=bottom] .react-datepicker__triangle::after': {
        borderBottomColor: theme.palette.background.default,
      },
    },
    '&-year-header': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default,
      fontSize: fontSizes.md,
    },
    '&__year-select': {
      backgroundColor: theme.palette.background.paper,
      border: '1px solid',
      borderRadius: '4px',
    },
    '&__header': {
      backgroundColor: theme.palette.background.default,
      borderTopLeftRadius: theme.shape.borderRadius,
    },
    '&__header:not(.react-datepicker__header--has-time-select)': {
      borderTopRightRadius: theme.shape.borderRadius,
    },
    '&__month': {
      '&-container': {
        fontSize: fontSizes.md,
      },
      '&-text': {
        margin: theme.spacing(0.5),
        padding: theme.spacing(0.25),
      },
      '&-text:hover': {
        backgroundColor: theme.palette.grey[200],
      },
      '&--selected': {
        color: `${theme.palette.primary.contrastText} !important`,
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
      '&-text--keyboard-selected': {
        color: 'unset',
        backgroundColor: 'unset',
      },
    },
    '&__day': {
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
      '&--selected': {
        color: `${theme.palette.primary.contrastText} !important`,
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
      '&--keyboard-selected': {
        color: 'unset',
        backgroundColor: 'unset',
      },
    },
    '&__navigation': {
      marginTop: 4,
      '&-icon::before': {
        borderColor: theme.palette.text.primary,
      },
    },
  },
}));
