import { WarningAmber } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Box, IconButton, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewDialogState } from '../dialog/BasicDialog';
import supportedBrowsers from './supportedBrowsers';
import { UnsupportedBrowserDialog } from './UnsupportedBrowserBadgeDialog';
const LOCALSTORAGE_KEY = 'unsupportedBrowserModalOpened';

export const UnsupportedBrowserBadge: React.FC = () => {
  const [showBadge, setShowBadge] = useState(false);
  const { t } = useTranslation('navigationModule');
  const modalState = localStorage.getItem(LOCALSTORAGE_KEY);

  useEffect(() => {
    if (modalState !== 'closed' && !supportedBrowsers.test(window.navigator.userAgent)) {
      setShowBadge(true);
    }
  }, [modalState]);

  const handleClose = useCallback(() => {
    localStorage.setItem(LOCALSTORAGE_KEY, 'closed');
    setShowBadge(false);
  }, []);

  const unsupportedBrowserDialog = useNewDialogState();
  if (showBadge) {
    return (
      <Box marginY={1}>
        <Alert
          severity="warning"
          icon={<WarningAmber fontSize="large" />}
          data-test-id="referral-button"
          onClick={unsupportedBrowserDialog.handleOpen}
          action={
            <IconButton onClick={unsupportedBrowserDialog.handleOpen}>
              <CloseIcon />
            </IconButton>
          }
        >
          <Typography variant="h3" color="text.primary">
            {t('unsupportedBrowserModal.buttonTitle')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t('unsupportedBrowserModal.buttonSubTitle')}
          </Typography>
        </Alert>
        <UnsupportedBrowserDialog dialogState={unsupportedBrowserDialog.dialogState} onClose={handleClose} />
      </Box>
    );
  } else {
    return <></>;
  }
};

export default UnsupportedBrowserBadge;
