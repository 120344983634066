import { DateTime } from 'luxon';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useMemo } from 'react';
import { ReportDiagramData } from '../report';

export function useLunchReport(): { reportMonth: DateTime; report?: ReportDiagramData; isLoading: boolean } {
  const reportMonth = useMemo(() => DateTime.now().minus({ months: 1 }).startOf('month'), []);
  const { data: report, isLoading } = useTenantQuery(
    ['lunch', 'reports', reportMonth.toFormat('yyyy-MM'), apis.lunch.getReport.name],
    tenantId => apis.lunch.getReport({ tenantId, month: reportMonth.toFormat('yyyy-MM') }).then(res => res.data),
    {
      placeholderData: previousData => previousData,
    },
  );

  return useMemo(
    () => ({
      reportMonth,
      report,
      isLoading,
    }),
    [isLoading, report, reportMonth],
  );
}
