import { Alert, Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { BenefitDTOBenefitEnum, NotifyTenantExportTypeDTO, TenantDTOTenantStatusEnum } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicDialog, DialogState } from '../../../component/dialog';
import { DialogLoadingButton } from '../../../component/dialog/DialogLoadingButton';

interface ExportOption {
  label: string;
  icon: string;
  value: string;
  isSelectable: boolean;
}

export const EXPORT_OPTIONS: ExportOption[] = [
  { label: 'ABS', icon: 'abs.png', value: 'abs', isSelectable: true },
  { label: 'Addison', icon: 'addison.svg', value: 'addison', isSelectable: true },
  { label: 'Agenda', icon: 'agenda.svg', value: 'agenda', isSelectable: true },
  { label: 'edlohn', icon: 'edlohn.png', value: 'edlohn', isSelectable: true },
  { label: 'Lexware', icon: 'lexware.svg', value: 'lexware', isSelectable: true },
  { label: 'LODAS', icon: 'datev.svg', value: 'lodas', isSelectable: true },
  { label: 'Lohn und Gehalt', icon: 'datev.svg', value: 'datev-lg', isSelectable: true },
  { label: 'Sage', icon: 'sage.svg', value: 'sage', isSelectable: true },
  { label: 'SP_Data', icon: 'spdata.png', value: 'spdata', isSelectable: true },
  { label: 'Allgemeiner Export', icon: 'generic.svg', value: 'generic', isSelectable: true },
  { label: 'Linde Export', icon: 'linde-mh.svg', value: 'custom_linde', isSelectable: false },
  { label: 'Lufthansa Export', icon: 'export.svg', value: 'custom_lufthansa', isSelectable: false },
  { label: 'Bertelsmann Export', icon: 'export.svg', value: 'custom_bertelsmann', isSelectable: false },
];

interface Props {
  benefits: BenefitDTOBenefitEnum[];
  dialogState: DialogState;
  handleNextDialog?: () => void;
}

export const PayrollReportChooseExportTypeDialog: React.FC<Props> = ({ benefits, dialogState, handleNextDialog }) => {
  const { t } = useTranslation('receiptsModule');
  const [exportType, setExportType] = useState<ExportOption | undefined>();
  const { tenant, invalidateTenant } = useTenant();

  useEffect(() => {
    if (dialogState.isOpen) {
      setExportType(undefined);
    }
  }, [dialogState.isOpen]);

  const updateExportType = useMutation({
    mutationFn: async () => {
      if (tenant && exportType) {
        await apis.tenants.updateTenant({ tenantId: tenant.id, updateTenantDTO: { lodasExportType: exportType.value } });
      }
    },

    onSuccess: () => {
      invalidateTenant();
      dialogState.handleClose();
      handleNextDialog?.();
    },
  });

  const notifyProbonioSupport = useMutation({
    mutationFn: async (params: NotifyTenantExportTypeDTO) => {
      if (tenant) {
        await apis.tenants.notifyTestAccountExportTypeChange({
          tenantId: tenant.id,
          notifyTenantExportTypeDTO: params,
        });
      }
    },
  });

  const handleChange = useCallback(
    (exportType: ExportOption) => {
      if (tenant && tenant.tenantStatus === TenantDTOTenantStatusEnum.TestPeriod) {
        notifyProbonioSupport.mutate({ exportType: exportType.label, benefit: benefits[0] });
      }
      setExportType(exportType);
    },
    [benefits, notifyProbonioSupport, tenant],
  );

  const handleChangeExportType = useCallback(() => {
    updateExportType.mutate();
  }, [updateExportType]);

  return (
    <BasicDialog
      dialogState={dialogState}
      title={t('selectExportDialog.title')}
      content={
        <Box>
          {!exportType ? (
            <>
              <Typography>{t('selectExportDialog.introText')}</Typography>
              <Box paddingY={1}>
                <List>
                  {EXPORT_OPTIONS.map(
                    option =>
                      option.isSelectable && (
                        <Box key={EXPORT_OPTIONS.indexOf(option)}>
                          <ListItem disablePadding sx={{ margin: 0 }}>
                            {/* eslint-disable-next-line react/jsx-no-bind */}
                            <ListItemButton sx={{ paddingY: 0.5 }} onClick={() => handleChange(option)}>
                              <ListItemIcon>
                                <img
                                  src={`/image/export/logos/${option.icon}`}
                                  height="50px"
                                  style={{ maxWidth: '50px', maxHeight: '50px', width: 'auto', height: 'auto' }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                sx={{ paddingLeft: 1, height: '50px', display: 'flex', alignItems: 'center' }}
                                primary={option.label}
                              />
                            </ListItemButton>
                          </ListItem>
                          {EXPORT_OPTIONS.indexOf(option) < EXPORT_OPTIONS.length - 1 && <Divider />}
                        </Box>
                      ),
                  )}
                </List>
              </Box>
            </>
          ) : tenant && tenant.tenantStatus === TenantDTOTenantStatusEnum.Active ? (
            <Alert severity="warning">{t('selectExportDialog.warning', { tenant: exportType.label })}</Alert>
          ) : (
            <Alert severity="info">{t('selectExportDialog.infoTestAccount')}</Alert>
          )}
        </Box>
      }
      actions={
        <>
          {exportType && tenant && tenant.tenantStatus === TenantDTOTenantStatusEnum.Active && (
            <DialogLoadingButton
              sx={{ marginLeft: 1 }}
              onClick={handleChangeExportType}
              loading={updateExportType.isPending}
              data-test-id="confirm-export-type-button"
            >
              {t('selectExportDialog.confirm')}
            </DialogLoadingButton>
          )}
        </>
      }
    />
  );
};
