import { Button, Typography } from '@mui/material';
import { FullWidthCenterBox } from '../../component/layout/BoxLayout';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const EmployeeNotFound: React.FC = () => {
  const { t } = useTranslation('usersModule');

  return (
    <FullWidthCenterBox height="80vh" flexDirection="column" alignItems="center" gap={1}>
      <Typography variant="h2">{t('employeeNotFound')}</Typography>
      <Typography>{t('employeeNotFoundInfo')}</Typography>
      <Button component={Link} to="/employees" color="warning">
        {t('dashboardModule:employeesPanel.goToEmployeeList')}
      </Button>
    </FullWidthCenterBox>
  );
};
