import { useTranslation } from 'react-i18next';
import UserProfileCard from '../../module/userManagement/UserProfile/profile/ProfileCardForm';
import { BaseEmployeePage } from './BaseEmployeePage';

const EmployeeEditProfilePage: React.FC = () => {
  const { t } = useTranslation('usersModule');

  return (
    <BaseEmployeePage title={t('userMenu.profile')} editActive>
      <UserProfileCard />
    </BaseEmployeePage>
  );
};

export default EmployeeEditProfilePage;
