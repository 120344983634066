import { useMemo } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectControl } from '../../../component/form';
import { SettingsFormRow } from '../../../component/settings/SettingsFormRow';

export interface FormFields {
  lunchPeriodCloseDelay: number;
}

interface Props {
  control: Control<FormFields>;
  disabled?: boolean;
}

const MAX_DAYS_BEFORE_END_OF_MONTH = 5;
const MAX_DAYS_AFTER_END_OF_MONTH = 3;

export const LunchSettingsForm: React.FC<Props> = ({ control, disabled }) => {
  const { t } = useTranslation('benefitsModule');

  const closeDelayOptions = useMemo(
    (): { label: React.ReactNode; value: string | number }[] => [
      ...new Array(MAX_DAYS_BEFORE_END_OF_MONTH).fill(0).map((v, index) => ({
        value: index - MAX_DAYS_BEFORE_END_OF_MONTH,
        label: t('LUNCH.settings.periodCloseDelay.currentMonth', { count: MAX_DAYS_BEFORE_END_OF_MONTH - index }),
      })),
      { value: 0, label: t('LUNCH.settings.periodCloseDelay.endOfMonth') },
      ...new Array(MAX_DAYS_AFTER_END_OF_MONTH).fill(0).map((v, index) => ({
        value: index + 1,
        label: t('LUNCH.settings.periodCloseDelay.nextMonth', { count: index + 1, ordinal: true }),
      })),
    ],
    [t],
  );

  return (
    <>
      <SettingsFormRow fullWidth title={t('LUNCH.settings.periodCloseDelay.title')} info={t('LUNCH.settings.periodCloseDelay.info')}>
        <SelectControl control={control} name="lunchPeriodCloseDelay" fullWidth options={closeDelayOptions} disabled={disabled} />
      </SettingsFormRow>
    </>
  );
};
