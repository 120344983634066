import { Box, Button, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { BAVContractDTO, CreateBAVChangeRequestDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BenefitPaper } from '../../../component/benefitTabs/BenefitPaper';
import { useNewDialogState } from '../../../component/dialog';
import { ConfirmationModal } from '../../../component/dialog/ConfirmationModal';
import { UnsavedChangesListener } from '../../../component/form/UnsavedChangesListener';
import { BENEFIT_ICONS } from '../BenefitIcon';
import BenefitSubPage from '../BenefitSubPage';
import { BAVContractDetails } from './BAVContractDetails';
import { RequestContactDialog } from './RequestContactDialog';

interface Props {
  contract: BAVContractDTO;
}

export const BAVContractCard: React.FC<Props> = ({ contract }) => {
  const { t } = useTranslation('bavModule');
  const [changedValues, setChangedValues] = useState<Partial<BAVContractDTO>>({});
  const confirmDialog = useNewDialogState();
  const contactDialog = useNewDialogState();
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: async () => {
      const mappedRequest: CreateBAVChangeRequestDTO = {
        ...changedValues,
        fromManager: true,
      };
      await apis.bav.createChangeRequest({
        tenantId: contract.tenantId,
        employeeId: contract.employeeId,
        contractId: contract.id,
        createBAVChangeRequestDTO: mappedRequest,
      });
    },
    onSuccess: () => {
      confirmDialog.dialogState.handleClose();
      setChangedValues({});
      enqueueSnackbar(t('submitSuccessToast'), { variant: 'success' });
    },
  });

  const handleSave = useCallback(() => {
    mutation.mutate();
  }, [mutation]);

  const isDirty = !!Object.keys(changedValues).length;

  return (
    <BenefitSubPage
      icon={BENEFIT_ICONS.BAV}
      title={t('benefitsModule:BAV.title')}
      subTitle={t('benefitsModule:BAV.contractDetails', { name: `${contract.firstName} ${contract.lastName}` })}
      showBack
      navigateBackTo="/benefits/bav/contracts"
      menu={
        <Stack direction="row" spacing={2}>
          <Button onClick={contactDialog.handleOpen}>{t('requestContact')}</Button>
          <Button color="success" onClick={confirmDialog.handleOpen} disabled={!isDirty}>
            {t('submitChanges')}
          </Button>
        </Stack>
      }
    >
      <BenefitPaper>
        <UnsavedChangesListener isDirty={isDirty} />
        <Stack direction="row" justifyContent="space-between" mb={2}>
          <Box>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              {t('contractIntro')}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {t('contractAppInfo')}
            </Typography>
          </Box>
          <Typography variant="body2" textAlign="right" visibility={isDirty ? undefined : 'hidden'}>
            <Trans i18nKey="bavModule:changesHint">
              <Typography component="span" variant="inherit" color="error.main" />
            </Trans>
          </Typography>
        </Stack>
        <BAVContractDetails contract={contract} changedValues={changedValues} onChange={setChangedValues} />
        <ConfirmationModal
          dialogState={confirmDialog.dialogState}
          title={t('submitChanges')}
          closeOnConfirm={false}
          loading={mutation.isPending}
          onConfirm={handleSave}
        >
          <Box whiteSpace="pre-line">{t('confirmSubmit')}</Box>
        </ConfirmationModal>
        <RequestContactDialog contract={contract} dialogState={contactDialog.dialogState} />
      </BenefitPaper>
    </BenefitSubPage>
  );
};
