import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider } from '@mui/material';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useTenant } from 'probonio-shared-ui/module/me/hooks';
import React from 'react';
import { Trans } from 'react-i18next';

interface Props {
  benefit?: BenefitDTOBenefitEnum;
  customBenefitTitle?: string;
  customBenefitKey?: string;
  overrideKey?: string;
}

const BENEFIT_LINKS: Partial<Record<BenefitDTOBenefitEnum, string>> = {
  LUNCH: 'https://probonio.atlassian.net/wiki/spaces/MAN/pages/557134/Benefit-Modul+Essenszuschuss',
  COUPONS: 'https://probonio.atlassian.net/wiki/spaces/MAN/pages/688186/Benefit-Modul+Gutscheine+f+r+den+Sachbezug',
  INTERNET: 'https://probonio.atlassian.net/wiki/spaces/MAN/pages/77856769/Benefit-Modul+Internetpauschale',
  MOBILITY: 'https://probonio.atlassian.net/wiki/spaces/MAN/pages/79626243/Benefit-Modul+Mobilit+tsbudget',
  COUPONS_V2: 'https://probonio.atlassian.net/wiki/spaces/MAN/pages/121831425/Benefit-Modul+Sachbezug+Flex',
};

const BenefitInfoText: React.FC<Props> = ({ benefit, customBenefitTitle, customBenefitKey, overrideKey }) => {
  const { tenant } = useTenant();
  const lunchDailyLimit = tenant?.taxConfig && tenant.taxConfig.lunchNonCashValue + tenant.taxConfig.lunchSupplement;

  return (
    <>
      <Accordion
        sx={{
          marginBottom: 1.5,
          borderRadius: 1,
          '&:before': {
            display: 'none',
          },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <InfoOutlinedIcon sx={{ marginRight: 1 }} />
          {customBenefitTitle ? <Trans i18nKey={`${customBenefitTitle}`} /> : <Trans i18nKey={`benefitsModule:${benefit}.title`} />}
        </AccordionSummary>
        <AccordionDetails>
          {customBenefitKey ? (
            <Trans i18nKey={`customBenefitsModule:benefits.${customBenefitKey}.infoTextFull`} />
          ) : (
            <Trans
              i18nKey={overrideKey ? overrideKey : `benefitsModule:${benefit}.infoTextFull`}
              values={
                lunchDailyLimit && {
                  lunchDailyLimit: lunchDailyLimit,
                  lunchMonthlyLimit: lunchDailyLimit * 15,
                }
              }
            >
              <Divider />
            </Trans>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'end', paddingRight: 1 }}>
            {benefit && BENEFIT_LINKS[benefit] && <a href={BENEFIT_LINKS[benefit]}>Weitere Informationen</a>}
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default BenefitInfoText;
