import { useLoginContext } from '@tiffinger-thiel/appauth-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Intermediate page that triggers the logout when called. Used to prevent the user from
 * being immediately logged in again.
 */
const LogoutPage: React.FC = () => {
  const { isLoggedIn, logout } = useLoginContext();
  const [loggingOut, setLoggingOut] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      setLoggingOut(true);
      void logout();
    } else if (!loggingOut) {
      navigate('/');
    }
  }, [isLoggedIn, loggingOut, logout, navigate]);

  return <div>Abmeldung läuft...</div>;
};

export default LogoutPage;
