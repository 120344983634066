import { MenuItem, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { SwitchControl } from 'probonio-shared-ui/component/form';
import { Control } from 'react-hook-form';
import { SelectControl } from '../../component/form';
import { CalculatorFormValues } from './useGrossNetCalculationState';

interface InputFieldProps {
  control: Control<CalculatorFormValues>;
  labelText: string;
  name: keyof CalculatorFormValues;
  selectorOptions?: { value: number | string; label: string | number }[];
  isBoolean?: boolean;
  dataTestId?: string;
}

export const CalculatorInputField: React.FC<InputFieldProps> = ({ control, labelText, name, selectorOptions, isBoolean, dataTestId }) => {
  return (
    <Grid display="flex" justifyContent="space-between" alignItems="center">
      <Typography>{labelText}</Typography>
      {selectorOptions && (
        <SelectControl control={control} name={name} dataTestId={dataTestId}>
          {selectorOptions.map((options, index) => (
            <MenuItem key={index} value={options.value}>
              {options.label}
            </MenuItem>
          ))}
        </SelectControl>
      )}
      {isBoolean && <SwitchControl control={control} name={name} label="" controlLabelProps={{ sx: { marginRight: 0 } }} />}
    </Grid>
  );
};
