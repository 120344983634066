import { Box, Button } from '@mui/material';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Step } from '../CustomBenefitStepper';

interface Props {
  onSubmit: () => void;
  isSubmitting?: boolean;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  steps: Step[];
  canContinue: boolean;
}

const StepContent: React.FC<Props> = ({ canContinue, activeStep, setActiveStep, steps, onSubmit, isSubmitting }) => {
  const { t } = useTranslation('customBenefitsModule');

  const handleNext = useCallback(() => {
    setActiveStep(prev => prev + 1);
  }, [setActiveStep]);

  const handleBack = useCallback(() => {
    setActiveStep(prev => prev - 1);
  }, [setActiveStep]);

  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <>
      <Box flex={1}>{steps[activeStep].content()}</Box>
      <Box display="flex" justifyContent="space-between">
        <Button
          data-test-id="stepper-back-button"
          color="secondary"
          style={{ visibility: activeStep === 0 ? 'hidden' : undefined }}
          onClick={handleBack}
        >
          {t('customBenefitCreator.content.backButton')}
        </Button>

        {activeStep === steps.length - 1 ? (
          <LoadingButton data-test-id="stepper-save-button" onClick={handleSubmit} disabled={!canContinue} loading={isSubmitting}>
            {t('customBenefitCreator.content.confirmButton')}
          </LoadingButton>
        ) : (
          <Button data-test-id="stepper-next-button" onClick={handleNext} disabled={!canContinue}>
            {t('customBenefitCreator.content.nextButton')}
          </Button>
        )}
      </Box>
    </>
  );
};

export default StepContent;
