import { BenefitActivationDTO } from 'probonio-shared-ui/api';
import { useCallback, useMemo, useState } from 'react';
import { DialogState, useNewDialogState } from '../../../component/dialog';

export interface ActivationDialogState {
  activation?: BenefitActivationDTO;
  setActivationToEdit: (activation: BenefitActivationDTO) => void;
  resetActivation: () => void;
  dialogState: DialogState;
  handleOpenDialogToEdit: (activation: BenefitActivationDTO) => void;
  handleOpenDialogToCreate: () => void;
  handleOpenDialogToBulkEdit: () => void;
}

export const useActivationDialogState = (): ActivationDialogState => {
  const [activation, setActivation] = useState<BenefitActivationDTO | undefined>();
  const { dialogState, handleOpen: handleOpenDialog } = useNewDialogState();

  const setActivationToEdit = useCallback((activationToEdit: BenefitActivationDTO) => {
    setActivation(activationToEdit);
  }, []);

  const resetActivation = useCallback(() => {
    setActivation(undefined);
  }, []);

  const handleOpenDialogToEdit = useCallback(
    (activation: BenefitActivationDTO) => {
      setActivationToEdit(activation);
      handleOpenDialog();
    },
    [handleOpenDialog, setActivationToEdit],
  );

  const handleOpenDialogToCreate = useCallback(() => {
    resetActivation();
    handleOpenDialog();
  }, [handleOpenDialog, resetActivation]);

  const handleOpenDialogToBulkEdit = useCallback(() => {
    // TODO
    resetActivation();
    handleOpenDialog();
  }, [handleOpenDialog, resetActivation]);

  return useMemo(
    () => ({
      activation,
      setActivationToEdit,
      resetActivation,
      dialogState,
      handleOpenDialogToEdit,
      handleOpenDialogToCreate,
      handleOpenDialogToBulkEdit,
    }),
    [
      activation,
      setActivationToEdit,
      resetActivation,
      dialogState,
      handleOpenDialogToEdit,
      handleOpenDialogToCreate,
      handleOpenDialogToBulkEdit,
    ],
  );
};
