import { Button, ButtonProps } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicDialog, DialogState } from '.';
import { DialogLoadingButton } from './DialogLoadingButton';

interface Props {
  dataTestId?: string;
  title?: string;
  confirmButtonTitle?: string;
  confirmColor?: ButtonProps['color'];
  closeOnConfirm?: boolean;
  cancelButtonTitle?: string;
  actionButtonTitle?: string;
  actionColor?: ButtonProps['color'];
  dialogState: DialogState;
  loading?: boolean;
  onConfirm: () => void;
  onAction?: () => void;
}

export const ConfirmationModal: React.FC<React.PropsWithChildren<Props>> = ({
  dataTestId,
  children,
  title,
  confirmButtonTitle,
  confirmColor,
  closeOnConfirm = true,
  cancelButtonTitle,
  actionButtonTitle,
  actionColor,
  dialogState,
  loading,
  onConfirm,
  onAction,
}) => {
  const { t } = useTranslation('common');

  const handleConfirm = useCallback(() => {
    if (closeOnConfirm) {
      dialogState.handleClose();
    }
    onConfirm();
  }, [closeOnConfirm, dialogState, onConfirm]);

  const handleAction = useCallback(() => {
    dialogState.handleClose();
    onAction!();
  }, [dialogState, onAction]);

  return (
    <BasicDialog
      dialogState={dialogState}
      title={title || t('confirmationModal.title')}
      content={children}
      actions={
        <>
          {onAction && actionButtonTitle && (
            <Button color={actionColor || 'secondary'} variant="contained" onClick={handleAction} aria-label={actionButtonTitle}>
              {actionButtonTitle}
            </Button>
          )}
          <DialogLoadingButton
            data-test-id={dataTestId}
            color={confirmColor || 'primary'}
            variant={(confirmColor && 'outlined') || 'text'}
            onClick={handleConfirm}
            aria-label={confirmButtonTitle || t('confirmationModal.confirm')}
            loading={loading}
          >
            {confirmButtonTitle || t('confirmationModal.confirm')}
          </DialogLoadingButton>
        </>
      }
    />
  );
};
