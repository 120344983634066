import { DateTime } from 'luxon';
import { CouponGiftDTOOccasionEnum, CouponsV2GiftDTOOccasionEnum } from '../../../../../probonio-shared-ui/api';
import { fillNumberRangeArray } from '../../../util/numberRange';

export const GIFT_OCCASSION_OPTIONS: CouponGiftDTOOccasionEnum[] = [
  CouponGiftDTOOccasionEnum.Birthday,
  CouponGiftDTOOccasionEnum.ChildBaptism,
  CouponGiftDTOOccasionEnum.ChildBirth,
  CouponGiftDTOOccasionEnum.ChildCommunion,
  CouponGiftDTOOccasionEnum.ChildConfirmationCath,
  CouponGiftDTOOccasionEnum.ChildConfirmationProt,
  CouponGiftDTOOccasionEnum.PassedExam,
  CouponGiftDTOOccasionEnum.SaintsDay,
  CouponGiftDTOOccasionEnum.Wedding,
  CouponGiftDTOOccasionEnum.WeddingGold,
  CouponGiftDTOOccasionEnum.WeddingSilver,
  CouponGiftDTOOccasionEnum.CompanyAnniversary,

  CouponGiftDTOOccasionEnum.Other,
];

export const INCENTIVE_OCCASSION_OPTIONS: CouponsV2GiftDTOOccasionEnum[] = [
  CouponsV2GiftDTOOccasionEnum.Achievement,
  CouponsV2GiftDTOOccasionEnum.Commitment,
  CouponsV2GiftDTOOccasionEnum.WorkPerformance,
  CouponsV2GiftDTOOccasionEnum.Proposal,
  CouponsV2GiftDTOOccasionEnum.Recruitment,
  CouponsV2GiftDTOOccasionEnum.Attendance,
  CouponsV2GiftDTOOccasionEnum.SigningBonus,
  CouponsV2GiftDTOOccasionEnum.ProfitSharing,

  CouponsV2GiftDTOOccasionEnum.Other,
];

export const MAX_YEARLY_INCENTIVE_AMOUNT = 1000000;
export function giftYearlyAvailable(gift: CouponsV2GiftDTOOccasionEnum): boolean {
  return (
    gift === CouponGiftDTOOccasionEnum.Birthday || gift === CouponGiftDTOOccasionEnum.SaintsDay || gift === CouponGiftDTOOccasionEnum.Other
  );
}

export function getNextBirthDay(birthDate?: string): DateTime | undefined {
  if (birthDate) {
    const nextBirthDay = DateTime.fromISO(birthDate).set({ year: DateTime.now().year });
    if (nextBirthDay < DateTime.now().startOf('day')) {
      return nextBirthDay.plus({ years: 1 });
    } else {
      return nextBirthDay;
    }
  }
}

export function getUpcomingGift(couponGifts: { payoutDate: string }[]): DateTime | undefined {
  if (couponGifts.length === 0) return;
  let minDate: DateTime | undefined;
  for (const couponGift of couponGifts) {
    const payoutDate = DateTime.fromISO(couponGift.payoutDate);
    // TODO calc status in backend
    if (payoutDate > DateTime.now())
      if (!minDate) {
        minDate = payoutDate;
      } else if (minDate > payoutDate) {
        minDate = payoutDate;
      }
  }
  return minDate;
}

const MIN = 500;
const MAX = 6000;
export const GIFT_BUDGET_OPTIONS = fillNumberRangeArray(MIN, MAX, 500).sort((a, b) => a - b);

export const INCENTIVE_BUDGET_OPTIONS = [
  ...fillNumberRangeArray(1000, 9000, 1000),
  ...fillNumberRangeArray(10000, 95000, 5000),
  ...fillNumberRangeArray(100000, 1000000, 10000),
].sort((a, b) => a - b);
