import { BenefitDTOBenefitEnum, CouponGiftDTO } from 'probonio-shared-ui/api';
import { useCallback, useState } from 'react';

import GiftActivationActionsContext from './useGiftActivationModal ';
import { useDeleteGiftActivationMutation } from '../hooks/useDeleteGiftsActivationMutations';
import { useProfileEmployee } from '../hooks/useProfileEmployee';
import { useNewDialogState } from '../../../../../component/dialog';
import { DeleteActivationDialog } from '../../../../benefits';
import { GiftsDialog } from '../../../../benefits/gifts';
import { useTenant } from 'probonio-shared-ui/module/me';
import { isBenefitActive } from '../../../../benefits/BenefitIcon';

export const GiftActivationModalProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { tenant } = useTenant();
  const { employee } = useProfileEmployee();
  const giftDialog = useNewDialogState();
  const deleteDialog = useNewDialogState();
  const [giftToEdit, setGiftToEdit] = useState<CouponGiftDTO>();
  const { deleteGift } = useDeleteGiftActivationMutation();

  const handleDeleteGift = useCallback(() => {
    deleteGift(giftToEdit!);
    deleteDialog.dialogState.handleClose();
  }, [deleteDialog.dialogState, deleteGift, giftToEdit]);

  const handleOpenGiftCreate = useCallback(() => {
    setGiftToEdit(undefined);
    giftDialog.handleOpen();
  }, [giftDialog]);

  const handleOpenGiftEdit = useCallback(
    (gift: CouponGiftDTO) => {
      setGiftToEdit(gift);
      giftDialog.handleOpen();
    },
    [giftDialog],
  );

  const handleOpenGiftDelete = useCallback(
    (gift: CouponGiftDTO) => {
      setGiftToEdit(gift);
      deleteDialog.handleOpen();
    },
    [deleteDialog],
  );
  return (
    <GiftActivationActionsContext.Provider
      value={{ onGiftCreate: handleOpenGiftCreate, onGiftDelete: handleOpenGiftDelete, onGiftEdit: handleOpenGiftEdit }}
    >
      {children}
      {isBenefitActive(tenant, BenefitDTOBenefitEnum.Gifts) && (
        <GiftsDialog
          dialogState={giftDialog.dialogState}
          employeeId={employee.id}
          currentGift={giftToEdit}
          birthDate={employee?.birthDate}
          userStatus={employee?.user?.status}
        />
      )}

      {giftToEdit && (
        <DeleteActivationDialog
          messageKey="userGiftsPage.deleteConfirmation"
          dialogState={deleteDialog.dialogState}
          onDelete={handleDeleteGift}
        />
      )}
    </GiftActivationActionsContext.Provider>
  );
};
