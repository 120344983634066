import { Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const AnonymousAvatar: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar
        sx={{
          width: 45,
          height: 45,
        }}
      />
      <div style={{ marginLeft: 10 }}>
        <div>{t('common:anonymousEmployee')}</div>
      </div>
    </div>
  );
};
