import { NewsArticleDTO } from 'probonio-shared-ui/api';
import { useTranslation } from 'react-i18next';
import { BenefitTab } from '../../component/benefitTabs/BenefitTab';
import { BenefitTabPanel } from '../../component/benefitTabs/BenefitTabPanel';
import { useRouterTabsState } from '../../component/tab/tabState';
import { EmployeeFilterState } from '../../module/benefits/employeeStatusList/employeeFilterState';
import { NewsArticlesCreator } from '../../module/benefits/news/NewsArticleCreator';
import ReadConfirmationPage from './ReadConfirmationPage';

interface Props {
  article: NewsArticleDTO;
  filterState: EmployeeFilterState;
}

export const ArticlesCreatorWrapper: React.FC<Props> = ({ article, filterState }) => {
  const { t } = useTranslation('benefitsModule');
  const tabsState = useRouterTabsState(`/benefits/news/addNewArticle/${article.id}`, 'tabName');

  return (
    <BenefitTabPanel tabsState={tabsState}>
      <BenefitTab value="" label={t('NEWS.subPage.overview')} sx={{ padding: 3 }}>
        <NewsArticlesCreator article={article} />
      </BenefitTab>
      {article.readConfirmation === true && (article.articleStatus === 'PUBLISHED' || article.articleStatus === 'EXPIRED') && (
        <BenefitTab value="readConfirmation" label={t('NEWS.subPage.readConfirmation')}>
          <ReadConfirmationPage filterState={filterState} />
        </BenefitTab>
      )}
    </BenefitTabPanel>
  );
};
