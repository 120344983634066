import { useTranslation } from 'react-i18next';
import { BasicDialog, DialogState } from '../../component/dialog';
import { useForm } from 'react-hook-form';
import { Alert, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { DatePickerControl } from '../../component/form';
import { DateTime } from 'luxon';
import { useMutation } from '@tanstack/react-query';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { toISOMonthString } from '../../util/form';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { EmployeeDTOStatusEnum } from 'probonio-shared-ui/api';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import { useRefetchEmployees } from './query';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';

interface Props {
  employeeId: string;
  dialogState: DialogState;
}

interface FormValues {
  terminationDate?: Date;
}

export const TerminateEmploymentDialog: React.FC<Props> = ({ employeeId, dialogState }) => {
  const { t } = useTranslation('usersModule');
  const getTenantId = useTenantID();
  const withMessage = useWithMessage();
  const refetchEmployees = useRefetchEmployees();
  const { enqueueSnackbar } = useSnackbar();
  const [benefitError, setBenefitError] = useState(false);

  const { control, handleSubmit, reset, watch } = useForm<FormValues>();
  const terminationDate = watch('terminationDate');

  useEffect(() => {
    if (dialogState.isOpen) {
      reset();
    }
  }, [dialogState.isOpen, reset]);

  const updateMutation = useMutation({
    mutationFn: async (terminationDate?: Date) => {
      setBenefitError(false);
      await apis.employee.updateEmployee({
        tenantId: getTenantId(),
        employeeId,
        updateEmployeeDTO: terminationDate
          ? { terminationDate: toISOMonthString(terminationDate) }
          : { status: EmployeeDTOStatusEnum.Inactive },
      });
    },

    onSuccess: async () => {
      enqueueSnackbar(t('userForm.terminateEmployment.deactivateSuccess'), { variant: 'success' });
      await refetchEmployees();
    },
    onError: err => {
      if (err instanceof AxiosError && err.response?.status === 422) {
        setBenefitError(true);
      } else {
        withMessage(err as Error);
      }
    },
  });

  const handleConfirm = useCallback(
    async (values: FormValues) => {
      try {
        await updateMutation.mutateAsync(values.terminationDate);
        dialogState.handleClose();
      } catch {
        // handled by withMessage
      }
    },
    [dialogState, updateMutation],
  );

  const handleSubmitEvent = useCallback(
    (event: React.FormEvent) => {
      event.stopPropagation();
      void handleSubmit(handleConfirm)(event);
    },
    [handleConfirm, handleSubmit],
  );

  return (
    <BasicDialog
      dialogState={dialogState}
      title={t('userForm.terminateEmployment.dialogTitle')}
      content={
        <>
          <Typography color="text.secondary" mb={1}>
            {t('userForm.terminateEmployment.infoText')}
          </Typography>
          <Typography color="text.secondary" mb={2}>
            {t('userForm.terminateEmployment.planInfo')}
          </Typography>
          {benefitError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {t('userForm.terminateEmployment.terminationNotPossible')}
            </Alert>
          )}
          <DatePickerControl
            fullWidth
            control={control}
            name="terminationDate"
            label={t('userForm.field.terminationDate.label')}
            minDate={DateTime.now().startOf('month').toJSDate()}
            monthYearPicker
          />
        </>
      }
      onSubmit={handleSubmitEvent}
      actions={
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          loading={updateMutation.isPending}
          data-test-id="confirm-terminate"
        >
          {t(terminationDate ? 'userForm.terminateEmployment.planTerminate' : 'userForm.terminateEmployment.terminateNow')}
        </LoadingButton>
      }
    />
  );
};
