import { Box, styled } from '@mui/material';
import { spacings } from '../../theme';

export const FullWidthCenterBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
}));

export const GradientBox = styled(Box)(({ theme }) => ({
  background: `linear-gradient(to bottom, ${theme.palette.background.default} 0%, #ffffff 100%)`,
  // needed for backgroundHider used in Parent Component
  // which has to be above this to hide this Box
  zIndex: -10,
}));

export const HorizontalBox = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
}));

export const VerticalBox = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
}));

export const MaxWidthBox = styled(Box)(() => ({
  maxWidth: spacings.maxWidth,
  width: '100%',
}));

// full height for main content excluding navbar
export const FullHeightBox = styled(Box)(() => ({
  // 64px toolbar + 21px breadcrumb + 40px main padding
  height: 'calc(100vh - 125px)',
}));
