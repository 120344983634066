import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FullWidthCenterBox, HorizontalBox, VerticalBox } from '../layout/BoxLayout';

export const ScreenSizeWarning: React.FC = () => {
  const { t } = useTranslation('errorModule');
  return (
    <VerticalBox>
      <HorizontalBox height="100vh" alignItems="center">
        <FullWidthCenterBox>
          <VerticalBox gap={2}>
            <img src="/image/mobileError/Logo.svg" />

            <Typography variant="h1">{t('pageSizeToTiny.header')}</Typography>
            <Typography alignSelf="center" variant="subtitle2">
              {t('pageSizeToTiny.text')}
            </Typography>
          </VerticalBox>
        </FullWidthCenterBox>
      </HorizontalBox>
      <HorizontalBox height="10%" />
    </VerticalBox>
  );
};
