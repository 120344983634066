import { Alert, Box, Divider } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { BenefitExpensesDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BasicDialog, DialogState } from '../../../../component/dialog';
import { DialogLoadingButton } from '../../../../component/dialog/DialogLoadingButton';
import { DialogTab, DialogTabs, useTabsState } from '../../../../component/tab';
import { FormFields } from '../../../tenant/TenantBillingAddressPanelForm';
import { TenantBillingAddressPanel } from '../../../tenant/TenantBillingAdressPanel';
import { AutoBudgetContent } from './AutoBudgetContent';
import { ManualIncreaseBudgetDialogContent } from './ManualIncreseDialogContent';

export interface IncreaseBudgetValues {
  amount: number;
  autoBudget: boolean;
}

interface Props {
  isLoading: boolean;
  expenses?: BenefitExpensesDTO;
  onIncreaseBudget: (budget: number) => void;
  onSetAutoBudget: (enabled: boolean) => void;
  dialogState: DialogState;
}

export const CouponsIncreaseBudgetDialog: React.FC<Props> = ({ expenses, dialogState, onIncreaseBudget, onSetAutoBudget, isLoading }) => {
  const { t } = useTranslation('couponsModule');
  const { enqueueSnackbar } = useSnackbar();
  const { handleTabChange, tabIndex } = useTabsState();
  const formContext = useForm<IncreaseBudgetValues>({
    mode: 'onChange',
  });

  const [isEditMode, setIsEditMode] = useState(false);
  const { tenant, invalidateTenant } = useTenant();

  useEffect(() => {
    if (dialogState.isOpen === false) {
      setIsEditMode(false);
    }

    if (!(tenant?.street && tenant.postalCode && tenant.city)) {
      setIsEditMode(true);
    }
  }, [dialogState.isOpen, tenant?.city, tenant?.postalCode, tenant?.street]);

  const mutation = useMutation({
    mutationFn: async (values: FormFields) => {
      await apis.tenants.updateTenant({
        tenantId: tenant!.id,
        updateTenantDTO: {
          name: values.tenantName,
          street: values.tenantStreet,
          postalCode: values.tenantPostalCode,
          city: values.tenantCity,
        },
      });
    },

    onSuccess: () => {
      enqueueSnackbar(t('tenantModule:paymentSettings.successToast'), { variant: 'success' });
      invalidateTenant();
    },
  });

  const handleSaveBillingAddress = useCallback(
    (values: FormFields) => {
      mutation.mutate(values);
      setIsEditMode(false);
    },
    [mutation, setIsEditMode],
  );

  const handleEditBillingAddress = useCallback(() => {
    setIsEditMode(true);
  }, [setIsEditMode]);

  useEffect(() => {
    if (dialogState.isOpen) {
      handleTabChange(undefined, 0);
    }
  }, [dialogState.isOpen, handleTabChange]);

  const handleSave = useCallback(
    (values: IncreaseBudgetValues) => {
      switch (tabIndex) {
        case 0:
          // The input is in €, we need Cent.
          onIncreaseBudget(Math.round(values.amount * 100));
          break;
        case 1:
          onSetAutoBudget(values.autoBudget);
          break;
        default:
      }
    },
    [onIncreaseBudget, onSetAutoBudget, tabIndex],
  );

  return (
    <BasicDialog
      dialogState={dialogState}
      title={t(`budgetPanelIncreaseBudgetDialog.title`)}
      maxWidth={700}
      content={
        <>
          <Box>
            <DialogTabs variant="fullWidth" value={tabIndex} onChange={handleTabChange}>
              <DialogTab label={t('budgetPanelIncreaseBudgetDialog.tabs.manual.title')} />
              <DialogTab label={t('budgetPanelIncreaseBudgetDialog.tabs.automatic.title')} />
            </DialogTabs>
          </Box>
          <Box sx={{ marginTop: 4, marginX: 2 }}>
            <FormProvider {...formContext}>
              {tabIndex === 0 && <ManualIncreaseBudgetDialogContent expenses={expenses} />}
              {tabIndex === 1 && <AutoBudgetContent />}
            </FormProvider>
          </Box>
          <Divider sx={{ marginTop: 4 }} />
          <Box marginTop={3} marginX={2}>
            <TenantBillingAddressPanel
              tenant={tenant!}
              isEditMode={isEditMode}
              handleSave={handleSaveBillingAddress}
              handleEdit={handleEditBillingAddress}
            />
          </Box>
          {tenant?.street && tenant?.postalCode && tenant?.city ? null : (
            <Alert severity="warning" sx={{ marginX: 1 }}>
              {t(`budgetPanelIncreaseBudgetDialog.billingAddressPanel.alert`)}
            </Alert>
          )}
        </>
      }
      actions={
        <DialogLoadingButton
          loading={isLoading}
          onClick={formContext.handleSubmit(handleSave)}
          disabled={!formContext.formState.isValid || isEditMode || !(tenant?.street && tenant?.postalCode && tenant?.city)}
          data-test-id="increase-budget-dialog-submit-button"
          variant="text"
        >
          {tabIndex === 0 && t(`budgetPanelIncreaseBudgetDialog.tabs.manual.actions.confirm`)}
          {tabIndex === 1 && t(`budgetPanelIncreaseBudgetDialog.tabs.automatic.actions.confirm`)}
        </DialogLoadingButton>
      }
    />
  );
};
