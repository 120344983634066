import { useTranslation } from 'react-i18next';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';
import { LegalTermsInfo } from './LegalTermsInfo';
import { useTenant } from 'probonio-shared-ui/module/me';
import { TenantDTOLegalTermsActionEnum } from 'probonio-shared-ui/api';
import { BenefitPaper } from '../../component/benefitTabs/BenefitPaper';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import PolicyIcon from '@mui/icons-material/Policy';

export const LegalTermsPage: React.FC = () => {
  const { t } = useTranslation('navigationModule');

  const { tenant } = useTenant();
  const legalTermsAction =
    tenant?.legalTermsAction !== TenantDTOLegalTermsActionEnum.None
      ? TenantDTOLegalTermsActionEnum.Consent
      : TenantDTOLegalTermsActionEnum.None;

  return (
    <AuthorizedLayout title={t('legalTerms:title')}>
      <BenefitSubPage title={t('legalTerms:longTitle')} icon={PolicyIcon}>
        <BenefitPaper>{tenant && <LegalTermsInfo tenant={tenant} action={legalTermsAction} />}</BenefitPaper>
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};
