import { Accordion, AccordionSummary, Typography, AccordionDetails, styled } from '@mui/material';
import React, { useMemo } from 'react';
import { CouponDefinitionDTO } from 'probonio-shared-ui/api';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

interface Props {
  coupons?: CouponDefinitionDTO[];
  amount?: number;
  showAmount?: boolean;
}

const StyledTypography = styled(Typography)(({ theme }) => {
  return {
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
  };
});

const isAvailableCoupon = (monthlyBudget: number, coupon: CouponDefinitionDTO): boolean => {
  const variant = coupon.variations?.find(variation => variation === monthlyBudget);
  return variant !== undefined;
};
const isCombinationCoupon = (monthlyBudget: number, coupon: CouponDefinitionDTO): boolean => {
  const combination = coupon.combinations?.find(combination => combination === monthlyBudget);
  return combination !== undefined;
};

export const AvailableCouponsAccordion: React.FC<Props> = ({ coupons, amount, showAmount }) => {
  const { t } = useTranslation('benefitsModule');
  const [availableCoupons, combinationCoupons, unavailableCoupons] = useMemo(() => {
    const availableCoupons: CouponDefinitionDTO[] = [];
    const combinationCoupons: CouponDefinitionDTO[] = [];
    const unavailableCoupons: CouponDefinitionDTO[] = [];
    coupons?.forEach(coupon => {
      if (isAvailableCoupon(amount || 0, coupon)) {
        availableCoupons.push(coupon);
      } else if (isCombinationCoupon(amount || 0, coupon)) {
        combinationCoupons.push(coupon);
      } else {
        unavailableCoupons.push(coupon);
      }
    });
    return [availableCoupons, combinationCoupons, unavailableCoupons];
  }, [coupons, amount]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h3" color="text.secondary">
          {t(showAmount ? 'COUPONS.availableTitleWithAmount' : 'COUPONS.availableTitle', {
            amount,
            count: (availableCoupons?.length || 0) + (combinationCoupons?.length || 0),
          })}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <StyledTypography paddingBottom={1.5}>
          <u>{t('COUPONS.available')}</u> {availableCoupons?.map(coupon => coupon.name).join(', ')}
        </StyledTypography>
        {!!combinationCoupons?.length && (
          <StyledTypography paddingBottom={1.5}>
            <u>{t('COUPONS.split')}</u> {combinationCoupons?.map(coupon => coupon.name).join(', ')}
          </StyledTypography>
        )}
        {!!unavailableCoupons?.length && (
          <StyledTypography>
            <u>{t('COUPONS.unavailable')}</u> {unavailableCoupons?.map(coupon => coupon.name).join(', ')}
          </StyledTypography>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
