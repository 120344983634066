import EditIcon from '@mui/icons-material/Edit';
import { Button, Tooltip } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RowSelectionState } from '../../../component/table/useManualRowSelection';
import { useBulkBenefitActivationModal } from './useBulkBenefitActivationModal';
import { useBenefitContext } from '../BenefitContext';

interface Props {
  rowSelectionState: RowSelectionState;
}

export const BulkEditActivationButton: React.FC<Props> = ({ rowSelectionState }) => {
  const { t } = useTranslation('benefitsModule');
  const { benefit, customBenefitId } = useBenefitContext();
  const { onBenefitEdit } = useBulkBenefitActivationModal();

  const noUsersSelected = !rowSelectionState.someRowsSelected;

  const handleOpenDialogToEdit = useCallback(() => {
    onBenefitEdit(benefit, customBenefitId);
  }, [benefit, customBenefitId, onBenefitEdit]);

  return (
    <>
      <Tooltip arrow placement="top" title={t('employeeTabs.pleaseSelect')} disableHoverListener={!noUsersSelected}>
        <span>
          <Button
            data-test-id="employee-benefit-configure-button"
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            disabled={noUsersSelected}
            onClick={handleOpenDialogToEdit}
          >
            {rowSelectionState.allSelected
              ? t('employeeTabs.editConfigurationAll')
              : t('employeeTabs.editConfiguration', { count: rowSelectionState.selectedCount })}
          </Button>
        </span>
      </Tooltip>
    </>
  );
};
