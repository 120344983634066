import SettingsIcon from '@mui/icons-material/Settings';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useTranslation } from 'react-i18next';
import { BenefitTab } from '../component/benefitTabs/BenefitTab';
import { BenefitTabPanel } from '../component/benefitTabs/BenefitTabPanel';
import { useRouterTabsState } from '../component/tab/tabState';
import BenefitSubPage from '../module/benefits/BenefitSubPage';
import { AuthorizedLayout } from '../module/navigation/AuthorizedLayout';
import { PaymentSettingsPanel } from '../module/tenant/PaymentSettingsPanel';
import { HRSystemPanel } from '../module/tenant/hrSystem/HRSystemPanel';
import { BenefitTemplatesPanel } from '../module/tenant/benefitTemplates/BenefitTemplatesPanel';
import { CustomisationSettingsPanel } from '../module/tenant/CustomisationSettingsPanel';

const TenantSettingsPage: React.FC = () => {
  const { t } = useTranslation('tenantModule');
  const { tenant } = useTenant();

  const tabsState = useRouterTabsState('/settings', 'tabName');

  return (
    <AuthorizedLayout title={t('title')}>
      <BenefitSubPage title={t('title')} subTitle={tenant?.name} icon={SettingsIcon}>
        <BenefitTabPanel tabsState={tabsState}>
          <BenefitTab value="" label={t('tab.paymentSettings')} sx={{ padding: 3 }}>
            <PaymentSettingsPanel />
          </BenefitTab>
          <BenefitTab value="customisation" label={t('tab.customisationSettings')} sx={{ padding: 3 }}>
            <CustomisationSettingsPanel />
          </BenefitTab>
          <BenefitTab value="benefitTemplates" label={t('tab.benefitTemplates')} sx={{ padding: 3 }}>
            <BenefitTemplatesPanel />
          </BenefitTab>
          <BenefitTab value="hrSystem" label={t('tab.hrSystem')} sx={{ padding: 3 }}>
            <HRSystemPanel />
          </BenefitTab>
        </BenefitTabPanel>
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};

export default TenantSettingsPage;
