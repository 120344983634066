import { MRT_ColumnDef } from 'material-react-table';
import { BikeContractDTO } from 'probonio-shared-ui/api';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { renderUserName } from '../../userManagement/userFormatter';

export function useBikeContractColumns(): MRT_ColumnDef<BikeContractDTO>[] {
  const { t } = useTranslation('bikeModule');

  return useMemo<MRT_ColumnDef<BikeContractDTO>[]>(
    () => [
      {
        id: 'name',
        accessorFn: contract => contract.employee?.user && renderUserName(contract.employee.user),
        header: t('contractsTable.header.name'),
      },
      {
        accessorKey: 'contractNumber',
        header: t('contractsTable.header.contractNumber'),
      },
      {
        accessorKey: 'leasingStartDate',
        header: t('contractsTable.header.leasingStartDate'),
        Cell: ({ cell }) => (cell.getValue<string>() ? t('common:date', { date: new Date(cell.getValue<string>()) }) : null),
      },
      {
        accessorKey: 'leasingEndDate',
        header: t('contractsTable.header.leasingEndDate'),
        Cell: ({ cell }) => (cell.getValue<string>() ? t('common:date', { date: new Date(cell.getValue<string>()) }) : null),
      },
      {
        accessorKey: 'status',
        header: t('contractsTable.header.status'),
      },
    ],
    [t],
  );
}
