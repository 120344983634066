import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Button, Stack } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardPanel } from '../../component/dashboard/DashboardPanel';
import { SearchInput } from '../../component/form/SearchInput';
import { useEmployeeFilterState } from '../benefits/employeeStatusList/employeeFilterState';
import { DashboardEmployeeList } from './DashboardEmployeeList';
import { AddUserDialog } from '../userManagement/AddUserDialog/AddUserDialog';
import { useNewDialogState } from '../../component/dialog';

export const EmployeeListDashboardPanel: React.FC<{ rows?: number }> = ({ rows }) => {
  const { t } = useTranslation('dashboardModule');
  const filterState = useEmployeeFilterState();

  const addUserDialog = useNewDialogState();

  const handleChangeSearchText = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    event => {
      const setFilter = filterState.setFilter;
      setFilter(prev => ({ ...prev, searchText: event.target.value }));
    },
    [filterState.setFilter],
  );

  return (
    <DashboardPanel
      rows={rows}
      title={t('employeesPanel.recentCreatedAt')}
      actions={
        <Stack direction="row" spacing={1}>
          <SearchInput value={filterState.filter.searchText} onChange={handleChangeSearchText} size="small" sx={{ width: 225 }} />
          <Button size="small" color="secondary" title={t('usersPage:addUser')} onClick={addUserDialog.handleOpen}>
            <PersonAddIcon />
          </Button>
        </Stack>
      }
    >
      <DashboardEmployeeList data-test-id="users-list" filter={filterState.debouncedFilter} />
      <AddUserDialog dialogState={addUserDialog.dialogState} />
    </DashboardPanel>
  );
};
