import { Alert } from '@mui/material';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useTranslation } from 'react-i18next';
import { useBenefitActivations } from '../ActivationsList/useBenefitActivations';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { DateTime } from 'luxon';

interface Props {
  employeeId?: string;
  benefit: BenefitDTOBenefitEnum;
  customBenefitId?: string;
  monthlyBudget?: number;
}

const MAX = 5000;

export const NonCashLimitWarning: React.FC<Props> = ({ employeeId, benefit, customBenefitId, monthlyBudget }) => {
  const { t } = useTranslation('benefitsModule');
  const { watch, setError, clearErrors, formState } = useFormContext();

  const formStartDate: Date = watch('startDate') || formState.defaultValues?.startDate;
  const startDate = DateTime.fromJSDate(formStartDate) < DateTime.now() ? DateTime.now().startOf('month').toJSDate() : formStartDate;
  const endDate: Date = watch('endDate');

  const { data: activations } = useBenefitActivations(employeeId, {
    startDate,
    endDate,
    excludeBenefit: benefit,
    excludeCustomBenefitId: customBenefitId,
  });

  const usedNonCashSum = activations?.nonCashBenefitSum || 0;

  const exceededNonCashLimitAmount = usedNonCashSum + (monthlyBudget || 0) - MAX;
  const hasExceededNonCashLimit = exceededNonCashLimitAmount > 0;

  useEffect(() => {
    if (hasExceededNonCashLimit) {
      setError('root', { message: 'error' });
      return () => {
        clearErrors('root');
      };
    }
  }, [clearErrors, hasExceededNonCashLimit, setError]);

  return (
    <>
      {hasExceededNonCashLimit && (
        <Alert severity="warning" sx={{ mb: 1 }}>
          {t('nonCashLimitWarning', { exceededNonCashLimitAmount })}
        </Alert>
      )}
      {!employeeId && (
        <Alert severity="info" sx={{ mb: 1 }}>
          {t('nonCashLimitWarningGeneric', { benefit })}
        </Alert>
      )}
    </>
  );
};
