import { DateTime } from 'luxon';

export class ValidationError extends Error {}

export class ImportError extends Error {
  public constructor(
    message: string,
    public readonly lineNumber: number,
  ) {
    super(message);
  }
}

export function parseDateTime(value: unknown): string | undefined {
  if (value instanceof Date) {
    return DateTime.fromJSDate(value).toISO()!;
  } else if (typeof value === 'string') {
    let date = DateTime.fromISO(value.trim());
    if (!date.isValid) {
      date = DateTime.fromSQL(value.trim());
    }
    if (!date.isValid) {
      throw new ValidationError('Expected ISO date');
    }
    return date.toISO()!;
  } else if (value) {
    throw new ValidationError('Expected ISO date');
  }
}

export function parseDate(value: unknown): string | undefined {
  return parseDateTime(value)?.substring(0, 10);
}

export function parseEuro(value: unknown): number | undefined {
  if ((typeof value === 'string' && value.trim()) || typeof value === 'number') {
    return Math.round(Number(value) * 100);
  }
}

export function parseInt(value: unknown): number | undefined {
  if (typeof value === 'string' && value.trim()) {
    const result = Math.trunc(Number(value.trim()));
    if (Number.isNaN(result)) {
      throw new ValidationError('Expected integer number');
    }
    return result;
  } else if (typeof value === 'number') {
    return Math.trunc(value);
  } else if (value) {
    throw new ValidationError('Expected integer number');
  }
}

export function parseFloat(value: unknown): number | undefined {
  if (typeof value === 'string' && value.trim()) {
    const result = Number(value.trim());
    if (Number.isNaN(result)) {
      throw new ValidationError('Expected float number');
    }
    return result;
  } else if (typeof value === 'number') {
    return value;
  } else if (value) {
    throw new ValidationError('Expected float number');
  }
}

export function parseBool(value: unknown): boolean {
  const trimmed = typeof value === 'string' ? value.trim().toLowerCase() : value;

  if (value === null || value === undefined) {
    return false;
  }
  if (trimmed === 'ja' || trimmed === 'yes' || trimmed === 'y' || trimmed === 'true' || trimmed === '1' || trimmed === 1) {
    return true;
  } else if (trimmed === 'nein' || trimmed === 'no' || trimmed === 'n' || trimmed === 'false' || trimmed === '0' || trimmed === 0) {
    return false;
  } else {
    throw new ValidationError('Expected boolean-like value');
  }
}

export function parseString(value: unknown): string | undefined {
  if (typeof value === 'string' && value.trim()) {
    return value.trim();
  } else if (typeof value === 'number') {
    return value.toString();
  }
}

/**
 * defines a mapping from target object T's fields to a RegExp to search for in the Excel file's columns.
 * If an array is given, the second entry specifies the parser function to transform the data. If no
 * parser is given, it defaults to string.
 */
export type ExcelFieldMapping<T> = {
  [K in keyof T]: RegExp | [RegExp, (value: unknown) => T[K] | undefined];
};

export class ExcelFileParser<T> {
  public constructor(
    private readonly fieldMapping: ExcelFieldMapping<T>,
    private readonly requiredFields: (keyof T)[],
  ) {}

  public async parseExcelFile(file: File): Promise<T[]> {
    const XLSX = await import('xlsx');
    // read XLSX file and transform rows to JSON objects
    const buffer = await file.arrayBuffer();
    const workbook = XLSX.read(buffer, { cellDates: true });
    const sheet = workbook.Sheets[workbook.SheetNames[0]];
    const rawData = XLSX.utils.sheet_to_json(sheet, { defval: null }) as Record<string, unknown>[];

    // map the fields in each row to the target fields defined by fieldMapping
    const parsedObjects = rawData.map((row, index) => {
      const lineNumber = index + 1;

      // the other fields are defined by fieldMapping
      const partialParsedObject = (Object.keys(this.fieldMapping) as (keyof T)[]).reduce((partialObject, field) => {
        // for each field, the mapping specifies a lookup RegExp and optionally a parser function (defaults to string)
        const mapping = this.fieldMapping[field]!;
        const fieldRegExp = Array.isArray(mapping) ? mapping[0] : (mapping as RegExp);
        const parser = Array.isArray(mapping) ? mapping[1] : parseString;

        // find the field that matches the RegExp from the mapping
        const matchingField = Object.keys(row).find(key => fieldRegExp.test(key));
        if (matchingField) {
          // transform using the given parser and add to target object
          try {
            const parsedValue = parser(row[matchingField]);
            return { ...partialObject, [field]: parsedValue === undefined ? null : parsedValue };
          } catch (err) {
            if (err instanceof ValidationError) {
              throw new ImportError(`Validation error in field ${String(field)}: ${err.message}`, lineNumber);
            }
          }
        }
        return partialObject;
      }, {} as Partial<T>);
      // after parsing, validate that the required fields are present
      this.requiredFields.forEach(field => {
        if (partialParsedObject[field] === null || partialParsedObject[field] === undefined) {
          throw new ImportError(`Missing required field ${String(field)}`, lineNumber);
        }
      });
      return partialParsedObject as T;
    });
    return parsedObjects;
  }
}
