import React from 'react';
import { EmployeeFilterState } from '../../module/benefits/employeeStatusList/employeeFilterState';
import { ReadConfirmationList } from './ReadConfirmationList';

interface Props {
  filterState: EmployeeFilterState;
}

export const ReadConfirmationPage: React.FC<Props> = ({ filterState }) => {
  return (
    <>
      <ReadConfirmationList filter={filterState.debouncedFilter} filterState={filterState} />
    </>
  );
};

export default ReadConfirmationPage;
