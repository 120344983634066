import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useBikeleasingPrecondition } from './bikeleasing/useBikeleasingPrecondition';

export interface BenefitPrecondition {
  isPreconditionMet: boolean;
  PreconditionComponent: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BENEFIT_PRECONDITION_HOOKS: Partial<Record<BenefitDTOBenefitEnum, () => BenefitPrecondition>> = {
  BIKELEASING: useBikeleasingPrecondition,
};
