import MailOutline from '@mui/icons-material/MailOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Stack } from '@mui/material';
import { InviteUsersButton } from './InviteUsersButton';
import { UnlockAllUsersButton } from './UnlockAllUsersButton';
import { EmployeeFilter, mapEmployeeFilterToRequest } from '../benefits/employeeStatusList/employeeFilterState';
import { useMemo } from 'react';
import { FilterEmployeesDTO, UserDTOStatusEnum } from 'probonio-shared-ui/api';

interface Props {
  employeeFilter?: EmployeeFilter;
}

export const UserActionButtons = ({ employeeFilter }: Props): JSX.Element => {
  const reinviteUsersFilter = useMemo(
    (): FilterEmployeesDTO => ({
      ...mapEmployeeFilterToRequest(employeeFilter),
      onlyExpiredRegistrations: true,
    }),
    [employeeFilter],
  );

  const inviteNewUsersFilter = useMemo(
    (): FilterEmployeesDTO => ({
      ...mapEmployeeFilterToRequest(employeeFilter),
      userStatus: UserDTOStatusEnum.Created,
    }),
    [employeeFilter],
  );

  return (
    <Stack direction="row" spacing={1}>
      <InviteUsersButton
        filter={reinviteUsersFilter}
        tooltipTextKey="usersPage:reinviteUsersButton.buttonText"
        icon={<RefreshIcon />}
        dialogTextKey="usersPage:reinviteUsersButton.confirmText"
        titleTextKey="usersPage:reinviteUsersButton.confirmTitle"
      />
      <UnlockAllUsersButton filter={employeeFilter} />
      <InviteUsersButton
        filter={inviteNewUsersFilter}
        icon={<MailOutline />}
        tooltipTextKey="usersPage:inviteNewUsersButton.buttonText"
        dialogTextKey="usersPage:inviteNewUsersButton.confirmText"
        titleTextKey="usersPage:inviteNewUsersButton.confirmTitle"
      />
    </Stack>
  );
};
