import { Slider, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useCallback } from 'react';

interface BenefitSliderProps {
  icon: React.ReactNode;
  label: string;
  value: number;
  onChange: (value: number) => void;
  perStep: number;
  maxStep: number;
  isPrice?: boolean;
  dataTestId?: string;
}

export const handleLabelFormat = (value: number): string => {
  return `${value} €`;
};

export const BenefitSlider: React.FC<BenefitSliderProps> = ({ icon, label, value, onChange, perStep, maxStep, isPrice, dataTestId }) => {
  const theme = useTheme();

  const handleChange = useCallback(
    (event: Event, value: number | number[]) => {
      onChange(value as number);
    },
    [onChange],
  );

  return (
    <Grid display="flex" flexDirection="column" justifyContent="space-between">
      <Grid display="flex" gap={1} color={theme.palette.primary.main}>
        {icon}
        <Typography variant="body1">{label}</Typography>
      </Grid>
      <Slider
        sx={{ mx: 1 }}
        marks
        step={perStep}
        max={maxStep}
        value={value}
        valueLabelDisplay="auto"
        onChange={handleChange}
        valueLabelFormat={isPrice ? handleLabelFormat : undefined}
        data-test-id={dataTestId}
      />
    </Grid>
  );
};
