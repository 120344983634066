import { Typography } from '@mui/material';
import { SwitchControl } from 'probonio-shared-ui/component/form';
import React, { Suspense } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextFieldControl } from '../../../../../../component/form';
import { VerticalBox } from '../../../../../../component/layout/BoxLayout';
import { FormValues } from '../../../useCustomBenefitCreator';
import { DashboardContentImage } from './DashboardContentImage';
import { DashboardContentValidation } from './DashboardContentValidation';

const DashboardContentIcons = React.lazy(() => import('./DashboardContentIcons'));

interface Props {
  setCanContinue: (newValue: boolean) => void;
  control: Control<FormValues>;
}

export const DashboardContent: React.FC<Props> = ({ control, setCanContinue }) => {
  const { t } = useTranslation('customBenefitsModule');

  return (
    <>
      <VerticalBox gap={2.5}>
        <div>
          <Typography variant="h2">{t('customBenefitCreator.content.summaryStep.title')}</Typography>
          <Typography variant="caption">{t('customBenefitCreator.content.summaryStep.description')}</Typography>
        </div>
        <div>
          <SwitchControl label={t('customBenefitCreator.content.summaryStep.switch')} name="showAsTile" control={control} />
          <br />
          <Typography variant="caption">{t('customBenefitCreator.content.summaryStep.switchHelperText')}</Typography>
        </div>
        <div>
          <TextFieldControl
            data-test-id="custom-benefit-creator-page-title"
            control={control}
            rules={{ required: true }}
            name="title"
            label={t('customBenefitCreator.content.summaryStep.inputTitle')}
          />
          <br />
          <Typography variant="caption">{t('customBenefitCreator.content.summaryStep.inputHelperText')}</Typography>
        </div>
        {/* TODO: Dropzone für Bild */}
        <div>
          <DashboardContentImage control={control} />
        </div>
        <div>
          <Suspense fallback={<div />}>
            <DashboardContentIcons control={control} />
          </Suspense>
        </div>
      </VerticalBox>
      <DashboardContentValidation control={control} setCanContinue={setCanContinue} />
    </>
  );
};
