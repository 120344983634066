import { UserDTO } from 'probonio-shared-ui/api';
import i18n from '../../lang';

export function renderUserName(
  user?: Pick<UserDTO, 'firstName' | 'lastName' | 'mail'>,
  employeeNumber?: string,
  isInactive?: boolean,
): string {
  if (!user) {
    return employeeNumber ? i18n.t('common:anonymousEmployeeWithNumber', { employeeNumber }) : i18n.t('common:anonymousEmployee');
  }
  if (!user.mail) {
    return '';
  }
  return `${user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.mail}${
    isInactive ? ` (${i18n.t('usersModule:statusTerminated')})` : ''
  }`;
}
