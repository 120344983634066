import { Box, Divider, Typography, useTheme } from '@mui/material';
import { BenefitDTOBenefitEnum, BenefitExpensesDTO, TenantDTO } from 'probonio-shared-ui/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HorizontalBox, VerticalBox } from '../../../../component/layout/BoxLayout';
import { BENEFIT_ICONS } from '../../BenefitIcon';

const ExpensesBox: React.FC<{
  couponExpenses: number | undefined;
  giftExpenses: number | undefined;
  title: string;
  withIcon?: boolean;
  fontColor?: string;
}> = ({ couponExpenses, giftExpenses, title, withIcon = false, fontColor = '' }) => {
  const { t } = useTranslation('couponsModule');
  const CouponsIcon = BENEFIT_ICONS.COUPONS;
  const GiftIcon = BENEFIT_ICONS.GIFTS;

  return (
    <VerticalBox justifyContent="space-evenly">
      <Typography color={fontColor} variant="body2">
        {title}
      </Typography>
      <Box display="flex" justifyContent="center" gap={0.5}>
        {withIcon && (
          <Box display="flex" flexDirection="column">
            {(couponExpenses || couponExpenses === 0) && <CouponsIcon color="primary" fontSize="small" />}
            {(giftExpenses || giftExpenses === 0) && <GiftIcon color="primary" fontSize="small" />}
          </Box>
        )}
        <Box textAlign="right">
          {(couponExpenses || couponExpenses === 0) && (
            <Typography color={fontColor} variant="body2" fontWeight={500}>
              {t('common:money', { money: couponExpenses })}
            </Typography>
          )}
          {(giftExpenses || giftExpenses === 0) && (
            <Typography color={fontColor} variant="body2" fontWeight={500}>
              {t('common:money', { money: giftExpenses })}
            </Typography>
          )}
        </Box>
      </Box>
    </VerticalBox>
  );
};

interface Props {
  tenant: TenantDTO | undefined;
  expenses: BenefitExpensesDTO | undefined;
}

export const CouponBudgetMonthlyCost: React.FC<Props> = ({ tenant, expenses }) => {
  const theme = useTheme();
  const { t } = useTranslation('couponsModule');

  const hasCouponsBenefit = !!tenant?.activeBenefits?.includes(BenefitDTOBenefitEnum.Coupons);
  const hasGiftsBenefit = !!tenant?.activeBenefits?.includes(BenefitDTOBenefitEnum.Gifts);

  return (
    <>
      <HorizontalBox textAlign="center" justifyContent="space-between" flexGrow={1}>
        <ExpensesBox
          title={t('budgetPanel.previousExpenses')}
          fontColor="text.secondary"
          couponExpenses={hasCouponsBenefit ? expenses?.benefits.COUPONS?.expensesPreviousMonth : undefined}
          giftExpenses={hasGiftsBenefit ? expenses?.benefits.GIFTS?.expensesPreviousMonth : undefined}
        />
        <Divider orientation="vertical" flexItem color={theme.palette.secondary.main} />
        <ExpensesBox
          title={t('budgetPanel.expectedExpenses')}
          withIcon
          couponExpenses={hasCouponsBenefit ? expenses?.benefits.COUPONS?.expectedExpensesMonth : undefined}
          giftExpenses={hasGiftsBenefit ? expenses?.benefits.GIFTS?.expectedExpensesMonth : undefined}
        />
        <Divider orientation="vertical" flexItem color={theme.palette.secondary.main} />
        <ExpensesBox
          title={t('budgetPanel.upcomingExpenses')}
          fontColor="text.secondary"
          couponExpenses={hasCouponsBenefit ? expenses?.benefits.COUPONS?.expectedUpcomingExpenses : undefined}
          giftExpenses={hasGiftsBenefit ? expenses?.benefits.GIFTS?.expectedUpcomingExpenses : undefined}
        />
      </HorizontalBox>
    </>
  );
};
