import { Box, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTenant } from 'probonio-shared-ui/module/me';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogState } from '../../../component/dialog';
import { CopyToClipboardButton } from 'probonio-shared-ui/component/button/CopyToClipboardButton';
import { fontWeights } from '../../../theme';
import { AddUserAccordion } from './AddUserAccordion';

interface Props {
  dialogState: DialogState;
}

export function formatRegistrationCode(code?: string): string | undefined {
  if (code) {
    const matches = /(\w{4})(\w{4})(\w{4})?/.exec(code);
    return matches ? `${matches[1]}-${matches[2]}${matches[3] ? '-' : ''}${matches[3] || ''}` : undefined;
  }
}

function formatRegistrationLink(code?: string): string | undefined {
  if (code) {
    return `${import.meta.env.VITE_WEB_APP_URL}/register/code?code=${code}`;
  }
}

export const AddUserCode: React.FC<Props> = ({ dialogState }) => {
  const { t } = useTranslation('usersModule');
  const { tenant } = useTenant();

  return (
    <Box>
      <Typography color="text.secondary" fontSize={14} fontWeight={fontWeights.semiBold} marginBottom={2}>
        {t('createNewUserFields.lead')}
      </Typography>
      <Grid container gap={2} marginBottom={2} flexDirection="column">
        <TextField
          variant="outlined"
          fullWidth
          label={t('createNewUserFields.registrationLink')}
          value={formatRegistrationLink(tenant?.registrationCode) || ''}
          slotProps={{
            input: {
              readOnly: true,
              endAdornment: <CopyToClipboardButton edge="end" value={formatRegistrationLink(tenant?.registrationCode)} />,
            },
          }}
          helperText={t('createNewUserFields.linkHelper')}
        />
        <TextField
          data-test-id="user-add-popup-code-realcode"
          variant="outlined"
          fullWidth
          label={t('createNewUserFields.registrationCode')}
          value={formatRegistrationCode(tenant?.registrationCode) || ''}
          slotProps={{
            input: {
              readOnly: true,
              endAdornment: <CopyToClipboardButton edge="end" value={formatRegistrationCode(tenant?.registrationCode)} />,
            },
          }}
          helperText={t('createNewUserFields.codeHelper')}
        />
        <AddUserAccordion dialogState={dialogState} />
      </Grid>
    </Box>
  );
};
