import PeopleIcon from '@mui/icons-material/People';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserActionButtons } from '../userManagement/UserActionButtons';
import { DashboardPanel } from '../../component/dashboard/DashboardPanel';
import { EmployeeStatusChart } from './charts/EmployeeStatusChart';
import { Link } from 'react-router-dom';
import { useEmployeeList } from '../userManagement/useEmployeeList';

export const EmployeeChartDashboardPanel: React.FC<{ rows?: number }> = ({ rows }) => {
  const { t } = useTranslation('dashboardModule');

  const employeeTotalCount = useEmployeeList({ page: 0, pageSize: 0 }, { staleTime: Infinity }).data?.totalCount || 0;

  return (
    <DashboardPanel
      rows={rows}
      title={t('employeesPanel.title')}
      subTitle={t('employeesPanel.totalCount', { count: employeeTotalCount })}
      actions={
        <Stack direction="row" spacing={1}>
          <UserActionButtons />
          <Button
            component={Link}
            to="/employees"
            variant="contained"
            size="small"
            color="secondary"
            startIcon={<PeopleIcon />}
            data-test-id="to-employee-list-button"
          >
            {t('employeesPanel.goToEmployeeList')}
          </Button>
        </Stack>
      }
    >
      <EmployeeStatusChart />
    </DashboardPanel>
  );
};
