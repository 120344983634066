import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Stack } from '@mui/material';
import { CouponGiftDTO, CouponGiftDTOStatusEnum } from 'probonio-shared-ui/api';
import React, { MouseEventHandler, useCallback } from 'react';
import { useGiftActivationModal } from '../modals/useGiftActivationModal ';

interface Props {
  gift: CouponGiftDTO;
}

export function GiftActivationActions({ gift }: Props): JSX.Element {
  const { onGiftEdit: onEdit, onGiftDelete: onDelete } = useGiftActivationModal();
  const handleStopPropagation = useCallback<MouseEventHandler>(event => {
    event.stopPropagation();
  }, []);

  const handleOpenDeleteGiftDialog = useCallback(
    (event: React.MouseEvent): void => {
      handleStopPropagation(event);
      onDelete(gift);
    },
    [gift, handleStopPropagation, onDelete],
  );

  const handleOpenDialogToEdit = useCallback(
    (event: React.MouseEvent) => {
      handleStopPropagation(event);
      onEdit(gift);
    },
    [gift, handleStopPropagation, onEdit],
  );

  const wasPayed = gift.status === CouponGiftDTOStatusEnum.Available || gift.status === CouponGiftDTOStatusEnum.Redeemed;
  const isPending = gift.status === CouponGiftDTOStatusEnum.Pending;
  return (
    <>
      <Stack direction="row" justifyContent="flex-end">
        <IconButton
          sx={{
            display: isPending ? '' : 'none',
          }}
          onClick={handleOpenDialogToEdit}
          onMouseDown={handleStopPropagation}
        >
          <EditIcon fontSize="small" />
        </IconButton>
        <IconButton
          sx={{
            display: isPending || !wasPayed ? '' : 'none',
          }}
          onClick={handleOpenDeleteGiftDialog}
          onMouseDown={handleStopPropagation}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Stack>
    </>
  );
}
