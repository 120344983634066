import { Box, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import {
  BenefitDTOBenefitEnum,
  LunchReceiptDTOStatusEnum,
  LunchReceiptTenantDTO,
  MobilityReceiptTenantDTO,
  MobilityRefundMetadataDTOTaxationTypeEnum,
  RecreationReceiptTenantDTO,
} from 'probonio-shared-ui/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { renderUserName } from '../../userManagement/userFormatter';
import { ReceiptAuditForm } from './ReceiptAuditForm';
import { mapReceiptStatus } from './useReceiptStatus';
import probonioLogoPlain from '../../../component/brand/logo/logoPlain.svg';

interface Props {
  benefit: BenefitDTOBenefitEnum;
  receipt: LunchReceiptTenantDTO | MobilityReceiptTenantDTO | RecreationReceiptTenantDTO;
}

const DetailField: React.FC<{
  color?: string;
  label: string;
  value?: string;
  ellipsis?: boolean;
  icon?: React.ReactNode;
  dataTestId?: string;
}> = ({ color, label, value, ellipsis, icon, dataTestId }) => {
  return value ? (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Typography variant="body2" color="text.secondary" flexGrow={1}>
        {label}
      </Typography>
      {icon && (
        <Box display="flex" mr={0.5} my={-0.5}>
          {icon}
        </Box>
      )}
      <Typography
        variant="body2"
        fontWeight="bold"
        color={color}
        data-test-id={dataTestId}
        textAlign="right"
        {...(ellipsis ? { noWrap: true, ml: 2, title: value } : {})}
      >
        {value}
      </Typography>
    </Box>
  ) : null;
};

const LunchRefund: React.FC<{ receipt: LunchReceiptTenantDTO }> = ({ receipt }) => {
  const { t } = useTranslation('receiptsModule');

  return (
    <>
      <DetailField
        label={t(receipt.status === LunchReceiptDTOStatusEnum.Approved ? 'receiptDetails.refund' : 'receiptDetails.potentialRefund')}
        value={t('common:money', { money: receipt.refund })}
      />
      <Box pl={2}>
        <DetailField
          label={t('receiptDetails.lunch.refundTaxFree')}
          value={t('common:money', { money: receipt.refundMetadata.taxFreeAmount })}
        />
        <DetailField
          label={t('receiptDetails.lunch.refundTaxable')}
          value={t('common:money', { money: receipt.refundMetadata.taxableAmount })}
        />
      </Box>
    </>
  );
};

const MobilityRefund: React.FC<{ receipt: MobilityReceiptTenantDTO }> = ({ receipt }) => {
  const { t } = useTranslation('receiptsModule');

  const approvedRefundKey =
    receipt.refundMetadata.taxationType === MobilityRefundMetadataDTOTaxationTypeEnum.Taxable
      ? 'receiptDetails.mobility.refundTaxable'
      : 'receiptDetails.mobility.refundTaxFree';

  return (
    <>
      <DetailField
        label={t(receipt.status === LunchReceiptDTOStatusEnum.Approved ? approvedRefundKey : 'receiptDetails.potentialRefund')}
        value={t('common:money', { money: receipt.refund })}
      />
      <DetailField
        label={t('receiptDetails.mobility.travelPurpose.title')}
        value={t(`receiptDetails.mobility.travelPurpose.${receipt.travelPurpose}.title`)}
      />
      <DetailField
        label={t('receiptDetails.mobility.mobilityType.title')}
        value={t(`receiptDetails.mobility.mobilityType.${receipt.mobilityType}.title`)}
      />
      <DetailField
        label={t('receiptDetails.validity')}
        value={
          receipt.validityStartDate && receipt.validityEndDate
            ? t('receiptDetails.validityMonths', {
                validityStartDate: new Date(receipt.validityStartDate),
                validityMonths:
                  DateTime.fromISO(receipt.validityEndDate).diff(DateTime.fromISO(receipt.validityStartDate), 'months').as('months') + 1,
              })
            : ''
        }
      />
    </>
  );
};

const RecreationRefund: React.FC<{ receipt: RecreationReceiptTenantDTO }> = ({ receipt }) => {
  const { t } = useTranslation('receiptsModule');

  return (
    <>
      <DetailField
        label={t(
          receipt.status === LunchReceiptDTOStatusEnum.Approved ? 'receiptDetails.recreation.refund' : 'receiptDetails.potentialRefund',
        )}
        value={t('common:money', { money: receipt.refund })}
      />
    </>
  );
};

export const ReceiptDetails: React.FC<Props> = ({ benefit, receipt }) => {
  const { t } = useTranslation('receiptsModule');

  const { color, status } = mapReceiptStatus(receipt);

  return (
    <Stack spacing={2} width="350px">
      <Stack spacing={1}>
        <Typography variant="h2" mb={1}>
          Details
        </Typography>
        <DetailField label={t('receiptDetails.employee')} value={renderUserName(receipt.employee.user)} />
        <DetailField label={t('receiptDetails.date')} value={t('common:date', { date: DateTime.fromISO(receipt.date) })} />
        <DetailField label={t('receiptDetails.total')} value={t('common:money', { money: receipt.total })} />
        {benefit === BenefitDTOBenefitEnum.Lunch && <LunchRefund receipt={receipt as LunchReceiptTenantDTO} />}
        {benefit === BenefitDTOBenefitEnum.Mobility && <MobilityRefund receipt={receipt as MobilityReceiptTenantDTO} />}
        {benefit === BenefitDTOBenefitEnum.Recreation && <RecreationRefund receipt={receipt as RecreationReceiptTenantDTO} />}
        <DetailField label={t('receiptDetails.comment')} value={receipt.comment} ellipsis />
        {[LunchReceiptDTOStatusEnum.Approved, LunchReceiptDTOStatusEnum.Rejected as string].includes(receipt.status) && (
          <DetailField
            label={t('receiptDetails.auditedBy')}
            value={receipt.tenantAuditUser ? renderUserName(receipt.tenantAuditUser) : t('receiptDetails.probonioAudit')}
            icon={receipt.tenantAuditUser ? undefined : <img src={probonioLogoPlain} height={16} alt={t('navigationModule:brand')} />}
          />
        )}
        <DetailField label={t('receiptDetails.status')} value={t(`benefitsModule:receipts.${status.toLowerCase()}`)} color={color} />
      </Stack>
      <ReceiptAuditForm benefit={benefit} receipt={receipt} />
    </Stack>
  );
};
