import { UseQueryResult, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { CouponsV2ApiListCouponGiftsRequest, CouponsV2GiftListDTO } from '../../../../../probonio-shared-ui/api';
import { apis, useTenantQuery } from '../../../../../probonio-shared-ui/module/api';
import { useTenantID } from '../../../../../probonio-shared-ui/module/me';

export function useCouponsV2Gifts(
  employeeId: string,
  params: Omit<CouponsV2ApiListCouponGiftsRequest, 'tenantId' | 'employeeId'>,
): UseQueryResult<CouponsV2GiftListDTO> {
  return useTenantQuery(
    ['employees', employeeId, 'benefits', 'coupons_v2', 'gifts', params, apis.couponsV2.listCouponGifts.name],
    tenantId => apis.couponsV2.listCouponGifts({ tenantId, employeeId, ...params }).then(res => res.data),
    {
      placeholderData: previousData => previousData,
    },
  );
}

export const useInvalidateCouponsV2Gifts = (employeeId: string): (() => Promise<void>) => {
  const queryClient = useQueryClient();
  const getTenantId = useTenantID();

  return useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: ['tenants', getTenantId(), 'employees', employeeId, 'benefits', 'coupons_v2', 'gifts'],
    });
  }, [queryClient, getTenantId, employeeId]);
};
