import CalculateIcon from '@mui/icons-material/Calculate';
import BenefitSubPage from '../module/benefits/BenefitSubPage';
import { GrossNetCalculator } from '../module/calculator/GrossNetCalculator';
import { AuthorizedLayout } from '../module/navigation/AuthorizedLayout';
import { useTranslation } from 'react-i18next';
import BenefitInfoText from './benefit/BenefitInfoText';

const EquivalenceCalculatorPage: React.FC = () => {
  const { t } = useTranslation('equivalenceCalculator');
  return (
    <AuthorizedLayout title={t('title')}>
      <BenefitSubPage title={t('title')} subTitle={t('subtitle')} icon={CalculateIcon}>
        <BenefitInfoText customBenefitTitle="equivalenceCalculator:title" overrideKey="equivalenceCalculator:infoTextFull" />
        <GrossNetCalculator />
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};

export default EquivalenceCalculatorPage;
