import { useTranslation } from 'react-i18next';
import { BenefitTab } from '../../component/benefitTabs/BenefitTab';
import { BenefitTabPanel } from '../../component/benefitTabs/BenefitTabPanel';
import { BENEFIT_ICONS } from '../../module/benefits/BenefitIcon';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { ContractsTable } from '../../module/benefits/bav/ContractsTable';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';

const BAVContractsPage: React.FC = () => {
  const { t } = useTranslation('benefitsModule');

  return (
    <AuthorizedLayout title={t('BAV.contracts')} breadcrumbs={[{ title: t('BAV.title'), to: '/benefits/bav' }]}>
      <BenefitSubPage icon={BENEFIT_ICONS.BAV} title={t('BAV.title')} subTitle={t('BAV.contracts')}>
        <BenefitTabPanel size="sm">
          <BenefitTab value="" label="" disabled>
            <ContractsTable />
          </BenefitTab>
        </BenefitTabPanel>
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};

export default BAVContractsPage;
