import { Control, useWatch } from 'react-hook-form';
import { FormValues } from '../../../useCustomBenefitCreator';
import { HelpPreview } from './HelpPreview';

interface Props {
  control: Control<FormValues>;
}

export const HelpPreviewForm: React.FC<Props> = ({ control }) => {
  const image = useWatch<FormValues>({ control, name: 'image' }) as string;
  const benefitTitle = useWatch<FormValues>({ control, name: 'title' }) as string;

  const htmlValue = useWatch<FormValues>({ control, name: 'html' }) as string | undefined;

  return <HelpPreview html={htmlValue} image={image} benefitTitle={benefitTitle} />;
};
