import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { BrandLogo, NavbarLayout } from '.';
import { useMainDrawer } from '../../component/drawer/MainDrawerContext';
import { BreadcrumbLink } from './NavbarLayout';
import { UserMenu } from './UserMenu';
import { useTranslation } from 'react-i18next';

interface Props {
  topPanel?: React.ReactNode;
  title?: string;
  breadcrumbs?: readonly BreadcrumbLink[];
}

export const AuthorizedLayout: React.FC<React.PropsWithChildren<Props>> = ({ topPanel, title, breadcrumbs, children }) => {
  const { t } = useTranslation('navigationModule');
  const { open, handleOpen: handleOpenDrawer } = useMainDrawer();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xl'));

  return (
    <NavbarLayout
      logo={
        isMobile ? (
          <BrandLogo />
        ) : (
          <Typography sx={{ verticalAlign: 'middle' }} component="span" variant="h2">
            {t('titleFull')}
          </Typography>
        )
      }
      menu={<UserMenu />}
      title={title}
      breadcrumbs={breadcrumbs}
      topPanel={topPanel}
      onOpenDrawer={handleOpenDrawer}
      open={open}
    >
      {children}
    </NavbarLayout>
  );
};
