import { useTranslation } from 'react-i18next';
import { BasicDialog, DialogState } from '../../../component/dialog';
import { Typography } from '@mui/material';
import { BulkResultItemDTO } from 'probonio-shared-ui/api';
import { renderUserName } from '../../userManagement/userFormatter';
import { DialogLoadingButton } from '../../../component/dialog/DialogLoadingButton';

interface Props {
  dialogState: DialogState;
  updateErrors: BulkResultItemDTO[];
}

export const BulkErrorDialog: React.FC<Props> = ({ dialogState, updateErrors }) => {
  const { t } = useTranslation('benefitsModule');

  return (
    <BasicDialog
      dialogState={dialogState}
      title={t('bulkEditDialog.errorModal.title')}
      content={
        <>
          <Typography>{t('bulkEditDialog.errorModal.text')}</Typography>
          <ul>
            {updateErrors.map(resultItem => (
              <li key={resultItem.targetId}>
                {renderUserName({
                  mail: resultItem.targetMail,
                  firstName: resultItem.targetFirstName,
                  lastName: resultItem.targetLastName,
                })}
                {resultItem.targetEmployeeNumber && ` (#${resultItem.targetEmployeeNumber})`}
              </li>
            ))}
          </ul>
        </>
      }
      actions={
        <DialogLoadingButton color="primary" onClick={dialogState.handleClose}>
          {t('common:buttons.close')}
        </DialogLoadingButton>
      }
    />
  );
};
