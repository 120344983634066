import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Button, Grid2, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DashboardPanel } from '../../component/dashboard/DashboardPanel';
import { BENEFIT_ICONS } from '../benefits/BenefitIcon';
import { DashboardBenefitList } from './DashboardBenefitList';
import { TextWithCaret, TextWithCaretPlaceholder } from './TextWithCaret';

export const BenefitsDashboardPanel: React.FC<{ rows?: number }> = ({ rows }) => {
  const { t } = useTranslation('dashboardModule');
  const [isPaused, setIsPaused] = useState(useMediaQuery('(prefers-reduced-motion)') || false);

  const { data: financialReport, isLoading } = useTenantQuery(
    ['benefits', 'financialReport', apis.benefits.getFinancialReport],
    tenantId => apis.benefits.getFinancialReport({ tenantId }).then(res => res.data),
    { staleTime: Infinity },
  );

  const handlePause = useCallback(() => {
    setIsPaused(prev => !prev);
  }, []);

  return (
    <DashboardPanel
      rows={rows}
      title={t('benefitsPanel.title')}
      subTitle={
        <>
          <Trans i18nKey="dashboardModule:benefitsPanel.totalSavings" values={{ amount: financialReport?.totalSavings }}>
            {isLoading ? <TextWithCaretPlaceholder /> : <TextWithCaret color="success.main" />}
          </Trans>
          <Tooltip title={<Trans i18nKey="dashboardModule:benefitsPanel.disclaimer" />} tabIndex={0}>
            <InfoOutlinedIcon fontSize="small" sx={{ marginLeft: 1, marginY: -0.5 }} />
          </Tooltip>
        </>
      }
      actions={
        <Grid2 container gap={1}>
          <Tooltip title={isPaused ? t('benefitsPanel.resumeCarouselTooltip') : t('benefitsPanel.pauseCarouselTooltip')}>
            <IconButton size="medium" onClick={handlePause}>
              {isPaused ? <PlayCircleIcon /> : <PauseCircleOutlineIcon />}
            </IconButton>
          </Tooltip>
          <Button
            component={Link}
            to="/benefits/custom"
            variant="contained"
            size="small"
            color="secondary"
            startIcon={<BENEFIT_ICONS.CUSTOM />}
          >
            {t('common:benefit.CUSTOM')}
          </Button>
        </Grid2>
      }
    >
      <DashboardBenefitList autoPlay={!isPaused} />
    </DashboardPanel>
  );
};
