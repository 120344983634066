import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { ReportDiagram } from '../../module/benefits';
import { useLunchReport } from '../../module/benefits/lunch/useLunchReport';
import BenefitPayrollReportPage from '../benefit/BenefitPayrollReportPage';

const LunchPayrollReportPage: React.FC = () => {
  const { reportMonth, report, isLoading } = useLunchReport();

  return (
    <BenefitPayrollReportPage
      benefit={BenefitDTOBenefitEnum.Lunch}
      menu={<ReportDiagram report={report} isLoading={isLoading} reportMonth={reportMonth} />}
      showReceiptsWarning={report && report.validatedCount < report.validatedMax}
    />
  );
};

export default LunchPayrollReportPage;
