import { createContext, useContext, useMemo } from 'react';
import { TutorialDrawerState, useTutorialDrawerState } from './useTutorialDrawerState';
import { TutorialStepsList, useTutorialSteps } from './useTutorialSteps';

export interface TutorialContextType {
  drawerState: TutorialDrawerState;
  steps: TutorialStepsList;
}

const TutorialContext = createContext<TutorialContextType>({
  drawerState: { open: false, handleOpen: () => void 0, handleClose: () => void 0 },
  steps: { tutorialSteps: [], loading: false, totalCount: 0, completedCount: 0, openCount: 0 },
});

export const TutorialContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const steps = useTutorialSteps();
  const drawerState = useTutorialDrawerState(steps);

  const contextValue = useMemo(
    (): TutorialContextType => ({
      steps,
      drawerState,
    }),
    [drawerState, steps],
  );

  return <TutorialContext.Provider value={contextValue}>{children}</TutorialContext.Provider>;
};

export const useTutorialContext = (): TutorialContextType => useContext(TutorialContext);
