import { Alert, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BasicDialog, DialogState } from '../../component/dialog';
import { DialogLoadingButton } from '../../component/dialog/DialogLoadingButton';
import { TextFieldControl } from '../../component/form';

interface Props {
  userName: string;
  dialogState: DialogState;
  loading?: boolean;
  onDelete: () => void;
}

interface DeleteFormValues {
  securityQuestion: string;
}

const DeleteUserDialog: React.FC<Props> = ({ userName, dialogState, loading, onDelete }) => {
  const { t } = useTranslation('usersModule');

  const { control, handleSubmit, reset } = useForm<DeleteFormValues>({
    defaultValues: { securityQuestion: '' },
  });

  const handleDelete = useCallback(async () => {
    onDelete();
  }, [onDelete]);

  useEffect(() => {
    if (!dialogState.isOpen) {
      reset();
    }
  }, [dialogState.isOpen, reset]);

  return (
    <BasicDialog
      dialogState={dialogState}
      title={t('userForm.deleteUser.title')}
      content={
        <form onSubmit={handleSubmit(handleDelete)}>
          <Typography gutterBottom>{t('userForm.deleteUser.message', { userName })}</Typography>

          <Typography>
            {t('userForm.deleteUser.securityQuestion', {
              securityQuestionValue: t('userForm.deleteUser.securityQuestionValue'),
            })}
          </Typography>
          <Alert severity="warning" sx={{ my: 1 }}>
            {t('userForm.deleteUser.warningAlert')}
          </Alert>
          <TextFieldControl
            data-test-id="delete-text-field"
            control={control}
            name="securityQuestion"
            label={t('userForm.deleteUser.securityQuestionValue')}
            variant="outlined"
            margin="dense"
            fullWidth
            disabled={loading}
            rules={{ required: true, validate: value => value === t('userForm.deleteUser.securityQuestionValue') }}
            autoComplete="off"
          />
        </form>
      }
      actions={
        <>
          <DialogLoadingButton
            data-test-id="delete-user-confirm"
            color="error"
            variant="outlined"
            onClick={handleSubmit(handleDelete)}
            aria-label={t('userForm.deleteUser.button')}
            loading={loading}
          >
            {t('userForm.deleteUser.button')}
          </DialogLoadingButton>
        </>
      }
    />
  );
};

export default DeleteUserDialog;
