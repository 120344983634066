import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, ButtonProps, FormControl, InputLabel, ListSubheader, Menu, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { BenefitDTOBenefitEnum, CustomBenefitDTO } from 'probonio-shared-ui/api';
import { CUSTOM_BENEFIT_ICONS } from 'probonio-shared-ui/component/icon';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BENEFIT_ICONS, isBenefitActive, orderedBenefits } from '../benefits/BenefitIcon';
import { BenefitRef } from '../benefits/BenefitsCell';

export interface BenefitFilter {
  benefit?: BenefitDTOBenefitEnum;
  customBenefitTitle?: string;
  customBenefitId?: string;
}

interface Props {
  benefitFilter?: BenefitFilter;
  onChangeBenefitFilter: (value: BenefitFilter) => void;
}

function useBenefitMenuItems({
  hideAll,
  excludeBenefits,
  onClick,
}: {
  hideAll?: boolean;
  excludeBenefits?: BenefitRef[];
  onClick?: (benefit: BenefitDTOBenefitEnum, customBenefit?: CustomBenefitDTO) => void;
  showTosBadge?: boolean;
}): React.ReactNode[] {
  const { t } = useTranslation('benefitsModule');
  const { tenant } = useTenant();
  const { data: customBenefits, isFetched } = useTenantQuery(
    ['benefits', 'custom', apis.customBenefits.listCustomBenefits],
    tenantId =>
      apis.customBenefits
        .listCustomBenefits({
          tenantId,
        })
        .then(res => res.data),
    {
      gcTime: 60000,
      staleTime: 60000,
    },
  );

  const handleClick = useCallback(
    (benefit: BenefitDTOBenefitEnum, customBenefit?: CustomBenefitDTO) => () => {
      onClick?.(benefit, customBenefit);
    },
    [onClick],
  );

  const menuItems: React.ReactNode[] = [
    !hideAll && (
      <MenuItem key="_all" value="">
        <em data-test-id="benefit-selector-all">{t('benefitSelector.all')}</em>
      </MenuItem>
    ),
    ...orderedBenefits(tenant)
      .filter(benefit => isBenefitActive(tenant, benefit))
      .map(benefit => {
        const Icon = BENEFIT_ICONS[benefit];
        return (
          benefit !== BenefitDTOBenefitEnum.Custom &&
          !excludeBenefits?.some(exclude => exclude.benefit === benefit) && (
            <MenuItem
              data-test-id={`filter-benefit-selector-value-${benefit}`}
              key={benefit}
              value={benefit}
              onClick={onClick && handleClick(benefit)}
            >
              <Box display="flex" gap={1} overflow="hidden" width="100%">
                <Icon key={benefit} color="primary" />
                {t(`common:benefit.${benefit}`)}
              </Box>
            </MenuItem>
          )
        );
      }),
    ...((isFetched &&
      customBenefits?.results
        .filter(
          customBenefit =>
            !excludeBenefits?.some(
              exclude => exclude.benefit === BenefitDTOBenefitEnum.Custom && exclude.customBenefitId === customBenefit.id,
            ),
        )
        .map(customBenefit => {
          const Icon = CUSTOM_BENEFIT_ICONS[customBenefit.iconName];
          return (
            <MenuItem
              key={customBenefit.title}
              value={customBenefit.id}
              onClick={onClick && handleClick(BenefitDTOBenefitEnum.Custom, customBenefit)}
            >
              <Box display="flex" gap={1} overflow="hidden">
                <Icon key={customBenefit.id} color="primary" />
                {customBenefit.title}
              </Box>
            </MenuItem>
          );
        })) ||
      []),
  ];

  return menuItems;
}

export const BenefitSelector: React.FC<Props> = ({ benefitFilter, onChangeBenefitFilter }) => {
  const { t } = useTranslation('benefitsModule');
  const { data: customBenefits } = useTenantQuery(
    ['benefits', 'custom', apis.customBenefits.listCustomBenefits],
    tenantId =>
      apis.customBenefits
        .listCustomBenefits({
          tenantId,
        })
        .then(res => res.data),
    {
      gcTime: 60000,
      staleTime: 60000,
    },
  );

  const handleChangeBenefitFilter = useCallback(
    async (event: SelectChangeEvent) => {
      if (Object.values(BenefitDTOBenefitEnum).includes(event.target.value as BenefitDTOBenefitEnum)) {
        onChangeBenefitFilter({ benefit: event.target.value as BenefitDTOBenefitEnum });
      } else if (event.target.value.length > 0) {
        const customBenefit = customBenefits?.results.find(benefit => {
          if (benefit.id === event.target.value) {
            return benefit;
          }
        });
        onChangeBenefitFilter({
          benefit: BenefitDTOBenefitEnum.Custom,
          customBenefitTitle: customBenefit?.title,
          customBenefitId: customBenefit?.id,
        });
      } else {
        onChangeBenefitFilter({});
      }
    },
    [onChangeBenefitFilter, customBenefits?.results],
  );

  const value = (benefitFilter?.customBenefitId ?? benefitFilter?.benefit) || '';

  const menuItems = useBenefitMenuItems({});

  return (
    <FormControl size="small">
      <InputLabel htmlFor="benefit-selector">{t('benefitSelector.benefit')}</InputLabel>
      <Select
        data-test-id="benefit-selector"
        id="benefit-selector"
        label={t('benefitSelector.benefit')}
        onChange={handleChangeBenefitFilter}
        value={value}
        sx={{ width: 250, height: '100%', backgroundColor: 'background.paper' }}
      >
        {menuItems}
      </Select>
    </FormControl>
  );
};

export const BenefitDropdown: React.FC<
  {
    excludeBenefits?: BenefitRef[];
    onSelect: (benefit: BenefitDTOBenefitEnum, customBenefit?: CustomBenefitDTO) => void;
    additionalItems?: React.ReactNode;
    showTosBadge?: boolean;
  } & Omit<ButtonProps, 'onSelect'>
> = ({ excludeBenefits, onSelect, additionalItems, showTosBadge = false, ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation('benefitsModule');

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const handleSelect = useCallback(
    (benefit: BenefitDTOBenefitEnum, customBenefit?: CustomBenefitDTO) => {
      handleClose();
      onSelect(benefit, customBenefit);
    },
    [handleClose, onSelect],
  );

  const menuItems = useBenefitMenuItems({ hideAll: true, excludeBenefits, onClick: handleSelect, showTosBadge });

  return (
    <>
      <Button
        {...props}
        id="benefit-dropdown-button"
        aria-controls={open ? 'benefit-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {props.children}
      </Button>
      <Menu
        id="benefit-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'benefit-dropdown-button',
        }}
      >
        {additionalItems && <ListSubheader>{t('benefitSelector.subheader')}</ListSubheader>}
        {menuItems}
        {additionalItems}
      </Menu>
    </>
  );
};
