import { LinearProgress, Skeleton } from '@mui/material';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { CUSTOM_BENEFIT_ICONS } from 'probonio-shared-ui/component/icon';
import React, { useEffect } from 'react';
import { useRowSelectionState } from '../../component/table/useManualRowSelection';
import { BenefitProvider } from '../../module/benefits/BenefitContext';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { BulkActivationButton } from '../../module/benefits/bulkActivation/BulkActivationButton';
import { BulkBenefitActivationModalProvider } from '../../module/benefits/bulkActivation/BulkBenefitActivationModalProvider';
import { BulkEditActivationButton } from '../../module/benefits/bulkActivation/BulkEditActivationButton';
import { useCustomBenefitCreatorState } from '../../module/benefits/customBenefit';
import { CustomBenefitTemplateKeys, CustomBenefitTemplates } from '../../module/benefits/customBenefit/customBenefitTemplates';
import { BenefitEmployeeTabs } from '../../module/benefits/employeeStatusList/BenefitEmployeeTabs';
import { useEmployeeFilterState } from '../../module/benefits/employeeStatusList/employeeFilterState';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';
import BenefitInfoText from '../benefit/BenefitInfoText';

export const CustomBenefitEmployeesPage: React.FC = () => {
  const customBenefitCreatorState = useCustomBenefitCreatorState();

  const filterState = useEmployeeFilterState();
  const rowSelectionState = useRowSelectionState();

  useEffect(() => {
    rowSelectionState.handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customBenefitCreatorState?.currentCustomBenefitTemplate?.customBenefitId, filterState.filter.activationState]);

  if (!customBenefitCreatorState?.currentCustomBenefitTemplate) {
    return (
      <AuthorizedLayout>
        <LinearProgress />
        <Skeleton animation="wave" height="80vh" />
      </AuthorizedLayout>
    );
  }

  const benefitKey = customBenefitCreatorState.currentCustomBenefitTemplate.customBenefit?.templateKey;

  return (
    <AuthorizedLayout title={customBenefitCreatorState.currentCustomBenefitTemplate.benefit.title}>
      <BenefitProvider
        benefit={BenefitDTOBenefitEnum.Custom}
        customBenefitId={customBenefitCreatorState.currentCustomBenefitTemplate.customBenefitId}
      >
        <BulkBenefitActivationModalProvider rowSelectionState={rowSelectionState} employeeFilter={filterState.filter}>
          <BenefitSubPage
            icon={CUSTOM_BENEFIT_ICONS[customBenefitCreatorState.currentCustomBenefitTemplate.benefit.iconValue]}
            title={customBenefitCreatorState.currentCustomBenefitTemplate.benefit.title}
            subTitle={customBenefitCreatorState.subtitle}
            menu={
              filterState.debouncedFilter.activationState === 'ACTIVE' ? (
                <BulkEditActivationButton rowSelectionState={rowSelectionState} />
              ) : (
                <BulkActivationButton rowSelectionState={rowSelectionState} />
              )
            }
          >
            {benefitKey && CustomBenefitTemplates.get(benefitKey as CustomBenefitTemplateKeys)?.hasInfoText ? (
              <BenefitInfoText
                customBenefitTitle={customBenefitCreatorState.currentCustomBenefitTemplate.customBenefit?.title}
                customBenefitKey={benefitKey}
              />
            ) : undefined}
            <BenefitEmployeeTabs
              benefit={BenefitDTOBenefitEnum.Custom}
              customBenefitId={customBenefitCreatorState.currentCustomBenefitTemplate.customBenefitId!}
              filterState={filterState}
              rowSelectionState={rowSelectionState}
            />
          </BenefitSubPage>
        </BulkBenefitActivationModalProvider>
      </BenefitProvider>
    </AuthorizedLayout>
  );
};
