import { Tooltip, Typography } from '@mui/material';
import { BenefitDTOBenefitEnum, TenantDTOCouponOrderAutomationBehaviourEnum } from 'probonio-shared-ui/api';
import { RadioControl, RadioControlOption } from 'probonio-shared-ui/component/form';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useMemo } from 'react';
import { Control, useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectControl } from '../../../component/form';
import { SettingsFormRow } from '../../../component/settings/SettingsFormRow';
import i18n from '../../../lang';

export interface FormFields {
  couponOrderDeadline: number;
  couponFallbackDefinitionId?: string;
  couponOrderAutomationBehaviour: TenantDTOCouponOrderAutomationBehaviourEnum;
}

interface Props {
  control: Control<FormFields>;
  disabled?: boolean;
}

function buildAutomatedBehaviourOption(
  option: TenantDTOCouponOrderAutomationBehaviourEnum,
  values: { couponOrderDeadline: React.ReactNode; hasFallback: boolean; couponFallback?: string },
  disabled?: boolean,
): RadioControlOption {
  let hint = i18n.t(`couponsModule:benefitSettings.fields.couponOrderAutomate.${option}.hint`, values);

  // Didn't find a better way for this...
  if (option === TenantDTOCouponOrderAutomationBehaviourEnum.Default) {
    hint += i18n.t(
      `couponsModule:benefitSettings.fields.couponOrderAutomate.${option}.hint${values.hasFallback ? 'WithFallback' : 'WithoutFallback'}`,
      values,
    );
  }

  return {
    label: i18n.t(`couponsModule:benefitSettings.fields.couponOrderAutomate.${option}.label`, values),
    hint: hint,
    value: option,
    disabled,
    tooltip: disabled ? i18n.t(`couponsModule:benefitSettings.fields.couponOrderAutomate.${option}.disabledTooltip`, values) : undefined,
  };
}

const MIN_DAY_OF_MONTH = 10;
const MAX_DAY_OF_MONTH = 20;

export const CouponSettingsForm: React.FC<Props> = ({ control, disabled }) => {
  const { t } = useTranslation('couponsModule');
  const { tenant } = useTenant();

  const couponOrderDeadline = useWatch({ control, name: 'couponOrderDeadline' });

  const deadlineOptions = useMemo(
    (): { label: React.ReactNode; value: string | number }[] => [
      { value: 1, label: t('benefitSettings.couponOrderDeadline.first') },
      ...new Array(MAX_DAY_OF_MONTH - MIN_DAY_OF_MONTH + 1).fill(0).map((v, index) => ({
        value: index + MIN_DAY_OF_MONTH,
        label: t('benefitSettings.couponOrderDeadline.perDay', { value: index + MIN_DAY_OF_MONTH }),
      })),
      { value: 31, label: t('benefitSettings.couponOrderDeadline.last') },
    ],
    [t],
  );

  let couponOrderDeadlineHint = 'benefitSettings.couponOrderDeadlineHint.perDay';
  if (couponOrderDeadline === 1) {
    couponOrderDeadlineHint = 'benefitSettings.couponOrderDeadlineHint.first';
  } else if (couponOrderDeadline === 31) {
    couponOrderDeadlineHint = 'benefitSettings.couponOrderDeadlineHint.last';
  }

  const fallbackFilterKey = tenant?.flexBenefits?.includes(BenefitDTOBenefitEnum.Coupons) ? 'isFlexFallback' : 'isFallback';
  const { data: fallbackCouponDefinitions } = useTenantQuery(
    ['benefits', 'coupons', 'definition', { [fallbackFilterKey]: true }, apis.coupons.findCouponDefinitions.name],
    tenantId =>
      apis.coupons
        .findCouponDefinitions({
          tenantId,
          [fallbackFilterKey]: true,
        })
        .then(res => res.data),
  );

  const { defaultValues } = useFormState({ control });
  const initialFallbackDefinitionId = defaultValues?.couponFallbackDefinitionId;
  const { data: selectedFallbackDefinition } = useTenantQuery(
    ['benefits', 'coupons', 'definition', initialFallbackDefinitionId, apis.coupons.getCouponDefinition.name],
    tenantId => apis.coupons.getCouponDefinition({ tenantId, couponId: initialFallbackDefinitionId! }).then(res => res.data),
    {
      enabled:
        !!initialFallbackDefinitionId &&
        fallbackCouponDefinitions &&
        !fallbackCouponDefinitions.coupons.some(coupon => coupon.id === initialFallbackDefinitionId),
    },
  );

  const { couponFallbackDefinitionId, couponOrderAutomationBehaviour } = useWatch<FormFields>({ control }) || {};

  const fallbackCouponOptions = useMemo(() => {
    const options = (fallbackCouponDefinitions?.coupons || []).map(definition => ({ label: definition.name, value: definition.id }));
    // If the selected id is not one of the DEFAULT coupons (e.g. manually inserted in the db),
    // add that coupon to the list to avoid an "empty" entry.
    if (selectedFallbackDefinition && !options.some(option => option.value === selectedFallbackDefinition.id)) {
      options.push({ label: selectedFallbackDefinition.name, value: selectedFallbackDefinition.id });
    }
    options.sort((o1, o2) => o1.label.localeCompare(o2.label, undefined, { sensitivity: 'base' }));
    if (couponOrderAutomationBehaviour !== TenantDTOCouponOrderAutomationBehaviourEnum.ForAllUsers) {
      options.unshift({ label: t('benefitSettings.fields.couponFallbackDefinitionId.emptyPlaceholder'), value: '' });
    }
    return options;
  }, [fallbackCouponDefinitions?.coupons, selectedFallbackDefinition, t, couponOrderAutomationBehaviour]);

  const automationBehaviourTranslationValues = {
    couponOrderDeadline: deadlineOptions.find(entry => entry.value === couponOrderDeadline)?.label,
    hasFallback: couponFallbackDefinitionId ? couponFallbackDefinitionId.length > 0 : false,
    couponFallback: fallbackCouponOptions.find(entry => entry.value === couponFallbackDefinitionId)?.label,
  };

  return (
    <>
      <SettingsFormRow fullWidth title={t('benefitSettings.orderDeadline.title')} info={t('benefitSettings.orderDeadline.info')}>
        <SelectControl control={control} name="couponOrderDeadline" fullWidth options={deadlineOptions} disabled={disabled} />
        <Typography variant="body2" color="text.secondary">
          {t(couponOrderDeadlineHint, { couponOrderDeadline, reportDay: couponOrderDeadline + 1 })}
        </Typography>
      </SettingsFormRow>
      <SettingsFormRow fullWidth title={t('benefitSettings.fallbackCoupon.title')} info={t('benefitSettings.fallbackCoupon.info')}>
        <Tooltip
          title={
            couponOrderAutomationBehaviour === TenantDTOCouponOrderAutomationBehaviourEnum.Disabled
              ? t('benefitSettings.fields.couponFallbackDefinitionId.disabledTooltip')
              : undefined
          }
        >
          <SelectControl
            control={control}
            name="couponFallbackDefinitionId"
            fullWidth
            displayEmpty
            options={fallbackCouponOptions}
            disabled={couponOrderAutomationBehaviour === TenantDTOCouponOrderAutomationBehaviourEnum.Disabled || disabled}
          />
        </Tooltip>
        <Typography variant="body2" color="text.secondary">
          {t('benefitSettings.fields.couponFallbackDefinitionId.hint', automationBehaviourTranslationValues)}
        </Typography>
      </SettingsFormRow>
      <SettingsFormRow
        fullWidth
        title={t('benefitSettings.automationBehaviour.title')}
        info={t('benefitSettings.automationBehaviour.info')}
      >
        <RadioControl
          control={control}
          name="couponOrderAutomationBehaviour"
          options={[
            buildAutomatedBehaviourOption(TenantDTOCouponOrderAutomationBehaviourEnum.Default, automationBehaviourTranslationValues),
            buildAutomatedBehaviourOption(
              TenantDTOCouponOrderAutomationBehaviourEnum.ForAllUsers,
              automationBehaviourTranslationValues,
              !couponFallbackDefinitionId,
            ),
            buildAutomatedBehaviourOption(
              TenantDTOCouponOrderAutomationBehaviourEnum.Disabled,
              automationBehaviourTranslationValues,
              !!couponFallbackDefinitionId,
            ),
          ]}
        />
      </SettingsFormRow>
    </>
  );
};
