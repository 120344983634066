import { MRT_TableOptions, MRT_Row, MRT_TableInstance } from 'material-react-table';
import { useCallback, useMemo, useState } from 'react';

export interface RowSelectionState {
  rowSelection: Record<string, boolean>;
  allSelected: boolean;
  setRowSelection: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  setAllSelected: React.Dispatch<React.SetStateAction<boolean>>;
  someRowsSelected: boolean;
  selectedCount: number;
  handleReset: () => void;
}

export function useRowSelectionState(): RowSelectionState {
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const [allSelected, setAllSelected] = useState(false);

  const handleReset = useCallback(() => {
    setRowSelection({});
    setAllSelected(false);
  }, []);

  return useMemo(
    () => ({
      rowSelection,
      setRowSelection,
      allSelected,
      setAllSelected,
      someRowsSelected: allSelected || Object.values(rowSelection).filter(value => value).length !== 0,
      selectedCount: Object.values(rowSelection).filter(value => value).length,
      handleReset,
    }),
    [allSelected, handleReset, rowSelection],
  );
}

const ALL_ROWS_SELECTABLE = (): boolean => true;

/**
 * @returns all props required to manually manage the selection state in a lazy-loading table
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-function-return-type
export function useManualRowSelection<TData extends Record<string, any>>(
  rowSelectionState: RowSelectionState,
  data: TData[],
  getRowId: (row: TData) => string,
  isRowSelectable: (row: TData) => boolean = ALL_ROWS_SELECTABLE,
) {
  const muiTableBodyRowProps = useCallback(
    ({ row }: { row: MRT_Row<TData> }) => {
      function handleRowClick(event: unknown): void {
        if (isRowSelectable(row.original)) {
          row.getToggleSelectedHandler()(event);
        }
      }

      return {
        onClick: handleRowClick,
        sx: { cursor: 'pointer' },
      };
    },
    [isRowSelectable],
  );

  const muiSelectCheckboxProps = useCallback(
    ({ row }: { row: MRT_Row<TData> }) => ({ disabled: !isRowSelectable(row.original) }),
    [isRowSelectable],
  );
  const muiSelectAllCheckboxProps = useCallback(
    ({ table }: { table: MRT_TableInstance<TData> }) => ({
      checked: rowSelectionState.allSelected,
      indeterminate: (table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) && !rowSelectionState.allSelected,
      onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        rowSelectionState.setAllSelected(checked);
        if (!checked) {
          table.resetRowSelection();
        }
      },
    }),
    [rowSelectionState],
  );
  const mappedRowSelection = rowSelectionState.allSelected
    ? data.filter(value => isRowSelectable(value)).reduce((selection, value) => ({ ...selection, [getRowId(value)]: true }), {})
    : rowSelectionState.rowSelection;
  const onRowSelectionChange = useCallback<NonNullable<MRT_TableOptions<TData>['onRowSelectionChange']>>(
    updaterOrValue => {
      rowSelectionState.setAllSelected(false);
      if (typeof updaterOrValue === 'function') {
        const newValue = updaterOrValue(mappedRowSelection);
        rowSelectionState.setRowSelection(newValue);
      } else {
        rowSelectionState.setRowSelection(updaterOrValue);
      }
      rowSelectionState.setRowSelection(updaterOrValue);
    },
    [mappedRowSelection, rowSelectionState],
  );

  return useMemo(
    () => ({
      enableRowSelection: true,
      getRowId,
      muiTableBodyRowProps,
      muiSelectCheckboxProps,
      muiSelectAllCheckboxProps,
      onRowSelectionChange,
      displayColumnDefOptions: { 'mrt-row-select': { size: 0 } },
      state: { rowSelection: mappedRowSelection },
    }),
    [getRowId, mappedRowSelection, muiSelectAllCheckboxProps, muiSelectCheckboxProps, muiTableBodyRowProps, onRowSelectionChange],
  );
}
