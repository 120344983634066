/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BAVBenefitOptionsDTO } from './bavbenefit-options-dto';
import { BikeBenefitOptionsDTO } from './bike-benefit-options-dto';
import { BikeleasingBenefitOptionsDTO } from './bikeleasing-benefit-options-dto';
import { CouponsBenefitOptionsDTO } from './coupons-benefit-options-dto';
import { CouponsV2BenefitOptionsDTO } from './coupons-v2-benefit-options-dto';
import { CustomBenefitOptionsDTO } from './custom-benefit-options-dto';
import { FlexBenefitOptionsDTO } from './flex-benefit-options-dto';
import { InternetBenefitOptionsDTO } from './internet-benefit-options-dto';
import { LunchBenefitOptionsDTO } from './lunch-benefit-options-dto';
import { MobilityBenefitOptionsDTO } from './mobility-benefit-options-dto';
import { RecreationBenefitOptionsDTO } from './recreation-benefit-options-dto';

/**
 * 
 * @export
 * @interface BenefitActivationDTO
 */
export interface BenefitActivationDTO {
    /**
     * 
     * @type {string}
     * @memberof BenefitActivationDTO
     */
    'benefit': BenefitActivationDTOBenefitEnum;
    /**
     * 
     * @type {string}
     * @memberof BenefitActivationDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BenefitActivationDTO
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof BenefitActivationDTO
     */
    'endDate'?: string;
    /**
     * 
     * @type {LunchBenefitOptionsDTO}
     * @memberof BenefitActivationDTO
     */
    'lunchOptions'?: LunchBenefitOptionsDTO;
    /**
     * 
     * @type {MobilityBenefitOptionsDTO}
     * @memberof BenefitActivationDTO
     */
    'mobilityOptions'?: MobilityBenefitOptionsDTO;
    /**
     * 
     * @type {CouponsBenefitOptionsDTO}
     * @memberof BenefitActivationDTO
     */
    'couponsOptions'?: CouponsBenefitOptionsDTO;
    /**
     * 
     * @type {CouponsV2BenefitOptionsDTO}
     * @memberof BenefitActivationDTO
     */
    'couponsV2Options'?: CouponsV2BenefitOptionsDTO;
    /**
     * 
     * @type {InternetBenefitOptionsDTO}
     * @memberof BenefitActivationDTO
     */
    'internetOptions'?: InternetBenefitOptionsDTO;
    /**
     * 
     * @type {BAVBenefitOptionsDTO}
     * @memberof BenefitActivationDTO
     */
    'bavOptions'?: BAVBenefitOptionsDTO;
    /**
     * 
     * @type {BikeBenefitOptionsDTO}
     * @memberof BenefitActivationDTO
     */
    'bikeOptions'?: BikeBenefitOptionsDTO;
    /**
     * 
     * @type {FlexBenefitOptionsDTO}
     * @memberof BenefitActivationDTO
     */
    'flexOptions'?: FlexBenefitOptionsDTO;
    /**
     * 
     * @type {RecreationBenefitOptionsDTO}
     * @memberof BenefitActivationDTO
     */
    'recreationOptions'?: RecreationBenefitOptionsDTO;
    /**
     * 
     * @type {CustomBenefitOptionsDTO}
     * @memberof BenefitActivationDTO
     */
    'customBenefitOptions'?: CustomBenefitOptionsDTO;
    /**
     * 
     * @type {BikeleasingBenefitOptionsDTO}
     * @memberof BenefitActivationDTO
     */
    'bikeleasingOptions'?: BikeleasingBenefitOptionsDTO;
}

export const BenefitActivationDTOBenefitEnum = {
    Bav: 'BAV',
    Lunch: 'LUNCH',
    Coupons: 'COUPONS',
    CouponsV2: 'COUPONS_V2',
    Mobility: 'MOBILITY',
    Gifts: 'GIFTS',
    Internet: 'INTERNET',
    Bike: 'BIKE',
    News: 'NEWS',
    Flex: 'FLEX',
    Recreation: 'RECREATION',
    Bikeleasing: 'BIKELEASING',
    Custom: 'CUSTOM'
} as const;

export type BenefitActivationDTOBenefitEnum = typeof BenefitActivationDTOBenefitEnum[keyof typeof BenefitActivationDTOBenefitEnum];


