import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { useRefetchEmployees } from '../userManagement/query';

export const useRefetchActivations = (employeeId?: string): (() => Promise<void>) => {
  const queryClient = useQueryClient();
  const getTenantId = useTenantID();
  const refetchEmployees = useRefetchEmployees();

  return useCallback(async () => {
    await refetchEmployees();
    await queryClient.invalidateQueries({ queryKey: ['tenants', getTenantId(), 'benefitActivations'] });
  }, [refetchEmployees, queryClient, getTenantId]);
};
export const useRefetchCouponGifts = (employeeId: string): (() => Promise<void>) => {
  const queryClient = useQueryClient();
  const getTenantId = useTenantID();

  return useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ['tenants', getTenantId(), 'employees', employeeId, 'benefits', 'coupons', 'gifts'] });
  }, [queryClient, getTenantId, employeeId]);
};
export const useRefetchGiftOccasions = (): (() => Promise<void>) => {
  const queryClient = useQueryClient();
  const getTenantId = useTenantID();

  return useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ['tenants', getTenantId(), 'benefits', 'coupons', 'giftOccasions'] });
  }, [queryClient, getTenantId]);
};
