import { Chip, styled } from '@mui/material';

const CustomChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  height: '25px',
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.grey[800],
}));

export default CustomChip;
