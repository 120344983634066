import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Stack } from '@mui/material';
import { MRT_Row } from 'material-react-table';
import { useSnackbar } from 'notistack';
import { BikeContractDTO } from 'probonio-shared-ui/api';
import { SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewDialogState } from '../../../component/dialog';
import { ConfirmationModal } from '../../../component/dialog/ConfirmationModal';
import { useDeleteBikeContract } from './useDeleteBikeContract';

export const BikeContractActions: React.FC<{ row: MRT_Row<BikeContractDTO> }> = ({ row }) => {
  const { t } = useTranslation('bikeModule');
  const { enqueueSnackbar } = useSnackbar();
  const confirmDeleteDialog = useNewDialogState();

  const deleteMutation = useDeleteBikeContract();
  const handleDelete = useCallback(() => {
    deleteMutation.mutate(row.original, {
      onSuccess: () => {
        enqueueSnackbar(t('contractsTable.deleteContractSuccess'), { variant: 'success' });
        confirmDeleteDialog.dialogState.handleClose();
      },
    });
  }, [confirmDeleteDialog.dialogState, deleteMutation, enqueueSnackbar, row.original, t]);

  // prevent clicks inside the actions component to propagate to the row click handler
  const handleStopPropagation = useCallback((ev: SyntheticEvent) => ev.stopPropagation(), []);

  return (
    <Stack direction="row" justifyContent="flex-end" onClick={handleStopPropagation}>
      <IconButton data-test-id="delete-contract-button" onClick={confirmDeleteDialog.handleOpen} title={t('contractsTable.deleteButton')}>
        <DeleteIcon fontSize="small" />
      </IconButton>
      <ConfirmationModal
        dialogState={confirmDeleteDialog.dialogState}
        confirmColor="error"
        confirmButtonTitle={t('contractsTable.deleteButton')}
        onConfirm={handleDelete}
        loading={deleteMutation.isPending}
        closeOnConfirm={false}
        dataTestId="confirm-delete-contract"
      >
        {t('contractsTable.confirmDelete')}
      </ConfirmationModal>
    </Stack>
  );
};
