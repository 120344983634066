import CloseIcon from '@mui/icons-material/Close';
import { Breakpoint, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, PaperProps, SxProps, Theme } from '@mui/material';
import React, { FormEventHandler, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface DialogState {
  isOpen: boolean;
  handleClose: () => void;
}

export const useNewDialogState = (): { dialogState: DialogState; handleOpen: () => void } => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  return useMemo(
    () => ({
      dialogState: {
        isOpen,
        handleClose,
      },
      handleOpen,
    }),
    [handleClose, handleOpen, isOpen],
  );
};

interface Props {
  dialogState: DialogState;
  fullWidth?: boolean;
  maxWidth?: Breakpoint | number;
  sx?: SxProps<Theme>;
  title: React.ReactNode;
  content: React.ReactNode;
  actions?: React.ReactNode;
  dataTestId?: string;
  onSubmit?: FormEventHandler<HTMLDivElement>;
}

export const BasicDialog: React.FC<Props> = memo(
  ({ dialogState: state, title, content, actions, fullWidth, maxWidth, sx, dataTestId, onSubmit }) => {
    const { handleClose, isOpen } = state;
    const { t } = useTranslation('common');

    return (
      <Dialog
        open={isOpen}
        onClose={handleClose}
        fullWidth={fullWidth}
        maxWidth={typeof maxWidth === 'number' ? false : maxWidth}
        aria-labelledby="alert-dialog-title"
        scroll="body"
        PaperProps={
          {
            'data-test-id': dataTestId,
            component: onSubmit ? 'form' : undefined,
            onSubmit,
            sx: { maxWidth: typeof maxWidth === 'number' ? maxWidth : undefined },
          } as PaperProps
        }
        sx={{
          '.MuiPaper-root': { overflow: 'visible' },
          ...sx,
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {title}
          <IconButton
            data-test-id="basic-dialog-button-close"
            name="close"
            sx={{ position: 'absolute', right: 8, top: 8 }}
            color="inherit"
            onClick={handleClose}
            title={t('buttons.close')}
            aria-label={t('buttons.close')}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{content}</DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
      </Dialog>
    );
  },
);
