import GradingIcon from '@mui/icons-material/Grading';
import { Box, Tooltip } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import { apis, downloadResponse } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';
import useDebounce from 'probonio-shared-ui/utils/useDebounce';
import { ChangeEventHandler, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewDialogState } from '../../../component/dialog';
import { SearchInput } from '../../../component/form/SearchInput';
import { HorizontalBox, VerticalBox } from '../../../component/layout/BoxLayout';
import { EmployeeClearFilterButton } from '../employeeStatusList/EmployeeClearFilterButton';
import ReportDownloadDialog from '../ReportDownloadDialog/ReportDownloadDialog';
import { CouponPendingBudgetOrdersTable } from './CouponPendingBudgetOrdersTable';
import { OrdersTable } from './OrdersTable';

interface Props {
  filter?: 'COUPON_ORDERS' | 'GIFTS' | 'BUDGET_ORDERS';
}

export const CouponOrderHistoryList: React.FC<Props> = ({ filter }) => {
  const { t } = useTranslation('couponsModule');
  const tenantId = useTenantID();

  const [filterValue, setFilterValue] = useState('');
  const debouncedFilterValue = useDebounce(filterValue);
  const handleChangeFilter = useCallback<ChangeEventHandler<HTMLInputElement>>(ev => {
    setFilterValue(ev.target.value);
  }, []);

  const exportDialog = useNewDialogState();
  const exportMutation = useMutation({
    mutationFn: (month?: DateTime) =>
      apis.coupons.exportTransactionHistory({ tenantId: tenantId(), month: month?.toFormat('yyyy-LL') }).then(res => {
        downloadResponse(res, 'Coupon-Order-History.csv');
      }),
  });
  const handleExport = useCallback(
    (month?: DateTime) => {
      exportMutation.mutate(month, { onSuccess: () => exportDialog.dialogState.handleClose() });
    },
    [exportDialog.dialogState, exportMutation],
  );

  const includeCouponOrders = !filter || filter === 'COUPON_ORDERS';
  const includeGiftOrders = !filter || filter === 'GIFTS';
  const includeBudgetOrders = !filter || filter === 'BUDGET_ORDERS';

  const handleRevertFilters = useCallback(() => {
    setFilterValue('');
  }, []);

  return (
    <>
      <VerticalBox>
        <HorizontalBox sx={{ marginBottom: 1.5 }}>
          <HorizontalBox sx={{ flexGrow: 1 }} gap={1}>
            <SearchInput size="small" value={filterValue} onChange={handleChangeFilter} />
            {filterValue && <EmployeeClearFilterButton onRevertFilters={handleRevertFilters} />}
          </HorizontalBox>
          <Box>
            <Tooltip title={t('orderHistoryList.export') || ''}>
              <LoadingButton
                sx={{ marginLeft: 2, p: 1 }}
                onClick={exportDialog.handleOpen}
                loading={exportMutation.isPending}
                variant="topBarButtonBorder"
                size="small"
              >
                <GradingIcon />
              </LoadingButton>
            </Tooltip>
          </Box>
        </HorizontalBox>
        {includeBudgetOrders && <CouponPendingBudgetOrdersTable />}
        <OrdersTable
          filter={debouncedFilterValue}
          includeCouponOrders={includeCouponOrders}
          includeGiftOrders={includeGiftOrders}
          includeBudgetOrders={includeBudgetOrders}
        />
      </VerticalBox>
      <ReportDownloadDialog
        message={t('reportDialogMessage')}
        loading={exportMutation.isPending}
        dialogState={exportDialog.dialogState}
        handleConfirm={handleExport}
      />
    </>
  );
};
