import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export interface HrSystemInstantInvite {
  updateHrSystemInstantInvite: (hrSystemInstantInvite: boolean) => void;
}

export function useHrSystemInstantInvite(): HrSystemInstantInvite {
  const { tenant, invalidateTenant } = useTenant();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('tenantModule');

  const handleSuccess = useCallback(() => {
    invalidateTenant();
    enqueueSnackbar(t('hrSystem.instantInvite.successToast'), { variant: 'success' });
  }, [enqueueSnackbar, invalidateTenant, t]);

  const instantInviteUpdateMutation = useMutation({
    mutationFn: (hrSystemInstantInvite: boolean) =>
      apis.hrSystem.updateTenantHRSystemConfig({
        tenantId: tenant!.id,
        updateTenantHRSystemDTO: {
          hrSystemInstantInvite,
        },
      }),
    onSuccess: handleSuccess,
  });

  const updateHrSystemInstantInvite = useCallback(
    (hrSystemInstantInvite: boolean) => {
      instantInviteUpdateMutation.mutate(hrSystemInstantInvite);
    },
    [instantInviteUpdateMutation],
  );

  return {
    updateHrSystemInstantInvite,
  };
}
