import { UpsertBikeContractDTO } from 'probonio-shared-ui/api';
import {
  ExcelFieldMapping,
  ExcelFileParser,
  parseDate,
  parseDateTime,
  parseEuro,
  parseInt,
} from 'probonio-shared-ui/utils/excelFileParser';

interface UpsertBikeContractDTOWithNumber extends UpsertBikeContractDTO {
  contractNumber: string;
}

const FIELD_MAPPING: ExcelFieldMapping<UpsertBikeContractDTOWithNumber> = {
  contractNumber: /Auftragsnummer/,
  employeeNumber: /Personalnummer/,
  contractDate: [/Anlagedatum/, parseDateTime],
  status: /Status/,
  leasingStartDate: [/Leasingbeginn/, parseDate],
  leasingEndDate: [/Leasingende/, parseDate],
  lastInspectionDate: [/Letzte.*Inspektion/s, parseDate],
  deliveryDate: [/Tag der Uebernahme/, parseDate],
  priceGross: [/Verkaufspreis.*zzgl/s, parseEuro],
  priceNet: [/Verkaufspreis.*inkl/s, parseEuro],
  priceRetail: [/UVP/, parseEuro],
  remainingValue: [/Restwert/, parseEuro],
  monthlyConversionRate: [/Umwandlungsrate/, parseEuro],
  monthlyLeasingRate: [/Leasingrate/, parseEuro],
  monthlyServiceRate: [/Servicerate/, parseEuro],
  monthlyInsuranceRate: [/Versicherungsrate/, parseEuro],
  employerGrant: [/Arbeitgeberzuschuss/, parseEuro],
  nonCashBenefit: [/Geldwerter.*Vorteil/s, parseEuro],
  bikeSupplier: /Lieferant/,
  bikeProduct: /Produkt/,
  bikeType: /Typ/,
  bikeModelYear: [/Modelljahr/, parseInt],
  bikeCategory: /Kategorie/,
  bikeSubCategory: /Unterkategorie/,
  servicePackage: /Servicepaket/,
};

export async function parseContractsFromExcel(file: File): Promise<{ contractNumber: string; contract: UpsertBikeContractDTO }[]> {
  const parser = new ExcelFileParser(FIELD_MAPPING, [
    'contractNumber',
    'employeeNumber',
    'leasingStartDate',
    'leasingEndDate',
    'bikeProduct',
  ]);

  const parsedContracts = await parser.parseExcelFile(file);
  return parsedContracts.map(contractWithId => {
    const { contractNumber, ...contract } = contractWithId;
    return { contractNumber, contract };
  });
}
