import { Box, CardContent, Divider, Typography } from '@mui/material';
import React from 'react';

interface Props {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  actions?: React.ReactNode;
}

export const ActionCardContent: React.FC<React.PropsWithChildren<Props>> = ({ title, subTitle, actions, children }) => {
  return (
    <CardContent
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: 0,
        paddingBottom: '0 !important',
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" minHeight={40}>
        <Typography variant="h2" lineHeight={0.95}>
          {title}
        </Typography>

        <Box>{actions}</Box>
      </Box>
      <Divider sx={{ marginBottom: 3, marginTop: 1.5 }} />
      <Box flexGrow={1} display="flex" flexDirection="column">
        {children}
      </Box>
    </CardContent>
  );
};
