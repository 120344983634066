import { ChartArea, ChartConfiguration, TooltipItem } from 'chart.js';
import { CalculatorResultDTO } from 'probonio-shared-ui/api';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../theme';

const CALCULATOR_ORDER = [
  'NET_INCOME',
  'BENEFITS',
  'WAGE_TAX',
  'RENT_INSURANCE',
  'SICK_INSURANCE',
  'AGE_INSURANCE',
  'CARE_INSURANCE',
  'SOLIDARITY_TAX',
];

export function useChartDataCalculator(resData?: CalculatorResultDTO): {
  config: ChartConfiguration<'pie', number[]>;
  chartArea: ChartArea | undefined;
} {
  const { t } = useTranslation('equivalenceCalculator');

  const [chartArea, setChartArea] = useState<ChartArea>();

  const equivalenceCalcData = useMemo(
    () => ({
      labels: CALCULATOR_ORDER.map(status => t(`graph.${status}`)),
      datasets: [
        {
          data: [
            (resData?.netYearlyIncome || 0) - (resData?.benefitCalculatedResult || 0),
            resData?.benefitCalculatedResult || 0,
            resData?.wageTax!,
            resData?.rentInsurance!,
            resData?.sickInsurance!,
            resData?.ageInsurance!,
            resData?.careInsurance!,
            resData?.solidarityTax!,
          ],
          backgroundColor: [
            colors.success,
            `${colors.primary}`,
            colors.error,
            `${colors.error}BF`,
            `${colors.error}9F`,
            `${colors.error}6F`,
            `${colors.error}3F`,
            `${colors.error}1F`,
          ],
          hoverOffset: 4,
        },
      ],
    }),
    [
      resData?.ageInsurance,
      resData?.benefitCalculatedResult,
      resData?.careInsurance,
      resData?.netYearlyIncome,
      resData?.rentInsurance,
      resData?.sickInsurance,
      resData?.solidarityTax,
      resData?.wageTax,
      t,
    ],
  );

  const config = useMemo(
    (): ChartConfiguration<'pie', number[]> => ({
      type: 'pie',
      data: equivalenceCalcData,
      plugins: [
        {
          id: 'test',
          beforeDraw: chart => {
            setChartArea({
              ...chart.chartArea,
              left: chart.chartArea.left + chart.canvas.offsetLeft,
              right: chart.chartArea.right + chart.canvas.offsetLeft,
              top: chart.chartArea.top + chart.canvas.offsetTop,
              bottom: chart.chartArea.bottom + chart.canvas.offsetTop,
            });
          },
        },
      ],
      options: {
        cutout: '40%',
        rotation: 0,
        aspectRatio: 2.75,
        responsive: true,
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: function (context: TooltipItem<'pie'>) {
                return context.parsed.toLocaleString('de') + '€';
              },
            },
          },
          legend: {
            onHover: event => {
              if (event.native?.target instanceof HTMLElement) {
                event.native.target.style.cursor = 'pointer';
              }
            },
            onLeave: event => {
              if (event.native?.target instanceof HTMLElement) {
                event.native.target.style.cursor = 'default';
              }
            },
            position: 'left',
            labels: {
              padding: 20,
              boxWidth: 10,
              boxHeight: 10,
              pointStyle: 'circle',
              usePointStyle: true,
              filter: (legendItem, data) => data.datasets[0].data[legendItem.index!] !== 0,
            },
          },
        },
      },
    }),
    [equivalenceCalcData],
  );
  return { config, chartArea };
}
