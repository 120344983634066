import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { TenantDTOCouponOrderAutomationBehaviourEnum } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SettingsFormPanel } from '../../../component/settings/SettingsFormPanel';
import { CouponSettingsForm, FormFields } from './CouponSettingsForm';

export const CouponBenefitSettingsPanel: React.FC = () => {
  const { t } = useTranslation('couponsModule');
  const { enqueueSnackbar } = useSnackbar();
  const { tenant, isLoading, invalidateTenant } = useTenant();

  const { control, reset, handleSubmit, formState } = useForm<FormFields>({
    defaultValues: {
      couponOrderDeadline: 31,
      couponFallbackDefinitionId: '',
      couponOrderAutomationBehaviour: TenantDTOCouponOrderAutomationBehaviourEnum.Default,
    },
  });

  const handleReset = useCallback(() => {
    if (!tenant) {
      return;
    }

    reset({
      couponOrderDeadline: tenant.couponOrderDeadline || 31,
      couponFallbackDefinitionId: tenant.couponFallbackDefinitionId || '',
      couponOrderAutomationBehaviour: tenant.couponOrderAutomationBehaviour || TenantDTOCouponOrderAutomationBehaviourEnum.Default,
    });
  }, [reset, tenant]);
  useEffect(handleReset, [handleReset]);

  const mutation = useMutation({
    mutationFn: async (values: FormFields) => {
      await apis.tenants.updateTenant({
        tenantId: tenant!.id,
        updateTenantDTO: {
          couponOrderDeadline: values?.couponOrderDeadline === 31 ? null : values?.couponOrderDeadline,
          couponFallbackDefinitionId: values?.couponFallbackDefinitionId ? values?.couponFallbackDefinitionId : null,
          couponOrderAutomationBehaviour: values?.couponOrderAutomationBehaviour,
        },
      });
    },

    onSuccess: () => {
      enqueueSnackbar(t('benefitSettings.successToast'), { variant: 'success' });
      invalidateTenant();
    },
  });

  const handleSave = useCallback(
    (values: FormFields) => {
      mutation.mutate(values);
    },
    [mutation],
  );

  return (
    <SettingsFormPanel
      title={t('benefitSettings.title')}
      info={t('benefitSettings.info')}
      isDirty={formState.isDirty}
      isLoading={mutation.isPending}
      onSubmit={handleSubmit(handleSave)}
      onReset={handleReset}
    >
      <CouponSettingsForm control={control} disabled={isLoading || mutation.isPending} />
    </SettingsFormPanel>
  );
};
