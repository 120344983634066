import { DateTime } from 'luxon';
import { CreateBenefitActivationDTO, UpdateBenefitActivationDTO } from 'probonio-shared-ui/api';
import React from 'react';
import { BaseActivationDialog, BaseActivationDialogProps } from './BaseActivationDialog';
import { ActivationDialogState } from './useActivationDialogState';

export interface ActivationDialogExtensions<T extends object>
  extends Pick<BaseActivationDialogProps<T>, 'onResetBenefitOptions' | 'onCreateActivation' | 'onUpdateActivation'> {
  Header?: React.ComponentType;
  Fields?: React.ComponentType;
  InlineField?: React.ComponentType;
  createLabel?: string;
  earliestStartDate?: DateTime;
}

export interface ActivationDialogSaveFunctions {
  activeEndDate?: DateTime;
  onCreateActivation: (create: CreateBenefitActivationDTO) => void | Promise<void>;
  onUpdateActivation?: (benefitActivationId: string, update: UpdateBenefitActivationDTO) => void | Promise<void>;
}

export interface ActivationDialogProps {
  activationDialogState: ActivationDialogState;
  isLoading?: boolean;
  saveFunctions: ActivationDialogSaveFunctions;
}

interface Props<T extends object> extends ActivationDialogProps {
  activationDialogExtensions: ActivationDialogExtensions<T>;
}

export const ActivationDialog = <T extends object>({
  activationDialogState,
  isLoading,
  activationDialogExtensions,
  saveFunctions,
}: Props<T>): JSX.Element => {
  const Header = activationDialogExtensions.Header;
  const Fields = activationDialogExtensions.Fields;
  const InlineField = activationDialogExtensions.InlineField;

  return (
    <BaseActivationDialog<T>
      activationDialogState={activationDialogState}
      isLoading={isLoading}
      activeEndDate={saveFunctions.activeEndDate}
      header={Header && <Header />}
      fields={Fields && <Fields />}
      inlineField={InlineField && <InlineField />}
      createLabel={activationDialogExtensions.createLabel}
      earliestStartDate={activationDialogExtensions.earliestStartDate}
      onResetBenefitOptions={activationDialogExtensions.onResetBenefitOptions}
      onCreateActivation={activationDialogExtensions.onCreateActivation}
      onUpdateActivation={activationDialogExtensions.onUpdateActivation}
      onSaveCreateBenefitActivationDTO={saveFunctions.onCreateActivation}
      onSaveUpdateBenefitActivationDTO={saveFunctions.onUpdateActivation}
    />
  );
};
