import { useTenant } from 'probonio-shared-ui/module/me';
import { useMemo } from 'react';
import { useConfigureBenefitsStep } from './stepHooks/useConfigureBenefitsStep';
import { useEmploymentContractStep } from './stepHooks/useEmploymentContractStep';
import { useFirstEmployeeStep } from './stepHooks/useFirstEmployeeStep';
import { useInformEmployeesStep } from './stepHooks/useInformEmployeesStep';
import { useInviteEmployeeStep } from './stepHooks/useInviteEmployeeStep';
import { useOrderCouponBudgetStep } from './stepHooks/useOrderCouponBudgetStep';
import { usePayrollReportStep } from './stepHooks/usePayrollReportStep';
import { useSEPAPaymentStep } from './stepHooks/useSEPAPaymentStep';
import { useTenantImageStep } from './stepHooks/useTenantImageStep';
import { TutorialStepDefinition } from './TutorialStep';

export interface TutorialStepsList {
  tutorialSteps: TutorialStepDefinition[];
  loading: boolean;
  totalCount: number;
  completedCount: number;
  openCount: number;
}

export function useTutorialSteps(): TutorialStepsList {
  const { tenant } = useTenant();

  const tenantImageStep = useTenantImageStep();
  const firstEmployeeStep = useFirstEmployeeStep();
  const configureBenefitsStep = useConfigureBenefitsStep();
  const orderCouponBudgetStep = useOrderCouponBudgetStep();
  const informEmployeesStep = useInformEmployeesStep();
  const inviteEmployeeStep = useInviteEmployeeStep();
  const sepaPaymentStep = useSEPAPaymentStep();
  const employmentContractStep = useEmploymentContractStep();
  const payrollReportStep = usePayrollReportStep();

  const activeBenefits = tenant?.activeBenefits;

  return useMemo((): TutorialStepsList => {
    const allSteps = [
      tenantImageStep,
      firstEmployeeStep,
      configureBenefitsStep,
      orderCouponBudgetStep,
      informEmployeesStep,
      inviteEmployeeStep,
      sepaPaymentStep,
      employmentContractStep,
      payrollReportStep,
    ];
    const filteredSteps = allSteps.filter(
      step => !step.requiredBenefits?.length || activeBenefits?.some(benefit => step.requiredBenefits!.includes(benefit)),
    );
    return {
      tutorialSteps: filteredSteps,
      loading: filteredSteps.some(step => step.loading),
      totalCount: filteredSteps.length,
      completedCount: filteredSteps.filter(step => step.completed).length,
      openCount: filteredSteps.filter(step => !step.loading && !step.completed).length,
    };
  }, [
    activeBenefits,
    configureBenefitsStep,
    employmentContractStep,
    firstEmployeeStep,
    informEmployeesStep,
    inviteEmployeeStep,
    orderCouponBudgetStep,
    payrollReportStep,
    sepaPaymentStep,
    tenantImageStep,
  ]);
}
