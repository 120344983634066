import { useTranslation } from 'react-i18next';
import { BenefitPaper } from '../../component/benefitTabs/BenefitPaper';
import { BENEFIT_ICONS } from '../../module/benefits/BenefitIcon';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { InternetReceiptList } from '../../module/benefits/internet/InternetReceiptList';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';

const InternetReceiptsPage: React.FC = () => {
  const { t } = useTranslation('benefitsModule');

  return (
    <AuthorizedLayout title={t('INTERNET.receipts')} breadcrumbs={[{ title: t('INTERNET.title'), to: '/benefits/internet' }]}>
      <BenefitSubPage icon={BENEFIT_ICONS.INTERNET} title={t('INTERNET.title')} subTitle={t('INTERNET.receipts')}>
        <BenefitPaper>
          <InternetReceiptList />
        </BenefitPaper>
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};

export default InternetReceiptsPage;
