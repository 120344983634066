import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query';
import { CouponsApiGetTransactionHistoryRequest, CouponTransactionsHistoryDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';

const PAGE_SIZE = 10;

export function useInfiniteTransactionHistory(
  parameters?: (pageParam: number) => Partial<CouponsApiGetTransactionHistoryRequest>,
  options?: { enabled?: boolean },
): UseInfiniteQueryResult<InfiniteData<CouponTransactionsHistoryDTO, Error>> {
  const getTenantId = useTenantID();

  return useInfiniteQuery({
    queryKey: [
      'tenants',
      getTenantId(),
      'benefits',
      'coupons',
      'transactionHistory',
      { pageSize: PAGE_SIZE, ...parameters?.(0) },
      apis.coupons.getTransactionHistory.name,
    ],
    queryFn: ({ pageParam = 0 }) =>
      apis.coupons
        .getTransactionHistory({
          tenantId: getTenantId(),
          page: pageParam,
          pageSize: PAGE_SIZE,
          ...parameters?.(pageParam),
        })
        .then(res => res.data),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => (pages.length * PAGE_SIZE < lastPage.totalCount ? pages.length : undefined),
    placeholderData: previousData => previousData,
    ...options,
  });
}
