import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { LoadingButton } from '../../../../../probonio-shared-ui/component/button';
import { BasicDialog, DialogState } from '../../../component/dialog';
import { colors } from '../../../theme';
import { DateTime } from 'luxon';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { apis } from 'probonio-shared-ui/module/api';
import { useCallback } from 'react';
import { BenefitActivationDTO } from 'probonio-shared-ui/api';
import { useRefetchActivations } from '../query';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { AxiosError } from 'axios';

interface Props {
  dialogState: DialogState;
  employeeId: string;
  activationToStop: BenefitActivationDTO;
}

export const StopActivationDialog: React.FC<Props> = ({ dialogState, employeeId, activationToStop }) => {
  const { t } = useTranslation('benefitsModule');
  const getTenantId = useTenantID();
  const refetchActivations = useRefetchActivations(employeeId);
  const withMessage = useWithMessage((err: Error) =>
    (err as AxiosError).response?.status === 422 ? t([`inuseError.${activationToStop.benefit}`, 'inuseError.default']) : undefined,
  );

  const newEndDate = DateTime.now().toFormat('yyyy-MM');

  const stopActivationMutation = useMutation({
    mutationFn: async (options: { activationIdToStop: string; endAtNearestDate?: boolean; endDate?: string }) => {
      const tenantId = getTenantId();
      await apis.benefits.updateBenefitActivation({
        tenantId,
        employeeId,
        benefitActivationId: options.activationIdToStop,
        updateBenefitActivationDTO: {
          endDate: options?.endDate,
          endAtNearestDate: options?.endAtNearestDate,
        },
      });
    },

    onSuccess: async () => {
      await refetchActivations();
      enqueueSnackbar(t('successStop'), { variant: 'success' });
      dialogState.handleClose();
    },
    onError: (err: Error) => {
      withMessage(err);
    },
  });

  const handleStopCurrentMonth = useCallback((): void => {
    stopActivationMutation.mutate({ activationIdToStop: activationToStop.id, endDate: newEndDate });
  }, [activationToStop.id, newEndDate, stopActivationMutation]);

  const handleStopNear = useCallback((): void => {
    stopActivationMutation.mutate({ activationIdToStop: activationToStop.id, endAtNearestDate: true });
  }, [activationToStop, stopActivationMutation]);

  return (
    <BasicDialog
      dialogState={dialogState}
      title={t('stopTitle')}
      content={
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body1" textAlign="left">
            <Trans i18nKey="benefitsModule:stopChoice" />
          </Typography>
        </Box>
      }
      actions={
        <Box>
          <Box
            sx={{
              border: 3,
              borderRadius: 1,
              borderColor: colors.primary,
              textAlign: 'center',
              padding: 2,
              marginBottom: 2,
            }}
          >
            <Typography display="block" whiteSpace="pre-wrap" sx={{ color: 'text.primary' }}>
              <Trans i18nKey="benefitsModule:stopConfirmation1" />
            </Typography>

            <LoadingButton
              variant="contained"
              loading={stopActivationMutation.isPending}
              onClick={handleStopNear}
              sx={{ marginTop: 2 }}
              data-test-id="button-stop-near"
            >
              {t('stopButton1')}
            </LoadingButton>
          </Box>

          <Box
            sx={{
              border: 3,
              borderRadius: 1,
              borderColor: colors.border.toggleButton,
              textAlign: 'center',
              padding: 2,
            }}
          >
            <Typography display="block" whiteSpace="pre-wrap" color="text.primary">
              <Trans i18nKey="benefitsModule:stopConfirmation2" />
            </Typography>
            <LoadingButton
              variant="contained"
              loading={stopActivationMutation.isPending}
              disabled={activationToStop.endDate?.substring(0, 7) === newEndDate}
              onClick={handleStopCurrentMonth}
              color="secondary"
              sx={{ marginTop: 2 }}
              data-test-id="button-stop-current-month"
            >
              {t('stopButton2')}
            </LoadingButton>
          </Box>
        </Box>
      }
    />
  );
};
