import { Alert, Box, Button, LinearProgress, LinearProgressProps, Typography } from '@mui/material';
import { UpsertBikeContractDTO } from 'probonio-shared-ui/api';
import { ImportError } from 'probonio-shared-ui/utils/excelFileParser';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicDialog, DialogState } from '../../../component/dialog';
import { FileDropZone } from '../../../component/form/FileDropZone';
import { parseContractsFromExcel } from './bikeContractImport';
import { useInvalidateBikeContracts } from './useBikeContracts';
import { useUpsertContractMutation } from './useUpsertContractMutation';
import { DialogLoadingButton } from '../../../component/dialog/DialogLoadingButton';

interface Props {
  dialogState: DialogState;
}

const LinearProgressWithLabel: React.FC<LinearProgressProps & { value: number }> = props => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

export const BikeImportDialog: React.FC<Props> = ({ dialogState }) => {
  const { t } = useTranslation('bikeModule');
  const [importState, setImportState] = useState<'ready' | 'running' | 'success' | 'error'>();
  const [errorLine, setErrorLine] = useState<number>(0);
  const [parsedContracts, setParsedContracts] = useState<{ contractNumber: string; contract: UpsertBikeContractDTO }[]>([]);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (dialogState.isOpen) {
      setImportState(undefined);
      setParsedContracts([]);
    }
  }, [dialogState.isOpen]);

  const handleSelectFile = useCallback(async (file: File) => {
    try {
      const parsedContracts = await parseContractsFromExcel(file);
      // check for duplicates: contract number and employee number must be unique
      const duplicateLine = parsedContracts.findIndex((record, index) =>
        parsedContracts.some(
          (otherRecord, otherIndex) =>
            otherIndex !== index &&
            (otherRecord.contract.employeeNumber === record.contract.employeeNumber ||
              otherRecord.contractNumber === record.contractNumber),
        ),
      );
      if (duplicateLine >= 0) {
        throw new ImportError('Duplicate entry found', duplicateLine + 1);
      }
      setParsedContracts(parsedContracts);
      setImportState('ready');
    } catch (err) {
      if (err instanceof ImportError) {
        setErrorLine(err.lineNumber);
        setImportState('error');
        setParsedContracts([]);
      } else {
        throw err;
      }
    }
  }, []);

  const mutation = useUpsertContractMutation();
  const invalidateContracts = useInvalidateBikeContracts();

  const handleSave = useCallback(async () => {
    setProgress(0);
    setImportState('running');
    try {
      for (const contractData of parsedContracts) {
        await mutation.mutateAsync(contractData);
        setProgress(p => p + 1);
      }
      await invalidateContracts();
      setImportState('success');
    } catch {
      setImportState('ready');
    }
  }, [invalidateContracts, mutation, parsedContracts]);

  return (
    <>
      <BasicDialog
        dialogState={dialogState}
        title={t('importDialog.title')}
        fullWidth
        maxWidth={700}
        content={
          <>
            <Alert severity="warning" sx={{ mb: 2 }}>
              {t('importDialog.dataPrivacyAlert')}
            </Alert>
            <Box display="flex" gap={2} mb={2}>
              <Typography variant="body2" color="text.secondary">
                {t('importDialog.templateInfo')}
              </Typography>
              <Button size="small" variant="outlined" href="/Firmenrad_Import_Vorlage.xlsx" sx={{ flexShrink: 0 }}>
                {t('importDialog.downloadTemplate')}
              </Button>
            </Box>
            <FileDropZone
              sx={{ mb: 2 }}
              disabled={importState === 'running' || importState === 'success'}
              accept={{ 'text/csv': ['.csv'], 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] }}
              onSelect={handleSelectFile}
            />
            {!importState && <Alert severity="info">{t('importDialog.state.initial')}</Alert>}
            {importState === 'ready' && <Alert severity="info">{t('importDialog.state.ready', { count: parsedContracts.length })}</Alert>}
            {importState === 'running' && <LinearProgressWithLabel value={(100 * progress) / parsedContracts.length} sx={{ my: 2.2 }} />}
            {importState === 'success' && (
              <Alert severity="success">{t('importDialog.state.success', { count: parsedContracts.length })}</Alert>
            )}
            {importState === 'error' && <Alert severity="error">{t('importDialog.state.error', { lineNumber: errorLine + 1 })}</Alert>}
          </>
        }
        actions={
          <>
            {importState !== 'success' && (
              <DialogLoadingButton
                size="small"
                loading={importState === 'running'}
                disabled={importState !== 'ready'}
                onClick={handleSave}
                data-test-id="bike-import-save"
              >
                {t('common:buttons.save')}
              </DialogLoadingButton>
            )}
          </>
        }
      />
    </>
  );
};
