import { type CUSTOM_BENEFIT_ICONS } from 'probonio-shared-ui/component/icon';
import { Control, useWatch } from 'react-hook-form';
import { FormValues } from '../../../useCustomBenefitCreator';
import { VisualisationPreview } from './VisualisationPreview';

interface Props {
  control: Control<FormValues>;
}

export const VisualisationPreviewForm: React.FC<Props> = ({ control }) => {
  const benefitTitle = useWatch<FormValues>({ control, name: 'title' }) as string;

  const icon = useWatch<FormValues>({ control, name: 'icon' }) as keyof typeof CUSTOM_BENEFIT_ICONS;
  const defaultMonthlyGrant = parseFloat(useWatch({ control, name: 'defaultMonthlyGrant' })?.toString() || '') * 100 || 0;
  const showInVisualisation = useWatch({ control, name: 'showInVisualisation' });

  return (
    <VisualisationPreview
      defaultMonthlyGrant={defaultMonthlyGrant}
      showInVisualisation={showInVisualisation}
      benefitTitle={benefitTitle}
      icon={icon}
    />
  );
};
