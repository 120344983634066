import { useEffect } from 'react';

interface ValidateFieldsProps {
  setCanContinue: (newValue: boolean) => void;
}
// TODO: Think of better structure as this was only way to prevent whole component from rerendering
export const HelpContentValidation: React.FC<ValidateFieldsProps> = ({ setCanContinue }) => {
  useEffect(() => {
    setCanContinue(true);
  }, [setCanContinue]);

  return <></>;
};
