import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { BikeleasingConditionDTO } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { useCallback, useMemo } from 'react';
import { useInvalidateBikeleasingConditions } from './useBikeleasingConditions';
import { useTranslation } from 'react-i18next';

const LATEST_CONDITION_QUERY_KEY = ['benefits', 'bikeleasing', 'conditions', { page: 0, pageSize: 1, sortBy: ['lastSyncedAt:desc'] }];

export function useBikeleasingLatestSyncedCondition(): BikeleasingConditionDTO | undefined {
  const { data: conditions } = useTenantQuery(LATEST_CONDITION_QUERY_KEY, tenantId =>
    apis.bikeLeasing.listConditions({ tenantId, page: 0, pageSize: 1, sortBy: ['lastSyncedAt:desc'] }).then(res => res.data),
  );
  return conditions?.results[0];
}

export interface BikeleasingSyncMutation {
  syncBikeleasingTenant: () => void;
  isLoading: boolean;
}
export function useBikeleasingTenantSyncMutation(): BikeleasingSyncMutation {
  const queryClient = useQueryClient();
  const withMessage = useWithMessage();
  const { enqueueSnackbar } = useSnackbar();
  const getTenantId = useTenantID();
  const invalidateConditions = useInvalidateBikeleasingConditions();

  const { t } = useTranslation('benefitsModule');
  const mutation = useMutation({
    mutationFn: async () => {
      await apis.bikeLeasing.syncConditionsWithEmployees({ tenantId: getTenantId() });
    },
  });

  const syncBikeleasingTenant = useCallback(async () => {
    try {
      await mutation.mutateAsync();
      await invalidateConditions();
      await queryClient.invalidateQueries({ queryKey: ['tenants', getTenantId(), ...LATEST_CONDITION_QUERY_KEY] });
      enqueueSnackbar(t('BIKELEASING.syncSuccess'), { variant: 'success' });
    } catch (e) {
      withMessage(e as Error);
    }
  }, [enqueueSnackbar, getTenantId, invalidateConditions, mutation, queryClient, t, withMessage]);

  return useMemo(
    () => ({
      syncBikeleasingTenant,
      isLoading: mutation.isPending,
    }),
    [mutation.isPending, syncBikeleasingTenant],
  );
}
