import { Box, Typography } from '@mui/material';
import { useTenant } from 'probonio-shared-ui/module/me';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const PaymentMethodInfo: React.FC = () => {
  const { t } = useTranslation('tenantModule');

  const { tenant } = useTenant();

  let paymentMethod = 'bankTransfer';
  if (tenant?.sepa && tenant?.sepaMandate) {
    paymentMethod = 'sepa';
  }
  const sepaInProgress = tenant?.sepa && !tenant?.sepaMandate;

  return (
    <Box>
      <Typography variant="h3" gutterBottom>
        {t(`paymentMethods.methodName`, { paymentMethod })}
      </Typography>
      {sepaInProgress && (
        <Typography variant="body2" gutterBottom>
          {t('paymentMethods.sepaInProgress')}
        </Typography>
      )}
      <Typography variant="body2" color="text.secondary">
        <Trans i18nKey="tenantModule:paymentMethods.changeHint">
          <Link to="/settings/payment" />
        </Trans>
      </Typography>
    </Box>
  );
};
