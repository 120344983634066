import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Checkbox, Typography, useTheme } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenant, useTenantID } from 'probonio-shared-ui/module/me';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogState } from '../../../component/dialog';

interface Props {
  dialogState: DialogState;
}

export const AddUserAccordion: React.FC<Props> = ({ dialogState }) => {
  const { t } = useTranslation('usersModule');
  const theme = useTheme();
  const getTenantId = useTenantID();
  const { tenant, invalidateTenant } = useTenant();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [checked, setChecked] = useState(false);

  const handlePanelChange =
    (panel: string): ((event: React.SyntheticEvent, isExpanded: boolean) => void) =>
    (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  }, []);

  const createRegistrationCodeMutation = useMutation({
    mutationFn: () => apis.tenants.createRegistrationCode({ tenantId: getTenantId() }),
    onSuccess: invalidateTenant,
  });

  const handleClick = useCallback(() => {
    createRegistrationCodeMutation.mutate();
    setChecked(false);
  }, [createRegistrationCodeMutation]);

  useEffect(() => {
    if (createRegistrationCodeMutation.isIdle && dialogState.isOpen && tenant && !tenant.registrationCode) {
      createRegistrationCodeMutation.mutate();
    }
  }, [createRegistrationCodeMutation, dialogState.isOpen, tenant]);

  return (
    <Box>
      <Accordion expanded={expanded === 'codeChange'} onChange={handlePanelChange('codeChange')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={theme.palette.text.secondary}>{t('createNewUserFields.codeChange')}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: 0, paddingLeft: 2, paddingRight: 2, paddingBottom: 2 }}>
          <Alert severity="error">{t('createNewUserFields.codeWarning')}</Alert>
          <Box display="flex" alignItems="center">
            <Checkbox checked={checked} onChange={handleCheckboxChange} />
            <Typography variant="body2" color={theme.palette.text.secondary}>
              {t('createNewUserFields.codeChangeConfirm')}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="right">
            <Button variant="contained" color="error" disabled={!checked} size="small" onClick={handleClick}>
              {t('createNewUserFields.codeChange')}
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
