import { Button } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ErrorView } from '../module/error/ErrorView';

const Error403Page: React.FC = () => {
  const { t } = useTranslation('errorModule');
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate('/logout');
  }, [navigate]);

  return (
    <ErrorView
      headlineLarge={t('unauthorized.headlineLarge')}
      headlineSmall={t('unauthorized.headlineSmall')}
      message={t('unauthorized.message')}
    >
      <Button color="warning" variant="contained" onClick={handleClick} data-test-id="error-403-button">
        {t('goHome')}
      </Button>
    </ErrorView>
  );
};

export default Error403Page;
