import { UseMutationResult, UseQueryResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import {
  ApplyBenefitTemplateDTO,
  BenefitTemplateListDTO,
  CreateBenefitTemplateDTO,
  UpdateBenefitTemplateDTO,
} from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { useTranslation } from 'react-i18next';
import { useRefetchActivations } from '../../benefits/query';

export function useBenefitTemplates(options?: { enabled?: boolean }): UseQueryResult<BenefitTemplateListDTO, Error> {
  return useTenantQuery(
    ['benefitTemplates', apis.benefits.getBenefitTemplates.name],
    tenantId => apis.benefits.getBenefitTemplates({ tenantId }).then(res => res.data),
    options,
  );
}

export function useCreateBenefitTemplateMutation(): UseMutationResult<string, Error, CreateBenefitTemplateDTO> {
  const getTenantId = useTenantID();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async createDTO => {
      const { id } = await apis.benefits
        .createBenefitTemplate({ tenantId: getTenantId(), createBenefitTemplateDTO: createDTO })
        .then(res => res.data);
      return id;
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['tenants', getTenantId(), 'benefitTemplates'] });
    },
  });
}

export function useUpdateBenefitTemplateMutation(): UseMutationResult<
  void,
  Error,
  { benefitTemplateId: string; update: UpdateBenefitTemplateDTO }
> {
  const getTenantId = useTenantID();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ benefitTemplateId, update }) => {
      await apis.benefits.updateBenefitTemplate({ tenantId: getTenantId(), benefitTemplateId, updateBenefitTemplateDTO: update });
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['tenants', getTenantId(), 'benefitTemplates'] });
    },
  });
}

export function useDeleteBenefitTemplateMutation(): UseMutationResult<void, Error, string> {
  const getTenantId = useTenantID();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (benefitTemplateId: string) => {
      await apis.benefits.deleteBenefitTemplate({ tenantId: getTenantId(), benefitTemplateId });
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['tenants', getTenantId(), 'benefitTemplates'] });
    },
  });
}

export function useApplyBenefitTemplateMutation(employeeId: string): UseMutationResult<void, Error, ApplyBenefitTemplateDTO> {
  const { t } = useTranslation('benefitsModule');
  const { enqueueSnackbar } = useSnackbar();
  const getTenantId = useTenantID();
  const withMessage = useWithMessage((err: Error) => {
    if ((err as AxiosError).response?.status === 409) {
      return t('conflictError');
    }
    return undefined;
  });
  const refetchActivations = useRefetchActivations(employeeId);

  return useMutation({
    mutationFn: async (dto: ApplyBenefitTemplateDTO) => {
      await apis.benefits.applyBenefitTemplate({ tenantId: getTenantId(), employeeId, applyBenefitTemplateDTO: dto });
    },

    onError: withMessage,
    onSuccess: async () => {
      enqueueSnackbar(t('benefitsModule:successCreate'), { variant: 'success' });
      await refetchActivations();
    },
  });
}
