import { Box, Button, SxProps, Typography, useTheme } from '@mui/material';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

interface Props {
  disabled?: boolean;
  accept?: Record<string, string[]>;
  sx?: SxProps;
  onSelect: (file: File) => void;
}

export const FileDropZone: React.FC<Props> = ({ disabled, accept, sx, onSelect }) => {
  const { t } = useTranslation('common');
  const theme = useTheme();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length) {
        onSelect(acceptedFiles[0]);
      }
    },
    [onSelect],
  );
  const { getRootProps, getInputProps, isDragAccept, isDragReject, open, acceptedFiles } = useDropzone({
    onDrop,
    disabled,
    accept,
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });

  let borderColor = theme.palette.grey[400];
  if (isDragAccept) {
    borderColor = theme.palette.success.main;
  } else if (isDragReject) {
    borderColor = theme.palette.error.main;
  }

  const currentFile = acceptedFiles[0];

  return (
    <Box
      sx={{
        textAlign: 'center',
        padding: 4,
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor,
        borderRadius: 1,
        ...sx,
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Typography variant="h3" mb={1} noWrap>
        {currentFile?.name || t('fileUpload.dropHere')}
      </Typography>
      <Typography variant="body2" color="text.secondary" mb={1}>
        {currentFile ? <>&nbsp;</> : t('fileUpload.divider')}
      </Typography>
      <Button size="small" variant="contained" onClick={open} disabled={disabled}>
        {t('fileUpload.browse')}
      </Button>
    </Box>
  );
};
