import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query';
import { CouponGiftWithEmployeeListDTO, CouponsApiListTenantCouponGiftsRequest } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';

const PAGE_SIZE = 10;

export function useInfiniteCouponGifts(
  parameters?: (pageParam: number) => Partial<CouponsApiListTenantCouponGiftsRequest>,
  options?: { enabled?: boolean },
): UseInfiniteQueryResult<InfiniteData<CouponGiftWithEmployeeListDTO, Error>> {
  const getTenantId = useTenantID();

  return useInfiniteQuery({
    queryKey: [
      'tenants',
      getTenantId(),
      'benefits',
      'coupons',
      'gifts',
      { pageSize: PAGE_SIZE, ...parameters?.(0) },
      apis.coupons.listTenantCouponGifts.name,
    ],
    queryFn: ({ pageParam = 0 }) =>
      apis.coupons
        .listTenantCouponGifts({
          tenantId: getTenantId(),
          page: pageParam,
          pageSize: PAGE_SIZE,
          ...parameters?.(pageParam),
        })
        .then(res => res.data),
    getNextPageParam: (lastPage: { totalCount: number }, pages) =>
      pages.length * PAGE_SIZE < lastPage.totalCount ? pages.length : undefined,
    initialPageParam: 0,
  });
}
