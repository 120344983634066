import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Button, IconButton, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCurrentCustomBenefitTemplate } from '../../../customBenefitTemplates';
import { FormValues } from '../../../useCustomBenefitCreator';

interface Props {
  control: Control<FormValues>;
}

export const DashboardContentImage: React.FC<Props> = ({ control }) => {
  const { t } = useTranslation('customBenefitsModule');
  const { enqueueSnackbar } = useSnackbar();

  const currentCustomBenefitTemplate = useCurrentCustomBenefitTemplate();
  const { field } = useController({ control: control, name: 'image' });

  const handleSelectFile = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    ev => {
      const maxSize500KB = 500 * 1024;
      const file = ev.target.files?.[0];
      // so it is possible to use same image
      ev.target.value = '';

      if (!file) {
        return;
      }
      if (file.size > maxSize500KB) {
        enqueueSnackbar(t('common:fileToBig', { maxKB: 500 }), { variant: 'error' });
        return;
      }

      field.onChange(file);
      field.onBlur();
    },
    [enqueueSnackbar, field, t],
  );

  const resetImageTemplate = useCallback(() => {
    field.onChange(currentCustomBenefitTemplate ? currentCustomBenefitTemplate?.benefit.imageUrl : '');
  }, [currentCustomBenefitTemplate, field]);

  return (
    <>
      <Button data-test-id="custom-benefit-creator-page-content-image-button" component="label" variant="outlined" color="primary">
        <input type="file" accept="image/*" style={{ display: 'none' }} onChange={handleSelectFile} />
        {t('customBenefitCreator.content.summaryStep.selectPictureText')}
      </Button>
      {/* Checking if currently selected image is the same as image provided by template  */}
      {field.value !== currentCustomBenefitTemplate?.benefit.imageUrl ? (
        <IconButton sx={{ marginLeft: '10px' }} size="small" onClick={resetImageTemplate}>
          <RestartAltIcon />
        </IconButton>
      ) : (
        <> </>
      )}
      <br />
      <Typography variant="caption">{t('customBenefitCreator.content.summaryStep.selectPictureHelperText')}</Typography>
    </>
  );
};
