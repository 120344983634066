import { Chip, Paper, Stack, styled, Tab, Tabs } from '@mui/material';
import React from 'react';
import { useTabsState } from '../tab';
import { TabsState } from '../tab/tabState';
import { BenefitTabProps } from './BenefitTab';

const StyledTabs = styled(Tabs, { shouldForwardProp: prop => prop !== 'size' })<{ size?: 'md' | 'sm' }>(({ size, theme }) => ({
  boxShadow: `inset 0px -2px 0px ${theme.palette.secondary.main}`,
  backgroundColor: theme.palette.secondary.main,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  minHeight: size === 'sm' ? 32 : undefined,
}));

const StyledTab = styled(Tab, { shouldForwardProp: prop => prop !== 'pullRight' && prop !== 'size' })<{
  pullRight?: boolean;
  size?: 'md' | 'sm';
}>(({ pullRight, size, theme }) => ({
  textTransform: 'none',
  marginLeft: pullRight ? 'auto' : undefined,
  minHeight: size === 'sm' ? 32 : undefined,
  padding: size === 'sm' ? '4px 12px' : undefined,
}));

interface TableLabelProps {
  active: boolean;
  label: string;
  icon?: React.ReactElement;
  count?: number;
}

const TabLabel: React.FC<TableLabelProps> = ({ label, count, active, icon }) => {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {icon}
      {label}
      {(count || count === 0) && <Chip sx={{ cursor: 'inherit' }} color={active ? 'primary' : 'default'} label={count} size="small" />}
    </Stack>
  );
};

interface Props {
  tabsState?: TabsState<string> | TabsState<number>;
  size?: 'md' | 'sm';
  variant?: 'standard' | 'scrollable' | 'fullWidth';
}

export const BenefitTabPanel: React.FC<React.PropsWithChildren<Props>> = ({ tabsState: controlledTabsState, size, variant, children }) => {
  const uncontrolledTabsState = useTabsState();
  const tabsState = controlledTabsState || uncontrolledTabsState;
  const tabs = React.Children.toArray(children) as React.ReactElement<BenefitTabProps>[];
  const activeTab =
    typeof tabsState.tabIndex === 'number' ? tabs[tabsState.tabIndex] : tabs.find(tab => tab.props.value === tabsState.tabIndex);

  return (
    <Paper>
      <StyledTabs size={size} variant={variant} value={tabsState.tabIndex} onChange={tabsState.handleTabChange}>
        {tabs.map((childTab, index) => (
          <StyledTab
            size={size}
            key={index}
            label={
              <TabLabel
                icon={childTab.props.labelIcon}
                label={childTab.props.label}
                count={childTab.props.count}
                active={childTab.props.value !== undefined ? tabsState.tabIndex === childTab.props.value : tabsState.tabIndex === index}
              />
            }
            value={childTab.props.value}
            pullRight={childTab.props.pullRight}
            disabled={childTab.props.disabled}
            data-test-id={`benefit-tab-panel-status-label-${
              childTab.props.value === undefined ? childTab.props.label : childTab.props.value || '_empty'
            }`}
          />
        ))}
      </StyledTabs>
      {activeTab}
    </Paper>
  );
};
