import { Box } from '@mui/material';
import { MRT_Row, MRT_SortingState } from 'material-react-table';
import { BikeleasingContractWithFullNameDTO } from 'probonio-shared-ui/api';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfiniteScrollReactTable } from '../../../../component/table/InfiniteScrollReactTable';
import { useLoadMore } from '../../../../component/table/useLoadMore';
import { useNavigateWithPrevPath } from '../../../../util/useNavigateWithPrevPath';
import { useBikeleasingContractColumns } from '../useBikeleasingContractColumns';
import { useInfiniteBikeleasingContracts } from '../useInfiniteBikeleasingContracts';
import { mapSortModel } from '../../../../component/table/sortMapper';
import { EmployeeFilterState } from '../../employeeStatusList/employeeFilterState';

interface Props {
  filterState: EmployeeFilterState;
}

const BikeleasingContractsTable: React.FC<Props> = ({ filterState }) => {
  const { t } = useTranslation('benefitsModule');
  const navigate = useNavigateWithPrevPath();
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const columns = useBikeleasingContractColumns();
  const queryResult = useInfiniteBikeleasingContracts({
    sortBy: mapSortModel(columns, sorting),
    searchText: filterState.debouncedFilter.searchText,
  });

  const loadMoreProps = useLoadMore(queryResult, 'results');
  const handleRowProps = useCallback(
    ({ row }: { row: MRT_Row<BikeleasingContractWithFullNameDTO> }) => ({
      onClick: () => {
        navigate(`/benefits/bikeleasing/employee/${row.original.employeeId}/contracts/${row.original.id}`);
      },
      onKeyDown: (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
          navigate(`/benefits/bikeleasing/employee/${row.original.employeeId}/contracts/${row.original.id}`);
        }
      },
      sx: { cursor: 'pointer' },
    }),
    [navigate],
  );

  return (
    <Box data-test-id="bikeleasing-contracts-table">
      <InfiniteScrollReactTable
        {...loadMoreProps}
        columns={columns}
        enablePagination={false}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        enableColumnActions={false}
        enableRowActions
        manualSorting
        state={{ sorting }}
        onSortingChange={setSorting}
        localization={{ noRecordsToDisplay: t('BIKELEASING.contractsTable.noResults') }}
        muiTableBodyRowProps={handleRowProps}
      />
    </Box>
  );
};

export default React.memo(BikeleasingContractsTable);
