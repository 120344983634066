import { EmployeeDTOCivilStatusEnum } from 'probonio-shared-ui/api';
import { useTenant } from 'probonio-shared-ui/module/me';

export function useRecreationMaxBudget(civilStatus: EmployeeDTOCivilStatusEnum, numberOfKids: number): number {
  const { tenant } = useTenant();

  if (!tenant?.taxConfig) {
    return 0;
  }

  return (
    tenant.taxConfig.recreationBaseAmount +
    (civilStatus === EmployeeDTOCivilStatusEnum.Married || civilStatus === EmployeeDTOCivilStatusEnum.CivilUnion
      ? tenant.taxConfig.recreationPartnerAmount
      : 0) +
    tenant.taxConfig.recreationChildAmount * numberOfKids
  );
}
