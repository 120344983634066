import React from 'react';
import { MaxWidthBox } from './BoxLayout';
import { Box, BoxProps } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

const ContainerBox: React.FC<Props & BoxProps> = ({ children, ...props }) => {
  return (
    <Box sx={{ width: '100%', justifyContent: 'center', display: 'flex' }} {...props}>
      <MaxWidthBox>{children}</MaxWidthBox>
    </Box>
  );
};

export default ContainerBox;
