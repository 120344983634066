import { UseQueryResult } from '@tanstack/react-query';
import { TenantApiGetTenantExportHistoryRequest, TenantExportHistoryWithUserListDTO } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';

export function usePayrollExportHistoryQuery(
  params: Omit<TenantApiGetTenantExportHistoryRequest, 'tenantId'>,
): UseQueryResult<TenantExportHistoryWithUserListDTO, Error> {
  const getTenantId = useTenantID();
  return useTenantQuery(['exportHistory', { tenantId: getTenantId(), params }, apis.tenants.getTenantExportHistory.name], tenantId =>
    apis.tenants
      .getTenantExportHistory({
        tenantId,
        ...params,
      })
      .then(res => res.data),
  );
}
