import { createTheme } from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    topBarButtonBorder: true;
  }
  interface ButtonPropsColorOverrides {
    primaryMuted: true;
  }
}

declare module '@mui/material/SvgIcon/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    primaryMuted: true;
  }
}

declare module '@mui/material/Divider' {
  interface DividerPropsVariantOverrides {
    lightBlue: true;
  }
}
declare module '@mui/material/styles' {
  interface Palette {
    primaryMuted: Palette['primary'];
  }
  interface PaletteOptions {
    primaryMuted: PaletteOptions['primary'];
  }
  interface TypeBackground {
    alpha?: string;
    tabs?: string;
  }
}

export const fontSizes = {
  sm: '0.875rem',
  md: '1rem',
  lg: '1.25rem',
  xl: '1.375rem',
  xxl: '1.625rem',
  xxxl: '3.75rem',
};

export const fontWeights = {
  bold: 600,
  regular: 400,
  semiBold: 500,
};

export const colors = {
  white: '#ffffff',
  primary: '#004976',
  primaryMuted: '#9BCBEB',
  secondary: '#e4eef7',
  error: '#DD1155',
  warning: '#ff9800',
  success: '#38C895',
  info: '#eff0f2',
  text: {
    primary: '#060F27',
    secondary: '#5a677f',
    light: '#5a677f33',
    warning: '#C7A416',
    topBarButtonBorder: '#5a677f',
  },
  border: {
    toggleButton: '#C8DDEE',
    alert: '#ff9800',
  },
  bg: {
    default: '#fafafa',
    alert: 'rgba(249, 214, 69, 0.2)',
  },
  chip: {
    info: {
      bg: '#e4eef7',
      text: '#004976',
    },
    warning: {
      bg: '#fef7da',
      text: '#664414',
    },
    error: {
      bg: '#fcebf1',
      text: '#5a192f',
    },
    success: {
      bg: '#edf7ed',
      text: '#1e4620',
    },
  },
};

export const spacings = {
  main: '50px',
  navbarAvatar: 32,
  maxWidth: '1440px',
  borderRadius: '15px',
};

const { palette } = createTheme();

export const MATERIAL_THEME = createTheme({
  components: {
    MuiSvgIcon: {
      variants: [
        {
          props: { fontSize: 'large' },
          style: {
            fontSize: '2.5rem',
          },
        },
      ],
    },
    MuiButton: {
      defaultProps: { variant: 'contained', disableElevation: true },
      variants: [
        {
          props: { variant: 'topBarButtonBorder' },
          style: {
            paddingLeft: '0px',
            paddingRight: '0px',
            minWidth: '45px',
            backgroundColor: colors.white,
            color: colors.text.topBarButtonBorder,
            border: 'solid 1px rgb(90, 103, 127, 0.4)',
            '&:hover': {
              backgroundColor: colors.white,
              border: 'solid 1px rgb(118, 118, 118)',
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        sizeMedium: {
          height: '45px',
          fontSize: fontSizes.md,
          paddingLeft: '30px',
          paddingRight: '30px',
        },
        sizeSmall: {
          height: '40px',
          fontSize: fontSizes.sm,
          lineHeight: 1.5,
          paddingLeft: '20px',
          paddingRight: '20px',
        },
        sizeLarge: {
          fontSize: fontSizes.xl,
          paddingLeft: '30px',
          paddingRight: '30px',
        },
        outlined: {
          borderWidth: '2px',
          '&:hover': {
            borderWidth: '2px',
          },
        },
      },
    },
    MuiDivider: {
      variants: [
        {
          props: { variant: 'lightBlue' },
          style: {
            height: 2,
            width: '100%',
            backgroundColor: `${colors.border.toggleButton}`,
            opacity: 0.5,
            margin: '20px 0px',
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: fontSizes.md,
          fontWeight: fontWeights.regular,
          color: colors.text.secondary,
        },
        input: {
          padding: '11px 14px',
          '&.MuiInputBase-inputAdornedStart': {
            paddingLeft: 0,
          },
          '&.MuiInputBase-inputAdornedEnd': {
            paddingRight: 0,
          },
        },
        inputSizeSmall: {
          padding: '8.5px 14px',
          '&.MuiInputBase-inputAdornedStart': {
            paddingLeft: 0,
          },
          '&.MuiInputBase-inputAdornedEnd': {
            paddingRight: 0,
          },
        },
        inputMultiline: {
          padding: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: fontSizes.md,
          color: colors.text.secondary,
        },
        outlined: {
          transform: 'translate(14px, 12px) scale(1)',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -9px) scale(0.75)',
          },
        },
        sizeSmall: {
          '&.MuiInputLabel-outlined': {
            transform: 'translate(14px, 9px) scale(1)',
          },
          '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -9px) scale(0.75)',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: '4px 9px 3px 9px',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: `1px solid ${colors.border.toggleButton}`,
          color: colors.text.secondary,
          textTransform: 'none',
          fontWeight: fontWeights.semiBold,
          fontSize: fontSizes.md,
          '&.MuiToggleButton-primary': {
            backgroundColor: `${colors.white}`,
            '&.Mui-selected': {
              border: `1px solid ${colors.primary}`,
              backgroundColor: `${colors.primary}`,
              color: `${colors.white}`,
            },
          },
        },
        sizeMedium: {
          height: '45px',
        },
        sizeSmall: {
          height: '40px',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardWarning: {
          backgroundColor: colors.bg.alert,
        },
        standardInfo: {
          backgroundColor: colors.bg.default,
          color: colors.text.primary,
          '& .MuiAlert-icon': {
            color: colors.text.primary,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.color === 'success' &&
            ownerState.variant === 'filled' && {
              backgroundColor: colors.chip.success.bg,
              color: colors.chip.success.text,
            }),
          ...(ownerState.color === 'warning' &&
            ownerState.variant === 'filled' && {
              backgroundColor: colors.chip.warning.bg,
              color: colors.chip.warning.text,
            }),
          ...(ownerState.color === 'error' &&
            ownerState.variant === 'filled' && {
              backgroundColor: colors.chip.error.bg,
              color: colors.chip.error.text,
            }),
          ...(ownerState.color === 'info' &&
            ownerState.variant === 'filled' && {
              backgroundColor: colors.chip.info.bg,
              color: colors.chip.info.text,
            }),
        }),
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          '& .MuiToolbar-root': {
            padding: `0 ${spacings.main}`,
          },
        },
        colorDefault: {
          backgroundColor: colors.white,
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          backgroundColor: colors.primary,
          color: colors.white,
          fontSize: fontSizes.sm,
          width: '100%',
          justifyContent: 'space-around',
          padding: `0 ${spacings.main}`,
        },
        ol: {
          maxWidth: spacings.maxWidth,
          width: '100%',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: colors.secondary,
          },
        },
      },
    },
  },

  shape: {
    borderRadius: 8,
  },

  spacing: 10,

  typography: {
    h1: {
      fontSize: fontSizes.xxl,
      fontWeight: fontWeights.bold,
    },
    h2: {
      fontSize: fontSizes.xl,
      fontWeight: fontWeights.bold,
    },
    h3: {
      fontWeight: fontWeights.bold,
      fontSize: fontSizes.md,
    },
    h4: {
      fontWeight: fontWeights.bold,
      fontSize: fontSizes.md,
      textTransform: 'uppercase',
    },
    subtitle1: {
      fontSize: fontSizes.lg,
      fontWeight: fontWeights.bold,
    },
    h5: {
      fontWeight: fontWeights.bold,
    },
    h6: {
      fontWeight: fontWeights.bold,
      fontSize: fontSizes.md,
      textTransform: 'uppercase',
    },
    body1: {
      fontSize: fontSizes.md,
      fontWeight: fontWeights.regular,
    },
    body2: {
      fontSize: fontSizes.sm,
      fontWeight: fontWeights.regular,
    },
  },
  palette: {
    text: {
      primary: colors.text.primary,
      secondary: colors.text.secondary,
    },
    primary: { main: colors.primary, contrastText: colors.white },
    primaryMuted: palette.augmentColor({ color: { main: colors.primaryMuted, contrastText: colors.white } }),
    secondary: { main: colors.secondary, contrastText: colors.text.primary, light: colors.text.light },
    info: { main: colors.info },
    warning: { main: colors.warning },
    success: { main: colors.success, contrastText: colors.white },
    error: { main: colors.error },
    background: {
      default: colors.bg.default,
      paper: colors.white,
      alpha: '#0004',
    },
  },
});
