/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { EmployeeDTO } from './employee-dto';

/**
 * 
 * @export
 * @interface CouponsV2TokenOrderDTO
 */
export interface CouponsV2TokenOrderDTO {
    /**
     * 
     * @type {string}
     * @memberof CouponsV2TokenOrderDTO
     */
    'status': CouponsV2TokenOrderDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CouponsV2TokenOrderDTO
     */
    'orderReason': CouponsV2TokenOrderDTOOrderReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof CouponsV2TokenOrderDTO
     */
    'giftOccasion'?: CouponsV2TokenOrderDTOGiftOccasionEnum;
    /**
     * 
     * @type {string}
     * @memberof CouponsV2TokenOrderDTO
     */
    'giftType'?: CouponsV2TokenOrderDTOGiftTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CouponsV2TokenOrderDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CouponsV2TokenOrderDTO
     */
    'orderDate': string;
    /**
     * 
     * @type {string}
     * @memberof CouponsV2TokenOrderDTO
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof CouponsV2TokenOrderDTO
     */
    'amount': number;
    /**
     * 
     * @type {EmployeeDTO}
     * @memberof CouponsV2TokenOrderDTO
     */
    'employee'?: EmployeeDTO;
    /**
     * 
     * @type {string}
     * @memberof CouponsV2TokenOrderDTO
     */
    'tenantId': string;
    /**
     * 
     * @type {string}
     * @memberof CouponsV2TokenOrderDTO
     */
    'tenantName'?: string;
}

export const CouponsV2TokenOrderDTOStatusEnum = {
    Initial: 'INITIAL',
    InvoiceCreated: 'INVOICE_CREATED',
    Paid: 'PAID',
    Available: 'AVAILABLE',
    Delivered: 'DELIVERED',
    PaymentFailed: 'PAYMENT_FAILED',
    PendingRefund: 'PENDING_REFUND',
    Cancelled: 'CANCELLED'
} as const;

export type CouponsV2TokenOrderDTOStatusEnum = typeof CouponsV2TokenOrderDTOStatusEnum[keyof typeof CouponsV2TokenOrderDTOStatusEnum];
export const CouponsV2TokenOrderDTOOrderReasonEnum = {
    BenefitActivation: 'BENEFIT_ACTIVATION',
    Gift: 'GIFT'
} as const;

export type CouponsV2TokenOrderDTOOrderReasonEnum = typeof CouponsV2TokenOrderDTOOrderReasonEnum[keyof typeof CouponsV2TokenOrderDTOOrderReasonEnum];
export const CouponsV2TokenOrderDTOGiftOccasionEnum = {
    Birthday: 'BIRTHDAY',
    SaintsDay: 'SAINTS_DAY',
    ChildBirth: 'CHILD_BIRTH',
    ChildBaptism: 'CHILD_BAPTISM',
    ChildCommunion: 'CHILD_COMMUNION',
    ChildConfirmationCath: 'CHILD_CONFIRMATION_CATH',
    ChildConfirmationProt: 'CHILD_CONFIRMATION_PROT',
    Wedding: 'WEDDING',
    WeddingSilver: 'WEDDING_SILVER',
    WeddingGold: 'WEDDING_GOLD',
    PassedExam: 'PASSED_EXAM',
    CompanyAnniversary: 'COMPANY_ANNIVERSARY',
    Achievement: 'ACHIEVEMENT',
    WorkPerformance: 'WORK_PERFORMANCE',
    Commitment: 'COMMITMENT',
    Proposal: 'PROPOSAL',
    Recruitment: 'RECRUITMENT',
    Attendance: 'ATTENDANCE',
    SigningBonus: 'SIGNING_BONUS',
    ProfitSharing: 'PROFIT_SHARING',
    Other: 'OTHER'
} as const;

export type CouponsV2TokenOrderDTOGiftOccasionEnum = typeof CouponsV2TokenOrderDTOGiftOccasionEnum[keyof typeof CouponsV2TokenOrderDTOGiftOccasionEnum];
export const CouponsV2TokenOrderDTOGiftTypeEnum = {
    Gift: 'GIFT',
    Incentive: 'INCENTIVE'
} as const;

export type CouponsV2TokenOrderDTOGiftTypeEnum = typeof CouponsV2TokenOrderDTOGiftTypeEnum[keyof typeof CouponsV2TokenOrderDTOGiftTypeEnum];


