import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useMemo } from 'react';
import BenefitPayrollReportPage from '../benefit/BenefitPayrollReportPage';

const CouponPayrollReportPage: React.FC = () => {
  const { tenant } = useTenant();

  const reportBenefits = useMemo(
    () =>
      tenant?.activeBenefits?.filter(benefit => benefit === BenefitDTOBenefitEnum.Coupons || benefit === BenefitDTOBenefitEnum.Gifts) || [
        BenefitDTOBenefitEnum.Coupons,
      ],
    [tenant],
  );

  return <BenefitPayrollReportPage benefit={reportBenefits[0]} reportBenefits={reportBenefits} />;
};

export default CouponPayrollReportPage;
