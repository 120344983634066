import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { BenefitButton } from './BenefitButton';
import { CustomBenefitButton } from './CustomBenefitButton';
import { BenefitButtonRenderer } from './NavBenefitList';

interface Props {
  renderer: BenefitButtonRenderer;
}

// match benefit url and subpages, but only if not followed by a uuid (for custom benefits)
const CUSTOM_BENEFIT_TARGET_URL = /^\/benefits\/custom\b(?!\/\w{8}-\w{4}-\w{4}-\w{4}-\w{12})/;

export const CustomBenefitList: React.FC<Props> = ({ renderer }) => {
  const { tenant } = useTenant();
  const location = useLocation();

  const { data: customBenefits } = useTenantQuery(
    ['benefits', 'custom', apis.customBenefits.listCustomBenefits],
    tenantId =>
      apis.customBenefits
        .listCustomBenefits({
          tenantId,
        })
        .then(res => res.data),
    {
      staleTime: 60000,
      enabled: tenant?.activeBenefits?.includes(BenefitDTOBenefitEnum.Custom),
    },
  );

  if (!tenant?.activeBenefits?.includes(BenefitDTOBenefitEnum.Custom)) {
    return <></>;
  }

  return (
    <>
      {(customBenefits?.results || []).map(customBenefit => {
        const targetUrl = `/benefits/custom/${customBenefit.id}`;

        return (
          <CustomBenefitButton
            renderer={renderer}
            selected={location.pathname.startsWith(targetUrl)}
            key={customBenefit.id}
            customBenefit={customBenefit}
          />
        );
      })}
      <BenefitButton
        renderer={renderer}
        selected={CUSTOM_BENEFIT_TARGET_URL.test(location.pathname)}
        benefit={BenefitDTOBenefitEnum.Custom}
        active
      />
    </>
  );
};
