import { useCallback } from 'react';
import { NavigateOptions, To, useLocation, useNavigate } from 'react-router-dom';

/**
 * In addition to the standard navigate(), tracks the origin path in the 'prevPath' state field
 */
export function useNavigateWithPrevPath(): (to: To, options?: NavigateOptions) => void {
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(
    (to: To, options?: NavigateOptions) => {
      navigate(to, { ...options, state: { prevPath: location.pathname, ...options?.state } });
    },
    [location.pathname, navigate],
  );
}
