import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import useDebounce from 'probonio-shared-ui/utils/useDebounce';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { BenefitFilter } from '../../userManagement/BenefitSelector';
import { EmployeeApiListEmployeesRequest, FilterEmployeesDTO } from 'probonio-shared-ui/api';
import { useStore } from 'react-redux';

export interface EmployeeFilter {
  searchText?: string;
  department?: string;
  costCenter?: string;
  benefitFilter?: BenefitFilter;
  activationState?: 'ACTIVE' | 'INACTIVE';
  showActiveUser?: EmployeeApiListEmployeesRequest['showActiveUser'];
}

export interface EmployeeFilterState {
  filter: EmployeeFilter;
  debouncedFilter: EmployeeFilter;
  setFilter: Dispatch<SetStateAction<EmployeeFilter>>;
}

export function useEmployeeFilterState(initialFilter?: EmployeeFilter): EmployeeFilterState {
  const [filter, setFilter] = useState<EmployeeFilter>(initialFilter || {});
  const searchTextDebounced = useDebounce(filter.searchText);

  return useMemo(
    () => ({
      filter,
      debouncedFilter: { ...filter, searchText: searchTextDebounced },
      setFilter,
    }),
    [filter, searchTextDebounced],
  );
}

/**
 * instead of using an internal state, connects to the global state in Redux
 */
export function useReduxEmployeeFilterState(
  selector: (state: RootState) => EmployeeFilter,
  action: ActionCreatorWithPayload<EmployeeFilter>,
): EmployeeFilterState {
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selector);
  const { getState } = useStore<RootState>();
  const searchTextDebounced = useDebounce(filter.searchText);

  const setFilter = useCallback<Dispatch<SetStateAction<EmployeeFilter>>>(
    valueOrSetter => {
      const currentFilter = selector(getState());
      const newValue = typeof valueOrSetter === 'function' ? valueOrSetter(currentFilter) : valueOrSetter;
      dispatch(action(newValue));
    },
    [action, dispatch, getState, selector],
  );

  return useMemo(
    (): EmployeeFilterState => ({
      filter,
      debouncedFilter: { ...filter, searchText: searchTextDebounced },
      setFilter,
    }),
    [filter, searchTextDebounced, setFilter],
  );
}

export function mapEmployeeFilterToRequest(employeeFilter?: EmployeeFilter): FilterEmployeesDTO {
  return {
    filter: employeeFilter?.searchText,
    activeBenefit: employeeFilter?.benefitFilter?.benefit,
    activeCustomBenefitId: employeeFilter?.benefitFilter?.customBenefitId,
    department: employeeFilter?.department,
    costCenter: employeeFilter?.costCenter,
    showActiveUser: employeeFilter?.showActiveUser,
  };
}
