import { Box, Card, CardActionArea, CardHeader, Chip, Stack, Typography } from '@mui/material';
import {
  BenefitDTOBenefitEnum,
  CouponGiftDTO,
  CouponGiftDTOStatusEnum,
  CouponsV2GiftDTO,
  CouponsV2GiftDTOStatusEnum,
} from 'probonio-shared-ui/api';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BENEFIT_ICONS } from '../../../../benefits/BenefitIcon';

const GiftCardChips: React.FC<{ gift: CouponsV2GiftDTO | CouponGiftDTO }> = ({ gift }) => {
  const { t } = useTranslation('benefitsModule');
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" spacing={1}>
        <Chip
          size="small"
          label={
            <Box display="inline-flex" alignItems="center" gap="0.33em">
              <span>{t('userGiftsPage.amount')}:</span>
              <span>{t('common:money', { money: gift.amount })}</span>
            </Box>
          }
        />

        {gift.recurring && (
          <Chip
            size="small"
            data-test-id="gift-card-chips-recurring"
            label={
              <Box display="inline-flex" alignItems="center" gap="0.33em">
                <span>{t('userGiftsPage.recurring')}</span>
              </Box>
            }
          />
        )}
        <Chip
          size="small"
          label={
            <Box display="inline-flex" alignItems="center" gap="0.33em">
              <span>{t(`couponsModule:giftOccasions.${gift.occasion}`)}</span>
            </Box>
          }
        />
      </Stack>
    </Stack>
  );
};

interface V2Props {
  gift: CouponsV2GiftDTO;
  onClick: (v2Gift: CouponsV2GiftDTO) => void;
  actions?: React.ReactNode;
}

export const V2GiftCard: React.FC<V2Props> = ({ gift, actions, onClick }) => {
  const { t } = useTranslation('couponsV2Module');
  const handleClick = useCallback(() => {
    onClick(gift);
  }, [gift, onClick]);

  const Icon = BENEFIT_ICONS[BenefitDTOBenefitEnum.Gifts];
  return (
    <Card key={gift.id} variant="outlined" data-test-id={`gift-card-COUPONS_V2${gift.giftType}`}>
      <CardActionArea component="span" onClick={handleClick}>
        <CardHeader
          sx={{ py: 1 }}
          avatar={<Icon color="primary" fontSize="large" />}
          title={
            <Typography color="primary.main" mb={0.5} data-test-id={`gift-card-COUPONS_V2${gift.giftType}-title`}>
              {t(`gifts.giftType.${gift.giftType}`)}
            </Typography>
          }
          subheader={<GiftCardChips gift={gift} />}
          action={
            <>
              {actions}
              <Stack
                direction="row"
                spacing={1}
                marginTop="-3px"
                data-test-id={`gift-card-chips-${CouponsV2GiftDTOStatusEnum.InvoiceCreated}`}
              >
                {gift.status === CouponsV2GiftDTOStatusEnum.InvoiceCreated && (
                  <Chip color="warning" size="small" label={`${t(`gifts.status.${CouponsV2GiftDTOStatusEnum.InvoiceCreated}`)}`} />
                )}
                <Chip
                  size="small"
                  color="info"
                  label={`${t('benefitsModule:userGiftsPage.payoutDate')}: ${t('common:date', { date: new Date(gift.payoutDate) })}`}
                />
              </Stack>
            </>
          }
        />
      </CardActionArea>
    </Card>
  );
};

interface Props {
  gift: CouponGiftDTO;
  onClick: (gift: CouponGiftDTO) => void;
  actions?: React.ReactNode;
}

export const GiftCard: React.FC<Props> = ({ gift, actions, onClick }) => {
  const { t } = useTranslation('benefitsModule');
  const handleClick = useCallback(() => {
    onClick(gift);
  }, [gift, onClick]);

  const Icon = BENEFIT_ICONS[BenefitDTOBenefitEnum.Gifts];
  return (
    <Card key={gift.id} variant="outlined" data-test-id="benefit-card-GIFTS">
      <CardActionArea component="span" onClick={handleClick}>
        <CardHeader
          sx={{ py: 1 }}
          avatar={<Icon color="primary" fontSize="large" />}
          title={
            <Typography color="primary.main" mb={0.5}>
              {t(`GIFTS.title`)}
            </Typography>
          }
          action={
            <>
              {actions}
              <Stack direction="row" spacing={1} marginTop="-3px">
                {gift.status === CouponGiftDTOStatusEnum.PaymentFailed && (
                  <Chip color="error" size="small" label={`${t(`GIFTS.paymentFailed`)}`} />
                )}
                <Chip
                  size="small"
                  color="info"
                  label={`${t('benefitsModule:userGiftsPage.payoutDate')}: ${t('common:date', { date: new Date(gift.payoutDate) })}`}
                />
              </Stack>
            </>
          }
          subheader={<GiftCardChips gift={gift} />}
        />
      </CardActionArea>
    </Card>
  );
};
