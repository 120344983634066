/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface TenantSupportMailDTO
 */
export interface TenantSupportMailDTO {
    /**
     * 
     * @type {string}
     * @memberof TenantSupportMailDTO
     */
    'mailType': TenantSupportMailDTOMailTypeEnum;
}

export const TenantSupportMailDTOMailTypeEnum = {
    CorporateFitness: 'corporate-fitness'
} as const;

export type TenantSupportMailDTOMailTypeEnum = typeof TenantSupportMailDTOMailTypeEnum[keyof typeof TenantSupportMailDTOMailTypeEnum];


