import { Alert, Box } from '@mui/material';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CheckboxControl } from '../../../../component/form/CheckboxControl';
import { IncreaseBudgetValues } from './CouponsIncreaseBudgetDialog';

export const AutoBudgetContent: React.FC = () => {
  const { t } = useTranslation('couponsModule');
  const { control, unregister } = useFormContext<IncreaseBudgetValues>();
  const { tenant } = useTenant();

  useEffect(() => {
    // Unregister values, which got registered while mounting
    // the form bellow, to remove the form validation for these fields on tab change.
    return () => {
      unregister('autoBudget');
    };
  }, [unregister]);

  return (
    <>
      <Box marginTop={-1}>
        <CheckboxControl
          control={control}
          defaultValue={!!tenant?.enableAutomatedBudgetOrders}
          name="autoBudget"
          label={t('budgetPanelIncreaseBudgetDialog.tabs.automatic.enableAutomaticRefill')}
          helperText={t('budgetPanelIncreaseBudgetDialog.tabs.automatic.infoText')}
        />
      </Box>
      {!tenant?.sepa && (
        <Box marginTop={1}>
          <Alert className="margin-top-1" severity="warning">
            <Trans i18nKey="couponsModule:budgetPanelIncreaseBudgetDialog.tabs.automatic.warnText">
              <Link to="/settings" />
            </Trans>
          </Alert>
        </Box>
      )}
    </>
  );
};
