import AddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';
import { Button, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BasicDialog, useNewDialogState } from '../../../component/dialog';
import { useBikeleasingPrecondition } from './useBikeleasingPrecondition';
import { useBikeleasingLatestSyncedCondition, useBikeleasingTenantSyncMutation } from './useBikeleasingSync';

interface Props {
  isLoading: boolean;
  onClick: () => void;
  lastSyncedAt?: string;
}

const SyncButton: React.FC<Props> = ({ isLoading, onClick, lastSyncedAt }: Props) => {
  const { t } = useTranslation('benefitsModule');
  const DISABLED_MINUTES_THRESHOLD = 1;
  const REFRESH_TIMER = 1000;
  const [isSyncDisabled, setIsSyncDisabled] = useState<boolean>(false);

  useEffect(() => {
    const checkSyncStatus = () => {
      const currentTime = DateTime.now();
      const lastSyncedTime = lastSyncedAt ? DateTime.fromISO(lastSyncedAt) : undefined;
      const isDisabled = lastSyncedTime && currentTime.diff(lastSyncedTime, 'minutes').minutes < DISABLED_MINUTES_THRESHOLD;

      setIsSyncDisabled(!!isDisabled);
    };

    checkSyncStatus();

    const intervalId = setInterval(checkSyncStatus, REFRESH_TIMER);

    return () => clearInterval(intervalId);
  }, [lastSyncedAt]);

  const toolTipTitle = isSyncDisabled
    ? t('BIKELEASING.syncNotAllowedToolTip', {
        date: DateTime.fromISO(lastSyncedAt!).plus({ minutes: DISABLED_MINUTES_THRESHOLD }).toJSDate(),
      })
    : undefined;

  return (
    <Tooltip title={toolTipTitle}>
      <span>
        <LoadingButton
          dataTestId="bikeleasing-sync-now"
          fullWidth
          color="secondary"
          loading={isLoading}
          onClick={onClick}
          disabled={isSyncDisabled}
          startIcon={<CachedIcon />}
        >
          {t('BIKELEASING.syncAll')}
        </LoadingButton>
      </span>
    </Tooltip>
  );
};

export const AddBikeleasingConditionMenu: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const theme = useTheme();
  const { dialogState, handleOpen } = useNewDialogState();
  const { PreconditionComponent, isPreconditionMet } = useBikeleasingPrecondition(dialogState.handleClose);
  const sync = useBikeleasingTenantSyncMutation();
  const latestCondition = useBikeleasingLatestSyncedCondition();

  return isPreconditionMet ? (
    <Stack direction="row" spacing={1}>
      <BasicDialog maxWidth="md" title={t('BIKELEASING.addCondition')} dialogState={dialogState} content={PreconditionComponent} />
      <Stack spacing={1}>
        <SyncButton isLoading={sync.isLoading} onClick={sync.syncBikeleasingTenant} lastSyncedAt={latestCondition?.lastSyncedAt} />
        {latestCondition && latestCondition.lastSyncedAt && (
          <Typography color={latestCondition.syncError ? theme.palette.warning.light : 'text.secondary'} variant="body2">
            <Trans i18nKey="benefitsModule:BIKELEASING.lastSync" values={{ date: new Date(latestCondition.lastSyncedAt) }} />
          </Typography>
        )}
      </Stack>
      <Button startIcon={<AddIcon />} onClick={handleOpen}>
        {t('BIKELEASING.addCondition')}
      </Button>
    </Stack>
  ) : null;
};
