import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useParams } from 'react-router-dom';
import BenefitPayrollReportPage from './BenefitPayrollReportPage';

export const ParamsBenefitPayrollReportPage: React.FC = () => {
  const { benefit: benefitName } = useParams<{ benefit: string }>();
  const reportBenefit: BenefitDTOBenefitEnum = benefitName!.toUpperCase() as BenefitDTOBenefitEnum;

  return <BenefitPayrollReportPage benefit={reportBenefit} />;
};
