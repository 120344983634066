import CreateIcon from '@mui/icons-material/Create';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BenefitPaper } from '../../component/benefitTabs/BenefitPaper';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { useEmployeeFilterState } from '../../module/benefits/employeeStatusList/employeeFilterState';
import { NewsArticlesCreator } from '../../module/benefits/news/NewsArticleCreator';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';
import { ArticlesCreatorWrapper } from './ArticlesCreatorWrapper';
import { ReadConfirmationDiagram } from './ReadConfirmationDiagram';
import { useReadConfirmationReport, useUnreadConfirmationReport } from './useReadConfirmationReport';

const NewsAddArticlesPage: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { articleId } = useParams();
  const unreadEmployeeCount = useUnreadConfirmationReport(articleId!);
  const readEmployeeCount = useReadConfirmationReport(articleId!);
  const filterState = useEmployeeFilterState();
  const { data, isLoading } = useTenantQuery(['benefits', 'news', 'articles', articleId, { withImage: true }], async tenantId => {
    if (articleId) {
      return apis.news.getArticle({ tenantId, articleId, withImage: true });
    } else {
      return null;
    }
  });

  const totalCount =
    unreadEmployeeCount.unreadEmployeeList?.totalCount && readEmployeeCount.readEmployeeList?.totalCount
      ? readEmployeeCount.readEmployeeList?.totalCount + unreadEmployeeCount.unreadEmployeeList?.totalCount
      : readEmployeeCount.readEmployeeList?.totalCount || unreadEmployeeCount.unreadEmployeeList?.totalCount;

  return (
    <AuthorizedLayout title={t('NEWS.articles')} breadcrumbs={[{ title: t('NEWS.title'), to: '/benefits/news' }]}>
      <BenefitSubPage
        navigateBackTo="/benefits/news"
        showBack
        icon={CreateIcon}
        title={!articleId ? t('NEWS.articleTitle.createTitle') : t('NEWS.articleTitle.modifyTitle')}
        subTitle={!articleId ? t('NEWS.articleSubTitle.createSubTitle') : t('NEWS.articleSubTitle.modifySubTitle')}
        menu={
          articleId &&
          !isLoading &&
          data?.data.readConfirmation &&
          (data.data.articleStatus === 'PUBLISHED' || data.data.articleStatus === 'EXPIRED') && (
            <ReadConfirmationDiagram confirmedCount={readEmployeeCount.readEmployeeList?.totalCount} confirmedMax={totalCount} />
          )
        }
      >
        {articleId && data?.data ? (
          <ArticlesCreatorWrapper article={data?.data} filterState={filterState} />
        ) : (
          <BenefitPaper>
            <NewsArticlesCreator />
          </BenefitPaper>
        )}
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};

export default NewsAddArticlesPage;
