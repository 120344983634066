import { CircularProgress } from '@mui/material';
import { BenefitDTOBenefitEnum, CustomBenefitDTO } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { createContext, useContext, useMemo } from 'react';

export interface BenefitContextValue {
  benefit: BenefitDTOBenefitEnum;
  customBenefitId?: string;
  customBenefit?: CustomBenefitDTO;
}

interface Props {
  benefit: BenefitDTOBenefitEnum;
  customBenefitId?: string;
  showLoadingIndicator?: boolean;
}

const BenefitContext = createContext<BenefitContextValue | undefined>(undefined);

export const BenefitProvider: React.FC<React.PropsWithChildren<Props>> = ({
  benefit,
  customBenefitId,
  showLoadingIndicator = true,
  children,
}) => {
  const { data: customBenefit } = useTenantQuery(
    ['benefits', 'custom', customBenefitId, apis.customBenefits.getCustomBenefit],
    tenantId => apis.customBenefits.getCustomBenefit({ tenantId, customBenefitId: customBenefitId! }).then(res => res.data),
    {
      enabled: benefit === BenefitDTOBenefitEnum.Custom && !!customBenefitId,
    },
  );

  const contextValue = useMemo(
    (): BenefitContextValue => ({
      benefit,
      customBenefitId,
      customBenefit,
    }),
    [benefit, customBenefit, customBenefitId],
  );

  if (benefit === BenefitDTOBenefitEnum.Custom && !customBenefit) {
    return showLoadingIndicator ? <CircularProgress /> : null;
  }

  return <BenefitContext.Provider value={contextValue}>{children}</BenefitContext.Provider>;
};

export function useBenefitContext(): BenefitContextValue {
  const ctx = useContext(BenefitContext);
  // Throw error that the Context is not injected, if it is undefined.
  if (ctx === undefined) {
    throw new Error('useBenefitContext must be used within a BenefitProvider');
  }
  return ctx;
}
