import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { BikeApiListTenantContractsRequest, BikeContractListDTO } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';

export function useBikeContracts(
  parameters?: Partial<BikeApiListTenantContractsRequest>,
  options?: { enabled?: boolean; staleTime?: number },
): UseQueryResult<BikeContractListDTO, Error> {
  return useTenantQuery(
    ['benefits', 'bike', 'contracts', parameters, apis.bike.listTenantContracts.name],
    tenantId => apis.bike.listTenantContracts({ tenantId, ...parameters }).then(res => res.data),
    {
      ...options,
    },
  );
}

const PAGE_SIZE = 15;

export function useInfiniteBikeContracts(
  parameters?: Partial<BikeApiListTenantContractsRequest>,
  options?: { enabled?: boolean },
): UseInfiniteQueryResult<InfiniteData<BikeContractListDTO, Error>> {
  const getTenantId = useTenantID();

  return useInfiniteQuery({
    queryKey: ['tenants', getTenantId(), 'benefits', 'bike', 'contracts', parameters, apis.bike.listTenantContracts.name],
    queryFn: ({ pageParam = 0 }) =>
      apis.bike.listTenantContracts({ tenantId: getTenantId(), page: pageParam, pageSize: PAGE_SIZE, ...parameters }).then(res => res.data),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => (pages.length * PAGE_SIZE < lastPage.totalCount ? pages.length : undefined),
    placeholderData: previousData => previousData,
    ...options,
  });
}

export function useInvalidateBikeContracts(): () => Promise<void> {
  const getTenantId = useTenantID();
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries({ queryKey: ['tenants', getTenantId(), 'benefits', 'bike', 'contracts'] });
}
