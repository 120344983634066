import { useTranslation } from 'react-i18next';
import { BenefitPaper } from '../../component/benefitTabs/BenefitPaper';
import { BENEFIT_ICONS } from '../../module/benefits/BenefitIcon';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { CouponBenefitSettingsPanel } from '../../module/benefits/coupons/CouponBenefitSettingsPanel';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';

export const CouponSettingsPage: React.FC = () => {
  const { t } = useTranslation('couponsModule');

  return (
    <AuthorizedLayout
      title={t('benefitsModule:subPage.settings')}
      breadcrumbs={[{ title: t('benefitsModule:COUPONS.title'), to: '/benefits/coupons' }]}
    >
      <BenefitSubPage
        icon={BENEFIT_ICONS.COUPONS}
        title={t('benefitsModule:COUPONS.title')}
        subTitle={t('benefitsModule:subPage.settings')}
      >
        <BenefitPaper>
          <CouponBenefitSettingsPanel />
        </BenefitPaper>
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};
