import { ScriptableContext } from 'chart.js';

function getGradient(ctx: CanvasRenderingContext2D, startY: number, endY: number, startColor: string, endColor: string): CanvasGradient {
  const gradient = ctx.createLinearGradient(0, startY, 0, endY);
  gradient.addColorStop(0, startColor);
  gradient.addColorStop(1, endColor);

  return gradient;
}

/**
 * creates a linear gradient starting with startColor at the highest point of the given dataset,
 * and ending at (startColor, alpha 0.4) at the lowest point of the dataset
 */
export function gradientFill(dataIndex: number, startColor: string): (context: ScriptableContext<'line'>) => CanvasGradient | undefined {
  return context => {
    const chart = context.chart;
    const dataSumsBottom = chart.data.datasets
      .filter((ds, index) => chart.isDatasetVisible(index) && index < dataIndex)
      .reduce((sumArray, ds) => (ds.data as number[]).map((value, index) => value + (sumArray[index] || 0)), [] as number[]);
    const dataSumsTop = (chart.data.datasets[dataIndex].data as number[]).map((value, index) => value + (dataSumsBottom[index] || 0));
    const { ctx, chartArea } = chart;
    if (!chartArea) {
      // This case happens on initial chart load
      return;
    }
    const minValue = Math.min(...dataSumsBottom, 0);
    const maxValue = Math.max(...dataSumsTop);
    const startY = chart.scales.y.getPixelForValue(minValue);
    const endY = chart.scales.y.getPixelForValue(maxValue);

    return getGradient(ctx, startY, endY, startColor.replace(/1\)/, '0.4)'), startColor);
  };
}

// Probonio CI primary color range
export const CHART_COLORS = [
  'hsla(202,94%,13%,1)',
  'hsla(203,100%,23%,1)',
  'hsla(203,85%,31%,1)',
  'hsla(203,72%,41%,1)',
  'hsla(202,59%,52%,1)',
  'hsla(203,57%,63%,1)',
  'hsla(203,70%,75%,1)',
  'hsla(202,70%,83%,1)',
  'hsla(202,70%,95%,1)',
];
