import { KeyboardEvent } from 'react';
import { Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { CustomEditor, Placeholder } from './PlaceholderInput.types';

export const replaceEnterWithNewLine = (editor: CustomEditor, e: KeyboardEvent<HTMLDivElement>): void => {
  if (e.key === 'Enter') {
    e.preventDefault();
    editor.insertFragment([{ type: 'span', children: [{ text: '\n' }] }]);
  }
};

export const insertPlaceholderChip =
  (editor: CustomEditor, value: string): (() => void) =>
  () => {
    Transforms.insertFragment(editor, [{ type: 'placeholder', children: [{ text: `{{${value}}}` }] }]);
    ReactEditor.focus(editor);
    Transforms.move(editor);
  };

export const getChipLabel = (value: string, placeholders: Placeholder[]): string => {
  const placeholder = placeholders.find(v => `{{${v.value}}}` === value);
  if (!placeholder) {
    return value;
  }
  return placeholder.label;
};

export const replacePlaceholdersWithChips = (editor: CustomEditor, initialValue: string): void => {
  // splits at Placeholder which always starts with {{
  initialValue.split(/({{.*?}})/).forEach((v, i) => {
    const isPlaceholder = v.startsWith('{{') && v.endsWith('}}');
    if (isPlaceholder) {
      // inserts CustomChip
      Transforms.insertFragment(editor, [{ type: 'placeholder', children: [{ text: v }] }], { at: Editor.end(editor, []) });

      return;
    }

    if (i === 0) {
      // inserts Text and removes everything that was in the TextField before
      Transforms.insertText(editor, v, { at: Editor.path(editor, []) });
      return;
    }

    Transforms.insertText(editor, v, { at: Editor.end(editor, []) });
  });
};

export const withPlaceholder = (editor: CustomEditor): CustomEditor => {
  const { isVoid, isInline } = editor;

  editor.isInline = e => {
    return e.type === 'placeholder' ? true : isInline(e);
  };

  editor.isVoid = e => {
    return e.type === 'placeholder' ? true : isVoid(e);
  };

  return editor;
};
