import { Box } from '@mui/material';
import { MRT_SortingState } from 'material-react-table';
import { CouponsV2TokenOrderDTO, CouponsV2TokenOrderDTOGiftTypeEnum, CouponsV2TokenOrderDTOStatusEnum } from 'probonio-shared-ui/api';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfiniteScrollReactTable } from '../../../../component/table/InfiniteScrollReactTable';
import { mapSortModel } from '../../../../component/table/sortMapper';
import { useLoadMore } from '../../../../component/table/useLoadMore';
import { AggregationContextProvider, AggregationsModel } from '../../coupons/aggregationContext';
import { useTokenOrderColumns } from './tokenOrderColumns';
import { useInfiniteTokenOrderHistory } from './useInfiniteTokenOrderHistory';

export type TokenOrderType = 'BENEFIT_ACTIVATION' | CouponsV2TokenOrderDTOGiftTypeEnum;

interface Props {
  filter?: string;
  orderType?: TokenOrderType;
  orderStatus?: CouponsV2TokenOrderDTOStatusEnum;
}

const OrdersTableComp: React.FC<Props> = ({ filter, orderType, orderStatus }) => {
  const { t } = useTranslation('couponsV2Module');
  const [sortModel, setSortModel] = useState<MRT_SortingState>([{ id: 'deliveryDate', desc: true }]);
  const isSortedByDate = sortModel.length === 0 || sortModel[0].id === 'deliveryDate';
  const columns = useTokenOrderColumns();
  const filteredColumns = columns.filter(col => isSortedByDate || col.id !== 'month');

  const mappedSortModel = mapSortModel(columns, sortModel);

  const queryResult = useInfiniteTokenOrderHistory(pageParam => ({
    sortBy: mappedSortModel,
    filter,
    withAggregations: pageParam === 0 && isSortedByDate,
    orderReason: orderType === 'BENEFIT_ACTIVATION' ? 'BENEFIT_ACTIVATION' : undefined,
    giftType: orderType && orderType !== 'BENEFIT_ACTIVATION' ? orderType : undefined,
    status:
      orderStatus &&
      (orderStatus === CouponsV2TokenOrderDTOStatusEnum.PaymentFailed
        ? [CouponsV2TokenOrderDTOStatusEnum.PaymentFailed, CouponsV2TokenOrderDTOStatusEnum.Cancelled]
        : [orderStatus]),
  }));

  const loadMoreProps = useLoadMore(queryResult, 'results');

  return (
    <Box>
      <AggregationContextProvider
        value={(queryResult.data?.pages[0].aggregations || { amountByMonth: [] }) as unknown as AggregationsModel}
      >
        <InfiniteScrollReactTable<CouponsV2TokenOrderDTO>
          {...loadMoreProps}
          columns={filteredColumns}
          enablePagination={false}
          enableTopToolbar={false}
          enableBottomToolbar={false}
          enableColumnActions={false}
          enableSortingRemoval={false}
          localization={{ noRecordsToDisplay: t('ordersTable.noResults') }}
          manualSorting
          onSortingChange={setSortModel}
          state={{ sorting: sortModel, grouping: isSortedByDate ? ['month'] : [], expanded: true }}
          groupedColumnMode="remove"
        />
      </AggregationContextProvider>
    </Box>
  );
};

export const OrdersTable = React.memo(OrdersTableComp);
