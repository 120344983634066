import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BenefitPaper } from '../../../component/benefitTabs/BenefitPaper';

export const CustomBenefitPanel: React.FC = () => {
  const { t } = useTranslation('customBenefitsModule');
  return (
    <BenefitPaper sx={{ width: '600px' }}>
      <Typography color="text.secondary">{t('topPanel.description')}</Typography>
    </BenefitPaper>
  );
};
