import { IconButton, IconButtonProps } from '@mui/material';

export const IconToggleButton: React.FC<IconButtonProps & { active?: boolean }> = ({ active, ...props }) => {
  return (
    <IconButton
      {...props}
      sx={{
        ...props.sx,
        ...(active
          ? { backgroundColor: 'secondary.main', color: 'secondary.contrastText', '&:hover': { backgroundColor: 'secondary.dark' } }
          : {}),
      }}
    />
  );
};
