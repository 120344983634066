import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Button, ButtonProps, styled } from '@mui/material';
import { fontSizes, fontWeights } from '../../theme';

const StyledBenefitButton = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(0.75),
  textAlign: 'left',
  textTransform: 'none',
  display: 'flex',
  boxShadow: `0px ${theme.spacing(0.1)} ${theme.palette.grey.A200}`,
  alignItems: 'center',
  border: '1px solid',
  borderColor: theme.palette.grey.A200,

  '& .icon': {
    marginRight: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    lineHeight: 0,
  },
  '& .header': {
    fontSize: fontSizes.md,
    fontWeight: fontWeights.semiBold,
  },
  '& .subtitle': {
    fontSize: theme.typography.body2.fontSize,
  },
  '& .arrow': {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    lineHeight: 0,
  },
  '& .statusIcon': {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    lineHeight: 0,
  },
}));
interface Props {
  icon: React.ReactNode;
  dataTestId: string;
  header?: React.ReactNode;
  content?: React.ReactNode;
  additionalIcons?: React.ReactNode;
  isConfigured?: boolean;
}

export const BenefitButtonWithChevron: React.FC<Props & Omit<ButtonProps, 'content'>> = ({
  icon,
  header,
  content,
  additionalIcons,
  isConfigured,
  dataTestId,
  ...props
}) => {
  return (
    <StyledBenefitButton data-test-id={dataTestId} {...props}>
      <Box display="flex" className="icon" flexDirection="row">
        {icon}
        {isConfigured && <CheckCircleOutlineIcon color="success" fontSize="large" sx={{ position: 'absolute', top: 20, right: 10 }} />}
      </Box>
      <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
        {header}
        {content}
      </Box>
      <Box className="arrow">
        {additionalIcons}
        <ChevronRightIcon fontSize="large" sx={{ color: 'text.secondary', marginX: '-0.33em' }} />
      </Box>
    </StyledBenefitButton>
  );
};
