import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EmployeeDTO, EmployeeDTOStatusEnum, UserDTOStatusEnum } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewDialogState } from '../../component/dialog';
import DeleteUserDialog from './DeleteUserDialog';
import { useRefetchEmployees } from './query';
import { renderUserName } from './userFormatter';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';

interface Props {
  employee: EmployeeDTO;
}

export const DeleteEmployeeButton: React.FC<Props> = ({ employee }) => {
  const { t } = useTranslation('usersModule');
  const withMessage = useWithMessage();
  const queryClient = useQueryClient();
  const refetchEmployees = useRefetchEmployees();
  const getTenantID = useTenantID();
  const confirmDeleteDialog = useNewDialogState();
  const { enqueueSnackbar } = useSnackbar();

  const navigateToUsersAndReload = useCallback(async () => {
    await refetchEmployees();
    window.history.go(-2);
  }, [refetchEmployees]);

  const deleteUserMutation = useMutation({
    mutationFn: async () => {
      await apis.employee.deleteEmployee({
        tenantId: getTenantID(),
        employeeId: employee.id,
      });
    },

    onSuccess: async () => {
      queryClient.removeQueries({ queryKey: ['tenants', getTenantID(), 'employees', employee.id] });
      await navigateToUsersAndReload();
    },
    onError: (err: Error) => {
      if ((err as AxiosError).response?.status === 422) {
        enqueueSnackbar(t('deletionError'), { variant: 'error' });
      } else {
        withMessage(err);
      }
    },
  });

  return (
    <>
      <Button
        data-test-id="delete-user"
        size="small"
        disabled={
          employee.status === EmployeeDTOStatusEnum.Active &&
          employee.user?.status !== UserDTOStatusEnum.Pending &&
          employee.user?.status !== UserDTOStatusEnum.Created
        }
        onClick={confirmDeleteDialog.handleOpen}
        color="error"
        variant="outlined"
        startIcon={<DeleteIcon />}
      >
        {t('userForm.deleteUser.button')}
      </Button>
      <DeleteUserDialog
        userName={renderUserName(employee.user, employee.employeeNumber)}
        dialogState={confirmDeleteDialog.dialogState}
        onDelete={deleteUserMutation.mutate}
        loading={deleteUserMutation.isPending}
      />
    </>
  );
};
