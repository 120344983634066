import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BenefitPaper } from '../../../component/benefitTabs/BenefitPaper';
import { useNewDialogState } from '../../../component/dialog';
import { BikeImportDialog } from './BikeImportDialog';

export const BikeBenefitMenu: React.FC = () => {
  const { t } = useTranslation('bikeModule');
  const importDialog = useNewDialogState();

  return (
    <>
      <BenefitPaper size="sm" sx={{ display: 'flex', alignItems: 'center', px: 2.5, gap: 3 }}>
        <Typography width={200} flexGrow={1} variant="body2">
          {t('importButtonHint')}
        </Typography>
        <Button
          data-test-id="bike-import-button"
          size="small"
          variant="contained"
          color="primary"
          startIcon={<CloudUploadIcon />}
          onClick={importDialog.handleOpen}
        >
          {t('importButton')}
        </Button>
      </BenefitPaper>
      <BikeImportDialog dialogState={importDialog.dialogState} />
    </>
  );
};
