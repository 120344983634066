import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { MRT_ColumnDef } from 'material-react-table';
import { EmployeeDTO } from 'probonio-shared-ui/api';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SortByDef } from '../../../component/table/sortMapper';
import { AnonymousAvatar } from '../../userManagement/AnonymousAvatar';
import { UserNameAndAvatar } from '../../userManagement/UserNameAndAvatar';

const EmployeeBenefitStatus: React.FC<{ employee: EmployeeDTO }> = ({ employee }) => {
  const { t } = useTranslation('benefitsModule');

  let color: string, label;
  if (employee.benefitActivation) {
    const startDate = DateTime.fromISO(employee.benefitActivation.startDate);
    if (startDate > DateTime.now()) {
      color = 'warning.main';
      label = t('employeeTabs.status.activeFrom', { startDate });
    } else {
      color = 'success.main';
      label = t('employeeTabs.status.active', { startDate });
    }
  } else {
    color = 'text.primary';
    label = t('employeeTabs.status.inactive');
  }

  return (
    <Typography variant="inherit" component="span" color={color}>
      {label}
    </Typography>
  );
};

export function useEmployeeColumns(): (MRT_ColumnDef<EmployeeDTO> & SortByDef)[] {
  const { t } = useTranslation('benefitsModule');
  return useMemo<(MRT_ColumnDef<EmployeeDTO> & SortByDef)[]>(
    () => [
      {
        accessorFn: employee => (employee.user ? <UserNameAndAvatar user={employee.user} /> : <AnonymousAvatar />),
        id: 'name',
        header: t('usersModule:usersList.header.name'),
        sortBy: ['user.lastName', 'user.firstName', 'user.mail'],
      },
      {
        accessorKey: 'employeeNumber',
        header: t('usersModule:usersList.header.employeeNumber'),
      },
      {
        accessorKey: 'department',
        header: t('usersModule:usersList.header.department'),
      },

      {
        accessorFn: employee => <EmployeeBenefitStatus employee={employee} />,
        id: 'status',
        header: t('employeeTabs.header.status'),
        enableSorting: false,
      },
    ],
    [t],
  );
}
