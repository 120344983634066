import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BenefitPaper } from '../../component/benefitTabs/BenefitPaper';
import { HorizontalBox } from '../../component/layout/BoxLayout';
import { CircularProgressWithLabel } from '../../module/benefits';

export interface ConfirmationDiagramData {
  confirmedCount?: number;
  confirmedMax?: number;
  isLoading?: boolean;
}

export const ReadConfirmationDiagram: React.FC<ConfirmationDiagramData> = ({ confirmedCount, confirmedMax, isLoading }) => {
  const { t } = useTranslation('benefitsModule');

  const progressPercent = (100 * (confirmedCount || 0)) / (confirmedMax || 0);

  return (
    <BenefitPaper size="sm" sx={{ px: 2 }} data-test-id="read-confirmation-diagram">
      <HorizontalBox gap={2} alignItems="center" flexGrow={1}>
        <CircularProgressWithLabel value={progressPercent} loading={isLoading} />
        <Typography variant="h3">{t('NEWS.readConfirmationTab.confirmationStatus')}</Typography>
      </HorizontalBox>
    </BenefitPaper>
  );
};
