import { Box, useMediaQuery, useTheme } from '@mui/material';
import { FC, useEffect } from 'react';
import { BenefitsDashboardPanel } from '../module/dashboard/BenefitsDashboardPanel';
import { EmployeeListDashboardPanel } from '../module/dashboard/EmployeeListDashboardPanel';
import { AuthorizedLayout } from '../module/navigation/AuthorizedLayout';
import { DashboardContainer } from '../component/dashboard/DashboardContainer';
import { BenefitGrantChartDashboardPanel } from '../module/dashboard/BenefitGrantChartDashboardPanel';
import { EmployeeChartDashboardPanel } from '../module/dashboard/EmployeeChartDashboardPanel';
import { useNewDialogState } from '../component/dialog';
import InitialLoginInfoDialog from '../module/userManagement/InitialLoginInfoDialog/InitialLoginInfoDialog';
import { useAppSelector } from '../redux/hooks';
import UnsupportedBrowserBadge from '../component/unsupportedBrowserBadge/UnsupportedBrowserBadge';

const HomePage: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const user = useAppSelector(state => state.me.user);
  const showWelcomeMessage = useAppSelector(state => state.me.showWelcomeMessage);

  const { dialogState: infoDialogState, handleOpen: handleOpenInfoDialog } = useNewDialogState();

  useEffect(() => {
    if (user && showWelcomeMessage) {
      handleOpenInfoDialog();
    }
  }, [handleOpenInfoDialog, showWelcomeMessage, user]);

  return (
    <AuthorizedLayout>
      <Box marginTop={0.5}>
        <UnsupportedBrowserBadge />
        <DashboardContainer columns={isMobile ? 1 : 2} rowHeight={theme.spacing(1.5)} gap={1.5}>
          <EmployeeChartDashboardPanel rows={11} />
          <BenefitsDashboardPanel rows={13} />
          <EmployeeListDashboardPanel rows={17} />
          <BenefitGrantChartDashboardPanel rows={15} />
        </DashboardContainer>
      </Box>
      {user && <InitialLoginInfoDialog user={user} dialogState={infoDialogState} />}
    </AuthorizedLayout>
  );
};

export default HomePage;
