import { Alert, Autocomplete, AutocompleteRenderInputParams, Box, SelectChangeEvent, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import {
  CouponsV2GiftDTO,
  CouponsV2GiftDTOGiftTypeEnum,
  CouponsV2GiftDTOOccasionEnum,
  CouponsV2GiftDTOStatusEnum,
  UserDTOStatusEnum,
} from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useTenantID } from 'probonio-shared-ui/module/me';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BasicDialog, DialogState } from '../../../../component/dialog';
import { DialogLoadingButton } from '../../../../component/dialog/DialogLoadingButton';
import { DatePickerControl, SelectControl } from '../../../../component/form';
import { CheckboxControl } from '../../../../component/form/CheckboxControl';
import PlaceholderInput from '../../../../component/form/PlaceholderInput/PlaceholderInput';
import { Placeholder } from '../../../../component/form/PlaceholderInput/PlaceholderInput.types';
import { AvailableCouponsAccordion } from '../../coupons/AvailableCouponsDrawer';
import {
  GIFT_BUDGET_OPTIONS,
  GIFT_OCCASSION_OPTIONS,
  INCENTIVE_BUDGET_OPTIONS,
  INCENTIVE_OCCASSION_OPTIONS,
  MAX_YEARLY_INCENTIVE_AMOUNT,
  getNextBirthDay,
  giftYearlyAvailable,
} from '../../coupons/giftUtils';
import { useRefetchGiftOccasions } from '../../query';
import { useInvalidateCouponsV2Gifts } from '../couponsV2Queries';

export interface GiftFormValues {
  payoutDate: Date;
  occasion: CouponsV2GiftDTOOccasionEnum;
  description: string;
  amount: number;
  recurring: boolean;
  inviteWhenActive: boolean;
  sendMailWhenActive: boolean;
}

interface Props {
  dialogState: DialogState;
  employeeId: string;
  incentives: CouponsV2GiftDTO[];
  currentGift?: CouponsV2GiftDTO;
  birthDate?: string;
  giftType?: CouponsV2GiftDTOGiftTypeEnum;
  userStatus?: UserDTOStatusEnum;
}

export const GiftsV2Dialog: React.FC<Props> = ({ dialogState, employeeId, incentives, currentGift, birthDate, giftType, userStatus }) => {
  const { t, i18n } = useTranslation('couponsV2Module');
  const { control, watch, handleSubmit, getValues, reset, setValue } = useForm<GiftFormValues>();
  const [descriptionPrefill, setDescriptionPrefill] = useState('');
  const getTenantId = useTenantID();
  const withMessage = useWithMessage((err: Error) => ((err as AxiosError).response?.status === 409 ? t('conflictError') : undefined));
  const { enqueueSnackbar } = useSnackbar();
  const invalidateOccasions = useRefetchGiftOccasions();
  const invalidateGifts = useInvalidateCouponsV2Gifts(employeeId);

  const { data, isLoading } = useTenantQuery(['benefits', 'coupons', 'definition', apis.coupons.findCouponDefinitions.name], tenantId =>
    apis.coupons.findCouponDefinitions({ tenantId }).then(resp => resp.data),
  );
  const { data: occasions } = useTenantQuery(['benefits', 'coupons', 'giftOccasions'], tenantId =>
    apis.coupons.getGiftOccasions({ tenantId }).then(resp => resp.data),
  );
  const amount = watch('amount');
  const occasion = watch('occasion');
  const payoutDate = watch('payoutDate');

  const yearlyIncentivesAmount = incentives
    .filter(
      incentive => incentive.id !== currentGift?.id && DateTime.fromISO(incentive.payoutDate).year === DateTime.fromJSDate(payoutDate).year,
    )
    .reduce((acc, incentive) => acc + incentive.amount, 0);

  const getDefaultDescriptionForOccasion = useCallback(
    (occasion: CouponsV2GiftDTOOccasionEnum) => {
      const description = occasions?.giftOccasions
        .filter(giftOccasion => giftOccasion.occasion === occasion)
        .map(giftOccasion => giftOccasion.description)[0];

      return description ? description : t(`couponsModule:giftOccasions.description.${occasion}`);
    },
    [occasions?.giftOccasions, t],
  );

  const renderAmountInput = useCallback(
    (params: AutocompleteRenderInputParams) => <TextField {...params} margin="dense" label={t('gifts.fields.amount')} />,
    [t],
  );

  const getAmountOptionsLabel = useCallback((value: number) => i18n.format(value, 'euro-float'), [i18n]);

  const filterAmountOptions = useCallback((options: number[], { inputValue }: { inputValue: string }): number[] => {
    const filteredOptions = options.filter(option => `${option / 100}`.includes(inputValue));
    return filteredOptions;
  }, []);

  //set values for Form
  useEffect(() => {
    if (dialogState.isOpen) {
      const defaultOccasion =
        giftType === CouponsV2GiftDTOGiftTypeEnum.Gift ? CouponsV2GiftDTOOccasionEnum.Birthday : CouponsV2GiftDTOOccasionEnum.Commitment;

      const defaultAmount = giftType === CouponsV2GiftDTOGiftTypeEnum.Gift ? 6000 : 10000;
      const occasion = currentGift?.occasion || defaultOccasion;
      const payoutDate = currentGift?.payoutDate
        ? DateTime.fromISO(currentGift?.payoutDate).toJSDate()
        : DateTime.now().endOf('day').toJSDate();
      const newPayoutDate =
        occasion === CouponsV2GiftDTOOccasionEnum.Birthday && birthDate && !currentGift?.payoutDate
          ? getNextBirthDay(birthDate)?.toJSDate()
          : payoutDate;
      const description = currentGift?.description || getDefaultDescriptionForOccasion(occasion);
      reset({
        payoutDate: newPayoutDate,
        amount: currentGift?.amount || defaultAmount,
        occasion,
        description,
        recurring: currentGift?.recurring || false,
        inviteWhenActive: currentGift?.inviteWhenActive || false,
        sendMailWhenActive: currentGift?.sendMailWhenActive || false,
      });
      setDescriptionPrefill(description);
    }
  }, [currentGift, dialogState.isOpen, reset, t, birthDate, getDefaultDescriptionForOccasion, giftType]);

  //set description depending on occasion
  const handleChangeOccasion = useCallback(
    (event: SelectChangeEvent<CouponsV2GiftDTOOccasionEnum>) => {
      const description = getDefaultDescriptionForOccasion(event.target.value as CouponsV2GiftDTOOccasionEnum);
      const birthDay = getNextBirthDay(birthDate)?.toJSDate();
      setValue('description', description);
      setDescriptionPrefill(description);
      if (birthDay && event.target.value === CouponsV2GiftDTOOccasionEnum.Birthday) {
        setValue('payoutDate', birthDay);
      }
    },
    [getDefaultDescriptionForOccasion, birthDate, setValue],
  );

  const giftMutation = useMutation({
    mutationFn: async (gift: GiftFormValues) => {
      if (currentGift) {
        const amount = currentGift?.status === CouponsV2GiftDTOStatusEnum.InvoiceCreated ? undefined : gift.amount;
        const newPayoutDate = DateTime.fromJSDate(gift.payoutDate).toFormat('yyyy-MM-dd');
        await apis.couponsV2.updateCouponGift({
          couponsV2GiftId: currentGift.id,
          tenantId: getTenantId(),
          employeeId,
          updateCouponsV2GiftDTO: {
            ...gift,
            amount,
            payoutDate: currentGift.payoutDate.substring(0, 10) === newPayoutDate ? undefined : newPayoutDate,
            recurring: gift.recurring && giftYearlyAvailable(gift.occasion),
          },
        });
      } else {
        await apis.couponsV2.createCouponGift({
          tenantId: getTenantId(),
          employeeId,
          createCouponsV2GiftDTO: {
            ...gift,
            giftType,
            payoutDate: DateTime.fromJSDate(gift.payoutDate).toFormat('yyyy-MM-dd'),
            recurring: gift.recurring && giftYearlyAvailable(gift.occasion),
          },
        });
      }
    },

    onSuccess: () => {
      void invalidateGifts();
      void invalidateOccasions();
      enqueueSnackbar(t(currentGift ? 'successUpdate' : 'successCreate'), { variant: 'success' });
      dialogState.handleClose();
    },
    onError: async err => {
      if (currentGift && err instanceof AxiosError && err.response?.status === 422) {
        enqueueSnackbar(t('giftAlreadyCompleted'), { variant: 'warning' });
        dialogState.handleClose();
        await invalidateGifts();
      } else {
        withMessage(err as Error);
      }
    },
  });

  const handleAmountChange = useCallback(
    (event: React.SyntheticEvent, value: number) => {
      setValue('amount', value);
    },
    [setValue],
  );

  const handleSaveGift = useCallback(() => {
    const values = getValues();
    giftMutation.mutate(values);
  }, [getValues, giftMutation]);

  const handleChangeDescription = useCallback(
    (v: string) => {
      setValue('description', v);
    },
    [setValue],
  );

  const placeholders: Placeholder[] = useMemo(
    () => [
      {
        label: t('gifts.placeHolderInput.firstNameChipLabel'),
        value: 'firstName',
      },
      {
        label: t('gifts.placeHolderInput.lastNameChipLabel'),
        value: 'lastName',
      },
    ],
    [t],
  );

  return (
    <BasicDialog
      dialogState={dialogState}
      sx={{ overflow: 'visible' }}
      title={currentGift ? t(`gifts.dialogEditTitle.${giftType}`) : t(`gifts.dialogCreateTitle.${giftType}`)}
      content={
        <form onSubmit={handleSubmit(handleSaveGift)}>
          {(occasion === CouponsV2GiftDTOOccasionEnum.CompanyAnniversary ||
            (occasion === CouponsV2GiftDTOOccasionEnum.Other && giftType !== CouponsV2GiftDTOGiftTypeEnum.Incentive)) && (
            <Alert severity="info" sx={{ mb: 2 }}>
              {t(`couponsModule:giftOccasions.info.${occasion}`)}
            </Alert>
          )}
          {currentGift?.status === CouponsV2GiftDTOStatusEnum.InvoiceCreated && (
            <Alert severity="warning" sx={{ mb: 1 }}>
              {t('benefitsModule:invoiceCreatedWarning')}
            </Alert>
          )}
          {giftType === CouponsV2GiftDTOGiftTypeEnum.Incentive && yearlyIncentivesAmount + amount > MAX_YEARLY_INCENTIVE_AMOUNT && (
            <Alert severity="warning" sx={{ mb: 1 }}>
              {t('gifts.maxIncentiveAmountWarning')}
            </Alert>
          )}
          <Grid container gap={1} wrap="nowrap" mb={2}>
            <Grid size={5}>
              <DatePickerControl
                dateFormat="dd.MM.yyyy"
                minDate={DateTime.now().startOf('day').toJSDate()}
                maxDate={DateTime.now().plus({ years: 10 }).toJSDate()}
                control={control}
                name="payoutDate"
                label={t('gifts.fields.payoutDate')}
                fullWidth
                margin="dense"
                rules={{
                  required: true,
                }}
              />
            </Grid>
            <Grid size={3}>
              <Autocomplete
                disablePortal
                fullWidth
                disableClearable
                disabled={currentGift?.status === CouponsV2GiftDTOStatusEnum.InvoiceCreated}
                value={amount}
                onChange={handleAmountChange}
                renderInput={renderAmountInput}
                filterOptions={filterAmountOptions}
                getOptionLabel={getAmountOptionsLabel}
                options={giftType === CouponsV2GiftDTOGiftTypeEnum.Gift ? GIFT_BUDGET_OPTIONS : INCENTIVE_BUDGET_OPTIONS}
                ListboxProps={{
                  style: {
                    maxHeight: 180,
                    minWidth: 100,
                  },
                }}
              />
            </Grid>
            <Grid size={5}>
              <SelectControl
                control={control}
                name="occasion"
                label={t('gifts.fields.occasion')}
                fullWidth
                margin="dense"
                rules={{ required: true, onChange: handleChangeOccasion }}
                options={
                  giftType === CouponsV2GiftDTOGiftTypeEnum.Gift
                    ? GIFT_OCCASSION_OPTIONS.map(n => ({ value: n, label: t(`couponsModule:giftOccasions.${n}`) }))
                    : INCENTIVE_OCCASSION_OPTIONS.map(n => ({ value: n, label: t(`couponsModule:giftOccasions.${n}`) }))
                }
              />
            </Grid>
          </Grid>
          <Box my={1}>
            <PlaceholderInput
              label={t('gifts.placeHolderInput.inputLabel')}
              placeholders={placeholders}
              onChange={handleChangeDescription}
              initialValue={descriptionPrefill}
            />
          </Box>
          <Box mb={1}>
            {giftYearlyAvailable(watch('occasion')) && (
              <CheckboxControl
                control={control}
                name="recurring"
                label={t('gifts.fields.recurringText')}
                disabled={!giftYearlyAvailable(watch('occasion'))}
              />
            )}
            {userStatus === 'CREATED' && (
              <CheckboxControl control={control} name="inviteWhenActive" label={t('gifts.fields.inviteWhenActive')} />
            )}
            <CheckboxControl control={control} name="sendMailWhenActive" label={t('gifts.fields.sendMailWhenActive')} />
          </Box>
          {!isLoading && amount && giftType === CouponsV2GiftDTOGiftTypeEnum.Gift && (
            <Box>
              <AvailableCouponsAccordion coupons={data?.coupons} amount={amount} />
            </Box>
          )}
        </form>
      }
      actions={
        <DialogLoadingButton
          loading={giftMutation.isPending}
          disabled={giftType === CouponsV2GiftDTOGiftTypeEnum.Incentive && yearlyIncentivesAmount + amount > MAX_YEARLY_INCENTIVE_AMOUNT}
          sx={{ whiteSpace: 'nowrap' }}
          onClick={handleSubmit(handleSaveGift)}
          data-test-id="button-gift-submit"
        >
          {currentGift ? t('common:buttons.save') : t('common:buttons.orderWithPayment')}
        </DialogLoadingButton>
      }
    />
  );
};
