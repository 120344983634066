import SettingsIcon from '@mui/icons-material/Settings';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TutorialStepDefinition } from '../TutorialStep';

export function useTenantImageStep(): TutorialStepDefinition {
  const { t } = useTranslation('tutorialModule');
  const { tenant } = useTenant();

  const loading = !tenant;
  const completed = !!tenant?.imageURL;

  return useMemo(
    () => ({
      ...(t('steps.tenantImage', { returnObjects: true }) as {
        label: string;
        description: string;
        help: string;
        buttonText: string;
      }),
      path: '/settings/customisation',
      buttonIcon: SettingsIcon,
      loading,
      completed,
    }),
    [completed, loading, t],
  );
}
