import { Tabs, TabsProps } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DialogTabs: React.FC<TabsProps> = ({ TabIndicatorProps, children, ...props }) => {
  return (
    <Tabs
      {...props}
      TabIndicatorProps={{
        ...TabIndicatorProps,
        sx: {
          height: '3px',
          ...(TabIndicatorProps || { sx: {} }).sx,
        },
      }}
    >
      {children}
    </Tabs>
  );
};
