import { Box, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { ScreenSizeWarning } from '../../component/screenSize/ScreenSizeWarning';

export const ScreenSizeWatcher: React.FC<React.PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const invalidScreen = (import.meta.env.VITE_ALLOW_SMALL_SCREEN || '').toString() === 'true' || matches;

  return (
    <>
      <Box>{children}</Box>
      <Box
        sx={{
          display: invalidScreen ? 'none' : undefined,
          height: '100%',
          width: '100%',
          position: 'fixed',
          zIndex: 9999,
          left: 0,
          top: 0,
          background: theme.palette.background.default,
          overflow: 'hidden',
        }}
      >
        <ScreenSizeWarning />
      </Box>
    </>
  );
};
