import { useEffect } from 'react';
import { Control, FormState, useController } from 'react-hook-form';
import { FormValues } from '../../../useCustomBenefitCreator';

interface ValidateFieldsProps {
  control: Control<FormValues>;
  formState: FormState<FormValues>;
  setCanContinue: (newValue: boolean) => void;
}
// TODO: Think of better structure as this was only way to prevent whole component from rerendering
export const VisualisationContentValidation: React.FC<ValidateFieldsProps> = ({ control, setCanContinue }) => {
  const { fieldState } = useController({ name: 'defaultMonthlyGrant', control: control });

  useEffect(() => {
    if (fieldState.error) {
      setCanContinue(false);
    } else {
      setCanContinue(true);
    }
  }, [fieldState.error, setCanContinue]);

  return <></>;
};
