import { useSnackbar } from 'notistack';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { APILoader } from 'probonio-shared-ui/module/api';
import { captureMessageError } from 'probonio-shared-ui/module/error/sentry';
import { DesktopAuthProvider } from './module/auth';
import { GlobalErrorCatcher, GlobalErrorCatcherWithLogout, MessageError } from 'probonio-shared-ui/module/error';
import AppRoutes from './page/AppRoutes';
import { store } from './redux/store';
import { loadMe } from 'probonio-shared-ui/module/me';
import { ErrorFallback } from './module/error';
import { ScreenSizeWatcher } from './module/screen/ScreenSizeWatcher';
import i18n from './lang';
import { AxiosError } from 'axios';

const App: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('errorModule');

  /**
   * Shows the snackbar on MessageError. All other errors are ignored and handled by the
   * error boundary in GlobalErrorCatcher instead.
   * @param error
   * @returns {void}
   */
  const handleError = useCallback(
    (error: unknown): boolean | undefined => {
      if (error instanceof MessageError) {
        // eslint-disable-next-line no-console
        console.error(error.originalError);
        if (!error.displayMessage && error.originalError instanceof AxiosError && error.originalError.response?.status === 403) {
          enqueueSnackbar(t('forbiddenMessage'), { variant: 'warning' });
          return true;
        }
        enqueueSnackbar(error.displayMessage || t('defaultMessage'), { variant: 'error' });
        if (!error.displayMessage) {
          // if no custom message is set, the error is unexpected and should be reported to Sentry
          captureMessageError(error.originalError);
        }
        return true;
      }
    },
    [enqueueSnackbar, t],
  );

  const handleLoadMe = useCallback(async (hasToken: boolean) => {
    await store.dispatch(loadMe(hasToken));
    const { me } = store.getState();
    if (me && me.user && me.user.localeManager) {
      void i18n.changeLanguage(me.user.localeManager);
    }
  }, []);

  return (
    <GlobalErrorCatcher errorHandler={handleError} ErrorFallback={ErrorFallback}>
      <BrowserRouter>
        <Provider store={store}>
          <DesktopAuthProvider>
            <GlobalErrorCatcherWithLogout errorHandler={handleError} ErrorFallback={ErrorFallback}>
              <APILoader onLoginStateChange={handleLoadMe}>
                <ScreenSizeWatcher>
                  <AppRoutes />
                </ScreenSizeWatcher>
              </APILoader>
            </GlobalErrorCatcherWithLogout>
          </DesktopAuthProvider>
        </Provider>
      </BrowserRouter>
    </GlobalErrorCatcher>
  );
};

export default App;
