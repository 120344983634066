import CheckIcon from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Button, Stack, TextField, Typography } from '@mui/material';
import {
  BenefitDTOBenefitEnum,
  LunchReceiptDTOStatusEnum,
  LunchReceiptTenantDTO,
  MobilityReceiptTenantDTO,
  RecreationReceiptTenantDTO,
} from 'probonio-shared-ui/api';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicDialog, DialogState, useNewDialogState } from '../../../component/dialog';
import { ConfirmationModal } from '../../../component/dialog/ConfirmationModal';
import { DialogLoadingButton } from '../../../component/dialog/DialogLoadingButton';
import { useUpdateReceiptStatusMutation } from './useUpdateReceiptStatusMutation';
import { useSnackbar } from 'notistack';

interface Props {
  benefit: BenefitDTOBenefitEnum;
  receipt: LunchReceiptTenantDTO | MobilityReceiptTenantDTO | RecreationReceiptTenantDTO;
  onReceiptUpdated?: () => void;
}

interface StatusPanelProps {
  benefit: BenefitDTOBenefitEnum;
  receipt: LunchReceiptTenantDTO | MobilityReceiptTenantDTO | RecreationReceiptTenantDTO;
  onApprove?: () => void;
  onReject?: () => void;
}

const ApprovedPanel: React.FC<StatusPanelProps> = ({ benefit, receipt, onReject }) => {
  const { t } = useTranslation('receiptsModule');

  const checklist: { checklistText: string }[] = t(`receiptChecklist.${benefit}`, { returnObjects: true }) as { checklistText: string }[];
  const isApprovedByTenant = !!receipt.tenantAuditUser;

  return (
    <>
      <Typography variant="h2" mb={1}>
        {t('auditForm.approvalCriteria')}
      </Typography>
      {isApprovedByTenant ? (
        <Stack direction="row" spacing={0.5}>
          <CheckIcon color="success" />
          <Typography variant="body2" color="text.secondary">
            {t('auditForm.approvedByTenant')}
          </Typography>
        </Stack>
      ) : (
        <Stack spacing={0.5}>
          {checklist.map(({ checklistText }) => (
            <Stack key={checklistText} direction="row" spacing={0.5}>
              <CheckIcon color="success" />
              <Typography variant="body2" color="text.secondary">
                {checklistText}
              </Typography>
            </Stack>
          ))}
        </Stack>
      )}
      {receipt.isPeriodEditable && (
        <Button variant="outlined" color="error" size="small" onClick={onReject}>
          {t('auditForm.revokeApprove')}
        </Button>
      )}
    </>
  );
};

const RejectedPanel: React.FC<StatusPanelProps> = ({ receipt, onApprove: onApprove }) => {
  const { t } = useTranslation('receiptsModule');

  return (
    <>
      <Typography variant="h2" mb={1}>
        {t('auditForm.rejectionReason')}
      </Typography>
      <Stack direction="row" spacing={0.5}>
        <WarningAmberIcon color="warning" />
        <Typography variant="body2" color="text.secondary">
          {receipt.rejectionReason}
        </Typography>
      </Stack>
      {receipt.isPeriodEditable && (
        <Button variant="outlined" color="error" size="small" onClick={onApprove}>
          {t('auditForm.revokeReject')}
        </Button>
      )}
    </>
  );
};

const PendingPanel: React.FC<StatusPanelProps> = ({ receipt, onApprove, onReject }) => {
  const { t } = useTranslation('receiptsModule');

  return (
    receipt.isPeriodEditable && (
      <>
        <Typography variant="h2" mb={1}>
          {t('auditForm.receiptAudit')}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {t('auditForm.receiptAuditInfo')}
        </Typography>
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Button variant="outlined" color="error" size="small" onClick={onReject}>
            {t('auditForm.reject')}
          </Button>
          <Button variant="outlined" color="success" size="small" onClick={onApprove}>
            {t('auditForm.approve')}
          </Button>
        </Stack>
      </>
    )
  );
};

const ApproveReceiptDialog: React.FC<{ dialogState: DialogState; loading?: boolean; onConfirm: () => void }> = ({
  dialogState,
  loading,
  onConfirm,
}) => {
  const { t } = useTranslation('receiptsModule');

  return (
    <ConfirmationModal
      dialogState={dialogState}
      title={t('auditForm.approveTitle')}
      confirmButtonTitle={t('auditForm.approve')}
      onConfirm={onConfirm}
      closeOnConfirm={false}
      loading={loading}
    >
      <Typography color="text.secondary">{t('auditForm.approveText')}</Typography>
    </ConfirmationModal>
  );
};

const RejectReceiptDialog: React.FC<{ dialogState: DialogState; loading?: boolean; onConfirm: (rejectionReason: string) => void }> = ({
  dialogState,
  loading,
  onConfirm,
}) => {
  const { t } = useTranslation('receiptsModule');
  const [rejectionReason, setRejectionReason] = useState('');

  const handleChangeRejectionReason = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRejectionReason(event.target.value);
  }, []);
  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      onConfirm(rejectionReason);
    },
    [onConfirm, rejectionReason],
  );

  useEffect(() => {
    if (dialogState.isOpen) {
      setRejectionReason('');
    }
  }, [dialogState.isOpen]);

  return (
    <BasicDialog
      dialogState={dialogState}
      onSubmit={handleSubmit}
      title={t('auditForm.rejectTitle')}
      content={
        <>
          <Typography mb={2} color="text.secondary">
            {t('auditForm.rejectText')}
          </Typography>
          <TextField
            multiline
            autoFocus
            fullWidth
            rows={2}
            placeholder={t('auditForm.rejectionReason')}
            required
            slotProps={{ htmlInput: { maxLength: 100 } }}
            value={rejectionReason}
            onChange={handleChangeRejectionReason}
          />
        </>
      }
      actions={
        <DialogLoadingButton type="submit" color="primary" variant="text" loading={loading}>
          {t('auditForm.reject')}
        </DialogLoadingButton>
      }
    />
  );
};

export const ReceiptAuditForm: React.FC<Props> = ({ benefit, receipt, onReceiptUpdated }) => {
  const { t } = useTranslation('receiptsModule');
  const approveDialog = useNewDialogState();
  const rejectDialog = useNewDialogState();
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useUpdateReceiptStatusMutation(
    benefit,
    { employeeId: receipt.employee.id, id: receipt.id },
    {
      onSuccess: () => {
        enqueueSnackbar(t('auditForm.updateSuccess'), { variant: 'success' });
        onReceiptUpdated?.();
      },
    },
  );

  const handleApprove = useCallback(() => {
    mutation.mutate({ status: LunchReceiptDTOStatusEnum.Approved }, { onSuccess: approveDialog.dialogState.handleClose });
  }, [approveDialog.dialogState.handleClose, mutation]);

  const handleReject = useCallback(
    (rejectionReason: string) => {
      mutation.mutate({ status: LunchReceiptDTOStatusEnum.Rejected, rejectionReason }, { onSuccess: rejectDialog.dialogState.handleClose });
    },
    [mutation, rejectDialog.dialogState.handleClose],
  );

  return (
    <>
      {receipt.status === LunchReceiptDTOStatusEnum.Approved && (
        <ApprovedPanel benefit={benefit} receipt={receipt} onReject={rejectDialog.handleOpen} />
      )}
      {receipt.status === LunchReceiptDTOStatusEnum.Rejected && (
        <RejectedPanel benefit={benefit} receipt={receipt} onApprove={approveDialog.handleOpen} />
      )}
      {receipt.status === LunchReceiptDTOStatusEnum.Pending && (
        <PendingPanel benefit={benefit} receipt={receipt} onApprove={approveDialog.handleOpen} onReject={rejectDialog.handleOpen} />
      )}
      <ApproveReceiptDialog dialogState={approveDialog.dialogState} loading={mutation.isPending} onConfirm={handleApprove} />
      <RejectReceiptDialog dialogState={rejectDialog.dialogState} loading={mutation.isPending} onConfirm={handleReject} />
    </>
  );
};
