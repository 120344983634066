import { BenefitDTOBenefitEnum, LunchReceiptTenantDTO, MobilityReceiptTenantDTO, RecreationReceiptTenantDTO } from 'probonio-shared-ui/api';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BenefitPaper } from '../../component/benefitTabs/BenefitPaper';
import { useNewDialogState } from '../../component/dialog';
import { BENEFIT_ICONS } from '../../module/benefits/BenefitIcon';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { ReceiptDetailsDrawer } from '../../module/benefits/ReceiptList/ReceiptDetailsDrawer';
import { ReceiptList } from '../../module/benefits/ReceiptList/ReceiptList';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';

const RecreationReceiptsPage: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const [selectedReceipt, setSelectedReceipt] = useState<LunchReceiptTenantDTO | MobilityReceiptTenantDTO | RecreationReceiptTenantDTO>();
  const { dialogState: drawerDialogState, handleOpen: handleOpenDrawer } = useNewDialogState();

  const handleSelectReceipt = useCallback(
    (receipt: LunchReceiptTenantDTO | MobilityReceiptTenantDTO | RecreationReceiptTenantDTO) => {
      setSelectedReceipt(receipt);
      handleOpenDrawer();
    },
    [handleOpenDrawer],
  );

  return (
    <AuthorizedLayout title={t('subPage.receipts')} breadcrumbs={[{ title: t('RECREATION.title'), to: '/benefits/recreation' }]}>
      <BenefitSubPage icon={BENEFIT_ICONS.RECREATION} title={t('RECREATION.title')} subTitle={t('subPage.receipts')}>
        <BenefitPaper>
          <ReceiptList
            benefit={BenefitDTOBenefitEnum.Recreation}
            selectedReceipt={drawerDialogState.isOpen ? selectedReceipt : undefined}
            onSelectReceipt={handleSelectReceipt}
            onReceiptUpdated={setSelectedReceipt}
          />
          {selectedReceipt && (
            <ReceiptDetailsDrawer dialogState={drawerDialogState} benefit={BenefitDTOBenefitEnum.Recreation} receipt={selectedReceipt} />
          )}
        </BenefitPaper>
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};

export default RecreationReceiptsPage;
