import { Box, BoxProps } from '@mui/material';
import { Chart, registerables, ChartType, DefaultDataPoint, ChartConfiguration } from 'chart.js';
import React, { useEffect, useRef, useState } from 'react';
import { FullWidthCenterBox } from '../layout/BoxLayout';

Chart.register(...registerables);

interface Props<TType extends ChartType = ChartType, TData = DefaultDataPoint<TType>, TLabel = unknown> extends BoxProps {
  /**
   * Can be used to overlay the chart with anything else.
   */
  children?: React.ReactNode;

  /**
   * chart config according to https://www.chartjs.org/
   */
  config: ChartConfiguration<TType, TData, TLabel>;
}

export const ReactChart = <TType extends ChartType = ChartType, TData = DefaultDataPoint<TType>, TLabel = unknown>({
  children,
  config,
  sx,
  ...boxProps
}: Props<TType, TData, TLabel>): JSX.Element => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [chart, setChart] = useState<Chart<TType, TData, TLabel>>();

  useEffect(() => {
    if (!canvasRef?.current) {
      return;
    }

    const newChart = new Chart(canvasRef?.current, config);

    setChart(newChart);

    return () => newChart.destroy();
  }, [canvasRef, config]);

  return (
    <Box sx={{ ...sx, position: 'relative' }} {...boxProps}>
      {!!chart && (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <FullWidthCenterBox>{children}</FullWidthCenterBox>
        </Box>
      )}
      <Box
        sx={{
          ...sx,
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <canvas
          ref={canvasRef}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </Box>
    </Box>
  );
};
