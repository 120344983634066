import { Button, Tooltip } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { FilterEmployeesDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewDialogState } from '../../component/dialog';
import { ConfirmationModal } from '../../component/dialog/ConfirmationModal';
import { useRefetchEmployees } from './query';
import { useEmployeeList } from './useEmployeeList';

interface Props {
  titleTextKey: string;
  dialogTextKey: string;
  tooltipTextKey: string;
  icon: React.ReactNode;
  filter?: FilterEmployeesDTO;
}

export const InviteUsersButton: React.FC<Props> = ({ titleTextKey, icon, dialogTextKey, tooltipTextKey, filter }) => {
  const refetchEmployees = useRefetchEmployees();
  const getTenantId = useTenantID();
  const confirmInviteAllDialog = useNewDialogState();
  const { t } = useTranslation();
  const { data } = useEmployeeList({
    ...filter,
    page: 0,
    pageSize: 0,
  });

  const mutation = useMutation({
    mutationFn: () =>
      apis.employee.inviteUsers({
        tenantId: getTenantId(),
        ...filter,
      }),

    onSuccess: async () => {
      await refetchEmployees();
      confirmInviteAllDialog.dialogState.handleClose();
    },
  });
  const inviteUsers = useCallback(() => {
    mutation.mutate();
  }, [mutation]);

  if (!data?.totalCount) {
    return <></>;
  }

  return (
    <>
      <ConfirmationModal
        dataTestId="confirm-inviteAllEmployee-button"
        dialogState={confirmInviteAllDialog.dialogState}
        onConfirm={inviteUsers}
        closeOnConfirm={false}
        loading={mutation.isPending}
        title={t(titleTextKey, { count: data?.totalCount || 0 })}
      >
        {t(dialogTextKey, { count: data?.totalCount || 0 })}
      </ConfirmationModal>
      <Tooltip title={t(tooltipTextKey, { count: data?.totalCount || 0 })}>
        <Button data-test-id={titleTextKey} onClick={confirmInviteAllDialog.handleOpen} startIcon={icon} size="small" color="success">
          {data?.totalCount || 0}
        </Button>
      </Tooltip>
    </>
  );
};
