import Grid from '@mui/material/Grid2';
import { validateEmail } from 'probonio-shared-ui/utils/email';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextFieldControl } from '../../component/form';
import { SettingsFormRow } from '../../component/settings/SettingsFormRow';
import TextFieldChipsControl from '../../component/form/TextFieldChipsControl';
import i18n from '../../lang';

export interface FormFields {
  tenantName: string;
  tenantStreet: string;
  tenantPostalCode: string;
  tenantCity: string;
  tenantMail: string;
  invoiceEmailList: string[];
}

interface Props {
  control: Control<FormFields>;
  disabled?: boolean;
}

function validateEmails(value: string[]): string | true {
  if (value.length > 5) {
    return i18n.t('tenantModule:paymentSettings.notMoreThanFive');
  } else if (value.some(email => !validateEmail(email))) {
    return i18n.t('usersModule:createNewUserFields.notValidMail');
  } else if (value.length <= 0) {
    return i18n.t('common:inputRequired');
  }
  return true;
}

export const TenantSettingsForm: React.FC<Props> = ({ control, disabled }) => {
  const { t } = useTranslation('tenantModule');

  return (
    <>
      <SettingsFormRow title={t('paymentSettings.address.title')} info={t('paymentSettings.address.info')}>
        <Grid container columnSpacing={1}>
          <Grid size={12}>
            <TextFieldControl
              label={t('paymentSettings.fields.tenantName')}
              control={control}
              name="tenantName"
              fullWidth
              sx={{ marginBottom: 2 }}
              disabled={disabled}
              rules={{ required: true }}
            />
          </Grid>
          <Grid size={12}>
            <TextFieldControl
              label={t('paymentSettings.fields.tenantMail')}
              control={control}
              name="tenantMail"
              fullWidth
              sx={{ marginBottom: 2 }}
              disabled={disabled}
              rules={{ required: true, validate: validateEmail }}
            />
          </Grid>

          <Grid size={12}>
            <TextFieldChipsControl
              sx={{ marginBottom: 2 }}
              label={t('paymentSettings.fields.invoiceEmailList')}
              fullWidth
              control={control}
              name="invoiceEmailList"
              disabled={disabled}
              rules={{ validate: { email: validateEmails } }}
            />
          </Grid>

          <Grid size={12}>
            <TextFieldControl
              label={t('paymentSettings.fields.tenantStreet')}
              control={control}
              name="tenantStreet"
              fullWidth
              sx={{ marginBottom: 2 }}
              disabled={disabled}
            />
          </Grid>

          <Grid size={6}>
            <TextFieldControl
              label={t('paymentSettings.fields.tenantPostalCode')}
              control={control}
              name="tenantPostalCode"
              fullWidth
              sx={{ marginBottom: 2 }}
              disabled={disabled}
            />
          </Grid>

          <Grid size={6}>
            <TextFieldControl
              label={t('paymentSettings.fields.tenantCity')}
              control={control}
              name="tenantCity"
              fullWidth
              sx={{ marginBottom: 2 }}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </SettingsFormRow>
    </>
  );
};
