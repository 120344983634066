import { SelectProps, SelectVariants } from '@mui/material';
import React from 'react';
import { SelectorLayout, SelectorTypes } from '../SelectorLayout';
import { useCostCenterSelector } from '../useSelectorQuery';

interface Props extends Omit<SelectProps<string>, 'onChange' | 'variant'> {
  onChange: (costCenter?: string) => void;
  variant?: SelectVariants;
}

export const CostCenterSelector: React.FC<Props> = ({ onChange, sx, value, ...props }) => {
  const data = useCostCenterSelector();

  return (
    <SelectorLayout selectorType={SelectorTypes.COST_CENTER} selectorData={data} onChange={onChange} sx={sx} value={value} {...props} />
  );
};
