import CancelOutlined from '@mui/icons-material/CancelOutlined';
import { Chip, styled, TextField, TextFieldProps } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { findAllEmails, validateEmail } from 'probonio-shared-ui/utils/email';

const TextFieldChip = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    width: 'auto',
    minWidth: 0,
    flexGrow: 1,
    flexWrap: 'wrap',
    margin: theme.spacing(0.5),
    padding: theme.spacing(0),
  },
}));

interface ChipsProps {
  value: string[];
  onChange: (newValue: string[]) => void;
}

type Props = ChipsProps & Omit<TextFieldProps, 'onChange'>;

const TextFieldChips = React.forwardRef<HTMLDivElement, Props>(({ value, onChange, ...props }, ref) => {
  const [text, setText] = useState<string>('');

  const handlePaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.currentTarget.value = event.clipboardData.getData('text').toLowerCase();

      event.preventDefault();
      const mails = findAllEmails(event.currentTarget.value);

      for (const mail of mails) {
        if (!value.includes(mail)) {
          value.push(mail);
        }
      }
      onChange(value);
      return;
    },
    [onChange, value],
  );

  const handleFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value.trim().replace(',', ''));
  }, []);

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const innerValue = value;

      if (event.key === 'Enter' || event.key === ' ' || event.key === ',' || event.key === 'Tab') {
        if (text !== '') {
          event.preventDefault();
        } else {
          return;
        }

        const duplicatedValue = innerValue.some(values => values === text);
        if (!duplicatedValue) {
          const newValues = [...innerValue, text];
          onChange(newValues);
          setText('');
        }
      }
      if (innerValue.length > 0 && text.length < 1 && event.key === 'Backspace') {
        const newValues = innerValue.slice(0, innerValue.length - 1);

        onChange(newValues);
      }
    },
    [onChange, text, value],
  );

  const handleChipDelete = useCallback(
    (index: number) => () => {
      const newValues = value;
      newValues.splice(index, 1);

      onChange(newValues);
    },
    [onChange, value],
  );

  const handleOnBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (event.target.value.length < 1) {
        return;
      }
      const newValues = [...value, event.target.value];

      onChange(newValues);
      setText('');
    },
    [onChange, value],
  );

  return (
    <TextFieldChip
      {...props}
      ref={ref}
      value={text}
      slotProps={{
        htmlInput: { className: 'no-full-width' },
        input: {
          sx: {
            display: 'flex',
            padding: 0.7,
            flexWrap: 'wrap',
            alignItems: 'baseline',
          },
          startAdornment: value.map((values, index) =>
            values.length > 0 ? (
              <Chip
                sx={
                  validateEmail(values)
                    ? {
                        borderRadius: 0.4,
                        marginY: 0.3,
                        marginX: 0.3,
                      }
                    : {
                        borderRadius: 0.4,
                        marginY: 0.3,
                        marginX: 0.3,
                        color: 'red',
                        borderStyle: 'solid',
                        borderWidth: 2,
                        borderColor: 'red',
                      }
                }
                onDelete={handleChipDelete(index)}
                deleteIcon={<CancelOutlined color="inherit" />}
                key={values}
                tabIndex={-1}
                label={values}
                disabled={props.disabled}
              />
            ) : null,
          ),
        },
      }}
      onChange={handleFieldChange}
      onKeyDown={handleKeyPress}
      onBlur={handleOnBlur}
      onPaste={handlePaste}
    />
  );
});

export default TextFieldChips;
