import { CouponGiftDTO } from 'probonio-shared-ui/api';
import { createContext, useContext } from 'react';

interface GiftActivationModal {
  onGiftCreate: () => void;
  onGiftDelete: (gift: CouponGiftDTO) => void;
  onGiftEdit: (gift: CouponGiftDTO) => void;
}

const GiftActivationModalContext = createContext<GiftActivationModal | null>(null);

export function useGiftActivationModal(): GiftActivationModal {
  const context = useContext(GiftActivationModalContext);

  if (!context) {
    throw new Error('No ProfileActivationActions found for component');
  }

  return context;
}

export default GiftActivationModalContext;
