import { Typography } from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';
import { EmployeeDTO } from 'probonio-shared-ui/api';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AnonymousAvatar } from '../userManagement/AnonymousAvatar';
import { EmployeeBenefitsCell } from '../userManagement/Column';
import { UserNameAndAvatar } from '../userManagement/UserNameAndAvatar';
import { mapEmployeeStatus } from '../userManagement/employeeStatus';

const DashboardEmployeeStatusCell: React.FC<{ employee: EmployeeDTO }> = ({ employee }) => {
  const { status, color } = mapEmployeeStatus(employee);

  const { t } = useTranslation('dashboardModule');
  return (
    <Typography variant="inherit" color={color}>
      <Trans i18nKey={`common:userStatus.${status}`} />
      <Typography variant="inherit" color="text.primary">
        {t('employeesPanel.createdAt', { date: new Date(employee.createdAt!) })}
      </Typography>
    </Typography>
  );
};

export function useDashboardEmployeeColumns(): MRT_ColumnDef<EmployeeDTO>[] {
  const { t } = useTranslation('usersModule');

  return useMemo(() => {
    const columns: MRT_ColumnDef<EmployeeDTO>[] = [
      {
        id: 'name',
        accessorFn: employee => (employee.user ? <UserNameAndAvatar user={employee.user} /> : <AnonymousAvatar />),
        header: t('usersModule:usersList.header.name'),
        enableSorting: false,
      },
      {
        accessorKey: 'activeBenefits',
        header: t('usersModule:usersList.header.activeBenefits'),
        enableSorting: false,
        Cell: ({ row }) => <EmployeeBenefitsCell employee={row.original} />,
      },
      {
        accessorKey: 'createdAt',
        header: t('usersModule:usersList.header.status'),
        enableSorting: false,
        Cell: ({ row }) => <DashboardEmployeeStatusCell employee={row.original} />,
      },
    ];
    return columns;
  }, [t]);
}
