import { Transition } from 'history';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from '../../util/useBlocker';
import { DialogState } from '../dialog';
import { ConfirmationModal } from '../dialog/ConfirmationModal';

interface DialogProps {
  dialogState: DialogState;
  onDiscard: () => void;
}

const UnsavedChangesModal: React.FC<DialogProps> = ({ dialogState, onDiscard }) => {
  const { t } = useTranslation('common');

  return (
    <ConfirmationModal
      dataTestId="discard-unsaved-changes"
      dialogState={dialogState}
      confirmButtonTitle={t('unsavedChanges.discard')}
      confirmColor="error"
      cancelButtonTitle={t('unsavedChanges.backToForm')}
      onConfirm={onDiscard}
    >
      {t('unsavedChanges.message')}
    </ConfirmationModal>
  );
};

interface Props {
  formState?: { isDirty?: boolean; isSubmitting?: boolean; isSubmitted?: boolean };
  isDirty?: boolean;
}

/**
 * Insert this into a form body to show a confirmation message on navigation as long as the
 * form state reports the form as modified (dirty and not submitted)
 */
export const UnsavedChangesListener: React.FC<Props> = ({ formState, isDirty }) => {
  const [transition, setTransition] = useState<Transition>();
  const [open, setOpen] = useState(false);
  const dialogState = useMemo<DialogState>(() => ({ isOpen: open, handleClose: () => setOpen(false) }), [open]);

  useBlocker(
    useCallback(tx => {
      setTransition(tx);
      setOpen(true);
    }, []),
    isDirty || (!!formState?.isDirty && !formState?.isSubmitting && !formState?.isSubmitted),
  );

  const handleContinue = useCallback(() => {
    transition?.retry();
  }, [transition]);

  return <UnsavedChangesModal dialogState={dialogState} onDiscard={handleContinue} />;
};
