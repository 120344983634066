import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query';
import { EmployeeApiListEmployeesRequest, EmployeeListDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';

const PAGE_SIZE = 10;

export function usePaginatedEmployeeList(
  parameters?: Partial<EmployeeApiListEmployeesRequest>,
  options?: { enabled?: boolean },
): UseInfiniteQueryResult<InfiniteData<EmployeeListDTO, Error>> {
  const getTenantId = useTenantID();

  return useInfiniteQuery({
    queryKey: [
      'tenants',
      getTenantId(),
      'employees',
      {
        pageSize: parameters?.pageSize ?? PAGE_SIZE,
        ...parameters,
        excludeEmployeeStatus: ['INACTIVE'],
      },
      apis.employee.listEmployees.name,
    ],
    queryFn: ({ pageParam = 0 }) =>
      apis.employee
        .listEmployees({
          tenantId: getTenantId(),
          page: pageParam,
          pageSize: parameters?.pageSize ?? PAGE_SIZE,
          ...parameters,
        })
        .then(res => res.data),
    getNextPageParam: (lastPage, pages) =>
      pages.length * (parameters?.pageSize ?? PAGE_SIZE) < lastPage.totalCount ? pages.length : undefined,
    staleTime: Infinity,
    initialPageParam: 0,
    placeholderData: previousData => previousData,
    ...options,
  });
}
