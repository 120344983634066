import { LinearProgress, Skeleton } from '@mui/material';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { CUSTOM_BENEFIT_ICONS } from 'probonio-shared-ui/component/icon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BenefitDocuments } from '../../module/benefits/BenefitDocuments';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { useCustomBenefitCreatorState } from '../../module/benefits/customBenefit';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';

export const CustomBenefitDocumentsPage: React.FC = () => {
  const { t } = useTranslation('benefitsModule');

  const customBenefitCreatorState = useCustomBenefitCreatorState();
  if (!customBenefitCreatorState?.currentCustomBenefitTemplate) {
    return (
      <AuthorizedLayout>
        <LinearProgress />
        <Skeleton animation="wave" height="80vh" />
      </AuthorizedLayout>
    );
  }

  return (
    <AuthorizedLayout
      title={t('subPage.documents')}
      breadcrumbs={[
        {
          title: customBenefitCreatorState.currentCustomBenefitTemplate.benefit.title,
          to: `/benefits/custom/${customBenefitCreatorState.currentCustomBenefitTemplate.customBenefitId}`,
        },
      ]}
    >
      <BenefitSubPage
        icon={CUSTOM_BENEFIT_ICONS[customBenefitCreatorState.currentCustomBenefitTemplate.benefit.iconValue]}
        title={customBenefitCreatorState.currentCustomBenefitTemplate.benefit.title}
        subTitle={t('subPage.documents')}
      >
        <BenefitDocuments benefit={BenefitDTOBenefitEnum.Custom} />
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};
