import { AuthGuard, useLoginContext } from '@tiffinger-thiel/appauth-react';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MessageLayout from '../../component/layout/MessageLayout';
import { CircularProgress } from '@mui/material';
import { useAppSelector } from '../../redux/hooks';
import { ErrorFallback } from '../error';
import Error403Page from '../../page/Error403Page';
import { LegalTermsGuard } from '../../page/legalTerms/LegalTermsGuard';

interface Props {
  page?: React.ComponentType;
}

export const OAUTH_RETURN_PATH = 'oauthReturnPath';

/**
 * Wraps a page component with a guard that automatically redirects to the login page
 */
const PrivatePage: React.FC<React.PropsWithChildren<Props>> = ({ page, children }) => {
  const { isReady, isLoggedIn, login } = useLoginContext();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (isReady && !isLoggedIn) {
      localStorage.setItem(OAUTH_RETURN_PATH, `${location.pathname}${location.search}`);
      void login();
    }
  }, [isReady, isLoggedIn, login, location, navigate]);

  const handleLogout = useCallback(() => {
    navigate('/logout');
  }, [navigate]);

  const PageComponent = page;

  const { forbidden, initializationError } = useAppSelector(state => state.me);

  if (forbidden === undefined) {
    return (
      <MessageLayout>
        <CircularProgress />
      </MessageLayout>
    );
  } else if (forbidden) {
    return <Error403Page />;
  } else if (initializationError) {
    return <ErrorFallback logout={handleLogout} />;
  } else {
    return (
      <AuthGuard>
        <LegalTermsGuard>{PageComponent ? <PageComponent /> : children}</LegalTermsGuard>
      </AuthGuard>
    );
  }
};

export default PrivatePage;
