import { CircularProgress } from '@mui/material';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BAVContractCard } from '../../module/benefits/bav/BAVContractCard';
import { useCurrentBAVContract } from '../../module/benefits/bav/useCurrentBAVContract';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';
import { renderUserName } from '../../module/userManagement/userFormatter';

const BAVContractDetailsPage: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { employeeId } = useParams();

  const { data: employee } = useTenantQuery(['employees', employeeId, apis.employee.getEmployeeById.name], tenantId =>
    apis.employee.getEmployeeById({ tenantId, employeeId: employeeId!, includeRegistrationToken: true }).then(res => res.data),
  );

  const currentBAVContract = useCurrentBAVContract(employeeId!);

  return (
    <AuthorizedLayout
      title={renderUserName(employee?.user, employee?.employeeNumber)}
      breadcrumbs={[
        { title: t('BAV.title'), to: '/benefits/bav' },
        { title: t('BAV.contracts'), to: '/benefits/bav/contracts' },
      ]}
    >
      {currentBAVContract ? <BAVContractCard contract={currentBAVContract} /> : <CircularProgress />}
    </AuthorizedLayout>
  );
};

export default BAVContractDetailsPage;
