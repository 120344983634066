import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MessageLayout from '../component/layout/MessageLayout';

const RegisterErrorPage: React.FC = () => {
  const { t } = useTranslation('authModule');

  return (
    <MessageLayout title={t('registrationExpired.title')} text={t('registrationExpired.text')}>
      <Button component={Link} to="/" variant="contained" color="warning">
        {t('errorModule:goHome')}
      </Button>
    </MessageLayout>
  );
};

export default RegisterErrorPage;
