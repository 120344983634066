import { Box, Card, CardActionArea, CardContent, CardMedia, SvgIconProps, Typography } from '@mui/material';
import { CircularProgressBar } from 'probonio-shared-ui/component/progress';
import React from 'react';
import { Ribbon } from 'probonio-shared-ui/component/ribbon';

interface Props {
  title: React.ReactNode;
  icon: React.ComponentType<SvgIconProps>;
  imageUrl: string;
  faded?: boolean;
  ribbonText?: string;
  onClick?: () => void;
  dataTestId?: string;
}

export const DashboardCarouselButton: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  icon,
  imageUrl,
  faded,
  ribbonText,
  onClick,
  children,
  dataTestId,
}) => {
  const IconComponent = icon;

  return (
    <Card variant="outlined" sx={{ height: '100%', position: 'relative' }}>
      {ribbonText && <Ribbon>{ribbonText}</Ribbon>}
      <CardActionArea sx={{ height: '100%' }} onClick={onClick} data-test-id={dataTestId}>
        <Box sx={{ height: '100%' }}>
          <CardMedia
            sx={{
              height: 100,
              position: 'relative',
              color: 'rgb(155, 203, 235)',
              p: 1,
              backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.6), transparent), url(${imageUrl});`,
              opacity: faded ? 0.4 : undefined,
            }}
          >
            <CircularProgressBar
              size="48px"
              backgroundColor="white"
              color="inherit"
              variant="determinate"
              thickness={4}
              value={100}
              className="circle"
            >
              <IconComponent sx={{ position: 'absolute', left: '12px', top: '12px' }} />
            </CircularProgressBar>
          </CardMedia>
          <CardContent>
            <Typography variant="h4" fontWeight="normal" textTransform="none" fontSize={20} gutterBottom noWrap>
              {title}
            </Typography>
            <Typography component="div" variant="body2" color="text.secondary">
              {children}
            </Typography>
          </CardContent>
        </Box>
      </CardActionArea>
    </Card>
  );
};
