import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import HandshakeIcon from '@mui/icons-material/Handshake';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import SettingsIcon from '@mui/icons-material/Settings';
import { Badge, Button, Divider, IconButton, Menu, MenuItem, Skeleton, Typography } from '@mui/material';
import { useLoginContext } from '@tiffinger-thiel/appauth-react';
import { UserDTO } from 'probonio-shared-ui/api';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useNewDialogState } from '../../component/dialog';
import { SupportDialog } from '../../component/support';
import { useAppSelector } from '../../redux/hooks';
import { fontSizes, spacings } from '../../theme';
import { TenantSwitcher } from '../tenant/TenantSwitcher';
import { useTutorialContext } from '../tenant/Tutorial/tutorialContext';
import { LetterAvatar } from '../userManagement/LetterAvatar';
import { renderUserName } from '../userManagement/userFormatter';
import { ReferralDialog } from './ReferralDialog';
import { UserEditDialog } from './UserEditDialog';
import { maySwitchAdminTenant } from 'probonio-shared-ui/module/me/permissionSelectors';

function getEnv(name: string): string {
  return (import.meta.env[name] || '').toString();
}

const UserWithAvatar: React.FC<{ user?: UserDTO; noName?: boolean; openUserEditDialog?: () => void }> = ({
  user,
  openUserEditDialog,
  noName = false,
}) => {
  return user ? (
    <>
      <LetterAvatar
        size={spacings.navbarAvatar}
        sx={{ marginRight: 0.5, fontSize: fontSizes.md, cursor: openUserEditDialog ? 'pointer' : 'inherit' }}
        firstName={user?.firstName}
        lastName={user?.lastName}
        mail={user?.mail}
        onClick={openUserEditDialog}
      />
      {!noName && (
        <Typography variant="h3" sx={{ marginRight: 1 }} noWrap>
          {renderUserName(user)}
        </Typography>
      )}
    </>
  ) : (
    <>
      <Skeleton variant="circular" width={spacings.navbarAvatar} height={spacings.navbarAvatar} sx={{ marginRight: 0.5 }} />
      {!noName && (
        <>
          <Skeleton variant="text" width="3em" sx={{ marginRight: 0.5 }} />
          <Skeleton variant="text" width="4em" sx={{ marginRight: 1 }} />
        </>
      )}
    </>
  );
};

const UserMenuComp: React.FC = () => {
  const { t } = useTranslation('navigationModule');
  const { login, isLoggedIn, isReady } = useLoginContext();
  const user = useAppSelector(state => state.me.user);

  const handleLogin = useCallback(() => login(), [login]);

  const userEditDialog = useNewDialogState();
  const userMenu = useNewDialogState();
  const supportDialog = useNewDialogState();
  const referralCodeDialog = useNewDialogState();

  const hasAvailableAdminTenants = useAppSelector(state => maySwitchAdminTenant(state.me.user));

  const userProfileButton = useRef<HTMLButtonElement>(null);

  const {
    drawerState: { handleOpen: handleOpenTutorial },
    steps: { openCount },
  } = useTutorialContext();

  const handleEditUser = useCallback(() => {
    userMenu.dialogState.handleClose();
    userEditDialog.handleOpen();
  }, [userEditDialog, userMenu.dialogState]);

  return (
    <>
      {!isLoggedIn && isReady && (
        <Button variant="text" color="inherit" onClick={handleLogin}>
          {t('login')}
        </Button>
      )}
      {isLoggedIn && (
        <>
          <IconButton href={getEnv('VITE_WEB_APP_URL')} sx={{ color: 'text.secondary' }} title={t('linkToApp')}>
            <MobileScreenShareIcon />
          </IconButton>

          <IconButton
            onClick={handleOpenTutorial}
            sx={{ color: 'text.secondary' }}
            title={t('tutorialModule:buttonOpenDrawer')}
            className="tutorial-opener"
            aria-label={t('tutorialModule:buttonOpenDrawer')}
          >
            <Badge badgeContent={openCount > 0 ? `${openCount}` : undefined} color="error">
              <LightbulbIcon />
            </Badge>
          </IconButton>

          <IconButton href="https://support.probonio.de/hc" target="_blank" sx={{ color: 'text.secondary' }} title={t('manual')}>
            <MenuBookIcon />
          </IconButton>
          <IconButton sx={{ color: 'text.secondary' }} title={t('menuItem.contactSupport')} onClick={supportDialog.handleOpen}>
            <ContactSupportIcon />
          </IconButton>

          {hasAvailableAdminTenants && <TenantSwitcher />}

          <IconButton sx={{ color: 'text.secondary' }} title={t('menuItem.referral')} onClick={referralCodeDialog.handleOpen}>
            <HandshakeIcon data-test-id="navbar-referral" />
          </IconButton>

          <IconButton component={Link} sx={{ color: 'text.secondary' }} title={t('menuItem.tenantSettings')} to="/settings">
            <SettingsIcon data-test-id="navbar-settings" />
          </IconButton>

          <IconButton ref={userProfileButton} sx={{ color: 'text.secondary' }} title={t('menuItem.account')} onClick={userMenu.handleOpen}>
            <AccountCircleIcon />
          </IconButton>
          <Menu anchorEl={userProfileButton.current} open={userMenu.dialogState.isOpen} onClose={userMenu.dialogState.handleClose}>
            <MenuItem disabled sx={{ opacity: '1 !important' }}>
              <UserWithAvatar user={user} />
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleEditUser}>{t('menuItem.userProfile')}</MenuItem>
            <MenuItem component={Link} to="/logout" onClick={userMenu.dialogState.handleClose}>
              {t('logout')}
            </MenuItem>
          </Menu>

          <UserEditDialog dialogState={userEditDialog.dialogState} />
          <SupportDialog dialogState={supportDialog.dialogState} />
          <ReferralDialog dialogState={referralCodeDialog.dialogState} />
        </>
      )}
    </>
  );
};

export const UserMenu = React.memo(UserMenuComp);
