import { Box, SxProps } from '@mui/material';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { CUSTOM_BENEFIT_ICONS } from 'probonio-shared-ui/component/icon';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useTranslation } from 'react-i18next';
import { BENEFIT_ICONS, orderedBenefits } from './BenefitIcon';

export interface BenefitRef {
  benefit: BenefitDTOBenefitEnum;
  customBenefitId?: string;
}

export const BenefitsCell: React.FC<{
  activeBenefits: BenefitRef[];
  upcomingBenefits?: BenefitRef[];
  inheritColor?: boolean;
  sx?: SxProps;
}> = ({ activeBenefits, upcomingBenefits, inheritColor, sx }) => {
  const { t } = useTranslation('benefitsModule');
  const { tenant } = useTenant();
  const { data: customBenefits, isFetched } = useTenantQuery(
    ['benefits', 'custom', apis.customBenefits.listCustomBenefits],
    tenantId =>
      apis.customBenefits
        .listCustomBenefits({
          tenantId,
        })
        .then(res => res.data),
    {
      gcTime: 60000,
      staleTime: 60000,
    },
  );

  if (!isFetched) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', width: '100%', columnGap: 0.2, flexWrap: 'wrap', ...sx }}>
      {orderedBenefits(tenant)
        ?.filter(
          benefit => benefit !== BenefitDTOBenefitEnum.Custom && activeBenefits?.some(activeBenefit => activeBenefit.benefit === benefit),
        )
        .map(benefit => {
          const Icon = BENEFIT_ICONS[benefit];
          return <Icon key={benefit} color={inheritColor ? 'inherit' : 'primary'} titleAccess={t(`common:benefit.${benefit}`)} />;
        })}
      {customBenefits?.results
        ?.filter(customBenefit => activeBenefits?.some(activeBenefit => activeBenefit.customBenefitId === customBenefit.id))
        .map(customBenefit => {
          const Icon = CUSTOM_BENEFIT_ICONS[customBenefit.iconName];
          return <Icon key={customBenefit.id} color={inheritColor ? 'inherit' : 'primary'} titleAccess={customBenefit.title} />;
        })}
      {orderedBenefits(tenant)
        ?.filter(
          benefit =>
            benefit !== BenefitDTOBenefitEnum.Custom && upcomingBenefits?.some(upcomingBenefit => upcomingBenefit.benefit === benefit),
        )
        .map(benefit => {
          const Icon = BENEFIT_ICONS[benefit];
          return <Icon key={benefit} color="primaryMuted" titleAccess={t(`common:benefit.${benefit}`)} />;
        })}
      {customBenefits?.results
        ?.filter(customBenefit => upcomingBenefits?.some(upcomingBenefit => upcomingBenefit.customBenefitId === customBenefit.id))
        .map(customBenefit => {
          const Icon = CUSTOM_BENEFIT_ICONS[customBenefit.iconName];
          return <Icon key={customBenefit.id} color="primaryMuted" titleAccess={customBenefit.title} />;
        })}
    </Box>
  );
};
