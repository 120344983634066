import { CustomBenefitDTO } from 'probonio-shared-ui/api';
import { type CUSTOM_BENEFIT_ICONS } from 'probonio-shared-ui/component/icon';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import i18n from '../../../lang';
import {
  CAPITAL_FORMING_BENEFITS_HELP,
  DAYCARE_SUPPORT_HELP,
  EMPLOYEE_ACTIONS_HELP,
  EMPLOYEE_OFFERS_HELP,
  HEALTH_BUDGET_HELP,
  INTERNET_FLAT_RATE_HELP,
  JOB_BIKE_HELP,
  RECOVERY_SUPPORT_HELP,
  URBAN_SPORTS_CLUB_HELP,
} from './customBenefitTemplatesHTML';

export interface CustomBenefitTemplate {
  iconValue: keyof typeof CUSTOM_BENEFIT_ICONS;
  title: string;
  description: string;
  imageUrl: string;
  defaultMonthlyGrant?: number;
  html?: string;
  showAsTile?: boolean;
  showVisualization?: boolean;
  enabled?: boolean;
  relevantForNonCash?: boolean;
  templateKey?: string;
  hasInfoText?: boolean;
}

export enum CustomBenefitTemplateKeys {
  JOB_BIKE = 'JOB_BIKE',
  CUSTOM_BENEFIT = 'CUSTOM_BENEFIT',
  INTERNET_FLAT_RATE = 'INTERNET_FLAT_RATE',
  HEALTH_BUDGET = 'HEALTH_BUDGET',
  URBAN_SPORTS_CLUB = 'URBAN_SPORTS_CLUB',
  EMPLOYEE_OFFERS = 'EMPLOYEE_OFFERS',
  EMPLOYEE_ACTIONS = 'EMPLOYEE_ACTIONS',
  DAYCARE_SUPPORT = 'DAYCARE_SUPPORT',
  CAPITAL_FORMING_BENEFITS = 'CAPITAL_FORMING_BENEFITS',
  RECOVERY_SUPPORT = 'RECOVERY_SUPPORT',
}

export const CustomBenefitTemplates: Map<CustomBenefitTemplateKeys, CustomBenefitTemplate> = new Map<
  CustomBenefitTemplateKeys,
  CustomBenefitTemplate
>([
  [
    CustomBenefitTemplateKeys.JOB_BIKE,
    {
      iconValue: 'DirectionsBike',
      imageUrl: '/image/customBenefits/banner/jobBike.jpg',
      title: i18n.t(`customBenefitsModule:benefits.${CustomBenefitTemplateKeys.JOB_BIKE}.title`),
      description: i18n.t(`customBenefitsModule:benefits.${CustomBenefitTemplateKeys.JOB_BIKE}.description`),
      html: JOB_BIKE_HELP,
      templateKey: CustomBenefitTemplateKeys.JOB_BIKE,
    },
  ],
  [
    CustomBenefitTemplateKeys.INTERNET_FLAT_RATE,
    {
      iconValue: 'Router',
      imageUrl: '/image/customBenefits/banner/Internetzuschuss.jpg',
      title: i18n.t(`customBenefitsModule:benefits.${CustomBenefitTemplateKeys.INTERNET_FLAT_RATE}.title`),
      description: i18n.t(`customBenefitsModule:benefits.${CustomBenefitTemplateKeys.INTERNET_FLAT_RATE}.description`),
      html: INTERNET_FLAT_RATE_HELP,
      templateKey: CustomBenefitTemplateKeys.INTERNET_FLAT_RATE,
    },
  ],
  [
    CustomBenefitTemplateKeys.HEALTH_BUDGET,
    {
      iconValue: 'MedicalServices',
      imageUrl: '/image/customBenefits/banner/Gesundheitsbudget.jpg',
      title: i18n.t(`customBenefitsModule:benefits.${CustomBenefitTemplateKeys.HEALTH_BUDGET}.title`),
      description: i18n.t(`customBenefitsModule:benefits.${CustomBenefitTemplateKeys.HEALTH_BUDGET}.description`),
      hasInfoText: true,
      html: HEALTH_BUDGET_HELP,
      relevantForNonCash: true,
      templateKey: CustomBenefitTemplateKeys.HEALTH_BUDGET,
    },
  ],
  [
    CustomBenefitTemplateKeys.URBAN_SPORTS_CLUB,
    {
      iconValue: 'FitnessCenter',
      imageUrl: '/image/customBenefits/banner/UrbanSportsClub.jpg',
      title: i18n.t(`customBenefitsModule:benefits.${CustomBenefitTemplateKeys.URBAN_SPORTS_CLUB}.title`),
      description: i18n.t(`customBenefitsModule:benefits.${CustomBenefitTemplateKeys.URBAN_SPORTS_CLUB}.description`),
      hasInfoText: true,
      html: URBAN_SPORTS_CLUB_HELP,
      relevantForNonCash: true,
      templateKey: CustomBenefitTemplateKeys.URBAN_SPORTS_CLUB,
    },
  ],
  [
    CustomBenefitTemplateKeys.EMPLOYEE_OFFERS,
    {
      iconValue: 'CardGiftcard',
      imageUrl: '/image/customBenefits/banner/CorporateBenefits.jpg',
      title: i18n.t(`customBenefitsModule:benefits.${CustomBenefitTemplateKeys.EMPLOYEE_OFFERS}.title`),
      description: i18n.t(`customBenefitsModule:benefits.${CustomBenefitTemplateKeys.EMPLOYEE_OFFERS}.description`),
      html: EMPLOYEE_OFFERS_HELP,
      defaultMonthlyGrant: 0,
      showVisualization: false,
      templateKey: CustomBenefitTemplateKeys.EMPLOYEE_OFFERS,
    },
  ],
  [
    CustomBenefitTemplateKeys.EMPLOYEE_ACTIONS,
    {
      iconValue: 'Celebration',
      imageUrl: '/image/customBenefits/banner/Mitarbeiteraktionen.jpg',
      title: i18n.t(`customBenefitsModule:benefits.${CustomBenefitTemplateKeys.EMPLOYEE_ACTIONS}.title`),
      description: i18n.t(`customBenefitsModule:benefits.${CustomBenefitTemplateKeys.EMPLOYEE_ACTIONS}.description`),
      html: EMPLOYEE_ACTIONS_HELP,
      defaultMonthlyGrant: 0,
      showVisualization: false,
      templateKey: CustomBenefitTemplateKeys.EMPLOYEE_ACTIONS,
    },
  ],
  [
    CustomBenefitTemplateKeys.DAYCARE_SUPPORT,
    {
      iconValue: 'BedroomBaby',
      imageUrl: '/image/customBenefits/banner/Kinderbetreuung.jpg',
      title: i18n.t(`customBenefitsModule:benefits.${CustomBenefitTemplateKeys.DAYCARE_SUPPORT}.title`),
      description: i18n.t(`customBenefitsModule:benefits.${CustomBenefitTemplateKeys.DAYCARE_SUPPORT}.description`),
      html: DAYCARE_SUPPORT_HELP,
      templateKey: CustomBenefitTemplateKeys.DAYCARE_SUPPORT,
    },
  ],
  [
    CustomBenefitTemplateKeys.CAPITAL_FORMING_BENEFITS,
    {
      iconValue: 'Euro',
      imageUrl: '/image/customBenefits/banner/VWL.jpg',
      title: i18n.t(`customBenefitsModule:benefits.${CustomBenefitTemplateKeys.CAPITAL_FORMING_BENEFITS}.title`),
      description: i18n.t(`customBenefitsModule:benefits.${CustomBenefitTemplateKeys.CAPITAL_FORMING_BENEFITS}.description`),
      html: CAPITAL_FORMING_BENEFITS_HELP,
      templateKey: CustomBenefitTemplateKeys.CAPITAL_FORMING_BENEFITS,
    },
  ],
  [
    CustomBenefitTemplateKeys.RECOVERY_SUPPORT,
    {
      iconValue: 'BeachAccess',
      imageUrl: '/image/customBenefits/banner/Erholungsbeihilfe.jpg',
      title: i18n.t(`customBenefitsModule:benefits.${CustomBenefitTemplateKeys.RECOVERY_SUPPORT}.title`),
      description: i18n.t(`customBenefitsModule:benefits.${CustomBenefitTemplateKeys.RECOVERY_SUPPORT}.description`),
      html: RECOVERY_SUPPORT_HELP,
      templateKey: CustomBenefitTemplateKeys.RECOVERY_SUPPORT,
    },
  ],
  [
    CustomBenefitTemplateKeys.CUSTOM_BENEFIT,
    {
      imageUrl: '',
      iconValue: 'Star',
      title: i18n.t(`customBenefitsModule:benefits.${CustomBenefitTemplateKeys.CUSTOM_BENEFIT}.title`),
      description: i18n.t(`customBenefitsModule:benefits.${CustomBenefitTemplateKeys.CUSTOM_BENEFIT}.description`),
    },
  ],
]);
export interface CurrentCustomBenefitTemplate {
  customBenefit?: CustomBenefitDTO;
  customBenefitId?: string;
  key?: CustomBenefitTemplateKeys;
  benefit: CustomBenefitTemplate;
}

const emptyTemplate: CurrentCustomBenefitTemplate = {
  key: CustomBenefitTemplateKeys.CUSTOM_BENEFIT,
  benefit: { iconValue: 'Euro', description: '', imageUrl: '', title: '', html: '', defaultMonthlyGrant: 0 },
};

export const useCurrentCustomBenefitTemplate = (): CurrentCustomBenefitTemplate | undefined => {
  const { templateId, customBenefitId } = useParams<{ templateId?: CustomBenefitTemplateKeys; customBenefitId?: string }>();

  const { data: customBenefit, isFetched } = useTenantQuery(
    ['benefits', 'custom', customBenefitId, apis.customBenefits.getCustomBenefit],
    tenantId =>
      apis.customBenefits
        .getCustomBenefit({
          tenantId,
          customBenefitId: customBenefitId!,
        })
        .then(res => res.data),
    {
      enabled: !!customBenefitId,
    },
  );

  return useMemo<CurrentCustomBenefitTemplate | undefined>(() => {
    if (!isFetched && customBenefitId) {
      return undefined;
    }

    const newBenefit = { customBenefitId, ...emptyTemplate, benefit: { ...emptyTemplate.benefit } };

    if (customBenefit) {
      newBenefit.customBenefit = customBenefit;
      newBenefit.customBenefitId = customBenefit.id;
      newBenefit.benefit.enabled = customBenefit.enabled;
      newBenefit.benefit.iconValue = customBenefit.iconName;
      newBenefit.benefit.showAsTile = customBenefit.showTile;
      newBenefit.benefit.showVisualization = customBenefit.showVisualization;
      newBenefit.benefit.description = '';
      newBenefit.benefit.imageUrl = customBenefit.tileImageURL || '';
      newBenefit.benefit.title = customBenefit.title;
      newBenefit.benefit.html = customBenefit.infoHtml;
      newBenefit.benefit.defaultMonthlyGrant = (customBenefit.defaultMonthlyGrant || 0) / 100;
      newBenefit.benefit.relevantForNonCash = customBenefit.relevantForNonCash;
      newBenefit.benefit.templateKey = customBenefit.templateKey || '';
      return newBenefit;
    }

    if (!templateId) {
      return newBenefit;
    }
    const currentCustomBenefitTemplate = CustomBenefitTemplates.get(templateId);

    if (!currentCustomBenefitTemplate) {
      return newBenefit;
    }

    newBenefit.key = templateId;
    newBenefit.benefit = currentCustomBenefitTemplate;
    newBenefit.customBenefitId = undefined;
    return newBenefit;
  }, [customBenefit, customBenefitId, isFetched, templateId]);
};
