import { useTranslation } from 'react-i18next';
import { LocaleEnum } from '../../lang';

export function useLocaleOptions(): { label: React.ReactNode; value: string }[] {
  const { t } = useTranslation('navigationModule');

  return (Object.values(LocaleEnum) as Array<LocaleEnum>).map(value => ({
    value,
    label: (
      <>
        <img height={15} src={`/image/locale/locale_${value}.svg`} alt={`Locale_${value}`} style={{ paddingRight: '0.5em' }} />
        {t(`userEdit.localeSwitch.${value}`)}
      </>
    ),
  }));
}
