import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { Typography, useTheme } from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';
import { CouponGiftDTO, CouponGiftDTOStatusEnum } from 'probonio-shared-ui/api';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SortByDef } from '../../../../component/table/sortMapper';

export function useGiftsColumns(): (MRT_ColumnDef<CouponGiftDTO> & SortByDef)[] {
  const { t } = useTranslation('couponsModule');
  const theme = useTheme();

  return useMemo(
    (): (MRT_ColumnDef<CouponGiftDTO> & SortByDef)[] => [
      {
        accessorKey: 'payoutDate',
        header: t('benefitsModule:userGiftsPage.payoutDate'),
        Cell: ({ cell }) => t('common:date', { date: new Date(cell.getValue<string>()) }),
      },
      {
        accessorKey: 'occasion',
        header: t('benefitsModule:userGiftsPage.occasion'),
        Cell: ({ cell }) => t(`giftOccasions.${cell.getValue<string>()}`),
        enableSorting: false,
      },
      {
        accessorKey: 'recurring',
        header: t('benefitsModule:userGiftsPage.recurring'),
        maxSize: 160,
        Cell: ({ cell }) => (cell.getValue<boolean>() ? t('common:yes') : t('common:no')),
        enableSorting: false,
      },
      {
        accessorKey: 'amount',
        header: t('benefitsModule:userGiftsPage.amount'),
        maxSize: 80,
        Cell: ({ cell, row }) => {
          return <Typography variant="body2">{t('common:money', { money: cell.getValue<number>() })}</Typography>;
        },
      },
      {
        accessorKey: 'status',
        header: t('benefitsModule:userGiftsPage.status'),
        minSize: 0,
        size: 0,
        Cell: ({ cell, row }) => {
          let text: string = '';
          let icon: React.ReactNode = null;

          const status = row.original.status;
          if (status === CouponGiftDTOStatusEnum.Available || status === CouponGiftDTOStatusEnum.Redeemed) {
            text = t('benefitsModule:GIFTS.completed');
            icon = <CheckCircleIcon fontSize="small" color="success" />;
          } else if (status === CouponGiftDTOStatusEnum.Pending) {
            text = t('benefitsModule:GIFTS.paymentPending');
            icon = <AccessTimeFilledIcon fontSize="small" sx={{ color: theme.palette.text.secondary }} />;
          } else if (status === CouponGiftDTOStatusEnum.PaymentFailed) {
            text = t('benefitsModule:GIFTS.paymentFailed');
            icon = <PauseCircleIcon fontSize="small" color="warning" />;
          }

          return (
            <Typography variant="body2" display="flex" gap={0.5}>
              {icon}
              {text}
            </Typography>
          );
        },
        enableSorting: false,
      },
    ],
    [t, theme.palette.text.secondary],
  );
}
