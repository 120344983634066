/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CouponTransactionHistoryDTO
 */
export interface CouponTransactionHistoryDTO {
    /**
     * 
     * @type {string}
     * @memberof CouponTransactionHistoryDTO
     */
    'transactionType': CouponTransactionHistoryDTOTransactionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CouponTransactionHistoryDTO
     */
    'giftOccasion'?: CouponTransactionHistoryDTOGiftOccasionEnum;
    /**
     * 
     * @type {string}
     * @memberof CouponTransactionHistoryDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CouponTransactionHistoryDTO
     */
    'tenantId': string;
    /**
     * 
     * @type {string}
     * @memberof CouponTransactionHistoryDTO
     */
    'transactionDate': string;
    /**
     * 
     * @type {number}
     * @memberof CouponTransactionHistoryDTO
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof CouponTransactionHistoryDTO
     */
    'reference': string;
    /**
     * 
     * @type {string}
     * @memberof CouponTransactionHistoryDTO
     */
    'employeeFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponTransactionHistoryDTO
     */
    'employeeLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponTransactionHistoryDTO
     */
    'employeeMail'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CouponTransactionHistoryDTO
     */
    'employeeInactive'?: boolean;
}

export const CouponTransactionHistoryDTOTransactionTypeEnum = {
    BudgetIncrease: 'BUDGET_INCREASE',
    CouponOrder: 'COUPON_ORDER',
    GiftOrder: 'GIFT_ORDER'
} as const;

export type CouponTransactionHistoryDTOTransactionTypeEnum = typeof CouponTransactionHistoryDTOTransactionTypeEnum[keyof typeof CouponTransactionHistoryDTOTransactionTypeEnum];
export const CouponTransactionHistoryDTOGiftOccasionEnum = {
    Birthday: 'BIRTHDAY',
    SaintsDay: 'SAINTS_DAY',
    ChildBirth: 'CHILD_BIRTH',
    ChildBaptism: 'CHILD_BAPTISM',
    ChildCommunion: 'CHILD_COMMUNION',
    ChildConfirmationCath: 'CHILD_CONFIRMATION_CATH',
    ChildConfirmationProt: 'CHILD_CONFIRMATION_PROT',
    Wedding: 'WEDDING',
    WeddingSilver: 'WEDDING_SILVER',
    WeddingGold: 'WEDDING_GOLD',
    PassedExam: 'PASSED_EXAM',
    CompanyAnniversary: 'COMPANY_ANNIVERSARY',
    Other: 'OTHER'
} as const;

export type CouponTransactionHistoryDTOGiftOccasionEnum = typeof CouponTransactionHistoryDTOGiftOccasionEnum[keyof typeof CouponTransactionHistoryDTOGiftOccasionEnum];


