import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigateWithPrevPath } from '../../../util/useNavigateWithPrevPath';
import { AllBenefitsIncludingComing, BENEFIT_ICONS } from '../BenefitIcon';
import { BenefitButtonRenderer } from './NavBenefitList';
import { useBenefitMenuItems } from '../benefitMenuItems';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';

export const BenefitButton: React.FC<{
  renderer: BenefitButtonRenderer;
  benefit: AllBenefitsIncludingComing;
  active?: boolean;
  selected: boolean;
  onShowFallback?: () => void;
}> = ({ renderer, benefit, active = true, onShowFallback, selected }) => {
  const { t } = useTranslation();
  const navigate = useNavigateWithPrevPath();

  const handleClick = useCallback(() => {
    if (benefit && active) {
      navigate(`/benefits/${benefit.toLowerCase()}`);
    } else if (onShowFallback) {
      onShowFallback();
    }
  }, [benefit, active, navigate, onShowFallback]);

  const benefitMenuItems = useBenefitMenuItems(benefit, undefined, selected && benefit !== BenefitDTOBenefitEnum.Custom);

  const BenefitButton = renderer;
  return (
    <BenefitButton
      selected={selected}
      icon={BENEFIT_ICONS[benefit]}
      title={t(`common:benefit.${benefit}`)}
      onClick={handleClick}
      active={active}
      dataTestId={`${benefit.toLowerCase()}-benefit-button`}
      subMenuItems={benefit !== BenefitDTOBenefitEnum.Custom ? benefitMenuItems : undefined}
    />
  );
};
