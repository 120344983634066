import { TextField, TextFieldProps } from '@mui/material';
import { useMemo } from 'react';
import { FieldPath, FieldValues, useController, UseControllerProps, ValidationRule, ValidationValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type TextFieldControlProps<T extends FieldValues, TName extends FieldPath<T>> = UseControllerProps<T, TName> & TextFieldProps;

const getRuleValue = <T extends ValidationValue>(rule?: ValidationRule<T>): T | undefined =>
  !rule || typeof rule === 'string' || typeof rule === 'number' || typeof rule === 'boolean' || rule instanceof RegExp ? rule : rule.value;

export const TextFieldControl = <T extends FieldValues, TName extends FieldPath<T>>({
  name,
  rules,
  control,
  defaultValue,
  shouldUnregister,
  ...props
}: TextFieldControlProps<T, TName>): JSX.Element => {
  const { t } = useTranslation('errorModule', { keyPrefix: 'form' });
  const { field, fieldState } = useController({ name, rules, control, defaultValue, shouldUnregister });
  const id = useMemo(() => (Math.random() + 1).toString(36).substring(7), []);
  const inputProps = useMemo(
    () => ({
      maxLength: getRuleValue(rules?.maxLength),
      min: getRuleValue(rules?.min),
      max: getRuleValue(rules?.max),
      ...props.slotProps?.htmlInput,
    }),
    [props.slotProps?.htmlInput, rules?.max, rules?.maxLength, rules?.min],
  );
  const errorMessage = useMemo(() => {
    const message = fieldState.error?.message;
    if (fieldState.error?.type === 'min') {
      return message || t('min', { value: getRuleValue(rules?.min) });
    }
    if (fieldState.error?.type === 'max') {
      return message || t('max', { value: getRuleValue(rules?.max) });
    }
    if (fieldState.error?.type === 'pattern') {
      return message || t('pattern');
    }
    if (fieldState.error?.type === 'positive') {
      return message || t('positive');
    }
    if (fieldState.error?.type === 'required') {
      return message || t('required');
    }
    return message;
  }, [fieldState.error, rules, t]);

  return (
    <TextField
      data-test-id="form-control-text-field"
      {...field}
      {...props}
      id={id}
      required={props.required || !!rules?.required}
      slotProps={{
        input: props.slotProps?.input,
        inputLabel: { shrink: true },
        htmlInput: inputProps,
      }}
      error={!!fieldState.error}
      helperText={errorMessage || props.helperText}
    />
  );
};
