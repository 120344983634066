import { createSlice } from '@reduxjs/toolkit';

export interface NavigationState {
  isDrawerOpen: boolean;
}

const initialState: NavigationState = {
  isDrawerOpen: true,
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    toggleDrawer: state => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.isDrawerOpen = !state.isDrawerOpen;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleDrawer } = navigationSlice.actions;

export const navigationReducer = navigationSlice.reducer;
