import { BenefitTemplateItemDTO, UpdateBenefitActivationDTO } from 'probonio-shared-ui/api';
import { useCallback } from 'react';
import { BasicDialog, DialogState } from '../../../component/dialog';
import { useBoundActivationComponent } from '../../benefits/ActivationDialog/useBoundActivationComponent';
import { BenefitProvider } from '../../benefits/BenefitContext';
import { TemplateItemForm } from './TemplateItemForm';
import { useTranslation } from 'react-i18next';

interface Props {
  dialogState: DialogState;
  templateItem?: BenefitTemplateItemDTO;
  onUpdate: (updatedItem: BenefitTemplateItemDTO) => void;
}

export const EditTemplateItemDialog: React.FC<Props> = ({ templateItem, dialogState, onUpdate }) => {
  const { t } = useTranslation('tenantModule');
  const TemplateFormComponent = useBoundActivationComponent(TemplateItemForm, templateItem?.benefit);

  const handleUpdate = useCallback(
    async (update: UpdateBenefitActivationDTO) => {
      onUpdate({
        ...update,
        benefit: templateItem!.benefit,
        customBenefitOptions: update.customBenefitOptions && {
          customBenefitId: templateItem!.customBenefitOptions!.customBenefitId,
          monthlyGrant: update.customBenefitOptions.monthlyGrant || undefined,
          comment: update.customBenefitOptions.comment || undefined,
          supplier: update.customBenefitOptions.supplier || undefined,
          relevantForNonCash: update.customBenefitOptions.relevantForNonCash || undefined,
        },
      });
      dialogState.handleClose();
    },
    [dialogState, onUpdate, templateItem],
  );

  if (!templateItem) {
    return <></>;
  }

  return (
    <BasicDialog
      dialogState={dialogState}
      title={t('benefitTemplates.editItemDialog.title')}
      content={
        <BenefitProvider benefit={templateItem.benefit} customBenefitId={templateItem.customBenefitOptions?.customBenefitId}>
          <TemplateFormComponent templateItem={templateItem} onCancel={dialogState.handleClose} onUpdate={handleUpdate} />
        </BenefitProvider>
      }
    />
  );
};
