import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { type CUSTOM_BENEFIT_ICONS } from 'probonio-shared-ui/component/icon';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { useCallback, useEffect, useMemo } from 'react';
import { SubmitHandler, UseFormReturn, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CurrentCustomBenefitTemplate, useCurrentCustomBenefitTemplate } from './customBenefitTemplates';
import { uploadCustomBenefitImageFile } from './fileUpload';

export interface FormValues {
  showAsTile: boolean;
  showInVisualisation: boolean;
  isActive: boolean;
  html: string | undefined;
  title: string;
  defaultMonthlyGrant: number;
  image?: string | File;
  relevantForNonCash: boolean;
  templateKey: string;
  icon: keyof typeof CUSTOM_BENEFIT_ICONS;
}

export interface CustomBenefitCreatorState {
  handleSubmit: SubmitHandler<FormValues>;
  currentCustomBenefitTemplate?: CurrentCustomBenefitTemplate;
  form: UseFormReturn<FormValues>;
  subtitle: string;
}

const FORM_DEFAULT_VALUES: FormValues = {
  icon: 'Euro',
  showAsTile: true,
  title: '',
  isActive: true,
  image: '',
  html: '',
  defaultMonthlyGrant: 50,
  showInVisualisation: true,
  relevantForNonCash: false,
  templateKey: '',
};

const defaultTo = <T extends unknown>(value: T | undefined, defaultValue: T): T => (value === undefined ? defaultValue : value);

export function useCustomBenefitCreatorState(): CustomBenefitCreatorState | undefined {
  const { t } = useTranslation('customBenefitsModule');
  const currentCustomBenefitTemplate = useCurrentCustomBenefitTemplate();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const getTenantId = useTenantID();
  const queryClient = useQueryClient();

  const form = useForm<FormValues>({
    defaultValues: FORM_DEFAULT_VALUES,
    mode: 'all',
  });

  useEffect(() => {
    if (currentCustomBenefitTemplate) {
      form.reset({
        icon: currentCustomBenefitTemplate.benefit.iconValue,
        showAsTile: defaultTo(currentCustomBenefitTemplate.benefit.showAsTile, FORM_DEFAULT_VALUES.showAsTile),
        title: currentCustomBenefitTemplate.benefit.title,
        isActive: defaultTo(currentCustomBenefitTemplate.benefit.enabled, FORM_DEFAULT_VALUES.isActive),
        image: currentCustomBenefitTemplate.benefit.imageUrl,
        html: currentCustomBenefitTemplate.benefit.html || '',
        defaultMonthlyGrant: defaultTo(currentCustomBenefitTemplate.benefit.defaultMonthlyGrant, FORM_DEFAULT_VALUES.defaultMonthlyGrant),
        showInVisualisation: defaultTo(currentCustomBenefitTemplate.benefit.showVisualization, FORM_DEFAULT_VALUES.showInVisualisation),
        relevantForNonCash: currentCustomBenefitTemplate.benefit.relevantForNonCash,
        templateKey: currentCustomBenefitTemplate.benefit.templateKey,
      });
    }
  }, [currentCustomBenefitTemplate, form]);

  const createCustomBenefitMutation = useMutation({
    mutationFn: async (values: FormValues) => {
      const tenantId = getTenantId();
      let blob: Blob;
      let type: string;

      let imageKey;
      if (values.image) {
        if (typeof values.image === 'string') {
          // template image
          blob = await fetch(values.image).then(res => res.blob());
          type = blob.type;
        } else {
          // uploaded user image
          blob = values.image;
          type = values.image.type;
        }

        imageKey = await uploadCustomBenefitImageFile(tenantId, type, blob);
      }

      const { id: customBenefitId } = await apis.customBenefits
        .createCustomBenefit({
          tenantId,
          createCustomBenefitDTO: {
            iconName: values.icon,
            infoHtml: values.html || '',
            tileImageKey: imageKey || '',
            title: values.title,
            defaultMonthlyGrant: Math.floor(values.defaultMonthlyGrant * 100),
            showTile: values.showAsTile,
            showVisualization: values.showInVisualisation,
            enabled: values.isActive,
            relevantForNonCash: values.relevantForNonCash,
            templateKey: values.templateKey,
          },
        })
        .then(res => res.data);
      return customBenefitId;
    },

    onSuccess: async customBenefitId => {
      await queryClient.invalidateQueries({ queryKey: ['tenants', getTenantId(), 'benefits', 'custom'] });
      enqueueSnackbar(t('customBenefitCreator.successCreate'), { variant: 'success' });
      navigate(`/benefits/custom/${customBenefitId}`);
    },
  });

  const updateCustomBenefitMutation = useMutation({
    mutationFn: async (values: FormValues) => {
      if (!currentCustomBenefitTemplate?.customBenefitId) {
        return;
      }

      const tenantId = getTenantId();

      let imageKey: undefined | string;
      // to avoid unnecessary image uploads
      if (values.image && typeof values.image !== 'string') {
        imageKey = await uploadCustomBenefitImageFile(tenantId, values.image.type, values.image);
      }

      await apis.customBenefits.updateCustomBenefit({
        tenantId,
        customBenefitId: currentCustomBenefitTemplate.customBenefitId,

        updateCustomBenefitDTO: {
          iconName: values.icon,
          infoHtml: values.html || '',
          tileImageKey: imageKey,
          title: values.title,
          defaultMonthlyGrant: Math.floor(values.defaultMonthlyGrant * 100),
          showTile: values.showAsTile,
          showVisualization: values.showInVisualisation,
          enabled: values.isActive,
          relevantForNonCash: values.relevantForNonCash,
        },
      });
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['tenants', getTenantId(), 'benefits', 'custom'] });
      enqueueSnackbar(t('customBenefitCreator.successUpdate'), { variant: 'success' });
      navigate(`/benefits/custom/${currentCustomBenefitTemplate!.customBenefitId}`);
    },
  });

  const handleSubmit: SubmitHandler<FormValues> = useCallback(
    async values => {
      // because templates have no id so it has to be a customBenefit from DB
      if (currentCustomBenefitTemplate?.customBenefitId) {
        await updateCustomBenefitMutation.mutateAsync(values).catch(() => void 0);
      } else {
        await createCustomBenefitMutation.mutateAsync(values).catch(() => void 0);
      }
    },
    [createCustomBenefitMutation, currentCustomBenefitTemplate?.customBenefitId, updateCustomBenefitMutation],
  );

  return useMemo(() => {
    if (!currentCustomBenefitTemplate) {
      return undefined;
    }

    return {
      handleSubmit,
      currentCustomBenefitTemplate,
      form,
      subtitle: t('customBenefitCreator.subTitle'),
    };
  }, [handleSubmit, currentCustomBenefitTemplate, form, t]);
}
