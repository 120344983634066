import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';

export interface TabsState<T extends number | string = number | string> {
  tabIndex: T;
  handleTabChange: (event: React.SyntheticEvent | undefined, value: T) => void;
}

export function useTabsState(): TabsState<number> {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent | undefined, newTabIndex: number) => {
      setTabIndex(newTabIndex);
    },
    [setTabIndex],
  );

  return useMemo(() => ({ tabIndex, handleTabChange }), [handleTabChange, tabIndex]);
}

export function useReduxTabsState(selector: (state: RootState) => number, action: ActionCreatorWithPayload<number>): TabsState<number> {
  const tabIndex = useAppSelector(selector);
  const dispatch = useAppDispatch();

  return useMemo(
    (): TabsState<number> => ({
      tabIndex,
      handleTabChange: (event, value) => {
        dispatch(action(value));
      },
    }),
    [action, dispatch, tabIndex],
  );
}

export function useRouterTabsState(urlPrefix: string, paramName: string, defaultTab: string = ''): TabsState<string> {
  const navigate = useNavigate();
  const tabName = useParams()[paramName];

  return useMemo(
    (): TabsState<string> => ({
      tabIndex: tabName || defaultTab,
      handleTabChange: (event, value) => {
        navigate(value ? `${urlPrefix}/${value}` : urlPrefix);
      },
    }),
    [tabName, defaultTab, navigate, urlPrefix],
  );
}

export function useSearchParamsTabsState(paramName: string, replace?: boolean): TabsState<string> {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tabName = searchParams.get(paramName);
  const location = useLocation();
  return useMemo(
    (): TabsState<string> => ({
      tabIndex: tabName || '',
      handleTabChange: (event, value) => {
        navigate(value ? `${location.pathname}?${paramName}=${value}` : location.pathname, { replace });
      },
    }),
    [tabName, navigate, location.pathname, paramName, replace],
  );
}
