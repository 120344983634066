import Grid from '@mui/material/Grid2';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useCallback, useMemo, useState } from 'react';
import { useNewDialogState } from '../../../component/dialog';
import { useNavigateWithPrevPath } from '../../../util/useNavigateWithPrevPath';
import { CustomBenefitItem } from './CustomBenefitItem';
import { CustomBenefitTemplate, CustomBenefitTemplateKeys, CustomBenefitTemplates } from './customBenefitTemplates';
import { CustomBenefitCreateModal } from './SelectCustomBenefitDialog';

export const CustomBenefitList: React.FC = () => {
  const [customBenefit, setCustomBenefit] = useState(CustomBenefitTemplates.get(CustomBenefitTemplateKeys.CUSTOM_BENEFIT));
  const [customBenefitKey, setCustomBenefitKey] = useState(CustomBenefitTemplateKeys.CUSTOM_BENEFIT);
  const dialogState = useNewDialogState();
  const navigate = useNavigateWithPrevPath();
  const { data: customBenefits } = useTenantQuery(['benefits', 'custom', apis.customBenefits.listCustomBenefits], tenantId =>
    apis.customBenefits
      .listCustomBenefits({
        tenantId,
      })
      .then(res => res.data),
  );

  const getCustomBenefitActivation = useCallback(
    (templateKey: string) => {
      return customBenefits?.results.find(customBenefit => {
        return customBenefit.templateKey === templateKey;
      });
    },
    [customBenefits?.results],
  );

  const handleClickBenefitItem = useCallback(
    (customBenefit: CustomBenefitTemplate, customBenefitKey: CustomBenefitTemplateKeys) => {
      setCustomBenefit(customBenefit);
      setCustomBenefitKey(customBenefitKey);
      const selectedCustomBenefitActivation = getCustomBenefitActivation(customBenefitKey);

      if (selectedCustomBenefitActivation) {
        navigate(`/benefits/custom/${selectedCustomBenefitActivation?.id}/edit`, { state: { forceHistoryBack: true } });
      } else {
        dialogState.handleOpen();
      }
    },
    [dialogState, navigate, getCustomBenefitActivation],
  );

  const sortBenefitItems = useMemo(() => {
    const preferredSorting = customBenefits?.results.map(customBenefit => {
      return customBenefit.templateKey;
    });
    if (preferredSorting) {
      preferredSorting.reverse();
      return Array.from(CustomBenefitTemplates).sort((a, b) => preferredSorting.indexOf(b[0]) - preferredSorting.indexOf(a[0]));
    }
  }, [customBenefits?.results]);

  return (
    <Grid container columns={14} spacing={3} justifyContent="center">
      {sortBenefitItems?.map(([key, benefit], i) => {
        const isConfigured = !!getCustomBenefitActivation(key);
        return (
          <Grid key={key + i} size={7}>
            <CustomBenefitItem
              customBenefit={benefit}
              key={key}
              customBenefitKey={key}
              onClick={handleClickBenefitItem}
              isConfigured={isConfigured}
            />
          </Grid>
        );
      })}

      {customBenefit && customBenefitKey && !getCustomBenefitActivation(customBenefitKey) ? (
        <CustomBenefitCreateModal dialogState={dialogState.dialogState} customBenefit={customBenefit} customBenefitKey={customBenefitKey} />
      ) : (
        <></>
      )}
    </Grid>
  );
};
