import { useTranslation } from 'react-i18next';
import { ReactChart } from '../../../component/chart';
import { colors } from '../../../theme';
import { useEmployeeList } from '../../userManagement/useEmployeeList';
import { useMemo, useState } from 'react';
import { ChartArea, ChartConfiguration } from 'chart.js';
import { Box, Typography } from '@mui/material';
import { UserDTOStatusEnum } from 'probonio-shared-ui/api';

const STATUS_ORDER = ['ACTIVE', 'PENDING', 'REGISTERED', 'CREATED', 'INVITATION_CODE', 'EXPIRED', 'MAIL_DELIVERY_ERROR', 'INACTIVE'];

export const EmployeeStatusChart: React.FC = () => {
  const { t } = useTranslation('dashboardModule');
  const { data: employeeListResult } = useEmployeeList({ aggregations: ['status'], page: 0, pageSize: 0 }, { staleTime: Infinity });
  const [chartArea, setChartArea] = useState<ChartArea>();

  const data = useMemo(
    () => ({
      labels: STATUS_ORDER.map(status => t(`common:userStatus.${status}`)),
      datasets: [
        {
          label: t('employeesPanel.legendLabel'),
          data: STATUS_ORDER.map(
            status => employeeListResult?.aggregations?.status?.find(bucket => bucket.bucketKey === status)?.value || 0,
          ),
          backgroundColor: [
            colors.success,
            `${colors.success}7F`,
            colors.warning,
            `${colors.warning}7F`,
            `${colors.warning}4F`,
            `${colors.error}7F`,
            colors.error,
            '#888',
          ],
          hoverOffset: 4,
        },
      ],
    }),
    [employeeListResult?.aggregations?.status, t],
  );

  const config = useMemo(
    (): ChartConfiguration<'doughnut', number[]> => ({
      type: 'doughnut',
      data: data,
      plugins: [
        {
          id: 'test',
          beforeDraw: chart => {
            setChartArea({
              ...chart.chartArea,
              left: chart.chartArea.left + chart.canvas.offsetLeft,
              right: chart.chartArea.right + chart.canvas.offsetLeft,
              top: chart.chartArea.top + chart.canvas.offsetTop,
              bottom: chart.chartArea.bottom + chart.canvas.offsetTop,
            });
          },
        },
      ],
      options: {
        cutout: '60%',
        rotation: -90,
        circumference: 180,
        aspectRatio: 2.75,
        responsive: true,
        plugins: {
          legend: {
            onHover: event => {
              if (event.native?.target instanceof HTMLElement) {
                event.native.target.style.cursor = 'pointer';
              }
            },
            onLeave: event => {
              if (event.native?.target instanceof HTMLElement) {
                event.native.target.style.cursor = 'default';
              }
            },
            position: 'left',
            labels: {
              boxWidth: 10,
              boxHeight: 10,
              pointStyle: 'circle',
              usePointStyle: true,
              filter: (legendItem, data) => data.datasets[0].data[legendItem.index!] !== 0,
            },
          },
        },
      },
    }),
    [data],
  );

  return (
    <ReactChart config={config} sx={{ height: '100%', width: '100%' }}>
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-end',
          left: chartArea?.left,
          top: chartArea?.top,
          width: chartArea && chartArea.right - chartArea.left,
          height: chartArea && chartArea.bottom - chartArea.top,
          paddingBottom: theme => theme.spacing(4),
        }}
      >
        <Typography component="div" color="text.secondary">
          {t('employeesPanel.activeEmployeesCount', {
            count: employeeListResult?.aggregations?.status?.find(bucket => bucket.bucketKey === UserDTOStatusEnum.Active)?.value || 0,
          })}
        </Typography>
        <Typography component="div" color="success.main">
          {t('employeesPanel.activeStatusText')}
        </Typography>
      </Box>
    </ReactChart>
  );
};
