import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table';

export interface SortByDef {
  sortBy?: string[];
}

/**
 * Maps a MRT_TableState `sorting` to the format expected by the API. The field name(s) passed to the
 * API can be overridden by setting the property `sortBy` on the column definition. If unset, the
 * accessorKey is used.
 *
 * @example [{ field: 'name', sort: 'asc' }] => ['lastName:asc', 'firstName:asc']
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapSortModel<T extends Record<string, any>>(
  columns: readonly (MRT_ColumnDef<T> & SortByDef)[],
  sortModel: MRT_SortingState,
): string[] {
  return sortModel.reduce<string[]>((agg, sortDef) => {
    const columnDef = columns.find(col => col.id === sortDef.id || col.accessorKey === sortDef.id);
    const sortFields = columnDef?.sortBy || [columnDef!.accessorKey as string];
    return [...agg, ...sortFields.map(sortField => `${sortField}:${sortDef.desc ? 'desc' : 'asc'}`)];
  }, []);
}
