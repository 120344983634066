import { Box, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

interface Props {
  title: React.ReactNode;
  info: React.ReactNode;
  fullWidth?: boolean;
  descriptionWidth?: number;
  settingsWidth?: number;
  hideDivider?: boolean;
}

export const SettingsFormRow: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  info,
  fullWidth,
  children,
  descriptionWidth,
  settingsWidth,
  hideDivider,
}) => {
  return (
    <>
      {hideDivider !== true && <Divider sx={{ marginY: 3 }} />}
      <Grid container>
        <Grid size={descriptionWidth ?? 6} paddingX={3}>
          <Typography variant="h3" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {info}
          </Typography>
        </Grid>
        <Grid
          size={{
            xl: fullWidth ? undefined : (settingsWidth ?? 4),
            lg: fullWidth ? undefined : (settingsWidth ?? 5),
            xs: settingsWidth ?? 6,
          }}
          paddingRight={3}
          display="flex"
          alignItems="stretch"
        >
          <Divider orientation="vertical" sx={{ marginRight: 3, height: 'auto' }} />
          <Box flexGrow={1}>{children}</Box>
        </Grid>
      </Grid>
    </>
  );
};
