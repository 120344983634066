import { Alert, Paper } from '@mui/material';
import { DateTime, Interval } from 'luxon';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useTranslation } from 'react-i18next';
import { ExportDate } from '../../../page/benefit/BenefitPayrollReportPage';
import { isDate } from './isDate';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
interface Props {
  exportDate: ExportDate;
  showReceiptsWarning?: boolean;
}

export const PayrollExportAlerts: React.FC<Props> = ({ exportDate, showReceiptsWarning }) => {
  const { t } = useTranslation('receiptsModule');
  const { tenant } = useTenant();

  const isPeriodGoing = isDate(exportDate)
    ? Interval.fromDateTimes(DateTime.fromJSDate(exportDate).startOf('month'), DateTime.fromJSDate(exportDate).endOf('month')).contains(
        DateTime.now(),
      )
    : false;

  const showReceiptUploadWarning = isDate(exportDate)
    ? tenant &&
      !!tenant.lunchPeriodCloseDelay &&
      DateTime.utc().day <= tenant.lunchPeriodCloseDelay &&
      DateTime.fromJSDate(exportDate).plus({ month: 1 }).month === DateTime.utc().month
    : false;

  const showCouponDeadlineWarning =
    tenant?.activeBenefits?.includes(BenefitDTOBenefitEnum.Coupons) &&
    !!tenant.couponOrderDeadline &&
    tenant?.activeBenefits?.includes(BenefitDTOBenefitEnum.Flex);

  return (
    <>
      {showCouponDeadlineWarning && (
        <Paper elevation={0} sx={{ marginBottom: 1.5 }}>
          <Alert severity="warning">{t('benefitsModule:report.couponDeadlineWarning')}</Alert>
        </Paper>
      )}
      {showReceiptsWarning && !isPeriodGoing && (
        <Paper elevation={0} sx={{ marginBottom: 1.5 }}>
          <Alert severity="warning">{t('receiptsNotAllCheckedWarning')}</Alert>
        </Paper>
      )}
      {isPeriodGoing && !showReceiptUploadWarning && (
        <Paper elevation={0} sx={{ marginBottom: 1.5 }}>
          <Alert severity="warning">
            {t('periodNotClosed', {
              // isPeriodGoing is false if it is not a date -> 'as Date'
              val: DateTime.fromJSDate(exportDate as Date)
                .endOf('month')
                .toJSDate(),
            })}
          </Alert>
        </Paper>
      )}
      {showReceiptUploadWarning && (
        <Paper elevation={0} sx={{ marginBottom: 1.5 }}>
          <Alert severity="warning">
            {t('periodNotClosedReceipts', {
              periodCloseDay: tenant?.lunchPeriodCloseDelay,
            })}
          </Alert>
        </Paper>
      )}
    </>
  );
};
