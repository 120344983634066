import {
  BenefitActivationDTO,
  BenefitDTOBenefitEnum,
  BenefitTemplateItemDTO,
  CreateBenefitActivationDTO,
  UpdateBenefitActivationDTO,
  UpdateTenantDTO,
} from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import React, { useCallback, useMemo } from 'react';
import { ActivationDialogExtensions } from '../ActivationDialog';

interface NewsBenefitOptions {}

const Fields: React.FC = () => {
  return <></>;
};

export function useNewsDialogExtensions(): ActivationDialogExtensions<NewsBenefitOptions> {
  const { tenant, invalidateTenant } = useTenant();
  const handleResetBenefitOptions = useCallback(
    (selectedActivation?: BenefitActivationDTO | BenefitTemplateItemDTO): NewsBenefitOptions => {
      return {};
    },
    [],
  );

  const handleCreateActivation = useCallback<ActivationDialogExtensions<NewsBenefitOptions>['onCreateActivation']>(
    async (baseValues, benefitOptions, saveActivation) => {
      const create: CreateBenefitActivationDTO = {
        ...baseValues,
        benefit: BenefitDTOBenefitEnum.News,
      };
      await saveActivation(create);
      const update: Partial<UpdateTenantDTO> = {};
      if (Object.keys(update).length > 0) {
        await apis.tenants.updateTenant({ tenantId: tenant!.id, updateTenantDTO: update });
        invalidateTenant();
      }
    },
    [invalidateTenant, tenant],
  );
  const handleUpdateActivation = useCallback<NonNullable<ActivationDialogExtensions<NewsBenefitOptions>['onUpdateActivation']>>(
    async (baseValues, benefitOptions, saveActivation) => {
      const update: UpdateBenefitActivationDTO = {
        ...baseValues,
      };
      await saveActivation(update);
    },
    [],
  );
  return useMemo(
    () => ({
      Fields,
      onResetBenefitOptions: handleResetBenefitOptions,
      onCreateActivation: handleCreateActivation,
      onUpdateActivation: handleUpdateActivation,
    }),
    [handleCreateActivation, handleResetBenefitOptions, handleUpdateActivation],
  );
}
