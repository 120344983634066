import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { BulkCreateBenefitActivationsDTO, CreateBenefitActivationDTO, FilterEmployeesDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RowSelectionState } from '../../../component/table/useManualRowSelection';
import { useRefetchEmployees } from '../../userManagement/query';
import { ActivationDialogSaveFunctions } from '../ActivationDialog';
import { useRefetchActivations } from '../query';
import { EmployeeFilter, mapEmployeeFilterToRequest } from './employeeFilterState';

export function useBulkActivationSaveFunctions(
  rowSelectionState: RowSelectionState,
  filter: EmployeeFilter | undefined,
): ActivationDialogSaveFunctions {
  const { t } = useTranslation('benefitsModule');
  const getTenantId = useTenantID();
  const refetchEmployees = useRefetchEmployees();
  const refetchActivations = useRefetchActivations();
  const { enqueueSnackbar } = useSnackbar();

  const bulkActivateBenefitMutation = useMutation({
    mutationFn: (params: BulkCreateBenefitActivationsDTO) =>
      apis.benefits.createBenefitActivations({ tenantId: getTenantId(), bulkCreateBenefitActivationsDTO: params }),

    onSuccess: async () => {
      await refetchEmployees();
      await refetchActivations();
    },
  });

  const handleCreateActivation = useCallback(
    async (benefitActivation: CreateBenefitActivationDTO) => {
      let employeeFilter: FilterEmployeesDTO | undefined;
      let employeeIds: string[] | undefined;
      if (rowSelectionState.allSelected) {
        employeeFilter = {
          ...mapEmployeeFilterToRequest(filter),
          considerFutureActivations: true,
          inactiveBenefit: benefitActivation.benefit,
          inactiveCustomBenefitId: benefitActivation.customBenefitOptions?.customBenefitId,
          excludeEmployeeStatus: ['INACTIVE'],
        };
      } else {
        employeeIds = Object.keys(rowSelectionState.rowSelection).filter(id => rowSelectionState.rowSelection[id]);
      }
      const { data: benefitActivationResponse } = await bulkActivateBenefitMutation.mutateAsync({
        employeeFilter,
        employeeIds,
        benefitActivation,
      });
      const successResults = benefitActivationResponse.results.filter(result => result.status === 'SUCCESS');
      const errorResults = benefitActivationResponse.results.filter(result => result.status === 'ERROR');
      if (benefitActivationResponse.results.length === 0) {
        enqueueSnackbar(t('bulkActivation.noNewActivationsDone'), { variant: 'warning' });
      } else if (errorResults.length && successResults.length) {
        enqueueSnackbar(
          t('bulkActivation.newActivationsSomeErrors', { successCount: successResults.length, errorCount: errorResults.length }),
          { variant: 'warning' },
        );
      } else if (errorResults.length) {
        enqueueSnackbar(t('bulkActivation.newActivationsError', { count: errorResults.length }), { variant: 'error' });
      } else {
        enqueueSnackbar(t('bulkActivation.newActivationsSuccess', { count: successResults.length }), { variant: 'success' });
      }

      if (successResults.length) {
        rowSelectionState.handleReset();
      }
    },
    [bulkActivateBenefitMutation, enqueueSnackbar, filter, rowSelectionState, t],
  );

  return useMemo(
    () => ({
      onCreateActivation: handleCreateActivation,
    }),
    [handleCreateActivation],
  );
}
