import { Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table';
import { InternetReceiptTenantDTO } from 'probonio-shared-ui/api';
import { apis, downloadResponse } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewDialogState } from '../../../component/dialog';
import { InfiniteScrollReactTable } from '../../../component/table/InfiniteScrollReactTable';
import { mapSortModel, SortByDef } from '../../../component/table/sortMapper';
import { useLoadMore } from '../../../component/table/useLoadMore';
import { ReportFilterSelector, useReportFilterState } from '../report';
import ReportDownloadDialog from '../ReportDownloadDialog/ReportDownloadDialog';
import { InternetReceiptActions } from './InternetReceiptActions';
import { useInternetReceiptsQuery } from './useInternetReceiptsQuery';

export const InternetReceiptList: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const getTenantId = useTenantID();
  const reportFilterState = useReportFilterState();
  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'name', desc: false }]);

  const COLUMNS: (MRT_ColumnDef<InternetReceiptTenantDTO> & SortByDef)[] = useMemo(
    () => [
      {
        id: 'name',
        accessorFn: receipt => {
          const user = receipt.employee.user;
          if (!user) {
            return '';
          }
          if (user.firstName || user.lastName) {
            return `${user.lastName}${user.firstName && user.lastName && ', '}${user.firstName}`;
          }
          return user.mail || '';
        },

        sortBy: ['employee.user.lastName', 'employee.user.firstName', 'employee.user.mail'],
        header: t('receipts.from'),
        enableGrouping: false,
      },
      {
        accessorKey: 'completedDate',
        header: t('receipts.completedDate'),
        enableGrouping: false,
        Cell: ({ cell }) => t('common:date', { date: new Date(cell.getValue<string>()) }),
        sortBy: ['completedDate'],
      },
      {
        accessorKey: 'expirationDate',
        header: t('receipts.expirationDate'),
        enableGrouping: false,
        Cell: ({ cell }) => t('common:date', { date: new Date(cell.getValue<string>()) }),
        sortBy: ['expirationDate'],
      },

      {
        id: 'refund',
        accessorFn: receipt => receipt.refund,
        header: t('receipts.refund'),
        enableSorting: false,
        enableGrouping: false,
        Cell: ({ cell }) => t('common:money', { money: cell.getValue() }),
        AggregatedCell: ({ cell }) => <b>{t('common:money', { money: cell.getValue() })}</b>,
      },
    ],
    [t],
  );

  const queryResult = useInternetReceiptsQuery(reportFilterState.filter, mapSortModel(COLUMNS, sorting));

  const loadMoreProps = useLoadMore(queryResult, 'receipts');

  const exportDialog = useNewDialogState();

  const exportMutation = useMutation({
    mutationFn: (month?: DateTime) =>
      apis.internet
        .getReceiptDetailsExport({
          tenantId: getTenantId(),
          month: (month || DateTime.now()).toFormat('yyyy-MM'),
        })
        .then(res => {
          const defaultFileName = `InternetReceiptDetails_${(month || DateTime.now()).toFormat('yyyy-MM')}.csv`;
          downloadResponse(res, defaultFileName);
        }),
    onSuccess: () => exportDialog.dialogState.handleClose(),
  });

  return (
    <>
      <Box marginBottom={1.5}>
        <ReportFilterSelector filterState={reportFilterState} exportMutation={exportMutation} />
      </Box>
      <InfiniteScrollReactTable
        {...loadMoreProps}
        columns={COLUMNS}
        enablePagination={false}
        enableBottomToolbar={false}
        enableTopToolbar={false}
        enableColumnActions={false}
        enableSortingRemoval={false}
        manualSorting
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={InternetReceiptActions}
        initialState={{
          expanded: true, //expand all groups by default
        }}
        onSortingChange={setSorting}
        state={{ sorting }}
      />
      <ReportDownloadDialog
        message={t('report.reportDialogMessage')}
        loading={exportMutation.isPending}
        dialogState={exportDialog.dialogState}
        handleConfirm={exportMutation.mutate}
        initialMonth={reportFilterState.filter.month}
      />
    </>
  );
};
