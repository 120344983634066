import { Box, Step, StepButton, StepButtonProps, StepContextType, Stepper, useStepContext } from '@mui/material';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SepaMandateComplete } from './SepaMandateComplete';
import { SepaMandateIBANForm } from './SepaMandateIBANForm';
import { SepaMandateUpload } from './SepaMandateUpload';

const CompleteStepButton: React.FC<StepButtonProps> = props => {
  const { completed } = useStepContext() as StepContextType;
  return <StepButton {...props} disabled={!completed} />;
};

export const SepaMandateStepper: React.FC = () => {
  const { t } = useTranslation('tenantModule');
  const { data: sepa } = useTenantQuery(['sepa', apis.sepa.getTenantSepaConfig.name], tenantId =>
    apis.sepa.getTenantSepaConfig({ tenantId }).then(res => res.data),
  );
  const [activeStep, setActiveStep] = useState<number>();

  useEffect(() => {
    if (!sepa) {
      return;
    }
    if (sepa.sepa && (sepa.sepaMandate || (sepa.sepaMandateURL && sepa.sepaConfirmationURL))) {
      setActiveStep(step => (step === undefined ? 3 : step));
    } else if (sepa.sepa && sepa.iban && sepa.sepaMandateURL) {
      setActiveStep(step => (step === undefined ? 2 : step));
    } else if (sepa.sepa && sepa.iban) {
      setActiveStep(step => (step === undefined ? 1 : step));
    } else {
      setActiveStep(0);
    }
  }, [sepa]);

  const handleBack = useCallback(() => {
    setActiveStep(step => (step || 0) - 1);
  }, []);
  const handleNext = useCallback(() => {
    setActiveStep(step => (step || 0) + 1);
  }, []);
  const handleStep = useCallback(
    (step: number) => () => {
      setActiveStep(step);
    },
    [],
  );

  return (
    <>
      <Stepper nonLinear activeStep={activeStep ?? 0} sx={{ marginBottom: 3 }}>
        <Step completed={sepa?.sepa && !!sepa?.iban}>
          <CompleteStepButton onClick={handleStep(0)}>{t('paymentMethods.sepaStepper.step.bankInfo.title')}</CompleteStepButton>
        </Step>
        <Step completed={sepa?.sepa && !!sepa?.iban && !!sepa?.sepaMandateURL}>
          <CompleteStepButton onClick={handleStep(1)}>{t('paymentMethods.sepaStepper.step.uploadMandate.title')}</CompleteStepButton>
        </Step>
        <Step completed={sepa?.sepa && !!sepa?.iban && !!sepa?.sepaMandateURL && !!sepa?.sepaConfirmationURL}>
          <CompleteStepButton onClick={handleStep(2)}>{t('paymentMethods.sepaStepper.step.uploadConfirmation.title')}</CompleteStepButton>
        </Step>
        <Step completed={sepa?.sepa && !!sepa?.sepaMandate}>
          <CompleteStepButton onClick={handleStep(3)}>{t('paymentMethods.sepaStepper.step.complete.title')}</CompleteStepButton>
        </Step>
      </Stepper>
      <Box marginLeft={3}>
        {(!activeStep || activeStep === 0) && <SepaMandateIBANForm onNext={handleNext} />}
        {activeStep === 1 && <SepaMandateUpload sepa={sepa} type="uploadMandate" onBack={handleBack} onNext={handleNext} />}
        {activeStep === 2 && <SepaMandateUpload sepa={sepa} type="uploadConfirmation" onBack={handleBack} onNext={handleNext} />}
        {activeStep === 3 && <SepaMandateComplete onBack={handleBack} />}
      </Box>
    </>
  );
};
