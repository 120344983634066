import { Box } from '@mui/material';

interface Props {
  columns?: number;
  rowHeight?: number | string;
  gap?: number;
}

export const DashboardContainer: React.FC<React.PropsWithChildren<Props>> = ({ columns = 2, rowHeight = 100, gap, children }) => {
  return (
    <Box display="grid" gridTemplateColumns={`repeat(${columns}, 1fr)`} gridAutoRows={rowHeight} gap={gap}>
      {children}
    </Box>
  );
};
