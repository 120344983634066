import { InfiniteData, UseInfiniteQueryResult, useInfiniteQuery } from '@tanstack/react-query';
import { BikeleasingApiGetBikeleasingContractsRequest, BikeleasingContractsListDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';

const PAGE_SIZE = 15;

export function useInfiniteBikeleasingContracts(
  parameters?: Partial<BikeleasingApiGetBikeleasingContractsRequest>,
): UseInfiniteQueryResult<InfiniteData<BikeleasingContractsListDTO, Error>> {
  const getTenantId = useTenantID();

  return useInfiniteQuery({
    queryKey: ['tenants', getTenantId(), 'benefits', 'bikeleasing', 'contract', parameters, apis.bike.listTenantContracts.name],
    queryFn: ({ pageParam = 0 }) =>
      apis.bikeLeasing
        .getBikeleasingContracts({
          tenantId: getTenantId(),
          page: pageParam,
          pageSize: PAGE_SIZE,
          ...parameters,
        })
        .then(res => res.data),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => (pages.length * PAGE_SIZE < lastPage.totalCount ? pages.length : undefined),
    placeholderData: previousData => previousData,
  });
}
