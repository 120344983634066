import AutorenewIcon from '@mui/icons-material/Autorenew';
import AddIcon from '@mui/icons-material/Add';

import { Button, Divider, Typography, useTheme } from '@mui/material';
import { TenantDTO } from 'probonio-shared-ui/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogState } from '../../../../component/dialog';
import { HorizontalBox, VerticalBox } from '../../../../component/layout/BoxLayout';

interface Props {
  tenant: TenantDTO | undefined;
  increaseBudgetDialogState: {
    dialogState: DialogState;
    handleOpen: () => void;
  };
}

export const CouponBudgetTotalCredit: React.FC<Props> = ({ tenant, increaseBudgetDialogState }) => {
  const theme = useTheme();
  const { t } = useTranslation('couponsModule');

  return (
    <HorizontalBox gap={2.5} alignItems="center" flexGrow={1}>
      <VerticalBox textAlign="center" flex={1}>
        <Typography lineHeight="inherit" variant="h1">
          {t('common:money', { money: tenant?.couponBudget || 0 })}
        </Typography>
        {tenant?.enableAutomatedBudgetOrders && (
          <Typography
            variant="body2"
            color={theme.palette.success.main}
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={0.3}
          >
            <AutorenewIcon sx={{ display: 'inline' }} fontSize="small" /> {t('budgetPanel.automaticTopUpActive')}
          </Typography>
        )}
      </VerticalBox>
      <Divider orientation="vertical" flexItem />
      <Button
        startIcon={<AddIcon />}
        color="primary"
        variant="contained"
        size="small"
        onClick={increaseBudgetDialogState.handleOpen}
        data-test-id="order-budget-button"
      >
        {t('budgetPanel.orderButton')}
      </Button>
    </HorizontalBox>
  );
};
