import { UseQueryResult } from '@tanstack/react-query';
import {
  CalculatorNetResultDTO,
  CalculatorResultDTO,
  SalaryCalculatorApiCalculateGrossValueRequest,
  SalaryCalculatorApiGetFromCalculatorRequest,
} from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';

export function useCalculator(
  parameters: Partial<SalaryCalculatorApiGetFromCalculatorRequest>,
  options?: { enabled?: boolean },
): UseQueryResult<CalculatorResultDTO, Error> {
  return useTenantQuery(
    ['grossNetCalculator', 'calculateNetValue', parameters, apis.salaryCalculator.getFromCalculator.name],
    tenantId =>
      apis.salaryCalculator
        .getFromCalculator({
          tenantId,
          childAmount: parameters.childAmount!,
          grossIncome: parameters.grossIncome!,
          isChurch: parameters.isChurch!,
          isPrivatelyInsured: parameters.isPrivatelyInsured!,
          privateInsuranceAmount: parameters.privateInsuranceAmount!,
          taxClass: parameters.taxClass!,
          lunchBenefitAmount: parameters.lunchBenefitAmount!,
          voucherBenefitAmount: parameters.voucherBenefitAmount!,
          mobilityBenefitAmount: parameters.mobilityBenefitAmount!,
          giftBenefitAmount: parameters.giftBenefitAmount!,
          internetBenefitAmount: parameters.internetBenefitAmount!,
          recreationBenefitAmount: parameters.recreationBenefitAmount!,
        })
        .then(res => res.data),
    {
      placeholderData: previousData => previousData,
      ...options,
    },
  );
}

export function useGrossCalculator(
  parameters: Partial<SalaryCalculatorApiCalculateGrossValueRequest>,
  options?: { enabled?: boolean },
): UseQueryResult<CalculatorNetResultDTO, Error> {
  return useTenantQuery(
    ['grossNetCalculator', 'calculateGrossValue', parameters, apis.salaryCalculator.calculateGrossValue.name],
    tenantId =>
      apis.salaryCalculator
        .calculateGrossValue({
          tenantId,
          childAmount: parameters.childAmount!,
          netIncome: parameters.netIncome!,
          isChurch: parameters.isChurch!,
          isPrivatelyInsured: parameters.isPrivatelyInsured!,
          privateInsuranceAmount: parameters.privateInsuranceAmount!,
          taxClass: parameters.taxClass!,
        })
        .then(res => res.data),
    {
      placeholderData: previousData => previousData,
      ...options,
    },
  );
}
