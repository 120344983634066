import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { BenefitDTOBenefitEnum, DocumentDTO } from 'probonio-shared-ui/api';
import { apis, downloadUrl } from 'probonio-shared-ui/module/api';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  benefit: BenefitDTOBenefitEnum;
  customBenefitTemplateKey?: string;
}

function formatFileSize(fileSize: number): string {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let size = fileSize;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  return `${size.toFixed(1)} ${units[unitIndex]}`;
}

const DocumentListItem: React.FC<{ document: DocumentDTO; divider?: boolean }> = ({ document, divider }) => {
  const { t } = useTranslation();

  const downloadMutation = useMutation({
    mutationFn: async () => {
      const { downloadURL } = await apis.documents.getDocument({ documentId: document.id }).then(res => res.data);
      if (downloadURL) {
        downloadUrl(downloadURL, document.fileName);
      }
    },
  });

  const handleDownload = useCallback(() => {
    downloadMutation.mutate();
  }, [downloadMutation]);

  return (
    <ListItem disablePadding>
      <ListItemButton
        component={Paper}
        sx={{ px: 1.5, py: 1.5, backgroundColor: 'background.paper', borderRadius: 1 }}
        onClick={handleDownload}
        disabled={downloadMutation.isPending}
      >
        <ListItemIcon sx={{ color: 'text.secondary' }}>
          <DescriptionIcon fontSize="large" />
        </ListItemIcon>
        <ListItemText
          primary={document.displayName}
          secondary={`${document.fileName} • ${formatFileSize(document.fileSize)} • ${t('common:date', {
            date: new Date(document.createdAt),
          })}`}
        />
        <DownloadIcon color="primary" />
      </ListItemButton>
    </ListItem>
  );
};

export const BenefitDocuments: React.FC<Props> = ({ benefit, customBenefitTemplateKey }) => {
  const { t } = useTranslation('benefitsModule');

  const { data: documents } = useQuery({
    queryKey: ['documents', { benefit, customBenefitTemplateKey }, apis.documents.listDocuments],
    queryFn: () => apis.documents.listDocuments({ benefit, customBenefitTemplateKey }).then(res => res.data),

    enabled: benefit !== BenefitDTOBenefitEnum.Custom || !!customBenefitTemplateKey,
  });

  if (documents?.results.length === 0 || (benefit === BenefitDTOBenefitEnum.Custom && !customBenefitTemplateKey)) {
    return (
      <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center">
        <Typography>{t('report.noDocuments')}</Typography>
      </Box>
    );
  }

  return (
    <List sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      {documents?.results.map((document, index) => (
        <DocumentListItem key={document.id} document={document} divider={index < documents!.results.length - 1} />
      ))}
    </List>
  );
};
