import ProfileActivations from '../../module/userManagement/UserProfile/activations/ProfileActivations';
import { BenefitActivationModalProvider } from '../../module/userManagement/UserProfile/activations/modals/BenefitActivationModalProvider';
import { GiftActivationModalProvider } from '../../module/userManagement/UserProfile/activations/modals/GiftActivationModalProvider';
import { V2GiftActivationModalProvider } from '../../module/userManagement/UserProfile/activations/modals/V2GiftActivationModalProvider';
import { BaseEmployeePage } from './BaseEmployeePage';

const EmployeeActivationsPage: React.FC = () => {
  return (
    <BaseEmployeePage isRoot>
      <V2GiftActivationModalProvider>
        <GiftActivationModalProvider>
          <BenefitActivationModalProvider>
            <ProfileActivations />
          </BenefitActivationModalProvider>
        </GiftActivationModalProvider>
      </V2GiftActivationModalProvider>
    </BaseEmployeePage>
  );
};

export default EmployeeActivationsPage;
