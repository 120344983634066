import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { UpdateUserDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { loadMe } from 'probonio-shared-ui/module/me';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BasicDialog, DialogState } from '../../component/dialog';
import { DialogLoadingButton } from '../../component/dialog/DialogLoadingButton';
import i18n from '../../lang';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import UserEditForm, { MeFields } from './UserEditForm';

interface Props {
  dialogState: DialogState;
}

export const UserEditDialog: React.FC<Props> = ({ dialogState }) => {
  const { t } = useTranslation('navigationModule');
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(state => state.me.user);
  const { handleSubmit, control, getValues } = useForm<MeFields>({
    defaultValues: {
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      title: currentUser?.title,
      salutation: currentUser?.salutation,
      locale: currentUser?.localeManager,
    },
  });
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: (userDTO: UpdateUserDTO) => apis.user.updateUser({ userId: currentUser!.id, updateUserDTO: userDTO }),
    onSuccess: async () => {
      await dispatch(loadMe(true));
      enqueueSnackbar(t('userEdit.success'), { variant: 'success' });
    },
  });

  const handleSubmitMe = useCallback(() => {
    const updateMe: UpdateUserDTO = {
      firstName: getValues('firstName'),
      lastName: getValues('lastName'),
      title: getValues('title'),
      salutation: getValues('salutation'),
      localeManager: getValues('locale'),
    };
    mutation.mutate(updateMe);
    if (i18n.resolvedLanguage !== updateMe.localeManager) {
      void i18n.changeLanguage(updateMe.localeManager);
    }
    dialogState.handleClose();
  }, [dialogState, getValues, mutation]);

  return (
    <BasicDialog
      dialogState={dialogState}
      maxWidth="xs"
      fullWidth
      title={t('userEdit.dialogTitle')}
      content={<UserEditForm control={control} onSubmit={handleSubmit(handleSubmitMe)} />}
      actions={
        <DialogLoadingButton loading={mutation.isPending} size="small" onClick={handleSubmit(handleSubmitMe)}>
          {t('common:buttons.save')}
        </DialogLoadingButton>
      }
    />
  );
};
