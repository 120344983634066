import { Box, Typography } from '@mui/material';
import { useTenant } from 'probonio-shared-ui/module/me';
import React from 'react';
import { EXPORT_OPTIONS } from './PayrollReportChooseExportTypeDialog';

export const SelectedExportTypeLogo: React.FC = () => {
  const { tenant } = useTenant();

  const exportName = EXPORT_OPTIONS.find(option => option.value === tenant?.lodasExportType);

  return (
    <Box gap={2} display="flex" alignItems="center" height={45}>
      <img style={{ maxWidth: '32px', maxHeight: '32px' }} src={`/image/export/logos/${exportName?.icon}`} alt="logo" />
      <Typography noWrap textTransform="none" variant="body2">
        {exportName?.label || ''}
      </Typography>
    </Box>
  );
};
