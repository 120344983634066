import { useTranslation } from 'react-i18next';
import { ActivationHistory } from '../../module/userManagement/UserProfile/activations/ActivationHistory';
import { BaseEmployeePage } from './BaseEmployeePage';

export const EmployeeActivationHistoryPage: React.FC = () => {
  const { t } = useTranslation('usersModule');

  return (
    <BaseEmployeePage title={t('profileActivationHistory.title')}>
      <ActivationHistory />
    </BaseEmployeePage>
  );
};
