import { createTheme, Theme, ThemeProvider } from '@mui/material';
import { getExpandedRowModel, getGroupedRowModel } from '@tanstack/react-table';
import {
  MaterialReactTable,
  MRT_Cell,
  MRT_Localization,
  MRT_Row,
  MRT_TableInstance,
  MRT_TableOptions,
  MRT_TableState,
} from 'material-react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Type is from the lib
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RowActionsRenderer<TData extends Record<string, any>> = ({
  cell,
  row,
  table,
}: {
  cell: MRT_Cell<TData>;
  row: MRT_Row<TData>;
  table: MRT_TableInstance<TData>;
}) => React.ReactNode;

const createTableTheme = (theme: Theme): Theme =>
  createTheme({
    ...theme,
    palette: { ...theme.palette, background: { default: theme.palette.background.paper } },
    components: {
      ...theme.components,
      MuiTableCell: {
        styleOverrides: {
          head: { color: theme.palette.text.secondary, fontSize: 14, textTransform: 'uppercase', fontWeight: '500 !important' },
        },
      },
    },
  });

const PAPER_PROPS = { elevation: 0 };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ThemedMaterialReactTable = <TData extends Record<string, any> = {}>(props: MRT_TableOptions<TData>): JSX.Element => {
  const { t } = useTranslation('common');

  const state = useMemo<Partial<MRT_TableState<TData>>>(() => ({ ...props.state, density: 'compact' }), [props.state]);
  const localization = useMemo(
    () => ({ ...(t('materialReactTableLocale', { returnObjects: true }) as MRT_Localization), ...props.localization }),
    [t, props.localization],
  );

  return (
    <ThemeProvider theme={createTableTheme}>
      <MaterialReactTable<TData>
        muiTablePaperProps={PAPER_PROPS}
        getExpandedRowModel={getExpandedRowModel()}
        getGroupedRowModel={getGroupedRowModel()}
        {...props}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: '',
          },
          ...props.displayColumnDefOptions,
        }}
        state={state}
        localization={localization}
      />
    </ThemeProvider>
  );
};
