import { Tooltip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import React from 'react';
import CustomChip from './CustomChip';

interface Props {
  label: string;
  onDelete: () => void;
  tooltipTitle: string;
}

const ChipPlaceholder: React.FC<Props> = React.memo(({ label, onDelete, tooltipTitle }: Props) => {
  return (
    <Tooltip arrow placement="top" title={tooltipTitle}>
      <CustomChip label={label} onDelete={onDelete} deleteIcon={<ClearIcon />} />
    </Tooltip>
  );
});

export default ChipPlaceholder;
