import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { BasicDialog, DialogState } from '../../../component/dialog';
import { PayrollHistoryList } from './PayrollHistoryList';
import { useTranslation } from 'react-i18next';

interface Props {
  dialogState: DialogState;
  benefit: BenefitDTOBenefitEnum[];
}

export const PayrollExportHistoryModal: React.FC<Props> = ({ dialogState, benefit }) => {
  const { t } = useTranslation('receiptsModule');
  return (
    <BasicDialog
      maxWidth="xl"
      dialogState={dialogState}
      title={t('payrollExportHistoryModal.title')}
      content={<PayrollHistoryList benefits={benefit} />}
    />
  );
};
