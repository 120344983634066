import { Chip, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { BenefitActivationDTO, CouponGiftDTO, CouponsV2GiftDTO } from 'probonio-shared-ui/api';
import { useTranslation } from 'react-i18next';

import { BenefitCard } from '../../../../tenant/benefitTemplates/BenefitCard';
import { BenefitActivationActions } from '../actions/BenefitActivationActions';
import { GiftActivationActions } from '../actions/GiftActivationActions';
import { V2GiftActivationActions } from '../actions/V2GiftActivationActions';
import { isUpcoming } from '../hooks/useEmployeeActivations';
import { useBenefitActivationModal } from '../modals/useBenefitActivationModal';
import { useGiftActivationModal } from '../modals/useGiftActivationModal ';
import { useV2GiftActivationModal } from '../modals/useV2GiftActivationModal';
import { GiftCard, V2GiftCard } from './GiftCard';

const BenefitDateChips: React.FC<{ benefit: BenefitActivationDTO }> = ({ benefit }) => {
  const { t } = useTranslation('benefitsModule');

  return (
    <Stack direction="row" spacing={1} justifyContent="end">
      {!isUpcoming(benefit) ? (
        benefit.endDate ? (
          <Chip
            size="small"
            data-test-id={`benefit-card-${benefit.benefit}-end-date`}
            color="warning"
            label={`${t('usersModule:profileActivationList.endDate')}: ${t('common:date', { date: new Date(benefit.endDate) })}`}
          />
        ) : (
          <Chip size="small" color="success" label={t('usersModule:profileActivationList.active')} />
        )
      ) : (
        <Chip
          color="info"
          size="small"
          data-test-id={`benefit-card-${benefit.benefit}-start-date`}
          label={`${t('usersModule:profileActivationList.startDate')}: ${t('common:date', { date: new Date(benefit.startDate) })}`}
        />
      )}
    </Stack>
  );
};
interface Props {
  activations: BenefitActivationDTO[];
  title: string;
  gifts?: CouponGiftDTO[];
  v2Gifts?: CouponsV2GiftDTO[];
}

const ProfileActivationsList: React.FC<Props> = ({ activations, title, gifts = [], v2Gifts = [] }) => {
  const { onGiftEdit } = useGiftActivationModal();
  const { onV2GiftEdit } = useV2GiftActivationModal();
  const { onBenefitEdit } = useBenefitActivationModal();
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {activations.length + gifts.length + v2Gifts.length > 0 && (
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="h3" gutterBottom>
            {title}
          </Typography>
          {activations.map(activation => {
            return (
              <BenefitCard
                onClick={onBenefitEdit}
                key={activation.id}
                cardItem={activation}
                actions={<BenefitActivationActions activation={activation} />}
                actionChips={<BenefitDateChips benefit={activation} />}
              />
            );
          })}

          {gifts.map(gift => {
            return <GiftCard onClick={onGiftEdit} key={gift.id} gift={gift} actions={<GiftActivationActions gift={gift} />} />;
          })}
          {v2Gifts.map(v2Gift => {
            return (
              <V2GiftCard
                onClick={onV2GiftEdit}
                key={v2Gift.id}
                gift={v2Gift}
                actions={<V2GiftActivationActions key={v2Gift.id} v2Gift={v2Gift} />}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default ProfileActivationsList;
