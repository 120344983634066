import { Box, Stack, Typography } from '@mui/material';
import { BenefitTemplateItemDTO, UpdateBenefitActivationDTO } from 'probonio-shared-ui/api';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DialogLoadingButton } from '../../../component/dialog/DialogLoadingButton';
import { ActivationDialogExtensions } from '../../benefits';

interface Props<T extends object> {
  activationDialogExtensions: ActivationDialogExtensions<T>;
  templateItem?: BenefitTemplateItemDTO;
  onCancel: () => void;
  onUpdate: (update: UpdateBenefitActivationDTO) => Promise<void>;
}

interface FormValues {
  benefitOptions: unknown;
}

export const TemplateItemForm = <T extends object>({
  activationDialogExtensions,
  templateItem,
  onCancel,
  onUpdate,
}: Props<T>): JSX.Element => {
  const { t } = useTranslation('tenantModule');

  const formMethods = useForm<FormValues>();
  const { handleSubmit, reset, watch, formState } = formMethods;

  const { Header, Fields, InlineField, onResetBenefitOptions, onUpdateActivation } = activationDialogExtensions;

  const handleSave = useCallback(
    async (values: FormValues) => {
      if (!onUpdateActivation) {
        throw new Error('Missing onUpdateActivation function');
      }
      await onUpdateActivation({}, values.benefitOptions as T, async update => {
        await onUpdate(update);
      });
    },
    [onUpdate, onUpdateActivation],
  );

  const handleSubmitForm = useCallback(
    (ev: React.FormEvent) => {
      ev.stopPropagation();
      void handleSubmit(handleSave)(ev);
    },
    [handleSave, handleSubmit],
  );

  useEffect(() => {
    const benefitOptions = onResetBenefitOptions(templateItem);
    reset({ benefitOptions });
  }, [onResetBenefitOptions, reset, templateItem]);

  const benefitOptions = watch('benefitOptions');

  const header = Header && benefitOptions ? <Header /> : null;
  const fields = Fields && benefitOptions ? <Fields /> : null;
  const inlineField = InlineField && benefitOptions ? <InlineField /> : null;

  return (
    <form onSubmit={handleSubmitForm}>
      <Typography color="text.secondary" mb={1}>
        {t('benefitTemplates.editItemDialog.info')}
      </Typography>
      <Typography color="text.secondary" mb={2}>
        {t('benefitTemplates.editItemDialog.warning')}
      </Typography>
      <FormProvider {...formMethods}>
        {header}
        <Box mb={1}>{inlineField}</Box>
        {fields}
      </FormProvider>
      <Stack direction="row" justifyContent="flex-end" spacing={1} mt={2}>
        <DialogLoadingButton type="submit" color="primary" loading={formState.isSubmitting} data-test-id="save-template-item">
          {t('common:buttons.save')}
        </DialogLoadingButton>
      </Stack>
    </form>
  );
};
