/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface LunchBenefitOptionsDTO
 */
export interface LunchBenefitOptionsDTO {
    /**
     * 
     * @type {number}
     * @memberof LunchBenefitOptionsDTO
     */
    'dailyLimit': number;
    /**
     * 
     * @type {number}
     * @memberof LunchBenefitOptionsDTO
     */
    'maxNumberOfReceipts': number;
    /**
     * 
     * @type {string}
     * @memberof LunchBenefitOptionsDTO
     */
    'taxationType': LunchBenefitOptionsDTOTaxationTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LunchBenefitOptionsDTO
     */
    'salaryConversation'?: boolean;
}

export const LunchBenefitOptionsDTOTaxationTypeEnum = {
    SimpleTaxation: 'SIMPLE_TAXATION',
    FlatRateTaxation: 'FLAT_RATE_TAXATION'
} as const;

export type LunchBenefitOptionsDTOTaxationTypeEnum = typeof LunchBenefitOptionsDTOTaxationTypeEnum[keyof typeof LunchBenefitOptionsDTOTaxationTypeEnum];


