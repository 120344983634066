import { type CUSTOM_BENEFIT_ICONS } from 'probonio-shared-ui/component/icon';
import { Control, useWatch } from 'react-hook-form';
import { FormValues } from '../../../useCustomBenefitCreator';
import { DashboardPreview } from './DashboardPreview';

interface Props {
  control: Control<FormValues>;
}

export const DashboardPreviewForm: React.FC<Props> = ({ control }) => {
  const image = useWatch<FormValues>({ control, name: 'image' }) as string;
  const benefitTitle = useWatch<FormValues>({ control, name: 'title' }) as string;

  const icon = useWatch<FormValues>({ control, name: 'icon' }) as keyof typeof CUSTOM_BENEFIT_ICONS;
  const showAsTile = useWatch({ control, name: 'showAsTile' });

  return <DashboardPreview benefitTitle={benefitTitle} icon={icon} image={image} showAsTile={showAsTile} />;
};
