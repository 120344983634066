import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { enqueueSnackbar } from 'notistack';
import { BenefitDTOBenefitEnum, PayrollExportHistoryWithUserDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortByDef } from '../../../component/table/sortMapper';
import { ThemedMaterialReactTable } from '../../../component/table/ThemedMaterialReactTable';
import { usePayrollExportHistoryQuery } from './usePayrollExportHistoryQuery';
interface Props {
  benefits: BenefitDTOBenefitEnum[];
}

interface MutationProps {
  filterMonth: string;
  statementMonth: string;
  recipients: string[];
  benefits: BenefitDTOBenefitEnum[];
}

export const PayrollHistoryList: React.FC<Props> = ({ benefits }) => {
  const { t } = useTranslation('receiptsModule');
  const [globalFilter, setGlobalFilter] = useState('');

  const INITIAL_PAGE_SIZE = 10;
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: INITIAL_PAGE_SIZE });

  const tenantId = useTenantID();

  const COLUMNS: (MRT_ColumnDef<PayrollExportHistoryWithUserDTO> & SortByDef)[] = useMemo(
    () => [
      {
        id: 'name',
        accessorFn: history => {
          const user = history.user;
          if (!user) {
            return <em>{t('payrollExportHistoryModal.automaticDispatch')}</em>;
          }
          if (user.firstName || user.lastName) {
            return `${user.lastName}${user.firstName && user.lastName && ', '}${user.firstName}`;
          }
        },
        header: t('payrollExportHistoryModal.createdBy'),
        enableSorting: false,
      },
      {
        id: 'benefit',
        accessorFn: history => history.benefits?.map(benefit => t(`usersModule:userForm.benefit.${benefit}.name`)).join(', '),
        header: t('benefitsModule:fields.benefit'),
        enableSorting: false,
      },
      {
        id: 'date',
        accessorFn: history => (history.createdAt ? t('common:date', { date: new Date(history.createdAt as string) }) : ''),
        header: t('usersModule:usersList.header.date'),
        enableSorting: false,
      },
      {
        id: 'mail',
        accessorFn: history => (history.reportEmailList ? history.reportEmailList.join(', ') : ''),
        header: t('usersModule:usersList.header.recipients'),
        enableSorting: false,
      },
    ],
    [t],
  );

  const { data } = usePayrollExportHistoryQuery({
    benefits: benefits,
    searchText: globalFilter,
    page: pagination.pageIndex,
    pageSize: pagination.pageSize,
  });

  const mutation = useMutation({
    mutationFn: async (props: MutationProps) => {
      await apis.benefits.sendPayrollReport({
        tenantId: tenantId(),
        resendPayrollReportRequestDTO: {
          recipients: props.recipients,
          filterMonth: props.filterMonth,
          statementMonth: props.statementMonth,
          benefits: props.benefits,
        },
      });
    },

    onSuccess: () => {
      enqueueSnackbar(t('payrollExportHistoryModal.successToast'), { variant: 'success' });
    },
  });
  const [clickedButtons, setClickedButtons] = useState<{ [key: string]: boolean }>({});

  const handleResendExport = useCallback(
    (filterMonth: string, statementMonth: string, recipients: string[], benefits: BenefitDTOBenefitEnum[], rowId: string) =>
      (event: React.MouseEvent) => {
        mutation.mutate({ filterMonth, statementMonth, recipients, benefits });
        setClickedButtons(prevState => ({ ...prevState, [rowId]: true }));
      },
    [mutation],
  );

  const renderRowActions: React.FC<{ row: MRT_Row<PayrollExportHistoryWithUserDTO> }> = useCallback(
    ({ row }) => {
      const isButtonClicked = clickedButtons[row.id] || false;

      return (
        row.original.reportEmailList &&
        row.original.reportEmailList.length > 0 && (
          <IconButton
            title={t('payrollExportHistoryModal.resendExport')}
            onClick={handleResendExport(
              DateTime.fromISO(row.original.month).toFormat('yyyy-MM'),
              DateTime.fromISO(row.original.month).plus({ months: 1 }).toFormat('yyyy-MM'),
              row.original.reportEmailList,
              row.original.benefits!,
              row.id,
            )}
            disabled={isButtonClicked}
          >
            <RefreshIcon fontSize="small" />
          </IconButton>
        )
      );
    },
    [clickedButtons, handleResendExport, t],
  );

  return (
    <ThemedMaterialReactTable
      columns={COLUMNS}
      enablePagination={true}
      enableBottomToolbar={true}
      enableTopToolbar={true}
      enableGlobalFilter={true}
      enableColumnActions={false}
      enableSortingRemoval={false}
      enableDensityToggle={false}
      enableFullScreenToggle={false}
      enableColumnFilters={false}
      enableHiding={false}
      manualPagination
      manualFiltering
      onPaginationChange={setPagination}
      onGlobalFilterChange={setGlobalFilter}
      localization={{ noRecordsToDisplay: t('payrollExportHistoryModal.noResults') }}
      state={{ globalFilter, pagination }}
      enableRowActions
      positionActionsColumn="last"
      data={data?.results || []}
      rowCount={data?.totalCount || 0}
      renderRowActions={renderRowActions}
      muiPaginationProps={{ rowsPerPageOptions: [5, 10, 20] }}
    />
  );
};
