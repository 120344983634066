import { FC } from 'react';
import { AuthorizedLayout } from '../module/navigation/AuthorizedLayout';
import UsersSubPage from '../module/userManagement/UsersSubPage';
import { useTranslation } from 'react-i18next';

const EmployeeListPage: FC = () => {
  const { t } = useTranslation('usersModule');

  return (
    <AuthorizedLayout title={t('title')}>
      <UsersSubPage />
    </AuthorizedLayout>
  );
};

export default EmployeeListPage;
