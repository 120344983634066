import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps, SelectVariants } from '@mui/material';
import React, { useMemo } from 'react';
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';

export type SelectControlProps<T extends FieldValues, TName extends FieldPath<T>> = UseControllerProps<T, TName> &
  Omit<SelectProps, 'defaultValue' | 'variant'> & {
    options?: { label: React.ReactNode; value: string | number; disabled?: boolean }[];
    helperText?: React.ReactNode;
    children?: React.ReactNode;
    dataTestId?: string;
    variant?: SelectVariants;
  };

export const SelectControl = <T extends FieldValues, TName extends FieldPath<T>>({
  name,
  label,
  rules,
  control,
  defaultValue,
  shouldUnregister,
  options,
  helperText,
  children,
  dataTestId,
  ...props
}: SelectControlProps<T, TName>): JSX.Element => {
  const { field, fieldState } = useController({ name, rules, control, defaultValue, shouldUnregister });
  const id = useMemo(() => (Math.random() + 1).toString(36).substring(7), []);

  return (
    <FormControl
      data-test-id={dataTestId}
      fullWidth={props.fullWidth}
      margin={props.margin}
      required={props.required || !!rules?.required}
      error={fieldState.invalid}
      size={props.size}
    >
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select {...field} {...props} labelId={`${id}-label`} id={id} label={label}>
        {options?.map(option => (
          <MenuItem
            key={option.value}
            data-test-id={`option-${dataTestId}-${option.value}`}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </MenuItem>
        ))}
        {children}
      </Select>
      {helperText && !fieldState.error && <FormHelperText>{helperText}</FormHelperText>}
      {fieldState.error?.message && <FormHelperText>{fieldState.error.message}</FormHelperText>}
    </FormControl>
  );
};
