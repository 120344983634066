import { DateTime } from 'luxon';
import { FieldNamesMarkedBoolean, FieldValues, UseFormGetValues } from 'react-hook-form';

export function getDirtyFieldsFromData<T extends FieldValues>(
  dirtyFields: FieldNamesMarkedBoolean<T>,
  getValues: UseFormGetValues<T>,
): {
  values: Partial<T>;
  count: number;
} {
  const data: Partial<T> = {};

  Object.keys(dirtyFields).forEach(k => {
    const key = k as keyof FieldNamesMarkedBoolean<T>;
    const dirty = dirtyFields[key];
    if (dirty) {
      // Cannot get this typed correctly...
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (data as any)[key] = getValues(key as any);
    }
  });

  return {
    values: data,
    count: Object.keys(data).length,
  };
}

// eslint-disable-next-line @rushstack/no-new-null
export function toISODateString(date: Date | null | undefined): string | null | undefined {
  return date ? DateTime.fromJSDate(date).toFormat('yyyy-MM-dd') : date;
}

// eslint-disable-next-line @rushstack/no-new-null
export function toISOMonthString(date: Date | null | undefined): string | null | undefined {
  return date ? DateTime.fromJSDate(date).toFormat('yyyy-MM') : date;
}
