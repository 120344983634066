import { SortingState } from '@tanstack/react-table';
import { useCallback } from 'react';
import { HorizontalBox } from '../../component/layout/BoxLayout';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { EmployeeFilterSelector } from '../benefits/employeeStatusList/EmployeeFilterSelector';
import { EmployeeFilterState } from '../benefits/employeeStatusList/employeeFilterState';
import { UserActionButtons } from './UserActionButtons';
import { UsersList } from './UsersList';
import { setSorting } from './filter';
import { RowSelectionState } from '../../component/table/useManualRowSelection';

interface Props {
  filterState: EmployeeFilterState;
  rowSelectionState: RowSelectionState;
  enableRowSelection?: boolean;
}

export const EmployeeListPanel = ({ filterState, rowSelectionState, enableRowSelection }: Props): JSX.Element => {
  const dispatch = useAppDispatch();

  // Load the filter here and not in the table itself, to avoid binding the table directly to redux.
  const { sorting } = useAppSelector(state => state.filter);

  const handleChangeSort = useCallback(
    (model: SortingState) => {
      dispatch(setSorting(model));
    },
    [dispatch],
  );

  return (
    <>
      <HorizontalBox gap={1} marginBottom={1.5} justifyContent="space-between">
        <EmployeeFilterSelector filterState={filterState} showBenefitFilter />
        <UserActionButtons employeeFilter={filterState.debouncedFilter} />
      </HorizontalBox>
      <UsersList
        data-test-id="users-list"
        filter={filterState.debouncedFilter}
        sorting={sorting}
        rowSelectionState={rowSelectionState}
        enableRowSelection={enableRowSelection}
        onChangeSort={handleChangeSort}
      />
    </>
  );
};
