import { BenefitActivationDTOBenefitEnum } from 'probonio-shared-ui/api';
import { createContext, useContext } from 'react';

interface BulkBenefitActivationModal {
  onBenefitCreate: (benefit: BenefitActivationDTOBenefitEnum, customId?: string) => void;
  onBenefitEdit: (benefit: BenefitActivationDTOBenefitEnum, customId?: string) => void;
  onApplyTemplate: () => void;
}

const BulkBenefitActivationModalContext = createContext<BulkBenefitActivationModal | null>(null);

export function useBulkBenefitActivationModal(): BulkBenefitActivationModal {
  const context = useContext(BulkBenefitActivationModalContext);

  if (!context) {
    throw new Error('No BenefitActivationModalContext found for component');
  }

  return context;
}

export default BulkBenefitActivationModalContext;
