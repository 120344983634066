import { Box, Typography } from '@mui/material';
import { useCallback } from 'react';
import { BenefitButtonWithChevron } from '../BenefitButtonwithChevron';
import { CustomBenefitTemplate, CustomBenefitTemplateKeys } from './customBenefitTemplates';
import { CUSTOM_BENEFIT_ICONS } from 'probonio-shared-ui/component/icon';

interface Props {
  customBenefit: CustomBenefitTemplate;
  customBenefitKey: CustomBenefitTemplateKeys;
  onClick: (customBenefit: CustomBenefitTemplate, customBenefitKey: CustomBenefitTemplateKeys) => void;
  isConfigured: boolean;
}
export const CustomBenefitItem: React.FC<Props> = ({ customBenefit, customBenefitKey, onClick, isConfigured }) => {
  const handleOpenModal = useCallback(() => {
    onClick(customBenefit, customBenefitKey);
  }, [customBenefit, customBenefitKey, onClick]);

  const Icon = CUSTOM_BENEFIT_ICONS[customBenefit.iconValue];
  return (
    <Box my={0.5} height="100%">
      <BenefitButtonWithChevron
        dataTestId={customBenefit.title}
        sx={{ backgroundColor: 'background.paper', height: '100%', py: 2 }}
        color="secondary"
        onClick={handleOpenModal}
        icon={<Icon color="primary" fontSize="large" sx={{ mr: 1 }} />}
        header={<Typography variant="h2">{customBenefit.title}</Typography>}
        content={<Typography mt={0.5}>{customBenefit.description}</Typography>}
        isConfigured={isConfigured}
      />
    </Box>
  );
};
