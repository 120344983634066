import { Box, BoxProps, Paper, useTheme } from '@mui/material';

export const BenefitPaper: React.FC<BoxProps & { size?: 'sm' | 'md' }> = ({ size, ...props }) => {
  const theme = useTheme();

  return (
    <Paper>
      <Box sx={{ height: size === 'sm' ? 24 : 32, backgroundColor: theme.palette.secondary.main }} />
      <Box p={size === 'sm' ? 1 : 1.5} {...props} />
    </Paper>
  );
};
