import { OutlinedInput, OutlinedInputProps } from '@mui/material';
import useDebounce from 'probonio-shared-ui/utils/useDebounce';
import React, { useCallback, useState } from 'react';
import { CopyToClipboardButton } from 'probonio-shared-ui/component/button/CopyToClipboardButton';

interface Props extends OutlinedInputProps {
  mask: string;
}

export const MaskedClipboardInput: React.FC<Props> = ({ mask, value, ...props }) => {
  const [show, setShow] = useState(false);
  const debouncedShow = useDebounce(show, show ? 0 : 500);

  const handleEnter = useCallback(() => {
    setShow(true);
  }, []);
  const handleLeave = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <OutlinedInput
      value={debouncedShow ? value : mask}
      disabled
      endAdornment={<CopyToClipboardButton value={String(value)} edge="end" />}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      {...props}
    />
  );
};
