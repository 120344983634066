import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Typography, Skeleton } from '@mui/material';

export const TextWithCaret: React.FC<React.PropsWithChildren<{ color?: string }>> = ({ color, children }) => (
  <Typography component="span" display="inline-block" variant="inherit" fontWeight={600} color={color} marginY={-0.5}>
    <ArrowDropUpIcon sx={{ verticalAlign: 'sub', marginRight: '-0.15em' }} />
    {children}
  </Typography>
);

export const TextWithCaretPlaceholder: React.FC = () => <Skeleton variant="text" width={70} sx={{ display: 'inline-block' }} />;
