import HistoryIcon from '@mui/icons-material/History';
import { Box, Button, Stack, Tab, Tabs } from '@mui/material';
import { IconToggleButton } from 'probonio-shared-ui/component/button';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useTranslation } from 'react-i18next';
import { ActionCardContent } from '../../../../component/layout/ActionCardContent';
import { useTabsState } from '../../../../component/tab';
import { ActivationsList } from '../../../benefits';
import { isBenefitActive } from '../../../benefits/BenefitIcon';
import { GiftsV2List } from '../../../benefits/couponsV2/gifts/GiftsV2List';
import { GiftsList } from '../../../benefits/gifts';
import { useProfileEmployee } from './hooks/useProfileEmployee';

export const ActivationHistory: React.FC = () => {
  const { t } = useTranslation('usersModule');
  const { tenant } = useTenant();

  const { employee, onNavigateBack } = useProfileEmployee();
  const { tabIndex, handleTabChange } = useTabsState();

  return (
    <ActionCardContent
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          <Box>{t('profileActivationList.title')}</Box>
          <IconToggleButton active title={t('profileActivationHistory.buttonTooltipHide')} onClick={onNavigateBack}>
            <HistoryIcon />
          </IconToggleButton>
        </Stack>
      }
      actions={
        <Button variant="text" size="small" onClick={onNavigateBack}>
          {t('common:buttons.close')}
        </Button>
      }
    >
      <Box mt={-1} mb={2}>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label={t('profileActivationHistory.tableHeader')} />
          {isBenefitActive(tenant, 'GIFTS') && <Tab value={1} label={t('profileActivationHistory.tableHeaderGifts')} />}
          {isBenefitActive(tenant, 'COUPONS_V2') && (
            <Tab
              value={2}
              label={t(
                isBenefitActive(tenant, 'GIFTS') && isBenefitActive(tenant, 'COUPONS_V2')
                  ? 'profileActivationHistory.tableHeaderGiftsV2'
                  : 'profileActivationHistory.tableHeaderGifts',
              )}
            />
          )}
        </Tabs>
      </Box>
      {tabIndex === 0 && <ActivationsList employeeId={employee.id} />}
      {tabIndex === 1 && <GiftsList employeeId={employee.id} />}
      {tabIndex === 2 && <GiftsV2List employeeId={employee.id} />}
    </ActionCardContent>
  );
};
