import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useCallback } from 'react';
import { BENEFIT_ACTIVATION_HOOKS } from '../benefitComponents';
import { ActivationDialogExtensions } from './ActivationDialog';

export function useBoundActivationComponent<A extends object, T extends { activationDialogExtensions: ActivationDialogExtensions<A> }>(
  componentType: React.ComponentType<T>,
  benefit?: BenefitDTOBenefitEnum,
): React.ComponentType<Omit<T, 'activationDialogExtensions'>> {
  const activationDialogExtensionHook = benefit && BENEFIT_ACTIVATION_HOOKS[benefit];
  const Component = componentType;
  const BoundComponent = useCallback<React.FC<Omit<T, 'activationDialogExtensions'>>>(
    props => {
      if (!activationDialogExtensionHook) {
        return <></>;
      }
      const activationDialogExtensions = activationDialogExtensionHook();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return <Component activationDialogExtensions={activationDialogExtensions} {...(props as any)} />;
    },
    [Component, activationDialogExtensionHook],
  );
  return BoundComponent;
}
