import { Box } from '@mui/material';
import React from 'react';
import { DialogTab, DialogTabs, useTabsState } from '../tab';
import { BasicDialog } from './BasicDialog';

interface TabbedDialogTabProps {
  label: React.ReactNode;
  actions?: React.ReactNode;
}

export const TabbedDialogTab: React.FC<React.PropsWithChildren<TabbedDialogTabProps>> = ({ children }) => {
  return <Box marginTop={2}>{children}</Box>;
};

interface Props extends Omit<React.ComponentProps<typeof BasicDialog>, 'content'> {
  tabIndex?: number;
  handleTabChange?: (event: React.SyntheticEvent | undefined, value: number) => void;
}

export const TabbedDialog: React.FC<React.PropsWithChildren<Props>> = ({ tabIndex, handleTabChange, children, ...props }) => {
  const managedTabsState = useTabsState();

  const tabs = React.Children.toArray(children) as React.ReactElement<TabbedDialogTabProps>[];
  const activeTab = tabs[tabIndex ?? managedTabsState.tabIndex];

  return (
    <BasicDialog
      {...props}
      content={
        <>
          <DialogTabs
            variant="fullWidth"
            value={tabIndex ?? managedTabsState.tabIndex}
            onChange={handleTabChange ?? managedTabsState.handleTabChange}
          >
            {tabs.map((childTab, index) => (
              <DialogTab key={index} label={childTab.props.label} />
            ))}
          </DialogTabs>
          {activeTab}
        </>
      }
      actions={activeTab.props.actions}
    />
  );
};
