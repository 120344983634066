import { Box } from '@mui/material';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useRowSelectionState } from '../../component/table/useManualRowSelection';
import { BenefitProvider } from '../../module/benefits/BenefitContext';
import { BENEFIT_ICONS, isBenefitActive, orderedBenefits } from '../../module/benefits/BenefitIcon';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { BikeleasingEmployeeInformationProvider } from '../../module/benefits/bikeleasing/context';
import { BulkActivationButton } from '../../module/benefits/bulkActivation/BulkActivationButton';
import { BulkBenefitActivationModalProvider } from '../../module/benefits/bulkActivation/BulkBenefitActivationModalProvider';
import { BulkEditActivationButton } from '../../module/benefits/bulkActivation/BulkEditActivationButton';
import { CouponsV2SepaAlert } from '../../module/benefits/couponsV2/CouponsV2SepaAlert';
import { BenefitEmployeeTabs } from '../../module/benefits/employeeStatusList/BenefitEmployeeTabs';
import { useEmployeeFilterState } from '../../module/benefits/employeeStatusList/employeeFilterState';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';
import BenefitInfoText from './BenefitInfoText';

const ADDITIONAL_BENEFIT_PROVIDERS: Partial<Record<BenefitDTOBenefitEnum, React.FC<{ children?: React.ReactNode }>>> = {
  BIKELEASING: BikeleasingEmployeeInformationProvider,
};

const FallbackComponent: React.FC<{ children: React.ReactNode }> = ({ children }) => <>{children}</>;

const BenefitEmployeesPage: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { tenant } = useTenant();
  const navigate = useNavigate();
  const { benefit: benefitName } = useParams<{ benefit: string }>();
  const benefit: BenefitDTOBenefitEnum = benefitName!.toUpperCase() as BenefitDTOBenefitEnum;
  const AdditionalProvider = ADDITIONAL_BENEFIT_PROVIDERS[benefit] ?? FallbackComponent;
  const filterState = useEmployeeFilterState();
  const rowSelectionState = useRowSelectionState();

  useEffect(() => {
    rowSelectionState.handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [benefit, filterState.filter.activationState]);

  useEffect(() => {
    if (tenant && (!orderedBenefits(tenant).includes(benefit) || !isBenefitActive(tenant, benefit))) {
      // benefit is not active, should not be here
      navigate('/');
    }
  }, [benefit, navigate, tenant]);

  return (
    <AuthorizedLayout title={t('subPage.employees')} breadcrumbs={[{ title: t(`${benefit}.title`), to: `/benefits/${benefitName}` }]}>
      <BenefitProvider benefit={benefit}>
        <AdditionalProvider>
          <BulkBenefitActivationModalProvider rowSelectionState={rowSelectionState} employeeFilter={filterState.filter}>
            {benefit === BenefitDTOBenefitEnum.CouponsV2 && (
              <Box mt={0.5}>
                <CouponsV2SepaAlert />
              </Box>
            )}
            <BenefitSubPage
              icon={BENEFIT_ICONS[benefit]}
              title={t(`${benefit}.title`)}
              subTitle={t('subPage.employees')}
              menu={
                filterState.debouncedFilter.activationState === 'ACTIVE' ? (
                  <BulkEditActivationButton rowSelectionState={rowSelectionState} />
                ) : (
                  <BulkActivationButton rowSelectionState={rowSelectionState} />
                )
              }
            >
              <BenefitInfoText benefit={benefit} />
              <BenefitEmployeeTabs key={benefit} benefit={benefit} filterState={filterState} rowSelectionState={rowSelectionState} />
            </BenefitSubPage>
          </BulkBenefitActivationModalProvider>
        </AdditionalProvider>
      </BenefitProvider>
    </AuthorizedLayout>
  );
};

export default BenefitEmployeesPage;
