import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HomeIcon from '@mui/icons-material/Home';
import { Box, Button, Step, StepButton, StepContent, StepProps, SvgIconProps, Typography } from '@mui/material';
import { TenantDTOActiveBenefitsEnum } from 'probonio-shared-ui/api';
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export interface TutorialStepDefinition {
  loading?: boolean;
  completed?: boolean;
  optional?: boolean;
  label: string;
  requiredBenefits?: TenantDTOActiveBenefitsEnum[];
  path?: string;
  buttonText?: string;
  buttonIcon?: React.ComponentType<SvgIconProps>;
  description: string;
  help?: string;
  onComplete?: () => void;
}

interface Props extends StepProps {
  optional?: boolean;
  manual?: boolean;
  label: React.ReactNode;
  description: React.ReactNode;
  help?: React.ReactNode;
  path?: string;
  buttonText?: React.ReactNode;
  buttonIcon?: React.ComponentType<SvgIconProps>;
  onToggleOpen: () => void;
  onComplete?: () => void;
  onAfterComplete?: () => void;
}

export const TutorialStep: React.FC<Props> = ({
  completed,
  optional,
  label,
  description,
  help,
  path,
  buttonText,
  buttonIcon,
  onToggleOpen,
  onComplete,
  onAfterComplete,
  ...stepProps
}) => {
  const { t } = useTranslation('tutorialModule');
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleNavigate = useCallback(() => {
    navigate(path!);
  }, [navigate, path]);

  const handleComplete = useCallback(() => {
    onComplete?.();
    onAfterComplete?.();
  }, [onAfterComplete, onComplete]);

  const ButtonIconComponent = buttonIcon || HomeIcon;

  return (
    <Step completed={completed} {...stepProps} data-test-id="tenant-tutorial-step">
      <StepButton onClick={onToggleOpen} optional={optional ? 'Optional' : ''}>
        {label}
      </StepButton>
      <StepContent>
        <Typography variant="body2">{description}</Typography>
        {help && (
          <Typography variant="body2" color="grey" pt={1}>
            {help}
          </Typography>
        )}

        <Box>
          {onComplete && !completed && (
            <Button
              fullWidth
              variant="contained"
              size="small"
              onClick={handleComplete}
              startIcon={<CheckCircleIcon />}
              sx={{ mt: 1, mr: 1 }}
              data-test-id="tutorial-complete-button"
            >
              {t('buttonDone')}
            </Button>
          )}
          {path && !pathname.endsWith(path) && (
            <Button
              fullWidth
              variant="contained"
              size="small"
              onClick={handleNavigate}
              sx={{ mt: 1, mr: 1 }}
              startIcon={<ButtonIconComponent />}
              data-test-id="tutorial-navigate-button"
            >
              {buttonText ? buttonText : t('buttonNavigate')}
            </Button>
          )}
        </Box>
      </StepContent>
    </Step>
  );
};
