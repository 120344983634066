import { DateTime } from 'luxon';
import { EmployeeDTO, EmployeeDTOStatusEnum, UserDTOStatusEnum } from 'probonio-shared-ui/api';

type CombinedEmployeeStatus = UserDTOStatusEnum | EmployeeDTOStatusEnum | 'EXPIRED' | 'MAIL_DELIVERY_ERROR' | 'PLANNED_TERMINATION';

export function mapEmployeeStatus(employee: EmployeeDTO): {
  status: CombinedEmployeeStatus;
  color: string;
  scheduledInviteDate?: string;
  inviteWhenActive?: boolean;
} {
  const inviteWhenActive = employee.nextGift?.inviteWhenActive;
  const scheduledInviteDate = employee.nextGift?.payoutDate;

  let status: CombinedEmployeeStatus = employee.status;
  let color = 'success.main';
  if (employee.user?.expiryDateRegisterLink && DateTime.fromISO(employee.user.expiryDateRegisterLink) <= DateTime.utc()) {
    status = 'EXPIRED';
    color = 'error.main';
  } else if (employee.status === 'INACTIVE') {
    color = 'text.secondary';
  } else if (employee.user?.status === 'PENDING' && employee.user.mailDeliveryError) {
    status = 'MAIL_DELIVERY_ERROR';
    color = 'error.main';
  } else if (employee.user?.status === 'PENDING' && employee.status === 'ACTIVE' && !employee.terminationDate) {
    status = employee.user.status;
    color = 'success.main';
  } else if (employee.user?.status === 'CREATED') {
    status = employee.user.status;
    color = 'warning.main';
  } else if (employee.status === 'ACTIVE' && employee.terminationDate) {
    status = 'PLANNED_TERMINATION';
    color = 'warning.main';
  } else if (employee.status === 'REGISTERED') {
    color = 'warning.main';
  } else if (employee.status === 'INVITATION_CODE') {
    color = 'warning.main';
  }
  return { status, color, scheduledInviteDate, inviteWhenActive };
}
