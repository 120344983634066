import { Box, Chip, Divider } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParamsTabsState } from '../../../component/tab/tabState';
import { BenefitToggleButton, BenefitToggleButtonGroup } from '../../benefits/BenefitToggleButton';
import { EditTemplateForm } from './EditTemplateForm';
import { TemplateBenefitIcons } from './TemplateBenefitIcons';
import { useBenefitTemplates } from './benefitTemplateQueries';

export const BenefitTemplatesPanel: React.FC = () => {
  const { t } = useTranslation('tenantModule');

  const { data: templates } = useBenefitTemplates();
  const { tabIndex: selectedTemplateId, handleTabChange } = useSearchParamsTabsState('t', true);

  const setSelectedTemplateId = useCallback((id: string | undefined) => handleTabChange(undefined, id || ''), [handleTabChange]);

  useEffect(() => {
    if (selectedTemplateId || !templates) {
      return;
    }
    if (templates.results.length) {
      setSelectedTemplateId(templates.results[0].id);
    } else {
      setSelectedTemplateId('NEW');
    }
  }, [selectedTemplateId, setSelectedTemplateId, templates]);

  const selectedTemplate = templates?.results.find(template => template.id === selectedTemplateId);

  const handleSelectTemplate = useCallback(
    (event: React.MouseEvent, value: string) => {
      if (value) {
        setSelectedTemplateId(value);
      }
    },
    [setSelectedTemplateId],
  );

  const handleCreate = useCallback(
    (newId: string) => {
      setSelectedTemplateId(newId);
    },
    [setSelectedTemplateId],
  );

  const handleDelete = useCallback(() => {
    setSelectedTemplateId(undefined);
  }, [setSelectedTemplateId]);

  return (
    <>
      <Box display="flex">
        <BenefitToggleButtonGroup
          data-test-id="template-list"
          exclusive
          orientation="vertical"
          value={selectedTemplateId}
          onChange={handleSelectTemplate}
        >
          {templates?.results.map(template => {
            return (
              <BenefitToggleButton
                key={template.id}
                value={template.id}
                color="secondary"
                sx={{
                  flexDirection: 'column',
                  whiteSpace: 'nowrap',
                  height: 'auto',
                  alignItems: 'flex-start',
                  py: 0.5,
                }}
              >
                {template.name}
                {template.isDefault ? (
                  <Box display="flex" justifyContent="space-between" width="100%" marginBottom={0.5}>
                    <TemplateBenefitIcons template={template} />
                    <Chip size="small" variant="outlined" color="error" label={<Box>{t('benefitTemplates.defaultTemplate')}</Box>} />
                  </Box>
                ) : (
                  <TemplateBenefitIcons template={template} sx={{ mb: 0.5 }} />
                )}
              </BenefitToggleButton>
            );
          })}
          <BenefitToggleButton value="NEW" color="secondary" sx={{ whiteSpace: 'nowrap' }}>
            {t('benefitTemplates.action.newTemplate')}
          </BenefitToggleButton>
        </BenefitToggleButtonGroup>
        <Divider orientation="vertical" sx={{ marginX: 3, height: 'auto' }} />
        <EditTemplateForm template={selectedTemplate} onCreate={handleCreate} onDelete={handleDelete} />
      </Box>
    </>
  );
};
