import { CircularProgress } from '@mui/material';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BenefitPaper } from '../../component/benefitTabs/BenefitPaper';
import { BENEFIT_ICONS } from '../../module/benefits/BenefitIcon';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { BikeBenefitMenu } from '../../module/benefits/bike/BikeBenefitMenu';
import { BikeContractDetails } from '../../module/benefits/bike/BikeContractDetails';
import { useCurrentBikeContract } from '../../module/benefits/bike/useCurrentBikeContract';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';
import { renderUserName } from '../../module/userManagement/userFormatter';

const BikeContractDetailsPage: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { employeeId } = useParams();

  const { data: employee } = useTenantQuery(['employees', employeeId, apis.employee.getEmployeeById.name], tenantId =>
    apis.employee.getEmployeeById({ tenantId, employeeId: employeeId!, includeRegistrationToken: true }).then(res => res.data),
  );

  const currentBikeContract = useCurrentBikeContract(employeeId!);

  return (
    <AuthorizedLayout
      title={renderUserName(employee?.user, employee?.employeeNumber)}
      breadcrumbs={[
        { title: t('BIKE.title'), to: '/benefits/bike' },
        { title: t('BIKE.contracts'), to: '/benefits/bike/contracts' },
      ]}
    >
      <BenefitSubPage
        icon={BENEFIT_ICONS.BIKE}
        title={t('BIKE.title')}
        subTitle={t('BIKE.contractDetails', { name: renderUserName(employee?.user, employee?.employeeNumber) })}
        menu={<BikeBenefitMenu />}
        showBack
        navigateBackTo="/benefits/bike/contracts"
      >
        <BenefitPaper>{currentBikeContract ? <BikeContractDetails contract={currentBikeContract} /> : <CircularProgress />}</BenefitPaper>
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};

export default BikeContractDetailsPage;
