import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, FormControl, FormHelperText, FormLabel, IconButton, styled, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { colors } from '../../theme';

export type UploadControlProps<T extends FieldValues, TName extends FieldPath<T>> = UseControllerProps<T, TName> &
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    buttonLabel?: React.ReactNode;
    label?: React.ReactNode;
    helperText?: React.ReactNode;
    fullWidth?: boolean;
  };

const StyledInputBox = styled(Box, { shouldForwardProp: prop => prop !== 'error' })<{ error?: boolean }>(({ error, theme }) => ({
  borderRadius: theme.shape.borderRadius,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: error ? theme.palette.error.main : colors.border.toggleButton,
  width: '100%',
  minWidth: '300px',
  padding: '2px',
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  '& > label': {
    height: 34,
  },
}));

export const UploadControl = <T extends FieldValues, TName extends FieldPath<T>>({
  name,
  buttonLabel,
  label,
  helperText,
  fullWidth,
  rules,
  control,
  defaultValue,
  shouldUnregister,
  ...props
}: UploadControlProps<T, TName>): JSX.Element => {
  const { t } = useTranslation('common');
  const { field, fieldState } = useController({ name, rules, control, defaultValue, shouldUnregister });

  const handleSelectFile = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    ev => {
      const file = ev.target.files?.[0];
      if (file) {
        field.onChange(file);
        field.onBlur();
      }
    },
    [field],
  );
  const handleClear = useCallback(() => {
    field.onChange(undefined);
    field.onBlur();
  }, [field]);

  return (
    <FormControl required={!!rules?.required} error={!!fieldState.error} fullWidth={fullWidth}>
      {label && <FormLabel>{label}</FormLabel>}
      <StyledInputBox error={!!fieldState.error}>
        <Button size="small" variant="contained" component="label" sx={{ marginRight: 1, flexShrink: 0 }}>
          {buttonLabel || t('buttons.upload')}
          <input {...props} hidden type="file" value={!field.value ? '' : undefined} onChange={handleSelectFile} />
        </Button>
        <Typography
          component="div"
          variant="body2"
          fontStyle={field.value ? undefined : 'italic'}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          lineHeight="unset"
          flexGrow={1}
        >
          {(field.value as File)?.name || t('noFileSelected')}
        </Typography>
        {field.value && (
          <IconButton size="small" onClick={handleClear}>
            <ClearIcon />
          </IconButton>
        )}
      </StyledInputBox>
      {helperText && !fieldState.error && <FormHelperText>{helperText}</FormHelperText>}
      {fieldState.error && (
        <FormHelperText>{t(`errorModule:form.${fieldState.error.type}`, fieldState.error.message || '')}</FormHelperText>
      )}
    </FormControl>
  );
};
