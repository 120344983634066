import { Box, ListSubheader, MenuItem } from '@mui/material';
import { BenefitDTOBenefitEnum, CouponsV2GiftDTOGiftTypeEnum } from 'probonio-shared-ui/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BENEFIT_ICONS, isBenefitActive } from '../../../../benefits/BenefitIcon';

interface ItemProps {
  benefit: BenefitDTOBenefitEnum;
  handleSelect: () => void;
  name: string;
  giftType?: CouponsV2GiftDTOGiftTypeEnum;
}

export const SelectionItem: React.FC<ItemProps> = ({ benefit, handleSelect, name, giftType }) => {
  const GiftIcon = BENEFIT_ICONS.GIFTS;
  const key = giftType ? `${benefit}${giftType}` : benefit;

  return (
    <MenuItem value={key} key={key} onClick={handleSelect} data-test-id={`gifts-selector-value-${key}`}>
      <Box display="flex" gap={1} overflow="hidden" width="100%">
        <GiftIcon key={`${key}icon`} color="primary" />
        {name}
      </Box>
    </MenuItem>
  );
};

interface Props {
  onSelect: (benefit: BenefitDTOBenefitEnum, giftType?: CouponsV2GiftDTOGiftTypeEnum) => void;
  title: string;
}
export const GiftSelectionItems: React.FC<Props> = ({ onSelect: handleSelect, title }) => {
  const { t } = useTranslation('benefitsModule');
  const { tenant } = useTenant();

  const showGifts = isBenefitActive(tenant, BenefitDTOBenefitEnum.Gifts);
  const showV2Gifts = isBenefitActive(tenant, BenefitDTOBenefitEnum.CouponsV2);

  const handleGiftSelect = useCallback(() => handleSelect(BenefitDTOBenefitEnum.Gifts), [handleSelect]);

  const handleV2GiftSelect = useCallback(
    () => handleSelect(BenefitDTOBenefitEnum.CouponsV2, CouponsV2GiftDTOGiftTypeEnum.Gift),
    [handleSelect],
  );
  const handleV2IncentiveSelect = useCallback(
    () => handleSelect(BenefitDTOBenefitEnum.CouponsV2, CouponsV2GiftDTOGiftTypeEnum.Incentive),
    [handleSelect],
  );

  if (!showGifts && !showV2Gifts) {
    return null;
  }

  return (
    <>
      <ListSubheader>{title}</ListSubheader>

      {showGifts && <SelectionItem benefit={BenefitDTOBenefitEnum.Gifts} handleSelect={handleGiftSelect} name={t('GIFTS.title')} />}
      {showV2Gifts && (
        <>
          <SelectionItem
            benefit={BenefitDTOBenefitEnum.CouponsV2}
            handleSelect={handleV2GiftSelect}
            name={t(`couponsV2Module:gifts.giftType.${CouponsV2GiftDTOGiftTypeEnum.Gift}`)}
            giftType={CouponsV2GiftDTOGiftTypeEnum.Gift}
          />
          <SelectionItem
            benefit={BenefitDTOBenefitEnum.CouponsV2}
            handleSelect={handleV2IncentiveSelect}
            name={t(`couponsV2Module:gifts.giftType.${CouponsV2GiftDTOGiftTypeEnum.Incentive}`)}
            giftType={CouponsV2GiftDTOGiftTypeEnum.Incentive}
          />
        </>
      )}
    </>
  );
};
