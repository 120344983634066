import { Box, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { CUSTOM_BENEFIT_ICONS } from 'probonio-shared-ui/component/icon';
import { BenefitButton } from 'probonio-shared-ui/component/button';
import { PREVIEW_WIDTH } from '../PreviewStepper';

interface Props {
  icon: string;
  benefitTitle: string;
  image?: string | File;
  showAsTile?: boolean;
}

export const DashboardPreview: React.FC<Props> = ({ icon, benefitTitle, image, showAsTile }) => {
  const theme = useTheme();
  const [imageUrl, setImageUrl] = useState<string | undefined>();

  useEffect(() => {
    if (typeof image === 'string' || !image) {
      setImageUrl(image);
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    setImageUrl(objectUrl);

    return () => {
      URL.revokeObjectURL(objectUrl);
    };
  }, [image, setImageUrl]);

  const Icon = CUSTOM_BENEFIT_ICONS[icon];
  return (
    <Box data-test-id="custom-benefit-creator-page-dashboard-preview" sx={{ position: 'relative', width: PREVIEW_WIDTH }}>
      <img
        style={{ borderRadius: theme.shape.borderRadius, filter: 'blur(3px)' }}
        src="/image/customBenefits/preview/Dashboard.png"
        width="100%"
      />
      <BenefitButton
        sx={{
          visibility: showAsTile ? undefined : 'hidden',
          position: 'absolute',
          left: 20,
          top: 275,
          width: '376px',
          height: '147px',

          // Override some values which are different in the UI-theme.
          paddingTop: '30px',
          paddingBottom: '30px',
          paddingLeft: '20px',
          paddingRight: '20px',
          '.benefit-button-title': {
            fontSize: 26,
            fontWeight: 400,
          },
          '.benefit-button-subtitle': {
            fontSize: 14,
          },
          '.benefit-button-progress': {
            color: 'rgb(155, 203, 235)',
          },
        }}
        benefitProgress={100}
        descriptionKey=""
        descriptionValues={{}}
        icon={Icon}
        picture={imageUrl}
        title={benefitTitle}
      />
    </Box>
  );
};
