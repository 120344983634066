import { EmployeeApiListEmployeesRequest } from 'probonio-shared-ui/api';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BenefitTab } from '../../component/benefitTabs/BenefitTab';
import { BenefitTabPanel } from '../../component/benefitTabs/BenefitTabPanel';
import { useSearchParamsTabsState } from '../../component/tab/tabState';
import { RowSelectionState } from '../../component/table/useManualRowSelection';
import { EmployeeFilter, EmployeeFilterState, mapEmployeeFilterToRequest } from '../benefits/employeeStatusList/employeeFilterState';
import { EmployeeListPanel } from './EmployeeListPanel';
import { useEmployeeList } from './useEmployeeList';

interface Props {
  filterState: EmployeeFilterState;
  rowSelectionState: RowSelectionState;
  enableRowSelection?: boolean;
  children?: React.ReactNode;
}

export const EmployeeListTabs = ({ filterState, rowSelectionState, enableRowSelection, children }: Props): JSX.Element => {
  const { t } = useTranslation('usersPage');
  const tabsState = useSearchParamsTabsState('state');

  const sharedParams: Partial<EmployeeApiListEmployeesRequest> = {
    ...mapEmployeeFilterToRequest(filterState.debouncedFilter),
    showActiveUser: undefined,
    page: 0,
    pageSize: 0,
  };

  const userCountActive = useEmployeeList({ ...sharedParams, showActiveUser: 'ACTIVE' }).data?.totalCount || 0;
  const userCountRegistration = useEmployeeList({ ...sharedParams, showActiveUser: 'REGISTRATION' }).data?.totalCount || 0;
  const userCountInactive = useEmployeeList({ ...sharedParams, showActiveUser: 'INACTIVE' }).data?.totalCount || 0;

  const userCountAll = userCountActive + userCountRegistration + userCountInactive;

  useEffect(() => {
    const setFilter = filterState.setFilter;
    let showActiveUser: EmployeeFilter['showActiveUser'];
    if (tabsState.tabIndex === '') {
      showActiveUser = 'ACTIVE';
    } else if (tabsState.tabIndex === 'registration') {
      showActiveUser = 'REGISTRATION';
    } else if (tabsState.tabIndex === 'inactive') {
      showActiveUser = 'INACTIVE';
    }

    setFilter(filter => ({
      ...filter,
      showActiveUser,
    }));
  }, [filterState.setFilter, tabsState.tabIndex]);

  return (
    <BenefitTabPanel tabsState={tabsState}>
      <BenefitTab value="" label={t('tab.active')} count={userCountActive}>
        <EmployeeListPanel filterState={filterState} rowSelectionState={rowSelectionState} enableRowSelection={enableRowSelection} />
      </BenefitTab>
      <BenefitTab value="registration" label={t('tab.registration')} count={userCountRegistration}>
        <EmployeeListPanel filterState={filterState} rowSelectionState={rowSelectionState} enableRowSelection={enableRowSelection} />
      </BenefitTab>
      <BenefitTab value="inactive" label={t('tab.inactive')} count={userCountInactive}>
        <EmployeeListPanel filterState={filterState} rowSelectionState={rowSelectionState} enableRowSelection={false} />
      </BenefitTab>
      <BenefitTab value="all" label={t('tab.all')} count={userCountAll}>
        <EmployeeListPanel filterState={filterState} rowSelectionState={rowSelectionState} enableRowSelection={enableRowSelection} />
      </BenefitTab>
      {children}
    </BenefitTabPanel>
  );
};
