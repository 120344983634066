import { Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { UpdateBenefitActivationDTO } from 'probonio-shared-ui/api';
import { RadioControl } from 'probonio-shared-ui/component/form';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DialogLoadingButton } from '../../../component/dialog/DialogLoadingButton';
import { DatePickerControl } from '../../../component/form';

interface Props {
  onCancel: () => void;
  onUpdate: (update: UpdateBenefitActivationDTO) => Promise<void>;
}

interface FormValues {
  deactivateMode: 'NEAREST_DATE' | 'END_OF_MONTH' | 'CUSTOM';
  endDate?: Date;
}

export const BulkDeactivateForm: React.FC<Props> = ({ onCancel, onUpdate }) => {
  const { t } = useTranslation('benefitsModule');

  const { control, watch, handleSubmit, formState } = useForm<FormValues>({
    defaultValues: { deactivateMode: 'NEAREST_DATE', endDate: new Date() },
  });

  const handleSave = useCallback(
    async (values: FormValues) => {
      let update: UpdateBenefitActivationDTO;
      if (values.deactivateMode === 'NEAREST_DATE') {
        update = { endAtNearestDate: true };
      } else if (values.deactivateMode === 'END_OF_MONTH') {
        update = { endDate: DateTime.now().toFormat('yyyy-MM') };
      } else {
        update = { endDate: DateTime.fromJSDate(values.endDate!).toFormat('yyyy-MM') };
      }
      await onUpdate(update);
    },
    [onUpdate],
  );

  const deactivateMode = watch('deactivateMode');

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Stack spacing={2}>
        <RadioControl
          control={control}
          name="deactivateMode"
          label={t('bulkEditDialog.deactivate.mode.info')}
          rules={{ required: true }}
          options={[
            {
              label: t('bulkEditDialog.deactivate.mode.nearestDate.label'),
              hint: t('bulkEditDialog.deactivate.mode.nearestDate.hint'),
              value: 'NEAREST_DATE',
            },
            {
              label: t('bulkEditDialog.deactivate.mode.endOfMonth.label'),
              hint: t('bulkEditDialog.deactivate.mode.endOfMonth.hint'),
              value: 'END_OF_MONTH',
            },
            {
              label: t('bulkEditDialog.deactivate.mode.custom.label'),
              hint: t('bulkEditDialog.deactivate.mode.custom.hint'),
              value: 'CUSTOM',
            },
          ]}
        />
        <DatePickerControl
          monthYearPicker
          control={control}
          name="endDate"
          label={t('bulkEditDialog.deactivate.endDate')}
          disabled={deactivateMode !== 'CUSTOM'}
          rules={{ required: deactivateMode === 'CUSTOM' }}
          minDate={DateTime.now().startOf('month').toJSDate()}
          maxDate={DateTime.now().plus({ years: 10 }).toJSDate()}
        />
      </Stack>
      <Stack direction="row" justifyContent="flex-end" spacing={1} mt={3}>
        <DialogLoadingButton type="submit" color="primary" loading={formState.isSubmitting}>
          {t('common:buttons.confirm')}
        </DialogLoadingButton>
      </Stack>
    </form>
  );
};
