import CheckIcon from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Stack, Typography } from '@mui/material';
import { useTenant } from 'probonio-shared-ui/module/me';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const HRSystemSyncStatus: React.FC = () => {
  const { t } = useTranslation('tenantModule');
  const { tenant } = useTenant();

  if (!tenant?.hrSystemType) {
    return <></>;
  }

  return (
    <Stack direction="column" alignItems="center">
      {tenant.hrSystemSyncError ? (
        <Typography color="warning.main">
          <WarningAmberIcon sx={{ verticalAlign: 'text-bottom' }} /> {t('hrSystem.status.syncError', { message: tenant.hrSystemSyncError })}
        </Typography>
      ) : (
        <Typography color="success.main">
          <CheckIcon sx={{ verticalAlign: 'text-bottom' }} /> {t('hrSystem.status.connected')}
        </Typography>
      )}
      {tenant.hrSystemLastSync && (
        <Typography variant="body2" color="text.secondary">
          <Trans i18nKey="tenantModule:hrSystem.status.lastSync" values={{ date: new Date(tenant.hrSystemLastSync) }} />
        </Typography>
      )}
    </Stack>
  );
};
