import { Box, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { MRT_Cell, MRT_ColumnDef, MRT_Row, MRT_TableInstance } from 'material-react-table';
import { CouponTransactionHistoryDTO, CouponTransactionHistoryDTOTransactionTypeEnum } from 'probonio-shared-ui/api';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAggregationContext } from './aggregationContext';
import { renderUserName } from '../../userManagement/userFormatter';

interface CellProps {
  cell: MRT_Cell<CouponTransactionHistoryDTO>;
  row: MRT_Row<CouponTransactionHistoryDTO>;
  table: MRT_TableInstance<CouponTransactionHistoryDTO>;
}

const DateCell = ({ cell, table }: CellProps): JSX.Element => {
  const { i18n } = useTranslation('couponsModule');

  return (
    <Box marginLeft={table.getState().grouping[0] === 'month' ? 1 : 0}>
      {cell.renderValue() ? i18n.format(new Date(cell.renderValue() as string), 'date') : undefined}
    </Box>
  );
};

const AmountCell = ({ cell, row }: CellProps): JSX.Element => {
  const { t } = useTranslation('couponsModule');
  const theme = useTheme();

  const color = (cell.getValue() as number) >= 0 ? theme.palette.success.main : theme.palette.error.main;

  return (
    <Typography component="span" variant="inherit" color={color}>
      {t('common:money', { money: cell.renderValue() })}
    </Typography>
  );
};

const DetailsCell = ({ cell, row }: CellProps): JSX.Element => {
  const { t } = useTranslation('couponsModule');
  const transactionType = cell.renderValue() as CouponTransactionHistoryDTOTransactionTypeEnum;

  if (transactionType === CouponTransactionHistoryDTOTransactionTypeEnum.CouponOrder) {
    return <>{t('ordersTable.row.couponOrder')}</>;
  }
  if (transactionType === CouponTransactionHistoryDTOTransactionTypeEnum.GiftOrder) {
    return <>{t('ordersTable.row.giftOrder', { occasion: row.original.giftOccasion })}</>;
  }
  return <>{t('ordersTable.row.budgetOrder.COMPLETED')}</>;
};

const AggregatedAmountCell = ({ aggregation, row }: CellProps & { aggregation: string }): JSX.Element => {
  const { t } = useTranslation('couponsModule');
  const theme = useTheme();
  const aggregationBuckets = useAggregationContext()[aggregation];
  const bucket = aggregationBuckets?.find(bucket => bucket.bucketKey === row.groupingValue);

  if (!bucket) {
    return <></>;
  }

  const color = (bucket.value as number) >= 0 ? theme.palette.success.main : theme.palette.error.main;

  return (
    <Typography component="span" variant="inherit" color={color}>
      {t('common:money', { money: bucket.value })}
    </Typography>
  );
};

export function useTransactionColumns(): MRT_ColumnDef<CouponTransactionHistoryDTO>[] {
  const { t } = useTranslation('couponsModule');

  return useMemo<MRT_ColumnDef<CouponTransactionHistoryDTO>[]>(
    () => [
      {
        id: 'month',
        accessorFn: transaction => DateTime.fromISO(transaction.transactionDate).toFormat('yyyy-MM'),
        header: t('ordersTable.header.date'),
      },
      {
        accessorKey: 'transactionDate',
        header: t('ordersTable.header.date'),
        Cell: DateCell,
        aggregationFn: 'max',
        AggregatedCell: ({ cell }) => <b>{DateTime.fromISO(cell.getValue<string>()).toLocaleString({ month: 'long', year: 'numeric' })}</b>,
      },
      {
        id: 'employeeName',
        accessorFn: transaction =>
          renderUserName(
            { firstName: transaction.employeeFirstName, lastName: transaction.employeeLastName, mail: transaction.employeeMail },
            undefined,
            transaction.employeeInactive,
          ),
        header: t('ordersTable.header.employee'),
        enableSorting: false,
      },
      {
        accessorKey: 'amount',
        header: t('ordersTable.header.amount'),
        Cell: AmountCell,
        AggregatedCell: props => (
          <b>
            <AggregatedAmountCell aggregation="amountByMonth" {...props} />
          </b>
        ),
      },
      {
        accessorKey: 'transactionType',
        header: t('ordersTable.header.details'),
        Cell: DetailsCell,
        enableSorting: false,
      },
    ],
    [t],
  );
}
