import { Button, Tooltip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';

interface Props {
  onRevertFilters: () => void;
}

export const EmployeeClearFilterButton: React.FC<Props> = ({ onRevertFilters }) => {
  const { t } = useTranslation('common');
  return (
    <Tooltip title={t('clearFilter')}>
      <Button sx={{ height: '100%' }} onClick={onRevertFilters} size="small" variant="topBarButtonBorder">
        <ClearIcon />
      </Button>
    </Tooltip>
  );
};
