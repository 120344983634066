import { useEffect } from 'react';
import { Control, useController } from 'react-hook-form';
import { FormValues } from '../../../useCustomBenefitCreator';

interface Props {
  control: Control<FormValues>;
  setCanContinue: (newValue: boolean) => void;
}
// TODO: Think of better structure as this was only way to prevent whole component from rerendering
export const DashboardContentValidation: React.FC<Props> = ({ control, setCanContinue }) => {
  const { fieldState } = useController({ name: 'title', control: control });
  const { field: iconField } = useController({ name: 'icon', control: control });

  useEffect(() => {
    setCanContinue(!fieldState.error && !!iconField.value);
  }, [fieldState.error, iconField.value, setCanContinue]);

  return <></>;
};
