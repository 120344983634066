import DeleteIcon from '@mui/icons-material/Delete';
import { Alert, Button } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useNewDialogState } from '../../../component/dialog';
import { ConfirmationModal } from '../../../component/dialog/ConfirmationModal';

interface Props {
  customBenefitId?: string;
  customBenefitTitle?: string;
}

export const DeleteCustomBenefitButton: React.FC<Props> = ({ customBenefitId, customBenefitTitle }) => {
  const { t } = useTranslation('customBenefitsModule');
  const confirmDeletionDialog = useNewDialogState();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { tenant } = useTenant();
  const [conflictCount, setConflictCount] = useState<{ employees: number; templates: number }>();

  const deleteMutation = useMutation({
    mutationFn: () =>
      apis.customBenefits.deleteCustomBenefit({
        tenantId: tenant?.id!,
        customBenefitId: customBenefitId!,
      }),

    onSuccess: async () => {
      enqueueSnackbar(t('customBenefitCreatorPage.successDelete'), { variant: 'success' });
      navigate('/');
    },
    onError: err => {
      if (err instanceof AxiosError && err.response?.status === 409) {
        const serviceExData = err.response.data;
        if (
          typeof serviceExData === 'object' &&
          'data' in serviceExData &&
          'employees' in serviceExData.data &&
          'templates' in serviceExData.data
        ) {
          setConflictCount(serviceExData.data);
          return;
        }
      }
    },
  });
  const handleDelete = useCallback(() => {
    setConflictCount(undefined);
    deleteMutation.mutate();
  }, [deleteMutation]);

  return (
    <>
      <ConfirmationModal
        dataTestId="confirm-deletion-button"
        dialogState={confirmDeletionDialog.dialogState}
        onConfirm={handleDelete}
        closeOnConfirm={false}
        loading={deleteMutation.isPending}
        title={t('customBenefitDeletionModal.title')}
      >
        {conflictCount && (
          <Alert severity="error" sx={{ mb: 2 }} data-test-id="custom-benefit-delete-conflict-alert">
            {t('customBenefitDeletionModal.conflict', { title: customBenefitTitle, ...conflictCount })}
          </Alert>
        )}
        {t('customBenefitDeletionModal.text', { title: customBenefitTitle })}
      </ConfirmationModal>
      <Button
        data-test-id="delete-custom-benefit-button"
        color="error"
        variant="outlined"
        startIcon={<DeleteIcon />}
        onClick={confirmDeletionDialog.handleOpen}
      >
        {t('customBenefitCreatorPage.deleteCustomBenefit')}
      </Button>
    </>
  );
};
