import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { meReducer } from 'probonio-shared-ui/module/me';
import { navigationReducer } from '../module/navigation/navigationSlice';
import { filterReducer } from '../module/userManagement/filter';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

export const store = configureStore({
  reducer: {
    me: meReducer,
    navigation: navigationReducer,
    filter: filterReducer,
  },
  enhancers: getDefaultEnhancers => getDefaultEnhancers().concat(sentryReduxEnhancer),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
