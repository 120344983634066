import { useTranslation } from 'react-i18next';
import { Box, Paper, Divider } from '@mui/material';
import CustomBenefitStepper, { Step } from './CustomBenefitStepper';
import ContentStepper from './ContentStepper/ContentStepper';
import { useCallback, useMemo, useState } from 'react';
import PreviewStepper, { PREVIEW_WIDTH } from './PreviewStepper/PreviewStepper';
import { DashboardContent } from './ContentStepper/DashboardContent';
import { HorizontalBox, VerticalBox } from '../../../../component/layout/BoxLayout';
import { HelpContent } from './ContentStepper/HelpContent';
import { VisualisationContent } from './ContentStepper/VisualisationContent';
import { DashboardPreviewForm } from './PreviewStepper/DashboardPreview/DashboardPreviewForm';
import { HelpPreviewForm } from './PreviewStepper/HelpPreview/HelpPreviewForm';
import { VisualisationPreviewForm } from './PreviewStepper/VisualisationPreview/VisualisationPreviewForm';
import { CustomBenefitCreatorState } from '../useCustomBenefitCreator';
import { UnsavedChangesListener } from '../../../../component/form/UnsavedChangesListener';

interface Props {
  customBenefitCreatorState: CustomBenefitCreatorState;
}

export const CustomBenefitCreator: React.FC<Props> = ({ customBenefitCreatorState }) => {
  const { control, handleSubmit, formState } = customBenefitCreatorState.form;
  const { t } = useTranslation('customBenefitsModule');

  const [activeStep, setActiveStep] = useState(0);
  const [canContinue, setCanContinue] = useState(false);

  const canContinueStep = useCallback(
    (stepNum: number) => (canContinue: boolean) => {
      if (activeStep !== stepNum) {
        return;
      }

      setCanContinue(canContinue);
    },
    [activeStep],
  );

  const steps: Step[] = useMemo(
    () => [
      {
        label: t('customBenefitCreator.content.summaryStep.title'),
        description: t('customBenefitCreator.content.summaryStep.description'),
        content: () => <DashboardContent control={control} setCanContinue={canContinueStep(0)} />,
        preview: () => <DashboardPreviewForm control={control} />,
      },
      {
        label: t('customBenefitCreator.content.textStep.title'),
        description: t('customBenefitCreator.content.textStep.description'),
        content: () => <HelpContent control={control} setCanContinue={canContinueStep(1)} />,
        preview: () => <HelpPreviewForm control={control} />,
      },
      {
        label: t('customBenefitCreator.content.visualisationStep.title'),
        description: t('customBenefitCreator.content.visualisationStep.description'),
        content: () => <VisualisationContent control={control} formState={formState} setCanContinue={canContinueStep(2)} />,
        preview: () => <VisualisationPreviewForm control={control} />,
      },
    ],
    [canContinueStep, control, formState, t],
  );

  if (!customBenefitCreatorState?.currentCustomBenefitTemplate) {
    return <></>;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'flex-start',
        height: '730px',
      }}
      gap={3}
      justifyContent="space-around"
    >
      <UnsavedChangesListener formState={formState} />
      {/* LEFT */}
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          minWidth: '900px',
          height: '100%',
        }}
      >
        <HorizontalBox height="100%" flexGrow={1}>
          <Box mx={2} display="flex" my={5} maxWidth="20%">
            <CustomBenefitStepper steps={steps} activeStep={activeStep} />
          </Box>

          <Box sx={{ my: 3 }}>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                height: '100%',
              }}
            />
          </Box>
          <VerticalBox flex="1" mx={5} my={5} position="relative">
            <ContentStepper
              canContinue={canContinue}
              onSubmit={handleSubmit(customBenefitCreatorState.handleSubmit)}
              isSubmitting={formState.isSubmitting}
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          </VerticalBox>
        </HorizontalBox>
      </Paper>

      {/* RIGHT */}
      {/* scaled down to avoid wasting space */}
      <Paper
        sx={{
          flex: 0,
          width: (PREVIEW_WIDTH + 40) * 0.8,
          height: '100%',
        }}
      >
        <Box sx={{ transform: 'scale(0.8) translateX(-12.5%) translateY(-12.5%)', height: '125%', width: '125%' }}>
          <PreviewStepper steps={steps} activeStep={activeStep} />
        </Box>
      </Paper>
    </Box>
  );
};
