import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { Trans, useTranslation } from 'react-i18next';
import { DashboardPanel } from '../../component/dashboard/DashboardPanel';
import { BenefitGrantChartPlaceholder } from './BenefitGrantChartPlaceholder';
import { TextWithCaret, TextWithCaretPlaceholder } from './TextWithCaret';
import { BenefitGrantChart } from './charts/BenefitGrantChart';

export const BenefitGrantChartDashboardPanel: React.FC<{ rows?: number }> = ({ rows }) => {
  const { t } = useTranslation('dashboardModule');

  const { data: financialReport, isLoading } = useTenantQuery(
    ['benefits', 'financialReport', apis.benefits.getFinancialReport],
    tenantId => apis.benefits.getFinancialReport({ tenantId }).then(res => res.data),
    { staleTime: Infinity },
  );

  const isReportEmpty = financialReport && !financialReport.totalGrant;

  return (
    <DashboardPanel
      rows={rows}
      title={t('benefitsPanel.totalGrantTitle')}
      subTitle={
        <Trans i18nKey="dashboardModule:benefitsPanel.totalGrant" values={{ amount: financialReport?.totalGrant }}>
          {isLoading ? <TextWithCaretPlaceholder /> : <TextWithCaret />}
        </Trans>
      }
    >
      {!isLoading && !isReportEmpty && <BenefitGrantChart reportData={financialReport?.benefits || []} />}
      {(isReportEmpty || isLoading) && <BenefitGrantChartPlaceholder isLoading={isLoading} />}
    </DashboardPanel>
  );
};
