import { useMutation } from '@tanstack/react-query';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useCallback, useMemo } from 'react';

export function useManualStep(stepId: string): { loading?: boolean; completed?: boolean; onComplete: () => void } {
  const { tenant, invalidateTenant } = useTenant();

  const loading = !tenant;
  const completed = tenant?.completedTutorialSteps?.includes(stepId);

  const mutation = useMutation({
    mutationFn: () =>
      apis.tenants.updateTenant({
        tenantId: tenant!.id,
        updateTenantDTO: { completedTutorialSteps: [...(tenant?.completedTutorialSteps || []), stepId] },
      }),
    onSuccess: invalidateTenant,
  });

  const handleComplete = useCallback(() => {
    mutation.mutate();
  }, [mutation]);

  return useMemo(
    () => ({
      loading,
      completed,
      onComplete: handleComplete,
    }),
    [completed, handleComplete, loading],
  );
}
