import { useTranslation } from 'react-i18next';
import { BenefitPaper } from '../../component/benefitTabs/BenefitPaper';
import { BENEFIT_ICONS } from '../../module/benefits/BenefitIcon';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { BAVTermsForm } from '../../module/benefits/bav/terms/BAVTermsForm';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';

const BAVTermsPage: React.FC = () => {
  const { t } = useTranslation('benefitsModule');

  return (
    <AuthorizedLayout title={t('bavModule:terms.title')} breadcrumbs={[{ title: t('benefitsModule:BAV.title'), to: '/benefits/bav' }]}>
      <BenefitSubPage icon={BENEFIT_ICONS.BAV} title={t('BAV.title')} subTitle={t('bavModule:terms.title')}>
        <BenefitPaper>
          <BAVTermsForm />
        </BenefitPaper>
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};

export default BAVTermsPage;
