import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

export interface LoadMoreProps<T> {
  data: T[];
  onLoadMore: () => void;
  hasMore?: boolean;
  rowCount?: number;
  /** true when fetching initial data, or when the parameters have changed */
  isLoading?: boolean;
  /** true whenever a request is ongoing (including fetching more rows) */
  isFetching?: boolean;
  isError?: boolean;
}

type ListData<TKey extends string> = { [key in TKey]: unknown[] } & { totalCount: number };

export function useLoadMore<TKey extends string, TListDTO extends ListData<TKey>, TData extends TListDTO[TKey][0]>(
  queryResult: UseInfiniteQueryResult<InfiniteData<TListDTO>>,
  dataKey: TKey,
): LoadMoreProps<TData> {
  const { data, fetchNextPage, hasNextPage, isError, isLoading, isFetching, isFetchingNextPage } = queryResult;

  const flatData = useMemo<TData[]>(() => data?.pages.flatMap(page => page[dataKey] as TData[]) ?? [], [data?.pages, dataKey]);
  const rowCount = data?.pages[0].totalCount || 0;

  const handleLoadMore = useCallback(() => {
    void fetchNextPage();
  }, [fetchNextPage]);

  return {
    data: flatData,
    onLoadMore: handleLoadMore,
    hasMore: hasNextPage && !isFetching,
    rowCount,
    isLoading: isLoading || (isFetching && !isFetchingNextPage),
    isFetching,
    isError,
  };
}
