import { InputAdornment } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  BenefitActivationDTO,
  BenefitDTOBenefitEnum,
  BenefitTemplateItemDTO,
  CreateBenefitActivationDTO,
  UpdateBenefitActivationDTO,
} from 'probonio-shared-ui/api';
import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextFieldControl } from '../../../component/form';
import { ActivationDialogExtensions } from '../ActivationDialog';

interface BAVBenefitOptions {
  additionalPaymentPercent: number;
  vwlConversion: number;
}

const Fields: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { control } = useFormContext();

  return (
    <Grid container columnSpacing={2} marginTop={2}>
      <Grid size={6}>
        <TextFieldControl
          slotProps={{ htmlInput: { step: '5' }, input: { endAdornment: <InputAdornment position="end">%</InputAdornment> } }}
          rules={{
            required: true,
            min: 0,
            max: 100,
          }}
          type="number"
          control={control}
          variant="outlined"
          name="benefitOptions.additionalPaymentPercent"
          label={t('BAV.fields.additionalPaymentPercent')}
          margin="dense"
          fullWidth
        />
      </Grid>
      <Grid size={6}>
        <TextFieldControl
          slotProps={{ htmlInput: { step: '5' }, input: { endAdornment: <InputAdornment position="end">€</InputAdornment> } }}
          rules={{
            required: true,
            min: 0,
            max: 40,
          }}
          type="number"
          control={control}
          variant="outlined"
          name="benefitOptions.vwlConversion"
          label={t('BAV.fields.vwlConversion')}
          margin="dense"
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export function useBAVDialogExtensions(): ActivationDialogExtensions<BAVBenefitOptions> {
  const handleResetBenefitOptions = useCallback((selectedActivation?: BenefitActivationDTO | BenefitTemplateItemDTO): BAVBenefitOptions => {
    return {
      vwlConversion: selectedActivation?.bavOptions ? selectedActivation.bavOptions.vwlConversion / 100 : 0,
      additionalPaymentPercent: selectedActivation?.bavOptions?.additionalPaymentPercent ?? 30,
    };
  }, []);

  const mapBAVOptions = useCallback(
    (benefitOptions: BAVBenefitOptions) => ({
      additionalPaymentPercent: Math.round(benefitOptions.additionalPaymentPercent),
      vwlConversion: Math.round(benefitOptions.vwlConversion * 100),
    }),
    [],
  );
  const handleCreateActivation = useCallback<ActivationDialogExtensions<BAVBenefitOptions>['onCreateActivation']>(
    async (baseValues, benefitOptions, saveActivation) => {
      const create: CreateBenefitActivationDTO = {
        ...baseValues,
        benefit: BenefitDTOBenefitEnum.Bav,
        bavOptions: mapBAVOptions(benefitOptions),
      };
      await saveActivation(create);
    },
    [mapBAVOptions],
  );
  const handleUpdateActivation = useCallback<NonNullable<ActivationDialogExtensions<BAVBenefitOptions>['onUpdateActivation']>>(
    async (baseValues, benefitOptions, saveActivation) => {
      const update: UpdateBenefitActivationDTO = {
        ...baseValues,
        bavOptions: mapBAVOptions(benefitOptions),
      };
      await saveActivation(update);
    },
    [mapBAVOptions],
  );

  return useMemo(
    () => ({
      Fields,
      onResetBenefitOptions: handleResetBenefitOptions,
      onCreateActivation: handleCreateActivation,
      onUpdateActivation: handleUpdateActivation,
    }),
    [handleCreateActivation, handleResetBenefitOptions, handleUpdateActivation],
  );
}
