import { Box, Typography } from '@mui/material';
import { SwitchControl } from 'probonio-shared-ui/component/form';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ContentEditor } from '../../../../component/contentEditor/ContentEditor';
import { UnsavedChangesListener } from '../../../../component/form/UnsavedChangesListener';
import { SettingsFormPanel } from '../../../../component/settings/SettingsFormPanel';
import { SettingsFormRow } from '../../../../component/settings/SettingsFormRow';
import { TermsControls, useBavTerms } from './useBavTerms';

export const BAVTermsForm: React.FC = () => {
  const { t } = useTranslation('bavModule');
  const { form, handleSubmit, isLoading, handleReset } = useBavTerms();

  return (
    <SettingsFormPanel
      title={t('terms.integration')}
      info={t('terms.info')}
      isDirty={form.formState.isDirty}
      isLoading={isLoading}
      onSubmit={form.handleSubmit(handleSubmit)}
      onReset={handleReset}
    >
      <SettingsFormRow
        settingsWidth={8}
        descriptionWidth={4}
        title={
          <Typography variant="h3" gutterBottom>
            {t('bavModule:terms.guide.title')}
          </Typography>
        }
        info={
          <>
            <Typography variant="body2" color="text.secondary">
              <Trans i18nKey="bavModule:terms.guide.info" />
            </Typography>
            <Typography variant="h3" marginTop={3}>
              {t('bavModule:terms.guide.section1Title')}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <Trans i18nKey="bavModule:terms.guide.section1Text" />
            </Typography>
          </>
        }
      >
        <UnsavedChangesListener formState={form.formState} />

        <ContentEditor control={form.control} name={TermsControls.termsHtml} />
        <Box marginTop={3}>
          <SwitchControl
            data-test-id="show-to-employees-switch"
            disabled={isLoading}
            control={form.control}
            name={TermsControls.showToEmployeesSwitch}
            label={t('terms.showToEmployees')}
          />
        </Box>
      </SettingsFormRow>
    </SettingsFormPanel>
  );
};
