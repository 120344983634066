import GradingIcon from '@mui/icons-material/Grading';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { CouponsV2TokenOrderDTOStatusEnum } from 'probonio-shared-ui/api';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import { apis, downloadResponse } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';
import useDebounce from 'probonio-shared-ui/utils/useDebounce';
import { ChangeEventHandler, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewDialogState } from '../../../../component/dialog';
import { SearchInput } from '../../../../component/form/SearchInput';
import { HorizontalBox, VerticalBox } from '../../../../component/layout/BoxLayout';
import { EmployeeClearFilterButton } from '../../employeeStatusList/EmployeeClearFilterButton';
import ReportDownloadDialog from '../../ReportDownloadDialog/ReportDownloadDialog';
import { OrdersTable, TokenOrderType } from './OrdersTable';

interface Props {}

export const TokenOrderHistoryList: React.FC<Props> = () => {
  const { t } = useTranslation('couponsV2Module');
  const tenantId = useTenantID();

  const [orderType, setOrderType] = useState<TokenOrderType>();
  const [orderStatus, setOrderStatus] = useState<CouponsV2TokenOrderDTOStatusEnum>();
  const [filterValue, setFilterValue] = useState('');
  const debouncedFilterValue = useDebounce(filterValue);
  const handleChangeFilter = useCallback<ChangeEventHandler<HTMLInputElement>>(ev => {
    setFilterValue(ev.target.value);
  }, []);

  const handleChangeOrderType = useCallback((e: SelectChangeEvent<string>) => {
    setOrderType((e.target.value as TokenOrderType) || undefined);
  }, []);

  const handleChangeOrderStatus = useCallback((e: SelectChangeEvent<string>) => {
    setOrderStatus((e.target.value as CouponsV2TokenOrderDTOStatusEnum) || undefined);
  }, []);

  const exportDialog = useNewDialogState();
  const exportMutation = useMutation({
    mutationFn: (month?: DateTime) =>
      apis.couponsV2.exportTokenOrders({ tenantId: tenantId(), month: month?.toFormat('yyyy-LL') }).then(res => {
        downloadResponse(res, 'Coupon-Order-History.csv');
      }),
  });
  const handleExport = useCallback(
    (month?: DateTime) => {
      exportMutation.mutate(month, { onSuccess: () => exportDialog.dialogState.handleClose() });
    },
    [exportDialog.dialogState, exportMutation],
  );

  const handleRevertFilters = useCallback(() => {
    setFilterValue('');
    setOrderType(undefined);
    setOrderStatus(undefined);
  }, []);

  return (
    <>
      <VerticalBox>
        <HorizontalBox sx={{ marginBottom: 1.5 }}>
          <HorizontalBox gap={1} sx={{ flexGrow: 1 }}>
            <SearchInput size="small" value={filterValue} onChange={handleChangeFilter} />
            <FormControl size="small">
              <InputLabel id="order-type-select-label">{t('ordersTable.orderTypeFilter.label')}</InputLabel>
              <Select
                data-test-id="order-type-selector"
                label={t('ordersTable.orderTypeFilter.label')}
                labelId="order-type-select-label"
                sx={{ minWidth: 100, width: 300, height: '100%' }}
                value={orderType || ''}
                onChange={handleChangeOrderType}
              >
                <MenuItem value="">
                  <em data-test-id="order-type-selector-all">{t('ordersTable.orderTypeFilter.all')}</em>
                </MenuItem>
                <MenuItem value="BENEFIT_ACTIVATION">{t('ordersTable.orderTypeFilter.BENEFIT_ACTIVATION')}</MenuItem>
                <MenuItem value="GIFT">{t('ordersTable.orderTypeFilter.GIFT')}</MenuItem>
                <MenuItem value="INCENTIVE">{t('ordersTable.orderTypeFilter.INCENTIVE')}</MenuItem>
              </Select>
            </FormControl>
            <FormControl size="small">
              <InputLabel id="order-status-select-label">{t('ordersTable.orderStatusFilter.label')}</InputLabel>
              <Select
                data-test-id="order-status-selector"
                label={t('ordersTable.orderStatusFilter.label')}
                labelId="order-status-select-label"
                sx={{ minWidth: 100, width: 300, height: '100%' }}
                value={orderStatus || ''}
                onChange={handleChangeOrderStatus}
              >
                <MenuItem value="">
                  <em data-test-id="order-status-selector-all">{t('ordersTable.orderTypeFilter.all')}</em>
                </MenuItem>
                {Object.values(CouponsV2TokenOrderDTOStatusEnum)
                  .filter(status => status !== CouponsV2TokenOrderDTOStatusEnum.Cancelled)
                  .map(status => (
                    <MenuItem key={status} value={status}>
                      {t(`ordersTable.row.status.${status}`)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {(filterValue || orderType || orderStatus) && <EmployeeClearFilterButton onRevertFilters={handleRevertFilters} />}
          </HorizontalBox>
          <Box>
            <Tooltip title={t('orderHistoryList.export') || ''}>
              <LoadingButton
                sx={{ marginLeft: 2, p: 1 }}
                onClick={exportDialog.handleOpen}
                loading={exportMutation.isPending}
                variant="topBarButtonBorder"
                size="small"
              >
                <GradingIcon />
              </LoadingButton>
            </Tooltip>
          </Box>
        </HorizontalBox>
        <OrdersTable filter={debouncedFilterValue} orderType={orderType} orderStatus={orderStatus} />
      </VerticalBox>
      <ReportDownloadDialog
        message={t('reportDialogMessage')}
        loading={exportMutation.isPending}
        dialogState={exportDialog.dialogState}
        handleConfirm={handleExport}
      />
    </>
  );
};
