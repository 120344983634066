import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EventIcon from '@mui/icons-material/Event';
import { Box, IconButton, InputAdornment, TextField, TextFieldProps, Tooltip, useTheme } from '@mui/material';
import { de } from 'date-fns/locale/de';
import { enGB } from 'date-fns/locale/en-GB';
import { DateTime } from 'luxon';
import React, { useCallback } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { DatePickerContainer } from './DatePickerContainer';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';

import 'react-datepicker/dist/react-datepicker.css';
registerLocale('de', de);
registerLocale('en', enGB);

type Props = Pick<TextFieldProps, 'sx' | 'error' | 'required' | 'helperText' | 'fullWidth' | 'margin' | 'size' | 'label' | 'slotProps'> & {
  selectsRange?: undefined;
  dataTestId?: string;
  value?: Date;
  minDate?: Date;
  maxDate?: Date;
  minTime?: Date;
  maxTime?: Date;
  dateFormat?: string;
  monthYearPicker?: boolean;
  showYearDropdown?: boolean;
  showNextPreviousToggle?: boolean;
  showTimeSelect?: boolean;
  customInput?: React.ReactElement;
  disabled?: boolean;
  showClearButton?: boolean;
  clearButtonTitle?: string;
  // eslint-disable-next-line @rushstack/no-new-null
  onChange: (value: Date | null) => void;
};

type RangeProps = Pick<
  TextFieldProps,
  'sx' | 'error' | 'required' | 'helperText' | 'fullWidth' | 'margin' | 'size' | 'label' | 'slotProps'
> & {
  selectsRange: true;
  dataTestId?: string;
  value?: [Date, Date];
  minDate?: Date;
  maxDate?: Date;
  minTime?: Date;
  maxTime?: Date;
  dateFormat?: string;
  monthYearPicker?: boolean;
  showYearDropdown?: boolean;
  showNextPreviousToggle?: false;
  showTimeSelect?: boolean;
  customInput?: React.ReactElement;
  disabled?: boolean;
  showClearButton?: boolean;
  clearButtonTitle?: string;
  // eslint-disable-next-line @rushstack/no-new-null
  onChange: (value: [Date | null, Date | null]) => void;
};

export const CustomDatePicker: React.FC<Props | RangeProps> = React.forwardRef<HTMLInputElement, Props | RangeProps>(
  (
    {
      selectsRange,
      dateFormat,
      onChange,
      dataTestId,
      value,
      required,
      monthYearPicker,
      showYearDropdown,
      showNextPreviousToggle,
      showTimeSelect,
      customInput,
      minDate,
      maxDate,
      minTime,
      maxTime,
      disabled,
      showClearButton,
      clearButtonTitle,
      ...textFieldProps
    },
    ref,
  ) => {
    const theme = useTheme();
    const { i18n } = useTranslation();

    const handlePrevious = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
      event => {
        event.stopPropagation();
        if (selectsRange) {
          return;
        }
        if (value) {
          onChange(new Date(value.setMonth(value.getMonth() - 1)));
        }
      },
      [onChange, selectsRange, value],
    );

    const handleNext = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
      event => {
        event.stopPropagation();
        if (selectsRange) {
          return;
        }
        if (value) {
          onChange(new Date(value.setMonth(value.getMonth() + 1)));
        }
      },
      [onChange, selectsRange, value],
    );

    const handleClear = useCallback(() => {
      if (selectsRange) {
        onChange([null, null]);
      } else {
        onChange(null);
      }
    }, [onChange, selectsRange]);

    const renderInput = (): JSX.Element => {
      return (
        <TextField
          data-test-id={dataTestId}
          {...textFieldProps}
          slotProps={{
            inputLabel: { required: required },
            input: {
              ...textFieldProps.slotProps?.input,
              inputRef: ref,
              autoComplete: 'off',
              startAdornment: showNextPreviousToggle ? (
                <InputAdornment position="start">
                  <IconButton
                    edge="start"
                    sx={{
                      borderRadius: 0,
                      borderTopLeftRadius: theme.shape.borderRadius,
                      borderBottomLeftRadius: theme.shape.borderRadius,
                    }}
                    disabled={
                      minDate &&
                      value &&
                      DateTime.fromJSDate(value).minus({ month: 1 }).startOf('month') < DateTime.fromJSDate(minDate).startOf('month')
                    }
                    onClick={handlePrevious}
                  >
                    <ArrowBackIosNewIcon />
                  </IconButton>
                </InputAdornment>
              ) : undefined,
              endAdornment: (
                <InputAdornment position="end">
                  {showClearButton && (
                    <Tooltip title={clearButtonTitle}>
                      <Box>
                        <IconButton disabled={!value} onClick={handleClear}>
                          <ClearIcon />
                        </IconButton>
                      </Box>
                    </Tooltip>
                  )}
                  <IconButton>
                    <EventIcon />
                  </IconButton>
                  {showNextPreviousToggle && (
                    <IconButton
                      edge="end"
                      sx={{
                        borderRadius: 0,
                        borderTopRightRadius: theme.shape.borderRadius,
                        borderBottomRightRadius: theme.shape.borderRadius,
                      }}
                      disabled={
                        maxDate &&
                        value &&
                        DateTime.fromJSDate(value).plus({ month: 1 }).startOf('month') > DateTime.fromJSDate(maxDate).startOf('month')
                      }
                      onClick={handleNext}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            },
          }}
        />
      );
    };

    return (
      <DatePickerContainer width="100%">
        <DatePicker
          dateFormat={dateFormat || (monthYearPicker ? 'MMMM y' : 'P')}
          selected={selectsRange ? value?.[0] : value}
          selectedDates={selectsRange ? value : undefined}
          locale={i18n.resolvedLanguage}
          minDate={minDate}
          maxDate={maxDate}
          minTime={minTime}
          maxTime={maxTime}
          popperPlacement="bottom"
          dropdownMode="select"
          showMonthYearPicker={monthYearPicker}
          showYearDropdown={showYearDropdown}
          disabled={disabled}
          customInput={customInput || renderInput()}
          showTimeSelect={showTimeSelect}
          {...(selectsRange ? { startDate: value?.[0], endDate: value?.[1], selectsRange, onChange } : { selected: value, onChange })}
        />
      </DatePickerContainer>
    );
  },
);
