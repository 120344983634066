import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { MRT_ColumnDef } from 'material-react-table';
import { BenefitDTOBenefitEnum, EmployeeDTO, EmployeeDTOStatusEnum, UserDTOStatusEnum } from 'probonio-shared-ui/api';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SortByDef } from '../../component/table/sortMapper';
import i18n from '../../lang';
import { BenefitRef, BenefitsCell } from '../benefits/BenefitsCell';
import { AnonymousAvatar } from './AnonymousAvatar';
import { InviteUserButton } from './InviteUserButton';
import { UnlockUserButton } from './UnlockUserButton';
import { UserNameAndAvatar } from './UserNameAndAvatar';
import { mapEmployeeStatus } from './employeeStatus';

const StatusCell: React.FC<{ employee: EmployeeDTO }> = ({ employee }) => {
  const { t } = useTranslation('common');
  const { status, color, scheduledInviteDate, inviteWhenActive } = mapEmployeeStatus(employee);

  if (
    employee.user?.status === 'PENDING' &&
    employee.user?.expiryDateRegisterLink &&
    DateTime.fromISO(employee.user.expiryDateRegisterLink) <= DateTime.utc()
  ) {
    return (
      <InviteUserButton
        data-test-id="invite-expired-user-link"
        employee={employee}
        tooltip={t(`userStatusButton.tooltip.pendingTooltip`)}
        buttonProps={{
          size: 'small',
          color: 'success',
          variant: 'contained',
        }}
      >
        <Trans i18nKey={`common:userStatusButton.${status}`} />
      </InviteUserButton>
    );
  } else if (
    scheduledInviteDate &&
    inviteWhenActive &&
    DateTime.fromISO(scheduledInviteDate) > DateTime.utc().minus({ day: 1 }) &&
    status === UserDTOStatusEnum.Created
  ) {
    return (
      <InviteUserButton
        data-test-id="invite-user-link"
        employee={employee}
        tooltip={t(`userStatusButton.tooltip.scheduledTooltip`, {
          scheduledInviteDate: DateTime.fromISO(scheduledInviteDate || '').toLocaleString(DateTime.DATE_SHORT),
        })}
        buttonProps={{
          size: 'small',
          color: 'success',
          variant: 'contained',
        }}
      >
        <AccessTimeIcon sx={{ marginRight: 0.5 }} />
        <Trans i18nKey={t('userStatusButton.SCHEDULED')} />
      </InviteUserButton>
    );
  } else if (status === UserDTOStatusEnum.Created) {
    return (
      <InviteUserButton
        data-test-id="invite-user-link"
        tooltip={t(`userStatusButton.tooltip.createdTooltip`)}
        employee={employee}
        buttonProps={{
          size: 'small',
          color: 'success',
          variant: 'contained',
        }}
      >
        <Trans i18nKey={`common:userStatusButton.${status}`} />
      </InviteUserButton>
    );
  } else if (status === EmployeeDTOStatusEnum.Registered) {
    return (
      <UnlockUserButton
        employee={employee}
        tooltip={t(`userStatusButton.tooltip.registeredTooltip`)}
        buttonProps={{
          size: 'small',
          color: 'success',
          variant: 'contained',
        }}
      >
        <Trans i18nKey={`common:userStatusButton.${status}`} />
      </UnlockUserButton>
    );
  }
  return (
    <Typography variant="inherit" color={color}>
      <Trans i18nKey={`common:userStatus.${status}`} />
    </Typography>
  );
};

export const EmployeeBenefitsCell: React.FC<{ employee: EmployeeDTO }> = ({ employee }) => {
  const activeBenefits: BenefitRef[] = employee.activeBenefits?.map(benefit => ({ benefit })) || [];
  if (employee.nextGift?.payoutDate) {
    activeBenefits.push({ benefit: 'GIFTS' });
  }
  activeBenefits.push(
    ...(employee.activeCustomBenefits || []).map(customBenefitId => ({ benefit: BenefitDTOBenefitEnum.Custom, customBenefitId })),
  );

  const upcomingBenefits: BenefitRef[] = employee.upcomingBenefits?.map(benefit => ({ benefit })) || [];
  upcomingBenefits.push(
    ...(employee.upcomingCustomBenefits || []).map(customBenefitId => ({ benefit: BenefitDTOBenefitEnum.Custom, customBenefitId })),
  );

  return <BenefitsCell activeBenefits={activeBenefits} upcomingBenefits={upcomingBenefits} />;
};

export function useColumns(): (MRT_ColumnDef<EmployeeDTO> & SortByDef)[] {
  const { t } = useTranslation('usersModule');

  return useMemo(() => {
    const columns: (MRT_ColumnDef<EmployeeDTO> & SortByDef)[] = [
      {
        id: 'name',
        accessorFn: employee => (employee.user ? <UserNameAndAvatar user={employee.user} /> : <AnonymousAvatar />),
        header: t('usersModule:usersList.header.name'),
        sortBy: ['user.lastName', 'user.firstName', 'user.mail'],
      },
      {
        accessorKey: 'employeeNumber',
        header: t('usersModule:usersList.header.employeeNumber'),
      },
      {
        accessorKey: 'hireDate',
        header: t('usersModule:usersList.header.hireDate'),
        Cell: ({ cell }) => (cell.getValue() ? i18n.format(new Date(cell.getValue<string>()), 'date') : null),
      },
      {
        accessorKey: 'roles',
        header: t('usersModule:usersList.header.roles'),
        Cell: ({ cell }) =>
          cell
            .getValue<string[]>()
            ?.map(role => t(`common:role.${role}.name`))
            .join(', ') || null,
      },

      {
        accessorKey: 'activeBenefits',
        header: t('usersModule:usersList.header.activeBenefits'),
        enableSorting: false,
        Cell: ({ row }) => <EmployeeBenefitsCell employee={row.original} />,
      },
      {
        accessorKey: 'status',
        header: t('usersModule:usersList.header.status'),
        sortBy: ['status', 'user.status', 'user.mailDeliveryError', 'user.mail'],
        Cell: ({ row }) => <StatusCell employee={row.original} />,
      },
    ];
    return columns;
  }, [t]);
}
