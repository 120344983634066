import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query';
import { BenefitDTOBenefitEnum, InternetReceiptTenantListDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { ReportFilter } from '../report';

const PAGE_SIZE = 10;

export function useInternetReceiptsQuery(
  reportFilter: ReportFilter,
  sortBy: string[],
): UseInfiniteQueryResult<InfiniteData<InternetReceiptTenantListDTO, Error>> {
  const getTenantId = useTenantID();

  const month = reportFilter.month.toFormat('yyyy-LL');
  return useInfiniteQuery({
    queryKey: [
      'tenants',
      getTenantId(),
      'benefits',
      BenefitDTOBenefitEnum.Internet.toLowerCase(),
      'receipts',
      {
        tenantId: getTenantId(),
        month,
        department: reportFilter.department,
        filter: reportFilter.searchText,
        sortBy,
        pageSize: PAGE_SIZE,
      },
      apis.internet.getInternetReceipts.name,
    ],
    queryFn: async ({ pageParam = 0 }) => {
      return apis.internet
        .getInternetReceipts({
          tenantId: getTenantId(),
          month,
          department: reportFilter.department,
          filter: reportFilter.searchText,
          sortBy,
          page: pageParam,
          pageSize: PAGE_SIZE,
        })
        .then(res => res.data);
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => (pages.length * PAGE_SIZE < lastPage.totalCount ? pages.length : undefined),
    placeholderData: previousData => previousData,
  });
}
