import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { IconButton, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { BenefitActivationDTO } from 'probonio-shared-ui/api';
import { MouseEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useBenefitActivationModal } from '../modals/useBenefitActivationModal';
import { useProfileEmployee } from '../hooks/useProfileEmployee';
import { useIsEmployeeTerminated } from '../../../query';

interface Props {
  activation: BenefitActivationDTO;
}

export function BenefitActivationActions({ activation }: Props): JSX.Element {
  const { employee } = useProfileEmployee();
  const { t } = useTranslation('benefitsModule');
  const disableEdit = useIsEmployeeTerminated(employee.id);

  const { onBenefitEdit: onEdit, onBenefitDelete: onDelete, onBenefitStop: onStop } = useBenefitActivationModal();

  const handleStopPropagation = useCallback<MouseEventHandler>(event => {
    event.stopPropagation();
  }, []);

  const handleOpenDeleteActivationDialog = useCallback(
    (event: React.MouseEvent) => {
      handleStopPropagation(event);
      onDelete(activation);
    },
    [activation, handleStopPropagation, onDelete],
  );

  const handleOpenStopActivationDialog = useCallback(
    (event: React.MouseEvent) => {
      handleStopPropagation(event);
      onStop(activation);
    },
    [activation, handleStopPropagation, onStop],
  );

  const handleOpenEditActivationDialog = useCallback(
    (event: React.MouseEvent) => {
      handleStopPropagation(event);
      onEdit(activation);
    },
    [activation, handleStopPropagation, onEdit],
  );

  const handleStop = useCallback(
    (event: React.MouseEvent) => {
      handleOpenStopActivationDialog(event);
    },
    [handleOpenStopActivationDialog],
  );

  return (
    <Stack direction="row" justifyContent="flex-end">
      <IconButton
        sx={{
          display:
            (!activation?.endDate || DateTime.fromISO(activation.endDate).endOf('day') >= DateTime.now()) && !disableEdit ? '' : 'none',
        }}
        onMouseDown={handleStopPropagation}
        onClick={handleOpenEditActivationDialog}
      >
        <EditIcon fontSize="small" />
      </IconButton>
      <IconButton
        sx={{
          display: DateTime.fromISO(activation.startDate) >= DateTime.now().endOf('month') ? '' : 'none',
        }}
        onMouseDown={handleStopPropagation}
        data-test-id="delete-icon-button"
        onClick={handleOpenDeleteActivationDialog}
        title={t('deleteActivation')}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
      <IconButton
        sx={{
          //if it starts before current month its a stop
          //yet enddate needs to be after current month
          display:
            DateTime.fromISO(activation.startDate) <= DateTime.now().endOf('month') &&
            (!activation.endDate || DateTime.fromISO(activation.endDate).endOf('day') >= DateTime.now())
              ? ''
              : 'none',
        }}
        onMouseDown={handleStopPropagation}
        data-test-id="auto-delete-icon-button"
        onClick={handleStop}
        title={t('stopButton')}
      >
        <StopCircleIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
}
