import { SalaryCalculatorApiGetFromCalculatorRequest } from 'probonio-shared-ui/api';
import { useState } from 'react';

export interface CalculatorFormValues {
  grossIncome: number;
  childAmount: number;
  isChurch: boolean;
  isPrivatelyInsured: number;
  privateInsuranceAmount: number;
  taxClass: number;
  resultNetValue: number;
  resultTenantValue: number;
  lunchBenefitAmount: number;
  voucherBenefitAmount: number;
  giftBenefitAmount: number;
  mobilityBenefitAmount: number;
  internetBenefitAmount: number;
  recreationBenefitAmount: number;
}

export const CALCULATOR_DEFAULT_VALUES: CalculatorFormValues = {
  grossIncome: 60000,
  childAmount: 0,
  isChurch: false,
  isPrivatelyInsured: 0,
  privateInsuranceAmount: 0,
  taxClass: 1,
  lunchBenefitAmount: 15,
  voucherBenefitAmount: 50,
  giftBenefitAmount: 2,
  mobilityBenefitAmount: 50,
  internetBenefitAmount: 0,
  recreationBenefitAmount: 156,
  resultNetValue: 0,
  resultTenantValue: 60000 * 1.2,
};

export function useGrossNetCalculationState(): {
  calculatorFormValues: CalculatorFormValues;
  calculationApiData: Partial<SalaryCalculatorApiGetFromCalculatorRequest>;
  setCalculatorFormValues: React.Dispatch<React.SetStateAction<CalculatorFormValues>>;
} {
  const [calculatorFormValues, setCalculatorFormValues] = useState<CalculatorFormValues>(CALCULATOR_DEFAULT_VALUES);

  const calculationApiData: Partial<SalaryCalculatorApiGetFromCalculatorRequest> = {
    ...calculatorFormValues,
    isPrivatelyInsured: calculatorFormValues.isPrivatelyInsured === 1 ? true : false,
  };

  return { calculatorFormValues, calculationApiData, setCalculatorFormValues };
}
