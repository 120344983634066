import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult, UseQueryResult } from '@tanstack/react-query';
import { BAVApiListTenantContractsRequest, BAVContractListDTO } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';

export function useBAVContracts(
  parameters?: Partial<BAVApiListTenantContractsRequest>,
  options?: { enabled?: boolean; staleTime?: number },
): UseQueryResult<BAVContractListDTO, Error> {
  return useTenantQuery(
    ['benefits', 'bav', 'contracts', parameters, apis.bav.listTenantContracts.name],
    tenantId => apis.bav.listTenantContracts({ tenantId, ...parameters }).then(res => res.data),
    {
      ...options,
    },
  );
}

const PAGE_SIZE = 15;

export function useInfiniteBAVContracts(
  parameters?: Partial<BAVApiListTenantContractsRequest>,
  options?: { enabled?: boolean },
): UseInfiniteQueryResult<InfiniteData<BAVContractListDTO, Error>> {
  const getTenantId = useTenantID();

  return useInfiniteQuery({
    queryKey: ['tenants', getTenantId(), 'benefits', 'bav', 'contracts', parameters, apis.bav.listTenantContracts.name],
    queryFn: ({ pageParam = 0 }) =>
      apis.bav.listTenantContracts({ tenantId: getTenantId(), page: pageParam, pageSize: PAGE_SIZE, ...parameters }).then(res => res.data),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => (pages.length * PAGE_SIZE < lastPage.totalCount ? pages.length : undefined),
    placeholderData: previousData => previousData,
    ...options,
  });
}
