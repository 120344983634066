import { FormControlLabel, Stack, Switch } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { TenantDTOHrSystemTypeEnum, UpdateTenantHRSystemDTOHrSystemTypeEnum } from 'probonio-shared-ui/api';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import { apis } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useTenant } from 'probonio-shared-ui/module/me';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextFieldControl } from '../../../component/form';
import { HRSystemSyncStatus } from './HRSystemSyncStatus';
import { useHrSystemInstantInvite } from './useInstantInviteMutation';

interface FormFields {
  apiKey: string;
  apiSecret: string;
}

const API_SECRET_DUMMY = '*'.repeat(50);

export const HRSystemIntegrationForm: React.FC = () => {
  const { t } = useTranslation('tenantModule');
  const { tenant, invalidateTenant, isLoading } = useTenant();
  const { updateHrSystemInstantInvite } = useHrSystemInstantInvite();

  const [isInstantInvite, setIsInstantInvite] = useState<boolean>(false);
  const withMessage = useWithMessage(err =>
    err instanceof AxiosError && err.response?.status === 422 ? t('hrSystem.form.connectionError') : undefined,
  );

  const handleInstantInviteChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsInstantInvite(event.target.checked);
      updateHrSystemInstantInvite(event.target.checked);
    },
    [updateHrSystemInstantInvite],
  );

  const { control, handleSubmit, reset } = useForm<FormFields>({ defaultValues: { apiKey: '', apiSecret: '' } });

  useEffect(() => {
    if (tenant) {
      reset({ apiKey: tenant.hrSystemApiKey || '', apiSecret: tenant.hrSystemApiSecret ? API_SECRET_DUMMY : '' });
    }
  }, [reset, tenant]);

  useEffect(() => {
    if (!isLoading && tenant) {
      setIsInstantInvite(tenant.hrSystemInstantInvite);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const connectMutation = useMutation({
    mutationFn: (values: FormFields) =>
      apis.hrSystem.updateTenantHRSystemConfig({
        tenantId: tenant!.id,
        updateTenantHRSystemDTO: {
          hrSystemType: TenantDTOHrSystemTypeEnum.Personio,
          hrSystemApiKey: values.apiKey,
          hrSystemApiSecret: values.apiSecret !== API_SECRET_DUMMY ? values.apiSecret : undefined,
        },
      }),

    onError: withMessage,
    onSuccess: invalidateTenant,
  });

  const syncNowMutation = useMutation({
    mutationFn: () => apis.hrSystem.syncTenantHRSystem({ tenantId: tenant!.id, fullSync: true }),
    onError: withMessage,
    onSuccess: invalidateTenant,
  });

  const disconnectMutation = useMutation({
    mutationFn: () =>
      apis.hrSystem.updateTenantHRSystemConfig({
        tenantId: tenant!.id,
        updateTenantHRSystemDTO: {
          hrSystemType: null as unknown as UpdateTenantHRSystemDTOHrSystemTypeEnum,
          hrSystemApiKey: null,
          hrSystemApiSecret: null,
        },
      }),

    onError: withMessage,
    onSuccess: invalidateTenant,
  });

  const handleSave = useCallback(
    (values: FormFields) => {
      connectMutation.mutate(values);
    },
    [connectMutation],
  );

  const handleSyncNow = useCallback(() => {
    syncNowMutation.mutate();
  }, [syncNowMutation]);

  const handleDisconnect = useCallback(() => {
    disconnectMutation.mutate();
  }, [disconnectMutation]);

  const isConnected = !!tenant?.hrSystemType;

  return (
    <form onSubmit={handleSubmit(handleSave)} style={{ flexGrow: 1 }}>
      <Grid container justifyContent="center">
        <Grid size={{ xs: 12, lg: 9, xl: 8 }}>
          <Stack direction="column" spacing={3} alignItems="center">
            <TextFieldControl
              control={control}
              name="apiKey"
              label={t('hrSystem.form.apiKey')}
              fullWidth
              rules={{ required: true }}
              disabled={isConnected}
            />
            <TextFieldControl
              control={control}
              name="apiSecret"
              type="password"
              label={t('hrSystem.form.apiSecret')}
              fullWidth
              rules={{ required: true }}
              disabled={isConnected}
            />
            <HRSystemSyncStatus />
            {isConnected ? (
              <Stack direction="column" justifyContent="center" alignItems="center" spacing={3}>
                <FormControlLabel
                  sx={{ margin: 0 }}
                  control={
                    <Switch
                      onChange={handleInstantInviteChange}
                      disabled={!tenant || syncNowMutation.isPending}
                      checked={isInstantInvite}
                    />
                  }
                  label={t('hrSystem.instantInvite.label')}
                />
                <Stack direction="row" spacing={1}>
                  <LoadingButton
                    size="small"
                    loading={syncNowMutation.isPending}
                    disabled={disconnectMutation.isPending}
                    onClick={handleSyncNow}
                  >
                    {t('hrSystem.form.syncNow')}
                  </LoadingButton>
                  <LoadingButton
                    size="small"
                    variant="outlined"
                    color="error"
                    loading={disconnectMutation.isPending}
                    disabled={syncNowMutation.isPending}
                    onClick={handleDisconnect}
                  >
                    {t('hrSystem.form.disconnect')}
                  </LoadingButton>
                </Stack>
              </Stack>
            ) : (
              <LoadingButton type="submit" size="small" loading={connectMutation.isPending}>
                {t('hrSystem.form.connect')}
              </LoadingButton>
            )}
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};
