import React from 'react';
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import TextFieldChips from './TextFieldChips';
import { TextFieldProps } from '@mui/material';

type Props<T extends FieldValues, TName extends FieldPath<T>> = UseControllerProps<T, TName> &
  Omit<TextFieldProps, 'defaultValue'> & {
    options?: { label: React.ReactNode; value: string | number }[];
  };

const TextFieldChipsControl = <T extends FieldValues, TName extends FieldPath<T>>({
  control,
  defaultValue,
  rules,
  name,
  ...props
}: Props<T, TName>): JSX.Element => {
  const { field, fieldState } = useController({ control, name, rules, defaultValue });
  return <TextFieldChips {...props} {...field} helperText={fieldState.error?.message} error={!!fieldState.error} />;
};

export default TextFieldChipsControl;
