/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpdateEmployeeDTO
 */
export interface UpdateEmployeeDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDTO
     */
    'status'?: UpdateEmployeeDTOStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateEmployeeDTO
     */
    'roles'?: Array<UpdateEmployeeDTORolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDTO
     */
    'employeeNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDTO
     */
    'employeeType'?: UpdateEmployeeDTOEmployeeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDTO
     */
    'hireDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDTO
     */
    'birthDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDTO
     */
    'terminationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDTO
     */
    'department'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDTO
     */
    'costCenter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDTO
     * @deprecated
     */
    'defaultCouponId'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateEmployeeDTO
     * @deprecated
     */
    'defaultCouponCombination'?: Array<number> | null;
    /**
     * Only for Superadmins
     * @type {number}
     * @memberof UpdateEmployeeDTO
     */
    'receiptCred'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDTO
     */
    'mail'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDTO
     */
    'civilStatus'?: UpdateEmployeeDTOCivilStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEmployeeDTO
     */
    'bavNotInterested'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateEmployeeDTO
     */
    'numberOfKids'?: number | null;
}

export const UpdateEmployeeDTOStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type UpdateEmployeeDTOStatusEnum = typeof UpdateEmployeeDTOStatusEnum[keyof typeof UpdateEmployeeDTOStatusEnum];
export const UpdateEmployeeDTORolesEnum = {
    TenantAdmin: 'TENANT_ADMIN',
    TenantAssistant: 'TENANT_ASSISTANT',
    User: 'USER',
    TenantSales: 'TENANT_SALES'
} as const;

export type UpdateEmployeeDTORolesEnum = typeof UpdateEmployeeDTORolesEnum[keyof typeof UpdateEmployeeDTORolesEnum];
export const UpdateEmployeeDTOEmployeeTypeEnum = {
    FullTime: 'FULL_TIME',
    PartTime: 'PART_TIME',
    Intern: 'INTERN',
    Student: 'STUDENT',
    MiniJob: 'MINI_JOB',
    Misc: 'MISC'
} as const;

export type UpdateEmployeeDTOEmployeeTypeEnum = typeof UpdateEmployeeDTOEmployeeTypeEnum[keyof typeof UpdateEmployeeDTOEmployeeTypeEnum];
export const UpdateEmployeeDTOCivilStatusEnum = {
    Single: 'SINGLE',
    Married: 'MARRIED',
    Divorced: 'DIVORCED',
    Widowed: 'WIDOWED',
    CivilUnion: 'CIVIL_UNION',
    CivilUnionDeceased: 'CIVIL_UNION_DECEASED',
    CivilUnionCancelled: 'CIVIL_UNION_CANCELLED'
} as const;

export type UpdateEmployeeDTOCivilStatusEnum = typeof UpdateEmployeeDTOCivilStatusEnum[keyof typeof UpdateEmployeeDTOCivilStatusEnum];


