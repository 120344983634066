import { Box, Divider, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { BenefitDTOBenefitEnum, BikeBenefitOptionsDTOEmployerExtrasEnum, BikeContractDTO } from 'probonio-shared-ui/api';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useActiveActivation } from '../ActiveActivation';

interface Props {
  contract: BikeContractDTO;
}

const LabelValueItem: React.FC<{
  label: string;
  value?: string;
  dataTestId?: string;
}> = ({ label, value, dataTestId }) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Typography variant="body2" color="text.secondary" fontWeight="bold">
        {label}
      </Typography>
      <Typography variant="body2" fontWeight="bold" flexGrow={1} textAlign="right" data-test-id={dataTestId}>
        {value}
      </Typography>
    </Box>
  );
};

export const BikeContractDetails: React.FC<Props> = ({ contract }) => {
  const { t } = useTranslation('bikeModule');

  const { activeActivation } = useActiveActivation(contract.employeeId, BenefitDTOBenefitEnum.Bike, false);

  const renderEuro = useCallback(
    (value: number | undefined): string => (value !== undefined ? t('common:money', { money: value }) : ''),
    [t],
  );
  const renderDate = useCallback((value: string | undefined): string => (value ? t('common:date', { date: new Date(value) }) : ''), [t]);

  return (
    <Box>
      <Typography variant="body2" color="text.secondary" gutterBottom>
        {t('contractIntro')}
      </Typography>
      <Typography variant="body2" color="text.secondary" mb={2}>
        {t('contractAppInfo')}
      </Typography>
      <Grid container spacing={3} mb={3}>
        <Grid size={{ xs: 6, lg: 4 }}>
          <Typography variant="h3" gutterBottom>
            {t('section.contract')}
          </Typography>
          <Divider sx={{ marginBottom: 1 }} />
          <Stack direction="column" spacing={0.75}>
            <LabelValueItem
              label={t('contractFields.contractNumber')}
              value={contract.contractNumber}
              dataTestId="bike-field-contractNumber"
            />
            <LabelValueItem label={t('contractFields.contractDate')} value={renderDate(contract.contractDate)} />
            <LabelValueItem label={t('contractFields.status')} value={contract.status} dataTestId="bike-field-status" />
          </Stack>
        </Grid>

        <Grid size={{ xs: 6, lg: 4 }}>
          <Typography variant="h3" gutterBottom>
            {t('section.leasing')}
          </Typography>
          <Divider sx={{ marginBottom: 1 }} />
          <Stack direction="column" spacing={0.75}>
            <LabelValueItem label={t('contractFields.leasingStartDate')} value={renderDate(contract.leasingStartDate)} />
            <LabelValueItem label={t('contractFields.leasingEndDate')} value={renderDate(contract.leasingEndDate)} />
            <LabelValueItem label={t('contractFields.monthlyInsuranceRate')} value={renderEuro(contract.monthlyInsuranceRate)} />
            <LabelValueItem label={t('contractFields.monthlyServiceRate')} value={renderEuro(contract.monthlyServiceRate)} />
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={3}>
        <Grid size={{ xs: 6, lg: 4 }}>
          <Typography variant="h3" gutterBottom>
            {t('section.bike')}
          </Typography>
          <Divider sx={{ marginBottom: 1 }} />
          <Stack direction="column" spacing={0.75}>
            <LabelValueItem label={t('contractFields.bikeProduct')} value={contract.bikeProduct} />
            <LabelValueItem label={t('contractFields.bikeType')} value={contract.bikeType} />
            <LabelValueItem label={t('contractFields.priceNet')} value={renderEuro(contract.priceNet)} />
            <LabelValueItem label={t('contractFields.bikeSupplier')} value={contract.bikeSupplier} dataTestId="bike-field-bikeSupplier" />
          </Stack>
        </Grid>

        <Grid size={{ xs: 6, lg: 4 }}>
          <Typography variant="h3" gutterBottom>
            {t('section.benefits')}
          </Typography>
          <Divider sx={{ marginBottom: 1 }} />
          <Stack direction="column" spacing={0.75}>
            <LabelValueItem
              label={t('contractFields.employerGrant')}
              value={renderEuro(contract.employerGrant ?? activeActivation?.bikeOptions?.employerGrant)}
            />
            {Object.values(BikeBenefitOptionsDTOEmployerExtrasEnum).map(extra => (
              <LabelValueItem
                key={extra}
                label={t(`benefitsModule:BIKE.employerExtra.${extra}`)}
                value={t(activeActivation?.bikeOptions?.employerExtras.includes(extra) ? 'common:yes' : 'common:no')}
              />
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
