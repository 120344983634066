import { Box } from '@mui/material';
import React, { ComponentProps, useCallback } from 'react';
import { FieldPath, FieldValues, UseControllerProps, useController } from 'react-hook-form';
import DOMPurify from 'dompurify';

const HtmlEditor = React.lazy(() => import('../form/RichTextEditor/HtmlEditor'));

type Props<T extends FieldValues, TName extends FieldPath<T>> = UseControllerProps<T, TName> &
  Omit<ComponentProps<typeof HtmlEditor>, 'onChange'>;

const cleanHtml = (html?: string): string | undefined => html?.replaceAll(' >', '>').replaceAll('<a  ', '<a ');

export const ContentEditor = <T extends FieldValues, TName extends FieldPath<T>>({
  control,
  name,
  initialPlaceholder,
  readOnly,
  ...props
}: Props<T, TName>): JSX.Element => {
  const { field } = useController({ control, name });
  const handleChange = useCallback(
    (html: string) => {
      const cleanedHtml = cleanHtml(html);

      field.onChange(cleanedHtml);
      field.onBlur();
    },
    [field],
  );

  return (
    <React.Suspense fallback={<Box height="468px" border="solid 1px lightgrey" borderRadius={1} />}>
      <HtmlEditor
        {...props}
        initialPlaceholder={initialPlaceholder}
        readOnly={readOnly}
        initialValue={DOMPurify.sanitize(field.value)}
        onChange={handleChange}
        height="425px"
      />
    </React.Suspense>
  );
};
