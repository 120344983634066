import { useTranslation } from 'react-i18next';
import { BenefitTab } from '../../component/benefitTabs/BenefitTab';
import { BenefitTabPanel } from '../../component/benefitTabs/BenefitTabPanel';
import { BENEFIT_ICONS } from '../../module/benefits/BenefitIcon';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { TenantGiftsPanel } from '../../module/benefits/gifts/GiftsList/TenantGiftsPanel';
import { useInfiniteCouponGifts } from '../../module/benefits/gifts/GiftsList/useInfiniteCouponGifts';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';

export const CouponGiftsPage: React.FC = () => {
  const { t } = useTranslation('couponsModule');

  const { data: pendingData } = useInfiniteCouponGifts(() => ({ page: 0, pageSize: 0, isDelivered: false }));
  const pendingCount = pendingData?.pages[0].totalCount;
  const { data: deliveredData } = useInfiniteCouponGifts(() => ({ page: 0, pageSize: 0, isDelivered: true }));
  const deliveredCount = deliveredData?.pages[0].totalCount;

  return (
    <AuthorizedLayout
      title={t('budgetPage.subTitle')}
      breadcrumbs={[{ title: t('benefitsModule:COUPONS.title'), to: '/benefits/coupons' }]}
    >
      <BenefitSubPage icon={BENEFIT_ICONS.COUPONS} title={t('benefitsModule:COUPONS.title')} subTitle={t('giftsPage.subTitle')}>
        <BenefitTabPanel>
          <BenefitTab label={t('giftsPage.tab.pending')} count={pendingCount}>
            <TenantGiftsPanel isDelivered={false} />
          </BenefitTab>
          <BenefitTab label={t('giftsPage.tab.delivered')} count={deliveredCount}>
            <TenantGiftsPanel isDelivered />
          </BenefitTab>
        </BenefitTabPanel>
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};
