import { Box, Button, Typography } from '@mui/material';
import { TenantDTO } from 'probonio-shared-ui/api/dto/tenant-dto';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TenantBillingAddressPanelForm, FormFields } from './TenantBillingAddressPanelForm';

interface Props {
  tenant: TenantDTO;
  isEditMode: boolean;
  handleSave: (values: FormFields) => void;
  handleEdit: () => void;
}

export const TenantBillingAddressPanel: React.FC<Props> = ({ tenant, isEditMode, handleSave, handleEdit }) => {
  const { t } = useTranslation('couponsModule');
  const { control, handleSubmit } = useForm<FormFields>({
    defaultValues: {
      tenantName: tenant.name,
      tenantStreet: tenant.street,
      tenantPostalCode: tenant.postalCode,
      tenantCity: tenant.city,
    },
  });

  return (
    <Box>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography> {t(`budgetPanelIncreaseBudgetDialog.billingAddressPanel.title`)}</Typography>
        {isEditMode ? (
          <Button size="small" color="primary" variant="text" sx={{ pointerEvents: 'auto' }} onClick={handleSubmit(handleSave)}>
            {t(`budgetPanelIncreaseBudgetDialog.billingAddressPanel.saveButton`)}
          </Button>
        ) : (
          <Button size="small" variant="text" sx={{ pointerEvents: 'auto' }} onClick={handleEdit}>
            {t(`budgetPanelIncreaseBudgetDialog.billingAddressPanel.editButton`)}
          </Button>
        )}
      </Box>
      <Box>
        {isEditMode ? (
          <TenantBillingAddressPanelForm control={control} />
        ) : (
          <Typography variant="h3">
            <Box>{tenant?.name}</Box>
            <Box>{tenant?.street}</Box>
            <Box>
              {tenant?.postalCode} {tenant?.city}
            </Box>
          </Typography>
        )}
      </Box>
    </Box>
  );
};
