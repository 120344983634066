import { CircularProgress } from '@mui/material';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BenefitPaper } from '../../component/benefitTabs/BenefitPaper';
import { FullWidthCenterBox } from '../../component/layout/BoxLayout';
import { BENEFIT_ICONS } from '../../module/benefits/BenefitIcon';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { BikeleasingContractDetails } from '../../module/benefits/bikeleasing/contracts/BikeleasingContractDetails';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';
import { renderUserName } from '../../module/userManagement/userFormatter';
import { useNavigateBack } from '../../util/useNavigateBack';

const BikeleasingContractDetailsPage: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { contractId, employeeId } = useParams();
  const withMessage = useWithMessage();
  const backNavigator = useNavigateBack('/benefits/bikeleasing/contracts');

  useEffect(() => {
    if (!contractId || !employeeId) {
      backNavigator();
    }
  }, [backNavigator, contractId, employeeId]);

  const handleError = useCallback(
    (error: Error) => {
      withMessage(error);
      backNavigator();
    },
    [backNavigator, withMessage],
  );
  const { data: employee, isLoading: isEmployeeLoading } = useTenantQuery(
    ['employees', employeeId, apis.employee.getEmployeeById.name],
    tenantId =>
      apis.employee
        .getEmployeeById({ tenantId, employeeId: employeeId!, includeRegistrationToken: true })
        .then(res => res.data)
        .catch(e => {
          handleError(e);
          return null;
        }),
  );

  const { data: contract, isLoading: isContractLoading } = useTenantQuery(
    ['employees', employeeId, 'benefit', 'bikeleasing', 'contracts', contractId],
    tenantId =>
      apis.bikeLeasing
        .getBikeleasingContractById({
          tenantId,
          employeeId: employeeId!,
          contractId: contractId!,
        })
        .then(res => res.data)
        .catch(e => {
          handleError(e);
          return null;
        }),
    { enabled: !!employee },
  );

  return (
    <AuthorizedLayout
      title={renderUserName(employee?.user, employee?.employeeNumber)}
      breadcrumbs={[
        { title: t('BIKELEASING.title'), to: '/benefits/bikeleasing' },
        { title: t('BIKELEASING.contracts'), to: '/benefits/bikeleasing/contracts' },
      ]}
    >
      <BenefitSubPage
        icon={BENEFIT_ICONS.BIKE}
        title={t('BIKELEASING.title')}
        subTitle={t('BIKELEASING.contractDetails', { name: renderUserName(employee?.user, employee?.employeeNumber) })}
        showBack
        navigateBackTo="/benefits/bikeleasing/contracts"
      >
        <BenefitPaper>
          {(isEmployeeLoading || isContractLoading) && (
            <FullWidthCenterBox>
              <CircularProgress />
            </FullWidthCenterBox>
          )}
          {contract && <BikeleasingContractDetails contract={contract} />}
        </BenefitPaper>
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};

export default BikeleasingContractDetailsPage;
