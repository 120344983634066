import Grid from '@mui/material/Grid2';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextFieldControl } from '../../component/form/TextFieldControl';

export interface FormFields {
  tenantName: string;
  tenantStreet: string;
  tenantPostalCode: string;
  tenantCity: string;
}

interface Props {
  control: Control<FormFields>;
}

export const TenantBillingAddressPanelForm: React.FC<Props> = ({ control }) => {
  const { t } = useTranslation('tenantModule');

  return (
    <form>
      <Grid container justifyContent="space-between">
        <Grid size={12} paddingTop={1}>
          <TextFieldControl
            label={t('paymentSettings.fields.tenantName')}
            control={control}
            name="tenantName"
            fullWidth
            sx={{ marginBottom: 2 }}
            rules={{ required: true }}
          />
        </Grid>

        <Grid size={12}>
          <TextFieldControl
            label={t('paymentSettings.fields.tenantStreet')}
            control={control}
            name="tenantStreet"
            fullWidth
            sx={{ marginBottom: 2 }}
            rules={{ required: true }}
          />
        </Grid>

        <Grid size={5.5}>
          <TextFieldControl
            label={t('paymentSettings.fields.tenantPostalCode')}
            control={control}
            name="tenantPostalCode"
            fullWidth
            sx={{ marginBottom: 2 }}
            rules={{ required: true }}
          />
        </Grid>

        <Grid size={5.5}>
          <TextFieldControl
            label={t('paymentSettings.fields.tenantCity')}
            control={control}
            name="tenantCity"
            fullWidth
            sx={{ marginBottom: 2 }}
            rules={{ required: true }}
          />
        </Grid>
      </Grid>
    </form>
  );
};
