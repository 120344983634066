import { Alert, Box, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { ApplyBenefitTemplateDTO } from 'probonio-shared-ui/api';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BasicDialog, DialogState } from '../../component/dialog';
import { DialogLoadingButton } from '../../component/dialog/DialogLoadingButton';
import { DatePickerControl } from '../../component/form';
import { SelectTemplateControl } from '../tenant/benefitTemplates/SelectTemplateControl';
import { useTemplateMinStartDate } from '../tenant/benefitTemplates/useTemplateMinStartDate';

interface Props {
  dialogState: DialogState;
  showActiveWarning?: boolean;
  onApply: (params: ApplyBenefitTemplateDTO) => Promise<void>;
}

interface FormValues {
  templateId: string;
  startDate: Date;
  endDate?: Date;
}

export const ApplyTemplateDialog: React.FC<Props> = ({ dialogState, showActiveWarning, onApply }) => {
  const { t } = useTranslation('usersModule');

  const { control, watch, setValue, handleSubmit, reset, formState } = useForm<FormValues>({
    defaultValues: { templateId: '', startDate: DateTime.now().startOf('month').toJSDate() },
  });
  const templateId = watch('templateId');
  const startDate = watch('startDate');
  const endDate = watch('endDate');

  const minStartDate = useTemplateMinStartDate(templateId, dialogState.isOpen);

  useEffect(() => {
    if (dialogState.isOpen) {
      reset();
    }
  }, [dialogState.isOpen, reset]);

  useEffect(() => {
    if (DateTime.fromJSDate(startDate) < minStartDate) {
      setValue('startDate', minStartDate.toJSDate());
    }
    if (!endDate) return;
    if (DateTime.fromJSDate(startDate).endOf('month') > DateTime.fromJSDate(endDate).endOf('month')) {
      setValue('endDate', startDate);
    }
  }, [startDate, endDate, setValue, minStartDate]);

  const handleApply = useCallback(
    async (values: FormValues) => {
      try {
        await onApply({
          startDate: DateTime.fromJSDate(values.startDate).toFormat('yyyy-MM'),
          endDate: values.endDate && DateTime.fromJSDate(values.endDate).toFormat('yyyy-MM'),
          benefitTemplateId: values.templateId,
        });
        dialogState.handleClose();
      } catch {
        // errors are handled by mutation
      }
    },
    [dialogState, onApply],
  );

  return (
    <BasicDialog
      dialogState={dialogState}
      title={t('userForm.applyTemplate.dialogTitle')}
      onSubmit={handleSubmit(handleApply)}
      content={
        <>
          <Stack spacing={2}>
            {showActiveWarning && <Alert severity="warning">{t('userForm.applyTemplate.activeWarning')}</Alert>}
            <Box>
              <Typography color="text.secondary" gutterBottom>
                {t('userForm.applyTemplate.info')}
              </Typography>
              <Typography color="text.secondary">
                <Trans i18nKey="usersModule:userForm.applyTemplate.infoManage">
                  <Link to="/settings/benefitTemplates" />
                </Trans>
              </Typography>
            </Box>
            <SelectTemplateControl
              control={control}
              name="templateId"
              label={t('userForm.applyTemplate.field.template')}
              fullWidth
              margin="dense"
              rules={{ required: true }}
              queryEnabled={dialogState.isOpen}
            />
            <Stack direction="row" spacing={1} sx={{ '& > div, & > div > div': { width: '100%' } }}>
              <DatePickerControl
                control={control}
                name="startDate"
                label={t('benefitsModule:fields.dateFrom')}
                fullWidth
                margin="dense"
                monthYearPicker
                minDate={minStartDate.toJSDate()}
                maxDate={DateTime.now().plus({ years: 10 }).toJSDate()}
                rules={{ required: true }}
              />
              <DatePickerControl
                control={control}
                name="endDate"
                label={t('benefitsModule:fields.dateTill')}
                fullWidth
                margin="dense"
                monthYearPicker
                minDate={minStartDate.toJSDate()}
                maxDate={DateTime.now().plus({ years: 10 }).toJSDate()}
              />
            </Stack>
          </Stack>
        </>
      }
      actions={
        <>
          <DialogLoadingButton
            data-test-id="confirm-apply-template"
            type="submit"
            color="primary"
            loading={formState.isSubmitting}
            disabled={!templateId}
          >
            {t('userForm.applyTemplate.button')}
          </DialogLoadingButton>
        </>
      }
    />
  );
};
