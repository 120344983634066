import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../redux/hooks';
import { BENEFIT_ICONS } from '../../../benefits/BenefitIcon';
import { TutorialStepDefinition } from '../TutorialStep';

export function useOrderCouponBudgetStep(): TutorialStepDefinition {
  const { t } = useTranslation('tutorialModule');
  const tenantId = useAppSelector(state => state.me.currentTenantId);
  const [queryEnabled, setQueryEnabled] = useState(true);

  const { data: budgetOrders } = useTenantQuery(
    ['benefits', 'coupons', 'budgetOrders', { page: 0, pageSize: 0 }, apis.coupons.getBudgetOrders.name],
    tenantId => apis.coupons.getBudgetOrders({ tenantId, page: 0, pageSize: 0 }).then(res => res.data),
    { enabled: queryEnabled },
  );

  const loading = !budgetOrders;
  const completed = !!budgetOrders?.totalCount;

  useEffect(() => {
    if (completed) {
      setQueryEnabled(false);
    }
  }, [completed]);
  useEffect(() => {
    if (tenantId) {
      setQueryEnabled(true);
    }
  }, [tenantId]);

  return useMemo(
    () => ({
      requiredBenefits: [BenefitDTOBenefitEnum.Coupons],
      ...(t('steps.orderCouponBudget', { returnObjects: true }) as {
        label: string;
        description: string;
        help: string;
        buttonText: string;
      }),
      path: '/benefits/coupons/transactions',
      buttonIcon: BENEFIT_ICONS[BenefitDTOBenefitEnum.Coupons],
      loading,
      completed,
    }),
    [completed, loading, t],
  );
}
