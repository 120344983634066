import { useQueryClient } from '@tanstack/react-query';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { useCallback } from 'react';
import { mapEmployeeStatus } from './employeeStatus';

export const useRefetchEmployees = (): (() => Promise<void>) => {
  const queryClient = useQueryClient();
  const getTenantId = useTenantID();

  return useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ['tenants', getTenantId(), 'employees'] });
  }, [queryClient, getTenantId]);
};

export function useIsEmployeeTerminated(employeeId: string): boolean {
  const { data: employee } = useTenantQuery(['employees', employeeId, apis.employee.getEmployeeById.name], tenantId =>
    apis.employee.getEmployeeById({ tenantId, employeeId: employeeId! }).then(res => res.data),
  );

  const mappedStatus = employee && mapEmployeeStatus(employee).status;
  return mappedStatus === 'INACTIVE' || mappedStatus === 'PLANNED_TERMINATION';
}
