import { TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { BAVContractDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import React, { ChangeEventHandler, FormEventHandler, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicDialog, DialogState } from '../../../component/dialog';
import { DialogLoadingButton } from '../../../component/dialog/DialogLoadingButton';

interface Props {
  contract: BAVContractDTO;
  dialogState: DialogState;
}

export const RequestContactDialog: React.FC<Props> = ({ contract, dialogState }) => {
  const { t } = useTranslation('bavModule');
  const [comment, setComment] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: async (comment: string) => {
      await apis.bav.createChangeRequest({
        tenantId: contract.tenantId,
        employeeId: contract.employeeId,
        contractId: contract.id,
        createBAVChangeRequestDTO: { fromManager: true, comment },
      });
    },

    onSuccess: () => {
      dialogState.handleClose();
      setComment('');
      enqueueSnackbar(t('contactSuccessToast'), { variant: 'success' });
    },
  });

  const handleSubmit = useCallback<FormEventHandler<HTMLDivElement>>(
    ev => {
      ev.preventDefault();
      mutation.mutate(comment);
    },
    [comment, mutation],
  );

  const handleChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(ev => {
    setComment(ev.target.value);
  }, []);

  return (
    <BasicDialog
      dialogState={dialogState}
      title={t('requestContact')}
      onSubmit={handleSubmit}
      content={
        <>
          <Typography mb={1}>{t('enterComment')}</Typography>
          <TextField required label="Nachricht eingeben" fullWidth multiline value={comment} onChange={handleChange} rows={4} />
        </>
      }
      actions={
        <DialogLoadingButton type="submit" color="primary" loading={mutation.isPending}>
          {t('sendContactRequest')}
        </DialogLoadingButton>
      }
    />
  );
};
