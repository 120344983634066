import { MRT_ColumnDef } from 'material-react-table';
import { NewsArticleDTO, NewsArticleDTOArticleStatusEnum } from 'probonio-shared-ui/api';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DrawIcon from '@mui/icons-material/Draw';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Typography, useTheme } from '@mui/material';

export function useNewsArticlesColumns(): MRT_ColumnDef<NewsArticleDTO>[] {
  const { t } = useTranslation('benefitsModule');
  const theme = useTheme();

  return useMemo<MRT_ColumnDef<NewsArticleDTO>[]>(
    () => [
      {
        accessorKey: 'title',
        header: t('NEWS.fields.title'),
      },
      {
        accessorKey: 'publishDate',
        header: t('NEWS.fields.releaseDateTime'),
        Cell: ({ cell }) =>
          cell.getValue<string>()
            ? t('common:date', {
                date: new Date(cell.getValue<string>()).toLocaleString([], {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                }),
              })
            : null,
      },
      {
        accessorKey: 'removalDate',
        header: t('NEWS.fields.removalDateTime'),
        Cell: ({ cell }) =>
          cell.getValue<string>()
            ? t('common:date', {
                date: new Date(cell.getValue<string>()).toLocaleString([], {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                }),
              })
            : null,
      },
      {
        accessorKey: 'articleStatus',
        header: t('NEWS.fields.articleStatus'),
        Cell: ({ cell, row }) => {
          const text: string = t(`NEWS.fields.${cell.getValue()}`);
          let icon: React.ReactNode = null;

          const status = row.original.articleStatus;
          if (status === NewsArticleDTOArticleStatusEnum.Published) {
            icon = <CheckCircleIcon fontSize="small" color="success" />;
          } else if (status === NewsArticleDTOArticleStatusEnum.Available) {
            icon = <AccessTimeFilledIcon fontSize="small" sx={{ color: theme.palette.text.secondary }} />;
          } else if (status === NewsArticleDTOArticleStatusEnum.Draft) {
            icon = <DrawIcon fontSize="small" sx={{ color: theme.palette.text.secondary }} />;
          } else if (status === NewsArticleDTOArticleStatusEnum.Expired) {
            icon = <VisibilityOffIcon fontSize="small" sx={{ color: theme.palette.text.secondary }} />;
          }

          return (
            <Typography variant="body2" display="flex" gap={0.5}>
              {icon}
              {text}
            </Typography>
          );
        },
        enableSorting: false,
      },
    ],
    [t, theme.palette.text.secondary],
  );
}
