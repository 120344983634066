/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface NewsArticleDTO
 */
export interface NewsArticleDTO {
    /**
     * 
     * @type {string}
     * @memberof NewsArticleDTO
     */
    'articleStatus': NewsArticleDTOArticleStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof NewsArticleDTO
     */
    'articleViewStatus'?: NewsArticleDTOArticleViewStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof NewsArticleDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NewsArticleDTO
     */
    'employeeId': string;
    /**
     * 
     * @type {string}
     * @memberof NewsArticleDTO
     */
    'tenantId': string;
    /**
     * 
     * @type {string}
     * @memberof NewsArticleDTO
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof NewsArticleDTO
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof NewsArticleDTO
     */
    'titleImageKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsArticleDTO
     */
    'publishDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsArticleDTO
     */
    'removalDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsArticleDTO
     */
    'imageURL'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewsArticleDTO
     */
    'readConfirmation'?: boolean;
}

export const NewsArticleDTOArticleStatusEnum = {
    Draft: 'DRAFT',
    Available: 'AVAILABLE',
    Published: 'PUBLISHED',
    Expired: 'EXPIRED'
} as const;

export type NewsArticleDTOArticleStatusEnum = typeof NewsArticleDTOArticleStatusEnum[keyof typeof NewsArticleDTOArticleStatusEnum];
export const NewsArticleDTOArticleViewStatusEnum = {
    Seen: 'SEEN',
    ConfirmedSeen: 'CONFIRMED_SEEN'
} as const;

export type NewsArticleDTOArticleViewStatusEnum = typeof NewsArticleDTOArticleViewStatusEnum[keyof typeof NewsArticleDTOArticleViewStatusEnum];


