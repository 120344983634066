import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, IconButton } from '@mui/material';
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table';
import { CouponsV2GiftWithEmployeeDTO, EmployeeDTOStatusEnum } from 'probonio-shared-ui/api';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfiniteScrollReactTable } from '../../../../component/table/InfiniteScrollReactTable';
import { RowActionsRenderer } from '../../../../component/table/ThemedMaterialReactTable';
import { SortByDef, mapSortModel } from '../../../../component/table/sortMapper';
import { useLoadMore } from '../../../../component/table/useLoadMore';
import { useNavigateWithPrevPath } from '../../../../util/useNavigateWithPrevPath';
import { renderUserName } from '../../../userManagement/userFormatter';
import { useGiftsV2Columns } from './giftsV2Columns';
import { useInfiniteCouponsV2Gifts } from './useInfiniteCouponsV2Gifts';

interface Props {
  filter?: string;
  isDelivered?: boolean;
}

export const TenantGiftsV2Table: React.FC<Props> = ({ filter, isDelivered }) => {
  const { t } = useTranslation('couponsV2Module');
  const navigate = useNavigateWithPrevPath();
  const [sortModel, setSortModel] = useState<MRT_SortingState>([{ id: 'payoutDate', desc: true }]);

  const baseColumns = useGiftsV2Columns();

  const COLUMNS = useMemo(
    (): (MRT_ColumnDef<CouponsV2GiftWithEmployeeDTO> & SortByDef)[] => [
      {
        id: 'employee',
        header: t('gifts.fields.employee'),
        accessorFn: gift =>
          renderUserName(gift.employee.user, gift.employee.employeeNumber, gift.employee.status === EmployeeDTOStatusEnum.Inactive),
        enableSorting: false,
      },
      ...(baseColumns as (MRT_ColumnDef<CouponsV2GiftWithEmployeeDTO> & SortByDef)[]),
    ],
    [baseColumns, t],
  );

  const mappedSortModel = mapSortModel(COLUMNS, sortModel);

  const queryResult = useInfiniteCouponsV2Gifts(() => ({
    sortBy: mappedSortModel,
    filter,
    isDelivered,
  }));

  const loadMoreProps = useLoadMore(queryResult, 'results');

  const handleGoToEmployee = useCallback(
    (employeeId: string) => (event: React.MouseEvent) => {
      event.preventDefault();
      navigate(`/employees/${employeeId}`, {
        state: { forceHistoryBack: true },
      });
    },
    [navigate],
  );

  const renderRowActions: RowActionsRenderer<CouponsV2GiftWithEmployeeDTO> = useCallback(
    ({ row }) => {
      return (
        <IconButton
          data-test-id="go-to-employee-button"
          title={t('giftsPage.goToEmployee')}
          onClick={handleGoToEmployee(row.original.employee.id)}
        >
          <OpenInNewIcon fontSize="small" />
        </IconButton>
      );
    },
    [handleGoToEmployee, t],
  );

  return (
    <Box>
      <InfiniteScrollReactTable<CouponsV2GiftWithEmployeeDTO>
        {...loadMoreProps}
        enablePagination={false}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        enableColumnActions={false}
        enableSortingRemoval={false}
        manualSorting
        onSortingChange={setSortModel}
        columns={COLUMNS}
        state={{ sorting: sortModel }}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={renderRowActions}
      />
    </Box>
  );
};
