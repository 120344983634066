import BusinessIcon from '@mui/icons-material/Business';
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
  Box,
  FilterOptionsState,
  IconButton,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { apis } from 'probonio-shared-ui/module/api';
import { NamedTenant, switchTenant, useTenant } from 'probonio-shared-ui/module/me';
import { getUserTenantAdminRoles, mayReadAllTenants } from 'probonio-shared-ui/module/me/permissionSelectors';
import useDebounce from 'probonio-shared-ui/utils/useDebounce';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BasicDialog, useNewDialogState } from '../../component/dialog';
import { DialogLoadingButton } from '../../component/dialog/DialogLoadingButton';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { resetEmployeeFilter, resetSorting, setTabIndex } from '../userManagement/filter';

export const TenantSwitcher: React.FC = () => {
  const { tenant, isLoading } = useTenant();
  const user = useAppSelector(state => state.me.user);
  const canReadAllTenants = useAppSelector(state => mayReadAllTenants(state.me.user));
  const dispatch = useAppDispatch();
  const { t } = useTranslation('navigationModule');
  const selectTenantDialog = useNewDialogState();
  const [inputValue, setInputValue] = useState('');
  const [selectedTenant, setSelectedTenant] = useState<NamedTenant>();
  const navigate = useNavigate();

  const availableTenants = useMemo(() => getUserTenantAdminRoles(user), [user]);

  const debouncedInputValue = useDebounce(inputValue, 500);

  const { data: loadedTenants } = useQuery({
    queryKey: ['tenants', { filter: debouncedInputValue, page: 0, pageSize: 10 }, apis.tenants.listTenants.name],
    queryFn: () => apis.tenants.listTenants({ filter: debouncedInputValue, page: 0, pageSize: 10 }).then(resp => resp.data),
    enabled: selectTenantDialog.dialogState.isOpen && mayReadAllTenants(user) && !!debouncedInputValue,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (tenant) {
      setSelectedTenant(availableTenants?.find(t => t.id === tenant.id) || tenant);
    }
  }, [availableTenants, tenant]);

  // close dialog when tenant is loaded
  useEffect(() => {
    if (selectTenantDialog.dialogState.isOpen) {
      selectTenantDialog.dialogState.handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant]);

  const changeTenant = useCallback(() => {
    if (selectedTenant) {
      navigate('/');
      dispatch(switchTenant({ tenantId: selectedTenant.id, isAdmin: true }));
      dispatch(resetSorting());
      dispatch(resetEmployeeFilter());
      dispatch(setTabIndex(0));
    }
  }, [dispatch, navigate, selectedTenant]);

  const renderOptions = useCallback((option: NamedTenant) => option.name, []);

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => <TextField {...params} label={t('changeTenantDialog.select')} />,
    [t],
  );

  const isMyCompany = useCallback(
    (tenant: NamedTenant) => availableTenants.some(adminTenant => adminTenant.id === tenant.id),
    [availableTenants],
  );

  const groupTenants = useCallback(
    (tenant: NamedTenant): string => {
      if (canReadAllTenants) {
        return isMyCompany(tenant) ? t('changeTenantDialog.myCompanies') : t('changeTenantDialog.otherCompanies');
      }
      return '';
    },
    [isMyCompany, t, canReadAllTenants],
  );

  const sortedTenants = useMemo(
    () => [
      ...(availableTenants || []).sort((a, b) => a.name.localeCompare(b.name)),
      ...(loadedTenants?.tenants.filter(tenant => !availableTenants?.some(myTenant => myTenant.id === tenant.id)) || []),
    ],
    [availableTenants, loadedTenants?.tenants],
  );

  const handleChange = useCallback((el: SyntheticEvent<Element, Event>, val: NamedTenant) => {
    setSelectedTenant(val);
  }, []);
  const handleFilterOptions = useCallback(
    (tenants: NamedTenant[], { inputValue }: FilterOptionsState<NamedTenant>) =>
      tenants.filter(tenant => !isMyCompany(tenant) || tenant.name.toLowerCase().includes(inputValue.toLowerCase())),
    [isMyCompany],
  );
  const handleInputChange = useCallback((event: React.SyntheticEvent, newInputValue: string, reason: AutocompleteInputChangeReason) => {
    if (reason === 'reset' || reason === 'clear') {
      setInputValue('');
    } else {
      setInputValue(newInputValue);
    }
  }, []);

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <>
      <IconButton sx={{ color: 'text.secondary' }} title={t('changeTenantDialog.title')} onClick={selectTenantDialog.handleOpen}>
        <BusinessIcon />
      </IconButton>
      {sortedTenants && (
        <BasicDialog
          dialogState={selectTenantDialog.dialogState}
          title={t('changeTenantDialog.title')}
          maxWidth="sm"
          fullWidth
          sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
          content={
            <Box>
              <Typography mb={2} color="text.secondary">
                {t('changeTenantDialog.text')}
              </Typography>
              <Autocomplete
                disablePortal
                disableClearable
                options={sortedTenants}
                groupBy={groupTenants}
                getOptionLabel={renderOptions}
                fullWidth
                renderInput={renderInput}
                value={selectedTenant}
                onChange={handleChange}
                filterOptions={handleFilterOptions}
                onInputChange={handleInputChange}
              />
            </Box>
          }
          actions={
            <Box>
              <DialogLoadingButton
                loading={isLoading}
                onClick={changeTenant}
                disabled={!selectedTenant || selectedTenant.id === tenant?.id}
              >
                {t('changeTenantDialog.submit')}
              </DialogLoadingButton>
            </Box>
          }
        />
      )}
    </>
  );
};
