import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { Box, IconButton, Tooltip } from '@mui/material';
import { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { BikeleasingConditionDTO } from 'probonio-shared-ui/api';
import React, { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BasicDialog, useNewDialogState } from '../../../component/dialog';
import { ConfirmationModal } from '../../../component/dialog/ConfirmationModal';
import { InfiniteScrollReactTable } from '../../../component/table/InfiniteScrollReactTable';
import { useLoadMore } from '../../../component/table/useLoadMore';
import { SelectedBikeleasingCondition } from './SelectedBikeleasingCondition';
import { useInfiniteBikeleasingConditions } from './useBikeleasingConditions';
import { useDeleteConditionMutation } from './useDeleteConditionMutation';

type WithOutHeaders = Omit<MRT_ColumnDef<BikeleasingConditionDTO>, 'header'>[];

export const BikeleasingConditionList: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { dialogState, handleOpen } = useNewDialogState();
  const deleteDialog = useNewDialogState();
  const queryResult = useInfiniteBikeleasingConditions();
  const deleteConditionMutation = useDeleteConditionMutation();
  const [selectedCondition, setSelectedCondition] = useState<BikeleasingConditionDTO>();

  const loadMoreProps = useLoadMore(queryResult, 'results');
  const COLUMNS = useMemo((): MRT_ColumnDef<BikeleasingConditionDTO>[] => {
    const columns: WithOutHeaders = [
      {
        accessorKey: 'companyCode',
      },
      {
        accessorKey: 'maxNumberOfBikesPerEmployee',
      },
      {
        accessorKey: 'allowedBikeTypes',
        Cell: ({ cell }) => cell.getValue<BikeleasingConditionDTO['allowedBikeTypes']>().join(', '),
      },
      {
        accessorKey: 'priceRestrictions.maxTotalPurchasePriceForAllBikes',
        Cell: ({ cell }) =>
          cell.getValue<number>()
            ? t('common:money', {
                money: cell.getValue<number>(),
              })
            : '-',
      },
      {
        accessorKey: 'employerContribution',
        Cell: ({ cell }) => {
          const value = cell.getValue<BikeleasingConditionDTO['employerContribution']>();
          if (value?.absolute) {
            return t('common:money', { money: value.absolute });
          }
          return value?.percentage ? `${value.percentage}%` : t('common:money', { money: 0 });
        },
      },
      {
        accessorKey: 'syncError',
        Cell: ({ cell }) => {
          const value = cell.getValue<BikeleasingConditionDTO['syncError']>();
          return value ? (
            <Tooltip title={t(`BIKELEASING.syncErrorDescription.${value}`)}>
              <CloseIcon color="error" />
            </Tooltip>
          ) : (
            <CheckIcon color="success" />
          );
        },
      },
    ];
    return columns.map(column => ({ ...column, header: t(`BIKELEASING.${column.accessorKey}`) }));
  }, [t]);

  const handleSelectCondition = useCallback(
    (bikeleasingCondition: BikeleasingConditionDTO) => {
      return () => {
        setSelectedCondition(bikeleasingCondition);
        handleOpen();
      };
    },
    [handleOpen],
  );

  const handleOpenConfirmDeleteCondition = useCallback(
    (bikeleasingCondition: BikeleasingConditionDTO) => {
      return () => {
        setSelectedCondition(bikeleasingCondition);
        deleteDialog.handleOpen();
      };
    },
    [deleteDialog],
  );

  const handleDeleteSelectedCondition = useCallback(() => {
    if (selectedCondition) {
      deleteConditionMutation.mutate(selectedCondition.id, {
        onSuccess: deleteDialog.dialogState.handleClose,
      });
    }
  }, [deleteConditionMutation, deleteDialog.dialogState.handleClose, selectedCondition]);

  const renderActions = useCallback(
    ({ row }: { row: MRT_Row<BikeleasingConditionDTO> }) => (
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick={handleSelectCondition(row.original)}>
          <InfoIcon />
        </IconButton>
        <IconButton
          title={t('BIKELEASING.deleteCondition')}
          data-test-id="bikeleasing-delete-condition"
          onClick={handleOpenConfirmDeleteCondition(row.original)}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    ),
    [handleSelectCondition, t, handleOpenConfirmDeleteCondition],
  );
  return (
    <>
      <BasicDialog
        dialogState={dialogState}
        maxWidth="xl"
        title={t('BIKELEASING.conditionDetailDialog.title', { companyCode: selectedCondition?.companyCode })}
        content={
          <Box minWidth="400px">
            <SelectedBikeleasingCondition bikeleasingCondition={selectedCondition} />
          </Box>
        }
      />
      <ConfirmationModal
        dialogState={deleteDialog.dialogState}
        closeOnConfirm={false}
        dataTestId="bikeleasing-confirm-delete"
        loading={deleteConditionMutation.isPending}
        onConfirm={handleDeleteSelectedCondition}
      >
        <Trans i18nKey="benefitsModule:BIKELEASING.confirmDeleteCondition" />
      </ConfirmationModal>
      <InfiniteScrollReactTable
        {...loadMoreProps}
        enableTopToolbar={false}
        enableColumnActions={false}
        enableSorting={false}
        columns={COLUMNS}
        enablePagination={false}
        manualPagination
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={renderActions}
      />
    </>
  );
};
