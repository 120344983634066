import { ComponentProps, useCallback } from 'react';
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { CustomDatePicker } from '../datePicker/DatePicker';

type Props<T extends FieldValues, TName extends FieldPath<T>> = UseControllerProps<T, TName> &
  Omit<ComponentProps<typeof CustomDatePicker>, 'value' | 'onChange' | 'error'>;

export const DatePickerControl = <T extends FieldValues, TName extends FieldPath<T>>({
  name,
  rules,
  control,
  defaultValue,
  shouldUnregister,
  helperText,
  disabled,
  selectsRange,
  showNextPreviousToggle,
  ...props
}: Props<T, TName>): JSX.Element => {
  const { field, fieldState } = useController({
    name,
    rules,
    control,
    defaultValue,
    shouldUnregister,
    disabled,
  });

  const handleChange = useCallback(
    (value: unknown) => {
      field.onChange(value);
    },
    [field],
  );

  return (
    <CustomDatePicker
      {...props}
      {...field}
      value={field.value ?? undefined}
      error={!!fieldState.error}
      required={props.required || !!rules?.required}
      helperText={helperText}
      onChange={handleChange}
      {...(selectsRange ? { selectsRange } : { showNextPreviousToggle })}
    />
  );
};
