import { useQuery } from '@tanstack/react-query';
import { BenefitDTOBenefitEnum, CustomBenefitDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface BenefitMenuItem {
  title: string;
  to: string;
  hasSubpages?: boolean;
}

export function useBenefitMenuItems(
  benefit: BenefitDTOBenefitEnum,
  customBenefit?: CustomBenefitDTO,
  allowLoad?: boolean,
): BenefitMenuItem[] {
  const { t } = useTranslation('benefitsModule');
  const { tenant } = useTenant();

  const { data: documents } = useQuery({
    queryKey: [
      'documents',
      { benefit, customBenefitTemplateKey: customBenefit?.templateKey, page: 0, pageSize: 0 },
      apis.documents.listDocuments,
    ],
    queryFn: () =>
      apis.documents
        .listDocuments({ benefit, customBenefitTemplateKey: customBenefit?.templateKey, page: 0, pageSize: 0 })
        .then(res => res.data),

    enabled: allowLoad && (benefit !== BenefitDTOBenefitEnum.Custom || !!customBenefit?.templateKey),
  });

  return useMemo(() => {
    const additionalItems = ((): BenefitMenuItem[] => {
      switch (benefit) {
        case BenefitDTOBenefitEnum.Lunch:
          return [
            { title: t('subPage.receipts'), to: '/benefits/lunch/receipts' },
            { title: t('subPage.payrollReport'), to: '/benefits/lunch/payrollReport' },
            { title: t('subPage.settings'), to: '/benefits/lunch/settings' },
          ];
        case BenefitDTOBenefitEnum.Mobility:
          return [
            { title: t('subPage.receipts'), to: '/benefits/mobility/receipts' },
            { title: t('subPage.payrollReport'), to: '/benefits/mobility/payrollReport' },
            { title: t('subPage.settings'), to: '/benefits/mobility/settings' },
          ];
        case BenefitDTOBenefitEnum.Internet:
          return [
            { title: t('INTERNET.receipts'), to: '/benefits/internet/receipts' },
            { title: t('subPage.payrollReport'), to: '/benefits/internet/payrollReport' },
          ];
        case BenefitDTOBenefitEnum.Coupons:
          return [
            { title: t('couponsModule:budgetPage.subTitle'), to: '/benefits/coupons/transactions' },
            { title: t('couponsModule:giftsPage.subTitle'), to: '/benefits/coupons/gifts' },
            { title: t('subPage.payrollReport'), to: '/benefits/coupons/payrollReport' },
            { title: t('subPage.settings'), to: '/benefits/coupons/settings' },
          ];
        case BenefitDTOBenefitEnum.CouponsV2:
          return [
            { title: t('couponsV2Module:budgetPage.subTitle'), to: '/benefits/coupons_v2/transactions' },
            { title: t('couponsV2Module:giftsPage.subTitle'), to: '/benefits/coupons_v2/gifts' },
            { title: t('subPage.payrollReport'), to: '/benefits/coupons_v2/payrollReport' },
          ];
        case BenefitDTOBenefitEnum.Bav:
          return [
            { title: t('BAV.contracts'), to: '/benefits/bav/contracts', hasSubpages: true },
            { title: t('bavModule:terms.title'), to: '/benefits/bav/terms' },
          ];
        case BenefitDTOBenefitEnum.Bike:
          return [{ title: t('BIKE.contracts'), to: '/benefits/bike/contracts', hasSubpages: true }];
        case BenefitDTOBenefitEnum.News:
          return [];
        case BenefitDTOBenefitEnum.Flex:
          const hasSettings =
            tenant?.flexBenefits?.includes(BenefitDTOBenefitEnum.Lunch) ||
            tenant?.flexBenefits?.includes(BenefitDTOBenefitEnum.Mobility) ||
            tenant?.flexBenefits?.includes(BenefitDTOBenefitEnum.Recreation) ||
            tenant?.flexBenefits?.includes(BenefitDTOBenefitEnum.Coupons);
          return [
            ...(tenant?.flexBenefits?.includes(BenefitDTOBenefitEnum.Lunch)
              ? [{ title: `${t('common:benefit.LUNCH')}: ${t('subPage.receipts')}`, to: '/benefits/flex/lunch/receipts' }]
              : []),
            ...(tenant?.flexBenefits?.includes(BenefitDTOBenefitEnum.Mobility)
              ? [{ title: `${t('common:benefit.MOBILITY')}: ${t('subPage.receipts')}`, to: '/benefits/flex/mobility/receipts' }]
              : []),
            ...(tenant?.flexBenefits?.includes(BenefitDTOBenefitEnum.Internet)
              ? [{ title: `${t('common:benefit.INTERNET')}: ${t('INTERNET.receipts')}`, to: '/benefits/flex/internet/receipts' }]
              : []),
            ...(tenant?.flexBenefits?.includes(BenefitDTOBenefitEnum.Coupons)
              ? [
                  {
                    title: `${t('common:benefit.COUPONS')}: ${t('couponsModule:budgetPage.subTitle')}`,
                    to: '/benefits/flex/coupons/transactions',
                  },
                  { title: `${t('common:benefit.COUPONS')}: ${t('couponsModule:giftsPage.subTitle')}`, to: '/benefits/flex/coupons/gifts' },
                ]
              : []),
            ...(tenant?.flexBenefits?.includes(BenefitDTOBenefitEnum.Recreation)
              ? [{ title: `${t('common:benefit.RECREATION')}: ${t('subPage.receipts')}`, to: '/benefits/flex/recreation/receipts' }]
              : []),
            ...(hasSettings ? [{ title: t('subPage.settings'), to: '/benefits/flex/settings', hasSubpages: true }] : []),
            { title: t('subPage.payrollReport'), to: '/benefits/flex/payrollReport' },
          ];
        case BenefitDTOBenefitEnum.Recreation:
          return [
            { title: t('subPage.receipts'), to: '/benefits/recreation/receipts' },
            { title: t('subPage.payrollReport'), to: '/benefits/recreation/payrollReport' },
          ];
        case BenefitDTOBenefitEnum.Bikeleasing:
          return [
            { title: t('benefitsModule:BIKELEASING.integration.title'), to: '/benefits/bikeleasing/integration' },
            { title: t('benefitsModule:BIKELEASING.contracts'), to: '/benefits/bikeleasing/contracts' },
          ];
        case BenefitDTOBenefitEnum.Custom:
          return [{ title: t('customBenefitsModule:configureButton'), to: `/benefits/custom/${customBenefit?.id}/edit` }];
      }
      return [];
    })();

    const benefitSubUrl = `${benefit.toLowerCase()}${customBenefit ? `/${customBenefit.id}` : ''}`;

    return [
      { title: t([`subPage.${benefit.toLowerCase()}`, 'subPage.employees']), to: `/benefits/${benefitSubUrl}` },
      ...additionalItems,
      ...(documents?.totalCount ? [{ title: t('subPage.documents'), to: `/benefits/${benefitSubUrl}/documents` }] : []),
    ];
  }, [benefit, customBenefit, documents?.totalCount, t, tenant?.flexBenefits]);
}
