import AddIcon from '@mui/icons-material/Add';
import { Button, Tooltip } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RowSelectionState } from '../../../component/table/useManualRowSelection';
import { useBulkBenefitActivationModal } from './useBulkBenefitActivationModal';
import { useBenefitContext } from '../BenefitContext';

interface Props {
  rowSelectionState: RowSelectionState;
}

export const BulkActivationButton: React.FC<Props> = ({ rowSelectionState }) => {
  const { t } = useTranslation('benefitsModule');
  const { benefit, customBenefitId } = useBenefitContext();
  const { onBenefitCreate } = useBulkBenefitActivationModal();

  const noUsersSelected = !rowSelectionState.someRowsSelected;

  const handleOpenDialogToCreate = useCallback(() => {
    onBenefitCreate(benefit, customBenefitId);
  }, [benefit, customBenefitId, onBenefitCreate]);

  return (
    <>
      <Tooltip arrow placement="top" title={t('employeeTabs.pleaseSelect')} disableHoverListener={!noUsersSelected}>
        <span>
          <Button
            data-test-id="employee-benefit-configure-button"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            disabled={noUsersSelected}
            onClick={handleOpenDialogToCreate}
          >
            {rowSelectionState.allSelected
              ? t('employeeTabs.configureNowAll')
              : t('employeeTabs.configureNow', { count: rowSelectionState.selectedCount })}
          </Button>
        </span>
      </Tooltip>
    </>
  );
};
