import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BasicDialog, DialogState } from '../../../component/dialog';
import { DialogLoadingButton } from '../../../component/dialog/DialogLoadingButton';

interface Props {
  messageKey?: string;
  dialogState: DialogState;
  onDelete: () => void;
}

export const DeleteActivationDialog: React.FC<Props> = ({ messageKey, dialogState, onDelete }) => {
  const { t } = useTranslation('benefitsModule');

  return (
    <BasicDialog
      dialogState={dialogState}
      title={t('common:confirmation')}
      content={<Typography display="block">{t(messageKey || 'deleteConfirmation')}</Typography>}
      actions={
        <DialogLoadingButton onClick={onDelete} aria-label={t('deleteActivation')} data-test-id="delete-activation-confirm">
          {t('common:buttons.delete')}
        </DialogLoadingButton>
      }
    />
  );
};
