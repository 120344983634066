import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Stack } from '@mui/material';
import { CouponsV2GiftDTO, CouponsV2GiftDTOStatusEnum } from 'probonio-shared-ui/api';
import { MouseEventHandler, useCallback } from 'react';
import { useV2GiftActivationModal } from '../modals/useV2GiftActivationModal';

interface Props {
  v2Gift: CouponsV2GiftDTO;
}

export function V2GiftActivationActions({ v2Gift }: Props): JSX.Element {
  const { onV2GiftDelete: onDelete, onV2GiftEdit: onEdit } = useV2GiftActivationModal();
  const handleStopPropagation = useCallback<MouseEventHandler>(event => {
    event.stopPropagation();
  }, []);

  const handleOpenDeleteGiftDialog = useCallback(
    (event: React.MouseEvent): void => {
      handleStopPropagation(event);
      onDelete(v2Gift);
    },
    [v2Gift, handleStopPropagation, onDelete],
  );

  const handleOpenDialogToEdit = useCallback(
    (event: React.MouseEvent) => {
      handleStopPropagation(event);
      onEdit(v2Gift);
    },
    [v2Gift, handleStopPropagation, onEdit],
  );

  const isDone = v2Gift.status === CouponsV2GiftDTOStatusEnum.Available || v2Gift.status === CouponsV2GiftDTOStatusEnum.Delivered;
  const isInvoiceCreatedOrDone =
    v2Gift.status === CouponsV2GiftDTOStatusEnum.InvoiceCreated ||
    v2Gift.status === CouponsV2GiftDTOStatusEnum.Available ||
    v2Gift.status === CouponsV2GiftDTOStatusEnum.Delivered;

  return (
    <Stack direction="row" justifyContent="flex-end">
      <IconButton
        sx={{
          display: isDone ? 'none' : '',
        }}
        onMouseDown={handleStopPropagation}
        onClick={handleOpenDialogToEdit}
      >
        <EditIcon fontSize="small" />
      </IconButton>
      <IconButton
        sx={{
          display: isInvoiceCreatedOrDone ? 'none' : '',
        }}
        onMouseDown={handleStopPropagation}
        onClick={handleOpenDeleteGiftDialog}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
}
