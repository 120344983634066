import CakeIcon from '@mui/icons-material/Cake';
import ChairIcon from '@mui/icons-material/Chair';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import WifiIcon from '@mui/icons-material/Wifi';
import { SvgIconProps } from '@mui/material';
import { BenefitDTOBenefitEnum, EmployeeDTOActiveBenefitsEnum, TenantDTO, TenantDTOActiveBenefitsEnum } from 'probonio-shared-ui/api';
import { BikeleasingBIcon, PalmTreeIcon, ThreeWayArrowIcon } from 'probonio-shared-ui/component/icon';
import { useLocation } from 'react-router-dom';

export type AllBenefitsIncludingComing = EmployeeDTOActiveBenefitsEnum;

export const BENEFIT_ICONS: Record<AllBenefitsIncludingComing, React.ComponentType<SvgIconProps>> = {
  BAV: ChairIcon,
  BIKE: DirectionsBikeIcon,
  // no pun intended
  BIKELEASING: BikeleasingBIcon,
  LUNCH: FastfoodIcon,
  MOBILITY: DirectionsBusIcon,
  INTERNET: WifiIcon,
  COUPONS: LocalOfferIcon,
  COUPONS_V2: LoyaltyIcon,
  GIFTS: CakeIcon,
  NEWS: TextSnippetIcon,
  FLEX: ThreeWayArrowIcon,
  RECREATION: PalmTreeIcon,
  CUSTOM: DashboardCustomizeIcon,
};

export const BENEFIT_ORDER: AllBenefitsIncludingComing[] = [
  'FLEX',
  'LUNCH',
  'COUPONS',
  'GIFTS',
  'COUPONS_V2',
  'MOBILITY',
  'BIKELEASING',
  'INTERNET',
  'RECREATION',
  'BAV',
  'BIKE',
  'NEWS',
  'CUSTOM',
];

export function orderedBenefits(tenant: TenantDTO | undefined): AllBenefitsIncludingComing[] {
  return [
    ...BENEFIT_ORDER.filter(benefit => isBenefitActive(tenant, benefit)),
    ...BENEFIT_ORDER.filter(
      benefit =>
        !isBenefitActive(tenant, benefit) &&
        // hide coupons/gifts v1 if v2 is active
        ((benefit !== BenefitDTOBenefitEnum.Coupons && benefit !== BenefitDTOBenefitEnum.Gifts) ||
          !isBenefitActive(tenant, BenefitDTOBenefitEnum.CouponsV2)),
    ),
  ].filter(benefit => !tenant?.flexBenefits?.includes(benefit));
}

export function isBenefitActive(tenant: TenantDTO | undefined, benefit: AllBenefitsIncludingComing): boolean | undefined {
  return tenant?.activeBenefits?.includes(benefit as TenantDTOActiveBenefitsEnum);
}
/**
 * Extracts the benefits of the currently displayed URL. It also checks if the superior benefit is FLEX and then returns both FLEX
 * and inferior benefit. E.g. FLEX, LUNCH or FLEX, MOBILITY etc. If the currently displayed page has no connections to any benefits, the array will be empty.
 * @returns array of active benefits || empty array
 */
export function useBenefitsFromURL(): BenefitDTOBenefitEnum[] {
  const currentURL = useLocation();
  const subURLs = currentURL.pathname.split('/');
  const benefitSubURLs = Object.values(BenefitDTOBenefitEnum).map(benefit => benefit.toLowerCase() as Lowercase<BenefitDTOBenefitEnum>);
  return subURLs
    .filter(subUrl => benefitSubURLs.some(benefit => benefit === subUrl))
    .map(benefit => benefit.toUpperCase() as BenefitDTOBenefitEnum);
}
