import { useTheme } from '@mui/material';
import { Control, FieldPath, FieldValues } from 'react-hook-form';
import { TextFieldControl } from '../form';

interface Props<T extends FieldValues, TName extends FieldPath<T>> {
  name: TName;
  control: Control<T>;
}

const ColorPicker = <T extends FieldValues, TName extends FieldPath<T>>({ name, control }: Props<T, TName>): JSX.Element => {
  const theme = useTheme();
  return (
    <>
      <TextFieldControl
        control={control}
        name={name}
        type="color"
        sx={{
          '.MuiInputBase-input': {
            ':focus-visible': { outline: `5px solid ${theme.palette.primary.main}` },
          },
          width: '100px',
          input: {
            padding: 'unset',
            height: ' 100px',
          },
          fieldset: {
            display: 'none',
          },
        }}
      />
    </>
  );
};

export { ColorPicker };
