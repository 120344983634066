import { useTranslation } from 'react-i18next';
import { BenefitPaper } from '../../component/benefitTabs/BenefitPaper';
import { BENEFIT_ICONS } from '../../module/benefits/BenefitIcon';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { BikeBenefitMenu } from '../../module/benefits/bike/BikeBenefitMenu';
import { BikeContractsTable } from '../../module/benefits/bike/BikeContractsTable';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';

const BikeContractsPage: React.FC = () => {
  const { t } = useTranslation('benefitsModule');

  return (
    <AuthorizedLayout title={t('BIKE.contracts')} breadcrumbs={[{ title: t('BIKE.title'), to: '/benefits/bike' }]}>
      <BenefitSubPage icon={BENEFIT_ICONS.BIKE} title={t('BIKE.title')} subTitle={t('BIKE.contracts')} menu={<BikeBenefitMenu />}>
        <BenefitPaper>
          <BikeContractsTable />
        </BenefitPaper>
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};

export default BikeContractsPage;
