import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import PeopleIcon from '@mui/icons-material/People';
import { Button, Stack, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewDialogState } from '../../component/dialog';
import { useRowSelectionState } from '../../component/table/useManualRowSelection';
import BenefitSubPage from '../benefits/BenefitSubPage';
import { BulkBenefitActivationModalProvider } from '../benefits/bulkActivation/BulkBenefitActivationModalProvider';
import { useReduxEmployeeFilterState } from '../benefits/employeeStatusList/employeeFilterState';
import { AddUserDialog } from './AddUserDialog/AddUserDialog';
import { EmployeeListEditButtons } from './EmployeeListEditButtons';
import { EmployeeListTabs } from './EmployeeListTabs';
import { setEmployeeFilter } from './filter';

const UsersSubPage: React.FC = () => {
  const { t } = useTranslation('usersPage');
  const [editMode, setEditMode] = useState(false);

  const filterState = useReduxEmployeeFilterState(
    useCallback(state => state.filter.employeeFilter, []),
    setEmployeeFilter,
  );
  const defaultRowSelectionState = useRowSelectionState();
  // leave edit mode when selection is reset, i.e. after successful save
  const rowSelectionState = useMemo(
    () => ({
      ...defaultRowSelectionState,
      handleReset: () => {
        defaultRowSelectionState.handleReset();
        setEditMode(false);
      },
    }),
    [defaultRowSelectionState],
  );

  const { dialogState: addUserDialogState, handleOpen: handleOpenAddUserDialog } = useNewDialogState();

  useEffect(() => {
    if (defaultRowSelectionState.someRowsSelected) {
      defaultRowSelectionState.handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState.filter.showActiveUser, editMode]);

  const handleToggleEditMode = useCallback(() => {
    setEditMode(active => !active);
  }, []);

  return (
    <BenefitSubPage
      title={t('title')}
      icon={PeopleIcon}
      menu={
        <Stack direction="row" spacing={1} key="view-mode-bar">
          {editMode ? (
            <BulkBenefitActivationModalProvider rowSelectionState={rowSelectionState} employeeFilter={filterState.debouncedFilter}>
              <EmployeeListEditButtons rowSelectionState={rowSelectionState} />
            </BulkBenefitActivationModalProvider>
          ) : (
            <Button data-test-id="user-add" onClick={handleOpenAddUserDialog} startIcon={<AddIcon />}>
              {t('addUser')}
            </Button>
          )}
          <Tooltip title={t(editMode ? 'closeEditMode' : 'editMode')}>
            <Button variant="topBarButtonBorder" onClick={handleToggleEditMode} data-test-id="toggle-edit-mode">
              {editMode ? <CloseIcon /> : <EditIcon />}
            </Button>
          </Tooltip>
        </Stack>
      }
    >
      <EmployeeListTabs filterState={filterState} rowSelectionState={rowSelectionState} enableRowSelection={editMode} />

      <AddUserDialog dialogState={addUserDialogState} />
    </BenefitSubPage>
  );
};

export default UsersSubPage;
