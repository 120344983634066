import { UserDTO } from 'probonio-shared-ui/api';
import { LetterAvatar } from './LetterAvatar';

interface Props {
  user: UserDTO;
}

export const UserNameAndAvatar: React.FC<Props> = ({ user }) => {
  return (
    <div data-test-id="user-name-and-avatar" style={{ display: 'flex', alignItems: 'center' }}>
      <LetterAvatar firstName={user.firstName} lastName={user.lastName} mail={user.mail} size={45} />
      <div style={{ marginLeft: 10 }}>
        <b>
          {user.lastName}
          {user.firstName && user.lastName && ','} {user.firstName}
        </b>
        <div>{user.mail}</div>
      </div>
    </div>
  );
};
