import { Box, Stepper } from '@mui/material';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { TutorialStep, TutorialStepDefinition } from './TutorialStep';

interface Props {
  tutorialSteps: TutorialStepDefinition[];
  completedCount: number;
}

export const TenantTutorialStepper: React.FC<Props> = ({ tutorialSteps, completedCount }) => {
  const [activeStep, setActiveStep] = useState(() => {
    const nextOpenStep = tutorialSteps.findIndex(step => !step.completed);
    return nextOpenStep >= 0 ? nextOpenStep : undefined;
  });

  const handleStep = useCallback(
    (step: number) => () => {
      if (activeStep === step) {
        setActiveStep(undefined);
        return;
      }
      setActiveStep(step);
    },
    [activeStep],
  );

  useEffect(() => {
    const nextOpenStep = tutorialSteps.findIndex(step => !step.completed);
    setActiveStep(nextOpenStep >= 0 ? nextOpenStep : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCount]);

  return (
    <Box padding={1.5} data-test-id="tenant-tutorial-stepper">
      <Stepper nonLinear activeStep={activeStep === undefined ? -1 : activeStep} orientation="vertical">
        {tutorialSteps.map((step, index) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { loading, requiredBenefits, ...stepProps } = step;
          return <TutorialStep key={index} {...stepProps} onToggleOpen={handleStep(index)} />;
        })}
      </Stepper>
    </Box>
  );
};
