import { Box, Card, CardActionArea, CardContent, CardHeader, Chip, Stack, Typography } from '@mui/material';
import { BenefitActivationDTO, BenefitDTOBenefitEnum, BenefitTemplateItemDTO } from 'probonio-shared-ui/api';
import { CUSTOM_BENEFIT_ICONS } from 'probonio-shared-ui/component/icon';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BENEFIT_ICONS } from '../../benefits/BenefitIcon';
import { useBenefitAttributes } from '../../benefits/benefitColumns';

interface Props {
  cardItem: BenefitTemplateItemDTO | BenefitActivationDTO;
  actions?: React.ReactNode;
  onClick?: (item: BenefitTemplateItemDTO | BenefitActivationDTO) => void;
  actionChips?: React.ReactNode;
}

function useBenefitOptionLabels(
  options: BenefitTemplateItemDTO | BenefitActivationDTO,
): { id: string; label: string; value: React.ReactNode }[] {
  const attributes = useBenefitAttributes(options.benefit);

  return attributes.map(attribute => {
    const optionValue = attribute.accessorFn!(options);
    const renderedValue = attribute.renderer ? attribute.renderer(optionValue) : `${optionValue}`;
    return { id: attribute.id, label: attribute.header, value: renderedValue };
  });
}

export const BenefitOptionChips: React.FC<{ benefitOptions: BenefitTemplateItemDTO | BenefitActivationDTO }> = ({ benefitOptions }) => {
  const optionLabels = useBenefitOptionLabels(benefitOptions);

  return (
    <Stack direction="row" spacing={1}>
      {optionLabels.map(({ id, label, value }) =>
        value || value === 0 ? (
          <Chip
            key={id}
            data-test-id={`attribute-chip-${id}`}
            size="small"
            label={
              <Box display="inline-flex" alignItems="center" gap="0.33em">
                <div>{label}:</div>
                <div>{value}</div>
              </Box>
            }
          />
        ) : null,
      )}
    </Stack>
  );
};

export const BenefitCard: React.FC<Props> = ({ cardItem, actions, onClick, actionChips }) => {
  const { t } = useTranslation('tenantModule');
  const { tenant } = useTenant();

  const { data: customBenefit } = useTenantQuery(
    ['benefits', 'custom', cardItem.customBenefitOptions?.customBenefitId, apis.customBenefits.getCustomBenefit],
    tenantId =>
      apis.customBenefits
        .getCustomBenefit({
          tenantId,
          customBenefitId: cardItem.customBenefitOptions?.customBenefitId || '',
        })
        .then(res => res.data),
    {
      enabled: !!cardItem.customBenefitOptions?.customBenefitId,
      gcTime: 60000,
      staleTime: 60000,
    },
  );

  const handleClick = useCallback(() => {
    onClick?.(cardItem);
  }, [onClick, cardItem]);

  const Icon = customBenefit ? CUSTOM_BENEFIT_ICONS[customBenefit.iconName] : BENEFIT_ICONS[cardItem.benefit];

  return (
    <Card key={cardItem.benefit} variant="outlined" data-test-id={`benefit-card-${cardItem.benefit}`}>
      <CardActionArea component="div" onClick={handleClick}>
        <CardHeader
          sx={{ py: 1 }}
          avatar={<Icon color="primary" fontSize="large" />}
          title={
            <Typography color="primary.main" mb={0.5}>
              {customBenefit ? customBenefit.title : t(`common:benefit.${cardItem.benefit}`)}
            </Typography>
          }
          subheader={<BenefitOptionChips benefitOptions={cardItem} />}
          action={
            <>
              {actions}
              {/* align with option chips */}
              <Box marginTop="-3px">{actionChips}</Box>
            </>
          }
        />
        {cardItem.benefit === BenefitDTOBenefitEnum.Flex && (
          <CardContent sx={{ paddingLeft: 7.2, paddingTop: 0, paddingBottom: 1 }}>
            <Typography color="text.secondary">{t('benefitsModule:FLEX.cardSubtitle')}</Typography>
            {tenant?.flexBenefits?.map(benefit => {
              const FlexIcon = BENEFIT_ICONS[benefit];

              return (
                <Stack key={benefit} direction="row" spacing={1}>
                  <FlexIcon color="primary" fontSize="small" />
                  <Typography color="text.secondary">{t(`common:benefit.${benefit}`)}</Typography>
                </Stack>
              );
            })}
          </CardContent>
        )}
      </CardActionArea>
    </Card>
  );
};
