import { MRT_ColumnDef } from 'material-react-table';
import { BikeleasingContractWithFullNameDTO } from 'probonio-shared-ui/api';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useBikeleasingContractColumns(): MRT_ColumnDef<BikeleasingContractWithFullNameDTO>[] {
  const { t } = useTranslation('benefitsModule');
  return useMemo<MRT_ColumnDef<BikeleasingContractWithFullNameDTO>[]>(
    () => [
      {
        id: 'name',
        accessorKey: 'fullName',
        header: t('BIKELEASING.contractsTable.header.name'),
        sortBy: ['employee.user.lastName', 'employee.user.firstName'],
      },
      {
        accessorKey: 'contractNumber',
        header: t('BIKELEASING.contractsTable.header.contractNumber'),
      },
      {
        accessorKey: 'startDate',
        header: t('BIKELEASING.contractsTable.header.startDate'),
        Cell: ({ cell }) => (cell.getValue<string>() ? t('common:date', { date: new Date(cell.getValue<string>()) }) : null),
      },
      {
        accessorKey: 'endDate',
        header: t('BIKELEASING.contractsTable.header.endDate'),
        Cell: ({ cell }) => (cell.getValue<string>() ? t('common:date', { date: new Date(cell.getValue<string>()) }) : null),
      },
      {
        accessorFn: row => `${row.bike.brand ?? ''} ${row.bike.model ?? ''}`.trim(),
        enableSorting: false,
        header: t('BIKELEASING.contractsTable.header.bike'),
      },
      {
        accessorFn: row => t([`BIKELEASING.contract.status.${row.status}`, 'BIKELEASING.contract.status.fallback']),
        enableSorting: false,
        header: t('BIKELEASING.contractsTable.header.status'),
      },
    ],
    [t],
  );
}
