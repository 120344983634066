import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { Box, IconButton, Typography } from '@mui/material';

interface Props {
  children: React.ReactNode;
  title: string;
  menu?: React.ReactNode;
  onNavigateBack?: () => void;
}

const SubPage: React.FC<Props> = ({ children, title, menu, onNavigateBack }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          marginY: 1,
        }}
      >
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
          {!!onNavigateBack && (
            <IconButton
              data-test-id="back-button-user"
              sx={{
                marginRight: 2,
                padding: 0,
              }}
              onClick={onNavigateBack}
            >
              <ChevronLeft fontSize="large" />
            </IconButton>
          )}

          <Typography data-test-id="user-profile-card-title" variant="h1" noWrap fontWeight="regular">
            {title}
          </Typography>
        </Box>
        <Box display="flex">{menu}</Box>
      </Box>
      {children}
    </>
  );
};

export default SubPage;
