import { DateTime } from 'luxon';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';

export interface ReportFilter {
  searchText?: string;
  month: DateTime;
  department?: string;
}

export interface ReportFilterState {
  filter: ReportFilter;
  setFilter: Dispatch<SetStateAction<ReportFilter>>;
}

export function useReportFilterState(): ReportFilterState {
  const [filter, setFilter] = useState<ReportFilter>({
    month: DateTime.now().minus({ months: 1 }).startOf('month'),
  });

  return useMemo(
    () => ({
      filter,
      setFilter,
    }),
    [filter],
  );
}
