import { UseMutationResult, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { apis, downloadResponse } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';

export function useDownloadReceiptMutation(
  benefit: BenefitDTOBenefitEnum,
  receipt: { employeeId: string; id: string },
): UseMutationResult<void, Error, { fileName: string; type: 'image' | 'pdf' }> {
  const getTenantId = useTenantID();

  if (
    benefit !== BenefitDTOBenefitEnum.Lunch &&
    benefit !== BenefitDTOBenefitEnum.Mobility &&
    benefit !== BenefitDTOBenefitEnum.Recreation
  ) {
    throw new Error(`Unsupported benefit ${benefit}`);
  }

  return useMutation({
    mutationFn: async ({ fileName, type }) => {
      const receiptWithImage = await apis[benefit.toLowerCase() as Lowercase<typeof benefit>]
        .getReceipt({
          tenantId: getTenantId(),
          employeeId: receipt.employeeId,
          receiptId: receipt.id,
          withImage: true,
        })
        .then(res => res.data);

      const url = type === 'image' ? receiptWithImage!.imageURL! : receiptWithImage!.pdfURL!;
      await axios.get(url, { responseType: 'blob' }).then(res => {
        downloadResponse(res, fileName);
      });
    },
  });
}
