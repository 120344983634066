import CreateIcon from '@mui/icons-material/Create';
import { Button, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BenefitPaper } from '../../../component/benefitTabs/BenefitPaper';
import { useNavigate } from 'react-router-dom';

export const NewsBenefitMenu: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(`/benefits/news/addNewArticle`, { state: { forceHistoryBack: true } });
  }, [navigate]);

  return (
    <>
      <BenefitPaper size="sm" sx={{ display: 'flex', alignItems: 'center', px: 2.5, gap: 3 }}>
        <Typography width={200} flexGrow={1} variant="body2">
          {t('NEWS.hintText')}
        </Typography>
        <Button
          data-test-id="news-article-create-button"
          size="small"
          variant="contained"
          color="primary"
          startIcon={<CreateIcon />}
          onClick={handleClick}
        >
          {t('NEWS.addNewArticlesButton')}
        </Button>
      </BenefitPaper>
    </>
  );
};
