import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Button, ButtonProps, Tooltip } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { EmployeeDTOStatusEnum } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewDialogState } from '../../component/dialog';
import { ConfirmationModal } from '../../component/dialog/ConfirmationModal';
import { EmployeeFilter, mapEmployeeFilterToRequest } from '../benefits/employeeStatusList/employeeFilterState';
import { useRefetchEmployees } from './query';
import { useEmployeeList } from './useEmployeeList';

interface Props {
  buttonProps?: ButtonProps;
  filter?: EmployeeFilter;
}

export const UnlockAllUsersButton: React.FC<Props> = ({ buttonProps, filter }) => {
  const refetchEmployees = useRefetchEmployees();
  const getTenantId = useTenantID();
  const confirmUnlockAllDialog = useNewDialogState();
  const { t } = useTranslation('usersPage');
  const { data } = useEmployeeList({
    ...mapEmployeeFilterToRequest(filter),
    status: EmployeeDTOStatusEnum.Registered,
    page: 0,
    pageSize: 0,
  });

  const mutation = useMutation({
    mutationFn: () =>
      apis.employee.unlockAllRegisteredUsers({
        tenantId: getTenantId(),
        ...mapEmployeeFilterToRequest(filter),
        status: EmployeeDTOStatusEnum.Registered,
      }),

    onSuccess: async () => {
      await refetchEmployees();
      confirmUnlockAllDialog.dialogState.handleClose();
    },
  });
  const unlockUsers = useCallback(() => {
    mutation.mutate();
  }, [mutation]);

  if (!data?.totalCount) {
    return <></>;
  }

  return (
    <>
      <ConfirmationModal
        dataTestId="confirm-unlockAllEmployees-button"
        dialogState={confirmUnlockAllDialog.dialogState}
        onConfirm={unlockUsers}
        closeOnConfirm={false}
        loading={mutation.isPending}
        title={t('unlockAllEmployees.confirmTitle')}
      >
        {t('unlockAllEmployees.confirmText', { count: data?.totalCount || 0 })}
      </ConfirmationModal>
      <Tooltip title={t('unlockAllEmployees.buttonText', { count: data?.totalCount || 0 })}>
        <Button onClick={confirmUnlockAllDialog.handleOpen} startIcon={<LockOpenIcon />} size="small" color="success" {...buttonProps}>
          {data?.totalCount || 0}
        </Button>
      </Tooltip>
    </>
  );
};
