import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import { Box, CircularProgress, IconButton, Stack } from '@mui/material';
import { BenefitDTOBenefitEnum, LunchReceiptTenantDTO, MobilityReceiptTenantDTO, RecreationReceiptTenantDTO } from 'probonio-shared-ui/api';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useReceiptWithImageQuery } from './useReceiptWithImageQuery';
import { useDownloadReceiptMutation } from './useDownloadReceiptMutation';

interface Props {
  benefit: BenefitDTOBenefitEnum;
  receipt: LunchReceiptTenantDTO | MobilityReceiptTenantDTO | RecreationReceiptTenantDTO;
}

const IMAGE_WIDTH = 400;

export const ReceiptImageView: React.FC<Props> = ({ benefit, receipt }) => {
  const { t } = useTranslation('receiptsModule');
  const pinPanchRef = useRef<ReactZoomPanPinchRef>(null);
  const [rotation, setRotation] = useState(0);
  const [minScale, setMinScale] = useState(0.2);
  const [isPanning, setIsPanning] = useState(false);

  const { data: receiptWithImage } = useReceiptWithImageQuery(benefit, { employeeId: receipt.employee.id, id: receipt.id });
  const downloadMutation = useDownloadReceiptMutation(benefit, { employeeId: receipt.employee.id, id: receipt.id });

  const handleRotate = useCallback(() => {
    setRotation(r => (r + 90) % 360);
  }, []);

  const handleResetPosition = useCallback(() => {
    if (pinPanchRef.current?.instance.wrapperComponent && pinPanchRef.current?.instance.contentComponent) {
      const scaleX = pinPanchRef.current.instance.wrapperComponent.offsetWidth / pinPanchRef.current.instance.contentComponent.offsetWidth;
      const scaleY =
        pinPanchRef.current.instance.wrapperComponent.offsetHeight / pinPanchRef.current.instance.contentComponent.offsetHeight;
      setMinScale(Math.min(scaleX, scaleY));
      pinPanchRef.current.centerView(scaleY, 100);
    }
  }, []);

  const fileNamePrefix = `${t('imageView.receipt')} ${t(`common:benefit.${benefit}`)} ${receipt.date} ${receipt.firstName} ${receipt.lastName}`;
  const handleDownloadImage = useCallback(() => {
    const fileName = `${fileNamePrefix}.jpg`;
    downloadMutation.mutate({ fileName, type: 'image' });
  }, [downloadMutation, fileNamePrefix]);
  const handleDownloadPDF = useCallback(() => {
    const fileName = `${fileNamePrefix}.pdf`;
    downloadMutation.mutate({ fileName, type: 'pdf' });
  }, [downloadMutation, fileNamePrefix]);

  const handlePanningStart = useCallback(() => {
    setIsPanning(true);
  }, []);
  const handlePanningStop = useCallback(() => {
    setIsPanning(false);
  }, []);

  return (
    <>
      <Stack direction="row" height="100%" justifyContent="center" alignItems="stretch" spacing={1}>
        {receiptWithImage ? (
          <TransformWrapper
            minScale={minScale}
            centerZoomedOut
            centerOnInit
            ref={pinPanchRef}
            onPanningStart={handlePanningStart}
            onPanningStop={handlePanningStop}
          >
            <TransformComponent wrapperStyle={{ height: 'auto', width: IMAGE_WIDTH, flexGrow: 1, cursor: isPanning ? 'grabbing' : 'grab' }}>
              <img
                alt=""
                className="receipt-img"
                src={receiptWithImage.imageURL}
                style={{ transform: `rotate(${rotation}deg)` }}
                onLoad={handleResetPosition}
              />
            </TransformComponent>
          </TransformWrapper>
        ) : (
          <Box width={IMAGE_WIDTH} height="auto" flexGrow={1} display="flex" justifyContent="center" alignItems="center">
            <CircularProgress size={IMAGE_WIDTH / 5} />
          </Box>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <IconButton title={t('imageView.rotate')} onClick={handleRotate}>
            <RotateRightIcon />
          </IconButton>
          <IconButton title={t('imageView.centerView')} onClick={handleResetPosition}>
            <ZoomInMapIcon />
          </IconButton>
          <IconButton title={t('imageView.downloadImage')} onClick={handleDownloadImage}>
            <DownloadIcon />
          </IconButton>
          {receiptWithImage?.pdfURL && (
            <IconButton title={t('imageView.downloadPDF')} onClick={handleDownloadPDF}>
              <PictureAsPdfIcon />
            </IconButton>
          )}
        </Box>
      </Stack>
    </>
  );
};
