import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BenefitPaper } from '../../component/benefitTabs/BenefitPaper';
import { BENEFIT_ICONS } from '../../module/benefits/BenefitIcon';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { CouponsV2SepaAlert } from '../../module/benefits/couponsV2/CouponsV2SepaAlert';
import { TokenOrderHistoryList } from '../../module/benefits/couponsV2/orderHistory/TokenOrderHistoryList';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';

export const CouponsV2BudgetPage: React.FC = () => {
  const { t } = useTranslation('couponsV2Module');

  return (
    <AuthorizedLayout
      title={t('budgetPage.subTitle')}
      breadcrumbs={[{ title: t('budgetPage.breadcrumbTitle'), to: '/benefits/coupons_v2' }]}
    >
      <Box mt={0.5}>
        <CouponsV2SepaAlert />
      </Box>
      <BenefitSubPage icon={BENEFIT_ICONS.COUPONS_V2} title={t('budgetPage.breadcrumbTitle')} subTitle={t('budgetPage.subTitle')}>
        <BenefitPaper>
          <TokenOrderHistoryList />
        </BenefitPaper>
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};
