import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PaymentsIcon from '@mui/icons-material/Payments';
import { Typography, useTheme } from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';
import { CouponsV2GiftDTO, CouponsV2GiftDTOStatusEnum } from 'probonio-shared-ui/api';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SortByDef } from '../../../../component/table/sortMapper';

export function useGiftsV2Columns(): (MRT_ColumnDef<CouponsV2GiftDTO> & SortByDef)[] {
  const { t } = useTranslation('couponsV2Module');
  const theme = useTheme();

  return useMemo(
    (): (MRT_ColumnDef<CouponsV2GiftDTO> & SortByDef)[] => [
      {
        accessorKey: 'payoutDate',
        header: t('gifts.fields.payoutDate'),
        Cell: ({ cell }) => t('common:date', { date: new Date(cell.getValue<string>()) }),
      },
      {
        accessorKey: 'giftType',
        header: t('gifts.fields.giftType'),
        maxSize: 160,
        Cell: ({ cell }) => (
          <Typography variant="body2" data-test-id="gift-type-cell">
            {t(`gifts.giftType.${cell.getValue<string>()}`)}
          </Typography>
        ),
        enableSorting: false,
      },
      {
        accessorKey: 'occasion',
        header: t('gifts.fields.occasion'),
        Cell: ({ cell }) => t(`couponsModule:giftOccasions.${cell.getValue<string>()}`),
        enableSorting: false,
      },
      {
        accessorKey: 'recurring',
        header: t('gifts.fields.recurring'),
        maxSize: 160,
        Cell: ({ cell }) => (cell.getValue<boolean>() ? t('common:yes') : t('common:no')),
        enableSorting: false,
      },
      {
        accessorKey: 'amount',
        header: t('gifts.fields.amount'),
        maxSize: 80,
        Cell: ({ cell, row }) => {
          return <Typography variant="body2">{t('common:money', { money: cell.getValue<number>() })}</Typography>;
        },
      },
      {
        accessorKey: 'status',
        header: t('gifts.fields.status'),
        minSize: 0,
        size: 0,
        Cell: ({ cell, row }) => {
          let icon: React.ReactNode = null;

          const status = cell.getValue<CouponsV2GiftDTOStatusEnum | undefined>();
          if (status === CouponsV2GiftDTOStatusEnum.Pending) {
            icon = <AccessTimeFilledIcon fontSize="small" sx={{ color: theme.palette.text.secondary }} />;
          } else if (status === CouponsV2GiftDTOStatusEnum.InvoiceCreated) {
            icon = <PaymentsIcon fontSize="small" color="warning" />;
          } else if (status === CouponsV2GiftDTOStatusEnum.Available) {
            icon = <CheckCircleIcon fontSize="small" color="success" />;
          } else {
            icon = <CheckCircleIcon fontSize="small" color="success" />;
          }

          return (
            <Typography variant="body2" display="flex" gap={0.5} data-test-id="gift-status-cell">
              {icon}
              {t(`gifts.status.${status}`)}
            </Typography>
          );
        },
        enableSorting: false,
      },
    ],
    [t, theme.palette.text.secondary],
  );
}
