import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, OutlinedInput, OutlinedInputProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './SearchInput.css';

export const SearchInput: React.FC<Omit<OutlinedInputProps, 'startAdornment'>> = ({ ...props }) => {
  const { t } = useTranslation('usersPage');

  return (
    <OutlinedInput
      type="search"
      placeholder={t('search')}
      size={props.size}
      {...props}
      sx={{ backgroundColor: 'background.paper', width: 300, ...props.sx }}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
    />
  );
};
