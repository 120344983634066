import { StyledComponentProps, styled } from '@mui/material';
import { Editable } from 'slate-react';

type StyledEditableProps = StyledComponentProps & {
  error?: boolean;
};

export const CustomEditable = styled(Editable)<StyledEditableProps>(({ theme, error }) => ({
  border: `solid 1px ${!error ? theme.palette.grey[400] : theme.palette.error.main}`,
  color: theme.palette.grey[800],
  borderRadius: theme.shape.borderRadius,
  padding: '10px 8px',
  position: 'relative',
  overflow: 'auto',
  maxHeight: '129px',
  minHeight: '129px',
  ':hover': {
    borderColor: !error ? theme.palette.text.primary : theme.palette.error.light,
  },
  ':focus': {
    border: `2px solid ${!error ? theme.palette.primary.main : theme.palette.error.main}`,
    // to prevent text from jumping around
    left: '-1px',
    top: '-1px',
  },
}));
