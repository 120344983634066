import { Box } from '@mui/material';
import { MRT_Row } from 'material-react-table';
import { BAVContractDTO } from 'probonio-shared-ui/api';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { InfiniteScrollReactTable } from '../../../component/table/InfiniteScrollReactTable';
import { useLoadMore } from '../../../component/table/useLoadMore';
import { useBAVContractColumns } from './bavContractColumns';
import { useInfiniteBAVContracts } from './useBAVContracts';
import { useNavigateWithPrevPath } from '../../../util/useNavigateWithPrevPath';

export const ContractsTable: React.FC = () => {
  const { t } = useTranslation('bavModule');
  const navigate = useNavigateWithPrevPath();
  const columns = useBAVContractColumns();

  const queryResult = useInfiniteBAVContracts();

  const loadMoreProps = useLoadMore(queryResult, 'results');

  const handleRowProps = useCallback(
    ({ row }: { row: MRT_Row<BAVContractDTO> }) => ({
      onClick: () => {
        navigate(`/benefits/bav/contracts/${row.original.employeeId}`);
      },
      onKeyDown: (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
          navigate(`/benefits/bav/contracts/${row.original.employeeId}`);
        }
      },
      sx: { cursor: 'pointer' },
    }),
    [navigate],
  );

  return (
    <Box>
      <InfiniteScrollReactTable
        {...loadMoreProps}
        columns={columns}
        enableSorting={false}
        enablePagination={false}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        enableColumnActions={false}
        enableSortingRemoval={false}
        localization={{ noRecordsToDisplay: t('contractsTable.noResults') }}
        muiTableBodyRowProps={handleRowProps}
      />
    </Box>
  );
};
