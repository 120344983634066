import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { enqueueSnackbar } from 'notistack';
import { CreateNewsArticleDTOArticleStatusEnum, NewsArticleDTO } from 'probonio-shared-ui/api';
import { SwitchControl } from 'probonio-shared-ui/component/form';
import { apis } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ContentEditor } from '../../../component/contentEditor/ContentEditor';
import { DatePickerControl, TextFieldControl } from '../../../component/form';
import { SelectImageControl } from '../../../component/form/SelectImageControl';
import { ImageFile } from '../../../component/imageFile/ImageFile';
import { SettingsFormRow } from '../../../component/settings/SettingsFormRow';
import { uploadNewsArticleImageFile } from './fileUpload';
import { UnsavedChangesListener } from '../../../component/form/UnsavedChangesListener';
import { AxiosError } from 'axios';

interface Props {
  article?: NewsArticleDTO;
}

export interface FormValues {
  isActive: boolean;
  html: string | undefined;
  title: string;
  image: string | ImageFile;
  readyToPublish: boolean;
  releaseDate?: Date;
  removalDate?: Date;
  readConfirmation?: boolean;
}

export const NewsArticlesCreator: React.FC<Props> = ({ article }) => {
  const { control, getValues, handleSubmit, watch, formState } = useForm<FormValues>({
    defaultValues: {
      isActive: false,
      readConfirmation: article?.readConfirmation || false,
      html: article?.description || '',
      title: article?.title || '',
      image: article?.imageURL || '',
      readyToPublish: article?.articleStatus !== 'DRAFT',
      releaseDate: article?.publishDate ? DateTime.fromISO(article?.publishDate).toJSDate() : undefined,
      removalDate: article?.removalDate ? DateTime.fromISO(article?.removalDate).toJSDate() : undefined,
    },
  });

  const { t } = useTranslation('benefitsModule');

  const getTenantId = useTenantID();
  const queryClient = useQueryClient();
  const withMessage = useWithMessage();
  const handleActivationError = useCallback(
    (err: Error) => {
      if ((err as AxiosError).response?.status === 422) {
        enqueueSnackbar(t('NEWS.articleCreator.errorCreate'), { variant: 'error' });
      } else {
        withMessage(err);
      }
    },
    [t, withMessage],
  );

  const navigate = useNavigate();

  const newsArticleMutation = useMutation({
    mutationFn: async () => {
      const updateArticle: FormValues = {
        isActive: getValues('isActive'),
        readConfirmation: getValues('readConfirmation'),
        html: getValues('html'),
        title: getValues('title'),
        image: getValues('image'),
        releaseDate: getValues('releaseDate'),
        removalDate: getValues('removalDate'),
        readyToPublish: article?.articleStatus === 'PUBLISHED' ? true : getValues('readyToPublish'),
      };

      const tenantId = getTenantId();
      let blob: Blob;
      if (typeof updateArticle.image === 'string') {
        // template image
        blob = await fetch(updateArticle.image).then(res => res.blob());
      } else {
        // uploaded user image
        blob = await updateArticle.image.getBlob();
      }

      const imageKey = updateArticle.image ? await uploadNewsArticleImageFile(tenantId, blob.type, blob) : undefined;

      const trueArticleStatus: CreateNewsArticleDTOArticleStatusEnum =
        updateArticle.readyToPublish === true &&
        (!updateArticle.releaseDate || DateTime.fromJSDate(updateArticle.releaseDate) < DateTime.now())
          ? 'PUBLISHED'
          : updateArticle.readyToPublish
            ? 'AVAILABLE'
            : 'DRAFT';

      if (article) {
        await apis.news.updateArticle({
          tenantId,
          articleId: article?.id || '',
          updateNewsArticleDTO: {
            description: updateArticle.html || '',
            publishDate:
              updateArticle?.readyToPublish === false
                ? null
                : (updateArticle.releaseDate && updateArticle.releaseDate.toISOString()) || null,
            removalDate:
              updateArticle?.readyToPublish === false
                ? null
                : (updateArticle.removalDate && updateArticle.removalDate.toISOString()) || null,
            titleImageKey: imageKey || undefined,
            title: updateArticle.title,
            articleStatus: trueArticleStatus,
            readConfirmation: updateArticle.readConfirmation,
          },
        });
      } else {
        await apis.news
          .createArticle({
            tenantId,
            createNewsArticleDTO: {
              description: updateArticle.html || '',
              publishDate:
                updateArticle?.readyToPublish === false ? undefined : updateArticle.releaseDate && updateArticle.releaseDate.toISOString(),
              removalDate: updateArticle.removalDate ? updateArticle.removalDate.toISOString() : undefined,
              titleImageKey: imageKey || undefined,
              title: updateArticle.title,
              articleStatus: trueArticleStatus,
              readConfirmation: updateArticle.readConfirmation,
            },
          })
          .then(res => res.data);
      }
      return trueArticleStatus;
    },

    onSuccess: async status => {
      if (status === 'PUBLISHED') {
        await queryClient.invalidateQueries({ queryKey: ['tenants', getTenantId(), 'benefits', 'news'] });
        enqueueSnackbar(t('NEWS.articleCreator.successPublish'), { variant: 'success' });
        navigate('/benefits/news');
      } else {
        await queryClient.invalidateQueries({ queryKey: ['tenants', getTenantId(), 'benefits', 'news'] });
        enqueueSnackbar(t('NEWS.articleCreator.successUpdate'), { variant: 'success' });
        navigate('/benefits/news');
      }
    },
    onError: (err: Error) => handleActivationError(err),
  });

  const handleSubmitArticle = useCallback(async () => {
    await newsArticleMutation.mutateAsync().catch(() => void 0);
  }, [newsArticleMutation]);

  const readyToPublish = watch('readyToPublish');
  const releaseDate = watch('releaseDate');

  return (
    <form onSubmit={handleSubmit(handleSubmitArticle)}>
      <UnsavedChangesListener formState={formState} />
      <SettingsFormRow
        descriptionWidth={4}
        settingsWidth={8}
        fullWidth
        title={t('NEWS.articleCreator.imageHeader')}
        info={t('NEWS.articleCreator.imageDescription')}
        hideDivider
      >
        <Grid mx="auto" maxWidth={400}>
          <SelectImageControl
            defaultValue={article?.imageURL}
            control={control}
            name="image"
            sx={{ height: 260 }}
            shouldCoverBox
            maxSize={1280}
            disabled={article?.articleStatus === 'EXPIRED'}
          />
        </Grid>
      </SettingsFormRow>
      <SettingsFormRow
        descriptionWidth={4}
        settingsWidth={8}
        fullWidth
        title={t('NEWS.articleCreator.articleHeader')}
        info={t('NEWS.articleCreator.articleDescription')}
      >
        <Grid mb={2}>
          <TextFieldControl
            required
            control={control}
            name="title"
            disabled={article?.articleStatus === 'EXPIRED'}
            fullWidth
            label={t('NEWS.labels.title')}
            rules={{ required: true, maxLength: 100 }}
          />
        </Grid>
        <ContentEditor
          readOnly={article?.articleStatus === 'EXPIRED'}
          height="400px"
          name="html"
          control={control}
          initialPlaceholder={t('NEWS.labels.description')}
        />
      </SettingsFormRow>
      <SettingsFormRow
        descriptionWidth={4}
        settingsWidth={8}
        fullWidth
        title={t('NEWS.articleCreator.settingsHeader')}
        info={t('NEWS.articleCreator.settingsDescription')}
      >
        <Grid container flexGrow={1} display="flex" mb={2}>
          <Grid size={3} alignSelf="center">
            <Typography variant="h3">{t('NEWS.labels.toBePublished')}</Typography>
          </Grid>
          <Grid>
            <SwitchControl
              name="readyToPublish"
              disabled={article?.articleStatus === 'PUBLISHED' || article?.articleStatus === 'EXPIRED'}
              label={undefined}
              control={control}
            />
          </Grid>
        </Grid>
        <Grid container flexGrow={1} display="flex" mb={2}>
          <Grid size={3} alignSelf="center">
            <Typography variant="h3">{t('NEWS.labels.readConfirmation')}</Typography>
          </Grid>
          <Grid>
            <SwitchControl
              data-test-id="switch-read-confirmation"
              name="readConfirmation"
              disabled={article?.articleStatus === 'PUBLISHED' || article?.articleStatus === 'EXPIRED'}
              label={undefined}
              control={control}
            />
          </Grid>
        </Grid>
        {readyToPublish && (
          <Grid container display="flex" flexDirection="column" spacing={2}>
            <Grid container flexGrow={1} display="flex">
              <Grid size={3} alignSelf="center">
                <Typography variant="h3">{t('NEWS.labels.releaseDate')}</Typography>
              </Grid>
              <Grid>
                {article?.articleStatus === 'PUBLISHED' || article?.articleStatus === 'EXPIRED' ? (
                  <Typography>{t('common:dateTime', { date: new Date(article.publishDate!) })}</Typography>
                ) : (
                  <DatePickerControl
                    minDate={DateTime.now().toJSDate()}
                    minTime={DateTime.now().toJSDate()}
                    maxTime={DateTime.now().endOf('day').toJSDate()}
                    control={control}
                    name="releaseDate"
                    showTimeSelect
                    dateFormat="dd.MM.yyyy, HH:mm"
                    slotProps={{ input: { readOnly: true } }}
                    showClearButton
                    clearButtonTitle={t('NEWS.articleCreator.clearStartDateTooltip')}
                  />
                )}
              </Grid>
            </Grid>
            <Grid container flexGrow={1} display="flex">
              <Grid size={3} alignSelf="center">
                <Typography variant="h3" alignSelf="center">
                  {t('NEWS.labels.removalDate')}
                </Typography>
              </Grid>
              <Grid>
                {article?.articleStatus === 'EXPIRED' ? (
                  <Typography>{t('common:dateTime', { date: new Date(article.removalDate!) })}</Typography>
                ) : (
                  <DatePickerControl
                    minDate={releaseDate || DateTime.now().toJSDate()}
                    minTime={releaseDate || DateTime.now().toJSDate()}
                    maxTime={DateTime.now().endOf('day').toJSDate()}
                    control={control}
                    name="removalDate"
                    showTimeSelect
                    dateFormat="dd.MM.yyyy, HH:mm"
                    slotProps={{ input: { readOnly: true } }}
                    showClearButton
                    clearButtonTitle={t('NEWS.articleCreator.clearEndDateTooltip')}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </SettingsFormRow>
      <Grid mt={2} display="flex" justifyContent="flex-end">
        {article?.articleStatus !== 'EXPIRED' && (
          <Button color="primary" type="submit">
            {!article ? t('NEWS.articleCreator.createArticle') : t('NEWS.articleCreator.updateArticle')}
          </Button>
        )}
      </Grid>
    </form>
  );
};
