import EditIcon from '@mui/icons-material/Edit';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Avatar, Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import {
  EmployeeDTOEmployeeTypeEnum,
  EmployeeDTORolesEnum,
  EmployeeDTOStatusEnum,
  UpdateEmployeeDTORolesEnum,
  UserDTOStatusEnum,
} from 'probonio-shared-ui/api';
import { IconToggleButton } from 'probonio-shared-ui/component/button';
import { CopyToClipboardButton } from 'probonio-shared-ui/component/button/CopyToClipboardButton';
import React, { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ActionCardContent } from '../../../../component/layout/ActionCardContent';
import { InviteUserButton } from '../../InviteUserButton';
import { LetterAvatar } from '../../LetterAvatar';
import { UnlockUserButton } from '../../UnlockUserButton';
import { mapEmployeeStatus } from '../../employeeStatus';
import { renderUserName } from '../../userFormatter';
import { useProfileEmployee } from '../activations/hooks/useProfileEmployee';

interface Props {
  editActive?: boolean;
  handleEdit: () => void;
}

const UserField: React.FC<{ color?: string; label: string; value?: string; button?: React.ReactNode; dataTestId?: string }> = ({
  color,
  label,
  value,
  button,
  dataTestId,
}) => {
  return value ? (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Typography variant="body2" color="text.secondary" fontWeight="bold" minWidth="60px" flexShrink={0} id="mail">
        {label}
      </Typography>
      <Typography
        variant="body2"
        fontWeight="bold"
        color={color}
        data-test-id={dataTestId}
        flexGrow={1}
        textAlign="right"
        style={{ wordBreak: 'break-word' }}
      >
        {value}
      </Typography>
      {button && (
        <Box my={-1} ml={0.5}>
          {button}
        </Box>
      )}
    </Box>
  ) : null;
};

function formatRegistrationTokenLink(employeeRoles: EmployeeDTORolesEnum[], token: string): string {
  if (employeeRoles.includes(EmployeeDTORolesEnum.TenantAdmin)) {
    return `${location.origin}/register#token=${token}`;
  }
  return `${import.meta.env.VITE_WEB_APP_URL}/register#token=${token}`;
}

const ProfileCard: React.FC<Props> = ({ editActive, handleEdit }) => {
  const { t } = useTranslation('usersModule');
  const { employee } = useProfileEmployee();
  const USER_ROLES: readonly UpdateEmployeeDTORolesEnum[] = useMemo(() => {
    return ['USER', 'TENANT_ADMIN', 'TENANT_ASSISTANT'] as const;
  }, []);

  const getRoleName = useCallback(
    (inputRole: string) => {
      const currentRole = USER_ROLES.find(role => role === inputRole);
      return currentRole ? t(`common:role.${currentRole}.name`) : undefined;
    },
    [USER_ROLES, t],
  );

  const getEmployeeTypeName = useCallback(
    (inputType: EmployeeDTOEmployeeTypeEnum | undefined) => {
      return inputType
        ? t(`common:employeeType.${Object.values(EmployeeDTOEmployeeTypeEnum).find(type => inputType === type)}`)
        : undefined;
    },
    [t],
  );

  const getDate = useCallback(
    (date: string | undefined) => {
      return date ? t('common:date', { date: new Date(date) }) : undefined;
    },
    [t],
  );

  const employeeStatus = mapEmployeeStatus(employee);

  const isInviteReinviteUserButtonVisible =
    employeeStatus.status !== EmployeeDTOStatusEnum.Inactive &&
    (employee.user?.status === UserDTOStatusEnum.Created || employee.user?.status === UserDTOStatusEnum.Pending);

  return (
    <ActionCardContent
      title={t('userMenu.profile')}
      actions={
        <IconToggleButton onClick={handleEdit} data-test-id="user-profile-card-edit" active={editActive}>
          <EditIcon fontSize="small" />
        </IconToggleButton>
      }
    >
      <Stack display="flex" flexDirection="column" gap={1} justifyContent="flex-start">
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={2}>
          {employee.user ? (
            <LetterAvatar firstName={employee.user.firstName} lastName={employee.user.lastName} mail={employee.user.mail} size={100} />
          ) : (
            <Avatar sx={{ width: 100, height: 100 }} />
          )}
          <Typography
            variant="body1"
            paddingTop={1}
            style={{ wordBreak: 'break-word', textAlign: 'center' }}
            data-test-id="user-profile-card-user-name"
          >
            {employee.user?.salutation} {renderUserName(employee.user, employee.employeeNumber)}
          </Typography>
        </Box>
        <UserField
          label={t('userForm.field.status.label')}
          value={t(`userForm.field.status.${employeeStatus.status}`)}
          color={employeeStatus.color}
          button={
            employeeStatus.status === 'PENDING' &&
            employee.registrationToken && (
              <CopyToClipboardButton
                title={t('userForm.copyRegistrationLink')}
                value={formatRegistrationTokenLink(employee.roles, employee.registrationToken)}
                size="small"
                sx={{ mr: -0.75 }}
              />
            )
          }
          dataTestId="user-status"
        />
        {(employee.status === 'INACTIVE' || employeeStatus.status === 'PLANNED_TERMINATION') && employee.terminationDate && (
          <UserField
            label={t('userForm.field.terminationDate.label')}
            value={t('userForm.field.terminationDate.value', { date: new Date(employee.terminationDate) })}
            dataTestId="employee-termination-date"
          />
        )}

        <UserField dataTestId="user-profile-card-user-mail" label={t('userForm.field.mail')} value={employee.user?.mail} />
        <UserField
          dataTestId="user-profile-card-user-birth-date"
          label={t('userForm.field.birthDate')}
          value={getDate(employee.birthDate)}
        />

        <UserField
          dataTestId="user-profile-card-user-employee-number"
          label={t('userForm.field.employeeNumber')}
          value={employee.employeeNumber}
        />

        <UserField dataTestId="user-profile-card-user-role" label={t('userForm.field.role')} value={getRoleName(employee.roles[0])} />
        <UserField dataTestId="user-profile-card-user-department" label={t('userForm.field.department')} value={employee.department} />
        <UserField dataTestId="user-profile-card-user-costCenter" label={t('userForm.field.costCenter')} value={employee.costCenter} />
        <UserField
          dataTestId="user-profile-card-user-employee-type"
          label={t('userForm.field.employeeTypeLabel')}
          value={getEmployeeTypeName(employee.employeeType)}
        />
        <UserField dataTestId="user-profile-card-user-hire-date" label={t('userForm.field.hireDate')} value={getDate(employee.hireDate)} />
        {employee.user?.status === UserDTOStatusEnum.Pending && employee.user.mailDeliveryError && (
          <Typography variant="body2" color="text.secondary">
            <Trans i18nKey="usersModule:userForm.mailDeliveryErrorInfo" />
          </Typography>
        )}
        <Box mt={1}>
          {employeeStatus.status === EmployeeDTOStatusEnum.Registered && (
            <UnlockUserButton
              employee={employee}
              buttonProps={{
                fullWidth: true,
                size: 'small',
                color: 'success',
                variant: 'contained',
                startIcon: <LockOpenIcon />,
              }}
            >
              {t('common:userStatusButton.REGISTERED')}
            </UnlockUserButton>
          )}
          {isInviteReinviteUserButtonVisible && (
            <InviteUserButton
              employee={employee}
              tooltip={t(`common:userStatusButton.tooltip.${employee.user!.status.toLowerCase()}Tooltip`)}
              buttonProps={{
                fullWidth: true,
                size: 'small',
                color: employee.user!.status === 'CREATED' ? 'success' : 'secondary',
                variant: 'contained',
                startIcon: <MailOutlineIcon />,
              }}
            >
              {t(`common:userStatusButton.${employee.user!.status}`)}
            </InviteUserButton>
          )}
        </Box>
      </Stack>
    </ActionCardContent>
  );
};

export default ProfileCard;
