import { UseQueryResult } from '@tanstack/react-query';
import { BenefitDTOBenefitEnum, LunchReceiptDTO, MobilityReceiptDTO, RecreationReceiptDTO } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';

export function useReceiptWithImageQuery(
  benefit: BenefitDTOBenefitEnum,
  receipt: { employeeId: string; id: string },
): UseQueryResult<LunchReceiptDTO | MobilityReceiptDTO | RecreationReceiptDTO, Error> {
  if (
    benefit !== BenefitDTOBenefitEnum.Lunch &&
    benefit !== BenefitDTOBenefitEnum.Mobility &&
    benefit !== BenefitDTOBenefitEnum.Recreation
  ) {
    throw new Error(`Unsupported benefit ${benefit}`);
  }

  return useTenantQuery(
    ['employees', receipt.employeeId, 'benefits', benefit.toLowerCase(), 'receipts', receipt.id, 'getReceipt'],
    async tenantId =>
      apis[benefit.toLowerCase() as Lowercase<typeof benefit>]
        .getReceipt({
          tenantId,
          employeeId: receipt.employeeId,
          receiptId: receipt.id,
          withImage: true,
        })
        .then(res => res.data),
  );
}
