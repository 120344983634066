import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { BenefitActivationDTOBenefitEnum, CreateBAVTermsDTO, TenantDTO } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useCallback, useEffect, useMemo } from 'react';
import { SubmitHandler, UseFormReturn, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export enum TermsControls {
  termsHtml = 'termsHtml',
  showToEmployeesSwitch = 'showToEmployeesSwitch',
}

export interface FormValues {
  [TermsControls.termsHtml]: string;
  [TermsControls.showToEmployeesSwitch]: boolean;
}

export interface BAVTermsState {
  handleSubmit: SubmitHandler<FormValues>;
  handleReset: () => void;
  form: UseFormReturn<FormValues>;
  isLoading: boolean;
}

export function useBavTerms(): BAVTermsState {
  const { tenant, invalidateTenant, isLoading: isTenantLoading } = useTenant();
  const withMessage = useWithMessage();

  const { t } = useTranslation();
  const { data: bavTermsList, isLoading: isTermsLoading } = useTenantQuery(
    ['benefits', 'bav', 'terms', { page: 0, pageSize: 1 }, apis.bav.listTerms.name],
    tenantId => apis.bav.listTerms({ tenantId, page: 0, pageSize: 1 }).then(res => res.data),
  );
  const latestBavTerms = bavTermsList?.results[0];

  const navigate = useNavigate();

  const formDefaultValues = useMemo(() => {
    return {
      [TermsControls.termsHtml]: latestBavTerms?.html ?? t('bavModule:terms.defaultTemplate'),
      [TermsControls.showToEmployeesSwitch]: tenant?.bavShowTerms ?? false,
    };
  }, [latestBavTerms?.html, t, tenant?.bavShowTerms]);

  const form = useForm<FormValues>({
    defaultValues: formDefaultValues,
  });

  const handleReset = useCallback(() => {
    navigate(`/benefits/${BenefitActivationDTOBenefitEnum.Bav.toLowerCase()}`);
  }, [navigate]);

  useEffect(() => {
    if (!isTenantLoading && !isTermsLoading) {
      form.reset(formDefaultValues);
    }
    // form is resetted directly in handleSubmit
    // https://stackoverflow.com/questions/63953501/react-hook-form-resetting-isdirty-without-clearing-form
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTenantLoading, isTermsLoading]);

  const createBavTerms = useMutation({
    mutationFn: async ({ html }: CreateBAVTermsDTO) => {
      await apis.bav.createTerms({
        tenantId: tenant!.id,
        createBAVTermsDTO: {
          html,
        },
      });
    },
  });

  const updateTenantShowBavTerms = useMutation({
    mutationFn: async ({ bavShowTerms }: Pick<TenantDTO, 'bavShowTerms'>) => {
      await apis.tenants.updateTenant({
        tenantId: tenant!.id,
        updateTenantDTO: { bavShowTerms },
      });
    },
  });

  const handleSubmit: SubmitHandler<FormValues> = useCallback(
    async values => {
      try {
        await updateTenantShowBavTerms.mutateAsync({ bavShowTerms: values[TermsControls.showToEmployeesSwitch] });
        await createBavTerms.mutateAsync({ html: values[TermsControls.termsHtml] });
        form.reset(form.getValues());
        invalidateTenant();
        enqueueSnackbar(t('bavModule:terms.submitSuccessToast'), { variant: 'success' });
      } catch (e) {
        withMessage(e as Error);
      }
    },
    [createBavTerms, form, invalidateTenant, t, updateTenantShowBavTerms, withMessage],
  );

  return useMemo(() => {
    return {
      handleSubmit,
      handleReset,
      form,
      isLoading: isTenantLoading || isTermsLoading,
    };
  }, [handleSubmit, handleReset, form, isTenantLoading, isTermsLoading]);
}
