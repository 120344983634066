import { Box, Stack, Typography } from '@mui/material';
import { UpdateBenefitActivationDTO } from 'probonio-shared-ui/api';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DialogLoadingButton } from '../../../component/dialog/DialogLoadingButton';
import { ActivationDialogExtensions } from './ActivationDialog';

interface Props<T extends object> {
  activationDialogExtensions: ActivationDialogExtensions<T>;
  onCancel: () => void;
  onUpdate: (update: UpdateBenefitActivationDTO) => Promise<void>;
}

interface FormValues {
  benefitOptions: unknown;
}

export const BulkEditForm = <T extends object>({ activationDialogExtensions, onCancel, onUpdate }: Props<T>): JSX.Element => {
  const { t } = useTranslation('benefitsModule');

  const formMethods = useForm<FormValues>();
  const { handleSubmit, reset, watch, formState } = formMethods;

  const { Header, Fields, InlineField, onResetBenefitOptions, onUpdateActivation } = activationDialogExtensions;

  const handleSave = useCallback(
    async (values: FormValues) => {
      if (!onUpdateActivation) {
        throw new Error('Missing onUpdateActivation function');
      }
      await onUpdateActivation({}, values.benefitOptions as T, async update => {
        await onUpdate(update);
      });
    },
    [onUpdate, onUpdateActivation],
  );

  useEffect(() => {
    const benefitOptions = onResetBenefitOptions();
    reset({ benefitOptions });
  }, [onResetBenefitOptions, reset]);

  const benefitOptions = watch('benefitOptions');

  const header = Header && benefitOptions ? <Header /> : null;
  const fields = Fields && benefitOptions ? <Fields /> : null;
  const inlineField = InlineField && benefitOptions ? <InlineField /> : null;

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Typography color="text.secondary" mb={2}>
        {t('bulkEditDialog.changeOptions.info')}
      </Typography>
      <FormProvider {...formMethods}>
        {header}
        <Box mb={1}>{inlineField}</Box>
        {fields}
      </FormProvider>
      <Stack direction="row" justifyContent="flex-end" spacing={1} mt={2}>
        <DialogLoadingButton type="submit" color="primary" loading={formState.isSubmitting}>
          {t('common:buttons.save')}
        </DialogLoadingButton>
      </Stack>
    </form>
  );
};
