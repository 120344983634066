import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { CouponGiftDTO, CouponsV2GiftDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useInvalidateCouponsV2Gifts } from '../../../../benefits/couponsV2/couponsV2Queries';
import { useRefetchCouponGifts } from '../../../../benefits/query';
import { useProfileEmployee } from './useProfileEmployee';

interface UseDeleteGiftsActivationMutation<T> {
  deleteGift: (gift: T) => void;
}

export function useDeleteV2GiftActivationMutation(): UseDeleteGiftsActivationMutation<CouponsV2GiftDTO> {
  const { employee } = useProfileEmployee();
  const { t } = useTranslation('benefitsModule');

  const getTenantId = useTenantID();
  const { enqueueSnackbar } = useSnackbar();
  const withMessage = useWithMessage();
  const invalidateGifts = useInvalidateCouponsV2Gifts(employee.id);

  const deleteActivationMutation = useMutation({
    mutationFn: async (giftID: string) => {
      const tenantId = getTenantId();
      await apis.couponsV2.deleteCouponGift({
        tenantId,
        employeeId: employee.id,
        couponsV2GiftId: giftID,
      });
    },

    onSuccess: async () => {
      await invalidateGifts();
      enqueueSnackbar(t('couponsV2Module:successDeleteGift'), { variant: 'success' });
    },
    onError: async err => {
      if (err instanceof AxiosError && err.response?.status === 422) {
        enqueueSnackbar(t('giftAlreadyCompleted'), { variant: 'warning' });
        await invalidateGifts();
      } else {
        withMessage(err as Error);
      }
    },
  });
  const deleteGift = useCallback(
    (couponsV2Gift: CouponsV2GiftDTO): void => {
      deleteActivationMutation.mutate(couponsV2Gift.id);
    },
    [deleteActivationMutation],
  );

  return useMemo(() => {
    return {
      deleteGift,
    };
  }, [deleteGift]);
}

export function useDeleteGiftActivationMutation(): UseDeleteGiftsActivationMutation<CouponGiftDTO> {
  const { employee } = useProfileEmployee();

  const { t } = useTranslation('couponsModule');
  const getTenantId = useTenantID();
  const { enqueueSnackbar } = useSnackbar();
  const withMessage = useWithMessage();
  const refetchGifts = useRefetchCouponGifts(employee.id);

  const deleteActivationMutation = useMutation({
    mutationFn: async (giftID: string) => {
      const tenantId = getTenantId();
      await apis.coupons.deleteCouponGift({
        tenantId,
        employeeId: employee.id,
        couponGiftId: giftID,
      });
    },
    onSuccess: async () => {
      await refetchGifts();
      enqueueSnackbar(t('successDeleteGift'), { variant: 'success' });
    },
    onError: async err => {
      if (err instanceof AxiosError && err.response?.status === 422) {
        enqueueSnackbar(t('giftAlreadyCompleted'), { variant: 'warning' });
        await refetchGifts();
      } else {
        withMessage(err as Error);
      }
    },
  });

  const deleteGift = useCallback(
    (couponsGift: CouponGiftDTO): void => {
      deleteActivationMutation.mutate(couponsGift.id);
    },
    [deleteActivationMutation],
  );

  return useMemo(() => {
    return {
      deleteGift,
    };
  }, [deleteGift]);
}
