import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortingState } from '@tanstack/react-table';
import { EmployeeFilter } from '../../benefits/employeeStatusList/employeeFilterState';

export interface FilterState {
  employeeFilter: EmployeeFilter;
  sorting: SortingState;
  tabIndex: number;
}

const initialFilterState: FilterState = {
  employeeFilter: { searchText: '' },
  sorting: [{ id: 'name', desc: false }],
  tabIndex: 0,
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState: initialFilterState,
  reducers: {
    setEmployeeFilter: (state, action: PayloadAction<EmployeeFilter>) => {
      state.employeeFilter = action.payload;
    },
    resetEmployeeFilter: state => {
      state.employeeFilter = initialFilterState.employeeFilter;
    },
    setSorting: (state, action: PayloadAction<SortingState>) => {
      state.sorting = action.payload;
    },
    resetSorting: state => {
      state.sorting = initialFilterState.sorting;
    },
    setTabIndex: (state, action: PayloadAction<number>) => {
      state.tabIndex = action.payload;
    },
  },
});

export const { setEmployeeFilter, resetEmployeeFilter, setSorting, resetSorting, setTabIndex } = filterSlice.actions;
export const filterReducer = filterSlice.reducer;
