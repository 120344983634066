import { UseMutationOptions, UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';

export function useUpdateReceiptStatusMutation(
  benefit: BenefitDTOBenefitEnum,
  receipt: { employeeId: string; id: string },
  options?: Omit<UseMutationOptions<void, Error, { status: 'APPROVED' | 'REJECTED'; rejectionReason?: string }>, 'mutationFn'>,
): UseMutationResult<void, Error, { status: 'APPROVED' | 'REJECTED'; rejectionReason?: string }> {
  const getTenantId = useTenantID();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async update => {
      if (benefit === BenefitDTOBenefitEnum.Lunch) {
        await apis.lunch.updateReceipt({
          tenantId: getTenantId(),
          employeeId: receipt.employeeId,
          receiptId: receipt.id,
          updateLunchReceiptDTO: update,
          isTenantAudit: true,
        });
      } else if (benefit === BenefitDTOBenefitEnum.Mobility) {
        await apis.mobility.updateReceipt({
          tenantId: getTenantId(),
          employeeId: receipt.employeeId,
          receiptId: receipt.id,
          updateMobilityReceiptDTO: update,
          isTenantAudit: true,
        });
      } else if (benefit === BenefitDTOBenefitEnum.Recreation) {
        await apis.recreation.updateReceipt({
          tenantId: getTenantId(),
          employeeId: receipt.employeeId,
          receiptId: receipt.id,
          updateRecreationReceiptDTO: update,
          isTenantAudit: true,
        });
      } else {
        throw new Error(`Unsupported benefit ${benefit}`);
      }
    },

    ...options,
    onSuccess: (...params) => {
      void queryClient.invalidateQueries({
        queryKey: ['tenants', getTenantId(), 'employees', receipt.employeeId, 'benefits', benefit.toLowerCase(), 'receipts', receipt.id],
      });
      void queryClient.invalidateQueries({ queryKey: ['tenants', getTenantId(), 'benefits', benefit.toLowerCase(), 'receipts'] });
      options?.onSuccess?.(...params);
    },
  });
}
