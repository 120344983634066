import { useLayoutEffect } from 'react';

/**
 * Include this hook to always scroll to the top of the page on mount
 */
export function useScrollToTop(): void {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
}
