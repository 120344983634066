import { Button, Stack } from '@mui/material';
import { useTenant } from 'probonio-shared-ui/module/me';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SepaStepHeader } from './SepaStepHeader';

interface Props {
  onBack: () => void;
}

export const SepaMandateComplete: React.FC<Props> = ({ onBack }) => {
  const { t } = useTranslation('tenantModule');
  const { tenant } = useTenant();

  const isComplete = tenant?.sepa && !!tenant?.sepaMandate;

  return (
    <>
      <SepaStepHeader
        title={t(`paymentMethods.sepaStepper.step.complete.header${isComplete ? 'Complete' : 'InProgress'}`)}
        text={t(`paymentMethods.sepaStepper.step.complete.info${isComplete ? 'Complete' : 'InProgress'}`, {
          sepaMandate: tenant?.sepaMandate ? new Date(tenant.sepaMandate) : '',
        })}
        complete={isComplete}
        inProgress={!isComplete}
      />
      <Stack spacing={1} direction="row" justifyContent="flex-end">
        <Button variant="text" size="small" onClick={onBack}>
          {t('common:buttons.back')}
        </Button>
      </Stack>
    </>
  );
};
