import { SentryRoutes } from 'probonio-shared-ui/module/error/sentry';
import { FC } from 'react';
import { Outlet, Route } from 'react-router-dom';
import { isDevelopment } from '../constants';
import PrivatePage from '../module/auth/PrivatePage';
import { ErrorSimulator } from '../module/error';
import { BenefitsDrawerWrapper } from '../module/navigation/BenefitsDrawer';
import { TutorialDrawer } from '../module/tenant/Tutorial/TutorialDrawer';
import { TutorialContextProvider } from '../module/tenant/Tutorial/tutorialContext';
import NavigateWithParams from '../util/NavigateWithParams';
import EmployeeListPage from './EmployeeListPage';
import Error403Page from './Error403Page';
import Error404Page from './Error404Page';
import EquivalenceCalculatorPage from './GrossNetCalculatorPage';
import HomePage from './HomePage';
import LogoutPage from './LogoutPage';
import OAuthPage from './OAuthPage';
import RegisterErrorPage from './RegisterErrorPage';
import RegisterPage from './RegisterPage';
import TenantSettingsPage from './TenantSettingsPage';
import BAVContractDetailsPage from './bav/BAVContractDetailsPage';
import BAVContractsPage from './bav/BAVContractsPage';
import BAVTermsPage from './bav/BAVTermsPage';
import BenefitDocumentsPage from './benefit/BenefitDocumentsPage';
import BenefitEmployeesPage from './benefit/BenefitEmployeesPage';
import { ParamsBenefitPayrollReportPage } from './benefit/ParamsBenefitPayrollReportPage';
import BikeContractDetailsPage from './bike/BikeContractDetailsPage';
import BikeContractsPage from './bike/BikeContractsPage';
import { BikeleasingIntegrationsPage } from './bikeleasing/BikeleasingConnectionsPage';
import BikeleasingContractDetailsPage from './bikeleasing/BikeleasingContractDetailsPage';
import BikeleasingContractsPage from './bikeleasing/BikeleasingContractsPage';
import { CouponBudgetPage } from './coupons/CouponBudgetPage';
import { CouponGiftsPage } from './coupons/CouponGiftsPage';
import CouponPayrollReportPage from './coupons/CouponPayrollReportPage';
import { CouponSettingsPage } from './coupons/CouponSettingsPage';
import { CouponsV2BudgetPage } from './couponsV2/CouponsV2BudgetPage';
import { CouponsV2GiftsPage } from './couponsV2/CouponsV2GiftsPage';
import { CustomBenefitCreatorPage } from './customBenefit/CustomBenefitCreatorPage';
import { CustomBenefitDocumentsPage } from './customBenefit/CustomBenefitDocumentsPage';
import { CustomBenefitEmployeesPage } from './customBenefit/CustomBenefitEmployeesPage';
import { SelectCustomBenefitPage } from './customBenefit/SelectCustomBenefitPage';
import { EmployeeActivationHistoryPage } from './employee/EmployeeActivationHistoryPage';
import EmployeeActivationsPage from './employee/EmployeeActivationsPage';
import EmployeeEditProfilePage from './employee/EmployeeEditProfilePage';
import FlexPayrollReportPage from './flex/FlexPayrollReportPage';
import { FlexSettingsPage } from './flex/FlexSettingsPage';
import InternetReceiptsPage from './internet/InternetReceiptsPage';
import { LegalTermsPage } from './legalTerms/LegalTermsPage';
import LunchPayrollReportPage from './lunch/LunchPayrollReportPage';
import LunchReceiptsPage from './lunch/LunchReceiptsPage';
import { LunchSettingsPage } from './lunch/LunchSettingsPage';
import MobilityPayrollReportPage from './mobility/MobilityPayrollReportPage';
import MobilityReceiptsPage from './mobility/MobilityReceiptsPage';
import NewsAddArticlesPage from './news/NewsAddArticlesPage';
import NewsArticlesPage from './news/NewsArticlesPage';
import RecreationReceiptsPage from './recreation/RecreationReceiptsPage';
import { StylesheetPage } from './stylesheet/StylesheetPage';

const AppRoutes: FC = () => (
  <SentryRoutes>
    <Route
      element={
        <PrivatePage>
          <TutorialContextProvider>
            <BenefitsDrawerWrapper>
              <TutorialDrawer>
                <Outlet />
              </TutorialDrawer>
            </BenefitsDrawerWrapper>
          </TutorialContextProvider>
        </PrivatePage>
      }
    >
      <Route path="/employees" element={<EmployeeListPage />} />
      <Route path="/employees/:employeeId/benefits/*" element={<EmployeeActivationsPage />} />
      <Route path="/employees/:employeeId" element={<EmployeeActivationsPage />} />
      <Route path="/employees/:employeeId/profile" element={<EmployeeEditProfilePage />} />
      <Route path="/employees/:employeeId/activationHistory" element={<EmployeeActivationHistoryPage />} />

      <Route path="/benefits/news" element={<NewsArticlesPage />} />
      <Route path="/benefits/news/addNewArticle" element={<NewsAddArticlesPage />} />
      <Route path="/benefits/news/addNewArticle/:articleId" element={<NewsAddArticlesPage />} />
      <Route path="/benefits/news/addNewArticle/:articleId/:tabName" element={<NewsAddArticlesPage />} />

      <Route path="/benefits/:benefit" element={<BenefitEmployeesPage />} />
      <Route path="/benefits/:benefit/payrollReport" element={<ParamsBenefitPayrollReportPage />} />
      <Route path="/benefits/:benefit/documents" element={<BenefitDocumentsPage />} />
      <Route path="/benefits/lunch/payrollReport" element={<LunchPayrollReportPage />} />
      <Route path="/benefits/lunch/receipts" element={<LunchReceiptsPage />} />
      <Route path="/benefits/:benefit/settings" element={<LunchSettingsPage />} />
      <Route path="/benefits/internet/receipts" element={<InternetReceiptsPage />} />
      <Route path="/benefits/mobility/payrollReport" element={<MobilityPayrollReportPage />} />
      <Route path="/benefits/mobility/receipts" element={<MobilityReceiptsPage />} />
      <Route path="/benefits/coupons/payrollReport" element={<CouponPayrollReportPage />} />
      <Route path="/benefits/coupons/transactions" element={<CouponBudgetPage />} />
      <Route path="/benefits/coupons/gifts" element={<CouponGiftsPage />} />
      <Route path="/benefits/coupons/settings" element={<CouponSettingsPage />} />
      <Route path="/benefits/coupons_v2/transactions" element={<CouponsV2BudgetPage />} />
      <Route path="/benefits/coupons_v2/gifts" element={<CouponsV2GiftsPage />} />
      <Route path="/benefits/bav/contracts" element={<BAVContractsPage />} />
      <Route path="/benefits/bav/contracts/:employeeId" element={<BAVContractDetailsPage />} />
      <Route path="/benefits/bav/terms" element={<BAVTermsPage />} />
      <Route path="/benefits/bike/contracts" element={<BikeContractsPage />} />
      <Route path="/benefits/bike/contracts/:employeeId" element={<BikeContractDetailsPage />} />

      <Route path="/benefits/flex/lunch/receipts" element={<LunchReceiptsPage />} />

      <Route path="/benefits/flex/mobility/receipts" element={<MobilityReceiptsPage />} />
      <Route path="/benefits/flex/internet/receipts" element={<InternetReceiptsPage />} />
      <Route path="/benefits/flex/coupons/transactions" element={<CouponBudgetPage />} />
      <Route path="/benefits/flex/coupons/gifts" element={<CouponGiftsPage />} />
      <Route path="/benefits/flex/coupons/settings" element={<CouponSettingsPage />} />
      <Route path="/benefits/flex/recreation/receipts" element={<RecreationReceiptsPage />} />
      <Route path="/benefits/flex/payrollReport" element={<FlexPayrollReportPage />} />
      <Route path="/benefits/flex/settings" element={<FlexSettingsPage />} />
      <Route path="/benefits/flex/settings/:benefit" element={<FlexSettingsPage />} />
      <Route path="/benefits/recreation/receipts" element={<RecreationReceiptsPage />} />
      <Route path="/benefits/custom" element={<SelectCustomBenefitPage />} />
      <Route path="/benefits/custom/create/:templateId" element={<CustomBenefitCreatorPage />} />
      <Route path="/benefits/custom/:customBenefitId" element={<CustomBenefitEmployeesPage />} />
      <Route path="/benefits/custom/:customBenefitId/documents" element={<CustomBenefitDocumentsPage />} />
      <Route path="/benefits/custom/:customBenefitId/edit" element={<CustomBenefitCreatorPage />} />
      <Route path="/benefits/bikeleasing/integration" element={<BikeleasingIntegrationsPage />} />
      <Route path="/benefits/bikeleasing/contracts" element={<BikeleasingContractsPage />} />
      <Route path="/benefits/bikeleasing/employee/:employeeId/contracts/:contractId" element={<BikeleasingContractDetailsPage />} />

      <Route path="/calculator" element={<EquivalenceCalculatorPage />} />
      <Route path="/settings" element={<TenantSettingsPage />} />
      <Route path="/legalTerms" element={<LegalTermsPage />} />
      <Route path="/settings/:tabName" element={<TenantSettingsPage />} />
      <Route path="/" element={<HomePage />} />
      {/* deprecated - for bookmarks */}
      <Route path="/users/:employeeId" element={<NavigateWithParams to="/employees/:employeeId" replace />} />
      <Route path="/settings/payment" element={<NavigateWithParams to="/settings" replace />} />
    </Route>
    <Route path="/oauth" element={<OAuthPage />} />
    <Route path="/logout" element={<LogoutPage />} />
    <Route path="/register" element={<RegisterPage />} />
    <Route path="/register/error" element={<RegisterErrorPage />} />
    <Route path="/unauthorized" element={<Error403Page />} />
    {isDevelopment && <Route path="/debug" element={<ErrorSimulator />} />}
    {isDevelopment && <Route path="/stylesheet" element={<StylesheetPage />} />}
    <Route path="/*" element={<Error404Page />} />
  </SentryRoutes>
);

export default AppRoutes;
