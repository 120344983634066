import { Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useMutation } from '@tanstack/react-query';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import { apis } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useTenantID } from 'probonio-shared-ui/module/me';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextFieldControl } from '../../../component/form';
import { useInvalidateBikeleasingConditions } from './useBikeleasingConditions';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';

interface FormFields {
  companyCode: string;
  bikeleasingSecret: string;
}

export const AddBikeleasingCondition: React.FC<{ onSuccess?: () => void }> = ({ onSuccess }) => {
  const { enqueueSnackbar } = useSnackbar();
  const getTenantId = useTenantID();
  const withMessage = useWithMessage();
  const invalidateBikeleasingConditions = useInvalidateBikeleasingConditions();
  const { t } = useTranslation('benefitsModule');

  const { control, handleSubmit } = useForm<FormFields>({ defaultValues: { companyCode: '', bikeleasingSecret: '' } });

  const handleSuccess = useCallback(async () => {
    await invalidateBikeleasingConditions();
    onSuccess?.();
  }, [invalidateBikeleasingConditions, onSuccess]);

  const connectMutation = useMutation({
    mutationFn: async (formValue: FormFields) => {
      await apis.bikeLeasing.createCondition({
        createBikeleasingConditionDTO: formValue,
        tenantId: getTenantId(),
      });
    },

    onError: (error: Error) => {
      if (error instanceof AxiosError && error.response?.status === 409) {
        enqueueSnackbar(t('BIKELEASING.fields.bikeleasingSecret.conflictMessage'), { variant: 'error' });
        return;
      }
      withMessage(error);
    },
    onSuccess: handleSuccess,
  });

  const handleSave = useCallback(
    (values: FormFields) => {
      connectMutation.mutate(values);
    },
    [connectMutation],
  );

  return (
    <form onSubmit={handleSubmit(handleSave)} style={{ flexGrow: 1 }}>
      <Grid container justifyContent="center">
        <Grid size={{ xs: 12, lg: 9, xl: 8 }}>
          <Stack direction="column" spacing={3} alignItems="center">
            <TextFieldControl
              control={control}
              name="companyCode"
              label={t('BIKELEASING.integration.form.companyCode')}
              fullWidth
              rules={{ required: true }}
              disabled={connectMutation.isPending}
            />
            <TextFieldControl
              control={control}
              name="bikeleasingSecret"
              type="password"
              label={t('BIKELEASING.integration.form.bikeleasingSecret')}
              fullWidth
              rules={{ required: true }}
              disabled={connectMutation.isPending}
            />
            <LoadingButton type="submit" size="small" dataTestId="bikeleasing-connect-btn" loading={connectMutation.isPending}>
              {t('BIKELEASING.integration.form.connect')}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};
