import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { BenefitActivationDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useRefetchActivations } from '../../../../benefits/query';
import { useProfileEmployee } from './useProfileEmployee';

interface UseDeleteBenefitActivationMutation {
  deleteActivation: (benefit: BenefitActivationDTO) => void;
}

export function useDeleteBenefitActivationMutation(): UseDeleteBenefitActivationMutation {
  const { employee } = useProfileEmployee();
  const { t } = useTranslation('benefitsModule');

  const getTenantId = useTenantID();
  const refetchActivations = useRefetchActivations(employee.id);

  const deleteActivationMutation = useMutation({
    mutationFn: async (activationId: string) => {
      const tenantId = getTenantId();
      await apis.benefits.deleteBenefitActivation({
        tenantId,
        employeeId: employee.id,
        benefitActivationId: activationId,
      });
    },

    onSuccess: async () => {
      await refetchActivations();
      enqueueSnackbar(t('successDelete'), { variant: 'success' });
    },
  });

  const deleteActivation = useCallback(
    (activation: BenefitActivationDTO): void => {
      deleteActivationMutation.mutate(activation.id);
    },
    [deleteActivationMutation],
  );

  return useMemo(() => {
    return {
      deleteActivation,
    };
  }, [deleteActivation]);
}
