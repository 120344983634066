import isEmail from 'validator/es/lib/isEmail';

// this regex is quite permissive and matches anything that looks like an email
// validation in validateEmail may later fail
const FIND_EMAIL_REGEX =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@[0-9\p{L}][0-9\p{L}.-]{1,61}[0-9\p{L}]\.[0-9\p{L}][\p{L}-]*[0-9\p{L}]+/gu;

export const validateEmail = (email: string): boolean => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  return isEmail(email, { allow_utf8_local_part: false, domain_specific_validation: true }) && !/["\\]/.test(email);
};

export const findAllEmails = (pastedEmails: string): string[] => {
  const matchedMails: string[] = [];
  const mails = pastedEmails.match(FIND_EMAIL_REGEX);
  if (mails) {
    mails.map(mail => matchedMails.push(mail));
  }
  return matchedMails;
};
