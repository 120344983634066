import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Box, Typography } from '@mui/material';
import React from 'react';

interface Props {
  title: string;
  text: string;
  inProgress?: boolean;
  complete?: boolean;
}

export const SepaStepHeader: React.FC<Props> = ({ title, text, inProgress, complete }) => {
  let icon;
  if (complete) {
    icon = <CheckCircleIcon fontSize="large" color="success" sx={{ marginRight: 2 }} />;
  } else if (inProgress) {
    icon = <ScheduleIcon fontSize="large" color="primary" sx={{ marginRight: 2 }} />;
  }

  return (
    <Box display="flex" alignItems="center" marginBottom={3}>
      {icon}
      <Box>
        <Typography variant="h2" marginBottom={0.5}>
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {text}
        </Typography>
      </Box>
    </Box>
  );
};
