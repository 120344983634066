import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Stack } from '@mui/material';
import { MRT_Row } from 'material-react-table';
import { useSnackbar } from 'notistack';
import { NewsArticleDTO } from 'probonio-shared-ui/api';
import { SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewDialogState } from '../../../component/dialog';
import { ConfirmationModal } from '../../../component/dialog/ConfirmationModal';
import { useDeleteNewsArticle } from './useDeleteNewsArticle';
import { useInvalidateNewsArticles } from './useNewsArticles';
import { useNavigate } from 'react-router-dom';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { useExpireNewsArticle } from './useExpireNewsArticle';

export const NewsArticlesActions: React.FC<{ row: MRT_Row<NewsArticleDTO> }> = ({ row }) => {
  const { t } = useTranslation('benefitsModule');
  const { enqueueSnackbar } = useSnackbar();
  const confirmExpireDialog = useNewDialogState();
  const invalidateNewsArticles = useInvalidateNewsArticles();
  const confirmDeleteDialog = useNewDialogState();

  const navigate = useNavigate();

  const handleEdit = useCallback(() => {
    navigate(`/benefits/news/addNewArticle/${row.original.id}`, {
      state: { forceHistoryBack: true, article: row.original },
    });
  }, [navigate, row]);

  const deleteMutation = useDeleteNewsArticle();
  const handleDelete = useCallback(() => {
    deleteMutation.mutate(row.original, {
      onSuccess: async () => {
        await invalidateNewsArticles();
        enqueueSnackbar(t('NEWS.fields.deleteArticleSuccess'), { variant: 'success' });
        confirmDeleteDialog.dialogState.handleClose();
      },
    });
  }, [confirmDeleteDialog.dialogState, deleteMutation, enqueueSnackbar, invalidateNewsArticles, row.original, t]);

  const expireMutation = useExpireNewsArticle();
  const handleExpiration = useCallback(() => {
    expireMutation.mutate(row.original, {
      onSuccess: async () => {
        await invalidateNewsArticles();
        enqueueSnackbar(t('NEWS.fields.expireArticleSuccess'), { variant: 'success' });
        confirmExpireDialog.dialogState.handleClose();
      },
    });
  }, [confirmExpireDialog.dialogState, expireMutation, enqueueSnackbar, invalidateNewsArticles, row.original, t]);

  // prevent clicks inside the actions component to propagate to the row click handler
  const handleStopPropagation = useCallback((ev: SyntheticEvent) => ev.stopPropagation(), []);

  return (
    <Stack direction="row" justifyContent="flex-end" onClick={handleStopPropagation}>
      {row.original.articleStatus !== 'EXPIRED' && (
        <IconButton onClick={handleEdit}>
          <EditIcon fontSize="small" />
        </IconButton>
      )}

      {(row.original.articleStatus === 'EXPIRED' || row.original.articleStatus === 'DRAFT') && (
        <>
          <IconButton data-test-id="delete-article-button" onClick={confirmDeleteDialog.handleOpen} title={t('NEWS.fields.deleteButton')}>
            <DeleteIcon fontSize="small" />
          </IconButton>
          <ConfirmationModal
            dialogState={confirmDeleteDialog.dialogState}
            confirmColor="error"
            confirmButtonTitle={t('NEWS.fields.deleteButton')}
            onConfirm={handleDelete}
            loading={deleteMutation.isPending}
            closeOnConfirm={false}
            dataTestId="confirm-delete-contract"
          >
            {t('NEWS.fields.confirmDelete')}
          </ConfirmationModal>
        </>
      )}
      {row.original.articleStatus === 'PUBLISHED' && (
        <>
          <IconButton data-test-id="expire-article-button" onClick={confirmExpireDialog.handleOpen} title={t('NEWS.fields.expireButton')}>
            <StopCircleIcon fontSize="small" />
          </IconButton>
          <ConfirmationModal
            dialogState={confirmExpireDialog.dialogState}
            confirmColor="error"
            confirmButtonTitle={t('NEWS.fields.expireButton')}
            onConfirm={handleExpiration}
            loading={expireMutation.isPending}
            closeOnConfirm={false}
            dataTestId="confirm-expire-Article"
          >
            {t('NEWS.fields.confirmExpire')}
          </ConfirmationModal>
        </>
      )}
    </Stack>
  );
};
