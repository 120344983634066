import { useTranslation } from 'react-i18next';
import { BenefitPaper } from '../../component/benefitTabs/BenefitPaper';
import { BENEFIT_ICONS } from '../../module/benefits/BenefitIcon';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { AddBikeleasingConditionMenu } from '../../module/benefits/bikeleasing/AddBikeleasingConditionMenu';
import { BikeleasingConditionList } from '../../module/benefits/bikeleasing/BikeleasingConditionList';
import { useBikeleasingPrecondition } from '../../module/benefits/bikeleasing/useBikeleasingPrecondition';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';

export const BikeleasingIntegrationsPage: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { PreconditionComponent, isPreconditionMet } = useBikeleasingPrecondition();

  return (
    <AuthorizedLayout
      title={t('BIKELEASING.integration.title')}
      breadcrumbs={[{ title: t('BIKELEASING.title'), to: '/benefits/bikeleasing' }]}
    >
      <BenefitSubPage
        icon={BENEFIT_ICONS.BIKELEASING}
        title={t('BIKELEASING.integration.title')}
        subTitle={t('BIKELEASING.integrationSubTitle')}
        menu={<AddBikeleasingConditionMenu />}
      >
        <BenefitPaper>{isPreconditionMet ? <BikeleasingConditionList /> : PreconditionComponent}</BenefitPaper>
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};
