import { useMemo } from 'react';

import { EmployeeListDTO } from 'probonio-shared-ui/api';
import { useEmployeeList } from '../../module/userManagement/useEmployeeList';

export function useUnreadConfirmationReport(articleId: string): { unreadEmployeeList?: EmployeeListDTO; isLoading: boolean } {
  const { data: unreadEmployeeList, isLoading } = useEmployeeList({
    includeStatusForNewsArticleId: articleId,
    unconfirmedNewsArticleId: articleId,
  });

  return useMemo(
    () => ({
      unreadEmployeeList,
      isLoading,
    }),
    [isLoading, unreadEmployeeList],
  );
}

export function useReadConfirmationReport(articleId: string): { readEmployeeList?: EmployeeListDTO; isLoading: boolean } {
  const { data: readEmployeeList, isLoading } = useEmployeeList({
    includeStatusForNewsArticleId: articleId,
    confirmedNewsArticleId: articleId,
  });

  return useMemo(
    () => ({
      readEmployeeList,
      isLoading,
    }),
    [isLoading, readEmployeeList],
  );
}
