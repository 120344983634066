import FitnessCenter from '@mui/icons-material/FitnessCenter';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { SvgIconProps } from '@mui/material';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { isBenefitActive, orderedBenefits } from '../BenefitIcon';
import { BenefitMenuItem } from '../benefitMenuItems';
import { CustomBenefitTemplateKeys } from '../customBenefit/customBenefitTemplates';
import { BenefitButton } from './BenefitButton';

export type BenefitButtonRenderer = React.FC<{
  icon?: React.ComponentType<SvgIconProps>;
  title: string;
  active?: boolean;
  onClick: () => void;
  selected?: boolean;
  dataTestId?: string;
  badgeLabel?: string;
  subMenuItems?: BenefitMenuItem[];
}>;

interface Props {
  renderer: BenefitButtonRenderer;
  onSupportDialogOpen: () => void;
  onSendMailDialogOpen: () => void;
}

export const NavBenefitList: React.FC<Props> = ({ renderer, onSupportDialogOpen, onSendMailDialogOpen }) => {
  const { t } = useTranslation();
  const { tenant } = useTenant();

  const location = useLocation();
  const [showUSCBadge, setShowUSCBadge] = useState(localStorage.getItem('show-usc-badge') !== 'false');

  const benefitToExclude: BenefitDTOBenefitEnum[] = [
    BenefitDTOBenefitEnum.Gifts,
    BenefitDTOBenefitEnum.Custom,
    BenefitDTOBenefitEnum.CouponsV2,
  ];

  const handleClick = useCallback(() => {
    onSendMailDialogOpen();
    localStorage.setItem('show-usc-badge', 'false');
    setShowUSCBadge(false);
  }, [onSendMailDialogOpen]);

  const { data: customBenefits } = useTenantQuery(
    ['benefits', 'custom', apis.customBenefits.listCustomBenefits],
    tenantId =>
      apis.customBenefits
        .listCustomBenefits({
          tenantId,
        })
        .then(res => res.data),
    {
      staleTime: 60000,
    },
  );

  const BaseBenefitButton = renderer;

  const isUSCExisting = customBenefits?.results.some(
    customBenefit => customBenefit.templateKey === CustomBenefitTemplateKeys.URBAN_SPORTS_CLUB,
  )
    ? true
    : false;

  return (
    <>
      {orderedBenefits(tenant)
        .filter(benefit => !benefitToExclude.includes(benefit))
        .map(benefit => {
          const active = isBenefitActive(tenant, benefit);

          // match benefit url and subpages
          const targetUrl = RegExp(`^/benefits/${benefit.toLowerCase()}\\b`);

          return (
            <BenefitButton
              renderer={renderer}
              selected={targetUrl.test(location.pathname)}
              key={benefit}
              benefit={benefit}
              active={active}
              onShowFallback={onSupportDialogOpen}
            />
          );
        })}
      {!isUSCExisting && (
        <BaseBenefitButton
          dataTestId="urban-sports-club-button"
          active={false}
          title={t(`customBenefitsModule:benefits:URBAN_SPORTS_CLUB:title`)}
          icon={FitnessCenter}
          badgeLabel={showUSCBadge ? t('navigationModule:badgeLabel.new') : undefined}
          onClick={handleClick}
        />
      )}

      <BaseBenefitButton
        dataTestId="more-benefits-button"
        active={false}
        title={t(`common:moreBenefits`)}
        icon={MoreHorizIcon}
        onClick={onSupportDialogOpen}
      />
    </>
  );
};
