import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Stack, IconButton } from '@mui/material';
import { BenefitTemplateItemDTO } from 'probonio-shared-ui/api';
import { useCallback, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  templateItem: BenefitTemplateItemDTO;
  onEdit: (item: BenefitTemplateItemDTO) => void;
  onDelete: (item: BenefitTemplateItemDTO) => void;
}

export const TemplateItemActions: React.FC<Props> = ({ templateItem, onEdit, onDelete }) => {
  const { t } = useTranslation('tenantModule');

  const handleEdit = useCallback<MouseEventHandler>(
    event => {
      event.stopPropagation();
      onEdit(templateItem);
    },
    [onEdit, templateItem],
  );

  const handleDelete = useCallback<MouseEventHandler>(
    event => {
      event.stopPropagation();
      onDelete(templateItem);
    },
    [onDelete, templateItem],
  );

  const handleStopPropagation = useCallback<MouseEventHandler>(event => {
    event.stopPropagation();
  }, []);

  return (
    <Stack direction="row" justifyContent="flex-end">
      <IconButton
        data-test-id="edit-template-item"
        title={t('benefitTemplates.action.editItem')}
        onClick={handleEdit}
        onMouseDown={handleStopPropagation}
      >
        <EditIcon fontSize="small" />
      </IconButton>
      <IconButton
        data-test-id="delete-template-item"
        title={t('benefitTemplates.action.deleteItem')}
        onClick={handleDelete}
        onMouseDown={handleStopPropagation}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
};
