import { Stack } from '@mui/material';
import { AxiosError } from 'axios';
import { CUSTOM_BENEFIT_ICONS } from 'probonio-shared-ui/component/icon';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useTranslation } from 'react-i18next';
import { BENEFIT_ICONS } from '../../benefits/BenefitIcon';
import { BenefitRef } from '../../benefits/BenefitsCell';

interface Props extends BenefitRef {
  iconSize?: 'inherit' | 'large' | 'medium' | 'small';
}

export const BenefitNameAndIcon: React.FC<Props> = ({ benefit, customBenefitId, iconSize }) => {
  const { t } = useTranslation();

  const { data: customBenefit } = useTenantQuery(
    ['benefits', 'custom', customBenefitId, apis.customBenefits.getCustomBenefit],
    async tenantId => {
      try {
        return await apis.customBenefits
          .getCustomBenefit({
            tenantId,
            customBenefitId: customBenefitId || '',
          })
          .then(res => res.data);
      } catch (err) {
        // ignore 404, as custom benefits can be deleted
        if (err instanceof AxiosError && err.response?.status === 404) {
          return null;
        }
        throw err;
      }
    },
    {
      enabled: !!customBenefitId,
      gcTime: 60000,
      staleTime: 60000,
    },
  );

  const Icon = customBenefit ? CUSTOM_BENEFIT_ICONS[customBenefit.iconName] : BENEFIT_ICONS[benefit];

  let title: React.ReactNode = t(`common:benefit.${benefit}`);
  if (customBenefit) {
    title = customBenefit.title;
  } else if (customBenefit === null) {
    title = <em>{t('common:deletedBenefit')}</em>;
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Icon color="primary" fontSize={iconSize} />
      <div>{title}</div>
    </Stack>
  );
};
