import { BenefitTemplateDTO, BenefitTemplateItemDTO, TenantDTO } from 'probonio-shared-ui/api';
import { isBenefitActive, orderedBenefits } from '../../benefits/BenefitIcon';

export function isTemplateValid(tenant: TenantDTO | undefined, template: BenefitTemplateDTO): boolean {
  return template.templateItems.every(item => isTemplateItemValid(tenant, item));
}

export function isTemplateItemValid(tenant: TenantDTO | undefined, templateItem: BenefitTemplateItemDTO): boolean {
  const tenantActiveBenefits = orderedBenefits(tenant).filter(benefit => isBenefitActive(tenant, benefit));

  return tenantActiveBenefits.includes(templateItem.benefit);
}
