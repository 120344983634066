import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import { useTranslation } from 'react-i18next';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { CustomBenefitList } from '../../module/benefits/customBenefit';
import { CustomBenefitPanel } from '../../module/benefits/customBenefit/CustomBenefitPanel';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';

export const SelectCustomBenefitPage: React.FC = () => {
  const { t } = useTranslation('customBenefitsModule');

  return (
    <AuthorizedLayout title={t('title')}>
      <BenefitSubPage icon={DashboardCustomizeIcon} title={t('title')} subTitle={t('subTitle')} menu={<CustomBenefitPanel />}>
        <CustomBenefitList />
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};
