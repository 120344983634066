import { Box, Divider, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { CUSTOM_BENEFIT_ICONS } from 'probonio-shared-ui/component/icon';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { PREVIEW_WIDTH } from '../PreviewStepper';

interface Props {
  icon: keyof typeof CUSTOM_BENEFIT_ICONS;
  benefitTitle: string;
  defaultMonthlyGrant: number;
  showInVisualisation?: boolean;
}

export const VisualisationPreview: React.FC<Props> = ({ icon, benefitTitle, defaultMonthlyGrant, showInVisualisation }) => {
  const theme = useTheme();
  const { t } = useTranslation('customBenefitsModule');

  const Icon = CUSTOM_BENEFIT_ICONS[icon];
  return (
    <Box sx={{ width: PREVIEW_WIDTH }}>
      <img
        style={{ borderRadius: theme.shape.borderRadius, filter: 'blur(3px)' }}
        src="/image/customBenefits/preview/Visualization.png"
        width="100%"
      />
      <Box sx={{ position: 'relative', width: '100%', visibility: showInVisualisation ? undefined : 'hidden' }}>
        <img style={{ borderRadius: theme.shape.borderRadius }} src="/image/customBenefits/preview/SingleVisualization.png" width="100%" />
        {/* Note: this is more or less a copy from the UI-App. Due to major differences it's currently not easy to do this as a shared component... */}
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            top: 0,
          }}
        >
          <Grid container>
            <Grid size={6} pl={2}>
              <Box>
                <Box
                  sx={{
                    height: '100%',
                    paddingY: 0.75,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <Box data-test-id="custom-benefit-creator-page-visualisation-preview">
                    <Grid container spacing={1} alignItems="center">
                      <Grid>
                        <Icon sx={{ color: theme.palette.primary.main }} />
                      </Grid>
                      <Grid size="grow">
                        <Typography variant="h3" color={theme.palette.primary.main} sx={{ hyphens: 'auto' }}>
                          {benefitTitle}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box data-test-id="custom-benefit-creator-page-visualisation-preview-amount" paddingTop={1}>
                    <Box paddingY={0.7}>
                      <Typography fontSize={14} color="rgb(90, 103, 127)">
                        {t('customBenefitCreator.content.visualisationStep.benefitAmountThisMonth')}
                      </Typography>
                    </Box>
                    <Grid container spacing={0.25}>
                      <Grid>
                        <Typography variant="h2" fontSize={22} fontWeight={500} color={theme.palette.success.main}>
                          {t('customBenefitCreator.content.visualisationStep.amount', { amount: defaultMonthlyGrant })}
                        </Typography>
                      </Grid>
                      <Grid>
                        <CheckIcon color="success" />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box paddingTop={0.4}>
                    <Typography variant="body2" color={theme.palette.primary.light}>
                      {t('customBenefitCreator.content.visualisationStep.availableAmount', { amount: defaultMonthlyGrant })}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Divider orientation="vertical" flexItem variant="middle" sx={{ marginX: '-1px' }} />
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
