import useDebounce from 'probonio-shared-ui/utils/useDebounce';
import { ChangeEventHandler, useCallback, useState } from 'react';
import { SearchInput } from '../../../../component/form/SearchInput';
import { HorizontalBox, VerticalBox } from '../../../../component/layout/BoxLayout';
import { TenantGiftsTable } from './TenantGiftsTable';

interface Props {
  isDelivered?: boolean;
}

export const TenantGiftsPanel: React.FC<Props> = ({ isDelivered }) => {
  const [filterValue, setFilterValue] = useState('');
  const debouncedFilterValue = useDebounce(filterValue);
  const handleChangeFilter = useCallback<ChangeEventHandler<HTMLInputElement>>(ev => {
    setFilterValue(ev.target.value);
  }, []);

  return (
    <>
      <VerticalBox>
        <HorizontalBox gap={1} sx={{ marginBottom: 1.5 }}>
          <SearchInput size="small" value={filterValue} onChange={handleChangeFilter} />
        </HorizontalBox>
        <TenantGiftsTable isDelivered={isDelivered} filter={debouncedFilterValue} />
      </VerticalBox>
    </>
  );
};
