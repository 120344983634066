import { createContext, useContext } from 'react';

export type AggregationsModel = Record<string, { bucketKey: string; value: unknown }[] | undefined>;

const AggregationContext = createContext({} as AggregationsModel);

export function useAggregationContext(): AggregationsModel {
  return useContext(AggregationContext);
}

export const AggregationContextProvider = AggregationContext.Provider;
