import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { useInvalidateBikeleasingConditions } from './useBikeleasingConditions';

export function useDeleteConditionMutation(): UseMutationResult<void, Error, string> {
  const getTenantId = useTenantID();
  const invalidateConditions = useInvalidateBikeleasingConditions();

  return useMutation({
    mutationFn: async (conditionId: string) => {
      await apis.bikeLeasing.deleteCondition({ tenantId: getTenantId(), conditionId });
    },
    onSuccess: invalidateConditions,
  });
}
