export function arrayEquals(a: string[], b: string[]): boolean {
  const aSorted = [...a].sort();
  const bSorted = [...b].sort();

  return JSON.stringify(aSorted) === JSON.stringify(bSorted);
}

/**
 * This will push or delete and element inside array and will return it this is useful to update array for api
 * @param element
 * @param {'delete' | 'update'} operation
 * @param array
 * @returns
 */
export const updateArray = <T>(element: T, operation: 'delete' | 'update', array?: T[]): T[] => {
  const updatedArray = array ? [...array] : [];
  const elementFoundIndex = updatedArray.findIndex(ele => ele === element);
  switch (operation) {
    case 'delete':
      if (elementFoundIndex >= 0) {
        updatedArray.splice(elementFoundIndex, 1);
      }
      break;
    case 'update':
      if (elementFoundIndex < 0) {
        updatedArray.push(element);
      }
      break;
  }
  return updatedArray;
};

export const updateArrayAll = <T>(elements: T[], operation: 'delete' | 'update', array?: T[]): T[] =>
  elements?.reduce((modArray, element) => updateArray<T>(element, operation, modArray), array) || [];
