import { Box } from '@mui/material';
import { Step } from '../CustomBenefitStepper';

interface Props {
  activeStep: number;
  steps: Step[];
}

export const PREVIEW_WIDTH = 415;

const StepPreview: React.FC<Props> = ({ activeStep, steps }) => {
  return (
    <Box data-test-id="custom-benefit-creator-page-step-preview" height="100%" p={2} sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
      {steps[activeStep].preview()}
    </Box>
  );
};

export default StepPreview;
