import 'probonio-shared-ui/module/error/sentry';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'simplebar-react/dist/simplebar.min.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import App from './App';
import { MATERIAL_THEME } from './theme';
import { SnackbarProvider } from './component/snackbar';
import './lang';

const root = createRoot(document.getElementById('root')!);
root.render(
  <ThemeProvider theme={MATERIAL_THEME}>
    <CssBaseline />
    <SnackbarProvider>
      <App />
    </SnackbarProvider>
  </ThemeProvider>,
);
