import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TutorialStepsList } from './useTutorialSteps';

export interface TutorialDrawerState {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}

export function useTutorialDrawerState({ loading, openCount, completedCount }: TutorialStepsList): TutorialDrawerState {
  const [open, setOpen] = useState(false);
  const initialized = useRef(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (!loading && !initialized.current) {
      initialized.current = true;
      if (openCount === 0) {
        setOpen(false);
      } else if (completedCount < 2) {
        setOpen(true);
      }
    }
  }, [loading, completedCount, openCount]);

  return useMemo(
    () => ({
      open,
      handleOpen,
      handleClose,
    }),
    [handleClose, handleOpen, open],
  );
}
