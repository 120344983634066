import { styled, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { colors } from '../../theme';

export const BenefitToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  gap: theme.spacing(1),
  minHeight: 0,
}));

export const BenefitToggleButton = styled(ToggleButton, { shouldForwardProp: prop => prop !== 'color' })<{
  color?: 'primary' | 'secondary';
}>(({ color, theme }) => ({
  width: '100%',
  justifyContent: 'left',
  flexShrink: 0,
  padding: `0 ${theme.spacing(1.1)}`,
  overflow: 'hidden',
  color: `${theme.palette.text.primary}`,
  border: `2px solid ${colors.border.toggleButton} !important`,
  borderRadius: `${theme.shape.borderRadius}px !important`,
  '&.Mui-selected, &.Mui-selected:hover': {
    color: color === 'secondary' ? theme.palette.text.primary : theme.palette.success.contrastText,
    backgroundColor: color === 'secondary' ? colors.secondary : colors.primary,
    border: color === 'secondary' ? `2px solid ${colors.secondary} !important` : '0 !important',
  },
}));
