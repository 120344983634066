import { BenefitDTOBenefitEnum, CouponsV2GiftDTO, CouponsV2GiftDTOGiftTypeEnum } from 'probonio-shared-ui/api';
import { useCallback, useMemo, useState } from 'react';

import { useDeleteV2GiftActivationMutation } from '../hooks/useDeleteGiftsActivationMutations';
import { useProfileEmployee } from '../hooks/useProfileEmployee';
import ProfileV2GiftActivationActionsContext from './useV2GiftActivationModal';
import { useNewDialogState } from '../../../../../component/dialog';
import { DeleteActivationDialog } from '../../../../benefits';
import { useCouponsV2Gifts } from '../../../../benefits/couponsV2/couponsV2Queries';
import { GiftsV2Dialog } from '../../../../benefits/couponsV2/gifts/GiftsV2Dialog';
import { useTenant } from 'probonio-shared-ui/module/me';
import { isBenefitActive } from '../../../../benefits/BenefitIcon';

export const V2GiftActivationModalProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { tenant } = useTenant();
  const { employee } = useProfileEmployee();
  const giftDialog = useNewDialogState();
  const deleteDialog = useNewDialogState();
  const [v2GiftToEdit, setGiftToEdit] = useState<CouponsV2GiftDTO>();
  const [selectedGiftType, setSelectedGiftType] = useState<CouponsV2GiftDTOGiftTypeEnum>();
  const { deleteGift } = useDeleteV2GiftActivationMutation();

  const { data: couponGifts } = useCouponsV2Gifts(employee.id, {});
  const incentives = useMemo(
    () => couponGifts?.results.filter(gift => gift.giftType === CouponsV2GiftDTOGiftTypeEnum.Incentive),
    [couponGifts?.results],
  );

  const handleDeleteV2Gift = useCallback(() => {
    deleteGift(v2GiftToEdit!);
    deleteDialog.dialogState.handleClose();
  }, [deleteDialog.dialogState, deleteGift, v2GiftToEdit]);

  const handleOpenGiftCreate = useCallback(
    (giftType: CouponsV2GiftDTOGiftTypeEnum) => {
      setGiftToEdit(undefined);
      setSelectedGiftType(giftType);
      giftDialog.handleOpen();
    },
    [giftDialog],
  );

  const handleOpenGiftEdit = useCallback(
    (gift: CouponsV2GiftDTO, giftType?: CouponsV2GiftDTOGiftTypeEnum) => {
      setGiftToEdit(gift);
      setSelectedGiftType(giftType);
      giftDialog.handleOpen();
    },
    [giftDialog],
  );

  const handleOpenGiftDelete = useCallback(
    (item: CouponsV2GiftDTO) => {
      setGiftToEdit(item);
      deleteDialog.handleOpen();
    },
    [deleteDialog],
  );
  return (
    <ProfileV2GiftActivationActionsContext.Provider
      value={{ onV2GiftCreate: handleOpenGiftCreate, onV2GiftDelete: handleOpenGiftDelete, onV2GiftEdit: handleOpenGiftEdit }}
    >
      {children}
      {isBenefitActive(tenant, BenefitDTOBenefitEnum.CouponsV2) && (
        <GiftsV2Dialog
          dialogState={giftDialog.dialogState}
          employeeId={employee.id}
          giftType={v2GiftToEdit?.giftType || selectedGiftType}
          currentGift={v2GiftToEdit}
          birthDate={employee?.birthDate}
          incentives={incentives || []}
          userStatus={employee?.user?.status}
        />
      )}
      {v2GiftToEdit && (
        <DeleteActivationDialog
          messageKey="couponsV2Module:gifts.deleteConfirmation"
          dialogState={deleteDialog.dialogState}
          onDelete={handleDeleteV2Gift}
        />
      )}
    </ProfileV2GiftActivationActionsContext.Provider>
  );
};
