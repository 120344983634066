import { BenefitActivationDTO, BenefitActivationDTOBenefitEnum } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  benefit: BenefitActivationDTOBenefitEnum;
  employeeId: string;
  customBenefitId?: string;
}

export function useActiveActivation(
  employeeId: string,
  benefit: BenefitActivationDTOBenefitEnum,
  refetchOnMount: boolean = true,
  customBenefitId?: string,
): { activeActivation: BenefitActivationDTO | undefined; isLoading?: boolean } {
  const { data: activeActivations, isLoading } = useTenantQuery(
    [
      'employees',
      employeeId,
      'benefitActivations',
      { onlyActive: true, benefit, customBenefitId },
      apis.benefits.getBenefitActivations.name,
    ],
    tenantId =>
      apis.benefits
        .getBenefitActivations({ employeeId: employeeId, tenantId, onlyActive: true, benefit, customBenefitId })
        .then(res => res.data),
    { refetchOnMount },
  );

  return {
    activeActivation: activeActivations?.benefitActivations[0],
    isLoading: isLoading,
  };
}

export const ActiveActivation: React.FC<Props> = ({ benefit, employeeId, customBenefitId }) => {
  const { t } = useTranslation('benefitsModule');

  const { activeActivation, isLoading } = useActiveActivation(employeeId, benefit, undefined, customBenefitId);

  // TODO: Skeleton here probably?
  if (isLoading || !activeActivation) {
    return null;
  }

  return <>{t('userBenefitPage.activeSince', { date: new Date(activeActivation.startDate) })}</>;
};
