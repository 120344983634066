export const isDevelopment = import.meta.env.MODE === 'development';
export const PROBONIO_MAIL = 'support@probonio.de';
export const PROBONIO_TEL = '+49 871 97623482';
export const PROBONIO_WA = '491633666523';
export const HUBSPOT_CALENDAR_LINK = 'https://meetings-eu1.hubspot.com/pia-fichtinger';
export const TENANT_MAX_IMAGE_SIZE = 500 * 1024;
export const TENANT_MIN_IMAGE_SIZE = 1024;
export const TENANT_MAX_PDF_SIZE = 5 * 1024 * 1024;
export const TENANT_MIN_PDF_SIZE = 1024;
export const APP_VERSION = __APP_VERSION__;
