import { CircularProgress, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { TenantDTOLegalTermsActionEnum } from 'probonio-shared-ui/api';
import { useTenant } from 'probonio-shared-ui/module/me/hooks';
import { Trans, useTranslation } from 'react-i18next';
import { BrandLogo, NavbarLayout } from '../../module/navigation';
import { sessionStorageSkippedKey } from './legalTerms.const';
import { LegalTermsInfo } from './LegalTermsInfo';
import probonioLogo from './../../component/brand/logo/logo.svg';
import { useAppSelector } from '../../redux/hooks';
interface Props {
  children: React.ReactNode;
}

export const LegalTermsGuard: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation('navigationModule');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xl'));
  const user = useAppSelector(state => state.me.user);

  const { tenant } = useTenant();
  const isTenantSkipping = sessionStorage.getItem(sessionStorageSkippedKey) === tenant?.id;
  const showLegalTerms =
    tenant?.legalTermsAction === TenantDTOLegalTermsActionEnum.Consent ||
    (tenant?.legalTermsAction === TenantDTOLegalTermsActionEnum.Skip && !isTenantSkipping);

  if (!tenant) {
    return <CircularProgress />;
  }

  const isAutoSkip = !user?.employments?.some(employments => employments.tenantId === tenant.id);

  if (showLegalTerms && !isAutoSkip) {
    return (
      <NavbarLayout
        logo={
          isMobile ? (
            <BrandLogo />
          ) : (
            <Typography sx={{ verticalAlign: 'middle' }} component="span" variant="h2">
              {t('titleFull')}
            </Typography>
          )
        }
        menu={undefined}
        title={t('legalTerms:title')}
      >
        <Stack alignItems="center" padding={5} spacing={2} justifyContent="center">
          <img src={probonioLogo} width={300} />
          <Typography variant="h1" data-test-id="legal-terms-guard-title">
            <Trans i18nKey="legalTerms:guardTitle" />
          </Typography>
          <Typography component="span" textAlign="center" data-test-id="legal-terms-consent-done">
            <Trans i18nKey="legalTerms:guardSubtitle" />
          </Typography>
          <LegalTermsInfo tenant={tenant} action={tenant.legalTermsAction!} align="center" />
        </Stack>
      </NavbarLayout>
    );
  }

  return children;
};
