import { useSnackbar } from 'notistack';
import { BulkResultItemDTO, UpdateBenefitActivationDTO } from 'probonio-shared-ui/api';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogState } from '../../../component/dialog';
import { TabbedDialog, TabbedDialogTab } from '../../../component/dialog/TabbedDialog';
import { RowSelectionState } from '../../../component/table/useManualRowSelection';
import { useBenefitContext } from '../BenefitContext';
import { EmployeeFilter } from '../employeeStatusList/employeeFilterState';
import { BulkDeactivateForm } from './BulkDeactivateForm';
import { BulkEditForm } from './BulkEditForm';
import { BulkErrorDialog } from './BulkErrorDialog';
import { BulkUpdateFailedError, useBulkUpdateActivationsMutation } from './useBulkUpdateActivationsMutation';
import { useBoundActivationComponent } from './useBoundActivationComponent';

interface Props {
  dialogState: DialogState;
  employeeFilter: EmployeeFilter;
  rowSelectionState: RowSelectionState;
}

export const BulkEditActivationsDialog: React.FC<Props> = ({ dialogState, employeeFilter, rowSelectionState }) => {
  const { t } = useTranslation('benefitsModule');
  const { benefit, customBenefit } = useBenefitContext();
  const { enqueueSnackbar } = useSnackbar();
  const [updateErrors, setUpdateErrors] = useState<BulkResultItemDTO[]>();

  const bulkUpdateMutation = useBulkUpdateActivationsMutation(employeeFilter, rowSelectionState, benefit, customBenefit?.id);

  const handleUpdate = useCallback(
    async (update: UpdateBenefitActivationDTO) => {
      setUpdateErrors(undefined);
      try {
        const response = await bulkUpdateMutation.mutateAsync(update);
        enqueueSnackbar(t('bulkEditDialog.updateSuccess', { count: response.results.length }), { variant: 'success' });
      } catch (err) {
        if (err instanceof BulkUpdateFailedError) {
          setUpdateErrors(err.results.filter(resultItem => resultItem.status === 'ERROR'));
        }
        // ignore other errors, as they are already handled by withMessage
      }
      dialogState.handleClose();
    },
    [bulkUpdateMutation, dialogState, enqueueSnackbar, t],
  );

  const EditFormComponent = useBoundActivationComponent(BulkEditForm, benefit);

  const handleCloseError = useCallback(() => {
    setUpdateErrors(undefined);
  }, []);

  if (updateErrors) {
    return <BulkErrorDialog dialogState={{ isOpen: !!updateErrors, handleClose: handleCloseError }} updateErrors={updateErrors} />;
  }

  return (
    <TabbedDialog dialogState={dialogState} title={t('bulkEditDialog.title')} maxWidth="sm" fullWidth>
      <TabbedDialogTab label={t('bulkEditDialog.tabs.deactivate')}>
        <BulkDeactivateForm onCancel={dialogState.handleClose} onUpdate={handleUpdate} />
      </TabbedDialogTab>
      <TabbedDialogTab label={t('bulkEditDialog.tabs.changeOptions')}>
        <EditFormComponent onCancel={dialogState.handleClose} onUpdate={handleUpdate} />
      </TabbedDialogTab>
    </TabbedDialog>
  );
};
