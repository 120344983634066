import { Box } from '@mui/material';
import { DateTime } from 'luxon';
import {
  BenefitActivationDTO,
  BenefitDTOBenefitEnum,
  BenefitTemplateItemDTO,
  CouponsV2BenefitOptionsDTO,
  CreateBenefitActivationDTO,
  UpdateBenefitActivationDTO,
} from 'probonio-shared-ui/api';
import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { apis, useTenantQuery } from '../../../../../probonio-shared-ui/module/api';
import { SelectControl } from '../../../component/form';
import { fillNumberRangeArray } from '../../../util/numberRange';
import { ActivationDialogExtensions } from '../ActivationDialog';
import { NonCashLimitWarning } from '../ActivationDialog/NonCashLimitWarning';
import { AvailableCouponsAccordion } from '../coupons/AvailableCouponsDrawer';

interface CouponsV2BenefitOptions {
  monthlyBudget: number;
}

// TODO this value should be configurable per tax period (TaxConfig)
const MIN = 500;
const MAX = 5000;
const BUDGET_OPTIONS = fillNumberRangeArray(MIN, MAX, 500);
BUDGET_OPTIONS.push(4400);
BUDGET_OPTIONS.sort((a, b) => a - b);

const Header: React.FC = () => {
  const { watch } = useFormContext();
  const { employeeId } = useParams();

  const benefitOptions: CouponsV2BenefitOptionsDTO = watch('benefitOptions');

  return (
    <>
      <NonCashLimitWarning employeeId={employeeId} benefit={BenefitDTOBenefitEnum.CouponsV2} monthlyBudget={benefitOptions.monthlyBudget} />
    </>
  );
};

const Fields: React.FC = () => {
  const { watch } = useFormContext();

  const benefitOptions: CouponsV2BenefitOptionsDTO = watch('benefitOptions');

  const { data, isLoading } = useTenantQuery(['benefits', 'coupons', 'definition', apis.coupons.findCouponDefinitions.name], tenantId =>
    apis.coupons.findCouponDefinitions({ tenantId }).then(resp => resp.data),
  );

  return (
    <>
      {!isLoading && benefitOptions.monthlyBudget && (
        <Box mb={2}>
          <AvailableCouponsAccordion coupons={data?.coupons} amount={benefitOptions.monthlyBudget} />
        </Box>
      )}
    </>
  );
};

const InlineField: React.FC = () => {
  const { t, i18n } = useTranslation('benefitsModule');
  const { control } = useFormContext();

  return (
    <SelectControl
      dataTestId="monthlyBudgetValue"
      control={control}
      name="benefitOptions.monthlyBudget"
      label={t('COUPONS_V2.fields.monthlyBudget')}
      fullWidth
      margin="dense"
      rules={{ required: true }}
      options={BUDGET_OPTIONS.map(n => ({ value: n, label: i18n.format(n, 'euro-float') }))}
    />
  );
};

export function useCouponsV2DialogExtensions(): ActivationDialogExtensions<CouponsV2BenefitOptions> {
  const { t } = useTranslation('couponsV2Module');

  const handleResetBenefitOptions = useCallback(
    (selectedActivation?: BenefitActivationDTO | BenefitTemplateItemDTO): CouponsV2BenefitOptions => {
      return {
        monthlyBudget: selectedActivation?.couponsV2Options
          ? selectedActivation.couponsV2Options.monthlyBudget
          : BUDGET_OPTIONS[BUDGET_OPTIONS.length - 1],
      };
    },
    [],
  );

  const mapcouponsV2Options = useCallback(
    (benefitOptions: CouponsV2BenefitOptions) => ({
      monthlyBudget: benefitOptions.monthlyBudget,
    }),
    [],
  );
  const handleCreateActivation = useCallback<ActivationDialogExtensions<CouponsV2BenefitOptions>['onCreateActivation']>(
    async (baseValues, benefitOptions, saveActivation) => {
      const create: CreateBenefitActivationDTO = {
        ...baseValues,
        benefit: BenefitDTOBenefitEnum.CouponsV2,
        couponsV2Options: mapcouponsV2Options(benefitOptions),
      };
      await saveActivation(create);
    },
    [mapcouponsV2Options],
  );
  const handleUpdateActivation = useCallback<NonNullable<ActivationDialogExtensions<CouponsV2BenefitOptions>['onUpdateActivation']>>(
    async (baseValues, benefitOptions, saveActivation) => {
      const update: UpdateBenefitActivationDTO = {
        ...baseValues,
        couponsV2Options: mapcouponsV2Options(benefitOptions),
      };
      await saveActivation(update);
    },
    [mapcouponsV2Options],
  );

  return useMemo(
    () => ({
      Header,
      Fields,
      InlineField,
      createLabel: t('common:buttons.orderWithPayment'),
      earliestStartDate: DateTime.now().plus({ months: DateTime.now().day > 15 ? 1 : 0 }),
      onResetBenefitOptions: handleResetBenefitOptions,
      onCreateActivation: handleCreateActivation,
      onUpdateActivation: handleUpdateActivation,
    }),
    [handleCreateActivation, handleResetBenefitOptions, handleUpdateActivation, t],
  );
}
