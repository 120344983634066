import WarningIcon from '@mui/icons-material/Warning';
import { Alert, Box } from '@mui/material';
import { FieldPath, FieldValues } from 'react-hook-form';
import { SelectControl, SelectControlProps } from '../../../component/form';
import { TemplateBenefitIcons } from './TemplateBenefitIcons';
import { useBenefitTemplates } from './benefitTemplateQueries';
import { useTranslation } from 'react-i18next';
import { useTenant } from 'probonio-shared-ui/module/me';
import { isTemplateValid } from './validateTemplate';

interface Props<T extends FieldValues, TName extends FieldPath<T>> extends Omit<SelectControlProps<T, TName>, 'options' | 'children'> {
  noSelectionOption?: boolean;
  queryEnabled?: boolean;
}

export interface ApplyTemplateFormValues {
  templateId: string;
  startDate: Date;
  endDate?: Date;
}

export const SelectTemplateControl = <T extends FieldValues, TName extends FieldPath<T>>({
  noSelectionOption,
  queryEnabled,
  ...props
}: Props<T, TName>): JSX.Element => {
  const { t } = useTranslation('usersModule');
  const { data: templates } = useBenefitTemplates({ enabled: queryEnabled });
  const { tenant } = useTenant();

  const options = templates?.results.map(template => {
    return {
      label: (
        <Box display="flex" flexGrow={1} justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap={1}>
            <Box maxWidth="300px" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
              {template.name}
            </Box>
            {!isTemplateValid(tenant, template) && <WarningIcon color="error" />}
          </Box>
          <TemplateBenefitIcons template={template} sx={{ marginY: -0.5 }} />
        </Box>
      ),
      value: template.id,
      disabled: !isTemplateValid(tenant, template),
    };
  });

  if (noSelectionOption) {
    options?.unshift({ label: <em>{t('userForm.applyTemplate.emptySelection')}</em>, value: '', disabled: false });
  }

  return templates?.results.length === 0 && !noSelectionOption ? (
    <Alert severity="warning">{t('userForm.applyTemplate.noTemplates')}</Alert>
  ) : (
    <SelectControl dataTestId="select-template-control" {...props} options={options} />
  );
};
